/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Zoom from '@mui/material/Zoom';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import UploadIcon from '@mui/icons-material/Upload';
// import { Typography } from '@mui/material';
import {
   CircularProgress, Tooltip, 
} from '@mui/material';
import cogoToast from 'cogo-toast';
import PrimaryButton from './PrimaryButton';
import SecondaryButton from './SecondaryButton';
import API from 'API/apiConfig';


import { styled } from '@mui/material/styles';
// import axios from 'axios';

const APIURL = process.env.REACT_APP_APIURL;


const styles = {
  noImageContainer: {
    width: '20rem',
    height: '12rem',
  
    // marginTop:'2px',
    // border: '1px solid grey',
    borderRadius:'22px',
    margin: 'auto',
    background: '#f2f2f2',
    textAlign:'center'
  },
  imageContainer: {
    // width: '80%',
    // height: '100px',
    // border: '1px solid grey',
    // margin: 'auto',
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',

    width: '19rem',
    height: '11rem',
    border: '1px solid grey',
    borderRadius:'22px',
    margin: 'auto',
   
  },
  imageDiv: {
    width: 'inherit',
    height: 'inherit',
  },
  logoHandler: {
    display: 'flex',
  },
  iconProps: {
    cursor: 'pointer',
    // height: '20rem',
    padding: '1.5rem',
    marginTop:'50%'
  },
};

class CompanyLogo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedImage: null,
      showDeleteLogoDialog: false,
      loading:false,
    };
  }


  imageSelectHandler = (event) => {
    // console.log('event ', event.target.files[0]);
    const selectedImage = event.target.files[0];
    if (selectedImage && (selectedImage.size > 5242880)) {
      cogoToast.error('File size cannot exceed 5MB');
      return;
    }
   
    // selectedImage.name = 'companyLogo';
    this.setState({
      selectedImage,
    });
  }

  imageUploadHandler = () => {
    const { handleCompanyLogoUpdate } = this.props;
    // const { updateCompanyLogo } = this.context;
    this.setState({
      loading:true
    })
    const { selectedImage } = this.state;
    if (!selectedImage) {
      cogoToast.error('No Image selected. Please select an image');
      return;
    }
    const imageData = new FormData();
    // console.log('img data', imageData, selectedImage)
    imageData.append('image', selectedImage); // this is very important. Make sure that the same name "image" is being used in the api on the server side
    // console.log('Image data ', imageData, ' \n selected image ', selectedImage);
    if(selectedImage.type.includes('svg')){
      this.setState({
        loading:false
      })
      cogoToast.error('svg format is not supported',{
        position: 'top-right',
       
      });
    }else{
      API.post(`${APIURL}/companylogo`, imageData)
      .then((serverRes) => {
        // console.log('Image res ', serverRes);
        if (serverRes.data && serverRes.data.status === 'success') {
          cogoToast.success('Successfully uploaded logo',{
            position: 'top-right',
           
          } );
          handleCompanyLogoUpdate();
          // window.location.reload();
          this.setState({
            loading:false
          })
          return;
        }
        const errorMsg = (serverRes.data && serverRes.data.message) ? serverRes.data.message : 'Internal Error';
        cogoToast.error(`Failed to upload the image: ${errorMsg}`);
      })
      .catch((serverErr) => {
        console.error('Error uploading image ', serverErr);
        cogoToast.error(`Failed to upload image: ${serverErr.message}`);
      });
    }
    
  }

  handleDeleteLogo = () => {
    this.setState({
      showDeleteLogoDialog: true,
      selectedImage:null,
    });
  }

  onDeleteDialogClose = () => {
    this.setState({
      showDeleteLogoDialog: false,
    });
  }

  onDeleteDialogSave = () => {
    // make the API request
    const { handleCompanyLogoUpdate } = this.props;
    API.delete(`${APIURL}/companylogo`)
      .then((serverRes) => {
        if (serverRes && serverRes.data && serverRes.data.status === 'success') {
          cogoToast.success('Successfully deleted the logo',{
            position: 'top-right',
          });
          this.setState({
            showDeleteLogoDialog: false,
          });
          // window.location.reload();
          handleCompanyLogoUpdate();
        } else {
          const errMessage = (serverRes.data && serverRes.data.message) ? serverRes.data.message : 'Internal Error';
          cogoToast.error(`Failed to delete logo: ${errMessage}`);
        }
      })
      .catch((serverErr) => {
        console.error('Failed to delete logo ', serverErr);
        cogoToast.error(`Failed to delete logo: ${serverErr.message}`);
        this.setState({
          showDeleteLogoDialog: false,
        });
      });
  }


  render() {
    const {
      classes,
      imageUrl,
      // handleCompanyLogoUpdate,
    } = this.props;

    const Input = styled('input')({
      display: 'none',
    });

    const {
      selectedImage,
      showDeleteLogoDialog,
      loading,
    } = this.state;
    if (!imageUrl) {
      return (
        <>
        {loading ?
          ( <div >
          <CircularProgress
            style={{ position: 'absolute', top: '50%', left: '50%' }}
          />
        </div> ) :
        (
<>

<Grid container spacing={2} style={{marginTop:'2px'}} >
        <Grid item lg={10} md={10} sm={10} xs={10}  className={classes.noImageContainer} >
        <label htmlFor="contained-button-file">
        <Input accept="image/*" id="contained-button-file" type="file"   onChange={this.imageSelectHandler} />
        <Button style={{margin:'auto'}}  component="span">
          Choose file
        </Button>
      <div style={{fontSize:'14px'}} >
        only .jpg/.jpeg/.png file
      </div>
      </label> 
      <p>
        {
          selectedImage && selectedImage.name ? 
          selectedImage.name :
          ''
        }
      </p>
        </Grid>
        <Grid item xs={2}>
        {
            selectedImage
              ? (
             <>
              <Tooltip title="Upload Image " placement='right-end'>

            
              <UploadIcon
                style={{height:'4rem', width:'1.7rem', color:'#008081' , cursor:'pointer' , marginTop:'1.5rem'}} 
                onClick={this.imageUploadHandler}/>
              </Tooltip>
                </>
                  
              )
              : (<div />)
          }
         
        </Grid>
      </Grid>
          
       


          {/* <Grid container  >
          <Grid item xs={5}>
          <div className={classes.noImageContainer}>


          <Typography variant="h5" component="h2">Company Logo</Typography> 
          <label
          htmlFor="file-upload"
          className={classes.fileUploadLabel}
          onChange={this.imageSelectHandler}
          >
            Upload file
 <input  type="file" accept="image/*" style={{marginTop:'2rem',borderRadius:'22px', backgroundColor:'#fff'}} />
          </label>
        

          <label htmlFor="contained-button-file">
        <Input accept="image/*" id="contained-button-file" type="file"   onChange={this.imageSelectHandler} />
        <Button style={{margin:'auto'}}  component="span">
          Choose file
        </Button>
      </label> 
      </div>
          </Grid>
          <Grid item xs={3}>
             <div>
           
          {
            selectedImage
              ? (
             <>
              
                <Button style={{marginTop:'3rem', padding:'2rem'}} size="small" color="primary" onClick={this.imageUploadHandler}>Upload</Button>
               
                </>
                   <img src="./../.././public/upload.png" alt="upload" /> 
              )
              : (<div />)
          }
          </div>
            </Grid>
        </Grid> */}
        </>
        )
      
      }
       

           

       </>
      );
    }
    return (
      <div className={classes.logoHandler}>
        <div className={classes.imageContainer}>
          <img src={imageUrl} alt="Logo" className={classes.imageDiv} />
        </div>
        <div>
            
          <DeleteIcon  color="error" className={classes.iconProps} onClick={this.handleDeleteLogo} />
        </div>
        <Dialog
          open={showDeleteLogoDialog}
          TransitionComponent={Zoom}
          onClose={this.onDeleteDialogClose}
          aria-labelledby="form-dialog-title"
          maxWidth="sm"
          scroll="body"
        >
          <DialogTitle id="form-dialog-title">
            <span className={classes.warning}>Warning</span>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete the logo ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <SecondaryButton text="No" onClick={this.onDeleteDialogClose} />
             
            <PrimaryButton  text="Yes" onClick={this.onDeleteDialogSave} />
             
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

CompanyLogo.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

CompanyLogo.defaultProps = {
};

export default withStyles(styles)(CompanyLogo);
