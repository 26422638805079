import React from 'react';
import { styled } from '@mui/material/styles';
// import { Select } from '@mui/material';
import Autocomplete from "@mui/material/Autocomplete";



export const SelectStyles = styled(Autocomplete)((props) => ({
  backgroundColor: props.backgroundColor,
  borderRadius: '20px',
  fontSize: '14px',
  border:'none',
  paddingLeft:'10px',
fontFamily:'Montserrat'
  // width:'100%'
}));

const AutoField = (props) => (
  <SelectStyles
    {...props}
    sx={{
      '.MuiOutlinedInput-notchedOutline': { border: 'none' },
      width:250
    }}
    fullWidth
    // inputProps={{
    //   // disableUnderline: true,
    //   style: {
    //     backgroundColor: '#fff',
    //     borderRadius: '22px',
    //     color: '#000',
    //     fontSize: '14px',
    //     border: 'none',
    //   },
    // }}
  />
);

export default AutoField;
// SelectDisplayProps
