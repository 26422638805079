/* eslint-disable react/prop-types */
import React, { useEffect, useState, useContext } from 'react';
// import TextField from '@mui/material/TextField';
// import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import { withStyles } from '@mui/styles';
// import cogoToast from 'cogo-toast';
import PropTypes from 'prop-types';
import UserContext from 'Context/UserContext';
import Input from 'components/Input';
import InputforCountrycode from 'components/InputforCountrycode';
import Typography from '@mui/material/Typography';

const styles = {
  textField: {
    width: '100%',
  },
  saveButton: {
    backgroundColor: '#008081',
    color: 'white',
    borderRadius: '20px',
    margin: '10px !important',
    padding: '5px 15px',
    minWidth: '99px',
    '&:hover': {
      backgroundColor: '#008081',
    },
  },
  subheading:{
    fontSize:'14px',
    fontFamily: 'Montserrat',
  }
};

function UserProfile({
  classes, defaultValues,settingsState, settingsDispatchFn
}) {
  // const [name, setName] = useState('');
  // const [contactNo, setContactNo] = useState('');
  // const [countryCode, setCountryCode] = useState('');
  const [loading, setLoading] = useState(true);
  const { UserDetails } = useContext(UserContext);
  

  // const changeName = (event) => setName(event.target.value);
  // const changeContactNo = (event) => setContactNo(event.target.value);
  // const changeCountryCode = (event) => setCountryCode(event.target.value);


  

  const { name, countryCode, contactNo } = settingsState;
 

  useEffect(() => {
    if (UserDetails) {
      setLoading(false);

      settingsDispatchFn({ type: "NAME", payload:UserDetails.name || ''});
      settingsDispatchFn({ type: "CONTACT_NUMBER", payload: UserDetails.contactNo ? UserDetails.contactNo.number : '' });
      settingsDispatchFn({ type: "COUNTRY_CODE", payload: UserDetails.contactNo ? UserDetails.contactNo.countryCode : ''});
      // settingsDispatchFn({ type: "USER_EMAIL", payload:  UserDetails.contactNo ? UserDetails.contactNo.countryCode : '' });

      // setName(UserDetails.name || '');
      // setContactNo(UserDetails.contactNo ? UserDetails.contactNo.number : '');
      // setCountryCode(
      //   UserDetails.contactNo ? UserDetails.contactNo.countryCode : '',
      // );
    }
    // return () => {
    //   setName('');
    //   setContactNo('');
    //   setCountryCode('');
    // };
  }, [UserDetails]);

  
  return (
    <>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div>
          {/* <form
            onSubmit={(props) => {
              onUpdate(props);
            }}
          > */}
            <Grid>
              <Grid
                container
                item
                spacing={3}
                xl={12}
                xs={12}
                md={12}
                lg={12}
             
                alignItems="center"
                justifyContent="center"
              >
                <Grid container item  alignItems="center" className={classes.subheading}>
                  <Grid item lg={3} md={3}  sm ={3} xs={12} >
                  <Typography component="span" variant="p">
                 Name:
                    </Typography>
                  </Grid>
                  <Grid item  lg={7} md={9}  sm={9} xs={12} >
                    <Input
                      id="name"
                      // label="Name"
                      placeholder="Name"
                      type="string"
                      margin="normal"
                      backgroundColor="#fff"
                // defaultValue={defaultValues.name ? defaultValues.name : ''}
                      value={name}
                      // onChange={changeName}
                      onChange={(event) => {
                        settingsDispatchFn({
                          type: "NAME",
                          payload: event.target.value,
                        });
                      }}
                      className={classes.textField}
                    />
                  </Grid>
                </Grid>

                <Grid container item alignItems="center" className={classes.subheading}>
                  <Grid item  lg={3} md={3} sm={3} xs={12}>
                    <Typography component="span" variant="p">
                  E-mail ID:
                    </Typography>
                  </Grid>
                  <Grid item  lg={7} md={9} sm={9} xs={12} >
                    <Input
                      id="emailId"
                      // label="Email ID"
                      type="email"
                      disabled
                      margin="normal"
                      backgroundColor="#fff"
                    value={
                  defaultValues.emailId ? defaultValues.emailId : ''
                }
                      className={classes.textField}
                    />
                  </Grid>
                </Grid>



                <Grid container item alignItems="center" className={classes.subheading}>
                  <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                    <Typography variant="p" component="span">
                  Phone Number:
                    </Typography>
                  </Grid>
                  <Grid container item  lg={8}  md={9} sm={9} xs={12} spacing={2}>
                    <Grid item lg={3} md={5}  sm={5} xs={6}>
                      <InputforCountrycode
                        id="countryCode"
                        // label="Country Code
                        backgroundColor="#fff"
                        placeholder="Country code"
                        onWheel={(e)=> e.target.blur()} 
                        margin="normal"
                        type="number"
                // defaultValue={defaultValues.contactNo && defaultValues.contactNo.countryCode ? defaultValues.contactNo.countryCode : ''}
                        value={countryCode=== 0 ? " ": countryCode}
                        // onChange={changeCountryCode}
                        onChange={(event) => {
                          settingsDispatchFn({
                            type: "COUNTRY_CODE",
                            payload: event.target.value,
                          });
                        }}
                       
                        className={classes.textField}
                      />
                    </Grid>
                    <Grid item lg={7.5} md={7}  sm={7} xs={6 } fullWidth>
                      <Input
                        id="number"
                        placeholder="Mobile number"
                        margin="normal"
                        type="number"
                        backgroundColor="#fff"
                        onWheel={(e)=> e.target.blur()} 
                // defaultValue={defaultValues.contactNo && defaultValues.contactNo.number ? defaultValues.contactNo.number : ''}
                        value={contactNo=== 0 ? " ": contactNo}
                        // onChange={changeContactNo}
                        onChange={(event) => {
                          settingsDispatchFn({
                            type: "CONTACT_NUMBER",
                            payload: event.target.value,
                          });
                        }}
                        className={classes.textField}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
           
              {/* <Grid item xs={12}>
                <Button variant="contained" className={classes.saveButton} type="submit">
                Update
                </Button>
              </Grid> */}
            {/* </Grid> */}
            {' '}

          {/* </form> */}
        </div>
      )}
    </>
  );
}

UserProfile.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  defaultValues: PropTypes.instanceOf(Object),
  onUpdate: PropTypes.func,
};

UserProfile.defaultProps = {
  onUpdate: (event) => { event.preventDefault(); },
  defaultValues: {
    name: '', emailId: '', contactNo: { countryCode: 0, number: 0 },
  },
};

export default withStyles(styles)(UserProfile);

