import { withStyles } from "@mui/styles";
import { Dialog, DialogActions, DialogTitle, Grid, Paper, Typography } from "@mui/material";
import PrimaryButton from "components/PrimaryButton";
import SecondaryButton from "components/SecondaryButton";
import React, { useState, useEffect, useContext } from "react";
import GoogleNestTable from "./GoogleNestTable";
import Grow from '@mui/material/Grow';
import Zoom from '@mui/material/Zoom';
import DeleteIcon from '@mui/icons-material/Delete';
import DialogContentText from '@mui/material/DialogContentText';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import IconButton from '@mui/material/IconButton';
import SearchOption from "components/SearchOption";
import { Button, Tooltip } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
// import Input from "components/Input";
import * as yup from 'yup';
import ReactSelect from "react-select";
import moment from "moment/moment";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from "@mui/material/TextField";
import AutoField from "components/AutoField";
import cogoToast from 'cogo-toast';
import { useMediaQuery, createTheme } from '@material-ui/core';
import UserContext from "Context/UserContext";
import CloseIcon from '@mui/icons-material/Close';
import NestSteps from "./NestSteps";
import API from "API/apiConfig";
import CampaignIcon from '@mui/icons-material/Campaign';
// import Zoom from '@mui/material/Zoom';

const APIURL = process.env.REACT_APP_APIURL;

const styles = {
    mainControlDiv: {
        // height: '50px',
        marginTop: '15px'
    },
    controlDiv: {
        height: 'inherit',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    externalDiv: {
        width: '100%',
        height: 'auto',
        marginTop: '2rem'
    },
    tableGrid: {
        // height: '70vh',
        backgroundColor: 'white'
    },
    deleteDiv: {
        width: '50px',
        // borderRight: '1px solid #dedede',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        color: 'red',
    },
    disabledDeleteDiv: {
        width: '50px',
        // borderRight: '1px solid #dedede',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#c2c2c2',
    },
    clearSelectionDisabled: {
        display: 'block',
        position: 'relative',
    },
    clearSelectionActive: {
        color: 'red',
    },
    errorMessageText: {
        fontFamily: 'Montserrat',
        fontSize: '16px',
        textAlign: 'left',
        color: 'red',
        marginTop: '15px',
    },
    editDiv: {
        width: '100px',
        color: 'rgb(0, 128, 129)',
        // borderRight: '1px solid #dedede',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
    },
    disabledEditDiv: {
        width: '100px',
        // borderRight: '1px solid #dedede',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#c2c2c2',
    },
    icon:{
        cursor:'pointer'
    },
    dialogTitle:{
        // textAlign:'left',
        backgroundColor:'#f2f2f2',
        borderRadius:'8px',
     
    }
}

const selectStyles = {
    control: () => ({
        alignItems: 'center',
        backgroundColor: '#f2f2f2',
        marginTop: '1rem',
        borderRadius: '20px',
        height: '2.8rem',
        borderBottom: '1px solid #dedede',
        cursor: 'default',

        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',

        outline: '0 !important',
        position: 'relative',

        transition: 'all 100ms',
        boxSizing: 'border-box',
    }),
};

const AddSmartUserSchema = yup.object().shape({
    emailId: yup.string().required('Smart User should not be empty') 

    // assetUidList:yup.array().min(1).required("Asset Group should not be empty"),
});



const GoogleNest = (props) => {


    const { classes, expanded } = props;
    const [openDialog, setOpenDialog] = useState(false);
    // const [selectedOption, setSelectedOption] = useState(null);
    const [reactselectList, setReactSelectList] = useState([]);
    const [selectedAGList, setSelectedAGList] = useState([])
    const [companyUsersList, setCompanyUsersList] = useState([]);
    const [users, setUsers] = useState()
    const [selectedRow, setSelectedRow] = useState([]);
    const [edit, setEdit] = useState(false)
    const [rowData, setRowData] = useState([]);
    const [opendeleteDialog, setOpendeleteDialog] = useState(false)
    const [errorMessage, setErrorMessage] = useState('');
    const [deviceList, setDeviceList] = useState([]);
    const [rowDataCopy, setRowDataCopy] = useState([]);
    const [isLoading, setIsLoading] = useState(true)
    const [openSaveDialog, setOpenSaveDialog] = useState(false);
    const [nestSetupDialog, setNestSetupDialog]=useState(false)
    const contextDetail = useContext(UserContext);
    const { CompanyInfo } = contextDetail;

    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1536,
            },
        },
    });


    const smBreakPoint = useMediaQuery(theme.breakpoints.up('sm'));

    const onAddButtonClick = () => {
      
        if (edit ) {
            cogoToast.error('Close the Edit dialog first', {
                position: 'top-right',
            });
        }else{
            if(nestSetupDialog){
                cogoToast.error('Close the dialog first', {
                    position: 'top-right',
                });
            }else{
                setOpenDialog(true);
            }
           
        }
        
    }


    const handleDialogClose = () => {
        setOpenDialog(false);
        setSelectedAGList([]);
        setUsers();
        setSelectedRow([])
        setEdit(false)
        setErrorMessage('')
        getSmartUserfromServer();
        getAssetGroupList();
    }

    const getSmartUserfromServer = () => {
        setIsLoading(true)
        API.get(`${APIURL}/smartuser`)
            .then((response) => {
                if (response.data && response.data.status === 'success') {

                    const resp = response.data.data;
                    // console.log('resp',resp)
                    setRowData(resp);
                    setRowDataCopy(resp)
                    setIsLoading(false)

                }
                else {
                    setRowData([]);
                    setRowDataCopy([])
                    setIsLoading(false)
                }

            })
            .catch((err) => {
                console.log('error getting smart users from server', err)
                setIsLoading(false)
            })
    }



    useEffect(() => {
        setOpenDialog(false);
        setSelectedRow([]);
        setUsers();
        setSelectedAGList([])
        setErrorMessage('')
        setEdit(false);
        setNestSetupDialog(false);
    }, [expanded])

    const getAssetGroupList = () => {
        const date = [moment().startOf('day').toDate(), moment().toDate()]
        const startInUnix = moment(date[0]).unix()
        const endInUnix = moment(date[1]).unix()
        API.get(`${APIURL}/assetDashboard?startTime=${startInUnix}&endTime=${endInUnix}&eventSummary=${false}`)
            .then((response) => {
                if (response.data.status === 'success') {

                    const assetsGroup = response.data.assetGroups;
                    setDeviceList(assetsGroup)
                
                    const filterassetGpList = assetsGroup.map((ag) => {
                        let assetGroupObj;
                        assetGroupObj = { value: ag.uid, label: ag.id, uid: ag.uid, assets: ag.assets && ag.assets.length ? ag.assets : [] }
                        return assetGroupObj
                    })

                    setReactSelectList(filterassetGpList)

                }

            })
            .catch((err) => {
                console.log('Err', err)
            })
    }

    useEffect(() => {
        getSmartUserfromServer();
        getAssetGroupList()
    }, [])



    const onSearchChange = (event) => {
        const searchUser = event.target.value;
        if (searchUser && searchUser.length) {
            const searchRow = rowDataCopy && rowDataCopy.filter((d) => (searchUser
                ? (d.emailId || ' ').toLowerCase().trim().includes(searchUser.toLowerCase())
                : true
            ));
            setRowData(searchRow)
        }
        else {
            setRowData(rowDataCopy)
        }

    }


    const handleChangeForAssetGroup = (selectedOption) => {
        //   console.log('selectedOption', selectedOption)
        // let agGroupObj;
        if (openDialog) {
            if (selectedOption && selectedOption.length > 0) {
                //  add all options map over selectedOption
                const agGroupObj1 = selectedOption.map((lst) => ({
                    uid: lst.value,
                    id: lst.label,
                    emailId:users,
                    assets: lst.assets && lst.assets.length && lst.assets.map((ast) => {
                        const assetObj = {
                            uid: ast.uid,
                            id: ast.id,
                            isSmartUserAssigned: true,
                            smartUsersCount: ast.smartUsersCount ? ast.smartUsersCount : 0,
                            devices:ast.devices && ast.devices.length ? ast.devices.map((dev)=>({
                                id:dev.id,
                                uid:dev.uid,
                            })) : [],
                        }
                        return assetObj
                    })
                }))
                setSelectedAGList([...selectedAGList, ...agGroupObj1])
            } else {
           
                if(reactselectList.length !== selectedAGList.length){
                    const agGroupObj = {
                        uid: selectedOption.value,
                        id: selectedOption.label,
                        emailId:users,
                        assets: selectedOption && selectedOption.assets && selectedOption.assets.length && selectedOption.assets.map((ast) => {
                            const assetObj = {
                                uid: ast.uid,
                                id: ast.id,
                                isSmartUserAssigned: true,
                                smartUsersCount: ast.smartUsersCount ? ast.smartUsersCount : 0,
                                devices:ast.devices && ast.devices.length ? ast.devices.map((dev)=>({
                                    id:dev.id,
                                    uid:dev.uid,
                                })) : [],
                            }
                            return assetObj
                        })
                    }
    
                    setSelectedAGList([...selectedAGList, agGroupObj])
                }
               
            }

        }
    }


    const DropDown = (props) => {


        const options = props.multi
            ? [{ label: "Select All", value: "all" }, ...props.options]
            : props.options;




        return (
            <div className={`react-select-wrapper ${props.multi ? "multi" : ""}`}>
                <ReactSelect
                    styles={selectStyles}
                    value={[null]}
                    // onChange={handleChangeForAssetGroup}
                  
                    placeholder={CompanyInfo && CompanyInfo.settings &&
                        CompanyInfo.settings.warehouseType ?
                        CompanyInfo && CompanyInfo.settings &&
                        "Select " + CompanyInfo.settings.warehouseType :
                        "Select Warehouse"}
                    name="WarehouseName"
                    options={options}
                    multi={props.multi}
                    // value={props.value ? props.value : null}
                    onChange={(selected) => {
                        // console.log('Selected ', selected, props.multi)
                        props.multi &&
                            selected.value === "all"
                            // selected.find((option) => option.value === "all") 
                            ? props.handleChangeForAssetGroup(options.slice(1))
                            : !props.multi
                                ? props.handleChangeForAssetGroup((selected && selected.value) || null)
                                : props.handleChangeForAssetGroup(selected);
                    }}
                />
            </div>
        )
    }



    const handleAssetGroupselection = (params) => {
        const { assetgpUid, value } = params;
        const { checked } = value.target;

        // if(checked){
        const newselectedAGtoAdd = [...selectedAGList];
        newselectedAGtoAdd.map((ag, agindex) => {
            if (ag.uid === assetgpUid) {
                newselectedAGtoAdd[agindex].assets.map((ast) => {
                    ast.isSmartUserAssigned = checked
                })
            }

        })

        setSelectedAGList(newselectedAGtoAdd)

        // }

    }

    const handleAssetsSelection = (params) => {
        const { assetgpUid, value, assetUid } = params;
        const { checked } = value.target;



        const newselectedAGtoAdd = [...selectedAGList];
        newselectedAGtoAdd.map((ag, agindex) => {
            if (ag.uid === assetgpUid) {
                ag.assets.map((ast, astindex) => {
                    if (ast.uid === assetUid) {
                        newselectedAGtoAdd[agindex].assets[astindex].isSmartUserAssigned = checked
                    }

                })
            }
        })

        setSelectedAGList(newselectedAGtoAdd)



    }

    const onRowCheckboxClick = (params) => {
        // console.log('onRowCheckboxClick', params)
        setSelectedRow(params)
    }

    const onEditButtonClick = () => {

        if (selectedRow && selectedRow.length === 1) {
            if (openDialog === true || nestSetupDialog  ) {
                cogoToast.error('Close the dialog first', {
                    position: 'top-right',
                });
            } else {
                setEdit(true);
                setOpenDialog(true)
                setUsers(selectedRow[0].emailId)
                setSelectedAGList(selectedRow[0].assetGroups)
            }

        }
        else {
            cogoToast.info('Select a single row to edit or View info', {
                position: 'top-right',
            });
        }
    }

    useEffect(() => {
        API.get(`${APIURL}/usersList`).then((usersResponse) => {
            const usersInfo = usersResponse.data.data;
            const usersList = usersInfo.map(user => user.uid);
            // console.log(usersList);
            setCompanyUsersList(usersList);
        });
    }, []);



    const handleUsersChange = (event, value) => {
        //checking value as null , as in schema , it shows error value cannot be null , so if value is null setUsers to empty string
        {
            value === null ?
            setUsers()
            :
            setUsers(value)
        }
        

    };

    const handleAddDialogSave = (event) => {
        event.preventDefault();
        //filter assetUi list from selectedAGList...
        let ListOfAssetUid = []
        selectedAGList && selectedAGList.length && selectedAGList.forEach((ag) => {
            if (ag.assets && ag.assets.length) {
                ag.assets.forEach((ast) => {
                    if (ast.isSmartUserAssigned) {
                        ListOfAssetUid = [...ListOfAssetUid, ast.uid]
                    }

                })
            }
        })

        const endUserObject = {
            emailId: users,
            assetUids: ListOfAssetUid

        }
        AddSmartUserSchema.validate({
            emailId: endUserObject.emailId,
        })
            .then(() => {
                if (selectedAGList && selectedAGList.length > 0) {
                    API.post(`${APIURL}/smartuser`, endUserObject)
                        .then((res) => {
                            // console.log('res', res)
                            if (res && res.data && res.data.status === "success") {

                                setUsers();
                                setErrorMessage('');
                                // setSelectedAGList([]);
                                setOpenDialog(false);
                                getSmartUserfromServer();
                                getAssetGroupList();
                                setOpenSaveDialog(true);
                                // cogoToast.success('Successfully added smart user', { position: 'top-right' });
                            } else {
                                const errMsg = res.data && res.data.message
                                cogoToast.error(errMsg, {
                                    position: 'top-right',
                                });
                            }
                        })
                        .catch((err) => {
                            const errMsg = err.data.data.message

                            cogoToast.error(errMsg, {
                                position: 'top-right',
                            });
                        })
                } else {
                    cogoToast.error("Warehouse list cannot be empty", {
                        position: 'top-right',
                    });
                }


            })
            .catch((error) => {
                console.log('err', error)
                const errorMessage = error.message ? error.message : 'Error adding smart user';
                setErrorMessage(errorMessage);
            })

    }

    const handleEditDialogSave = (event) => {
        event.preventDefault();
        let ListOfAssetUid = []
        selectedAGList && selectedAGList.length && selectedAGList.forEach((ag) => {
            if (ag.assets && ag.assets.length) {
                ag.assets.forEach((ast) => {
                    if (ast.isSmartUserAssigned) {
                        ListOfAssetUid = [...ListOfAssetUid, ast.uid]
                    }

                })
            }
        })

        const endUserObjectEdit = {
            emailId: users,
            assetUids: ListOfAssetUid
        }
    
        if (users && users.length) {
            if (ListOfAssetUid && ListOfAssetUid.length > 0) {
              
                API.patch(`${APIURL}/smartuser`, endUserObjectEdit)
                    .then((res) => {
                        // console.log('res', res)
                        if (res && res.data && res.data.status === "success") {
                            setUsers();
                            setErrorMessage('');
                            setSelectedAGList([]);
                            setOpenDialog(false);
                            getSmartUserfromServer()
                            getAssetGroupList();
                            setSelectedRow([])
                            setEdit(false)
                            cogoToast.success('Successfully updated selected smart user', { position: 'top-right' });
                        }
                        else {
                            const errMsg = res.data && res.data.message
                            cogoToast.error(errMsg, {
                                position: 'top-right',
                            });
                        }
                    })
                    .catch((err) => {
                        const errMsg = err.data.data.message

                        cogoToast.error(errMsg, {
                            position: 'top-right',
                        });
                        //toast msg
                    })
            } else {
                cogoToast.error("Warehouse list cannot be empty", {
                    position: 'top-right',
                });
            }

        } else {
            const errorMessage = "Smart User cannot be empty";
            setErrorMessage(errorMessage);
        }


    }

    const onDeleteDialogSave = () => {

        let ListOfEmailIds = []
        selectedRow && selectedRow.length && selectedRow.forEach((ag) => {
            ListOfEmailIds = [...ListOfEmailIds, ag.emailId]

        })

        API
            .delete(`${APIURL}/smartuser`, {
                params: { emailIds: ListOfEmailIds },
            })
            .then((resp) => {
                if (resp && resp.data && resp.data.status === "success") {
                    setOpendeleteDialog(false);
                    setSelectedRow([])
                    getSmartUserfromServer();
                    getAssetGroupList();
                    cogoToast.success('Successfully deleted selected smart user(s)', { position: 'top-right' });
                }
                else {
                    const errMsg = resp.data && resp.data.message
                    cogoToast.error(errMsg, {
                        position: 'top-right',
                    });
                }
            })
            .catch((err) => {
                console.log('err', err)
            })

    }

    const handleDeleteDialogClose = () => {
        setOpendeleteDialog(false);
        setSelectedRow([]);

    }

    const onDeleteButtonClick = () => {
        if (edit) {
            cogoToast.error('Close the Edit dialog first', {
                position: 'top-right',
            });
        } else {
            if(nestSetupDialog){
                cogoToast.error('Close the  dialog first', {
                    position: 'top-right',
                });
            }else{
                setOpendeleteDialog(true)
            }
            
        }

    }

    const handleSelectionClear = () => {
        setSelectedRow([])
        setOpenDialog(false);
        setNestSetupDialog(false);
        setSelectedAGList([])
        getSmartUserfromServer()
        setEdit(false);
        setNestSetupDialog(false)
    }
   
    const handleNestdialogClick=()=>{
        setNestSetupDialog(true);
        setOpenSaveDialog(false);

    }

    const handleSaveDialogClose=()=>{
        setOpenSaveDialog(false);
        setSelectedAGList([]);
    }

    const handleNestSetUpDialogClose=()=>{
        setNestSetupDialog(false)
        setSelectedAGList([]);
        setSelectedRow([])
        setUsers();
    }

    const handletestclick=()=>{
       
        if (selectedRow && selectedRow.length === 1) {
           
            setNestSetupDialog(true)
            setSelectedAGList(selectedRow[0] && selectedRow[0].assetGroups)
            setUsers(selectedRow[0].emailId)
        }
        else {
            cogoToast.info('Select a single row to test notification', {
                position: 'top-right',
            });
        }

    }

   
    //  to filter react select list.
    const filterSelectList = reactselectList.filter(
        (item) =>
            !selectedAGList.map((el) => el.uid).includes(item.uid)
    );




    return (<>

        <div className={classes.externalDiv}>
            <Grid container spacing={12} justifyContent="center" className={classes.mainControlDiv}>
                <Grid item md={12} sm={12} xs={12} className={classes.controlDiv}>
                    <PrimaryButton
                        variant="contained"
                        text="Add Smart User"
                        onClick={onAddButtonClick}
                    // disabled={userPermissions &&
                    //     !userPermissions.assetGroups?.update  &&
                    //      !userPermissions.assets?.update 
                    //    }
                    />

                </Grid>
            </Grid>


            {/* Add user dialog */}
            {
                openDialog ?
                    <Grow in={openDialog}>

                        <Paper >
                            <DialogTitle>
                                {edit ? "Edit Smart User" : "Add Smart User"}
                            </DialogTitle>
                            <DialogContent style={{ height: '40vh' }}>
                                <Grid container style={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <Grid item xl={4} lg={4} md={4} sm={12} xs={12} >
                                      
                                        <AutoField
                                            style={{ width: '98%', height: '2.8rem', marginTop: '1em' }}
                                            disablePortal
                                            backgroundColor="#f2f2f2"
                                            // multiple
                                            disabled={edit}
                                            id="users"
                                            value={users}
                                            onChange={handleUsersChange}
                                            options={companyUsersList}
                                            getOptionLabel={option => option}
                                            filterSelectedOptions
                                            renderOption={(props, option) => (
                                                <li {...props} key={option}>
                                                    {option}
                                                </li>
                                            )}
                                            renderInput={(params) => <TextField {...params} placeholder="Select Smart User" />}
                                        />
                                    </Grid>
                                    <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                                        <DropDown
                                            // value={selectedOption || '' }
                                            value={selectedAGList}
                                            options={filterSelectList}
                                            handleChangeForAssetGroup={handleChangeForAssetGroup}

                                            multi={true}>

                                        </DropDown>


                                    </Grid>
                                </Grid>
                                {/* selected AssetGroup List */}
                                <Grid >
                                    <Typography

                                        sx={{
                                            textAlign: 'left',
                                            marginTop: '1rem',
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        Selected  {CompanyInfo && CompanyInfo.settings &&
                                            CompanyInfo.settings.warehouseType ?
                                            CompanyInfo && CompanyInfo.settings &&
                                            CompanyInfo.settings.warehouseType + " List" :
                                            " Warehouse List"} :

                                    </Typography>
                                    <Grid >
                                        {
                                            selectedAGList && selectedAGList.length ?
                                                selectedAGList.map((ag) => (
                                                    <>
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={ag.assets && ag.assets.some((ast) => ast.isSmartUserAssigned === true)}

                                                                        onChange={(e) => { handleAssetGroupselection({ value: e, assetgpUid: ag.uid, assets: ag.assets }) }}
                                                                        sx={{ '& .MuiSvgIcon-root': { fontSize: 20, color: '#008081' } }}
                                                                    />

                                                                }
                                                                label={ag.id}
                                                                style={{ color: '#008081' }}
                                                            />

                                                        </FormGroup>
                                                        <Grid style={{ display: "grid", gridTemplateColumns: `repeat(${smBreakPoint ? 3 : 1}, 1fr)`, paddingLeft: '2rem', gridGap: '1rem' }} >
                                                            {
                                                                ag.assets && ag.assets.length ?
                                                                    ag.assets.map((ast) => (
                                                                        <>
                                                                            <Grid key={ast.uid} sx={{ color: 'rgba(0,0,0,0.8)' }}>
                                                                                <FormGroup>

                                                                                    <FormControlLabel
                                                                                        //   disabled={!edit ?  ast.smartUsersCount  >= 5 : false }
                                                                                        // disabled={edit ?  ast.smartUsersCount  >= 5 : false }
                                                                                        control={
                                                                                            <Tooltip title={ast.smartUsersCount <= 5 ? "" : 'you cannot add the  same asset more than 5 times'}>
                                                                                                <span>
                                                                                                    <Checkbox

                                                                                                        // checked={!edit ? ast.isSmartUserAssigned && ast.smartUsersCount < 5 : ast.isSmartUserAssigned}
                                                                                                        checked={ast.isSmartUserAssigned}
                                                                                                        onChange={(e) => { handleAssetsSelection({ value: e, assetgpUid: ag.uid, assetUid: ast.uid }) }}
                                                                                                        sx={{ '& .MuiSvgIcon-root': { fontSize: 16, color: '#008081', } }}
                                                                                                    />
                                                                                                </span>
                                                                                            </Tooltip>

                                                                                        }
                                                                                        label={ast.id}

                                                                                    />

                                                                                </FormGroup>

                                                                            </Grid>

                                                                        </>
                                                                    ))
                                                                    : "No chambers found"
                                                            }
                                                        </Grid>

                                                    </>
                                                ))
                                                :
                                                <Grid sx={{ textAlign: 'center', paddingTop: '6rem' }}>
                                                    <Typography variant="div" >
                                                        No {" "}

                                                        {CompanyInfo && CompanyInfo.settings &&
                                                            CompanyInfo.settings.warehouseType ?
                                                            CompanyInfo && CompanyInfo.settings &&
                                                            CompanyInfo.settings.warehouseType :
                                                            " Warehouse"}
                                                        {" "} selected
                                                    </Typography>
                                                </Grid>
                                        }
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <DialogActions>
                                    <SecondaryButton text="Cancel" onClick={handleDialogClose} />

                                    <PrimaryButton text="Save" type="submit"
                                        onClick={edit ? handleEditDialogSave : handleAddDialogSave}
                                        className={classes.saveButton} variant="contained" />
                                </DialogActions>
                            </DialogActions>
                            {errorMessage ? (
                                <div style={{ textAlign: 'left', padding: '1rem' }} >
                                    <span className={classes.errorMessageText}>
                                        *
                                        {errorMessage}
                                    </span>
                                </div>
                            ) : null}
                        </Paper>

                    </Grow>
                    : ''
            }
            {
                nestSetupDialog ? 
                <>
                <Grow in={nestSetupDialog}>
                <Paper>
                <Grid sx={{textAlign:'right',}} >
                <Tooltip title="Close" >
                            <IconButton
                                onClick={handleNestSetUpDialogClose}
                                className={classes.icon}
                            >
                                <CloseIcon />
                            </IconButton>
            </Tooltip>
                        </Grid>
                    <DialogTitle  className={classes.dialogTitle} >
                <Grid container
                       
                    >
            
                        <Grid style={{margin:'auto'}} >
                           <Typography variant="p" sx={{fontSize:'1.5rem', fontFamily:'Montserrat',}}>How to setup google home ? </Typography>
                        </Grid>
                      
                    </Grid>
                    </DialogTitle>
                    <DialogActions style={{justifyContent:'center'}}>
                        <NestSteps 
                        selectedAGList={selectedAGList}
                        users={users}
                        />
                    </DialogActions>
                </Paper>
                </Grow>
                </>
                :''
            }
            <Grow in>
                <Grid container justifyContent="center">
                    <Grid item xl={7} lg={7} md={5} sm={5} xs={12} style={{ textAlign: 'left', display: 'flex' }}  >
                        <div style={{ display: 'flex' }}>
                            <Tooltip title="Delete" placement="top-end">
                                <Button
                                    color="error"

                                    className={(selectedRow && selectedRow.length) ? classes.deleteDiv : classes.disabledDeleteDiv}
                                    disabled={!(selectedRow && selectedRow.length >= 1)}
                                // disabled={!(userPermissions && 
                                //     userPermissions.assetGroups?.update &&
                                //     userPermissions.assets?.update &&
                                //      selectedRow && selectedRow.length >= 1)}


                                >
                                    <DeleteIcon
                                        onClick={(selectedRow && selectedRow.length >= 1) ? onDeleteButtonClick : null}
                                    />

                                </Button>
                            </Tooltip>
                            <Tooltip title="Edit" placement="top-end">


                                <Button

                                    onClick={(selectedRow && selectedRow.length >= 1) ? onEditButtonClick : null}
                                    // disabled={!(
                                    //     userPermissions && 
                                    //      userPermissions.assetGroups?.update &&
                                    //      userPermissions.assets?.update &&
                                    //       selectedRow && selectedRow.length >= 1)}
                                    disabled={!(selectedRow && selectedRow.length >= 1)}
                                >
                                    <EditIcon
                                        className={(selectedRow && selectedRow.length) ? classes.editDiv : classes.disabledEditDiv}
                                    // className={(
                                    //     userPermissions && 
                                    //  userPermissions.assetGroups?.update &&
                                    //  userPermissions.assets?.update &&
                                    //     selectedRow && selectedRow.length 
                                    //     ) ? classes.editDiv : classes.disabledEditDiv}

                                    />

                                </Button>
                            </Tooltip>
                            <Button 
                              disabled={!(selectedRow && selectedRow.length >= 1)}
                            variant="text"
                             sx={{ color: '#008081', textTransform: "capitalize", fontSize: "16px"  , fontWeight:600}}
                             startIcon={<CampaignIcon />}
                             onClick={handletestclick}
                             >
                                test voice alert</Button>
                        </div>
                    </Grid>
                    <Grid item xl={3} lg={2} md={3} sm={3} xs={12}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                flexGrow: 1,
                            }}
                        >
                            {/* <Tooltip title="use 'shift' or 'ctrl' for multi-select"> */}

                            <div>
                                <span>Selected : </span>
                                <span className={classes.Selected}>

                                    {selectedRow.length}
                                </span>
                            </div>

                            {/* </Tooltip> */}
                            <Tooltip title="Clear Selection">
                                <IconButton
                                    onClick={handleSelectionClear}


                                >
                                    <HighlightOffIcon
                                        className={
                                            selectedRow && selectedRow.length > 0
                                                ? classes.clearSelectionActive
                                                : classes.clearSelectionDisabled
                                        }
                                    />
                                </IconButton>
                            </Tooltip>

                        </div>




                    </Grid>
                    <Grid item xl={2} lg={3} md={4} sm={4} xs={12}>

                        <SearchOption
                            variant="standard"
                            id="userSearchInput"
                            backgroundColor="#f2f2f2"
                            // value={searchInput}
                            onChange={onSearchChange}
                            placeholder="Search by email id"

                        />
                    </Grid>
                </Grid>

            </Grow>

            <Grid className={classes.tableGrid}>
                <GoogleNestTable
                    rowData={rowData}
                    isLoading={isLoading}
                    selectedRow={selectedRow}
                    onRowCheckboxClick={onRowCheckboxClick}
                    deviceList={deviceList}
                    edit={edit}
                    nestSetupDialog={nestSetupDialog}
                    handleSelectionClear={handleSelectionClear}
                />
            </Grid>

            {/* --------------Dialog to delete -------------- */}
            <Dialog
                open={opendeleteDialog}
                TransitionComponent={Zoom}
                // onClose={this.onDeleteDialogClose}
                aria-labelledby="form-dialog-title"
                maxWidth="sm"
                scroll="body"
            >
                <DialogTitle id="form-dialog-title">
                    <span className={classes.warning}>Warning</span>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete the selected Smart User ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <SecondaryButton text="No"
                        onClick={handleDeleteDialogClose}
                    />

                    <PrimaryButton text="Yes"
                        onClick={onDeleteDialogSave}
                    />

                </DialogActions>
            </Dialog>

            {/* --------------------successfully Save Dialog ----------------- */}
            <Dialog
                open={openSaveDialog}
                TransitionComponent={Zoom}
                // onClose={this.onDeleteDialogClose}
                aria-labelledby="form-dialog-title"
                maxWidth="sm"
                scroll="body"
                PaperProps={{
                    style: {
                      backgroundColor: '#7e7e7e00',
                      boxShadow:'none'
                    },
                  }}
            >
                
                <Grid container sx={{justifyContent:'center',}}>
                    <img src="./../img/success.png" alt="faq" style={{top:'1.5rem', position:'relative', height:'3rem'}} />
                  
                </Grid>
                <Grid sx={{backgroundColor:'#fff', borderRadius:'16px', borderBottom:'7px solid #008081'}}>
                    <Grid>
                    <Grid sx={{textAlign:'right', height:'1rem'}} >
                            <IconButton
                                onClick={handleSaveDialogClose}
                                className={classes.icon}
                            >
                                <CloseIcon />
                            </IconButton>

                        </Grid>
                    </Grid>
                <DialogTitle>
                    <Grid container
                       
                    >
                      {/* <Grid item xs={12}>
                      <Typography variant="p"> Smart User</Typography><br/>
                      </Grid> */}
               
               
                        <Grid style={{margin:'auto'}} >
                           <Typography variant="p" sx={{fontSize:'16px', fontFamily:'Montserrat'}}> Successfully Added Smart User</Typography>
                        </Grid>
                     
                    </Grid>
                   

                </DialogTitle>
                <DialogContent >
                    <Typography variant="p" sx={{color:'rgba(0,0,0,0.7)'}}>
                        To setup google home.  
                    </Typography>
                    <Typography  variant="p" sx={{color:'#008081', cursor:'pointer'}} onClick={handleNestdialogClick}><u> Click here</u></Typography>
                    {/* <Typography variant="p" sx={{color:'rgba(0,0,0,0.7)'}} ><br />*You can skip the steps, if you know how to do that.    </Typography> */}
                </DialogContent>
                
                </Grid>
            </Dialog>



        </div>


    </>)
}

export default withStyles(styles)(GoogleNest);