/* eslint-disable no-unused-vars */
import React from 'react';
import { useEffect , useContext} from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled, useTheme, ThemeProvider } from "@mui/material/styles";
import {
  Route, NavLink, useLocation, useHistory
} from 'react-router-dom';
import { useMediaQuery, createTheme } from '@material-ui/core';
import { makeStyles } from '@mui/styles';
import UserContext from 'Context/UserContext';
import Roles from './Roles/Roles';
import UserGroups from './User Groups/UserGroups';
import Logs from './AuditSection/Logs/Logs';
import DataExport from './AuditSection/DataExport/DataExport';
import EndUser from './EndUser/EndUser';
import TabPanel from '@mui/lab/TabPanel';

const useStyles = makeStyles(() => ({
  root: {
    margin: 0,
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
  },
  root1:{
    margin: 0,
    padding: 0,
    // display: 'flex',
    flexDirection: 'row',
  },
  navigationMenu: {
    textDecoration: 'none',
    pointer: 'cursor',
    paddingRight: '15px',
    paddingLeft: '15px',
    paddingBottom: '10px',
    fontSize: '14px',
    fontFamily: 'Montserrat',
    color: 'black',
    marginBottom: '10px',
  },
  activeLink: {
    color: 'blue',
  },
  tabs: {
    marginTop: '5%',
    
  },
  tab1:{
marginTop: '0%',
  },
  indicator: {
    top: "0px",
    left: "0px",
    
  },
}));

let TabContent = [
  {
    name: 'User Groups',
    path: 'userGroups',
    id: 1,
    component: UserGroups,
    pathname: '/controlpanel/admin/userGroups',
  },
  {
    name: 'Roles',
    path: 'roles',
    component: Roles,
    id: 2,
    pathname: '/controlpanel/admin/roles',
  },
  {
    name: 'End User',
    component: EndUser,
    id: 3,
    path: "enduser",
    pathname: "/controlpanel/admin/enduser",
  },
  {
    name: "Audit Logs",
    component: Logs,
    id: 4,
    path: "logs",
    pathname: "/controlpanel/admin/logs",
  },
  {
    name: "Data Export",
    component: DataExport,
    id: 5,
    path: "dataexport",
    pathname: "/controlpanel/admin/dataexport",
  },


];
const StyledTab = styled((props) => <Tab aria-label="simple tabs example" disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "capitalize",
    color: theme.palette.primary.main,
    fontWeight: "bold",
    minWidth: "150px",
    width: "100%",
    fontSize: theme.typography.pxToRem(14),
    fontFamily: "Montserrat",
    marginRight: theme.spacing(1),
    
  }),
);

function Admin() {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const theme1 = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
  });
   const breakPoint = useMediaQuery(theme1.breakpoints.up('md'));

   

  const companyDetails = useContext(UserContext);                                 // react context
  const { userPermissions } = companyDetails;

  useEffect(() => {
    if (location.pathname === '/controlpanel/admin') {
      history.push('/controlpanel/admin/userGroups');
    }
   

  },[location]);
  
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
    //if roles read permission is not there, so remove that object from tabContent
    if (Object.keys(userPermissions).length && !userPermissions?.roles?.read) {
     
      TabContent = TabContent.filter((t) => t.path !== "roles");
    }

    //if logs read permission is not there, so remove that object from tabContent
    if (Object.keys(userPermissions).length && !userPermissions?.logs?.read) {
      TabContent = TabContent.filter(
        (t) => t.path !== "logs" || t.path !== "dataExport",
      );
    }

 
  const tabValue = TabContent.findIndex(
    (a) => a.pathname === location.pathname,
  );

  return (
    <div className={breakPoint ?  classes.root : classes.root1}>
  
{/* <Tabs
         value={tabValue}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        aria-label="disabled tabs example"
        
      >
       <Tab label="User" />
        
       <Tab label="Roles" />
      </Tabs> */}
          <ThemeProvider theme={theme}>
      <Tabs
        orientation= {breakPoint ? "vertical"  : "horizontal" }
        variant="scrollable"
        allowScrollButtonsMobile
        value={tabValue}
        classes={{
          indicator: classes.indicator,
          root: breakPoint ? classes.tabs : classes.tab1,
        }}
        TabIndicatorProps={ breakPoint ? {
          style: {
            backgroundColor: "#008081",
            width: "2px",
            height: "36px",
            
          }
          
        } : 
      {style:{
        backgroundColor: "#008081",
        top: "50px"
      }}
    } 
      >
        {TabContent.map(({
          name, pathname, path, id,
        }) => {

 
          if(name === "User Groups"){
            return (
              <NavLink
              key={id}
              className={classes.navigationMenu}
              activeClassName={classes.activeLink}
              to={pathname}
            >
              <StyledTab label={name} value={id} />
            </NavLink>
            )
          }
          if( name === "Roles" && (
                   userPermissions?.roles?.read 
                || userPermissions?.roles?.create
                || userPermissions?.roles?.update
                || userPermissions?.roles?.delete
          )){
            return (
              <NavLink
              key={id}
              className={classes.navigationMenu}
              activeClassName={classes.activeLink}
              to={pathname}
            >
              <StyledTab label={name} value={id} />
            </NavLink>
            )
          }
          if(name === "End User" && (
            userPermissions
            && userPermissions?.assetGroups?.read 
            &&  userPermissions?.assets?.read 
          )){
            return (
              <NavLink
              key={id}
              className={classes.navigationMenu}
              activeClassName={classes.activeLink}
              to={pathname}
            >
              <StyledTab label={name} value={id} />
            </NavLink>
            )
          }
          if((name === "Audit Logs" || name === "Data Export" ) && 
            userPermissions
              && userPermissions?.logs?.read
          ){
            return (
              <NavLink
              key={id}
              className={classes.navigationMenu}
              activeClassName={classes.activeLink}
              to={pathname}
            >
              <StyledTab label={name} value={id} />
            </NavLink>
            )
          }
          // if(name === "Data Export" ){
          //   return (
          //     <NavLink
          //     key={id}
          //     className={classes.navigationMenu}
          //     activeClassName={classes.activeLink}
          //     to={pathname}
          //   >
          //     <StyledTab label={name} value={id} />
          //   </NavLink>
          //   )
          // }
          {/* <NavLink key={name} className={classes.navigationMenu} activeClassName={classes.activeLink} to={`${pathname}`}>
            {
            name === 'User Groups' ? (<StyledTab label="User Groups" value={1} />) :
            (
              userPermissions && userPermissions.roles && (userPermissions.roles.read || userPermissions.roles.create || userPermissions.roles.delete || userPermissions.roles.update) && <StyledTab label="Roles" value={2} />
            )
          }
          </NavLink>
           */}
         
})}
      </Tabs>
      {TabContent.map(({ component, id, pathname }) => (
        <Route key={id} path={`${pathname}`} component={component} />
      ))}
      </ThemeProvider>
    </div>

  );
}
export default Admin;

