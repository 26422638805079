import React, {useContext} from "react";
import UserContext from "Context/UserContext";
import { withStyles } from "@mui/styles";
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// import rowData from "./rowData";
import Checkbox from '@mui/material/Checkbox';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import {CircularProgress, Tooltip } from '@mui/material';
import { useMediaQuery, createTheme } from '@material-ui/core';

const styles={
    imgGrid:{
        marginTop:'10%',
       
    },
    headertext:{
      color:'#fff'
    },
    headerDiv:{
      backgroundColor:'#008081' ,
      height:'3rem',
      color:'#fff',
      fontWeight:'bold',
      fontSize:'14px'
    },
    text:{
      margin:'auto'
    },
    selectedAGtext:{
      fontWeight:'bold',
      fontSize:'14px',
      paddingTop:'0.5rem'
    },
    warehouseHeadingText:{
      textAlign:'left',
      paddingLeft:'0px',
       fontSize:'1rem' ,
       color:'rgba(0,0,0,0.9)'
    },
    chamberHeadingText:{
      textAlign:'left',
      paddingLeft:'4rem',
       fontSize:'1rem',
       color:'rgba(0,0,0,0.9)'
    }
}




const EndUserTable=(props)=>{
    const { classes,rowData,onRowCheckboxClick, selectedRow, isLoading } = props;
  // const [selectedRow, setSelectedRow]= useState([])
  const contextDetail = useContext(UserContext);
  const { CompanyInfo } = contextDetail;

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
  });
  

   const lgBreakPoint= useMediaQuery(theme.breakpoints.up('lg'));

    const handleRowSelect=(params)=>{
    
      const {row, value}=params;
      const {checked}=value.target;
    
      if(checked){
        // setSelectedRow([...selectedRow, row])
        onRowCheckboxClick([...selectedRow, row])
        //set Selected Row to row value...here
       
      }
      else{ 
          //remove selected row from selectedRow array..
          const arr = [...selectedRow]
          const index = selectedRow.findIndex((s) => s === row)

          if(index > -1 ){
            const tableRow = arr[index]
            if (tableRow) { arr.splice(index, 1) }
            // setSelectedRow(arr)
            onRowCheckboxClick(arr)
          }
      }
     
    
    }


    function Row(props){
      const { row ,  } = props;
      const [open, setOpen] = React.useState(false);

    
     
      return(
        <>
        <TableRow  >
          <TableCell style={{padding:'10px'}} >
          <Checkbox
                  style={{color:'#008081'}}
                // color="primary"
                // indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={selectedRow && selectedRow.length && selectedRow.some((sel)=>sel.number === row.number )}
                onChange={(e)=>handleRowSelect({value:e,row:row})}
                inputProps={{
                  'aria-label': 'select all users',
                }}
              />
          </TableCell>
          <TableCell style={{padding:'10px'}}>
           <Typography sx={{fontFamily:'Montserrat', fontSize:'14px'}} >{row.userName}</Typography> 
          </TableCell>
          <TableCell style={{padding:'10px' , }}>
           
          <Typography sx={{fontFamily:'Montserrat', fontSize:'14px',display:'flex', justifyContent:'space-between'}} > 
           +{row.countryCode}-{row.number} 
          {"  "}
          <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}                                                                                                
                // style={{marginRight:'1rem'}}
              >
                {open ? 
               <Tooltip title="Hide detail" >
             <KeyboardArrowUpIcon sx={{fontSize:'2rem',color:'rgba(0,0,0,0.6)' }} />
               </Tooltip>  : 
<Tooltip title="View detail" >
                <KeyboardArrowDownIcon sx={{fontSize:'2rem', color:'rgba(0,0,0,0.6)'}} />
                </Tooltip>}
              </IconButton>
          </Typography>                                                                                                                                                                                           
       
          </TableCell>
          {/* <TableCell> */}
          {/* <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon sx={{fontSize:'2rem'}} /> : <KeyboardArrowDownIcon sx={{fontSize:'2rem'}} />}
              </IconButton> */}
          {/* </TableCell> */}
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            
         {/* <Grid className={classes.selectedAGtext}>Selected Warehouse List:</Grid> */}

            {lgBreakPoint ? 
              <Grid container style={{marginTop:'0.5rem'}} >
              <Grid item xs={2} />
              <Grid item xs={4}  className={classes.warehouseHeadingText}>
              {CompanyInfo && CompanyInfo.settings  && CompanyInfo.settings.warehouseType ? CompanyInfo && CompanyInfo.settings  && CompanyInfo.settings.warehouseType + " Id" : "Warehouse Id"}
                </Grid>
              <Grid item xs={3} className={classes.chamberHeadingText}>
              {CompanyInfo && CompanyInfo.settings  && CompanyInfo.settings.chamberType ? CompanyInfo && CompanyInfo.settings  && CompanyInfo.settings.chamberType + " Id" : "Chamber Id"}
                </Grid>
              <Grid  item xs={3} />
            </Grid> :''}
            {
              row && row.assetGroups && row.assetGroups.length && row.assetGroups.map((ag)=>(
                <Grid container spacing={2} key={ag.id} style={{padding:'1rem',}}>
                   <Grid item xl={2} lg={2} md={2} sm={2} xs={2} />
                  <Grid item xl={4} lg={4} md={12} sm={12} xs={12} style={{ textAlign:'left',paddingLeft:'0px' }} > 
                 {!lgBreakPoint ?  CompanyInfo && CompanyInfo.settings  && CompanyInfo.settings.warehouseType ?   CompanyInfo.settings.warehouseType  + " : " :  "Warehouse Id:":''} 
                   <Typography key={ag.id} variant="p" sx={{fontSize:'14px',fontFamily:'Montserrat', color:'#008081',}} >
                 {ag.id}
                </Typography>
                  </Grid>
                  <Grid item xl={6} lg={6} md={12} sm={12} xs={12} style={{textAlign:'left',paddingLeft:lgBreakPoint ?'4.5rem' :'2rem'}}>
                  {!lgBreakPoint ? CompanyInfo && CompanyInfo.settings  && CompanyInfo.settings.chamberType ? CompanyInfo.settings.chamberType + " : ": "Chamber(s):":''} 
                    {
                      ag.assets && ag.assets.length ?
                      ag.assets.map((ast)=>{
                        if(ast.isEndUserAssigned){
                         return ( <Grid key={ast.id}  >
                          <Typography  sx={{fontSize:'14px',fontFamily:'Montserrat', color:'rgba(0, 0, 0, 0.75)'}}  >{ast.id}</Typography>
                          </Grid> ) 
                        }
                       
                         }
                      )
                      :
                      'No chambers found'
                    }
                   
                    </Grid>
                    {/* <Grid xl={3} lg={3} md={3} item sm={3} xs={3} /> */}
                </Grid>
                
              ))
            }
                 
            </Collapse>
          </TableCell>
        </TableRow>
        </>
      )
    }



    
    return(
        <>
        {/* <Grid  >

        <img src="../../img/emptybox.png" alt="empty" className={classes.imgGrid}></img>
        <div>No Users Found,To add user, click on Add User button on top...</div>
        </Grid> */}
  {
    isLoading ? 
    <div className={classes.overlay}>
    <CircularProgress
      style={{ position: 'absolute', top: '50%', left: '50%' }}
    />
  </div>
     :
     <Grid >
     {
       rowData && rowData.length ?
       <TableContainer component={Paper}style={{height:'70vh'}} >
 <Table aria-label="collapsible table">
   <TableHead style={{backgroundColor:'#008081', color:'#fff', }}>
     <TableRow >
       <TableCell />
       <TableCell align="left"  style={{color:'#fff',fontWeight:'bold',fontFamily:'Montserrat', fontSize:'14px' }} >End User Name</TableCell>
       <TableCell align="left" style={{color:'#fff',fontWeight:'bold',fontFamily:'Montserrat', fontSize:'14px' }}> Contact</TableCell>
       {/* <TableCell align="left" style={{color:'#fff',fontWeight:'bold' ,fontFamily:'Montserrat', fontSize:'14px'}}>View Detail</TableCell> */}
     </TableRow>
   </TableHead>
   <TableBody>
 {
   rowData.map((row)=>(
     <Row key={row.id} row={row} />
   ))
 }
 
       </TableBody>
       </Table>
       </TableContainer>
       :
       <Grid>
           <Grid container className={classes.headerDiv}  > 
             <Grid item  xs={4}  style={{margin:'auto',fontFamily:'Montserrat', fontSize:'14px'}}  >End User Name</Grid>
             <Grid item xs={4} style={{margin:'auto',fontFamily:'Montserrat', fontSize:'14px'}}>Contact</Grid>
             {/* <Grid item  xs={4}style={{margin:'auto', fontFamily:'Montserrat', fontSize:'14px'}} >View Detail</Grid> */}
           </Grid>
          <img src="../../img/emptybox.png" alt="empty" className={classes.imgGrid}></img>
   {/* <div>No end user found,To add end user, click on Add User button on top...</div> */}
       </Grid>
     }
   
   </Grid>
  }
        
        </>
    )
}


export default withStyles(styles)(EndUserTable);