/* eslint-disable no-prototype-builtins */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import Paper from "@mui/material/Paper";
// import Grow from '@mui/material/Grow';
// import Slide from "@mui/material/Slide";
import { withStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
// import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import PropTypes from "prop-types";
// import { Typography, AccordionDetails } from "@mui/material";
import { Autocomplete, Tooltip } from '@mui/material';
import TextField from '@mui/material/TextField';
import ChamberCard from "../../components/ChamberCard";
import SearchInput from "../../components/SearchBar";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import MuiAccordion from "@mui/material/Accordion";
// import MuiAccordionSummary from "@mui/material/AccordionSummary";
// import { styled } from "@mui/material/styles";
// import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import WarehouseReport from "./WarehouseReport";
import GlobalDownload from "./GlobalDownload";



const styles = {
  chamber_outerDiv: {
    margin: "20px 0px",
  },
  emptyPaper: {
    minHeight: "100px",
    margin: "20px 0px",
    display: "flex",
    justifyContent: "center",
  },
  emptyHeading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  heading: {
    width: "100%",
    paddingTop: "20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontFamily: "Montserrat",
    // height: '40px',
    // color: 'white',
    textAlign: "left",
    borderRadius: "22px",
    // padding: '10px 0px 0px 1em',
  },
  heading_text: {
    margin: "0",
    padding: "10px",
    fontWeight: "600",
    fontSize: "20px",
    fontFamily: "Montserrat",
  },
  warehouseHeading: {
    textAlign: "left",
    fontWeight: "600",
    fontSize: "20px",
    borderBottom: "1px solid black",
  },
  cardDiv: {
    display: "flex",
    flexWrap: "wrap",
    // backgroundColor: '#fff',

    borderRadius: "22px",
    padding: "25px 10px",
  },
  searchBar: {
    // width: '100%',
    // display: 'flex',
    // justifyContent: 'flex-end',
    // alignItems: 'center',
    // height: '40px',
    // color: 'white',
    // textAlign: 'right',
    paddingLeft: "10px",
    // padding: '10px 0px 0px 1em',
    // paddingBottom: '1em',
    paddingTop: "1em",
    display:'flex',
     justifyContent:'flex-end'
  },
  searchBar1:{
justifyContent:'right',
textAlign:'right'
  },
 
  assetGroupContainer: {
    display: "block",
    padding: "10px 10px",
  },
  assetGroupHeader: {
    textAlign: "left",
    // paddingLeft: '15px',
    marginTop: "5px",
  },
  autocompleteTextfield: {
    // width: "90%",
    backgroundColor: "#f2f2f2",
    paddingLeft: "20px",
    height: "2.2rem",
    borderRadius: "28px",
    paddingBotto: "-15px",
    // border: "1px solid #b3b5b4",
  },
  menuItem:{
    fontFamily:'Montserrat'
  }
};





const menuItems=[
   
  {
      type:'All Alerts'
  },
  {
      type:'Humidity Alerts'
  },
  
  {
      type:'Temperature Alerts'
  },
  {
    type:'Battery Alerts',
},
  

]

//  export const Accordion = styled((props) => (
//   <MuiAccordion disableGutters elevation={0} square {...props} />
// ))(({ theme }) => ({
//   border: `1px solid ${theme.palette.divider}`,
//   "&:not(:last-child)": {
//     borderBottom: 0,
//   },
//   "&:before": {
//     display: "none",
//   },
// }));

//  const  AccordionSummary= styled((props) => (
//   <MuiAccordionSummary
//     expandIcon={
//     <ArrowForwardIosSharpIcon 
     
//       sx={{ fontSize: "1rem", margin:'auto', }} />}
//     {...props}
//   />
// ))(({ theme }) => ({
//   backgroundColor:
//     theme.palette.mode === "dark"
//       ? "#000"
//       : "#000",
//   flexDirection: "row-reverse",
//   "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
//     transform: "rotate(90deg)",
//   },
//   "& .MuiAccordionSummary-content": {
//     marginLeft: theme.spacing(1),
//   },
// }));



class MainPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchInput: "",
      currentView: "DashboardView",
      searchIn: false,
      viewChange:false,
      alertExpand:true,
      allChambersExpand:false,
      autoCompleteValue:'',
      menuItem:'All Alerts'
    };
  }


  
  getDeviceType=()=>{
    const deviceWidth = window.innerWidth;
    
    if(deviceWidth <= 700){
    this.setState({
    viewChange:true
    })
    }
    
    }



  componentDidMount() {
    const viewType = localStorage.getItem("view");
    this.setState({
      currentView: viewType || "DashboardView",
    });
    this.getDeviceType();
  }


  
  

  checkViolationsOrWarning =(dev, type)=>{
    const isVioOrWar= dev.violations && dev.violations[type] && dev.violations[type] !== 0  || 
    dev.warnings && dev.warnings[type] && dev.warnings[type] !== 0
    return isVioOrWar;
  }

  getAlertAssets = (assetsWithGroups, autoCompleteValue,menuItem ) => {
    // const{searchIn}= this.state;
  // console.log('autoCompleteValue', autoCompleteValue, assetsWithGroups, menuItem)
  // console.log('Assetsgroup',assetsWithGroups )
    const alertAssets = [];
   
    if(autoCompleteValue.length > 1){
      assetsWithGroups.forEach((ag) => {
        if (ag.assets && ag.assets.length) {
          ag.assets.forEach((asset) => {
          
            if(ag.assetGroupId ===  autoCompleteValue && menuItem === "All Alerts"){
              if (asset.violated || asset.warning) {
                alertAssets.push(asset);
              }
            
           
          }
          //if warehouse is filter &&  humidity violation filter is also applied
          if(ag.assetGroupId ===  autoCompleteValue && menuItem === "Humidity Alerts"){
            let filteredAssets=[];
            if (asset.violated || asset.warning) {
              filteredAssets=ag.assets.filter((ast)=>{
                if(ast.devices && ast.devices.length){
  
                  const isVio=ast.devices.some((dev)=>
                  this.checkViolationsOrWarning(dev,'humidity')
                 
                  )
                
                  return isVio;
                }
              })
              alertAssets.push(...filteredAssets);
              
              
            }
          }
           //if warehouse is filter &&  temp violation filter is also applied
          if(ag.assetGroupId ===  autoCompleteValue && menuItem === "Temperature Alerts"){
            let filteredAssets=[];
            if (asset.violated || asset.warning) {
              filteredAssets=ag.assets.filter((ast)=>{
                if(ast.devices && ast.devices.length){
  
                  const isVio=ast.devices.some((dev)=>
                  this.checkViolationsOrWarning(dev,'temperature')
                 
                  )
                
                  return isVio;
                }
              })
              alertAssets.push(...filteredAssets);
             
              
            }
          }
         //if warehouse is filter &&  temp battery filter is also applied
          if(ag.assetGroupId ===  autoCompleteValue && menuItem === "Battery Alerts"){
            let filteredAssets=[];
            if (asset.violated || asset.warning) {
              filteredAssets=ag.assets.filter((ast)=>{
                if(ast.devices && ast.devices.length){
  
                  const isVio=ast.devices.some((dev)=>
                  this.checkViolationsOrWarning(dev,'battery')
                 
                  )
                
                  return isVio;
                }
              })
              alertAssets.push(...filteredAssets);
      
            }
          }

            
          });
        }
      }); 
    
    }
    else{
 
      if(menuItem === "All Alerts"){
        assetsWithGroups.forEach((ag) => {
          if (ag.assets && ag.assets.length) {
            ag.assets.forEach((asset) => {
              if (asset.violated || asset.warning) {
                alertAssets.push(asset);
              }
            });
          }
        });
      }
      

      if(menuItem === "Humidity Alerts"){
        assetsWithGroups && assetsWithGroups.map((ag)=>{
          let filteredAssets = []
          if(ag.assets && ag.assets.length){
            filteredAssets=ag.assets.filter((ast)=>{
              if(ast.devices && ast.devices.length){

                const isVio=ast.devices.some((dev)=>
                this.checkViolationsOrWarning(dev,'humidity')
               
                )
              
                return isVio;
              }
            })
        
            alertAssets.push(...filteredAssets);
          }
        })
      }

      if(menuItem === "Temperature Alerts"){
        assetsWithGroups && assetsWithGroups.map((ag)=>{
          let filteredAssets = []
          if(ag.assets && ag.assets.length){
            filteredAssets=ag.assets.filter((ast)=>{
              if(ast.devices && ast.devices.length){

                const isVio=ast.devices.some((dev)=>
                this.checkViolationsOrWarning(dev,'temperature')
                )
              
                return isVio;
              }
            })
        
            alertAssets.push(...filteredAssets);
          }
        })
      }

      if(menuItem === "Battery Alerts"){
        assetsWithGroups && assetsWithGroups.map((ag)=>{
          let filteredAssets = []
          if(ag.assets && ag.assets.length){
            filteredAssets=ag.assets.filter((ast)=>{
              if(ast.devices && ast.devices.length){

                const isVio=ast.devices.some((dev)=>
                this.checkViolationsOrWarning(dev,'battery')
                // dev.violations && dev.violations.battery && dev.violations.battery !== 0  || 
                //     dev.warnings && dev.warnings.battery && dev.warnings.battery !== 0
                )
               
                return isVio;
              }
            })
        
            alertAssets.push(...filteredAssets);
          }
        })
      }

      
    }
   
    return alertAssets;
  };

  
 


  getAllAssets = (assetsWithGroups) => {
    const allAssets = [];
    assetsWithGroups.forEach((ag) => {
      ag.assets.forEach((asset) => {
        allAssets.push(asset);
      });
    });
    return allAssets;
  };

  getAssetSuggestions = (allAssets, suggestions) => {
    if (!allAssets || !allAssets.length) return [];
    const assetSuggestions = [];
    allAssets.forEach((asset) => {
      suggestions.forEach((suggestedAsset) => {
        if (suggestedAsset.uid === asset.uid) {
          assetSuggestions.push(asset);
        }
      });
    });

    return assetSuggestions;
  };

  getAssetGroupSuggestions = (
    assetsWithGroups1
    // suggestions, searchMode, filterBy
  ) => {
    let assetGroupSuggestions = [];
 
    const { searchInput, searchIn } = this.state;
    // const {assetsWithGroups} = this.props;
    // const assetgroupref=[...assetsWithGroups1]
    const { alertedAssets } = this.props;

    if (searchIn && searchInput.length > 1) {
      const allassetsGroups = assetsWithGroups1
        .map((assestgp) => {
          if (
            assestgp.assetGroupId
              .toLowerCase()
              .includes(searchInput.toLowerCase())
          ) {
            // this solves,
            // warehouse doesn't show all chambers after we filter by chamber - as we are setting assets to particular searched asset below
            const alertAsset = alertedAssets.find((a) =>
              a.assetGroupId
                .toLowerCase()
                .includes(assestgp.assetGroupId.toLowerCase())
            );
            assestgp.assets = alertAsset.assets;
            assetGroupSuggestions = assestgp;
            return assetGroupSuggestions;
          }

          const filterassets =
            assestgp.assets &&
            assestgp.assets.filter((a) =>
              a.id.toLowerCase().includes(searchInput.toLowerCase())
            );
          if (filterassets && filterassets.length) {
            //take the reference of assetgp and add filteredasset to that assetgp object and return that value.
            const assestgpCopy = { ...assestgp };

            assestgpCopy.assets = filterassets;
            return assestgpCopy;
          }

          return null;
        })
        .filter((m) => m);

      assetGroupSuggestions = allassetsGroups;
      return assetGroupSuggestions;
    }
    if (searchInput.length === 0) {
      assetGroupSuggestions = alertedAssets;
    }

    // assetGroupSuggestions = assetsWithGroups1;
    return assetGroupSuggestions;
  };

  getDataForSearchbar = (assetsWithGroups, allAssets, filterBy) => {
    if (filterBy === "assetGroupId") {
      return assetsWithGroups;
    }

    return allAssets;
  };

    handleSearchInput = (event) => {
    const value = event.target.value;
    this.setState({
      searchInput: value,
      searchIn: true,
    });
  };

  toggleView = (view) => {
    localStorage.setItem("view", view);
    if (view === "DashboardView") {
      this.setState({
        currentView: "DashboardView",
      });
    } else {
      this.setState({
        currentView: "DeviceView",
      });
    }
    // localStorage.setItem('view', view);
  };


  handleautoComplete =(e)=>{
    
   this.setState({
      autoCompleteValue:e?.target?.outerText || ""
    })

  }

  handleExpandChangeforAlert = ()=> {
    const {alertExpand, allChambersExpand}=this.state;
    this.setState({
      alertExpand:!alertExpand,
          menuItem:'All Alerts',
          autoCompleteValue:'',
          allChambersExpand:alertExpand && !allChambersExpand? true : allChambersExpand
        })
  };

  handleExpandChangeforAllChambers=()=>{
    const {allChambersExpand,alertExpand }=this.state;
    this.setState({  
      allChambersExpand:!allChambersExpand,
      searchInput:'',
      alertExpand:!alertExpand && allChambersExpand ? true : alertExpand
         
        })
  }

  onFilterTypeChanged=(e)=>{
    this.setState({
      menuItem:e.target.value
    })
  }

  render() {
    const {
      classes,
      onDashboardStateChange,
      onClickChamber,
      apiUrl,
      filterKey,
      // placeholder,
      onValueChange,
      needToFilter,
      filterBy,
      filterItems,
      onFilterChange,
      suggestions,
      assetsWithGroups,
      companySettings,
      warehouseType,
      chamberType,
      alertedAssets,
     
    } = this.props;
    const { searchInput, searchIn, viewChange, autoCompleteValue,menuItem, allChambersExpand, alertExpand } = this.state;

    // const {  currentView } = this.state;

    // const allAssets = this.getAllAssets(assetsWithGroups);
    let alertAssets = this.getAlertAssets(alertedAssets, autoCompleteValue, menuItem);
    const autoCompleteOptions= alertAssets.map((ch)=>ch.assetGroupId)
    let uniqueAlertedAssets = autoCompleteOptions.filter((c, index) => {
      return autoCompleteOptions.indexOf(c) === index;
  });
  
  

    // const dataForSearchbar = this.getDataForSearchbar(assetsWithGroups, allAssets, filterBy);
    const assetGroupSuggestions = this.getAssetGroupSuggestions(
      assetsWithGroups,
      suggestions,
      searchIn,
      filterBy
    );
   
  
    return (
      <>
        {
          <>
          
          <Grid  style={{marginTop:'2rem',}}>
       
     
            {/* Alerted Chambers */}
              <Accordion
        expanded={alertExpand}
        
          style={{marginBottom:'1rem'}}
      >
        <AccordionSummary
        style={{ backgroundColor: "#fff",cursor:'default'}}
       
        >
          <Grid container >

            <Grid item
             xl={7}
             lg={6}
             md={alertExpand ? 4 :10}
             sm={0}
             xs={12} 
             style={{display:'flex', justifyContent:'left',  }}
             >
              {alertExpand ? 
              ( 
               
              <ExpandMoreIcon style={{fontSize:'2rem', cursor:'pointer'}}  onClick={()=>{this.handleExpandChangeforAlert()}} />
             
              )
              :    
              <Tooltip title= { chamberType && chamberType ? "Click to view Alerted " +  chamberType  :" Click to view Alerted Chambers" } >    
              <ExpandMoreIcon style={{fontSize:'2rem' , transform: 'rotate(-90deg)', cursor:'pointer'}}  onClick={()=>{this.handleExpandChangeforAlert()}} />
              </Tooltip>
              }
          
            <Typography   
            variant="h5"
            component="div"
            sx={{
              color: "#000",
              fontFamily: "Montserrat",
              fontSize: "25px",
              fontWeight: 700,
             
            }}
            className={classes.violation}
            gutterBottom
          >
         Alerted {chamberType ? chamberType : "Chamber"}
          </Typography>
            </Grid>
           {
            alertExpand ? (
              <>
               <Grid item 
            xl={2}
            lg={2}
            md={alertExpand ? 4 :1}
            sm={6}
            xs={12}
            style={{display:'flex', justifyContent:'right', margin:'auto'}}>
            <FormControl >
        <Select
        variant='standard'
          className={classes.selectText}
          disableUnderline
          value={menuItem}
          onChange={this.onFilterTypeChanged}
          
        >
            
       {menuItems.map(({ type }) => (
         <MenuItem key={type} value={type} >
           <div
          className={classes.menuItem}
                           
          >
                              
            <span >{type}</span>
             </div>
             </MenuItem>
                          
             ))}
                       
        </Select>

            </FormControl>
            </Grid>
            <Grid item
             xl={3} 
             lg={4}
              md={alertExpand ? 4 :1} 
              sm={5} 
              xs={12} 
              style={{display:'flex', justifyContent:'center', margin:'auto'}}>
            <Autocomplete
                 disablePortal
     
              getOptionLabel={(option) => option}
              id="autoCompleteOptions"
               options={uniqueAlertedAssets}
            
               className={classes.autocompleteTextfield}
             
               sx={{ width: 250,  }}
               value={autoCompleteValue}
               onChange={(e) => {
                //  console.log(e?.target?.outerText || "");
                 this.setState({
                   autoCompleteValue:e?.target?.outerText || ""
                })
        
              }}
              //  onChange={(e)=>this.handleautoComplete(e)}
               renderInput={(params) => 
               <TextField 
               {...params} 
                variant="standard"
                //  value={autoCompleteValue}
                 // onChange={(e) => {
                 //   console.log('WH ', e.target.value)
            
                  //     this.setState({       
                  //       autoCompleteValue:e.target.value
                  //     })
    
        
                  // }}
                   InputProps={{
                   ...params.InputProps,
                  disableUnderline: true,
                    }}
                 placeholder= {warehouseType ? "Search by "  + warehouseType + " Id" : "Search by Warehouse Id"} 
                
                  />}
                  /> 
              </Grid>
              </>
            ):
            (
              " "
            )
           }
           
         
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
      
        {alertAssets && alertAssets.length ? (
                <div style={{height:'60vh', overflow:'auto'}}>
                  {/* <div className={classes.heading}>
                    <Typography
                      variant="h5"
                      sx={{
                        color: "#000",
                        fontFamily: "Montserrat",
                        fontSize: "34px",
                        fontWeight: 700,
                      }}
                      className={classes.violation}
                      gutterBottom
                    >
                      Alerted {chamberType ? chamberType : "Chamber"}
                    </Typography>
                  </div> */}

                  <Paper elevation={0} className={classes.chamber_outerDiv}>
                    <div  className={classes.cardDiv}>
                      
                      <Grid container spacing={2}>
                        {/* spacing=5 */}
                        {alertAssets.map((asset) => (
                          <Grid
                            item
                            md={6}
                            sm={viewChange ? 12 :6}
                            xs={12}
                            lg={4}
                            style={{ width: "500px" }}
                            key={asset.uid}
                          >
                            <ChamberCard
                              warehouse={asset.assetGroupId}
                              chamberName={asset.id}
                              warehouseName={asset.assetGroupId}
                              maxTemp={
                                asset.temperatureSettings &&
                                asset.temperatureSettings.hasOwnProperty(
                                  "maxViolation"
                                )
                                  ? asset.temperatureSettings.maxViolation
                                  : null
                              }
                              minTemp={
                                asset.temperatureSettings &&
                                asset.temperatureSettings.hasOwnProperty(
                                  "minViolation"
                                )
                                  ? asset.temperatureSettings.minViolation
                                  : null
                              }
                              maxHumidity={
                                asset.humiditySettings &&
                                asset.humiditySettings.hasOwnProperty(
                                  "maxViolation"
                                )
                                  ? asset.humiditySettings.maxViolation
                                  : null
                              }
                              minHumidity={
                                asset.humiditySettings &&
                                asset.humiditySettings.hasOwnProperty(
                                  "minViolation"
                                )
                                  ? asset.humiditySettings.minViolation
                                  : null
                              }
                              devices={asset.devices}
                              violated={asset.violated}
                              warning={asset.warning}
                              chamberUid={asset.uid}
                              onDashboardStateChange={onDashboardStateChange}
                              onClickChamber={onClickChamber}
                              companySettings={companySettings}
                              warehouseType={warehouseType}
                              chamberType={chamberType}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </div>
                  </Paper>
                </div>
              ) : (
                <Paper elevation={0} className={classes.emptyPaper}>
                {
                  <h4 className={classes.emptyHeading}>
                    {" "}
                    No {chamberType ? chamberType : "Chamber"} found 
                  </h4>
                }
              </Paper>
              )}
        </AccordionDetails>
      </Accordion>

        {/* All Chambers */}
        <Accordion
        expanded={allChambersExpand}
      >
        <AccordionSummary 
        style={{ backgroundColor: "#fff", cursor:'default'}}
        >
          
          <Grid container >
            <Grid item
                  xl={8}
                  lg={6}
                  md={6}
                    sm={12}
                    xs={12}
                    style={{justifyContent:'left'}}
                    className={classes.heading} >
                    
                    {
                      allChambersExpand ? 
                      
                      <ExpandMoreIcon  style={{fontSize:'2rem', marginBottom:'0.5rem', cursor:'pointer'}}  onClick={()=>{this.handleExpandChangeforAllChambers()}} />
                      
                      :
                    <Tooltip      
                    title={ warehouseType && warehouseType ? "Click to view All " +  warehouseType  :" Click to view All Warehouses"  }  > 
                     <ExpandMoreIcon style={{fontSize:'2rem', transform: 'rotate(-90deg)', marginBottom:'0.5rem', cursor:'pointer'}}  onClick={()=>{this.handleExpandChangeforAllChambers()}} />
                     </Tooltip>
                    }
                    
          
                 
            <Typography
              variant="h5"
              component="div"
              sx={{
              color: "#000",
              fontFamily: "Montserrat",
              fontSize: "25px",
              fontWeight: 700,
              // margin:'auto',
             
              
             
            }}
            gutterBottom
          >
          {warehouseType ? warehouseType : "Warehouse"}
          </Typography>

            </Grid>
            {
              allChambersExpand ? (
                <>
                <Grid item 
                    xl={4}
                    lg={6}
                    md={6} 
                    sm={12}
                    xs={12}
                    style={{display:'flex', justifyContent:'flex-end'}}
                    className={classes.searchBar}>
                       <SearchInput
                      handleSearchInput={this.handleSearchInput}
                      apiUrl={apiUrl}
                     
                      // data={dataForSearchbar}
                      filterKey={filterKey}
                      warehouseType={warehouseType}
                      chamberType={chamberType}
                      // placeholder={placeholder}
                      onValueChange={onValueChange}
                      needToFilter={needToFilter}
                      filterBy={filterBy}
                      onFilterChange={onFilterChange}
                      filterItems={filterItems}
                      searchInput={searchInput}
                    />
                     <GlobalDownload 
                assetsGroup={assetGroupSuggestions}
                warehouseType={warehouseType}
                chamberType={chamberType}
                />
                    </Grid>
                </>
              ):
              " "
            }
           
    

          </Grid>
            
         
         
        </AccordionSummary>
        <AccordionDetails>
        
                <div className={classes.cardDiv}>
                  <Grid container spacing={0} style={{ display: "block", height:'60vh', overflow:'auto' }}>
                    {assetGroupSuggestions && assetGroupSuggestions.length ? (
                      assetGroupSuggestions.map((ag) => (  
                        <Paper
                          elevation={0}
                          className={classes.chamber_outerDiv}
                          key={ag.assetGroupUid}
                        >
                          <div className={classes.assetGroupContainer}>
                          
                            <h3 className={classes.assetGroupHeader}>
                              {" "}
                              {/* Warehouse: */}
                              {warehouseType ? warehouseType : "Warehouse"}
                              {" : "}
                              {ag.assetGroupId}{" "}
                            
                            </h3>
                           
                            <Grid container spacing={5}>
                              {/* spacing=5 */}

                              {ag.assets && ag.assets.length ? (
                                ag.assets.map((asset) => (
                                  <Grid
                                    item
                                    md={6}
                                    sm={viewChange ? 12: 6}
                                    xs={12}
                                    lg={4}
                                    key={`allAssets${asset.uid}`}
                                  >
                                    <ChamberCard
                                      warehouse={asset.assetGroupId}
                                      chamberName={asset.id}
                                      chamberUid={asset.uid}
                                      maxTemp={
                                        asset.temperatureSettings &&
                                        asset.temperatureSettings.hasOwnProperty(
                                          "maxViolation"
                                        )
                                          ? asset.temperatureSettings
                                              .maxViolation
                                          : null
                                      }
                                      minTemp={
                                        asset.temperatureSettings &&
                                        asset.temperatureSettings.hasOwnProperty(
                                          "minViolation"
                                        )
                                          ? asset.temperatureSettings
                                              .minViolation
                                          : null
                                      }
                                      maxHumidity={
                                        asset.humiditySettings &&
                                        asset.humiditySettings.hasOwnProperty(
                                          "maxViolation"
                                        )
                                          ? asset.humiditySettings.maxViolation
                                          : null
                                      }
                                      minHumidity={
                                        asset.humiditySettings &&
                                        asset.humiditySettings.hasOwnProperty(
                                          "minViolation"
                                        )
                                          ? asset.humiditySettings.minViolation
                                          : null
                                      }
                                      devices={asset.devices}
                                      onDashboardStateChange={
                                        onDashboardStateChange
                                      }
                                      violated={asset.violated}
                                      warning={asset.warning}
                                      onClickChamber={onClickChamber}
                                      companySettings={companySettings}
                                      warehouseType={warehouseType}
                                      chamberType={chamberType}
                                    />
                                  </Grid>
                                ))
                              ) : (
                                <p style={{ marginLeft: "50%" }}>
                                  {" "}
                                  No {chamberType ? chamberType : "Chambers"}
                                </p>
                              )}
                            </Grid>
                          </div>
                        </Paper>
                        
                      ))
                    ) : (
                      <Paper elevation={0} className={classes.emptyPaper}>
                        {
                          <h4 className={classes.emptyHeading}>
                            {" "}
                            No Warehouse/Chamber found
                          </h4>
                        }
                        {/* {(filterBy === ('assetGroupId' || 'assetId'))
                              ? <h4 className={classes.emptyHeading}> No {warehouseType ? warehouseType : 'Warehouse'} found </h4>
                              : <h4 className={classes.emptyHeading}> No {chamberType ? chamberType : 'Chamber'} found </h4>} */}
                      </Paper>
                    )}
                  </Grid>
                  </div>
        </AccordionDetails>
      </Accordion>

          </Grid>
          </>
        }
      </>
    );
  }
}

MainPage.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  assetsWithGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
  // onDashboardStateChange: PropTypes.func.isRequired,
  onClickChamber: PropTypes.func.isRequired,
  apiUrl: PropTypes.string,
  filterKey: PropTypes.string,
  filterItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  placeholder: PropTypes.string,
  onValueChange: PropTypes.func.isRequired,
  needToFilter: PropTypes.bool,
  filterBy: PropTypes.string,
  onFilterChange: PropTypes.func.isRequired,
  suggestions: PropTypes.instanceOf(Object).isRequired,
};

MainPage.defaultProps = {
  apiUrl: "",
  filterKey: "",
  placeholder: "Search",
  needToFilter: true,
  filterBy: "",
  filterItems: [],
};

export default withStyles(styles)(MainPage);