import React from 'react';
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import InputForChamber from 'components/InputForChamber';

// import { useState } from 'react';


const styles = {
  textStyle: {
    // margin: 'auto',
    textAlign: 'left',
    fontfamily: 'Montserrat',
    fontsize: '14px',
  },
  subHeading: {
    padding: '1.2rem',
    fontWeight: 'bold',
    fontfamily: 'Montserrat',
    fontsize: '14px',
  },


};

const TemperatureSettings = (props) => {

  const {
    classes, defaultValueMinViolation, defaultValueMaxViolation, defaultValueMinWarning, defaultValueMaxWarning,
  } = props;

 

return(
    <>
      <Grid container className={classes.subHeading}>
        <Grid item lg={6} md={6} sm={6} xs={6}>
Violation Temperature
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={6}>
Warning Temperature
        </Grid>
      </Grid>

      <Grid container spacing={2} item xs={12}>
        <Grid container item xs={6}>

          <Grid item lg={5} md={5} sm={5} xs={12} 
          sx={{margin:'auto' , 
           textAlign: 'left',
            fontfamily: 'Montserrat',
           fontsize: '14px'}} 
           >
             Minimum Threshold
              </Grid>
          <Grid item lg={5} md={5} sm={5} xs={12}>
            <InputForChamber
              id="temperatureMinimum"
              margin="normal"
              type="number"
              autoComplete="off"
            // onChange={ViolationTempMin}
            // inputProps={{max:warmintemp ? warmintemp-1 : null ,  }}
            // inputProps={!defaultValueMaxViolation ? {max:warmintemp ? warmintemp-1 : null ,  }  : {min: viomaxtemp ? parseInt(defaultValueMaxViolation) + 1 :null }}
              placeholder="0"
              defaultValue={
                defaultValueMinViolation
              }
            />

          </Grid>

          <Grid item xs={3} />
        </Grid>

        <Grid container item xs={6}>
          <Grid item lg={5} md={5} sm={5} xs={12}
          sx={{margin:'auto' , 
          textAlign: 'left',
           fontfamily: 'Montserrat',
          fontsize: '14px'}} 
          >Minimum Threshold</Grid>
          <Grid item lg={5} md={5} sm={5} xs={12}>
            <InputForChamber
              autoComplete="off"
              placeholder="0"
              id="temperatureWarningMinimum"
              // onChange={WarningTempMin}
              // inputProps={{min:viomintemp, max: warmaxtemp ? warmaxtemp-1 : null}}
             
              defaultValue={
              defaultValueMinWarning
             }

              margin="normal"
              type="number"
            />
      
          </Grid>
          <Grid item xs={3} />
        </Grid>
      </Grid>

     
      <Grid container spacing={2} item xs={12}>
        <Grid container item xs={6}>

          <Grid item lg={5} md={5} sm={5} xs={12}
          sx={{margin:'auto' , 
          textAlign: 'left',
           fontfamily: 'Montserrat',
          fontsize: '14px'}} 
           >Maximum Threshold</Grid>
          <Grid item lg={5} md={5} sm={5} xs={12}>
            <InputForChamber
              id="temperatureMaximum"
              // onChange={ViolationTempMax}
              autoComplete="off"
              defaultValue={defaultValueMaxViolation}
              placeholder="0"
              margin="normal"
              type="number"
              // inputProps={{min : viomintemp ? parseInt(viomintemp) + 1 : null, }}

              // inputProps={ !defaultValueMinViolation ? {min : viomintemp ? parseInt(viomintemp) + 1 : null, 
              //   max: warmaxtemp ? parseInt(warmaxtemp)+1 : null
              // } : {min:parseInt(defaultValueMinViolation) + 1 }}
              
              
            />
        
          </Grid>
          <Grid item xs={3} />
        </Grid>

        <Grid container item xs={6}>
          <Grid item lg={5} md={5} sm={5} xs={12} 
          sx={{margin:'auto' , 
          textAlign: 'left',
           fontfamily: 'Montserrat',
          fontsize: '14px'}} 
          >
            Maximum Threshold</Grid>
          <Grid item lg={5} md={5} sm={5} xs={12}>
            <InputForChamber
              id="temperatureWarningMaximum"
              defaultValue={defaultValueMaxWarning}
              // onChange={WarningTempMax}
              margin="normal"
              placeholder="0"
              type="number"
              autoComplete="off"
              // inputProps={{min:warmintemp? parseInt(warmintemp)+1 : null, max: viomaxtemp ? viomaxtemp-1 : null}}
            />

          </Grid>
          <Grid item xs={3} />
        </Grid>
      </Grid>
{/* <div>
  <span style={{color:'red', fontSize:'12px'}}>{err}</span>
</div> */}
    </>
  );
};


export default withStyles(styles)(TemperatureSettings);
