/* eslint-disable react/prop-types */
import React , {useContext} from 'react';
// import withStyles from 'react-jss';
import { withStyles } from '@mui/styles';
import ListItem from '@mui/material/ListItem';
// import ListItemText from '@mui/material/ListItemText';
// import HighlightOffIcon from '@mui/icons-material/HighlightOff';
// import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import UserContext from 'Context/UserContext';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import GppGoodIcon from '@mui/icons-material/GppGood';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
// import Button from '@mui/material/Button';
// import Divider from '@mui/material/Divider';
import moment from 'moment/moment';

const styles = {
  selectedStyles: {
    backgroundColor:'#fff',
    borderRadius: '8px',
    // height: '8rem',
    border: '1px solid #00808161',
    width: '500px',
    fontsize: '500px  !important',
    cursor: "pointer",
    '&:hover':{
      boxShadow:'1px 2px 2px 0px rgb(0 0 0 / 20%)'
    }
  },
  deselectedStyles: {
    backgroundColor:'#fff',
  
    borderRadius: '8px',
    // height: '8rem',
    border: '2px solid #008081',
    width: '500px',
    fontsize: '500px  !important',
    cursor: "pointer",
    boxShadow: "2px 7px 7px 0px rgb(0 0 0 / 20%)"
  },
  highlightOffIconstyle:{
    color: '#000', 
    position:'absolute' ,
     width: '1.5rem',
      height: '1.4rem' , 
       cursor: "pointer",
       top:'10px'
  },
  deviceIdstyle:{
    marginBottom:'14%',
    fontSize:'10px',
    opacity:'0.50',
    marginLeft:'18%',
  },
  chamberIdstyle:{
    marginBottom:'14%',
    fontSize:'10px',
    postion:'fixed',
    top:'5',
    opacity:'0.50',
    marginLeft:'18%',
  },
  warehouseIdstyle:{
    marginBottom:'14%',
    postion:'fixed',
    top:'5',
    fontSize:'10px',
    opacity:'0.50',
    marginLeft:'12%'
  },
  devicenameDiv:{
    borderLeft:'1px solid rgba(169,208,208)',
    borderRight:'1px solid rgba(169,208,208)',
    padding:'1rem'


  },
  outerDiv:{
    borderBottom:'1px solid rgba(169,208,208)',
  },
  textstyle:{
    color:'rgba(0,0,0,0.7)',
    fontSize:'14px',
    fontFamily:'Montserrat'
  },
  textSpace:{
    paddingBottom:'5px'
  }

};




function DeviceRow(props){
  const {
    classes, device, onClick, selected,
  } = props;


  const userDetails = useContext(UserContext);
  const { CompanyInfo} = userDetails;

  const getWarehouseName=()=>{
   
    const assetGroupName= device && device.assetGroupId;
    if(assetGroupName ){
        if(assetGroupName && assetGroupName.length > 19){
          return assetGroupName.slice(0,19) + '...';
        }
        if (assetGroupName && assetGroupName.length <= 19){
          return assetGroupName; 
        }
    }
    else{
      return 'N/A'
    }
    
  }

  const getChamberName=()=>{
    const assetName=device && device.assetId ;
    // console.log('assetName name ', device,assetName)
    if(assetName ){
      if(assetName && assetName.length > 19){
        return assetName.slice(0,19) + '...';
      }
      if (assetName && assetName.length <= 19 ){
        return assetName; 
      }
  }
  else{
    return 'N/A'
  }

  };

  // const getDeviceId=()=>{
  //   const DeviceId= device && device.id;
  //   if(DeviceId ){
  //     if(DeviceId && DeviceId.length > 15){
  //       return DeviceId.slice(0,15) + '...';
  //     }
  //     if (DeviceId && DeviceId.length <= 15 ){
  //       return DeviceId; 
  //     }
  // }
  // else{
  //   return 'N/A'
  // }
  // }

  const getAssetgroupName=(assetgroup)=>{

    if(assetgroup){
      if(assetgroup && assetgroup.length > 14){
        return assetgroup.slice(0,14) + '... : ';
      }
      if (assetgroup && assetgroup.length <= 14 ){
        return assetgroup + " Id :"; 
      }
    }
 
  }

  const getAssetName=(asset)=>{

    if(asset){
      if(asset && asset.length > 14){
        return asset.slice(0,14) + '... :';
      }
      if (asset && asset.length <= 14 ){
        return asset + " Id :"; 
      }
    }
 
  }

  

  // const handleDeviceStatus=(device)=>{
  //   let deviceStatus ='noViolation' ;
  //   // console.log('Device ::::::::::;;;',device)
  //  const isVio= device && device.violations &&  device.violations.temperature ||
  //  device && device.violations &&  device.violations.humidity ||
  //  device && device.violations &&  device.violations.battery ;
  //  const isWarned=device && device && device.warnings &&  device.warnings.temperature ||
  //  device && device.warnings &&  device.warnings.humidity ||
  //  device && device.warnings &&  device.warnings.battery ;
  // //  console.log('Device ',isVio, isWarned)
  //   if(isVio === 1){
  //     deviceStatus ='Violation'
  //   }
  //   if(isVio !== 1 && isWarned === 1){
  //     deviceStatus = 'Warning'
  //   }
   
  //   return deviceStatus;

  // }

  const handleDeviceStatus=(device)=>{
    let deviceStatus ='noViolation' ;
  
    const tempLastPing=Number( device && device.lastPing && device.lastPing.t1 && device.lastPing.t1[device.lastPing.t1.length - 1] );
    const humidityLastPing=Number( device && device.lastPing && device.lastPing.humidity && device.lastPing.humidity[device.lastPing.humidity.length - 1]);
    const batteryLastPing=Number(device.lastPing && device.lastPing.battery);
   
    const maxTempVio =Number(device?.assetTemperatureSettings?.maxViolation);
    const minTempVio =Number(device?.assetTemperatureSettings?.minViolation);
    const maxHumVio=Number(device?.assetHumiditySettings?.maxViolation);
    const minHumVio =Number(device?.assetHumiditySettings?.minViolation);
    const criticalBattery=Number(device?.assetBatterySettings?.critical)

    const maxTempWarning =Number(device?.assetTemperatureSettings?.maxWarning);
    const minTempWarning =Number(device?.assetTemperatureSettings?.minWarning);
    const maxHumWarning=Number(device?.assetHumiditySettings?.maxWarning);
    const minHumWarning =Number(device?.assetHumiditySettings?.minWarning);
    const warningBattery=Number(device?.assetBatterySettings?.warning)

    const isVio=tempLastPing > maxTempVio
     || tempLastPing < minTempVio
     || humidityLastPing > maxHumVio
     ||  humidityLastPing < minHumVio
     || batteryLastPing < criticalBattery
     || batteryLastPing < 10

     const isWarned = tempLastPing > maxTempWarning 
     || tempLastPing < minTempWarning
     || humidityLastPing > maxHumWarning 
     || humidityLastPing < minHumWarning  
     || batteryLastPing < warningBattery 
     || batteryLastPing < 25 && batteryLastPing >= 10

     if(isVio){
      deviceStatus ='Violation'
    }
    if(!isVio && isWarned){
      deviceStatus = 'Warning'
    }
   
    return deviceStatus;

  }

  const getTooltipTitle=(props)=>{
    const { lastUpdate}=props;
return (
  <div >
  <div>Paired</div>
  <div>Last updated : 
   <span > {lastUpdate &&  moment().unix() -  Number(lastUpdate) <= 864000 ?
     moment.unix(Number(lastUpdate)).fromNow() : "N/A" }</span>
     </div>
  </div>
)
  }
  
  return(
    <div>
 <ListItem className={(selected === true) ? classes.deselectedStyles : classes.selectedStyles}  key={device.id || device.IMEI} onClick={(e) => { onClick(device, e); }}>

<Grid container>
{device && !device.paired ? 
<>
<Grid container>
  <Grid item xs={2} style={{margin:'auto', }}>
    <IconButton style={{backgroundColor:'transparent',padding:0}}>
      <Tooltip title="Unpaired">
      <RadioButtonUncheckedIcon  sx={{color:'rgb(0,0,0,0.5)' , width:'2rem', height:'2rem',}} />
      </Tooltip>
    </IconButton>
  
  </Grid>
  <Grid item xs={8} className={classes.devicenameDiv}>
  <Typography variant='p' sx={{margin:'auto', fontSize:'14px', fontFamily:'Montserrat', color:'rgba(0,0,0,0.6)'}}>
  <span style={{fontSize:'14px',color:'rgba(0,0,0,0.5)'}}>Device Id :</span>{device.id}
    </Typography>
</Grid>
<Grid item xs={2} style={{textAlign:'center'}}>
<img src="../../img/battery.svg" alt="empty" style={{width:'3rem',height:'2rem',}}   />
<Typography variant='p' sx={{fontSize:'14px',color:'rgba(0,0,0,0.7)'}}>
  {device?.lastPing?.battery ? Math.round(device.lastPing.battery)+ "%":"N/A"}
</Typography>
</Grid>
</Grid>
</>

:
<>
<Grid container className={classes.outerDiv}>
   <Grid item xs={2} >
    {
      handleDeviceStatus(device) === "Violation" ?
     <Tooltip title={getTooltipTitle({lastUpdate:device.lastPing && device.lastPing.timeStamp })}>
      <GppMaybeIcon  sx={{ color: "#af4f4f", width: "40px", height: "40px" }} />
     </Tooltip> 
    
       :
       handleDeviceStatus(device) === "Warning" ? 
       <Tooltip title={getTooltipTitle({lastUpdate:device.lastPing && device.lastPing.timeStamp })}>
       <GppMaybeIcon  sx={{ color: "#dcac1980", width: "40px", height: "40px" }} /> 
       </Tooltip>
       : 
       <Tooltip title={getTooltipTitle({lastUpdate:device.lastPing && device.lastPing.timeStamp })}>
          <GppGoodIcon  sx={{ color: "#008081a1", width: "40px", height: "40px" }} /> 
         </Tooltip>
    }
    
    </Grid>
   <Grid item xs={8} className={classes.devicenameDiv}>
   <Typography variant='p' sx={{ fontSize:'14px', fontFamily:'Montserrat', color:'rgba(0,0,0,0.5)' ,pb:'5px'}}>
  Device Id : 
<span className={classes.textstyle}>{ device && device.id}</span></Typography>
    </Grid>
   <Grid item  xs={2} style={{textAlign:'center'}}>
   <img src="../../img/battery.svg" alt="empty" style={{width:'3rem',height:'2rem',}}   />
<Typography variant='p' sx={{fontSize:'13px',color:'rgba(0,0,0,0.7)'}}>
  {device?.lastPing?.battery ? (device.lastPing.battery)+ "%":"N/A"}
</Typography>
   </Grid>
</Grid>
<Grid container style={{padding:'0.5rem'}}>
 
   <Grid item xs={12} className={classes.textSpace}>
   <Typography variant='p'sx={{ fontSize:'14px', fontFamily:'Montserrat', color:'rgba(0,0,0,0.5)',pb:'5px'}} >
    <Tooltip title={CompanyInfo && CompanyInfo.settings && 
          CompanyInfo.settings.warehouseType ? CompanyInfo.settings.warehouseType  + " Id": "Warehouse Id "}>
   <span>{ CompanyInfo && CompanyInfo.settings && 
          CompanyInfo.settings.warehouseType 
          ? getAssetgroupName( CompanyInfo.settings.warehouseType)
          : 'Warehouse Id : ' }</span></Tooltip>
          <Tooltip title={device && device.assetGroupId}>
   <span className={classes.textstyle}> 
   {getWarehouseName()}
   </span>
   </Tooltip>
   </Typography>
  </Grid>
   <Grid item xs={12} className={classes.textSpace}>
   <Typography variant='p' sx={{ fontSize:'14px', fontFamily:'Montserrat', color:'rgba(0,0,0,0.5)',pb:'5px'}}>
   <Tooltip title={
    CompanyInfo && CompanyInfo.settings && 
    CompanyInfo.settings.chamberType ?
    CompanyInfo.settings.chamberType + " Id" : "Chamber Id"
   }>
   <span>{ CompanyInfo && CompanyInfo.settings && 
          CompanyInfo.settings.chamberType 
          ? getAssetName( CompanyInfo.settings.chamberType)  
          : 'Chamber Id : ' }</span>
           </Tooltip>
           <Tooltip title={device && device.assetId}>
   <span className={classes.textstyle}>
    {getChamberName()}
    </span>
    </Tooltip>
   </Typography>
  </Grid>

</Grid>

</>
}
</Grid>

</ListItem>
    </div>
  )
}



export default withStyles(styles)(DeviceRow);
// divider="true" button dense



{/* <Grid container >

  <Grid container item xs={12} >

    <Grid item xs={4} style={{textAlign:'center',borderRight:'1px solid #BCBCB9',paddingRight:'0.3em'}} >

    <Typography 

     variant="p"

     sx={{

      fontSize: "14px",

      fontFamily: "Montserrat",

      // fontWeight: "bold",

      // textAlign:'center',

      overflowWrap: 'break-word'

    }}

        component="span" >

          <div className={classes.warehouseIdstyle}> { CompanyInfo && CompanyInfo.settings && 
          CompanyInfo.settings.warehouseType 
          ? getAssetgroupName( CompanyInfo.settings.warehouseType)
          : 'Warehouse ID' }</div>

      <div style={{wordBreak:'break-all'}}>
      {getWarehouseName()}
      </div>

          </Typography>

    </Grid>

    

    <Grid item xs={4} style={{textAlign:'center',borderRight:'1px solid #BCBCB9 ',paddingRight:'0.8em'}} >

    <Typography 

     variant="p"

     sx={{

      fontSize: "14px",

      fontFamily: "Montserrat",

      

      // textAlign:'center'

    }}

        component="span" >

          <div className={classes.chamberIdstyle}  >
          { CompanyInfo && CompanyInfo.settings && 
          CompanyInfo.settings.chamberType 
          ? getAssetName( CompanyInfo.settings.chamberType) 
          : 'Chamber ID' }
            
            </div>

          <div style={{wordBreak:'break-all'}}>
          {getChamberName()}
          </div>

          </Typography>

    </Grid>

    <Grid item xs={3.5} style={{textAlign:'center'}} >

    <Typography 

     variant="p"

     sx={{

      fontSize: "14px",

      fontFamily: "Montserrat",

     

      // textAlign:'center'

    }}

        component="span" >

          <div  className={classes.deviceIdstyle}  >Device ID</div>

          <Typography variant="p" component="span" 

      sx={{

        fontSize: "14px",

        fontFamily: "Montserrat",
        textAlign:'center'

      }}

       >

<div style={{wordBreak:'break-all'}}>
        {getDeviceId()}
        </div>
          

      </Typography>

          </Typography>

    </Grid>

    <Grid item xs={0.5} >

    {selected ? (

  <HighlightOffIcon  className={classes.highlightOffIconstyle}  />

) : ''}



    </Grid>

  </Grid>

</Grid> */}