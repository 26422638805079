/* eslint-disable react/prop-types */
import React from "react";
import { Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledSecondaryButton = styled(Button)(() => ({
  // color: theme.palette.secondary.main,
  // transition: "0.1s ease-out",
  textTransform: "capitalize",
  fontSize: "1em",
  borderRadius: "18px",
  border: "3px solid #008081",
  color:'#008081',
  padding: "5px 1rem",
  "&:hover": {
    backgroundImage: "linear-gradient(to bottom, #02ACAE 50%, #008081 100%)",
    color: "#fff",
    border: "3px solid transparent",
    borderColor: "#fff",
  },
}));

function SecondaryButton({
  onClick, disabled, text, type, icon, ...props
}) {
  return (
    <StyledSecondaryButton
      {...props}
      onClick={onClick}
      variant="outlined"
      disabled={disabled}
      type={type}
      startIcon={icon}
    >
      <Typography variant="p" component="span" sx={{ fontWeight: "bold" }}>
        {text}
      </Typography>
    </StyledSecondaryButton>
  );
}

export default SecondaryButton;