import { withStyles } from "@mui/styles";
import React, {useState} from "react";
import Grid from '@mui/material/Grid';
import FAQ from "./faq";
// import Slider from "react-slick";
// import { Typography } from "@mui/material";
import Carosel from "./Carosel";
import { useEffect } from "react";
import FAQsearch from "components/FAQsearch";
// import "~slick-carousel/slick/slick.css"; 
// import "~slick-carousel/slick/slick-theme.css";
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
// import { IconButton, Typography } from "@mui/material";
import {Typography } from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useMediaQuery, createTheme } from '@material-ui/core';
import videos from "./videos";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Zoom from '@mui/material/Zoom';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton,} from "@mui/material";
import MailIcon from '@mui/icons-material/Mail';
// import PhoneIcon from '@mui/icons-material/Phone';

const styles={
    faqDiv:{
        backgroundColor:'#C9E9E9',
        // overflow:'hidden'
    },
    queDiv:{
        marginTop:'0.8rem',
        fontSize:'14px',
      
        
    },
    queDiv1:{
        marginTop:'0.8rem',
        fontSize:'14px',
        color:'#008081',
        fontWeight:'bold',
        
    },
    videoOuterDiv:{
        marginTop:'1rem'
    },
    iframeDiv:{
        borderRadius:'8px', 
        width:'20rem', 
        height:'12rem',
        cursor:'pointer',
      border:'1px solid rgba(0,0,0,0.3)',
      "&:hover": {
        boxShadow: "10px 10px 5px -3px rgba(0,0,0,0.2)",
      },
    },
    ansDiv:{
        padding:'1rem',
         backgroundColor:'#fff',
          width:'90%',
           height:'14rem',
            borderRadius:'22px',
             textAlign:'left', 
             fontSize:'0.8rem',
             overflow:'auto',

    },
    newline :{
        whiteSpace: "pre-line",
        
      },
      iframeDiv1:{
        borderRadius:'8px', 
        width:'100%', 
        height:'100%',

    },
    dialogTitle:{
        display:'flex', 
        justifyContent:'space-between'
    },
    icon:{
        cursor:'pointer'
    }
}




const Document=(props)=>{
    const {classes}=props;
    // const [answer, setAnswer]= useState({ans:'The answer of que 1 is....' , id:1})
    // const [count, setCount]=useState(0)
    const [que, setQue]= useState([]);
    
    const [expanded, setExpanded] = useState(false);
    const [seeMore, setSeeMore]= useState(false);
    const [searchValue, setSearchValue]=useState("");
    // const [lowerLimit, setLowerLimit]=useState(0);
    // const [upperLimit, setUpperLimit]=useState(5);
 const [openDialog, setOpenDialog]=useState(false);
    const [videoDetails, setVideoDetails]= useState({});

    const theme = createTheme({
        breakpoints: {
          values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
          },
        },
      });
      
    //    const showImg = useMediaQuery(theme.breakpoints.up('md'));
       const mdBreakPoint= useMediaQuery(theme.breakpoints.up('md'));
    


  //  useEffect(()=>{
  //   const first10questions= FAQ && FAQ.slice(0, 8)
  //    setQue(first10questions)
  //    setQueCopy(FAQ)
  //   },[])

    useEffect(()=>{
      const filterfirst10Que= !seeMore ? FAQ && FAQ.slice(0, 10) : FAQ ;
      setQue(filterfirst10Que)
    
    },[seeMore])

  //  const onSearchChange=(e)=>{
  //   // console.log('Search value', e.target.value)
  //    const searchInput= e.target.value;
    
  //   setSearchValue(searchInput)
  //   if(searchInput && searchInput.length){
  //       // const filterQue=que.filter((q)=>q.question.toLowerCase().includes((searchInput).toLowerCase()))
  //       const filterQue = que && que.filter((q) => (searchInput 
  //           ? (q.question || ' ').toLowerCase().includes(searchInput.toLowerCase()) 
  //         : true
  //         ));
  //       setQue(filterQue)
  //   }else{
  //       setQue(queCopy)
  //   }
  //  } 

   const questionsList=que && que.filter((q) => (searchValue 
    ? (q.question || ' ').toLowerCase().includes(searchValue.toLowerCase()) 
  : true
  ));

const handleChange = (panel) => (event, isExpanded) => {
    
    setExpanded(isExpanded ? panel : false);
  };

   const handleOpenDialogClick=(e,params)=>{
   
        setOpenDialog(true);
        setVideoDetails(params)
        // const vidId=videoId;

      }

      const handleCloseDialogClick=()=>{
        setOpenDialog(false);
      }

      const handleSeeMore=()=>{
        setSeeMore(!seeMore);
      }

    return (
      <>
    <div style={{marginTop:'2rem'}}>
 

    {/* -------------------New Screen-------------------- */}
   

    {/* ----------------end here---------------- */}

    {/* ------------------------------------Screen 2--------------------------------- */}
    {
    !mdBreakPoint ?
    <>
    <Grid  style={{height:'15rem'}}>
    <img  src="./../img/FAQ.png" alt="faq" height="100%" />
    </Grid>
   <Grid style={{marginBottom:'2rem'}}>
    <Carosel />
    </Grid>
    </> 
    : ""
}
    <div>
   
        <Grid container>
        <Grid  item xl={7} lg={7} md={6} sm={12} xs={12}>
        <Grid item xs={12} style={{textAlign:'left'}}>
            <Typography variant="p" sx={{fontWeight:'bold', fontSize:'1.5rem'}}>
                Still in doubt ? Check this...
            </Typography>
            <FAQsearch
                          variant="standard"
                            id="userSearchInput"
                            // value={searchInput}
                            // onChange={onSearchChange}
                            onChange={(e) => { setSearchValue(e.target.value); }}
                            placeholder="Search your query here"
                          />

        </Grid>
       
        <Grid item xs={12} style={{marginTop:'4rem'}}>
        {   questionsList && questionsList.length ? 
        questionsList.map((q)=>(
            <>
             <Accordion expanded={expanded === q.id} onChange={handleChange(q.id)} 
              style={{marginBottom:'1rem', backgroundColor:"#c9e9e9ad", borderRadius:'8px'}}>
        <AccordionSummary
          expandIcon={
           
           <ExpandMoreIcon sx={{color:'#008081',fontSize:'2rem'}} />
          
          
        }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{textAlign:'left',fontFamily:'Montserrat', color:"#0f0e0ec2",fontWeight:'bold', fontSize:'16px'}}>{q.id}.{" "}{q.question}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.newline} style={{backgroundColor:'#fff'}}>
          <Typography sx={{textAlign:'left',fontFamily:'Montserrat',fontSize:'14px'}}>
           {q.ans}
          </Typography>
          {
            q.link ?
            <Typography  sx={{textAlign:'left',fontFamily:'Montserrat',fontSize:'14px',paddingTop:'10px'}} >
          <a href={q.link} rel="noopener noreferrer" target="_blank">Click here</a> to see the walk-through
           
          </Typography>
             :""
          }
         
        </AccordionDetails>
      </Accordion>
    
            </>
         
        )) 
       
        :
        <Grid style={{marginTop:'2rem'}}>
         
            <img  src="./../img/noresult.png" alt="faq" /><br/>
         
       
            </Grid>
            
        
    }
    
    { searchValue && searchValue.length  ?
     " "
    : 
    <Grid sx={{textAlign:'right' , cursor:'pointer'}} onClick={handleSeeMore}>{!seeMore ? 
      <span style={{color:'#008081'}}>+See more</span> 
        :
        <span style={{color:'#008081'}}>-See less</span> 
         }</Grid>
    }
   
   {
    que && que.length ?
    <>
    <Grid container sx={{pb:'1rem'}}>
   <Typography variant="div" >Could not find the answer you are looking for ? </Typography>
   {/* <Typography variant="div"> Contact <b>info@adaptideations.com </b> or <b>+91-4045207973</b> for more information.</Typography>  */}
    </Grid>
    <Grid sx={{textAlign:'left',backgroundColor:'#d6ecec', width:'18rem', p:'0.5rem',borderRadius:'5px'}}>
    <Grid><Typography variant="p" >Contact</Typography></Grid>
    <Grid><Typography variant="p"><IconButton><MailIcon /></IconButton> support@adaptideations.com</Typography></Grid>
    {/* <Grid><Typography variant="p"><IconButton><PhoneIcon /></IconButton>+91-4045207973</Typography></Grid> */}

      </Grid>
      </>
     : ""

   }
        </Grid>
        </Grid>
        <Grid  item  xl={5} lg={5}  md={6} sm={12} xs={12}>
        <Grid item  style={{height:'15rem'}}  >
       {
         mdBreakPoint ? 
         <img  src="./../img/videoimg.png" alt="faq" height="100%" />
     
          :
         ""
       } 
        </Grid>
        <Grid >
        {
            mdBreakPoint ? 
            <Grid item xs={12} style={{ height:'100vh',width:'100%', overflow:'auto'}} >

        {
            videos.map((vid)=>(
                <Grid key={vid.id} container sx={{mb:'1.2rem'}} >
            <Grid item xs={12} >
            <Grid container style={{justifyContent:'center',}}
             >
            <img  src={vid.src} alt="faq"  className={classes.iframeDiv}
             onClick={(e)=>handleOpenDialogClick(e,{title:vid.title,link: vid.link})}  
             />
            {/* <iframe width="100%" height="100%"
                className={classes.iframeDiv}
                allowFullScreen
                autoPlay
               src= {vid &&  vid.link}
               onClick={(e)=>handleOpenDialogClick(e,{title:vid.title,link: vid.link})} 
               >
               </iframe> */}
               
              
               
            </Grid>
            </Grid>
            {/* <Grid item xs={12} style={{margin:'auto',}} >
               <Typography variant="p" sx={{fontWeight:'bold', fontSize:'16px', color:'rgba(0,0,0,0.7)',}}>{vid.title}</Typography>
            </Grid> */}
        </Grid>
            ))
        }
        
    </Grid>
            :""
        }
      </Grid>
         </Grid>
        </Grid>
    </div>

   
    </div>


<Dialog 
open={openDialog}
TransitionComponent={Zoom}
aria-labelledby="form-dialog-title"
maxWidth="lg"
fullWidth
scroll="body"

PaperProps={{
  style: {
    backgroundColor: '#f2f2f2',
  },
}}
>
    <DialogTitle>
        <Grid container 
        className={classes.dialogTitle}
        >
    <Grid>
    {videoDetails &&  videoDetails.title}
    </Grid>
    <Grid>
        <IconButton
         onClick={handleCloseDialogClick}
          className={classes.icon}
          >
        <CloseIcon  />
        </IconButton>
       
        </Grid>
        </Grid>
       
   
    </DialogTitle>
    <DialogContent  style={{padding:'2rem'}} >
        <Grid container style={{height:'40rem', }}>
            
        <iframe width="100%" height="100%"
            className={classes.iframeDiv1}
            allowFullScreen
            autoPlay
           src= {videoDetails &&  videoDetails.link}
           >
           </iframe>
          
        </Grid>
   
    </DialogContent>
</Dialog>
</>
    )

}




export default withStyles(styles)(Document);


