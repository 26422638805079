/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
import { Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledPrimaryButton = styled(Button)(() => ({
 
  color: "#fff",
  textTransform: "capitalize",
  fontSize: "1em",
  borderRadius: "22px",
  backgroundColor: "#008081",

  "&:hover": {
    backgroundColor: "#008081",
    // backgroundImage: "linear-gradient(to bottom, #02ACAE 50%, #008081 100%)",
  },
}));

function CreateButton({
  onClick, disabled, text, type, icon, ...props
}) {
  return (
    <StyledPrimaryButton
      {...props}
      onClick={onClick}
      variant="contained"
      disabled={disabled}
      type={type}
      startIcon={icon}
    >
      <Typography variant="p" component="span" sx={{ fontWeight: "bold" }}>
        {text}
      </Typography>
    </StyledPrimaryButton>
  );
}

export default CreateButton;
