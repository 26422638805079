import React, { useState, useEffect, useContext } from 'react';

import {
  Grid,CircularProgress
} from '@mui/material';
import { withStyles } from '@mui/styles';
import moment from 'moment';
import DateRange from 'components/DateRange';
import UserContext from 'Context/UserContext';
import cogoToast from 'cogo-toast';
import LogsTable from './LogsTable';
// import API from '../../../../API/apiConfig';
import API from 'API/apiConfig';
import PrimaryButton from 'components/PrimaryButton';
import GetAppIcon from '@mui/icons-material/GetApp';

const APIURL = process.env.REACT_APP_APIURL;

const styles = {
  externalDiv: {
    width: '100%',
    height: 'auto',
    marginTop: "0.8rem"
  },
  controlDiv: {
    height: 'inherit',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  
  },
  clearSelectionDisabled: {
    display: 'block',
    position: 'relative',
  },
  clearSelectionActive: {
    color: 'red',
  },
  Selected: {
    cursor: 'auto',
    color: 'blue',
  },
};


const Logs = ({ classes }) => {
  const [rowData, setRowData] = useState([]);

  // const [selectedRowData, setSelectedRowData] = useState(null);
  const [startDate, setStartDate] = useState(moment().subtract(7, 'days'));
  const [endDate, setEndDate] = useState(moment());
  const [selectedQuickOption, setSelectedQuickOption] = useState(7);
  const [downloadReport, setDownloadReport]= useState(false)
  const [loading, setLoading]= useState(true)
  const companyDetails = useContext(UserContext); // react context
  const { userPermissions, } = companyDetails;

  // const diffDays = (date, otherDate) => Math.ceil(Math.abs(date.getTime() - otherDate.getTime()) / (1000 * 60 * 60 * 24));
  

  const getLogs = () => {
    const sD = moment(startDate).unix();
    const eD = moment(endDate).unix();
   
    // const daysDiff=diffDays(new Date(startDate), new Date(endDate));  
    const daysDiff = (moment(endDate).diff(moment(startDate), 'days'))
    
   
  
    if(daysDiff <= 7){
    
      API.get(`${APIURL}/logs?from=${sD}&&to=${eD}`)
      .then((logsResponse) => {
        if (logsResponse.data.status === 'success') {
          const logsData = logsResponse.data.data;
          // console.log('Audit resp',logsData )
          setRowData(logsData);
          setLoading(false)
        } else {
          console.log('error getting logs');
          setLoading(false)
        }
      }).catch((error) => {
        console.log('error getting logs', error.message || error);
        cogoToast.error('error getting logs');
        setLoading(false)
      });
    }else{
      cogoToast.error("Please select dates within a week to see audit logs", {
        position: "top-right",
      });
      setLoading(false)
    }
   
  };


  useEffect(() => {
    getLogs();
  }, [startDate, endDate]);

  // const onSelectionChanged = (selectedRows) => {
  //   setSelectedRowData(selectedRows);
  // };

  const handleQuickOptionsChange = (value) => {
    setSelectedQuickOption(value);
    setStartDate(moment().subtract(value, 'days'));
    setEndDate(moment());
  };

  const handleRangeChange = (start, end) => {
    setSelectedQuickOption(0)
    setStartDate(moment(start));
    setEndDate(moment(end));
  };

  const handleReportDownload=()=>{
    setDownloadReport(!downloadReport)
  }

  const routeToDashboard = () => {
    // history.push('/dashboard');
    cogoToast.error('You do not have permission to access Logs', { position: 'top-right' });
  };

  // console.log('User Perm', userPermissions)

  return (
    <>
    {loading ? (
      <> 
      <div className={classes.overlay}>
          <CircularProgress
            style={{ position: 'absolute', top: '50%', left: '50%' }}
          />
        </div>
      </>
    ) :(
      <> 
      {userPermissions && userPermissions.logs && userPermissions.logs.read ? (
<> 
<Grid container>
        <Grid item xl={4}
            lg={4}
            md={12}
            sm={12}
            xs={12}
    
        style={{ paddingBottom:'0.5rem', marginTop: '2rem',display:'flex', justifyContent:'left'}} >
            <PrimaryButton 
            variant="contained"
            text="Download" 
            onClick={handleReportDownload}
            icon={<GetAppIcon style={{ marginRight: "5px" }} 

            />}
            />
        </Grid>
        <Grid
        xl={8}
        lg={8}
        md={12}
        sm={12}
        xs={12}
          item
        //   justifyContent="flex-end"
        //   className={classes.ControlDiv}
          style={{margin:'auto' }}
        >

          <DateRange
            startDate={startDate}
            endDate={endDate}
            handleQuickOptionsChange={handleQuickOptionsChange}
            handleRangeChange={handleRangeChange}
            selectedQuickOption={selectedQuickOption}
            message="Please select dates within a week to see audit logs."
            disable
          />
        </Grid>
        <Grid item md={12} sm={12}  xs={12} style={{ height: '100%' }}>
          <LogsTable
            rowData={rowData}
            // onSelectionChanged={onSelectionChanged}
            // selectedRowData={selectedRowData}
            downloadReport={downloadReport}
          />
        </Grid>
      </Grid>
</>
      ) : (
        routeToDashboard()
      )}
      </>
    )}
      
    </>
  );
};

// export default Logs;
export default withStyles(styles)(Logs);
