/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import ReactEcharts from 'echarts-for-react';
import moment from 'moment';

const Temperaturehumidity = (props) => {
  const [hasData, setHasData] = useState(false);
  const chartRef = useRef(null);
  useEffect(() => {
    let isCtrlDown = false;
    window.addEventListener('keydown', (e) => {
      if (e.key === 'Control' || e.key === 'Meta') {
        isCtrlDown = true;
      }
    });
    window.addEventListener('keyup', (e) => {
      if (e.key === 'Control' || e.key === 'Meta') {
        isCtrlDown = false;
      }
    });
    if (chartRef && chartRef.current) {
      const i = chartRef.current.getEchartsInstance();
      i.on('legendselectchanged', (p) => {
        // if(isCtrlDown){
        //     console.log(p);
        //     let series_name:string = p.name;
        //     let option = i.getOption();
        //     let rest_of_series = option.series?.filter(s=> s.name !== series_name);
        //     if(rest_of_series){
        //         //let name_of_series = rest_of_series.map(s=>s.name);
        //         option.series = rest_of_series;
        //         i.setOption(option, true);
        //     }
        // }
      });
    }
  }, []);
  const {
    seriesData, titleText, axisLabel, humidityData,temperatureSettings,humiditySettings
  } = props;

  const minTempValue=(value)=>{

    if(temperatureSettings && temperatureSettings.minViolation !== undefined){
       const minTemperature = temperatureSettings.minViolation;
     if (value.min > minTemperature) {
        return Math.round(temperatureSettings.minViolation - 5);
      }
    }
     
    return Math.round(value.min - 5);
    
  }

  const maxTempValue=(value)=>{
    if(temperatureSettings && temperatureSettings.maxViolation !== undefined){
       const  maxTemperature = temperatureSettings.maxViolation;
     if (value.max <  maxTemperature) {
        return Math.round(temperatureSettings.maxViolation + 5);
      }
    }else{
       return Math.round(value.max + 5);
    }
  }

  const minHumidityValue=(value)=>{
    if(humiditySettings && humiditySettings.minViolation !== undefined){
      const minHumidity = humiditySettings.minViolation;
    if (value.min > minHumidity) {
       return Math.round(humiditySettings.minViolation - 5);
     }
   }
    
   return Math.round(value.min - 5);
  }


  const maxHumidityValue=(value)=>{
    if(humiditySettings && humiditySettings.maxViolation !== undefined){
       const  maxHumidity = humiditySettings.maxViolation;
     if (value.max <  maxHumidity) {
        return Math.round(humiditySettings.maxViolation + 5);
      }
    }else{
       return Math.round(value.max + 5);
    }
  }


  // console.log('Inside charts', seriesData.map(data => data.temperature));
  //   const arr = [];

  //   seriesData.map((data) => {
  //     if (data && data.temperature) {
  //       data.temperature.map((value, index) => {
  //         const { length } = data.temperature;
  //         arr.push([(data.timeStamp - ((length - index - 1) * 60)) * 1000, value]);
  //       });
  //     }
  //   });
  // console.log("Array ",arr);

  useEffect(() => {
    if (chartRef && chartRef.current) {
      const i = chartRef.current.getEchartsInstance();
      if (!seriesData.length) {
        i.clear();
        // i.setOption({series: []}, true); // alternate
      }
    }
    // Show Chart vs No data
    if (seriesData.length) setHasData(true);
    else setHasData(false);
  }, [seriesData]);


  const option = {


    title: {
      text: titleText,
      textStyle: {
        fontFamily: 'lato',
      },
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        animation: false,
      },
    },
    legend: {
      // data: [seriesData.map((item) => item.name),humidityData.map((item) => item.name)]
      // data: seriesData.map((item) => item.name),
    },
    calculable: true,
    grid: [{
      left: 60,
      right: 50,
      height: '35%',
    },
    {
      left: 60,
      right: 50,
      top: '55%',
      height: '35%',
    }],

    xAxis: [
      // {
      //   type: 'time',
      //   boundaryGap: false,
      //   axisTick: {
      //     alignWithLabel: true,
      //   },

      //   axisLabel: {
      //     formatter(value) {
      //       const date = moment(value);
      //       return `${date.format('HH:mm')} \n ${date.format('Do MMM')}`;
      //     },
      //   },
      //   name: 'Time',
      //   nameLocation: 'center',
      //   nameGap: 35,

      // },
      {
        type: 'time',
        boundaryGap: false,
        axisLine: { onZero: true },
        // data: timeData
        axisLabel: {
          formatter(value) {
            const date = moment(value);
            return `${date.format('HH:mm')} \n ${date.format('Do MMM')}`;
          },
          interval:0,
          // rotate:60,
          hideOverlap:true
        },

      },
      {
        gridIndex: 1,
        type: 'time',
        boundaryGap: false,
        axisLine: { onZero: true },
        name: 'Time',
        nameLocation: 'center',
        nameGap: 35,
        // data: timeData,
        axisLabel: {
          formatter(value) {
            const date = moment(value);
            return `${date.format('HH:mm')} \n ${date.format('Do MMM')}`;
          },
          interval:0,
          // rotate:60,
          hideOverlap:true
        },
        // position: 'top'
      },

    ],
    yAxis: [
      {

        type: 'value',
        name: 'Temperature',
        nameLocation: 'center',
        nameGap: 28,
        min:minTempValue,
        max: maxTempValue,

      },
      {
        gridIndex: 1,
        name: 'Humidity',
        type: 'value',
        // inverse: true,
        nameGap: 28,     
        nameLocation: 'center',
        // max:100,
        min:minHumidityValue, 
        max: maxHumidityValue,
       
      },
    ],

    toolbox: {
      show: true,
      orient: 'vertical',
      iconStyle: {
        borderColor: "#008081",
        border:'3px solid #008081'
      },
      // top: '28',
      feature: {
        dataZoom: {
          yAxisIndex: 'none',
          title: {
            zoom: 'Area Zooming',
            back: 'Restore Area Zooming',
          },
        },
        // dataView: {
        //   readOnly: false,
        //   title: 'View Data',
        //   lang: ['List View', 'Turn Off', 'Refresh'],
        // },
        magicType: {
          type: ['line', 'bar'],
          title: {
            line: 'Switch to Line Chart',
            bar: 'Switch to Bar Chart',
            // stack: 'Stack',
            // tiled: 'Tile',
          },
        },
        restore: {
          title: 'Reset',
        },
        saveAsImage: {
          title: 'save as image',
        },
      },
    },
    // series: seriesData.map(item => ({
    //   ...item,
    //   data: item.data.map(point => [moment(point[0] * 1000).format(), point[1]]),
    // })),
    series: [{
      name: 'Temperature',
      data: seriesData,
      type: 'line',
      showSymbol:false,
      markLine: {
        data: [
          {
          // type: 'min',
          label: {
            position: 'insideEndTop',
            // distance:[-50,100],
            formatter: `Min. Threshold : ${temperatureSettings && temperatureSettings.minViolation}°C`,
          },
          tooltip:{
            trigger:'item',
            formatter: () => {
             
              return  `Min. Threshold : ${ temperatureSettings && temperatureSettings.minViolation}°C`;
            },
          },
          name: 'Min',
          yAxis: temperatureSettings && temperatureSettings.minViolation !== undefined ? temperatureSettings.minViolation : '',
          // yAxis: 25,
           // put the value of min of temp
          lineStyle: {
            normal: {
              type: 'dashed',
              color: '#7c2d12ff',
            },
          },
          
        },
        {
         
          name: 'Max',
          label: {
            position: 'insideEndTop',
            formatter: `Max. Threshold : ${temperatureSettings && temperatureSettings.maxViolation}°C`,
          },
          tooltip:{
            trigger:'item',
            formatter: () => {
             
              return  `Max. Threshold : ${ temperatureSettings && temperatureSettings.maxViolation}°C`;
            },
          },
          yAxis: temperatureSettings && temperatureSettings.maxViolation !== undefined ? temperatureSettings.maxViolation :'' , // put the value of max of temp
          // yAxis:30,
          lineStyle: {
            normal: {
              type: 'dashed',
              color: '#dc2626',
            },
          },
          
        },
        ],

      },
    },
    {
      name: 'Humidity',
      data: humidityData,
      type: 'line',
      xAxisIndex: 1,
      yAxisIndex: 1,
      showSymbol:false,
      markLine: {
        data: [
          {
          // type: 'min',
         
          name: 'Min',
          yAxis: humiditySettings && humiditySettings.minViolation !== undefined ? humiditySettings.minViolation : '',
          // yAxis:30,
           // put the value of min of temp
           label: {
            position: 'insideEndTop',
            // distance:[-50,100],
            formatter: `Min. Threshold : ${humiditySettings && humiditySettings.minViolation}°C`,
          },
          tooltip:{
            trigger:'item',
            formatter: () => {
             
              return  `Min. Threshold : ${ humiditySettings && humiditySettings.minViolation}°C`;
            },
          },
          lineStyle: {
            normal: {
              type: 'dashed',
              color: '#7c2d12ff',
            },
          },
          
        },
        {
         
          name: 'Max',
          // yAxis:50,
          yAxis: humiditySettings && humiditySettings.maxViolation !== undefined ? humiditySettings.maxViolation : '',
          label: {
            position: 'insideEndTop',
            // distance:[-50,100],
            formatter: `Max. Threshold : ${humiditySettings && humiditySettings.maxViolation}°C`,
          },
          tooltip:{
            trigger:'item',
            formatter: () => {
             
              return  `Max. Threshold : ${ humiditySettings && humiditySettings.maxViolation}°C`;
            },
          },
          lineStyle: {
            normal: {
              type: 'dashed',
              color: '#dc2626',
            },
          },
          
        },
        ],

      },
    }],
    dataZoom: [
      {
        type: 'slider',
        start: 0,
        end: 100,
        // @ts-ignore
        height: 15,
        bottom: 6,
        // top:20,
        right: 75,
        left: 75,
        handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
        handleSize: '100%',
        showDataShadow: 'true',
        xAxisIndex: [0, 1],
        handleStyle: {
          color: '#fff',
          shadowBlur: 3,
          shadowColor: 'rgba(200, 200, 200, 1)',
          shadowOffsetX: 0,
          shadowOffsetY: 0,
          // marginTop: '20px',
        },
      },
      {

        type: 'inside',
        start: 0,
        end: 100,
        xAxisIndex: [0, 1],
      },
    ],
  };
 
  return (
   
    <div style={{
      width: '100%', height: '100vh', position: 'relative', 
    }}
    >
      {hasData && (<ReactEcharts ref={chartRef} option={option} style={{ width: '100%', height: '100%' }} />)}

      {!hasData && (
        <h2 style={{
          position: 'absolute', width: '100%', marginTop: '10%', textAlign: 'center',
        }}
        >
          {/* NO DATA */}
          <img src="../../img/nodata1.png" alt="No data" width='60%' ></img>
        </h2>
      )}
    </div>
   
  );
};
export default Temperaturehumidity;
