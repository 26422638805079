import React from 'react';
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import InputForChamber from 'components/InputForChamber';

const styles = {
  
  subHeading: {
    padding: '1.2rem',
    fontWeight: 'bold',
    fontfamily: 'Montserrat',
    fontsize: '14px',
  },
};

const HumiditySettings = (props) => {
  const {
    classes, defaultValueMinViolation, defaultValueMaxViolation, defaultValueMinWarning, defaultValueMaxWarning,
  } = props;

  return (
    <>
      <Grid container   className={classes.subHeading}>
        <Grid item xs={6}>
Violation Humidity
        </Grid>
        <Grid item xs={6}>
Warning Humidity
        </Grid>
      </Grid>

      <Grid container spacing={2} item xs={12}>
        <Grid container item xs={6}>

          <Grid item lg={5} md={5} sm={5} xs={12} 
          sx={{margin:'auto' , 
          textAlign: 'left',
           fontfamily: 'Montserrat',
          fontsize: '14px'}} 
          >Minimum Threshold </Grid>
          <Grid item lg={5} md={5} sm={5} xs={12}>
            <InputForChamber
              id="humidityMinimum"
              margin="normal"
              type="number"
              autoComplete="off"
              placeholder="0"
              defaultValue={
                defaultValueMinViolation
              }
            />

          </Grid>

          <Grid item xs={3} />
        </Grid>

        <Grid container item xs={6}>
          <Grid item lg={5} md={5} sm={5} xs={12}
          sx={{margin:'auto' , 
          textAlign: 'left',
           fontfamily: 'Montserrat',
          fontsize: '14px'}} 
          >Minimum Threshold</Grid>
          <Grid item lg={5} md={5} sm={5} xs={12}>
            <InputForChamber
              autoComplete="off"
              id="humidityWarningMinimum"
              placeholder="0"
              margin="normal"
              type="number"
              defaultValue={
                defaultValueMinWarning
               }
            />


          </Grid>
          <Grid item xs={3} />
        </Grid>
      </Grid>

      <Grid container spacing={2} item xs={12}>
        <Grid container item xs={6}>

          <Grid item lg={5} md={5} sm={5} xs={12}
          sx={{margin:'auto' , 
          textAlign: 'left',
           fontfamily: 'Montserrat',
          fontsize: '14px'}} 
          >Maximum Threshold</Grid>
          <Grid item lg={5} md={5} sm={5} xs={12}>
            <InputForChamber
              id="humidityMaximum"
              autoComplete="off"
              defaultValue={defaultValueMaxViolation}
              placeholder="0"
              margin="normal"
              type="number"
            />
          </Grid>
          <Grid item xs={3} />
        </Grid>

        <Grid container item xs={6}>
          <Grid item lg={5} md={5} sm={5} xs={12} 
          sx={{margin:'auto' , 
          textAlign: 'left',
           fontfamily: 'Montserrat',
          fontsize: '14px'}} 
          >Maximum Threshold</Grid>
          <Grid item lg={5} md={5} sm={5} xs={12}>
            <InputForChamber
              id="humidityWarningMaximum"
              defaultValue={defaultValueMaxWarning}
              margin="normal"
              placeholder="0"
              type="number"
              autoComplete="off"
            />

          </Grid>
          <Grid item xs={3} />
        </Grid>
      </Grid>

    </>
  );
};


export default withStyles(styles)(HumiditySettings);
