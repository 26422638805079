/* eslint-disable no-prototype-builtins */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import GetAppIcon from '@mui/icons-material/GetApp';
// import InfoIcon from '@mui/icons-material/Info';
import Typography from '@mui/material/Typography';
// import Breadcrumbs from '@mui/material/Breadcrumbs';
// import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import cogoToast from 'cogo-toast';
import { Divider } from '@mui/material';
import { withStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
// import Grow from '@mui/material/Grow';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import ExpansionPanel from '@mui/material/ExpansionPanel';
// import ExpansionPanelSummary from '@mui/material/ExpansionPanelSummary';
// import ExpansionPanelDetails from '@mui/material/ExpansionPanelDetails';
import { Grow } from '@mui/material';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import moment from 'moment';
// import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
// import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';

import ReactSelect from 'react-select';

// import Calendar from '../../components/Calendar';
import UserContext from 'Context/UserContext';
// import API from 'API/apiConfig';
import TemperatureHumidityGraph from '../../components/TemperatureHumidityGraph';
import SecondaryButton from 'components/SecondaryButton';
import PrimaryButton from 'components/PrimaryButton';
import SelectField from 'components/SelectField';
// import EventLogsGrid from '../EventLogs/EventLogsGrid';
import SingleChamberEventLogsGrid from './SingleChamberEventLogsGrid';
// import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
// import Calendar from '../../components/Calendar';
import DateAndTimeRangePicker from '../../components/DateAndTimeRangePicker';
import { CtoF } from '../../utils/helpers';
import API from 'API/apiConfig'; // module import.
import { CircularProgress, } from "@mui/material";
import DeviceLocation from './DeviceLocation';

const queryString = require('query-string'); // commonJS import.


const APIURL = process.env.REACT_APP_APIURL;

const moment = require('moment-timezone');

const styles = {
  chamber: {
    fontFamily: 'Montserrat',
    fontSize: '25px',
    fontWeight: '900',
  },
  chamber_subHeading: {
    margin: '0',
    fontFamily: 'Montserrat',
    fontSize: '20px',
    fontWeight: '600',
  },
  chamber_contentLabel: {
    margin: '0',
    fontFamily: 'Montserrat',
    fontSize: '15px',
    fontWeight: 'bold',
  },
  chamber_contentText: {
    margin: '0',
    fontFamily: 'Montserrat',
    fontSize: '15px',
    fontWeight: '300',
  },
  downloadButton: {
    float: 'left',
    marginLeft: '10px',
    backgroundColor: '#008081',
    color: 'white',
    borderRadius: '20px',
    font: 'bold',
    margin: '10px !important',
    padding: '5px 15px',
    minWidth: '99px',
    '&:hover': {
      backgroundColor: '#008081',
    },
  },
  formControl: {
    // minWidth: '100%',
    width: '100%',
    margin: 'auto'
  },
  headerInfo: {
    padding: '1em 2em',
    width: '100%',
  },
  subheading: {
    fontSize: '20px',
    fontWeight: 'bold',
    fontFamily: 'Montserrat',
    textAlign: 'left',
    padding: '20px',
  },
  downloadDialog: {
    margin: 'auto',
    textAlign: 'left'
  },
  title: {

    justifyContent: 'left',

    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '700',
  }
};

const selectStyles = {
  control: () => ({
    alignItems: 'center',
    backgroundColor: 'hsl(0,0%,100%)',
    // marginTop: '1rem',
    borderRadius: '21px',

    borderBottom: '1px solid #dedede',
    cursor: 'default',

    display: 'flex',

    flexWrap: 'wrap',

    justifyContent: 'space-between',
    minHeight: '38px',
    outline: '0 !important',
    position: 'relative',

    transition: 'all 100ms',
    boxSizing: 'border-box',
  }),
};

class SingleChamber extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chamberInfoDialog: false,
      expanded: 'chamber',
      frequency: 15,
      selectedOption: null,
      timeZoneOptions: [],
      dataType: 'all',
      isEvents: '1',
      reportType: 'pdf',
      date: [moment().startOf('day').toDate(), moment().toDate()],
      selectedQuickOption: 1,
      rowData: null,
      openPDFDialog: false,
      selectedAsset: {},
      selectedChamber: [],
      devicePingsforGraph: [],
      deviceInfoForMaps: [],

      chamberDevice: [],
      hide: false,
    };
  }
  static contextType = UserContext;




  getAssets = (queryParam) => {

    const url = queryParam ? `${APIURL}/assets/?uid=${queryParam}` : `${APIURL}/assets`;
    API.get(url)
      .then((response) => {
        const resp = response.data.data;
        // console.log('response', response.data.data);
        this.setState({
          selectedChamber: resp
        })
      })
      .catch(() => {

      });
  }

  componentDidMount = () => {

    const { location } = this.props;
    const timeZones = moment.tz.names();
    const tz = moment.tz.guess();
    const index = timeZones.indexOf(tz);

    const timeZoneOptions = timeZones.map((item) => ({ label: `(GMT${moment.tz(item).format('Z')})${item}`, value: item }));
    this.setState({
      timeZoneOptions,
      selectedOption: timeZoneOptions[index],
    }, () => {
      // console.log('timeZoneOptions', this.state.timeZoneOptions);
    });
    // console.log('Props r',assetInfo.id )
    // if (location.search === '' && location.pathname === '/dashboard' ) {
    //   history.push({ pathname:'/dashboard', search:`?assetId=${assetInfo.id}`, state: { isActive: true } });
    // }

    const parsed = queryString.parse(location.search);
    const st = parsed.startTime;
    const et = parsed.endTime;
    const newst = new Date(st * 1000);
    const newet = new Date(et * 1000)
    // console.log('Current date', date, st , et, newst)
    this.setState({
      date: [newst, newet],
      selectedQuickOption: 0,
    })
    this.refreshChamberData(parsed.assetUid, st, et, true);
    this.getAssets(parsed.assetUid);

  };

  //   componentDidUpdate = (prevProps) => {
  //     console.log('CDU')
  //     // const {updateSingleChamber} = this.context;
  //     // updateSingleChamber(false);
  //     const { location } = this.props;
  //     // const assetInfo = selectedAsset.info;  

  //    const parsed = queryString.parse(location.search);
  //    const st = parsed.startTime;
  //    const et = parsed.endTime;
  //  console.log('Props', this.props)
  //       if(this.props !== prevProps){
  //         console.log('Inside if')

  //         this.getAssets(parsed.assetUid)
  //         this.refreshChamberData(parsed.assetUid, st, et, true);
  //       }

  //       // if(prevState.selectedChamber !== selectedChamber ){

  //       //   //   const parsed = queryString.parse(location.search);
  //       //   // this.getAssets(parsed.assetUid)

  //       // }

  //     // if (location.search === '' && location.pathname === '/dashboard') {
  //     //   history.push({ pathname: '/dashboard', search:`?assetId=${assetInfo.id}`, state: { isActive: true } });
  //     // }
  //   }


  handlePDFButtonClick = () => {

    this.setState({
      openPDFDialog: true,
    });
  }
  handlePDFDialogClose = () => {

    this.setState({
      openPDFDialog: false,
      frequency: 15,
      dataType: 'all',
      isEvents: '1',
      reportType: 'pdf',
    }, () => {
      // console.log('pdf dialog is set to false')
    });
  };

  handleExcelDownload = (timeZone, frequency, dataType, hideHumidityData) => {
    const { date, selectedChamber } = this.state;
    // console.log('Time zone',timeZone, dataType )
    if (!selectedChamber) {
      cogoToast.error('No chamber selected', { position: 'top-right' });
    } else if (date && date.length === 2) {
      this.setState(
        {
          isLoading: true,
        },
        () => {
          const assetUid = selectedChamber[0].uid;
          if (date.length === 2) {
            const startTime = Math.round(Number(date[0].getTime() / 1000));
            const endTime = Math.round(Number(date[1].getTime() / 1000));
            // console.log('Time zone',startTime,endTime )
            let queryString = `?assetUid=${assetUid}&startTime=${startTime}&endTime=${endTime}&timeZone=${timeZone}&dataInterval=${frequency}`;
            if (hideHumidityData) queryString = `${queryString}&dataType=${'temperature'}` // if data type option is not present , send temperature by dafault
            if (dataType && ['temperature', 'humidity'].includes(dataType)) queryString = `${queryString}&dataType=${dataType}`;
            API({
              url: `${APIURL}/report/excel${queryString}`,
              method: 'GET',
              headers: {
                Accept: 'application/vnd.ms-excel',
              },
              responseType: 'blob',
            })
              .then((response) => {
                if (response.data) {
                  document.body.style.cursor = 'default';
                  const url = window.URL.createObjectURL(new Blob([response.data]));
                  const link = document.createElement('a');
                  link.href = url;
                  let filename = `${selectedChamber[0].id}`;
                  filename += '.xlsx';
                  link.setAttribute('download', filename);
                  document.body.appendChild(link);
                  link.click();
                  link.remove();
                  this.setState({
                    isLoading: false,
                    openPDFDialog: false,
                    frequency: 15,
                    dataType: 'all',
                    isEvents: '1',
                    reportType: 'pdf',
                  });
                } else {
                  cogoToast.error('No report to download', {
                    position: 'top-right',
                  });
                  this.setState({
                    isLoading: false,
                  });
                }
              })
              .catch(() => {
                this.setState({
                  isLoading: false,
                });
                // console.error('Error downloading report ', err);
              });
          }
        },
      );
    } else {
      cogoToast.error('Select Date range, not single date', {
        position: 'top-right',
      });
    }
  };


  handlePDFDownload = (timeZone, frequency, dataType, isEvents, hideHumidityData) => {


    const { date, selectedChamber } = this.state;

    if (!selectedChamber) {
      cogoToast.error('No chamber selected', { position: 'top-right' });
    } else if (date && date.length === 2) {
      this.setState(
        {
          isLoading: true,
        },
        () => {
          const assetUid = selectedChamber[0].uid;

          if (date.length === 2) {

            const startTime = Math.round(Number(date[0].getTime() / 1000));
            const endTime = Math.round(Number(date[1].getTime() / 1000));
            let queryString = `?assetUid=${assetUid}&startTime=${startTime}&endTime=${endTime}&timeZone=${timeZone}&dataInterval=${frequency}&isEvents=${isEvents}`;
            if (hideHumidityData) queryString = `${queryString}&dataType=${'temperature'}` // if data type option is not present , send temperature by dafault
            if (dataType && ['temperature', 'humidity'].includes(dataType)) queryString = `${queryString}&dataType=${dataType}`;
            API({
              url: `${APIURL}/report/pdf${queryString}`,
              method: 'GET',
              headers: {
                Accept: 'application/pdf',
              },
              responseType: 'blob',
            })
              .then((response) => {
                if (response.data) {
                  // console.log('pdf response', response.data)
                  const url = window.URL.createObjectURL(
                    new Blob([response.data]),
                  );
                  const link = document.createElement('a');
                  link.href = url;
                  // const contentDisposition = response.headers['content-disposition'];
                  const filename = `${selectedChamber[0].id}.pdf`;
                  link.setAttribute('download', filename);
                  document.body.appendChild(link);
                  link.click();
                  link.remove();
                  this.setState({
                    isLoading: false,
                    openPDFDialog: false,
                    frequency: 15,
                    dataType: 'all',
                    isEvents: '1',
                    reportType: 'pdf',

                  });
                  // console.log("download function completed");
                } else {
                  cogoToast.error('No report to download', {
                    position: 'top-right',
                  });
                  this.setState({
                    isLoading: false,
                  });
                }
              })
              .catch(() => {
                this.setState({
                  isLoading: false,
                });
                // console.error('Error downloading report ', err);
              });
          }
        },
      );
    } else {
      cogoToast.error('Select Date range, not single date', {
        position: 'top-right',
      });
    }
  }


  getAssetDevicesAndPings = (asset) => new Promise((resolve, reject) => {

    if (!asset.assetUid || !asset.startTime || !asset.endTime) {
      return reject(new Error('assetUid, startTime and endTime are required'));
    }
    // console.log('querying pings from start time ', asset.startTime, ' to ', asset.endTime);
    const devicesQuery = `?assetUid=${asset.assetUid}&startTime=${asset.startTime}&endTime=${asset.endTime}`;
    API.get(`${APIURL}/devices${devicesQuery}`)
      .then((devicesResponse) => {

        if (devicesResponse.data.status === 'success') {

          const assetDevices = devicesResponse.data.data;

          const devicePingsPromises = assetDevices.map((device) => {
            let finalStartTime = null;
            let finalEndTime = null;
            const queryStartTime = asset.startTime;
            const queryEndTime = asset.endTime;
            if (device.startTime) {
              finalStartTime = (queryStartTime > device.startTime) ? queryStartTime : device.startTime;
            } else {
              finalStartTime = queryStartTime;
            }
            if (device.endTime) {
              finalEndTime = (queryEndTime < device.endTime) ? queryEndTime : device.endTime;
            } else {
              finalEndTime = queryEndTime;
            }
            // const endTime = device.endTime ? Number(device.endTime) : Math.floor(Date.now() / 1000);
            const devicePingQuery = `?uid=${device.uid}&startTime=${finalStartTime}&endTime=${finalEndTime}`;
            return API.get(`${APIURL}/devicepings${devicePingQuery}`);
          });
          const eventsPromises = assetDevices.map((device) => {
            let finalStartTime = null;
            let finalEndTime = null;
            const queryStartTime = asset.startTime;
            const queryEndTime = asset.endTime;
            if (device.startTime) {
              finalStartTime = (queryStartTime > device.startTime) ? queryStartTime : device.startTime;
            } else {
              finalStartTime = queryStartTime;
            }
            if (device.endTime) {
              finalEndTime = (queryEndTime < device.endTime) ? queryEndTime : device.endTime;
            } else {
              finalEndTime = queryEndTime;
            }
            // const endTime = device.endTime ? Number(device.endTime) : Math.floor(Date.now() / 1000);
            const eventQuery = `?assetUid=${asset.assetUid}&startTime=${finalStartTime}&endTime=${finalEndTime}`;
            return API.get(`${APIURL}/events${eventQuery}`);
          });
          Promise.all(devicePingsPromises)
            .then((devicePingsResponse) => {
              Promise.all(eventsPromises)
                .then((eventsResponse) => {
                  // console.log('devicePings : ', devicePingsResponse);
                  // console.log('events : ', eventsResponse);
                  const allPings = devicePingsResponse.map((devicePings, index) => (
                    {
                      deviceUid: assetDevices[index].uid,
                      deviceId: assetDevices[index].id,
                      data: devicePings.data.data ? devicePings.data.data : [],
                    }));
                  const allEvents = eventsResponse.map((events, index) => (
                    {
                      deviceUid: assetDevices[index].uid,
                      deviceId: assetDevices[index].id,
                      data: events.data.data,
                    }));
                  // const allDevices = assetDevices.map((devices) => (devices));
                  return resolve({
                    devices: assetDevices,
                    devicePings: allPings,
                    deviceEvents: allEvents,
                  });
                })
                .catch((err) =>
                  // console.error('Error getting eventss ', err);
                  reject(new Error(err)));
            })
            .catch((err) =>
              // console.error('Error getting device pings ', err);
              reject(new Error(err)));
        } else {
          // console.error('Error getting devices ', devicesResponse.data.message);
          return reject(new Error(devicesResponse.data.message));
        }
      })
      .catch((err) =>
        // console.error('Error getting devices ', err);
        reject(new Error(err)));
  })

  getEventLogs = ({ assetUid, startTime, endTime }) => {
    // console.log('get event logs 1',assetUid,startTime, endTime )
    API.get(`${APIURL}/events/asset/?uid=${assetUid}&startTime=${startTime}&endTime=${endTime}`)
      .then((response) => {
        if (response.data && response.data.status && response.data.status === 'success') {
          console.log('get event logs', response)
          this.setState({
            rowData: response.data.data,
          });
        } else {
          cogoToast.error('unable to get events', { position: 'top-right' });
          // console.error('unable to get events error in dashboard', response);
        }
      })
      .catch(() => {
        // cogoToast.error('unable to get event log', { position: 'top-right' });
        // console.error('error getting events', err);
      });
  };

  checkTypeMatch = (deviceTypes, objects) => {
    let hasMatch = false;
    let hasNonMatchingObject = false;
    for (const obj of objects) {
      if (deviceTypes && deviceTypes.includes(obj.type)) {
        hasMatch = true;
      } else {
        hasNonMatchingObject = true;
      }
    }
    return hasMatch && !hasNonMatchingObject;
  }


  componentDidUpdate(prevProps, prevState) {
    // Check if the state has changed
    const { chamberDevice } = this.state;
    const { CompanyInfo } = this.context;

    if (this.state.chamberDevice !== prevState.chamberDevice || this.state.hide !== prevState.hide) {
      // Run your logic here
      // This block will be executed whenever the state changes
      const deviceTypes = CompanyInfo &&
        CompanyInfo.settings && CompanyInfo.settings.data && CompanyInfo.settings.data.humidity &&
        CompanyInfo.settings.data.humidity.disableDeviceTypes ? CompanyInfo.settings.data.humidity.disableDeviceTypes : []


      //if hasTypeMatch is true , hide humidity column

      const hasTypeMatch = this.checkTypeMatch(deviceTypes, chamberDevice);

      this.setState({
        hide: hasTypeMatch
      })

    }
  }


  refreshChamberData = (assetid, startTime, endTime, changeDashboardState) => {
    //  const {history}= this.props;
    // const assetUid = queryString.parse(location.pathname.search);
    // const starttime= queryString.parse(location);
    this.setState({
      isLoading: true,
    })
    this.getAssetDevicesAndPings({
      assetUid: assetid,
      startTime,
      endTime,
    })
      .then((assetWithPingsEvents) => {

        const pings = assetWithPingsEvents.devicePings
        //  console.log('Pings are',assetWithPingsEvents, pings)

        const chamberDev = assetWithPingsEvents.devices
        //  console.log('Pings are',assetWithPingsEvents, pings)
        this.setState({
          // selectedAsset: { info: selectedAsset, ...assetWithPingsEvents },
          deviceInfoForMaps: assetWithPingsEvents,
          chamberDevice: chamberDev,
          devicePingsforGraph: pings,
          isLoading: false,
        }, () => {
          // if (selectedAsset.devices && selectedAsset.devices.length) {
          if (changeDashboardState) {

            // this.onDashboardStateChange();
          }
          // } else {
          //   cogoToast.error('No data available for the selected chamber', { position: 'top-right' });
          // }
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        }, () => {
          // cogoToast.error('Failed to load the chamber data', { position: 'top-right' });
          // cogoToast.error('You do not have permission to view this page , please contact your Admin***', { position: 'top-right' });
          console.error("Couldn't load the dashboard", err);
          //         const starttime=(localStorage.getItem('startTime'));
          // const newst=moment(starttime).unix();
          // const endtime=(localStorage.getItem('endTime'));
          // const newet=moment(endtime).unix();    

          //  history.push({
          //   pathname: "/dashboard",
          //   replace: true,


          // });
        });
      });
    this.getEventLogs({
      assetUid: assetid,
      startTime,
      endTime,
    });
    // this.getAssets(parsed.assetUid);
  }




  handleQuickOptionsChange = (value) => {
    const parsed = queryString.parse(location.search);
    const assetId = parsed.assetUid
    const { history } = this.props;

    if (value === 1) {
      this.setState({
        selectedQuickOption: 1,
        date: [moment().startOf('day').toDate(), moment().toDate()],
      }, () => {
        const startTime = moment().startOf('day').unix();
        const endTime = moment().unix();
        this.refreshChamberData(assetId, startTime, endTime);
        if (parsed && !parsed.EL && !parsed.RS) history.push(`/dashboard?assetUid=${assetId}&&startTime=${startTime}&&endTime=${endTime}&&SC=showsinglechamber`);

      });
    }
    else if (value === 1.5) {
      this.setState({
        selectedQuickOption: 1.5,
        date: [moment().subtract(15, 'm').toDate(), moment().toDate()],
      }, () => {
        const startTime = moment().subtract(15, 'm').unix();
        const endTime = moment().unix();
        this.refreshChamberData(assetId, startTime, endTime);
        if (parsed && !parsed.EL && !parsed.RS) history.push(`/dashboard?assetUid=${assetId}&&startTime=${startTime}&&endTime=${endTime}&&SC=showsinglechamber`);

      });

    }
    else {
      this.setState({
        selectedQuickOption: value,
        date: [moment().subtract(value - 1, 'd').startOf('day').toDate(), moment().toDate()],
      }, () => {
        const startTime = moment().subtract(value, 'd').startOf('day').unix();
        const endTime = moment().unix();
        this.refreshChamberData(assetId, startTime, endTime);
        if (parsed && !parsed.EL && !parsed.RS) history.push(`/dashboard?assetUid=${assetId}&&startTime=${startTime}&&endTime=${endTime}&&SC=showsinglechamber`);
      });
    }


  }


  handleOnDateClick = (params) => {
    // console.log('Date is changed ', params)
    const { history } = this.props;
    this.setState({
      date: params,
      selectedQuickOption: 0,
    }, () => {
      const parsed = queryString.parse(location.search);

      if (this.state.date.length === 2) {
        const startTime = Math.round(Number(this.state.date[0].getTime() / 1000));
        const endTime = Math.round(Number(this.state.date[1].getTime() / 1000));
        // console.log('startEnd', startTime, endTime);

        this.refreshChamberData(parsed.assetUid, startTime, endTime);
        // history.push(`?startTime=${moment(params[0]).unix()}&&endTime=${moment(params[1]).unix()}`);
        // history.push(`/dashboard?assetUid=${parsed.assetUid}&&startTime=${startTime}&&endTime=$${endTime}`);
        if (parsed && !parsed.EL && !parsed.RS) {
          history.push(`/dashboard?assetUid=${parsed.assetUid}&&startTime=${startTime}&&endTime=${endTime}&&SC=showsinglechamber`);
        }
      }
    });
  };


  handleDeviceNoteChange = (uid, note) => {

    const deviceUid = uid

    var data = JSON.stringify([
      {
        "path": "/note",
        "op": "replace",
        "value": note.length ? note : "-"
      }
    ]);
    const encodeDeviceUid = (text) => encodeURIComponent(text).replace(/'/g, "%27").replace(/"/g, "%22")

    API
      .patch(`${APIURL}/events/${encodeDeviceUid(deviceUid)}`, data)
      .then((response) => {
        // console.log('edit response', response);
        if (response && response.data && response.data.status && response.data.status === 'success') {
          //if 

          this.setState({
            //selected row data to []
          })
        } else {
          //else
        }
      })
      .catch((err) => {
        console.error('editingDeviceError', err);
        this.setState({
          isLoading: false,
        });
      });
  }



  handleSelectChange = (value) => {
    this.setState({
      frequency: value,
    });
  };

  handleDataTypeChange = (value) => {
    this.setState({
      dataType: value,
    });
  };

  handleEventLogsChange = (value) => {
    this.setState({
      isEvents: value,
    });
  }

  handleReportTypeChange = (value) => {
    this.setState({
      reportType: value,
    });
  };

  toggleChamberInfoDialog = () => {
    this.setState((prevState) => ({
      chamberInfoDialog: !prevState.chamberInfoDialog,
    }));
  }

  handleChange = (value) => (event, isExpanded) => {
    if (isExpanded) {
      this.setState({
        expanded: value,
      });
    } else {
      this.setState({
        expanded: false,
      });
    }
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    params.api.sizeColumnsToFit();
    params.api.resetRowHeights();
  };


  handleReactSelectChange = (selectedOption) => {
    this.setState(
      { selectedOption },
    );
  };

  handlebackButtonChange = () => {
    const { history } = this.props;

    const parsed = queryString.parse(location.search);
    // const st=parsed.startTime;                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          
    // const et=parsed.endTime;
    const ELvalue = parsed.EL;
    const RS = parsed.RS;
    const CV = parsed.CV;
    if (ELvalue === 'singlechamber') {

      const starttime = (localStorage.getItem('startTime'));
      const newst = moment(starttime).unix();
      const endtime = (localStorage.getItem('endTime'));
      const newet = moment(endtime).unix();

      history.push({
        pathname: "/eventlogs",
        replace: true,
        search: `?startTime=${newst}&&endTime=${newet}&&EL=events`,

      });

    } else if (RS === "ReportSection") {
      if (RS === "ReportSection" && CV === "TableView") {
        history.push({
          pathname: "/dailyEvents",
          replace: true,
          search: `?&&view=TableView`,
        });

      } else {
        history.push({
          pathname: "/dailyEvents",
          search: `?&&view=CalendarView`,
          replace: true,

        })
      }

    }
    else {
      const { updateSingleChamber } = this.context;
      updateSingleChamber(false);
      history.push({ pathname: '/dashboard' })
    }

  };


  parseDate = (unixDate) => {
    if (unixDate && Number(unixDate)) {
      const parsedTime = moment(Number(unixDate * 1000)).format(
        "Do MMM, YYYY  HH:mm A",
      );
      if (parsedTime !== "Invalid date") return parsedTime;
    }
    return null;
  }


  render() {
    // console.log(this.props);
    const { classes } = this.props;
    const { CompanyInfo , singleChamberValue} = this.context;
    const { temperature } = CompanyInfo && CompanyInfo.settings;

    const {
      frequency,
      selectedOption,
      timeZoneOptions,
      dataType,
      reportType,
      date,
      selectedQuickOption,
      rowData,
      openPDFDialog,
      devicePingsforGraph,
      selectedChamber,
      isLoading,
      isEvents,
      deviceInfoForMaps,

      hide

    } = this.state;


    // comparing with length for hotfix , we can make it better
    const compareLength = CompanyInfo &&
      CompanyInfo.settings && CompanyInfo.settings.data && CompanyInfo.settings.data.humidity &&
      CompanyInfo.settings.data.humidity.disableDeviceTypes ?
      CompanyInfo.settings.data.humidity.disableDeviceTypes.length === 4 : false

    const showAssetInfo =
      selectedChamber && selectedChamber.length >= 0 ? selectedChamber[0] : [];

    const settings = {
      temperature:
        showAssetInfo && showAssetInfo.temperatureSettings
          ? showAssetInfo.temperatureSettings
          : null,
      humidity:
        showAssetInfo && showAssetInfo.humiditySettings
          ? showAssetInfo.humiditySettings
          : null,
    };
    let temperatureText;
    let humidityText;
    if (
      showAssetInfo &&
      showAssetInfo.temperatureSettings &&
      showAssetInfo.temperatureSettings.hasOwnProperty("maxViolation") &&
      showAssetInfo.temperatureSettings.hasOwnProperty("minViolation")
    ) {
      temperatureText =
        temperature && temperature === "F"
          ? ` ${CtoF(showAssetInfo.temperatureSettings.minViolation).toFixed(
            1
          )} to ${CtoF(
            showAssetInfo.temperatureSettings.maxViolation
          ).toFixed(1)}°F `
          : ` ${showAssetInfo.temperatureSettings.minViolation} to ${showAssetInfo.temperatureSettings.maxViolation}°C `;
      // temperatureText = ` ${assetInfo.temperatureSettings.minViolation} to ${assetInfo.temperatureSettings.maxViolation}°C `;
    } else if (
      showAssetInfo &&
      showAssetInfo.temperatureSettings &&
      !showAssetInfo.temperatureSettings.hasOwnProperty("maxViolation") &&
      showAssetInfo.temperatureSettings.hasOwnProperty("minViolation")
    ) {
      temperatureText =
        temperature && temperature === "F"
          ? ` > ${CtoF(showAssetInfo.temperatureSettings.minViolation).toFixed(
            1
          )}°F `
          : ` > ${showAssetInfo.temperatureSettings.minViolation}°C `;
    } else if (
      showAssetInfo &&
      showAssetInfo.temperatureSettings &&
      showAssetInfo.temperatureSettings.hasOwnProperty("maxViolation") &&
      !showAssetInfo.temperatureSettings.hasOwnProperty("minViolation")
    ) {
      temperatureText =
        temperature && temperature === "F"
          ? ` < ${CtoF(showAssetInfo.temperatureSettings.maxViolation).toFixed(
            1
          )}°F `
          : ` < ${showAssetInfo.temperatureSettings.maxViolation}°C `;
    } else {
      temperatureText = " - ";
    }
    if (
      showAssetInfo &&
      showAssetInfo.humiditySettings &&
      showAssetInfo.humiditySettings.hasOwnProperty("maxViolation") &&
      showAssetInfo.humiditySettings.hasOwnProperty("minViolation")
    ) {
      humidityText = ` ${showAssetInfo.humiditySettings.minViolation} - ${showAssetInfo.humiditySettings.maxViolation}% `;
    } else if (
      showAssetInfo &&
      showAssetInfo.humiditySettings &&
      !showAssetInfo.humiditySettings.hasOwnProperty("maxViolation") &&
      showAssetInfo.humiditySettings.hasOwnProperty("minViolation")
    ) {
      humidityText = ` > ${showAssetInfo.humiditySettings.minViolation}% `;
    } else if (
      showAssetInfo &&
      showAssetInfo.humiditySettings &&
      showAssetInfo.humiditySettings.hasOwnProperty("maxViolation") &&
      !showAssetInfo.humiditySettings.hasOwnProperty("minViolation")
    ) {
      humidityText = ` < ${showAssetInfo.humiditySettings.maxViolation}% `;
    } else {
      humidityText = " - ";
    }

    const createdOn = selectedChamber && selectedChamber.length > 0 && selectedChamber[0].createdOn ?
      this.parseDate(selectedChamber[0].createdOn)
      : "N/A"



    return (
      <div>


        <>
          {/* <Slide
          direction="left"
          in
          mountOnEnter
          unmountOnExit
          timeout={{ enter: 300, exit: 300 }}
        > */}
          <Grow in>
            <div style={{ marginTop: "40px" }}>
              <Grid container spacing={0}>
                <Grid
                  item
                  md={3}
                  sm={12}
                  xs={12}
                  style={{ display: "flex", justifyContent: "flex-start" }}
                >

                  <Typography>
                    {/* <ArrowBackIcon /> */}
                    <Button
                      variant="outlined"
                      style={{
                        border: "3px solid #008081", borderRadius: "40px",
                        color: '#008081',
                      }}
                      // onClick={onBackClick && onBackClick}
                      onClick={this.handlebackButtonChange}
                    >
                      <ArrowBackIcon />
                      back
                    </Button>
                  </Typography>


                </Grid>
                <Grid
                  item
                  md={7}
                  sm={8}
                  xs={12}
                  style={{ display: "flex", justifyContent: "flex-end", margin: 'auto' }}
                >
                  <DateAndTimeRangePicker
                    date={date}
                    onChange={this.handleOnDateClick}
                    selectedQuickOption={selectedQuickOption}
                    handleQuickOptionsChange={this.handleQuickOptionsChange}
                    disableMenu={false}
                    singleChamberValue={singleChamberValue}
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sm={4}
                  xs={12}
                  style={{ display: "flex", justifyContent: "flex-end", paddingTop: '10px' }}
                >
                  {/* <Button
                  variant="contained"
                  
                  className={classes.downloadButton}
                  startIcon={ <GetAppIcon style={{ marginRight: '5px' }} />}
                  onClick={handlePDFButtonClick}
                >
                  Report
                  <GetAppIcon style={{ marginRight: '5px' }} />
                  </Button> */}
                  <PrimaryButton
                    text="Report"
                    icon={<GetAppIcon style={{ marginRight: "5px" }} />}
                    onClick={this.handlePDFButtonClick}
                  />
                </Grid>
              </Grid>

              {
                isLoading && (
                  <div
                    style={{
                      position: "fixed",
                      display: "block",
                      width: "100%",
                      height: "100%",
                      top: "0",
                      left: "0",
                      right: "0",
                      bottom: "0",
                      backgroundColor: "rgba(0,0,0,0.5)",
                      zIndex: "2",
                    }}
                  >
                    <CircularProgress
                      style={{ position: "absolute", top: "50%", left: "50%" }}
                    />
                  </div>)
              }
              <div style={{ paddingTop: "20px", textAlign: "left" }}>
                {/* <Paper style={{ textAlign: 'left'  }}> */}
                <Grid container>
                  <Grid item xl={3} lg={3} md={4} sm={5} xs={7} className={classes.subheading}>
                    {CompanyInfo.settings.chamberType || "Chamber"} Details
                  </Grid>
                  <Grid item xl={9} lg={9} md={8} sm={7} xs={5}>
                    <Divider fullWidth style={{ marginTop: "2rem" }} />
                  </Grid>
                </Grid>

                <Grid container spacing={2} className={classes.headerInfo}>
                  <Grid item md={6} sm={6} xs={12}>
                    <Typography variant="subtitle1">
                      <b>
                        {CompanyInfo.settings.chamberType || "Chamber"} {" : "}{" "}
                      </b>
                      {selectedChamber && selectedChamber.length >= 0
                        ? selectedChamber[0] && selectedChamber[0].id
                        : "N/A"}
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={6} xs={12}>
                    <Typography variant="subtitle1">
                      <b>
                        {CompanyInfo.settings.warehouseType || "Warehouse"}{" "}
                        {" : "}{" "}
                      </b>
                      {selectedChamber && selectedChamber.length >= 0
                        ? selectedChamber[0] && selectedChamber[0].assetGroupId
                        : "N/A"}
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={6} xs={12}>
                    <Typography variant="subtitle1">
                      <b>Temperature Settings: </b>
                      {temperatureText}
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={6} xs={12}>
                    <Typography variant="subtitle1">

                      <b> {hide || compareLength ? '' : 'Humidity Settings: '}</b>
                      {hide || compareLength ? ' ' : humidityText}

                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={6} xs={12}>
                    <Typography variant="subtitle1">
                      <b>Created On: </b>
                      {createdOn}

                    </Typography>
                  </Grid>
                </Grid>
                {/* </Paper> */}

                <Grid container>
                  <Grid item xl={4} lg={4} md={6} sm={8} xs={9} className={classes.subheading}>
                    {hide ? 'Temperature Graph' : 'Temperature Humidity Graph '}
                  </Grid>
                  <Grid item xl={8} lg={8} md={6} sm={4} xs={3}>
                    <Divider fullWidth style={{ marginTop: "2rem" }} />
                  </Grid>
                </Grid>
                <TemperatureHumidityGraph
                  // devicesWithPings={selectedAsset.devicePings ? selectedAsset.devicePings : [] }
                  isLoading={isLoading}
                  devicesWithPings={
                    devicePingsforGraph ? devicePingsforGraph : []
                  }

                  settings={settings}
                  companySettings={CompanyInfo}
                  hideHumidityData={hide}
                />


                {
                  CompanyInfo &&
                  CompanyInfo.settings.enableLocation && (
                    <>
                      <Grid container style={{ margin: "20px 0px" }}>
                        <Grid item xl={2} lg={2} md={4} sm={4} xs={6} className={classes.subheading}>
                          Device Location
                        </Grid>
                        <Grid item xl={10} lg={10} md={8} sm={8} xs={6}>
                          <Divider fullWidth style={{ marginTop: "2rem" }} />
                        </Grid>
                      </Grid>

                      <Grid container style={{
                        position: "relative",
                        paddingTop: "0px",
                        marginTop: "32px",
                      }}>
                        <DeviceLocation
                          deviceInfoForMaps={
                            deviceInfoForMaps ? deviceInfoForMaps : []
                          }
                          temperatureSettings={showAssetInfo &&
                            showAssetInfo.temperatureSettings &&
                            showAssetInfo.temperatureSettings}
                          humiditySettings={showAssetInfo &&
                            showAssetInfo.humiditySettings &&
                            showAssetInfo.humiditySettings}
                          batterySettings={showAssetInfo &&
                            showAssetInfo.batterySettings &&
                            showAssetInfo.batterySettings}
                        />
                      </Grid>

                    </>
                  )
                }



                {/* <Paper style={{ margin: '20px 0px' }}> */}
                <Grid container style={{ margin: "20px 0px" }}>
                  <Grid item xl={2} lg={2} md={4} sm={4} xs={6} className={classes.subheading}>
                    List of Events
                  </Grid>
                  <Grid item xl={10} lg={10} md={8} sm={8} xs={6}>
                    <Divider fullWidth style={{ marginTop: "2rem" }} />
                  </Grid>
                </Grid>

                <SingleChamberEventLogsGrid
                  rowData={rowData}
                  onGridReady={this.onGridReady}
                  companySettings={CompanyInfo}
                  handleDeviceNoteChange={this.handleDeviceNoteChange}
                />
                {/* </Paper> */}

              </div>
              {/* pdf dialog */}
              {
                isLoading ?
                  <div
                    style={{
                      position: "fixed",
                      display: "block",
                      width: "100%",
                      height: "100%",
                      top: "0",
                      left: "0",
                      right: "0",
                      bottom: "0",
                      backgroundColor: "rgba(0,0,0,0.5)",
                      zIndex: "2",
                    }}
                  >
                    <CircularProgress
                      style={{ position: "absolute", top: "50%", left: "50%" }}
                    />
                  </div> :
                  <>
                    <Dialog
                      open={openPDFDialog}
                      onClose={this.handlePDFDialogClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      maxWidth="sm"
                      fullWidth
                      scroll="body"
                      TransitionComponent={Grow}
                      PaperProps={{
                        style: {
                          backgroundColor: '#f2f2f2',
                        },
                      }}
                    >
                      <DialogTitle id="alert-dialog-title" className={classes.title}>Report</DialogTitle>
                      <DialogContent style={{ minHeight: "150px", overflow: "hidden" }}>
                        <Grid container style={{ padding: '1rem' }} >
                          <Grid item sm={4} className={classes.downloadDialog}>
                            Time Zone
                          </Grid>
                          <Grid item sm={8} className={classes.formControl}  >
                            <ReactSelect
                              value={selectedOption}
                              onChange={this.handleReactSelectChange}
                              options={timeZoneOptions}
                              styles={selectStyles}
                            />
                          </Grid>

                        </Grid>
                        <Grid container style={{ padding: '1rem' }} >
                          <Grid item xs={4} className={classes.downloadDialog}>
                            Data Interval
                          </Grid>
                          <Grid item xs={8} >
                            <FormControl className={classes.formControl}>

                              <SelectField
                                variant="standard"
                                value={frequency}
                                onChange={(event) => {
                                  this.handleSelectChange(event.target.value);
                                }}
                                inputProps={{
                                  name: "Frequency",
                                  id: "frequency",
                                }}
                                backgroundColor="#fff"
                              >
                                <MenuItem value={15}>15 min</MenuItem>
                                <MenuItem value={30}>30 min</MenuItem>
                                <MenuItem value={60}>1 hr</MenuItem>
                              </SelectField>
                            </FormControl>

                          </Grid>

                        </Grid>
                        {/* if humidty is off for all devices , then hide this option , and by default send temperature*/}
                        {!hide && (
                          <Grid container style={{ padding: '1rem' }}  >
                            <Grid item xs={4} className={classes.downloadDialog}>
                              Data Type
                            </Grid>
                            <Grid item xs={8}>
                              <FormControl className={classes.formControl}>

                                <SelectField
                                  variant="standard"
                                  value={dataType}
                                  onChange={(event) => {
                                    this.handleDataTypeChange(event.target.value);
                                  }}
                                  inputProps={{
                                    name: "DATA TYPE",
                                    id: "dataType",
                                  }}
                                  backgroundColor="#fff"
                                >
                                  <MenuItem value="all">All</MenuItem>
                                  <MenuItem value="temperature">Only Temperature</MenuItem>
                                  <MenuItem value="humidity">Only Humidity</MenuItem>
                                </SelectField>
                              </FormControl>
                            </Grid>
                          </Grid>
                        )}

                        {/* event logs option */}
                        <Grid container style={{ padding: '1rem' }}  >
                          <Grid item xs={4} className={classes.downloadDialog}>
                            Download report
                          </Grid>
                          < Grid item xs={8}>
                            <FormControl className={classes.formControl}>

                              <SelectField
                                variant="standard"
                                value={isEvents}
                                onChange={(event) => {
                                  this.handleEventLogsChange(event.target.value);
                                }}
                                inputProps={{
                                  name: "EVENT TYPE",
                                  id: "isEvents",
                                }}
                                backgroundColor="#fff"
                              >

                                <MenuItem value="1">With Event Logs</MenuItem>
                                <MenuItem value="0">Without Event Logs</MenuItem>
                              </SelectField>
                            </FormControl>
                          </Grid>
                        </Grid>

                        {
                          CompanyInfo && CompanyInfo.settings.enableExcelDownload ? (
                            <Grid container style={{ padding: '1rem' }} >
                              <Grid item xs={4} className={classes.downloadDialog}>
                                Report Type
                              </Grid>
                              <Grid item xs={8}>
                                <FormControl className={classes.formControl}>

                                  <SelectField
                                    variant="standard"
                                    value={reportType}
                                    onChange={(event) => {
                                      this.handleReportTypeChange(event.target.value);
                                    }}
                                    inputProps={{
                                      name: "REPORT TYPE",
                                      id: "reportType",
                                    }}
                                    backgroundColor="#fff"
                                  >
                                    <MenuItem value="pdf">PDF</MenuItem>
                                    <MenuItem value="excel">EXCEL</MenuItem>
                                  </SelectField>
                                </FormControl>
                              </Grid>
                            </Grid>
                          ) :
                            null
                        }



                      </DialogContent>
                      <DialogActions>
                        <SecondaryButton text=" Cancel" onClick={this.handlePDFDialogClose} color="primary" />

                        <PrimaryButton
                          onClick={
                            CompanyInfo &&
                              CompanyInfo.settings.enableExcelDownload &&
                              reportType === "excel"
                              ? () => {
                                this.handleExcelDownload(
                                  selectedOption.value,
                                  frequency,
                                  dataType,
                                  hide
                                );
                              }
                              : () => {
                                this.handlePDFDownload(
                                  selectedOption.value,
                                  frequency,
                                  dataType,
                                  isEvents,
                                  hide,
                                );
                              }
                          }
                          color="primary"
                          text="Download"
                        />

                      </DialogActions>
                    </Dialog>
                  </>
              }




            </div>

          </Grow>
          {/* </Slide> */}


        </>


        {/* <Dialog
          open={chamberInfoDialog}
          TransitionComponent={Zoom}
          onClose={this.toggleChamberInfoDialog}
          aria-labelledby="form-dialog-title"
          maxWidth="lg"
          scroll="body"
        >
          <DialogTitle id="form-dialog-title">
            <span className={classes.chamber}>
              {assetInfo && assetInfo.id ? assetInfo.id : 'N/A'}
            </span>
          </DialogTitle>
          <DialogContent style={{ width: '60vw' }}>
            <ExpansionPanel
              expanded={expanded === 'chamber'}
              onChange={this.handleChange('chamber')}
            >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <p className={classes.chamber_subHeading}>Chamber Details</p>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div style={{ display: 'flex' }}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <p className={classes.chamber_contentLabel}>Warehouse:</p>
                    <p className={classes.chamber_contentLabel}>
                      Temperature Range:
                    </p>
                    <p className={classes.chamber_contentLabel}>
                      Humidity Range:
                    </p>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginLeft: '10px',
                    }}
                  >
                    <p className={classes.chamber_contentText}>
                      {assetInfo && assetInfo.assetGroupId
                        ? assetInfo.assetGroupId
                        : 'N/A'}
                    </p>
                    <p className={classes.chamber_contentText}>
                      {assetInfo
                      && assetInfo.temperatureSettings
                      && assetInfo.temperatureSettings.maxViolation
                      && assetInfo.temperatureSettings.minViolation
                        ? `${assetInfo.temperatureSettings.maxViolation}-${assetInfo.temperatureSettings.minViolation} °C`
                        : 'N/A'}
                    </p>
                    <p className={classes.chamber_contentText}>
                      {assetInfo
                      && assetInfo.humiditySettings
                      && assetInfo.humiditySettings.maxViolation
                      && assetInfo.humiditySettings.minViolation
                        ? `${assetInfo.humiditySettings.maxViolation}-${assetInfo.humiditySettings.minViolation}%`
                        : 'N/A'}
                    </p>
                  </div>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel
              expanded={expanded === 'devices'}
              onChange={this.handleChange('devices')}
            >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <p className={classes.chamber_subHeading}>Devices</p>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <DevicesGrid
                  rowData={devicesForGrid}
                  onGridReady={this.onGridReady}
                />
              </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel
              expanded={expanded === 'clients'}
              onChange={this.handleChange('clients')}
            >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <p className={classes.chamber_subHeading}>Clients</p>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <p className={classes.chamber_contentText}>
                  {assetInfo && assetInfo.client ? assetInfo.client : 'N/A'}
                </p>
              </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel
              expanded={expanded === 'users'}
              onChange={this.handleChange('users')}
            >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <p className={classes.chamber_subHeading}>Users</p>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <p className={classes.chamber_contentText}>
                  {assetInfo && assetInfo.users ? assetInfo.users : 'N/A'}
                </p>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.toggleChamberInfoDialog} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog> */}


      </div>
    );
  }
}

SingleChamber.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  // selectedAsset: PropTypes.instanceOf(Object).isRequired,
  // selectedQuickOption: PropTypes.number.isRequired,
  // handleQuickOptionsChange: PropTypes.func.isRequired,
  // onBackClick: PropTypes.func.isRequired,
};


export default withStyles(styles)(SingleChamber);
