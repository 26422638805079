/* eslint-disable react/prop-types */
import React from 'react';
import {
  List, ListItem, ListItemAvatar, ListItemText,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { useMediaQuery, createTheme } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import ClickAwayListener from "@mui/material/ClickAwayListener";

// import moment from 'moment';


const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  list: {
    width: '100%',
    height: '30vh',
    overflowY: 'scroll',
    // backgroundColor: theme.palette.background.paper,
  },
  heading: {
    // fontSize: theme.typography.pxToRem(17),
    // fontWeight: theme.typography.fontWeightBold,
  },
 
  accordion: {
    position: 'relative',
  },
}));

export default function Notification({ expanded, handleClose, warningAndViolationData }) {
  const classes = useStyles();
const reverseList=[...warningAndViolationData]
  

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});
const showImginNotification = useMediaQuery(theme.breakpoints.up('md'));


  return (
    <div className={classes.root}>
       <ClickAwayListener onClickAway={handleClose}>
      <Accordion className={classes.accordion} expanded={expanded}>
        <AccordionSummary
          expandIcon={<CloseIcon onClick={handleClose} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Live Notifications</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List className={classes.list}>
            {
              reverseList && reverseList.length > 0 ? reverseList
            .reverse().map((warning) => (
                 
                <ListItem key={warning}>
                  { showImginNotification  ?  <ListItemAvatar>
                   <img src={`/img/${warning[3]}.png`} alt="icon" style={{width:"4rem" , height:"4rem"}}  />
                 </ListItemAvatar> :
                 " "
                  
                  }
                 
                  <ListItemText primary={warning[0]} secondary={warning[1]} />
                
                </ListItem>
              )) : (
                <span style={{ fontSize: '1rem' }}> No Events Found </span>
              )
            }
          </List>
         
         
           
         
        </AccordionDetails>
      </Accordion>
      </ClickAwayListener>
    </div>
  );
}
// export default Notification;
//  secondary={warning[0].substring(warning[0].indexOf(" % ") + 1) }