import React from "react";
import { withStyles } from "@mui/styles";
import Battery60RoundedIcon from '@mui/icons-material/Battery60Rounded';

const styles = {
    outerDiv: () => ({
      display: "flex",
      height: "100%",
      width: "100%",
    }),
    modularDiv: (props) => ({
      display: "flex",
      borderRight: `1px solid ${props.borderColor}`,
      alignItems: "center",
      fontSize: props.fontSize,
      // width: (props.width),
      borderLeft: `1px solid ${props.borderColor}`,
      width: "75px",
    }),
    icon: (props) => ({
      maxHeight: "3.5em",
      paddingLeft: "0.8em",
      fontSize: props.fontSize,
    }),
    text: (props) => ({
      margin: "0px",
      fontFamily: "Montserrat",
      fontSize: props.fontSize,
      color: props.textColor,
      marginLeft:'5px'
   
    }),
    noViolationsDiv: {
      display: "flex",
      // justifyContent: 'center',
      alignItems: "center",
      height: "auto",
      width: "100%",
    },
    noViolationsText: (props) => ({
      margin: "0px",
      fontFamily: "Montserrat",
      fontSize: props.fontSize,
      color: props.textColorForNoViolations,
      // paddingLeft: '0.8em',
    }),
  };

const StripsofBattery=(props)=>{
const {classes, params}=props;
// console.log('Params are', params.data)

const gettempValue=()=>{
    if (params.data.lastPing && params.data.lastPing.battery ){
        return `${(params.data.lastPing.battery)}%`;
      }return ' - ';

}

// const getIcon=()=>{
//     const {violations, warnings}= params && params.data;
//     if(!violations && !warnings)return;
//    if(violations && warnings){
//     if(violations && violations.battery > 0  ){
//         return (
//             <Battery60RoundedIcon style={{ color: 'rgb(255, 86, 48)', fontSize: '1.5em',marginLeft:'-5px' }} />
//         )
//     }
//     if(warnings && warnings.battery > 0){
//         return (
//             <Battery60RoundedIcon style={{ color: 'rgb(255, 171, 0)', fontSize: '1.5em',marginLeft:'-5px' }} />
//         )
//     }
//    }
//    if(violations && !warnings){
//     if(violations && violations.battery  ){
//         return(
//             <Battery60RoundedIcon style={{ color: 'rgb(255, 86, 48)', fontSize: '1.5em',marginLeft:'-5px' }} />
//         )
//     }
//    }
//    if(!violations && warnings){
//     if(warnings && warnings.battery ){
//         return(
//             <Battery60RoundedIcon style={{ color: 'rgb(255, 171, 0)', fontSize: '1.5em',marginLeft:'-5px' }} />
//         )
//     }
//    }

// }

const getIcon=()=>{
  const {assetBatterySettings }= params && params.data;
  if(!assetBatterySettings )return;

let deviceStatus= "noVio";
const batteryLastPing=Number((params && params.data && params.data.lastPing && params.data.lastPing.battery));
const criticalBattery=Number(assetBatterySettings?.critical)
const warningBattery=Number(assetBatterySettings?.warning)
 
const isVio=  batteryLastPing < criticalBattery
|| batteryLastPing < 10
    


const isWarned =  batteryLastPing < warningBattery 
|| batteryLastPing < 25 && batteryLastPing >= 10

     if(isVio){
      deviceStatus ='Violation'
    }
    if(!isVio && isWarned){
      deviceStatus = 'Warning'
    }

    if( deviceStatus === 'Violation'){
      return (
        <Battery60RoundedIcon style={{ color: 'rgb(255, 86, 48)', fontSize: '1.5em',marginLeft:'-5px' }} />
         )
    }
    if( deviceStatus === 'Warning'){
      return (
        <Battery60RoundedIcon style={{ color: 'rgb(255, 171, 0)', fontSize: '1.5em',marginLeft:'-5px' }} />
         )
    }
    
}

const Icon= getIcon()

const tempValue=gettempValue()
return(
 
    <div>
         <div className={classes.outerDiv}>
         <div className={classes.modularDiv} >

         
            {Icon}
            <p className={classes.text}>
            {tempValue}
                </p>
          </div>
         </div>
    </div>



)


}


export default withStyles(styles)(StripsofBattery);