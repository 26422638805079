import React from 'react';
import Select from '@mui/material/Select';


// export const SelectStyles = styled(Select)(() => ({
//   backgroundColor: '#fff',
//   borderRadius: '22px',
//   fontSize: '14px',
//  width:'100%'
//   // padding: '4px',
// }));


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP,
      // width: 350,
    },
  },
};

const SelectField = (props) => (
  <Select
    {...props}
    fullWidth
    disableUnderline
    MenuProps={MenuProps}
  //   MenuProps={{
  //     PaperProps: {
  //       onScroll: loadMoreItems,
  //     },
  //     style: {
  //       maxHeight: 500,
  //     },
  //  }}
    SelectDisplayProps={{
      disableUnderline: true,
      style: {
        backgroundColor: props.backgroundColor,
        borderRadius: '22px',
        color: '#000',
        fontSize: '14px',
        border:'none',
        width:'100%',
        textAlign:'center',
        padding:'7px',
        
      },
    }}
  />
);
export default SelectField;

// import { Select } from "@mui/material";
// import { styled } from "@mui/material/styles";

// export const SelectStyled = styled(Select)(() => ({
//   marginTop: "10px",
//   borderRadius: "22px",
//   height: "2.25rem",
//   backgroundColor: "#f2f2f2",
//   border:'none'
// }));

// export function SelectField(props) {
//   return (
//     <SelectStyled {...props} />
//   );
// }