import React, { useReducer, useContext, useEffect, useState } from "react";
import { withStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import DashboardSettings from "./DashboardSettings";
import DownloadSettings from "./DownloadSettings";
import CompanyLogoSettings from "./CompanyLogoSettings";
import PeriodicReportSettings from "./PeriodicReportSettings";
import Integration from "../Integration";
import PrimaryButton from "components/PrimaryButton";
import DataSettings from "./DataSettings";
import API from "API/apiConfig";
import UserContext from "Context/UserContext";
import { useMediaQuery, createTheme } from "@material-ui/core";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { AccordionDetails } from "@mui/material";
import { styled } from "@mui/material/styles";
import cogoToast from "cogo-toast";
import GoogleNestBanner from "../GoogleNest/GoogleNestBanner";
import GoogleNest from "../GoogleNest/GoogleNest";

const styles = {
  heading: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: "36px",
    marginTop: "2rem",
    textAlign: "left",
    marginLeft: "2rem",
  },
};

export const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));
const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const CompanySettings = () => {
  const {
    CompanyInfo,
    accountUser,
    // CompanySettings,
    updateCompanyLogo,
    updateCompanyInfo,
    updateSingleChamber,
  } = useContext(UserContext);

  const APIURL = process.env.REACT_APP_APIURL;



  const settingsReducer = (prevSettingsState, action) => {
    // console.log("action", action.type, action.payload);
    switch (action.type) {
      case "WAREHOUSENAME":
        return { ...prevSettingsState, warehouseType: action.payload };
      case "CHAMBERNAME":
        return { ...prevSettingsState, chamberType: action.payload };
      case "EXCEL":
        return { ...prevSettingsState, enableExcelDownload: action.payload };
      case "PERIOD":
        return { ...prevSettingsState, period: action.payload };
      case "USERSLIST":
        // console.log("action excureted", action.payload);
        return { ...prevSettingsState, users: action.payload };
      case "DEVICELIST":
        return { ...prevSettingsState, device: action.payload };
      default:
        return { ...prevSettingsState };
    }
  };

  const [companysettingsState, companysettingsDispatchFn] = useReducer(
    settingsReducer,
    {
      enableExcelDownload: false,
      warehouseType: " ",
      chamberType: " ",
      instantNotification: false,
      period: "none",
      users: [],
      device: [],
    }
  );


  const [expanded, setExpanded] = useState("companyProfile");
  const panels = ["companyProfile", "integrations", "googlenest"];//for google nest
  // const panels = ["companyProfile", "integrations"]

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
  });

  const breakPoint = useMediaQuery(theme.breakpoints.up("sm"));

  useEffect(() => {
    updateSingleChamber(false);
  }, []);

  const getUserRole = () => {
    let userRole;
    // let company;
    if (accountUser && accountUser.resource_access && accountUser.companyId) {
      const resources = accountUser.resource_access;
      const companyId = accountUser.companyId.map((id) => id);
      const groupAttribute = accountUser.groupAttributes;
      const groupName = Object.keys(groupAttribute);
      const clientArr = [];
      if (groupName && groupName.length > 0) {
        groupName.map((group) => {
          const groupRole = group.split('#');
          groupRole[0] = groupRole[0].substring(accountUser.companyId[0].length + 1);
          groupRole[0] = `${groupRole[0]}[${groupRole[1]}]`;
          clientArr.push(groupRole[0]);
        });
      }
      userRole = resources[companyId.map((id) => id)] ? resources[companyId.map((id) => id)].roles : [];
      return userRole.length ? `[${userRole.map((r) => {
        if (r.startsWith(accountUser.companyId[0])) {
          if ((r.substring(accountUser.companyId[0].length + 1)) === 'default_client') {
            return (clientArr);
          } return (r.substring(accountUser.companyId[0].length + 1));
        }
        return r;
      }).join(',')}]` : 'No Role Assigned';
    }
    userRole = 'No Role Assigned';
    return userRole;
  };



  const handleCompanyLogoUpdate = () => {
    API.get(`${APIURL}/companies`)
      .then((companyRes) => {
        if (companyRes.data && companyRes.data.status === "success") {
          const uploadedLogo = companyRes.data.data;
          updateCompanyLogo(uploadedLogo);
        } else {
          console.error("Failed to get company info");
        }
      })
      .catch((companyErr) => {
        console.error("Http error. Failed to get company info", companyErr);
      });
  };

  const handleUpdateCompanyProfile = (event) => {
    event.preventDefault();
    const { enableExcelDownload, warehouseType, chamberType, period, users, device } =
      companysettingsState;
    const companySettingsOld =
      CompanyInfo && CompanyInfo.settings ? CompanyInfo.settings : {};

    const updatedUsersList = [...new Set(users)];
    const updatedDeviceList = [...new Set(device)];
    // console.log('updatedDeviceList',updatedDeviceList, device);

    const newSettings = {
      ...companySettingsOld,
      enableExcelDownload,
      warehouseType,
      chamberType,
      reports: { period, users: updatedUsersList },
      data: { humidity: { disableDeviceTypes: updatedDeviceList } }
    };
    // console.log(newSettings);
    const patchArray = [
      {
        op: "add",
        path: "/settings",
        value: newSettings,
      },
    ];

    API.patch(`${APIURL}/companies`, patchArray)
      .then((resp) => {
        if (resp.status === 200 && resp.data.status === "success") {
          updateCompanyInfo(newSettings);
          cogoToast.success("Company Settings have been updated", {
            position: "top-right",
          });
        } else {
          cogoToast.error("Company Settings update failed", {
            position: "top-right",
          });
        }
      })
      .catch(() => {
        cogoToast.error("Failed updating company settings", {
          position: "top-right",
        });
      });
  };

  // for google nest
  const handleChange = (panel) => (_event, newExpanded) => {

    const newPanel = panel === panels[0] ? panels[1] : panel === panels[2] ? panels[0] : panels[2];

    setExpanded(newExpanded ? panel : newPanel);
  };

  //  const handleChange = (panel) => (_event, newExpanded) => {
  //   const newPanel = panel === panels[0] ? panels[1] : panels[0];
  //   setExpanded(newExpanded ? panel : newPanel);
  // };


  const routeToDashboard = () => {

    cogoToast.error('You do not have permission to view Company Settings', { position: 'top-right' });
  };

  return (
    <>
      {getUserRole().includes('SuperUser') ?
        <>
          <div style={{ textAlign: "left", marginTop: '2rem' }}>
            {/* Company Profile */}
            <Accordion
              expanded={expanded === "companyProfile"}
              onChange={handleChange("companyProfile")}>

              <AccordionSummary style={{ backgroundColor: '#fff' }}>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: "bold",
                    // fontSize: "36px",

                  }}
                >
                  Company Profile
                </Typography>
              </AccordionSummary>
              <AccordionDetails >
                {/* Dashboard settings */}
                <Grid container item xs={12} lg={12} md={12} alignItems="center">
                  <Grid item xl={3} lg={4} md={5} sm={6} xs={9}>
                    <Typography
                      variant="h4"
                      component="div"
                      sx={{
                        fontFamily: "Montserrat",
                        fontWeight: "bold",
                        fontSize: "1.2rem",
                        margin: breakPoint ? "2rem" : "0.8rem",
                      }}
                    >
                      Dashboard Settings
                    </Typography>
                  </Grid>
                  <Grid item xl={9} lg={8} md={7} sm={6} xs={3}>
                    <Divider fullWidth light={false} style={{ color: "#000" }} />
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  style={{ marginLeft: breakPoint ? "3rem" : "1rem" }}
                >
                  <DashboardSettings
                    companysettingsState={companysettingsState}
                    companysettingsDispatchFn={companysettingsDispatchFn}
                  />
                </Grid>

                {/* Excel Download */}
                <Grid container item xs={12} lg={12} md={12} alignItems="center">
                  <Grid item xl={3} lg={4} md={5} sm={6} xs={8}>
                    <Typography
                      variant="h4"
                      component="div"
                      sx={{
                        fontFamily: "Montserrat",
                        fontWeight: "bold",
                        fontSize: "1.2rem",
                        margin: breakPoint ? "2rem" : "0.9rem",
                      }}
                    >
                      Download Settings
                    </Typography>
                  </Grid>
                  <Grid item xl={9} lg={8} md={7} sm={6} xs={4}>
                    <Divider fullWidth light={false} style={{ color: "#000" }} />
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  style={{ marginLeft: breakPoint ? "3rem" : "1rem" }}
                >
                  <DownloadSettings
                    companysettingsState={companysettingsState}
                    companysettingsDispatchFn={companysettingsDispatchFn}
                  />
                </Grid>

                {/* Instance Notification Settings */}
                <Grid container item xs={12} lg={12} md={12} alignItems="center">
                  <Grid item xl={3} lg={4} md={5} sm={6} xs={8}>
                    <Typography
                      variant="h4"
                      component="div"
                      sx={{
                        fontFamily: "Montserrat",
                        fontWeight: "bold",
                        fontSize: "1.2rem",
                        margin: breakPoint ? "2rem" : "1rem",
                      }}
                    >
                      Periodic Report Settings
                    </Typography>
                  </Grid>
                  <Grid item xl={9} lg={8} md={7} sm={6} xs={4}>
                    <Divider fullWidth light={false} style={{ color: "#000" }} />
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  style={{ marginLeft: breakPoint ? "3rem" : "1rem" }}
                >
                  <PeriodicReportSettings
                    companysettingsState={companysettingsState}
                    companysettingsDispatchFn={companysettingsDispatchFn}
                  />
                </Grid>

                {/* Data Settings */}
                <Grid container item xs={12} lg={12} md={12} alignItems="center">
                  <Grid item xl={3} lg={4} md={5} sm={6} xs={8}>
                    <Typography
                      variant="h4"
                      component="div"
                      sx={{
                        fontFamily: "Montserrat",
                        fontWeight: "bold",
                        fontSize: "1.2rem",
                        margin: breakPoint ? "2rem" : "0.9rem",
                      }}
                    >
                      Data Settings
                    </Typography>
                  </Grid>
                  <Grid item xl={9} lg={8} md={7} sm={6} xs={4}>
                    <Divider fullWidth light={false} style={{ color: "#000" }} />
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  style={{ marginLeft: breakPoint ? "3rem" : "1rem" }}
                >
                  <DataSettings
                    companysettingsState={companysettingsState}
                    companysettingsDispatchFn={companysettingsDispatchFn} />
                </Grid>

                {/* Company Logo Settings */}
                <Grid container item xs={12} lg={12} md={12} alignItems="center">
                  <Grid item xl={3} lg={4} md={5} sm={7} xs={8}>
                    <Typography
                      variant="h4"
                      component="div"
                      sx={{
                        fontFamily: "Montserrat",
                        fontWeight: "bold",
                        fontSize: "1.2rem",
                        margin: breakPoint ? "2rem" : "1rem",
                      }}
                    >
                      CompanyLogo Settings
                    </Typography>
                  </Grid>
                  <Grid item xl={9} lg={8} md={7} sm={5} xs={3}>
                    <Divider fullWidth light={false} style={{ color: "#000" }} />
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  style={{ marginLeft: breakPoint ? "3rem" : "1rem" }}
                >
                  <CompanyLogoSettings
                    companyInfo={CompanyInfo}
                    handleCompanyLogoUpdate={handleCompanyLogoUpdate}
                  />
                </Grid>

                <Grid container item spacing={2}>
                  <Grid item xs={12}>
                    <Divider
                      fullWidth
                      light={false}
                      style={{ color: "#000", marginTop: "2rem" }}
                    />
                  </Grid>
                </Grid>

                <Grid container item style={{ marginTop: "2rem" }}>
                  <Grid item lg={10} md={8} sm={7} xs={5} />
                  <Grid item lg={2} md={4} sm={4} xs={7}>
                    <PrimaryButton
                      text="Update All Changes "
                      onClick={handleUpdateCompanyProfile}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            {/* Intergartions  */}
            <Accordion
              expanded={expanded === "integrations"}
              onChange={handleChange("integrations")}
            >
              <AccordionSummary style={{ backgroundColor: '#fff' }}>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: "bold",
                    // fontSize: "36px",
                    // marginTop: "2rem",
                  }}
                >
                  Integrations
                </Typography>
              </AccordionSummary>
              <AccordionDetails>

                <Grid container item>
                  <Integration />
                </Grid>
              </AccordionDetails>
            </Accordion>

            {/* Google Nest */}
            <Accordion
              expanded={expanded === "googlenest"}
              onChange={handleChange("googlenest")}
            >
              <AccordionSummary style={{ backgroundColor: '#fff' }}>

                <Typography
                  variant="h5"
                  component="div"
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: "bold",
                    // fontSize: "36px",
                    // marginTop: "2rem",
                  }}
                >
                  Google Nest
                  <sup style={{
                    verticalAlign: 'super',
                    fontSize: '14px',
                    marginLeft: '3px',
                    color:"rgba(0,0,0,0.6)"
                  }}>beta</sup>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>


                {
                  CompanyInfo && CompanyInfo.settings &&
                    CompanyInfo.settings.smartNotifications ?
                    <GoogleNest expanded={expanded} />
                    :
                    <GoogleNestBanner />

                }


              </AccordionDetails>
            </Accordion>

          </div>
        </> :

        routeToDashboard()

      }
    </>
  );
};

export default withStyles(styles)(CompanySettings);
