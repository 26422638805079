import React, { Component } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
// // import withStyles from 'react-jss';
import * as yup from "yup";

import { withStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import axios from "axios";
import moment from "moment";
import io from "socket.io-client";
import cogoToast from "cogo-toast";
import API from "API/apiConfig";
// import { ThreeSixty, TimerSharp } from '@mui/icons-material';
// import permissions from 'redux-helper/reducers/permision';
import keycloak from "utils/keycloak";
import DashboardOption from "routes/Dashboard/DashboardOption";
import CompanySettings from "routes/Settings/CompanySettings/CompanySettings";
import TopNavbar from "./components/TopNavbar";
import Dashboard from "./routes/Dashboard/Dashboard";

import EventLogs from "./routes/EventLogs/EventLogs";
import CalendarView from "routes/Events Summary/EventSummary";
// import Reports from './routes/Reports/Reports';
import ControlPanel from "./routes/ControlPanel/ControlPanel";

// import SharedDashboard from './routes/SharedDashboard/SharedDashboard';
import Settings from "./routes/Settings/Settings";
// import Configuration from 'routes/Settings/Configuration';
import { CtoF } from "./utils/helpers";
// import { sendToLogin } from './utils/helpers';
// import { setPermissions } from './redux-helper/actions/actions';
import toastOptions from "./utils/toast";
import UserContext from "./Context/UserContext";
import DeviceView from "routes/Dashboard/DeviceView";
import Document from "./routes/ControlPanel/Documentation/Document";


axios.defaults.withCredentials = true;

const styles = {
  imageContainer: {
    width: "140px",
    margin: "auto",
    marginTop: "10px",
    position: "absolute",
  },
  errorMessageDiv: {
    MarginTop: "20%",
    height: "20px",
    textAlign: "left",
  },
  errorMessageText: {
    fontFamily: "Montserrat",
    fontSize: "12px",
    textAlign: "left",
    color: "red",
    marginTop: "15px",
  },
  inputTextField: {
    border: "none",
    borderBottom: "1px solid grey",
    width: "100%",
    height: "30px",
    outline: "none",
    "&:focus": {
      borderBottom: "1px solid #00adef",
    },
  },
  inputLabel: {
    opacity: "0.5",
    fontFamily: "Montserrat",
    fontSize: "12px",
    textAlign: "left",
    marginTop: "10px",
    color: "rgba(3, 0, 0, 0.5)",
  },
  modalDialogOuterDiv: {
    display: "flex",
    justifyContent: "center",
  },
  modalDialogDiv: {
    display: "flex",
    width: "50%",
    flexDirection: "column",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  modalDisplayDiv: {
    height: "35px",
    display: "flex",
    alignItems: "center",
    color: "#333",
  },
  outerDiv: {
    width: "100%",
    height: "100%",
    boxShadow: "1px 1.7px 7px 0 rgba(136, 156, 176, 0.1)",
    backgroundColor: "#ffffff",
  },
  externalDiv: {
    height: "auto",
  },
  mainControlDiv: {
    height: "50px",
  },
  controlDiv: {
    height: "inherit",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  topDiv: {
    width: "100%",
    height: "50px",
    display: "flex",
    justifyContent: "space-between",
  },
  importantField: {
    color: "red",
  },
  submitBtnDiv: {
    width: "50%",
    margin: "auto",
    display: "flex",
    justifyContent: "flex-end",
  },
  // accordion: {
  //   display: 'inline-block',
  //   bottom: 10,
  //   position: 'fixed',
  // },
  // closedAccordion: {
  //   display: 'none',
  // },
  // notificationDiv: {
  //   width: '25%',
  //   marginLeft: '60%',
  //   bottom: 10,
  //   position: 'fixed',
  // },
  // extendedIcon: {
  //   marginLeft: '-45%',
  // },
};

const routes = [
  {
    path: "dashboard",
    name: "Dashboard",
    component: (props) => <Dashboard {...props} />,
  },
  {
    path: "dailyEvents",
    name: "Reports",
    component: (props) => <CalendarView {...props} />,
  },
  {
    path: "eventlogs",
    name: "Event Logs",
    component: (props) => <EventLogs {...props} />,
  },
  {
    path: "controlpanel",
    name: "Control Panel",
    component: (props) => <ControlPanel {...props} />,
  },

  {
    path: "deviceView",
    name: "DeviceView",
    component: (props) => <DeviceView {...props} />,
  },
  {
    path: "myaccount",
    name: "Settings",
    component: (props) => <Settings {...props} />,
  },
  {
    path: "company",
    name: "Company",
    component: (props) => <CompanySettings {...props} />,
  },
  {
    path: "documentation",
    name: "Documentation",
    component: (props) => <Document {...props} />,
  },
];

const APIURL = process.env.REACT_APP_APIURL;
export const GRID_SPACING = 0;

const PasswordsSchema = yup.object().shape({
  newPassword: yup
    .string()
    .required("Password cannot be empty")
    .min(8, "Passwords should contain minimum 8 characters"),
  confirmPassword: yup
    .string()
    .required("Confirm Password cannot be empty")
    .min(8, "Passwords should contain minimum 8 characters"),
});

// const ThemeContext = React.createContext('light');

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      socketsConnected: false,
      currentView: "DashboardView",
      // tokenVerified: false,
      user: null,
      companyInfo: null,
      pingFromSocket: null,
      deviceAlertStatus: null,
      initials: null,
      passwordChangeDialog: false,
      clientEnabled: false,
      regionEnabled: false,
      errorMessage: null,
      keycloak: null,
      isLoading: true,
      tokenVerified: false,
      userDetails: [],
      getDevicesFromServer: [],
      setClient: [],
      myvalue: "Myvalue",
      userpermissions: {},
      accountUser: [],
      expanded: false,
      violationsAndWarnings: [],
      counter: 0,
      singleChamberValue: false,
      assetGroupsList: [],
      deviceViewinSideDrawer: false,
      date: [moment().startOf("day").toDate(), moment().toDate()],
      assetGroups: [],
      assetGrouplistforContext:[]

    };
  }

  handleChange = () => {
    this.setState({
      expanded: !this.state.expanded,
      counter: 0,
    });
  };

  getvalueofDeviceView = (deviceviewvalue) => {
    console.log("deviceviewvalue", deviceviewvalue);
    this.setState({
      deviceViewinSideDrawer: true,
    });
  };

  getCompanyInfo = () => {
    API.get(`${APIURL}/companies`)
      .then((companyRes) => {
        // console.log('company Info is +++', companyRes.data);
        if (companyRes.data && companyRes.data.status === "success") {
          const companyInfo = companyRes.data.data;
          this.setState({
            companyInfo,
            // userpermissions:
          });
        } else {
          console.error("Failed to get company info");
        }
      })
      .catch((companyErr) => {
        console.error("Http error. Failed to get company info", companyErr);
      });
  };

  getUserInfo = () => {
    API.get(`${APIURL}/userinfo`)
      .then((userInfoResponse) => {
        // console.log('USER INfo  is +++', userInfoResponse.data);
        if (
          userInfoResponse &&
          userInfoResponse.status === 200 &&
          userInfoResponse.data.status === "success"
        ) {
          const userData = userInfoResponse.data.data;
          this.setState({
            userDetails: userData, // stoaring in react context
          });
        }
      })
      .catch(() => {
        // console.log(
        //   'error getting user info from user profile',
        //   error.message || error,
        // );
        cogoToast.error("error getting user info", {
          position: "top-right",
          heading: "Error",
        });
      });
  };

  // geting the list of all the devices
  initialDevicesFromServer = () => {
    API.get(`${APIURL}/devices`)
      .then((response) => {
        if (response.data.status && response.data.status === "success") {
          const initialDevices = response.data.data.map((item) => item);

          this.setState({
            getDevicesFromServer: initialDevices, // stoaring in react context
          });
        } else {
          // console.log('Error getting devices ', response.data.message);
          cogoToast.error(
            response.data.message
              ? response.data.message
              : "Please assign a role to view devices",
            toastOptions
          );
        }
      })
      .catch(() => {
        // cogoToast.error(error, toastOptions);
      });
  };

  // geting the list of all the devices
  initialClientsFromServer = () => {
    API.get(`${APIURL}/clients`)
      .then((clientsResponse) => {
        const clientsData = clientsResponse;
        if (clientsData) {
          this.setState({
            setClient: clientsData, // stoaring in react context
          });
          // setClients(clientsData)  // redux store
        }
      })
      .catch((clientsError) => {
        console.log(
          "error getting clients in live container",
          clientsError.message || clientsError
        );
      });
  };

  // geting the list of all the clients
  initialUsersFromServer = () => {
    API.get(`${APIURL}/usersList`)
      .then((usersResponse) => {
        if (
          usersResponse &&
          usersResponse.status &&
          usersResponse.status === 200
        ) {
          if (usersResponse.data && usersResponse.data.data) {
            const initialUsers = usersResponse.data.data.filter(
              (user) => user.role !== "uma_protection"
            );
            this.setState({
              getUsersFromServer: initialUsers, // react context
            });
          }
        } else {
          console.log("Error loading users ", usersResponse.data.message);
        }
      })
      .catch((error) => {
        console.error("response clients error", error);
      });
  };

  // geting the list of all the assetgroups along with assets
  initialassetgroups = () => {
    const { date } = this.state;
    // console.log('AssetsGroup are' );
    const startInUnix = moment(date[0]).unix();
    const endInUnix = moment(date[1]).unix();
    API.get(
      `${APIURL}/assetDashboard?startTime=${startInUnix}&endTime=${endInUnix}`
    ).then((resp) => {
      const response = resp.data.assetGroups;
      this.setState({
        assetGroups: response,
      });
    });
  };

  // geting the list of all the assets
  initialassets = () => {
    API.get(`${APIURL}/assets`).then((resp) => {
      const allAssetGroups = resp.data.data;

      this.setState({
        assetGroupsList: allAssetGroups,
      });
    });
  };

  userPermissions = () => {
    // console.log('res**');
    const userPermissionsArr = {
      assetGroups: {
        create: false,
        update: false,
        read: false,
        delete: false,
      },
      assets: {
        create: false,
        update: false,
        read: false,
        delete: false,
      },
      clients: {
        create: false,
        update: false,
        read: false,
        delete: false,
      },
      devices: {
        create: false,
        update: false,
        read: false,
        delete: false,
      },
      groups: {
        create: false,
        update: false,
        read: false,
        delete: false,
      },
      logs: {
        create: false,
        update: false,
        read: false,
        delete: false,
      },
      roles: {
        create: false,
        update: false,
        read: false,
        delete: false,
      },
      integrations: {
        create: false,
        update: false,
        read: false,
        delete: false,
      },
    };
    API.get(`${APIURL}/roles/attributes`)
      .then((response) => {
        // console.log('Response for per', response);
        const permissionsData = response.data.data.filter(
          (item) => item.role !== "uma_protection"
        );
        // permissionsData.map((permission) => {
        //   this.setState({
        //     clientEnabled: permission.attributes.client[0].enabled,
        //     regionEnabled: permission.attributes.region[0].enabled,
        //   });
        // });
        const permissionsArray = Object.keys(userPermissionsArr); // return keys

        permissionsArray.forEach((arr) => {
          const actionsArr = Object.keys(userPermissionsArr[arr]); // CRUD

          actionsArr.forEach((action) => {
            const isTrue = !!permissionsData.find((rolePermissions) => {
              if (
                rolePermissions.attributes &&
                rolePermissions.attributes.permissions &&
                rolePermissions.attributes.permissions.length &&
                rolePermissions.attributes.permissions[0][arr]
              ) {
                return !!rolePermissions.attributes.permissions[0][arr][action];
              }
              return false;
            });
            userPermissionsArr[arr][action] = isTrue;
          });
        });

        this.setState({
          userpermissions: userPermissionsArr,
        });
      })
      .catch((error) =>
        console.log("error setting permissions", error.message || error)
      );
  };

  // axios.get(`${APIURL}/verify`)
  //   .then((res) => {
  //     console.log('verify Status', res.data);
  //     if (res.data.status === 'success') {
  //       const { user } = res.data.data;
  //       if (!user.isVerified) {
  //         cogoToast.info('Account is not verified, Please Contact Support', { position: 'top-right' });
  //         sendToLogin();
  //         return 0;
  //       }
  //       if (!user.isTemporaryPasswordChanged) {
  //         this.setState({
  //           passwordChangeDialog: true,
  //         });
  //       } else {
  //         if (['root', 'admin'].includes(user.role)) {
  //           this.getCompanyInfo();
  //         }
  //         this.initiateSockets(res.data.data.token);
  //         this.setState({
  //           // tokenVerified: true,
  //           user: res.data.data.user,
  //           // user: { role: 'level1'}
  //         }, () => {
  //           // console.log('user', this.state.user)
  //           if (this.state.user && this.state.user.name) {
  //             let initials = this.state.user.name.match(/\b\w/g) || [];
  //             initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
  //             // console.log('initials', initials);
  //             this.setState({
  //               initials,
  //             });
  //           }
  //         });
  //       }
  //     } else if (res.data.status === 'fail') {
  //       if (res.data.redirect === true && res.data.url) window.location = res.data.url;
  //       else sendToLogin();
  //     }
  //   })
  //   .catch((err) => {
  //     // console.error('verification error in app.js', err);
  //   });

  getUserRole = () => {};

  hideDocsAlert = () => {
    this.setState({
      showdocsInfo: false,
    });
  };

  initiateApp = (accessToken, tokenParsed) => {
    window.accessToken = accessToken;
    this.setState({
      accountUser: tokenParsed,
    });
    API.defaults.headers.common.Authorization = `Bearer ${window.accessToken}`;
    localStorage.setItem("accessToken", window.accessToken);
    this.initiateSockets(window.accessToken);
    this.setState({ tokenVerified: true, isLoading: false }, () => {
      this.getCompanyInfo();
      this.userPermissions();
      this.getUserInfo();
      this.initialClientsFromServer();
      this.initialDevicesFromServer();
      this.initialUsersFromServer();
      // this.initialassetgroups();
      this.initialassets();
    });
  };

  verifyToken = (tokenParsed) => {
    const currTime = new Date().getTime() / 1000;
    if (
      currTime - tokenParsed.auth_time >= tokenParsed.exp - tokenParsed.iat ||
      currTime > tokenParsed.exp
    ) {
      this.handleLogout();
    }
  };

  verifyAuthAndInitiateApp = () => {
    keycloak
      .init({
        onLoad: "login-required",
      })
      .then((authenticated) => {
        this.setState({ keycloak });
        this.setState({ authenticated });

        if (authenticated) {
          this.verifyToken(keycloak.tokenParsed);
          this.initiateApp(keycloak.token, keycloak.tokenParsed);
          // keycloak.onTokenExpired = () => {
          //   this.handleLogout();
          // };
        } else {
          this.setState({
            tokenVerified: false,
          });
        }
      });
  };

  componentDidMount = () => {
    this.verifyAuthAndInitiateApp();
    const lastDashboardType = localStorage.getItem("currentView");
    if (
      lastDashboardType &&
      ["DashboardView", "DeviceView"].includes(lastDashboardType)
    ) {
      this.setState({ currentView: lastDashboardType });
    }
    this.getUserRole();
  };

  handleLogout = () => {
    // console.log('Keycloack logout');
    if (this.state.keycloak && this.state.keycloak.logout) {
      this.state.keycloak.logout();
    }
  };

  onIdle = () => {
    this.handleLogout();
  };

  updateCompanyInfo = (updatedCompanysetting) => {
    // console.log('Updated company info ',updatedCompanysetting );
    const { companyInfo } = this.state;
    this.setState({
      companyInfo: { ...companyInfo, settings: updatedCompanysetting },
    });
  };

  updatUserProfile = (newProfileSettings) => {
    const { userDetails } = this.state;
    // console.log('From App ', newProfileSettings, userDetails);
    this.setState({
      userDetails: { ...userDetails, ...newProfileSettings },
    });
  };

  updateCompanyNotification = (updatedCompanyNoti) => {
    const { userDetails } = this.state;
    this.setState({
      userDetails: { ...userDetails, notificationSettings: updatedCompanyNoti },
    });
  };

  updateCompanyUserProfile = (updatedName) => {
    this.setState({
      userDetails: updatedName,
    });
  };

  updateCompanylogo = (companyResponse) => {
    const { companyInfo } = this.state;
    const logo = companyResponse && companyResponse.companyLogo;
    if (logo) {
      this.setState({
        companyInfo: { ...companyInfo, companyLogo: logo },
      });
    } else {
      this.setState({
        companyInfo: companyResponse,
      });
    }
  };

  updateSingleChamber = (value) => {
    // console.log('Valus is ', value)
    this.setState({
      singleChamberValue: value,
    });
  };

  updateAssetGroupList=(value)=>{
    // console.log('Valus is ', value)
    this.setState({

      assetGrouplistforContext: value,
    });
  }


  onPasswordSaveDialogClick = (event) => {
    event.preventDefault();
    const newPassword = event.target.newPassword.value;
    const confirmPassword = event.target.confirmPassword.value;

    PasswordsSchema.validate({
      newPassword,
      confirmPassword,
    })
      .then(() => {
        console.log("validated ");
        if (newPassword !== confirmPassword) {
          this.setState({
            errorMessage: "Passwords do not match",
          });
        } else {
          const passwordsObj = {
            newPassword,
            confirmPassword,
          };
          axios
            .post(`${APIURL}/changeTemporaryPassword`, passwordsObj)
            .then((response) => {
              if (response.data.status === "success") {
                console.log("Passwords changed ");
                window.location.reload(true);
              } else {
                this.setState({
                  errorMessage: response.data.message
                    ? response.data.message
                    : "Error updating passwords. Please contact service provider",
                });
              }
            })
            .catch((err) => {
              this.setState({
                errorMessage: err.message,
              });
            });
        }
      })
      .catch((err) => {
        console.log("Err ", err.message);
        this.setState({
          errorMessage: err.message,
        });
      });
  };

  toggleView = (view) => {
    // const { currentView } = this.state;

    this.setState({ currentView: view }, () => {
      localStorage.setItem("currentView", view);
    });
  };

  checkDevicePermission = (data) => {
    const { deviceId } = data.event;
    const { assetGroupsList } = this.state;

    for (const assetGroupslist of assetGroupsList) {
      // console.log('deviceids', consignment.devices.map(e => e.deviceId))
      if (
        assetGroupslist.devices &&
        assetGroupslist.devices.length &&
        assetGroupslist.devices.map((e) => e.id).includes(deviceId)
      ) {
        return true;
      }
    }
    return false;
  };

  initiateSockets(token) {
    const socket = io(process.env.REACT_APP_SOCKETURL, {
      // const socket = io('http://localhost:3500', {
      query: { token },
      secure: true,
    });
    const that = this;
    socket.on("connect", () => {
      // console.log('connected to socket');
      if (!this.state.socketsConnected) {
        // console.log('room is not joined!');
        // if (this.state.company_id) {
        //   socket.emit('joinRoom', { token: 'test1' });
        //   socket.emit('joinRoom', { name: '12345645' });
        //   console.log('Joining room ', ' again');
        // }
      }
      // subscribe events
      socket.on("log", (data) => {
        // console.log('socket log: ', data.msg);
        cogoToast.error(data.msg);
      });

      socket.on("pingData", (event) => {
        // console.log('--------- Device ping -------- ', event);
        this.setState({
          pingFromSocket: event.pingData,
        });
      });

      socket.on("deviceAlertStatus", (event) => {
        // console.log('---------deviceAlertStatus----------', event);
        this.setState({
          deviceAlertStatus: event,
        });
      });

      socket.on("alert", (data) => {
        const { event } = data;
        // console.log('-----alert-----', data);
        // this.props.violatedDevice(data);
        const { companyInfo, violationsAndWarnings } = this.state;
        // console.log('Company Info -----', companyInfo);
        const { expanded, counter } = this.state;
        let notificationCounter = counter;

        const isPermitted = this.checkDevicePermission(data);

        if (isPermitted) {
          let timeStamp = event.timeStamp || event.timestamp;
          if (event.type === "temperature") {
            if (!expanded) notificationCounter += 1;
            if (!timeStamp) {
              timeStamp = moment().format("Do MMMM hh:mm A");
            }
            // const messageType = (event.level === 'violation') ? 'error' : 'warn';
            const alertType =
              event.level === "violation" ? "Violation" : "Warning";
            if (
              companyInfo &&
              companyInfo.settings &&
              companyInfo.settings.temperature === "F"
            ) {
              violationsAndWarnings.push([
                `${alertType}: Device ${event.deviceId}'s temperature in ${
                  event.assetId
                }(${event.assetGroupId})
              has gone ${
                CtoF(event.setValue) > CtoF(event.data) ? "below" : "above"
              } ${CtoF(event.setValue)} °F `,
                `${moment(timeStamp * 1000).format("Do MMMM hh:mm A")}`,
                // event.timestamp,
                { hideAfter: 6, position: "top-right" },
                event.type,
              ]);
              //  violationsAndWarnings.push({value1:})
            } else {
              violationsAndWarnings.push([
                `${alertType}: Device ${event.deviceId}'s temperature in ${
                  event.assetId
                }(${event.assetGroupId})
              has gone ${event.setValue > event.data ? "below" : "above"} ${
                  event.setValue
                } °C `,
                `${moment(timeStamp * 1000).format("Do MMMM hh:mm A")}`,
                // event.timestamp,
                { hideAfter: 6, position: "top-right" },
                event.type,
              ]);
            }
          }
          if (event.type === "humidity") {
            if (!expanded) notificationCounter += 1;
            if (!timeStamp) {
              timeStamp = moment().format("Do MMMM hh:mm A");
            }
            // const messageType = (event.level === 'violation') ? 'error' : 'warn';
            const alertType =
              event.level === "violation" ? "Violation" : "Warning";
            violationsAndWarnings.push([
              `${alertType}: Device ${event.deviceId}'s humidity in ${
                event.assetId
              }(${event.assetGroupId})
            has gone ${event.setValue > event.data ? "below" : "above"} ${
                event.setValue
              } % `,
              ` ${moment(timeStamp * 1000).format("Do MMMM hh:mm A")}`,
              // event.timestamp,
              { hideAfter: 6, position: "top-right" },
              event.type,
            ]);
          }
          // if (event.type === 'light') {
          //   cogoToast.error(`Violation: Device ${data.deviceId} in ${data.consignmentId}(${data.tripId}) has been opened`, { hideAfter: 6, position: 'top-right' });
          // }
          // if (event.type === 'shock') {
          //   cogoToast.error(`Violation: Device ${data.deviceId} in ${data.consignmentId}(${data.tripId}) has experienced shock`, { hideAfter: 6, position: 'top-right' });
          // }
          if (event.type === "battery") {
            if (!expanded) notificationCounter += 1;
            if (!timeStamp) {
              timeStamp = moment().format("Do MMMM hh:mm A");
            }

            // const messageType = (event.level === 'critical') ? 'error' : 'warn';
            const alertType =
              event.level === "critical" ? "Critical" : "Warning";
            violationsAndWarnings.push([
              `${alertType}: Device ${event.deviceId}'s battery in ${event.assetId}(${event.assetGroupId})
            has gone below ${event.setValue}. Current battery: ${event.data} % `,
              `${moment(timeStamp * 1000).format("Do MMMM hh:mm A")} `,
              // event.timestamp,
              { hideAfter: 6, Position: "top-right" },
              event.type,
            ]);
          }
          // if (event.type === 'geofence') {
          //   const position = (event.data && event.data.isInside) ? 'entered' : 'left';
          //   cogoToast.error(`Alert: Device ${data.deviceId} in ${data.consignmentId}(${data.tripId}) has ${position} ${event.setValue.name ? event.setValue.name : (event.setValue.address ? event.setValue.address : 'N/A')}`, { hideAfter: 6, position: 'top-right' });
          // }
          if (violationsAndWarnings.length > 1000) {
            violationsAndWarnings.shift();
          }
          this.setState({
            violationsAndWarnings,
            counter: notificationCounter,
          });
        }
      });
    });

    socket.on("disconnect", () => {
      // console.log('socket disconnected !');
      socket.off("log");
      socket.off("pingData");
      socket.off("alert");
      that.setState({ socketsConnected: false });
    });
  }

  render() {
    const {
      pingFromSocket,
      deviceAlertStatus,
      initials,
      companyInfo,
      userpermissions,
      accountUser,
      userDetails,
      getDevicesFromServer,
      setClient,
      expanded,
      violationsAndWarnings,
      counter,
      currentView,
      isLoading,
      singleChamberValue,
      deviceViewinSideDrawer,
      assetGroupsList,
      assetGroups,
      assetGrouplistforContext
    } = this.state;

    const props = {
      // singleChamberValue,
      pingFromSocket,
      deviceAlertStatus,
      // user,
      currentView,
      // companyInfo,
      // assetGroups,
      // userpermissions,
      // assets,
      // UpdatedNotifSett:this.UpdatedNotificationofCompany,
    };

    if (isLoading) {
      return (
        <div>
          <h1>Loading...</h1>
        </div>
      );
    }

    // const companyLogoUrl = companyInfo && companyInfo.companyLogo && companyInfo.companyLogo.url
    //   ? companyInfo.companyLogo.url
    //   : '/img/logo.png';

    let userRoutes;
    if (
      userpermissions &&
      ((userpermissions.assetGroups && userpermissions.assetGroups.read) ||
        (userpermissions.assets && userpermissions.assets.read) ||
        (userpermissions.devices && userpermissions.devices.read) ||
        (userpermissions.clients && userpermissions.clients.read) ||
        (userpermissions.groups && userpermissions.groups.read) ||
        (userpermissions.roles && userpermissions.roles.read) ||
        (userpermissions.logs && userpermissions.logs.read))
    ) {
      userRoutes = deviceViewinSideDrawer
        ? routes.slice(0, 5)
        : routes.slice(0, 4);
    } else {
      userRoutes = routes.slice(0, 4);
    }

    // if (!passwordChangeDialog) {
    //   if (user && user.isVerified && user.isTemporaryPasswordChanged) {

    return (
      <>
        <div className="App">
          <UserContext.Provider
            value={{
              CompanyInfo: companyInfo,
              userPermissions: userpermissions,
              accountUser,
              Devices: getDevicesFromServer,
              Client: setClient,
              UserDetails: userDetails,
              singleChamberValue: singleChamberValue,
              assetGroupsList: assetGroupsList,
              assetGroups: assetGroups,
              assetGrouplistforContext:assetGrouplistforContext,
              updateSingleChamber: this.updateSingleChamber,
              updateCompanyInfo: this.updateCompanyInfo,
              updateCompanyNotification: this.updateCompanyNotification,
              updateCompanyUserProfile: this.updateCompanyUserProfile,
              updateCompanyLogo: this.updateCompanylogo,
              updatUserProfile: this.updatUserProfile,
              updateAssetGroupList:this.updateAssetGroupList
            }}
          >
            <Router>
              <TopNavbar
                navItems={userRoutes}
                initials={initials}
                handleLogout={this.handleLogout}
                warningAndViolationData={violationsAndWarnings}
                expanded={expanded}
                counter={counter}
                handleChange={this.handleChange}
                getvalueofDeviceView={this.getvalueofDeviceView}
              />
              <Grid
                container
                spacing={GRID_SPACING}
                className="contentContainer"
              >
                <Grid item md={1} sm xs>
                  {userpermissions &&
                    userpermissions.devices &&
                    userpermissions.devices.read && (
                      <Route
                        key={routes[0].path}
                        path={`/${routes[0].path}`}
                        component={() => (
                          <>
                            <DashboardOption
                              viewstate={currentView}
                              toggleoption={this.toggleView}
                            />
                          </>
                        )}
                      />
                    )}
                </Grid>

                <Grid item md={10} sm={11} xs={11}>
                  {userRoutes.map((route) => (
                    <Route
                      key={route.path}
                      path={`/${route.path}`}
                      render={(routeProps) =>
                        route.component({ ...props, ...routeProps })
                      }
                    />
                  ))}
                  <Route
                    key={routes[5].path}
                    path={`/${routes[5].path}`}
                    component={routes[5].component}
                  />
                  <Route
                    key={routes[6].path}
                    path={`/${routes[6].path}`}
                    component={routes[6].component}
                  />
                  <Route
                    key={routes[7].path}
                    path={`/${routes[7].path}`}
                    component={routes[7].component}
                  />

                  <Switch>
                    <Redirect exact from="/" to={`/${routes[0].path}`} />
                  </Switch>
                </Grid>

                <Grid item md={1} sm xs></Grid>
                {/* <Footer /> */}
                {/* <Grid style={{


                    // position:'fixed',
                      width: '100%',
                      bottom: 0,
                     //  color: "white",
                      fontSize: "14px"
                   }}>
                  @Powered by Adapt Ideations
                   </Grid> */}
              </Grid>
            </Router>
          </UserContext.Provider>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(App);
