
import React, { useContext, useEffect} from "react";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import AutoField from "components/AutoField";
import UserContext from "Context/UserContext";
import Alert from '@mui/material/Alert';


const DataSettings=(props)=>{
    const { companysettingsDispatchFn,companysettingsState } = props;
 const { CompanyInfo } = useContext(UserContext);
 const {device} = companysettingsState;
const deviceTypeList=[ "PIXEL_W300", "PIXEL_W100","PIXEL_C300", "PIXEL_C100"]

const isSelectAllSelected = device.length === deviceTypeList.length;
const optionsWithSelectAll = ['Select All', ...deviceTypeList];

const handleDeviceChange = (event, value) => {
  companysettingsDispatchFn({ type: "DEVICELIST", payload: value });

  let DeviceType=value
  if(value && value.includes("Select All")){
    DeviceType= ["PIXEL_W300", "PIXEL_W100","PIXEL_C300", "PIXEL_C100"]
  }else{
    DeviceType=value
  }
  // console.log('DeviceType is ', DeviceType)
  companysettingsDispatchFn({ type: "DEVICELIST", payload: DeviceType });

};

  useEffect(() => {
    if (CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.data && CompanyInfo.settings.data.humidity && CompanyInfo.settings.data.humidity.disableDeviceTypes  ) {
      companysettingsDispatchFn({
        type: "DEVICELIST",
        payload: CompanyInfo.settings.data.humidity.disableDeviceTypes || [],
      });


    }
  }, [CompanyInfo]);


    return(
        <>
         <Grid
        container
        item
        spacing={2}
        xs={12}
        lg={12}
        md={12}
        sm={12}
        alignItems="center"
        justifyContent="center"
      >
         <Grid container item alignItems="center">
          <Grid item xl={2} lg={3} md={3} sm={4} xs={12}>
            <Typography
              component="span"
              variant="p"
              sx={{
                fontFamily: "Montserrat",
                textAlign: "left",
                fontSize: "16px",
              }}
            >
            Disable Humidity for :
            </Typography>
          </Grid>
          <Grid item xl={3} lg={3} md={4} sm={5} xs={9}>
             <AutoField
            style={{width:'98%', marginTop:'1em'}}
              disablePortal
            backgroundColor="#f2f2f2"
              multiple
              id="Device type"
              value={device}
              onChange={handleDeviceChange}
              options={optionsWithSelectAll}
              getOptionLabel={option => option}
              filterSelectedOptions
              renderOption={(props, option) => {
                if (option === 'Select All') {
                  return (
                    <li {...props} key={option}>
                      <input
                        type="checkbox"
                        checked={isSelectAllSelected}
                        readOnly
                      />
                      {option}
                    </li>

                  );
                }
                return (
                  <li {...props} key={option}>
                    {option}
                  </li>
                );
              }}
              renderInput={(params) => <TextField {...params} label="Device type" />}
            />
          </Grid>
        <Grid container sx={{mt:'2rem'}}>
        <Alert severity="info">For selected device types, you will no longer receive humidity data</Alert>
        </Grid>
        </Grid>
        </Grid>
        </>
    )
}
export default DataSettings;
