import React from "react";
import { ThemeProvider, withStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";
import {
    createTheme,
    responsiveFontSizes,
  } from '@mui/material/styles';
const styles={
    outerDiv:{
        height:'60vh',
        width:'80%',
        margin:'auto'
    },
    banner:{
        backgroundColor:'rgba(237, 245, 253, 1)',
        // height:'8rem',
       borderRadius:'20px',
    //    margin:'2rem',
       padding:'2.5rem',
      marginTop:'1.5rem',
    //    display:'flex',
    //    justifyContent:'space-between',
       boxShadow:" 0px 4px 4px rgba(0, 0, 0, 0.25)",
       width:'80%',
        margin:'auto'
    },
    info:{
        fontWeight:600,
    fontSize:'1.2rem',
    },
    phoneinfo:{
        backgroundColor:'#fff',
        margin: 'auto',
        //  height: '3rem',
         borderRadius: '14px',
        // width: '15rem',
        padding: '1rem',
        fontWeight:'bold',
        fontSize:'14px',
       
    },
    emailinfo:{
        backgroundColor:'#fff',
        margin: 'auto',
        //  height: '3rem',
         borderRadius: '14px',
        // width: '20rem',
        padding: '1rem',
        fontWeight:'bold',
        fontSize:'14px',
        
    }
}
const GoogleNestBanner=(props)=>{
    const {classes}= props
    let theme = createTheme();
theme = responsiveFontSizes(theme);
    return(
        <ThemeProvider theme={theme}>
        <div className={classes.outerDiv} >
     <Grid container className={classes.banner}>
        <Grid item xl={5}  lg={4} md={12} sm={12} xs={12}  style={{margin:'auto', display:'flex', justifyContent:'center'}}>
            <Typography   sx={{ fontWeight:600, fontSize:'1.2rem'}}  > 
            To get voice alerts , contact Adapt Ideations :
            </Typography>
            </Grid>
        {/* <Grid item  xl={4} lg={3} md={6} sm={12} xs={12} style={{margin:'auto', display:'flex', justifyContent:'center'}}>
           <Typography sx={{fontWeight:600,  fontSize:'15px',m:'0.5rem',width:'70%',textAlign:'center'}} className={classes.phoneinfo}>
             Phone : +91 4045207973 
             </Typography> 
            </Grid> */}
        <Grid item xl={3} lg={5}md={6} sm={12}  xs={12} style={{margin:'auto',  display:'flex', justifyContent:'center'}}>
            <Typography sx={{ fontWeight:600, fontSize:'15px',width:'100%',textAlign:'center'}}  className={classes.emailinfo}>
                Email : support@adaptideations.com 
                </Typography>
            </Grid>
     </Grid>
        </div>
        </ThemeProvider>
    )
}
export default withStyles(styles)(GoogleNestBanner);