import React from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Search } from '@mui/icons-material';
import { Tooltip } from '@mui/material';


const styles = {
  searchInputDiv: {
    // width: '25%',
    minHeight: '34px',
    minWidth: '15%',
    padding: '8px',
    position: 'relative',
    border:'none',
    
  },
  searchInput: {
    // border: '1px solid #fff',
    fontFamily:'Montserrat',
    borderRadius: '50px',
    border:'none',
    background: '#ffff',
    width: '100%',
    height: '34px',
    boxSizing: 'border-box',
    padding: '0 15px',
    "&:focus": {
      outline: 'none'
      
    },
  },
  searchIcon: {
    position: 'absolute',
    right: '12px',
    top: '12px',
    color: '#6d6d6d',
  },
};

const SearchOption = (props) => {
  const {
    classes, id, value, onChange, placeholder,Type, backgroundColor
  } = props;



 
  
  return (
    <div className={classes.searchInputDiv}  >
      <Tooltip title={ Type && Type.length > 53 ? "Search by " + Type + " Id"  : '' } >
        <div>
      <input
        type="string"
       autoComplete='off'
        id={id}
        value={value}
        onChange={onChange}
        className={classes.searchInput}
        placeholder={placeholder}
        style={{backgroundColor:backgroundColor ? backgroundColor :'#fff'}}
      />
      <Search
  
        className={classes.searchIcon}
      />
      </div>
      </Tooltip>
    </div>
  );
};

SearchOption.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any),
  id: PropTypes.string.isRequired,
  // value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

SearchOption.defaultProps = {
  classes: {},
  placeholder: 'Search',
};

export default withStyles(styles)(SearchOption);
