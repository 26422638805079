/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { withStyles } from '@mui/styles';
import * as yup from 'yup';
import Grid from '@mui/material/Grid';
import cogoToast from 'cogo-toast';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Input from 'components/Input';
import SelectField from 'components/SelectField';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';
// import UserContext from 'Context/UserContext';

// import PropTypes from 'prop-types';
import API from 'API/apiConfig';
import Delete from '@mui/icons-material/Delete';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import PrimaryButton from 'components/PrimaryButton';
import SecondaryButton from 'components/SecondaryButton';
import CreateButton from 'components/CreateButton';
import toastOptions from 'utils/toast';

const APIURL = process.env.REACT_APP_APIURL;

const styles = {
  apiKeyContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingBottom: '5px',
  },
  gridParams: {
    // width: '100%',
    boxShadow: '1px 1.7px 7px 0 rgba(136, 156, 176, 0.1)',
    // height:'400px'
  },
  deleteDiv: {
    width: '50px',
    // borderRight: '1px solid #dedede',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    color: 'red',
  },
  disabledDeleteDiv: {
    width: '50px',
    // borderRight: '1px solid #dedede',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#c2c2c2',
  },
  headerClass: {
    backgroundColor: '#008081',
    color: 'white',
    '&:hover': {
      backgroundColor: '#008081!important',
    },
    fontFamily:'Montserrat', fontSize:"14px" 
  },
  warning: {
    color: 'red',
  },
  modalDialogOuterDiv: {
    display: 'flex',
    justifyContent: 'center',
  },
  modalDialogDiv: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    paddingLeft: '10px',
    paddingRight: '10px',
    padding:'2rem',
   
  },
  inputLabel: {
    opacity: '0.5',
    fontFamily: 'Montserrat',
    fontSize: '12px',
    textAlign: 'left',
    marginTop: '10px',
    color: 'rgba(3, 0, 0, 0.5)',
  },
  inputTextField: {
    border: 'none',
    borderRadius:'22px',
    // borderBottom: '1px solid grey',
    width: '100%',
    height: '30px',
    outline: 'none',
    '&:focus': {
      borderBottom: '1px solid #00adef',
    },
  },
  addButton:{
    backgroundColor:'#008081',
    borderRadius:'20px',
    textAlign:'center',
    color:'white',
    fontWeight:'bold',
    // minWidth:'99px',
    '&:hover': {
      backgroundColor:'#008081',
    },
  },
  saveButton:{
    backgroundColor:'#008081',
    color:'white',
    borderRadius:'20px',
    margin:'10px !important',
    padding:'5px 15px',
    minWidth:'99px',
    '&:hover': {
      backgroundColor:'#008081',
    },
  },
  cancleButton:{
    color:'#008081',
    padding:'5px 15px',
    minWidth:'99px',
    borderRadius:'20px !important' ,
    border:'2px solid #008081 !important'
  },
  title: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  appBar:{
    position:'sticky'
  }
};


let gridApi = null;
const onGridReady = (params) => {
  // console.log('gridreadyparams', params);
  gridApi = params.api;
  params.api.sizeColumnsToFit();
};

const allowedIntegrationTypes = [{
  type: 'webhook',
  name: 'Web Hook',
}, {
  type: 'apikey',
  name: 'API Key',
}];

const allowedIntegrationVersions = {
  webhook: ['v1.0', 'v2.0'],
  apikey: ['v1.0'],
};


const WebhookSchema = yup.object().shape({
  applicationId: yup.string().required('Application Id cannot be empty'),
  applicationUrl: yup
    .string()
    .required('URL endpoint cannot be empty')
    .url('Invalid URL'),
  version: yup.string().required('Version cannot be empty'),
  // iccid: yup.string().required('SIM ICCID is required'),
  // provider: yup.string().required('SIM Provider cannot be empty'),
});

const APIKeySchema = yup.object().shape({
  applicationId: yup.string().required('Application Id cannot be empty'),
  version: yup.string().required('Version cannot be empty'),
  // iccid: yup.string().required('SIM ICCID is required'),
  // provider: yup.string().required('SIM Provider cannot be empty'),
});


const Integration = ({ classes }) => {
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [keysList, setKeysList] = React.useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openAddDialog, setOpenAddDialog] = React.useState(false);
  // const [selectDevices, setSelectDevices] = React.useState(false);
  // const [selectDevicePings, setSelectDevicePings] = React.useState(false);
  const [selectIntegrationType, setSelectIntegrationType] = useState(allowedIntegrationTypes[0].type);
  const [selectIntegrationVersion, setSelectIntegrationVersion] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [webhookPermissions, setWebhookPermissions] = useState({});
  const [fullScreen , setFullScreen]= useState(false);
  // const [integrationrowData, setIntegrationrowData] = useState([]);


  const getAPIKeys = () => {
    API.get(`${APIURL}/keys`)
      .then((response) => {
        if (response.data.status === 'success') {
          setKeysList(response.data.data);
          setIsLoading(false);
        } else {
          console.log('Failed to get the API Keys ', response.data.message);
        }
      })
      .catch((err) => {
        console.log('Failed to get api Keys ', err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    
    if (window.innerWidth <= 600) {
      setFullScreen(true);
    }
  }, []);

  // const UserContextInfo = useContext(UserContext); // react context
  // const { userPermissions } = UserContextInfo;

  React.useEffect(() => {
    getAPIKeys();
    const possibleVersions = allowedIntegrationVersions[selectIntegrationType];

    setSelectIntegrationVersion(possibleVersions[possibleVersions.length - 1]);
  }, [selectIntegrationType]);


  const postDatatoServer = (url, apiKeyObj) => {
    API
      .post(url, apiKeyObj)
      .then((apiResponse) => {
        // let newDeviceObject = { ...addDeviceResponse.data.data[0] };
        if (apiResponse.data.status === 'success') {
          const url = window.URL.createObjectURL(
            new Blob([apiResponse.data.data]),
          );
          const link = document.createElement('a');
          link.href = url;
          // const contentDisposition = response.headers['content-disposition'];
          const filename = `${apiKeyObj.applicationId}.csv`;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          link.remove();
          // this.setState({
          //   isLoading: false,
          //   openPDFDialog: false
          // });
          cogoToast.success('Created API Key successfully', toastOptions);
          setErrorMessage(null);
          setOpenAddDialog(false);
          setSelectIntegrationType(allowedIntegrationTypes[0].type);
          // setSelectIntegrationVersion()
          getAPIKeys();
        } else {
          setErrorMessage(apiResponse.data.message ? apiResponse.data.message : 'Failed to create API Key');
        }
      })
      .catch((error) => {
        // console.error(error);
        setErrorMessage('Operation failed. Please check your network connectivity');
      });
  };

  const handleDeleteDialog = () => {
    setOpenDeleteDialog(!openDeleteDialog);
  };

  // params: { applicationId: selectedRow.applicationId, type: selectedRow.type },
  const onDeleteDialogSave = () => {
    const selectedRow = selectedRowData[0];
    // console.log('Selected  delet', selectedRow )
    // const selectedRowsUidsArray = selectedRowData.map((item) => ({ applicationId: item.applicationId, type: item.type}));
    // console.log('Selected  delet 2',selectedRowsUidsArray )
    API.delete(`${APIURL}/keys`, {
      params: { applicationId: selectedRow.applicationId, type: selectedRow.type },
    })
      .then((result) => {
        if (result.data.status === 'success') {
          getAPIKeys();
          setOpenDeleteDialog(false);
          setSelectedRowData([]);
          cogoToast.success('Successfully deleted the api key', toastOptions);
        } else {
          cogoToast.error(result.data.message ? result.data.message : 'Internal Error', toastOptions);
        }
      })
      .catch((error) => {
        console.log('Error in deleting api key', error);
        cogoToast.error('Failed to delete api Key. Please check your network', toastOptions);
      });
  };


  const onAddDialogSave = (event) => {
    event.preventDefault();
    const newApplicationId = event.target.applicationId.value;

    if (selectIntegrationType === 'webhook') {
      const newApplicationUrl = event.target.applicationUrl.value;

      WebhookSchema.validate({
        applicationId: newApplicationId,
        applicationUrl: newApplicationUrl,
        version: selectIntegrationVersion,
      })
        .then(() => {
          const apiKeyObj = {
            applicationId: newApplicationId,
            type: selectIntegrationType,
            url: newApplicationUrl,
            _version: selectIntegrationVersion,
            permissions: webhookPermissions,
          };
          const url = `${APIURL}/keys?type=${selectIntegrationType}`;
          postDatatoServer(url, apiKeyObj);
        })
        .catch((err) => {
          console.error('Error creating api key ', err.message);
          setErrorMessage(err.message);
        });
    } else {
      APIKeySchema.validate({
        applicationId: newApplicationId,
        version: selectIntegrationVersion,
      })
        .then(() => {
          const apiKeyObj = {
            applicationId: newApplicationId,
            type: selectIntegrationType,
            _version: selectIntegrationVersion,
            permissions: webhookPermissions,
          };
          const url = `${APIURL}/keys?type=${selectIntegrationType}`;
          postDatatoServer(url, apiKeyObj);
        })
        .catch((err) => {
          console.error('Error creating api key ', err.message);
          setErrorMessage(err.message);
        });
    }
  };


  const onSelectionChanged = () => {
    if (gridApi) {
      setSelectedRowData(gridApi.getSelectedRows());
    }
  };

  const onAddDialogClick = () => {
    setOpenAddDialog(!openAddDialog);
  };

 

  const handleCheckbox = (params) => {
    const newPermissions = { ...webhookPermissions, [params.resource]: { ...webhookPermissions[params.resource], [params.actionType]: params.value } };
    setWebhookPermissions(newPermissions);
  };

  const domLayout = 'autoHeight';
  const overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Please wait while your keys are loading</span>';
  const overlayNoRowsTemplate = '<img src="./../img/emptybox.png" />';

  const columnDefs = [
    {
      headerName: 'Application Id ',
      field: 'applicationId',
      minWidth: 150,
      checkboxSelection: true,
    },
    {
      headerName: 'Type',
      field: 'type',
      valueFormatter: (params) => {
        if (params.value && params.value === 'webhook') return 'Web Hook';
        if (params.value && params.value === 'apikey') return 'API Key';
        return '-';
      },
      minWidth: 130,
    },
    {
      headerName: 'Version',
      field: '_version',
      valueFormatter: (params) => (params.value ? params.value : '-'),
      minWidth: 100,
    },
    {
      headerName: 'API Key',
      field: 'keyMask',
      valueFormatter: (params) => (params.value ? params.value : '-'),
      minWidth: 200,
    },
    {
      headerName: 'Public Key',
      field: 'publicKey',
      valueFormatter: (params) => {
        const key = params.value;
        let maskedKey = 'N/A';
        if (key && key.length) {
          maskedKey = `${key.slice(0, 6)}.........${key.slice(key.length - 5, key.length)}`;
        }
        return maskedKey;
      },
      minWidth: 200,
    },
    {
      headerName: 'Application Url',
      field: 'url',
      valueFormatter: (params) => (params.value ? params.value : '-'),
      minWidth: 200,
      
    },

  ];

  // const devicepermissions = [
  //   {
  //     name: 'Device',
  //     id: 'device',
  //   },
  //   {
  //     name: 'Device Pings',
  //     id: 'devicepings',
  //   },
  // ];

 
  

  const defaultColDef = {
    sortable: false,
    filter: false,
    resizable: true,
    suppressMovable: true,
    cellStyle: { textAlign: 'left', fontFamily:'Montserrat', fontSize:"14px" },
    headerClass: classes.headerClass,
   
  };
  const rowSelection = 'single';

  return (
    <>
      <>
            <div style={{width:'100%'}}>
              <Grid container spacing={1} >
                <Grid item xs={12} className={classes.apiKeyContainer}>
                  <CreateButton
                    variant="contained"
                  text=" Create integration"
                    onClick={onAddDialogClick}
                 />
          
                 
                </Grid>
                <Grid item xs={12}>
                  <div style={{ display: 'flex' }}>
                    <div
                      className={
                selectedRowData && selectedRowData.length >= 1 ? classes.deleteDiv : classes.disabledDeleteDiv
            }
                      onClick={
                selectedRowData && selectedRowData.length >= 1
                  ? handleDeleteDialog
                  : null

            }
                    >
                      <Delete />
            {/* Delete */}
                    </div>
                  </div>
                  <div className={` ${classes.gridParams} ag-theme-material`}>
                    <AgGridReact
                      enableBrowserTooltips
                      columnDefs={columnDefs}
                      rowData={keysList}
                      rowMultiSelectWithClick
                      pagination
                      paginationPageSize={10}
                      rowSelection={rowSelection}
                      defaultColDef={defaultColDef}
                      domLayout={domLayout}
                      overlayLoadingTemplate={overlayLoadingTemplate}
                      overlayNoRowsTemplate={overlayNoRowsTemplate}
                      rowDeselection
                      onGridReady={onGridReady}
                      onSelectionChanged={onSelectionChanged}
                    />
                  </div>
                </Grid>
               
              
              </Grid>

              <Dialog
                open={openAddDialog}
                aria-labelledby="form-dialog-title"
                maxWidth="sm"
                fullWidth
                fullScreen={fullScreen}
                PaperProps={{
                  style: {
                    backgroundColor: '#f2f2f2',
                  },
                }}
              >
                <form onSubmit={onAddDialogSave}>

                { fullScreen && (
                <AppBar className={classes.appBar}>
                          <Toolbar style={{backgroundColor:'#008081'}}>
                          <IconButton
                          edge="start"
                          color="inherit"
                          onClick={onAddDialogClick}
                          aria-label="close"
                        >
                          <CloseIcon />
                        </IconButton>
                          <Typography variant="h6" className={classes.title}>
                          Create Integration
                        </Typography>
                          <Button color="inherit" type="submit" 
                            
                            >
                         Save
                        </Button>
                        </Toolbar>
                        </AppBar>
                   )}

                 {!fullScreen && (
 <DialogTitle id="form-dialog-title">Create Integration</DialogTitle>
                 )}
                 

                  <DialogContent>
                  <Grid style={{marginTop: fullScreen ? "4rem" : '0rem' }} container spacing={2}>
                    <Grid item xs={12}>
                    <Input
                        placeholder='Application ID'
                      type="string"
                      id="applicationId"
                      backgroundColor="#fff"
                    />
                    </Grid>
                    <Grid item xs={12}>
                    <SelectField
                        placeholder="Integration Type"
                        variant="standard"
                      value={selectIntegrationType}
                      backgroundColor="#fff"
                      // className={classes.selectBox}
                      onChange={(e) => {
                       setSelectIntegrationType(e.target.value);
                       const possibleVersions = allowedIntegrationVersions[e.target.value];
                       setSelectIntegrationVersion(possibleVersions[possibleVersions.length - 1]);
                     }}
                    >
                      {
                        (allowedIntegrationTypes.map((it) => (
                          <MenuItem key={it.type} value={it.type}>{it.name}</MenuItem>
                        )))
                    }
                    </SelectField>
                    </Grid>

                    {
                    (selectIntegrationType && selectIntegrationType === 'webhook')
                      ? (
                        <>
                        <Grid item xs={12}>
                        <SelectField
                          variant="standard"
                          placeholder="Version"
                            value={selectIntegrationVersion}
                            onChange={(e) => {
                              setSelectIntegrationVersion(e.target.value);
                            }}
                            backgroundColor="#fff"
                          >
                            {
                            (allowedIntegrationVersions[selectIntegrationType].map((version) => (
                              <MenuItem key={version} value={version}>{version}</MenuItem>
                            )))
                          }
                          </SelectField>
                        </Grid>
                        <Grid item xs={12}>
                        <Input
                            type="string"
                            id="applicationUrl"
                            placeholder="Application url"
                            backgroundColor="#fff"
                          />
                        </Grid>
                        <Grid item xs={12}>
                       
                            <div style={{ marginTop: '1.5vh' }}>
                             <div style={{ display: 'flex', justifyContent: 'space-between' }}> 
                              <Typography style={{margin:'0.5rem'}}>Devices</Typography>
                              <FormGroup row>
                                <FormControlLabel value="read" control={<Checkbox style={{color:'#008081'}} onChange={(e) => handleCheckbox({ value: e.target.checked, actionType: 'read', resource: 'devices' })} />} label="Read" />
                                <FormControlLabel value="create" control={<Checkbox style={{color:'#008081'}} onChange={(e) => handleCheckbox({ value: e.target.checked, actionType: 'create', resource: 'devices' })} />} label="Create" />
                                <FormControlLabel value="update" control={<Checkbox style={{color:'#008081'}}onChange={(e) => handleCheckbox({ value: e.target.checked, actionType: 'update', resource: 'devices' })} />} label="Update" />
                                <FormControlLabel value="delete" control={<Checkbox style={{color:'#008081'}} onChange={(e) => handleCheckbox({ value: e.target.checked, actionType: 'delete', resource: 'devices' })} />} label="Delete" />


                              </FormGroup>
                              </div>
                              </div>
                          

                            <div style={{ display: 'flex' , paddingTop:'1rem'}}>
                              <Typography style={{margin:'0.5rem'}}>Device Pings</Typography>
                              <FormGroup
                                row
                               
                                style={{
                                  //  justifyContent:'flex-start'
                                  marginLeft: '3.9rem',
                                }}
                              >
                                <FormControlLabel value="read" control={<Checkbox style={{color:'#008081'}} />} label="Read" onChange={(e) => handleCheckbox({ value: e.target.checked, actionType: 'read', resource: 'devicePings' })} />
                              </FormGroup>
                              </div>
                            {/* </div>

                          </div> */}

                        </Grid>
                          

                        
                          
                         
                          

                        </>
                      )
                      : (
                                <>
                               
                        <Grid item xs={12}>
                       <SelectField
                          variant="standard"
                          placeholder="Version"
                            value={selectIntegrationVersion}
                            onChange={(e) => {
                              setSelectIntegrationVersion(e.target.value);
                            }}
                            backgroundColor="#fff"
                          >
                            {
                            (allowedIntegrationVersions[selectIntegrationType].map((version) => (
                              <MenuItem key={version} value={version}>{version}</MenuItem>
                            )))
                          }
                          </SelectField>
                        </Grid>
                       
                        <Grid item xs={12}>
                       
                           <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <Typography style={{margin:'0.5rem'}}>Devices</Typography>
                              <FormGroup row>
                                <FormControlLabel value="read" control={<Checkbox style={{color:'#008081'}} onChange={(e) => handleCheckbox({ value: e.target.checked, actionType: 'read', resource: 'devices' })} />} label="Read" />
                                <FormControlLabel value="create" control={<Checkbox style={{color:'#008081'}} onChange={(e) => handleCheckbox({ value: e.target.checked, actionType: 'create', resource: 'devices' })} />} label="Create" />
                                <FormControlLabel value="update" control={<Checkbox style={{color:'#008081'}} onChange={(e) => handleCheckbox({ value: e.target.checked, actionType: 'update', resource: 'devices' })} />} label="Update" />
                                <FormControlLabel value="delete" control={<Checkbox style={{color:'#008081'}} onChange={(e) => handleCheckbox({ value: e.target.checked, actionType: 'delete', resource: 'devices' })} />} label="Delete" />


                              </FormGroup>
                            </div>
                           
                            <div style={{ display: 'flex' }}>
                            <Typography style={{margin:'0.5rem'}}>Device Pings</Typography>
                              <FormGroup
                                row
                                style={{
                                  //  justifyContent:'flex-start'
                                  marginLeft: '3.9rem',
                                }}
                              >
                                <FormControlLabel value="read" control={<Checkbox style={{color:'#008081'}} />} label="Read" onChange={(e) => handleCheckbox({ value: e.target.checked, actionType: 'read', resource: 'devicePings' })} />
                              </FormGroup>
                              </div>
                           
                              
                        </Grid>
                          
                        </>
                        

                        
                      )

                }

                    </Grid>

                  </DialogContent>
                  {
                    !fullScreen && (
                      <DialogActions>
                      <SecondaryButton text="Cancel" onClick={onAddDialogClick} />
                
                      <PrimaryButton text="Save" type="submit"  variant="contained" />
               
                    </DialogActions>
                    )
                  }
                 
                </form>
              </Dialog>


              <Dialog
                open={openDeleteDialog}
                onClose={handleDeleteDialog}
                aria-labelledby="form-dialog-title"
                maxWidth="sm"
              >
                <DialogTitle id="form-dialog-title">
                  <span className={classes.warning}>Warning</span>
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
            Are you sure you want to delete the selected keys ?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <SecondaryButton onClick={handleDeleteDialog} text="No" />
           
                  <PrimaryButton  text="Yes" onClick={onDeleteDialogSave}  variant="contained" />
           
                  
                </DialogActions>
              </Dialog>
            </div>
          </>

    </>
  );
};


export default withStyles(styles)(Integration);
