import React from "react";
import { withStyles } from "@mui/styles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faThermometerThreeQuarters,  } from '@fortawesome/free-solid-svg-icons';
// import Battery60RoundedIcon from '@mui/icons-material/Battery60Rounded';

const styles = {
    outerDiv: () => ({
      display: "flex",
      height: "100%",
      width: "100%",
    }),
    modularDiv: (props) => ({
      display: "flex",
      borderRight: `1px solid ${props.borderColor}`,
      alignItems: "center",
      fontSize: props.fontSize,
      // width: (props.width),
      borderLeft: `1px solid ${props.borderColor}`,
      width: "75px",
    }),
    icon: (props) => ({
      maxHeight: "3.5em",
      paddingLeft: "0.8em",
      fontSize: props.fontSize,
    }),
    text: (props) => ({
      margin: "0px",
      fontFamily: "Montserrat",
      fontSize: props.fontSize,
      color: props.textColor,
      marginLeft:'5px'
    }),
    noViolationsDiv: {
      display: "flex",
      // justifyContent: 'center',
      alignItems: "center",
      height: "auto",
      width: "100%",
    },
    noViolationsText: (props) => ({
      margin: "0px",
      fontFamily: "Montserrat",
      fontSize: props.fontSize,
      color: props.textColorForNoViolations,
      // paddingLeft: '0.8em',
    }),
  };

const StripsofViolation=(props)=>{
const {classes, params}=props;


const gettempValue=()=>{
    if (params.data.lastPing && params.data.lastPing.temperature && params.data.lastPing.temperature.length >= 1){
        return `${(params.data.lastPing.temperature[params.data.lastPing.temperature.length - 1] )} °C`;
      }return ' - ';

}

// const getIcon=()=>{
//     const {violations, warnings}= params && params.data;
//     if(!violations && !warnings)return;
//    if(violations && warnings){
//     if(violations && violations.temperature > 0  ){
//         return (
//        <FontAwesomeIcon icon={faThermometerThreeQuarters} style={{ color: 'rgb(255, 86, 48)', fontSize: '1.5em', alignContent:'left'}} />
//         )
//     }
//     if(warnings && warnings.temperature > 0){
//         return (
//             <FontAwesomeIcon icon={faThermometerThreeQuarters} style={{ color: 'rgb(255, 171, 0)', fontSize: '1.5em', alignContent:'left' }} />
//         )
//     }
//    }
//    if(violations && !warnings){
//     if(violations && violations.temperature  ){
//         return(
//             <FontAwesomeIcon icon={faThermometerThreeQuarters} style={{  color: 'rgb(255, 86, 48)', fontSize: '1.5em', alignContent:'left' }} />
//         )
//     }
//    }
//    if(!violations && warnings){
//     if(warnings && warnings.temperature  ){
//         return(
//             <FontAwesomeIcon icon={faThermometerThreeQuarters} style={{ color: 'rgb(255, 171, 0)', fontSize: '1.5em', alignContent:'left',marginTop:'8px' }} />
//         )
//     }
//    }

// }

const getIcon=()=>{
  const {assetTemperatureSettings }= params && params.data;
  if(!assetTemperatureSettings )return;

let deviceStatus= "noVio";
const tempLastPing=Number( params && params.data &&  params.data.lastPing && params.data.lastPing.t1 && params.data.lastPing.t1[params.data.lastPing.t1.length - 1] );
const maxVio= Number(assetTemperatureSettings.maxViolation); 
const minVio= Number(assetTemperatureSettings.minViolation); 
const maxWarning= Number(assetTemperatureSettings.maxWarning); 
const minWarning= Number(assetTemperatureSettings.minWarning); 
 
const isVio=tempLastPing > maxVio
     || tempLastPing < minVio

const isWarned = tempLastPing > maxWarning 
     || tempLastPing < minWarning

     if(isVio){
      deviceStatus ='Violation'
    }
    if(!isVio && isWarned){
      deviceStatus = 'Warning'
    }

    if( deviceStatus === 'Violation'){
      return (
        <FontAwesomeIcon icon={faThermometerThreeQuarters} style={{ color: 'rgb(255, 86, 48)', fontSize: '1.5em', alignContent:'left'}} />
         )
    }
    if( deviceStatus === 'Warning'){
      return (
        <FontAwesomeIcon icon={faThermometerThreeQuarters} style={{  color: 'rgb(255, 171, 0)', fontSize: '1.5em', alignContent:'left' }} />
         )
    }
    
}

const Icon= getIcon()

const tempValue=gettempValue()

return(
 
    <div>
         <div className={classes.outerDiv}>
         <div className={classes.modularDiv} >

         
            {Icon}
            <p className={classes.text}>
            {tempValue}
                </p>
          </div>
         </div>
    </div>



)


}


export default withStyles(styles)(StripsofViolation);