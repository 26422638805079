import React, { useState, useContext, useEffect } from 'react';
import { withStyles } from '@mui/styles';
import { AgGridReact } from 'ag-grid-react';
// import { connect } from 'react-redux';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
// import UserGroups from './UserGroups';
import UserContext from 'Context/UserContext';


const styles = {
  gridParams: {
    width: '100%',
    boxShadow: '1px 1.7px 7px 0 rgba(136, 156, 176, 0.1)',
    // height: '500px',
  },
  headerClass: {
    backgroundColor: '#008081',
    color: 'white',
    fontFamily:'Montserrat',
    fontSize: "14px",
    '&:hover': {
      backgroundColor: '#008081!important',
    },

  },
  emptyStateDiv: {
    height: '300px',
  },
  rowIcon: {
    maxHeight: '3.5em',
    paddingLeft: '0.8em',
    // fontSize: props.fontSize,
  },
};
const domLayout = 'autoHeight';
const overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Please wait while your groups are loading</span>';
const overlayNoRowsTemplate = '<img src="../../img/emptybox.png" alt="No groups"  />';

function UserTable(props) {
  const { rowData, classes, onSelectionChanged, selectedRowData, searchvalue } = props;
  const [gridApi, setGridApi] = useState(null);


  const companyDetails = useContext(UserContext); // react context
  const { accountUser } = companyDetails;

  const onGridReady = (params) => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };

  const handleSelectionChanged = () => {
    if (gridApi) {
      onSelectionChanged(gridApi.getSelectedRows());
    }
  };

  const handleSelectionCleared = () => {
    if (selectedRowData.length === 0 && gridApi) {
      gridApi.deselectAll();
    }
  };


  const onSearch = () => {
    
    if (gridApi) {
      gridApi.setQuickFilter(searchvalue);
    }
  };

  useEffect(() => {
    onSearch();
  }, [searchvalue]);


  useEffect(() => {
    if (!selectedRowData.length) {
      handleSelectionCleared();
    }
  }, [selectedRowData && selectedRowData.length]); // selectedRowData

  const rolesRenderer = (params) => {
    if (params && params.data && params.data.groupClientRole && Object.keys(params.data.groupClientRole).length > 0) {
      let roleName = params.data.groupClientRole[accountUser.companyId[0]][0];
      if (roleName && roleName.startsWith(accountUser.companyId[0])) {
        roleName = roleName.substring(accountUser.companyId[0].length + 1); // remove companyId_
       
        // roleName = roleName.split('_').join(' ');
        
      }
      return roleName;
    }
    return 'No role alloted to group';
  };



  const groupNameFormatter = (params) => {
    if (params.data.type && params.data.type === 'general') {
      return 'General';
    }
    if (params.data.type && params.data.type === 'clientGroup') {
      return 'Client Group';
    }
    return params.data.type;
  };
  const columnDefs = [
    {
      headerName: 'Group Name', sortable: true, field: 'groupName', minWidth: 200,pinned: 'left',  checkboxSelection: true,
    },
    {
      headerName: 'Role', field: 'groupClientRole', minWidth: 200, valueFormatter: rolesRenderer,
    },
    {
      headerName: 'Type', sortable: true, field: 'type', minWidth: 200, valueFormatter: groupNameFormatter,
    },
  ];

  const rowSelection = 'multiple';
  const defaultColDef = {
    sortable: false,
    filter: true,
    suppressMovable: true,
    resizable: true,
    cellStyle: { textAlign: 'left', fontFamily:'Montserrat',fontSize: "14px", },
    headerClass: classes.headerClass,
   
  };

  return (
    <div className={`${classes.gridParams} ag-theme-material`}>
      <AgGridReact
        enableBrowserTooltips
        columnDefs={columnDefs}
        rowData={rowData}
        defaultColDef={defaultColDef}
        rowMultiSelectWithClick
        pagination
        domLayout={domLayout}
        paginationPageSize={10}
        overlayLoadingTemplate={overlayLoadingTemplate}
          // overlayNoRowsTemplate={noRowsTemplate}
        rowSelection={rowSelection}
        onGridReady={onGridReady}
        onSelectionChanged={handleSelectionChanged}
        rowDeselection
        overlayNoRowsTemplate={overlayNoRowsTemplate}
      />
    </div>
  );
}
export default (withStyles(styles)(UserTable));
