/* eslint-disable react/prop-types */
/**
 * This grid doen't allow you to select a row
 */
import React  from 'react';
import { withStyles } from '@mui/styles';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTint, faThermometerThreeQuarters, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import Battery60RoundedIcon from '@mui/icons-material/Battery60Rounded';
import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';
import { Tooltip } from '@mui/material';
import { CtoF } from '../../utils/helpers';


const moment = require('moment');

const styles = {
  headerClass: {
    backgroundColor: '#008081',
    color: 'white',
    fontFamily:'Montserrat',
     fontSize:'14px' ,
     width:'100%',
    borderRight: '1px solid white!important',
    '&:hover': {
      backgroundColor: '#008081!important',
    },
  },
  buttonStyle:{
    height: '30px', 
    cursor:'pointer', 
     backgroundColor:'#fff' ,
     border:'1px solid rgba(0,0,0,0.25)',
     borderRadius:'4px',
     "&:hover": {
      boxShadow: "7px 7px 5px -3px rgba(169,208,208)",
    },
  }
};

function timeStampFormatter(params) {
  return moment.unix(params.value).format('DD-MM-YY,  hh:mm A ');
}

function currentValueFormatter(params, companySettings) {

  // const companySettings = null;
  if (params.data && params.data.data  && Object.prototype.hasOwnProperty.call(params.data, "setValue") ) {
    if (params.data.type && params.data.type === 'temperature') {
      if (companySettings && companySettings.temperature && companySettings.temperature === 'F') {
        // console.log('sdsd yes', `${CtoF(params.data.data)}°F (${CtoF(params.data.setValue)}°F)`)
        return `${CtoF(params.data.data).toFixed(1)}°F (${CtoF(params.data.setValue).toFixed(1)}°F)`;
      }
      return `${params.data.data}°C (${params.data.setValue}°C)`;
    }
    if (params.data.type && params.data.type === 'humidity') {
      return `${params.data.data}% (${params.data.setValue}%)`;
    }
    return `${params.data.data}% (${params.data.setValue}%)`;
  }
  return ' - ';
}

function currentNoteFormatter(params){
  // console.log('params', params, params.data.note)
    if(params && params.data && params.data.note){
      return params.data && params.data.note
    }
  return "-"
  }

  

function eventTypeFormatter(params) {
  if (params.data && params.data.type && params.data.level) {
    const { type } = params.data;
    const { level } = params.data;
    return (
      <div style={{ display: 'inline-flex', verticalAlign: 'middle' }} title={`${type} ${level}`}>
        { type === 'temperature' && level === 'violation' && (
        <FontAwesomeIcon icon={faThermometerThreeQuarters} style={{ color: 'rgb(255, 86, 48)', fontSize: '2em',paddingLeft:'0.3rem'}} />
        )}
        { type === 'temperature' && level === 'warning' && (
        <FontAwesomeIcon icon={faThermometerThreeQuarters} style={{ color: 'rgb(255, 171, 0)', fontSize: '2em',paddingLeft:'0.3rem' }} />
        )}
        { type === 'humidity' && level === 'violation' && (
        <FontAwesomeIcon icon={faTint} style={{ color: 'rgb(255, 86, 48)', fontSize: '2em',paddingLeft:'0.3rem' }} />
        )}
        { type === 'humidity' && level === 'warning' && (
        <FontAwesomeIcon icon={faTint} style={{ color: 'rgb(255, 171, 0)', fontSize: '2em' ,paddingLeft:'0.3rem'}} />
        )}
        { type === 'battery' && level === 'critical' && (
        <Battery60RoundedIcon style={{ color: 'rgb(255, 86, 48)', fontSize: '2em' , }} />
        )}
        { type === 'battery' && level === 'warning' && (
        <Battery60RoundedIcon style={{ color: 'rgb(255, 171, 0)', fontSize: '2em', }} />
        )}
      </div>
    );
  } return (
    <div style={{ display: 'inline-flex', verticalAlign: 'middle' }}>
      <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: 'rgb(163, 6, 6)', fontSize: '2em' }} />
    </div>
  );
}

function EventLogsGrid({
  classes, rowData, onGridReady, companySettings,handleDeviceNoteChange
}) 
{




 




  function deviceNoteChange(change){
    // console.log('changed',change.data,change.data.note);
    if (handleDeviceNoteChange) {
     
      handleDeviceNoteChange(change.data.uid, change.newValue);
    }
  }

 
  function CellRenderer(props){
  
    const handleClick = () => {
     
      props.api.startEditingCell({
        rowIndex: props.rowIndex,
        colKey: props.column.getId(),
      });
    }; 
   

    return (
      <span>
        <Tooltip title="click to edit note">
        <button 
        className={classes.buttonStyle}
    
        onClick={handleClick}
        >
          {/* ✎ */}
          <EditIcon style={{color:' rgb(0 128 129 / 60%'}}/>
        </button>
  
        </Tooltip>
        <span style={{ paddingLeft: '4px' }}  >{props.value ? props.value : '-' }</span>
       
      </span>
    );
  }

  const columnDefs = [
    {
      headerName: 'Event',
      field: 'type',
      // filter: true,
      minWidth: 130,
     
      // minWidth: 150,
      cellRendererFramework: eventTypeFormatter,
      // pinned:true,
    },
    {
      headerName: 'Device ID',
      field: 'deviceId',
      // minWidth: 500,
      minWidth:250,
      sortable: true,
      filter: true,
      // pinned:true,
      // pinned: 'left',
    },
    {
      headerName: 'Time',
      field: 'timeStamp',
      sortable: 'true',
      sort: 'desc',
      minWidth: 300,
      valueFormatter: timeStampFormatter,
    },
    {
      headerName: 'Value (Set Value)',
    
      // field: 'data',
      // sortable: 'true',
      // filter: true,
      minWidth: 320,
      valueFormatter: (params) => { return currentValueFormatter(params, companySettings)},
    },
    {
      headerName: 'Note',
      field:'note',
      minWidth: 445,
      // minHeight:200,
      editable: true,
      headerTooltip:'Note',
      valueFormatter:  currentNoteFormatter,
      onCellValueChanged: deviceNoteChange,
      cellRenderer: CellRenderer,
    
    },
  ];

  // useEffect(() => {
  //   document.addEventListener('click', handleClickOutsideGrid);
  //   return () => {
  //     document.removeEventListener('click', handleClickOutsideGrid);
  //   };
  // }, []);

  // function handleClickOutsideGrid(event) {
  //   if (agGridRef.current && !agGridRef.current.contains(event.target)) {
      
  //     columnDefs.api.stopEditing();
  //     // User clicked outside of ag grid
  //     // Add your code here
  //   }
  // }

  const defaultColDef = {
    sortable: false,
    filter: false,
    resizable: true,
    cellStyle: { textAlign: 'left',fontSize:'14px', fontFamily:'Montserrat', height:'48px' },
    headerClass: classes.headerClass,

  };

 

  

 

  const overlayLoadingTemplate = '<span class="ag-overlay-loading-center"><img src="./../img/horizontalLoader.svg" /></span>';
  const overlayNoRowsTemplate = '<img src="./../img/emptybox.png" />';
  return (
    <div  className="ag-theme-material" style={{ width: 'auto' }}>
     
      <AgGridReact
        enableBrowserTooltips
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        rowMultiSelectWithClick
        // onSelectionChanged={handleSelectionChanged}
    
        rowData={rowData}
        onGridReady={onGridReady}
        
        pagination
        paginationPageSize={10}
        enableCellTextSelection
        domLayout="autoHeight"
        overlayLoadingTemplate={overlayLoadingTemplate}
        overlayNoRowsTemplate={overlayNoRowsTemplate}
        suppressClickEdit={true}
      />
     
    </div>
  );
}

EventLogsGrid.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  rowData: PropTypes.instanceOf(Object),
  onGridReady: PropTypes.func.isRequired,
  // onSelectionChanged: PropTypes.instanceOf(Object),
};

EventLogsGrid.defaultProps = {
  rowData: null,
  // onSelectionChanged: [],
};

export default withStyles(styles)(EventLogsGrid);
