/* eslint-disable react/prop-types */
import React from "react";
import { withStyles } from "@mui/styles";
import Grid from '@mui/material/Grid';
import { Typography } from "@mui/material";
// import PrimaryButton from "components/PrimaryButton";
import CompanyLogo from "components/CompanyLogo";

const styles={

}

const CompanyLogoSettings=(props)=>{
  const {companyInfo, handleCompanyLogoUpdate }=props;
// console.log('Props', companyInfo, handleCompanyLogoUpdate)

    return (
        <>
        <Grid
                container
                item
                spacing={2}
                xs={12}
                alignItems="center"
                justifyContent="center"
              >
                   <Grid container item   alignItems="center" >
                  <Grid item  lg={2} md={3} sm={4} xs={8} >
                  <Typography component="span" variant="p"  
                  sx={{
                    fontFamily: "Montserrat",
                    textAlign:'left',
                    fontSize: "16px",
                    
                  }} >
            <Grid item lg={12} md={12} sm={12} xs={12}  >
                  <CompanyLogo
                    imageUrl={(companyInfo && companyInfo.companyLogo && companyInfo.companyLogo.url) ? companyInfo.companyLogo.url : null}
                    handleCompanyLogoUpdate={handleCompanyLogoUpdate}
                  />
                </Grid>
                    </Typography>
                  </Grid>
                  {/* <Grid item xs={3}   >
                 
                  </Grid> */}
                  
                  {/* <Grid container item >
                    <Grid item xs={9} />
                   <Grid item xs={3} style={{justifyContent:'flex-end'}}>
                       <PrimaryButton text="Update All Changes " />
                       </Grid>   
                  </Grid> */}
                </Grid>
                  </Grid>
        </>
    )
};

export default withStyles(styles)(CompanyLogoSettings);