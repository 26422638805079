import axios from 'axios';
import keycloak from 'utils/keycloak';

const verifyToken = () => {
  const tokenParsed = keycloak? keycloak.tokenParsed : null;
  const currTime = new Date().getTime() / 1000
  if (!tokenParsed || !tokenParsed.auth_time || 
        ( currTime - tokenParsed.auth_time) > (tokenParsed.exp - tokenParsed.iat) || 
        ( currTime > tokenParsed.exp)) {
    keycloak.logout()
  }
}
const onBeforeRequestSent = (config) => {
  verifyToken()
  const token = window.accessToken;
  if (token) config.headers.common.Authorization = `Bearer ${token}`;
  return config;
};

const onRequestError = (error) => Promise.reject(error);

const instance = axios.create({
  headers: { 'Content-Type': 'application/json' },
  baseURL: process.env.API_URL,
});

// request interceptor
instance.interceptors.request.use(onBeforeRequestSent, onRequestError);

const API = instance;

export default API;
