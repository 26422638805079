/* eslint-disable react/prop-types */
// import Calendar from 'components/Calendar';
import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import API from 'API/apiConfig';
import Charts from 'components/Charts';
import { CircularProgress, } from "@mui/material";

const APIURL = process.env.REACT_APP_APIURL;
const deviceViews = {
  PIXEL: {
    component: (props) => <Charts  {...props} />,
  },
  PIXEL_W300: {
    component: (props) => <Charts {...props} />,
  },
  PIXEL_C100: {
    component: (props) => <Charts {...props} />,
  },
  PIXEL_W100: {
    component: (props) => <Charts {...props} />,
  },
  PIXEL_C300: {
    component: (props) => <Charts {...props} />,
  },
};

function Devicecontainer(props) {
  const { selecteddevice, newdates } = props;
  const [hasData, setHasData] = useState(false);
  const [devicesWithData, setDevicesWithData] = useState([]);
  const [loading, setLoading] = useState(false);
  const deviceType = (selecteddevice && selecteddevice[0].type) || 'PIXEL';
  // console.log('Start',newdates.start, newdates.start.unix())
  const getDevicePings = () => new Promise((resolve, reject) => {
    if (selecteddevice && selecteddevice.length) {
      const devicesQuery = `?uid=${selecteddevice[0].uid}&startTime=${newdates.start.unix()}&endTime=${newdates.end.unix()}`;
      setLoading(true);
      API
        .get(`${APIURL}/devicepings${devicesQuery}`)
        .then((res) => {

          if (res.data.status === 'success') {
            const devicePings = res.data.data;
            resolve(devicePings);
            setLoading(false);
          } else resolve([]);
        }).catch((error) => {
          reject(error);
          setLoading(false);
          console.error("error getting device pings", error?.response?.data);
        });
    }
  });

  useEffect(() => {

    getDevicePings().then((devicePings) => {
      if (devicePings) {
        setHasData(true);
        setLoading(false);
        setDevicesWithData([{ ...selecteddevice[0], devicePings }]);
      }
    }).catch(() => {

    });
  }, [newdates, selecteddevice]);

  return (
    <div>
      <Grid container >
        <Grid item lg={12} sm={12} xs={12} md={12} >

          {(hasData && deviceViews[deviceType]) && deviceViews[deviceType].component({ devices: devicesWithData })}
          {loading && (
            <div
              style={{
                position: "fixed",
                display: "block",
                width: "100%",
                height: "100%",
                top: "0",
                left: "0",
                right: "0",
                bottom: "0",
                backgroundColor: "rgba(0,0,0,0.5)",
                zIndex: "2",
              }}
            >
              <CircularProgress
                style={{ position: "absolute", top: "50%", left: "50%" }}
              />
            </div>
          )}
          {!hasData && !loading && (
            <div>
              <img src="../../img/nodata.png" alt="No data" ></img>
              {/* No data */}
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
export default Devicecontainer;
