/* eslint-disable no-prototype-builtins */
import React,{useEffect, useState, useContext} from 'react';
import { withStyles } from '@mui/styles';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import PropTypes from 'prop-types';
import UserContext from 'Context/UserContext';
import { CtoF } from '../../../utils/helpers';

const styles = {
  gridParams: {
    width: "100%",
    boxShadow: "1px 1.7px 7px 0 rgba(136, 156, 176, 0.1)",
  },
  headerClass: {
    backgroundColor: '#008081',
    fontFamily:'Montserrat',
    fontSize:'14px',
    borderRight: '1px solid white!important',
    color: 'white',
    '&:hover': {
      backgroundColor: '#008081!important',
    },
  },
};



function temperatureValueFormatter(params, companySettings) {

  
  if (params.data.temperatureSettings) {
    if (params.data.temperatureSettings.hasOwnProperty('minViolation') && params.data.temperatureSettings.hasOwnProperty('maxViolation')) {
      if (companySettings && companySettings.temperature && companySettings.temperature === 'F') {
        return `${CtoF(params.data.temperatureSettings.minViolation).toFixed(1)} to ${CtoF(params.data.temperatureSettings.maxViolation).toFixed(1)}°F`;
      }
      return `${params.data.temperatureSettings.minViolation}°C to ${params.data.temperatureSettings.maxViolation}°C`;
    } if (params.data.temperatureSettings.hasOwnProperty('minViolation') && !params.data.temperatureSettings.hasOwnProperty('maxViolation')) {
      if (companySettings && companySettings.temperature && companySettings.temperature === 'F') {
        return `> ${CtoF(params.data.temperatureSettings.minViolation).toFixed(1)}°F`;
      }
      return `> ${params.data.temperatureSettings.minViolation}°C`;
    } if (!params.data.temperatureSettings.hasOwnProperty('minViolation') && params.data.temperatureSettings.hasOwnProperty('maxViolation')) {
      if (companySettings && companySettings.temperature && companySettings.temperature === 'F') {
        return `> ${CtoF(params.data.temperatureSettings.maxViolation).toFixed(1)}°F`;
      }
      return `< ${params.data.temperatureSettings.maxViolation}°C`;
    } return ' - ';
  } return ' - ';
}

function temperatureWarningValueFormatter(params, companySettings) {
  if (params.data.temperatureSettings) {
    if (params.data.temperatureSettings.hasOwnProperty('minWarning') && params.data.temperatureSettings.hasOwnProperty('maxWarning')) {
      if (companySettings && companySettings.temperature && companySettings.temperature === 'F') {
        return `${CtoF(params.data.temperatureSettings.minWarning).toFixed(1)} to ${CtoF(params.data.temperatureSettings.maxWarning).toFixed(1)}°F`;
      }
      return `${params.data.temperatureSettings.minWarning}°C to ${params.data.temperatureSettings.maxWarning}°C`;
    } if (params.data.temperatureSettings.hasOwnProperty('minWarning') && !params.data.temperatureSettings.hasOwnProperty('maxWarning')) {
      if (companySettings && companySettings.temperature && companySettings.temperature === 'F') {
        return `> ${CtoF(params.data.temperatureSettings.minWarning).toFixed(1)}°F`;
      }
      return `> ${params.data.temperatureSettings.minWarning}°C`;
    } if (!params.data.temperatureSettings.hasOwnProperty('minWarning') && params.data.temperatureSettings.hasOwnProperty('maxWarning')) {
      if (companySettings && companySettings.temperature && companySettings.temperature === 'F') {
        return `> ${CtoF(params.data.temperatureSettings.maxWarning).toFixed(1)}°F`;
      }
      return `< ${params.data.temperatureSettings.maxWarning}°C`;
    } return ' - ';
  } return ' - ';
}

function humidityValueFormatter(params) {
  if (params.data.humiditySettings) {
    if (params.data.humiditySettings.hasOwnProperty('minViolation') && params.data.humiditySettings.hasOwnProperty('maxViolation')) {
      return `${params.data.humiditySettings.minViolation}% - ${params.data.humiditySettings.maxViolation}%`;
    } if (params.data.humiditySettings.hasOwnProperty('minViolation') && !params.data.humiditySettings.hasOwnProperty('maxViolation')) {
      return `> ${params.data.humiditySettings.minViolation}%`;
    } if (!params.data.humiditySettings.hasOwnProperty('minViolation') && params.data.humiditySettings.hasOwnProperty('maxViolation')) {
      return `< ${params.data.humiditySettings.maxViolation}%`;
    } return ' - ';
  } return ' - ';
}

function humidityWarningValueFormatter(params) {
  if (params.data.humiditySettings) {
    if (params.data.humiditySettings.hasOwnProperty('minWarning') && params.data.humiditySettings.hasOwnProperty('maxWarning')) {
      return `${params.data.humiditySettings.minWarning}% - ${params.data.humiditySettings.maxWarning}%`;
    } if (params.data.humiditySettings.hasOwnProperty('minWarning') && !params.data.humiditySettings.hasOwnProperty('maxWarning')) {
      return `> ${params.data.humiditySettings.minWarning}%`;
    } if (!params.data.humiditySettings.hasOwnProperty('minWarning') && params.data.humiditySettings.hasOwnProperty('maxWarning')) {
      return `< ${params.data.humiditySettings.maxWarning}%`;
    } return ' - ';
  } return ' - ';
}

 function batteryValueFormatter(params){
  if (params.data.batterySettings){
    if (params.data.batterySettings.hasOwnProperty('critical') && params.data.batterySettings.hasOwnProperty('warning')) {
      return `${params.data.batterySettings.critical}% - ${params.data.batterySettings.warning}%`;
    } if (params.data.batterySettings.hasOwnProperty('critical') && !params.data.batterySettings.hasOwnProperty('warning')) {
      return `> ${params.data.batterySettings.critical}%`;
    } if (!params.data.batterySettings.hasOwnProperty('critical') && params.data.batterySettings.hasOwnProperty('warning')) {
      return `< ${params.data.batterySettings.warning}%`;
    } return ' - ';
  }return ' - ';
 }
function ChambersGrid({
   classes,rowData, onSelectionChanged, companySettings,selectedRowData,searchvalue, 
}) {

  // console.log('Row Data',rowData )
  const companyDetails = useContext(UserContext);
  const { CompanyInfo } = companyDetails;


  let columnDefs = [{
    headerName: CompanyInfo && CompanyInfo.settings.chamberType ? (CompanyInfo.settings.chamberType + " ID") : "Chamber Id",
    field: 'id',
    sortable: true,
    filter: true,
    pinned: 'left',
    minWidth: 220,
    headerTooltip: CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.chamberType &&  CompanyInfo.settings.chamberType.length > 18 ?  CompanyInfo.settings.chamberType  + " ID" : '',
    tooltipField: 'id',
    checkboxSelection: true,
  }, {
    headerName: `${(companySettings && companySettings.temperature && companySettings.temperature === 'F') ? 'Temperature (°F)' : 'Temperature Violation'}`,
    minWidth: 220,
    valueFormatter: (params) => temperatureValueFormatter(params, companySettings),
  }, {
    headerName: 'Humidity Violation',
    minWidth: 200,
    valueFormatter: humidityValueFormatter,
  }, {
    headerName: `${(companySettings && companySettings.temperature && companySettings.temperature === 'F') ? 'Temperature warning(°F)' : 'Temperature Warning'}`,
    minWidth: 240,
    valueFormatter: (params) => temperatureWarningValueFormatter(params, companySettings),
  }, {
    headerName: 'Humidity Warning',
    minWidth: 220,
    valueFormatter: humidityWarningValueFormatter,
  },
  {
    headerName: 'Battery Warning Range',
    minWidth: 240,
    valueFormatter: batteryValueFormatter,
  },
   {
    headerName: 'Commodity',
    field: 'note',
    minWidth: 260,
    cellClass: 'cell-wrap-text',
    autoHeight: true,
  }];
  if (CompanyInfo && CompanyInfo.companyId && (["QTS", "FTVP"].includes(CompanyInfo.companyId))) {
    columnDefs = columnDefs.filter(c => (c.headerName && !["Humidity Violation", "Humidity Warning"].includes(c.headerName)))
  }
  const [gridApi, setGridApi] = useState(null);

  const onGridReady = (params) => {
    // const gridApi = params.api;
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };

  const handleSelectionChanged = () => {
    if (gridApi) {
      onSelectionChanged(gridApi.getSelectedRows());
    }
  };

  const onSearch = () => {
    if (gridApi) {
      gridApi.setQuickFilter(searchvalue);
    }
  };

  const handleSelectionCleared = () => {
    if (selectedRowData.length === 0 && gridApi) {
      gridApi.deselectAll();
    }
  };

  useEffect(() => {
    if(selectedRowData){
      if (!selectedRowData.length) {
        handleSelectionCleared();
      }
    }
    
  }, [selectedRowData && selectedRowData.length]); // selectedRowData


  useEffect(() => {
    onSearch();
  }, [searchvalue]);

 

  const gridOptions = {
    tooltipShowDelay: 0,
    // tooltipHideDelay: 0,
  };

  const defaultColDef = {
    sortable: false,
    filter: false,
    suppressMovable: true,
    resizable: true,
    cellStyle: { textAlign: 'left',fontFamily:'Montserrat',
    fontSize: "14px", },
    headerClass: classes.headerClass,
   
  };
  const overlayLoadingTemplate = '<span class="ag-overlay-loading-center"><img src="./../img/horizontalLoader.svg" /></span>';
  const overlayNoRowsTemplate = '<img src="./../img/emptybox.png" />';
  const rowSelection = 'multiple';
  return (
    <div
      className="ag-theme-material"
    >
      <AgGridReact
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        rowData={rowData}
        rowSelection={rowSelection}
        // onSelectionChanged={onSelectionChanged}
         onSelectionChanged={handleSelectionChanged}
         gridOptions={gridOptions}
        rowMultiSelectWithClick="true"
        onGridReady={onGridReady}
        pagination
        paginationPageSize={10}
        domLayout="autoHeight"
        overlayLoadingTemplate={overlayLoadingTemplate}
        overlayNoRowsTemplate={overlayNoRowsTemplate}
      />
    </div>
  );
}

ChambersGrid.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  rowData: PropTypes.instanceOf(Object),
  // onGridReady: PropTypes.func.isRequired,
  onSelectionChanged: PropTypes.instanceOf(Object),
};

ChambersGrid.defaultProps = {
  rowData: null,
  onSelectionChanged: [],
};

export default withStyles(styles)(ChambersGrid);
