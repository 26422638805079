/* eslint-disable react/prop-types */
import React, {useContext, useEffect} from "react";
import { withStyles } from "@mui/styles";
import Grid from '@mui/material/Grid';
import { Typography } from "@mui/material";
import FormControlLabel from '@mui/material/FormControlLabel';
import UserContext from 'Context/UserContext';
import Checkbox from '@mui/material/Checkbox';
// import PrimaryButton from "components/PrimaryButton";

const styles={

}

const DownloadSettings=(props)=>{
  const {companysettingsState, companysettingsDispatchFn }= props;

  const { enableExcelDownload} = companysettingsState;

  const { CompanyInfo } = useContext(UserContext);


  useEffect(() => {
    if (CompanyInfo && CompanyInfo.settings) {
     
      companysettingsDispatchFn({ type: "EXCEL", payload:CompanyInfo.settings.enableExcelDownload || ''});
     
     
      
    }
  }, [CompanyInfo]);

  const onhandleClick = (props) => {
    if (props === 'enableExcelDownload') {
      companysettingsDispatchFn({ type: "EXCEL", payload: !enableExcelDownload });
    }
  };


    return (
        <>
        <Grid
                container
                item
                spacing={2}
                // xs={12}
                // lg={12}
                // sm={12}
                alignItems="center"
                justifyContent="center"
              >
                   <Grid container item   alignItems="center" >
                  <Grid item lg={2} md={3} sm={4}  xs={5} >
                  <Typography component="span" variant="p"  
                  sx={{
                    fontFamily: "Montserrat",
                    textAlign:'left',
                    fontSize: "16px",
                    
                  }} >
              Excel download :
                    </Typography>
                  </Grid>
                  <Grid item  lg={3} md={3} sm={3} xs={3}   >
                  <FormControlLabel
                        control={(
                          <Checkbox
                
                            checked={enableExcelDownload}
                            onChange={() => { onhandleClick('enableExcelDownload'); }}
                            style={{color:'#008081'}}
                            value="enableExcelDownload"
                            color="primary"
                          />
)}
                      />
                  </Grid>
                 
                </Grid>
                  </Grid>
        </>
    )
};

export default withStyles(styles)(DownloadSettings);