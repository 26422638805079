import React, { useState, useEffect, useContext } from 'react';
import { withStyles } from '@mui/styles';
import UserContext from 'Context/UserContext';
import { AgGridReact } from 'ag-grid-react';
// import { connect } from 'react-redux';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';

// import '../../../../styles/agGridTheme.scss';
const styles = {
  gridParams: {
    width: '100%',
    // boxShadow: '1px 1.7px 7px 0 rgba(136, 156, 176, 0.1)',
    // height: '100%',
  
  },
  rowClass:{
    backgroundColor:'#000',
    '&:hover': {
      backgroundColor: '#008081!important',
    },
  },
  headerClass: {
    backgroundColor: '#008081', // 00adef
    color: 'white',
    fontFamily:'Montserrat',
    fontSize: "14px",
    '&:hover': {
      backgroundColor: '#008081!important',
    },
    textAlign:'center'
  },
  emptyStateDiv: {
    height: '300px',
  },
  rowIcon: {
    maxHeight: '3.5em',
    paddingLeft: '0.8em',
    // fontSize: props.fontSize,
  },
};

const domLayout = 'autoHeight';
const overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Loading roles</span>';
const overlayNoRowsTemplate = '<img src="../../img/emptybox.png" alt="No roles" />';
// const restrictionformatter = (params) => ((params.value && params.value.enabled) ? 'Restricted' : 'Full access');

// const getEmptyOverlayTemplate = (classes) => (
//   `<div className=${classes.emptyStateDiv}>
//       <p>No roles found </p>
//     </div>`
// );

function RolesTable(props) {
  const [gridApi, setGridApi] = useState(null);
  const {
    rowData, classes, onSelectionChanged, searchvalue, selectedRowData,
  } = props;

  const companyDetails = useContext(UserContext); // react context
  const { accountUser } = companyDetails;
  const roleNameFormatter = (params) => {
    let roleName = params.value;
    if (roleName.startsWith(accountUser.companyId[0])) {
      roleName = roleName.substring(accountUser.companyId[0].length + 1); // remove companyId_
    }
    return roleName;
  };

  const restrictionformatter = (params) => {
   
    return (params.value && params.value.enabled) ? 'Full access' : 'Restricted';
  };

  const columnDefs = [{
    headerName: 'Role Name', sortable: true, field: 'name', valueFormatter: roleNameFormatter, pinned: 'left',minWidth:200, checkboxSelection: true
  },
  {
    headerName: ' Access Type', field: 'fullAccess', minWidth: 250, valueFormatter: restrictionformatter, 
  },
  ];




   

  // onSelectionClear( gridApi.deselectAll());

  const onGridReady = (params) => {
    // const gridApi = params.api;
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };

  const handleSelectionChanged = () => {
   
    if (gridApi) {
      onSelectionChanged(gridApi.getSelectedRows());
    }
  };

  const handleSelectionCleared = () => {
    if (selectedRowData.length === 0 && gridApi) {
      gridApi.deselectAll();
    }
  };

  useEffect(() => {
    if (!selectedRowData.length) {
      handleSelectionCleared();
    }
  }, [selectedRowData.length]); // selectedRowData


  const onSearch = () => {
    
    if (gridApi) {
      gridApi.setQuickFilter(searchvalue);
    }
  };

  useEffect(() => {
    onSearch();
  }, [searchvalue]);



  const rowSelection = 'multiple';
  const defaultColDef = {
    sortable: false,
    filter: false,
    suppressMovable: true,
    resizable: true,
    cellStyle: { textAlign: 'left' , fontFamily:'Montserrat', fontSize: "14px",},
    headerClass: classes.headerClass,
    rowClass:classes.rowClass,
    
  };

  
  return (
    <div className={`${classes.gridParams} ag-theme-material`}>
      <AgGridReact
        // enableBrowserTooltips
        columnDefs={columnDefs}
        rowData={rowData}
        defaultColDef={defaultColDef}

        rowMultiSelectWithClick
        pagination
        domLayout={domLayout}
        paginationPageSize={10}
        overlayLoadingTemplate={overlayLoadingTemplate}
        // overlayNoRowsTemplate={noRowsTemplate}
        rowSelection={rowSelection}
        onGridReady={onGridReady}
        onSelectionChanged={handleSelectionChanged}
        rowDeselection
        overlayNoRowsTemplate={overlayNoRowsTemplate}
      />
    </div>
  );
}
export default (withStyles(styles)(RolesTable));
