/* eslint-disable no-unused-vars */
import {
  Button, CircularProgress, Typography, Tooltip, Dialog, DialogTitle, DialogContent, DialogContentText,
  DialogActions,
  TextField, MenuItem, List, ListItem, ListItemAvatar,FormControl,
   Divider,
} from '@mui/material';
import { withStyles } from '@mui/styles';
// import InputLabel from '@mui/material/InputLabel';
// import FormControl from '@mui/material/FormControl';
// import Accordion from '@mui/material/Accordion';
// import { ExpansionPanel } from '@mui/material';
import cogoToast from 'cogo-toast';
import * as yup from 'yup';
import React, {
  useState, useEffect, useContext,
} from 'react';
import Grid from '@mui/material/Grid';
import Grow from '@mui/material/Grow';
import DeleteIcon from '@mui/icons-material/Delete';
import Delete from '@mui/icons-material/Delete';
import Input from 'components/Input';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import PersonIcon from '@mui/icons-material/Person';
import PeopleIcon from '@mui/icons-material/People';
import ListItemText from '@mui/material/ListItemText';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SearchOption from 'components/SearchOption';
// import { makeStyles } from '@mui/material';
import API from 'API/apiConfig';
import toastOptions from 'utils/toast';
import UserContext from 'Context/UserContext';
import IconButton from '@mui/material/IconButton';
// import Autocomplete from '@mui/material/Autocomplete';

import SelectField from 'components/SelectField';
import UserTable from './UserTable';
import PrimaryButton from "../../../../components/PrimaryButton";
import SecondaryButton from "../../../../components/SecondaryButton";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';
import { useMediaQuery, createTheme } from '@material-ui/core';
import AutoField from 'components/AutoField';
import ListItemButton from '@mui/material/ListItemButton';

// import { Autocomplete } from "@mui/material";
// import groups from './groupdata';

const APIURL = process.env.REACT_APP_APIURL;

// const AddGroupsSchema = yup.object().shape({
//   groupName: yup.string().required('Group Name cannot be empty'),
//   roles: yup.array().required('Role cannot be empty'),
// });

const AddGroupsSchema= yup.object().shape({
  groupName: yup.string().required("Group Name cannot be empty"),
  roles: yup.array().required("Role cannot be empty"),
})



const styles = {

  hoverClass: {
    color: 'red',
    cursor: 'pointer',
    marginRight: '1em',
  },

  warning: {
    color: 'red',
    fontWeight: '500',
  },
  errorMessageDiv: {
    MarginTop: '20%',
    height: '20px',
    textAlign: 'left',
  },
  errorMessageText: {
    fontFamily: 'Montserrat',
    fontSize: '16px',
    textAlign: 'left',
    color: 'red',
    marginTop: '15px',
  },
  inputTextField: {
    border: 'none',
    borderBottom: '1px solid grey',
    width: '100%',
    height: '30px',
    outline: 'none',
    '&:focus': {
      borderBottom: '1px solid #00adef',
    },
  },
  inputLabel: {
    opacity: '0.5',
    fontFamily: 'Montserrat',
    fontSize: '12px',
    textAlign: 'left',
    marginTop: '10px',
    color: 'rgba(3, 0, 0, 0.5)',
  },
  modalDialogOuterDiv: {
    display: 'flex',
    justifyContent: 'center',
  },
  modalDialogDiv: {
    // display: 'flex',
    // width: '50%',
    // flexDirection: 'column',
    // paddingLeft: '10px',
    // paddingRight: '10px',
    width: '100%',

  },
  formControl: {
    marginTop: '1rem',
    width:'100%',
    
    overflow: 'auto',
                       
    
  },
  modalDisplayDiv: {
    height: '35px',
    display: 'flex',
    alignItems: 'center',
  },
  externalDiv: {
    width: '100%',
    height: 'auto',
  },
  outerDiv: {
    width: '100%',
    // float: 'left',
    // boxShadow: '1px 1.7px 7px 0 rgba(136, 156, 176, 0.1)',
    // backgroundColor: '#ffffff',
  },
  topDiv: {
    width: '100%',
    height: '50px',
    // marginTop: '-10%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonText: {
    fontFamily: 'Montserrat',
    fontSize: '13px',
    // width:'2rem',
    fontWeight: '500',
    marginLeft: '10px',
    marginRight: '10px',
  },
  editDiv: {
    // width: '100px',
    // borderRight: '1px solid #dedede',
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    cursor: 'pointer',
    color: '#008081',

  },
  disabledEditDiv: {
    // display: 'none',
    // width: '100px',
    // borderRight: '1px solid #dedede',
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    color: '#c2c2c2',
  },
  deleteDiv: {
    width: '10px',
    // borderRight: '1px solid #dedede',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    
  },
  disabledDeleteDiv: {
    // display: 'none',
    width: '10px',
    // borderRight: '1px solid #dedede'
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#c2c2c2',
  },
  tableHeadingDiv: {
    marginBottom: '30px',
    textAlign: 'left',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  tableHeading: {
    fontSize: '30px',
    color: '#000000',
    fontWeight: '300',
    lineHeight: '1',
  },
  addUsersDiv: {
    display: 'flex',
    float: 'right',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '50px',
  },
  addButtonDiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '30px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  importantField: {
    color: 'red',
  },
  mainControlDiv: {
    height: '50px',
  },
  controlDiv: {
    height: 'inherit',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  addButtonRoot: {
    // marginTop: '-20%',
    borderRadius: '20px',
    boxShadow: '0px 2px 12px 0 rgba(0, 0, 0, 0.1)',
    backgroundColor: '#00adef',
    '&:hover': {
      backgroundColor: '#00adef',
    },
  },
  addButtonLabel: {
    textTransform: 'capitalize',
  },
  clearSelectionDisabled: {
    display: 'block',
    position: 'relative',
  },
  clearSelectionActive: {
    color: 'red',
  },
  Selected: {
    cursor: 'auto',
    // color: 'blue',
  },
  formGroup: {
    display: 'flex',
    flexDirection: 'row',
  },
  permissionsGrid: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '0.1rem',
  },
  permissionsLabel: {
    opacity: '0.5',
    fontFamily: 'Montserrat',
    fontSize: '15px',
    textAlign: 'left',
    marginLeft: '10px',
    color: 'rgba(3, 0, 0, 0.5)',
  },
  addUserForm: {
    // minWidth: 240,
    width:'100%'
    // marginLeft: '12px',

  },
  infoDisabled: {
    display: 'none',

  },
  infoEnabled: {
    margin: '12px',
    // marginRight: '60rem',
    color: '#777',

  },
  overlay: {
    position: 'fixed',
    display: 'block',
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    backgroundColor: 'rgba(0,0,0,0.5)',
    zIndex: '2',
  },
  deleteButton: {
    padding: '1.2rem',
  },
  editmanageuserDiv: {
    borderRight: '1px solid #dedede',
    marginRight: 'auto',
    // width: '10rem',
    textTransform: "capitalize",

  },
  disabledmanageuserDiv: {
    borderRight: '1px solid #dedede',
    marginRight: 'auto',
    // width: '10rem',
    textTransform: "capitalize",
  },
  addButton: {
    backgroundColor: '#008081',
    borderRadius: '20px',
    textAlign: 'center',
    color: 'white',
    fontWeight: 'bold',
    // minWidth:'99px',
    '&:hover': {
      backgroundColor: '#008081',
    },
  },
  saveButton: {
    backgroundColor: '#008081',
    color: 'white',
    borderRadius: '20px',
    margin: '10px !important',
    padding: '5px 15px',
    minWidth: '99px',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#008081',
    },
  },
  cancelButton: {
    color: '#008081',
    padding: '5px 15px',
    minWidth: '99px',
    borderRadius: '20px !important',
    border: '2px solid #008081 !important',
  },
  dialogtitle: {
    textAlign: 'left',
    color: '#000',
    fontWeight: 'bold',
    fontFamily:'Montserrat',
    fontSize:'20px'
  },
  selectStyles: {
    backgroundColor: '#fff',
    borderRadius: '22px',
    fontSize: '14px',
    disableUnderline: true,
    '&:hover': {
      backgroundColor: '#fff',
      borderRadius: '22px',
      fontSize: '14px',
      disableUnderline: true,
    },
  },
  addUserText:{
    fontSize:'20px',
    fontFamily:'Montserrat',
    fontWeight:'bold',
    color: '#000',
    // padding:'2rem'
  },
  title: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  appBar:{
    position:'sticky'
  }

};


const UserGroups = ({ classes }) => {
  // const [groups, setgroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [roles, setRoles] = useState([]);
  const [edit, setEdit] = useState(false);
  const [name, setName] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [openUserDialog, setOpenUserDialog] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [accessType, setAccessType] = useState(' ');
  const [rowData, setRowData] = useState(null);
  const [searchInput, setSearchInput] = useState('');
  const [userList, setUserList] = useState(false);
  const [addedUserList, setAddedUserList] = useState([]);
  const [usersInGroup, setUsersInGroup] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [groupData, setGroupData] = useState([]);
  const [groupRole, setGroupRole] = useState('');
  const [selectedUserGroup, setSelectedUserGroupName]=useState('');
 const [loadingScreen , setLoadingScreen]= useState(false)
  const [inputValue, setInputValue] = React.useState('');
  const [fullScreen, setFullScreen]= useState(false);

  

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 670,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
  });
  
   const breakPoint= useMediaQuery(theme.breakpoints.up('sm'));


  const companyDetails = useContext(UserContext);
  const { userPermissions, accountUser } = companyDetails;


  

 
  const onSearchChange = (event) => {
    setSearchInput(event.target.value);
  };

  const onDeleteDialogClose = () => {
    setOpenDeleteDialog(false);
    setSelectedRowData([])
  };

  // this function is called when we delete some group
  const onDeleteDialogSave = () => {
    const { groupId } = { ...selectedRowData[0] };
    // const { selectedRowData } = this.state;
   
    const selectedRowsUidsArray = selectedRowData.map((item) => item.groupId);
   
    setLoading(true);
    API.delete(`${APIURL}/groups`, {
      params: {
        groupIds: selectedRowsUidsArray,
        
      },
    })
      .then((deletingUsersResponse) => {
        if (deletingUsersResponse.data.status === 'success') {

         
        
          const newGroups = rowData.filter((r) => !selectedRowsUidsArray.includes(r.groupId));
          
          setOpenDeleteDialog(false);
          setSelectedRowData([]);
          setRowData([...newGroups]);
          setLoading(false);
          setSearchInput('');
          cogoToast.success(
            'Deleted the selected group successfully',
            toastOptions,
          );
        } else {
          cogoToast.error(
            deletingUsersResponse.data.message
              ? deletingUsersResponse.data.message
              : 'Error deleting users',
            toastOptions,
          );
          setLoading(false);
        }
      })
      .catch((errorDeletingUsers) => {
        console.error(
          errorDeletingUsers.message
            ? errorDeletingUsers.message
            : 'Error deleting users',
        );
        setLoading(false);
      });
  };


  const onAddDialogClose = () => {
    setOpenAddDialog(false);
    setSelectedRole(' ');
    setEdit(false);
    setErrorMessage(null);
    setSelectedRowData([])
  };

  // to get UserGroups
  const getUserGroups = () => {
    API.get(`${APIURL}/groups`)
      .then((response) => {
        if (response.data && response.data.status === 'success'){
          const groupResponse = response.data.data;
          const groups = [];
          // console.log('groups', groupResponse);
          groupResponse.forEach((group) => {
            groups.push({
              groupId: group.id,
              groupName: group.name,
              groupClientRole: group.clientRoles,
              clientName: group.attributes && group.attributes.client && group.attributes.client[0] ? group.attributes.client[0].name : '',
              notificationSettings: group.attributes.notificationSettingsByAdmin ? group.attributes.notificationSettingsByAdmin : [],
              type: (group.attributes && group.attributes.type) ? group.attributes.type[0] : '',
              accessType: group.accessType,
            });
          });
          setLoading(false);
          setRowData(groups);
          setGroupData(groups);
        }else{
          setLoading(false);
        }
       
      })
      .catch((error) => console.log('error getting groups', error.message || error),
        setLoading(false));
  };

  // to get userGroup in which user is present
  const getGroupsOfUser = () => {
    if (accountUser && accountUser.sub) {
      API.get(`${APIURL}/users/${accountUser.sub}/groups`)
        .then((userGroupsResponse) => {
          if (userGroupsResponse.data && userGroupsResponse.data.status === 'success'){
            const userGroupsResponseData = userGroupsResponse.data.data;
            const groupsOfUser = [];
            // console.log('groups', groupResponse);
            userGroupsResponseData.forEach((group) => {
              groupsOfUser.push({
                accessType: group.accessType,
                groupId: group.id,
                groupName: group.name,
                groupClientRole: group.clientRoles,
                notificationSettings: group.attributes.notificationSettingsByAdmin ? group.attributes.notificationSettingsByAdmin : [],
                // country: group.attributes && group.attributes.country ? group.attributes.country[0] : '',
                // state: group.attributes && group.attributes.region ? group.attributes.region[0] : '',
                type: (group.attributes && group.attributes.type) ? group.attributes.type[0] : '',
              });
            });
            setLoading(false);
            setRowData(groupsOfUser);
          }else{
            setLoading(false);
          }
         
        }).catch((error) => {
          console.log('error getting groups of user', error.message || error);
        });
    }
  };

  useEffect(() => {
   
    if (accountUser && accountUser.sub && userPermissions && userPermissions.groups && userPermissions.groups.read === false) {
      getGroupsOfUser();
    }
  }, [userPermissions, accountUser]);

  // to get Roles
  const getRoles = () => {
    API.get(`${APIURL}/roles`)
      .then((response) => {
        if (response.data && response.data.status === 'success'){
          const roleResponse = response.data.data.filter(
            (res) => res.name !== 'uma_protection' && !(res.name.startsWith(accountUser.companyId[0])),
          );
         
          setRoles(roleResponse);
        }
        else{
          setLoading(false);
        }
       
      })
      .catch((error) => console.log('error getting roles', error.message || error));
  };

  useEffect(() => {
  
    getRoles();
    getUserGroups();
 
    if (window.innerWidth <= 600) {
      setFullScreen(true);
    }
  }, []);

  // this function is called when we add new group
  const onAddDialogSave = (event) => {
    event.preventDefault();
    const rolesArr = [];
    if (roles) {
      // console.log('Added', roles, selectedRole);
      roles.forEach((role) => {
        if (selectedRole && role.name === selectedRole) {
          rolesArr.push({ id: role.id, name: role.name });
        }
      });
    }
    const axiosUserObject = {
      groupName: name,
      roles: rolesArr,
    };
    // console.log('Object to send',axiosUserObject)
    setLoading(true);
    AddGroupsSchema.validate({
      groupName: axiosUserObject.groupName,
      roles: axiosUserObject.roles,
    }).then(() => {
      API.post(`${APIURL}/groups`, axiosUserObject)
        .then((addingUserResponse) => {
         
          if (addingUserResponse.data.status === 'success') {
            setOpenAddDialog(false);
            setErrorMessage(null);
            setSelectedRowData([]);
            setSearchInput('');
            const newRoles = [];
            rolesArr.map((x) => {
              newRoles.push(x.name);
            });
            const newGroups = [...rowData, {
              groupId: addingUserResponse.data.data.id,
              groupName: axiosUserObject.groupName,
              groupClientRole: {
                [`${accountUser.companyId}`]: newRoles,
              },
              type: 'General',
            }];
            setRowData(newGroups);

            const userToastOptions = {
              hideAfter: 6,
              ...toastOptions,
            };
            setLoading(false);
            cogoToast.success(
              'Added group',
              userToastOptions,
            );
          } else {
            const errorMessage = addingUserResponse.data.message;
            cogoToast.error(errorMessage, toastOptions);
            console.log('error adding user group', errorMessage);
            setLoading(false)
          }
        })
        .catch((addingUserError) => {
          setErrorMessage('Failed to add user group');
          setLoading(false);
        });
    }).catch((addingUserError) => {
      console.error('Error creating user', addingUserError);
      const errorMessage = addingUserError.message
        ? addingUserError.message
        : 'Error creating group';
      setLoading(false);
      setErrorMessage(errorMessage);
    });
  };


  const changeName = (e) => {
    setName(e.target.value);
  };
  const changeSelection = (e) => {
    // console.log('Change role is ', e.target.value);
    setSelectedRole(e.target.value);
  };


  const onAddButtonClick = (event) => {
    setOpenAddDialog(true);
    setSelectedRole(' ');
    setEdit(false);
    setName('');
  };


  const onDeleteButtonClick = () => {
    setOpenDeleteDialog(true);
  };

  // this function runs when we click edit button
  const onEditButtonClick = () => {
if(selectedRowData && selectedRowData.length === 1){
  const editGroupRow = { ...selectedRowData[0] };  
  
  const { companyId } = accountUser;
  if (editGroupRow) {
    if (editGroupRow.groupClientRole && editGroupRow.groupClientRole && editGroupRow.groupClientRole[companyId]) {
      setSelectedRole(editGroupRow.groupClientRole[companyId][0]); // setting value for role
    } else setSelectedRole('');
    if (editGroupRow.groupName) setName(editGroupRow.groupName);
  }
  setEdit(true);
  setOpenAddDialog(true);

}else{
  cogoToast.info('Select a single row to edit or View info', {
    position: 'top-right',
  });
}
   

  };

  //this runs when we save edit details

  const onEditButtonSave = (event) => {
   
     event.preventDefault();
     const { groupId, groupClientRole } = { ...selectedRowData[0] };
     const rolesArr = [];
     const removeRoleArr = [];
     let removeRole;
  
     if (roles && roles.length > 0 ) {
       
       const userRole = roles.find((role) => role.name === selectedRole);
       
       rolesArr.push(userRole); // new role
       //if no role is assign to usergroup , 
       if(groupClientRole &&  groupClientRole[`${accountUser.companyId}`] && groupClientRole[`${accountUser.companyId}`][0]){
        removeRole = roles.find((role) => role.name === groupClientRole[`${accountUser.companyId}`][0]);
       
        removeRoleArr.push(removeRole); // role to remove previous role
       }
     
     }else{
      removeRoleArr.push('');
     }
    
     const generatePatchBody = (params) => {
      
       const body = [];
       const validParams = ['name', 'roles'];
       Object.keys(params).map((p) => {
         if (validParams.includes(p)) body.push({ op: 'replace', path: `/${p}`, value: params[p] });
         if(groupClientRole &&  groupClientRole[`${accountUser.companyId}`] && groupClientRole[`${accountUser.companyId}`][0]){
          if (p === 'roles' && params[p] && params[p][0] &&  params[p][0].name !== groupClientRole[`${accountUser.companyId}`][0] &&  params[p][0].name === selectedRole) {
            body.push({ op: 'remove', path: '/roles', value: removeRoleArr });
          }
         }
        
         return null;
       }).filter((f) => f);
       return body;
     };
     const groupPatchObj = generatePatchBody({
       name,
       roles: rolesArr,
     });
 
   
     const nameObj = groupPatchObj.find((groupObj) => groupObj.path === '/name');
     if (nameObj.value === '') cogoToast.error('Group Name cannot be empty', { position: 'top-right' });
     else {
       setLoading(true)
       API.patch(`${APIURL}/groups/${groupId}`,groupPatchObj) 
         .then((addingUserResponse) => {   
          
             if (addingUserResponse.data.status === 'success') {
             const newRoles = [];
             rolesArr.map((a) => {
               newRoles.push(a.name);
             });
             const rowIndex = rowData.findIndex((r) => r.groupId === groupId);
             if (rowData && rowIndex > -1) {
               const newRowData = [...rowData];
               newRowData[rowIndex] = {
                 groupId,
                 groupName: name,
                 groupClientRole: {
                   [`${accountUser.companyId}`]: newRoles,
                 },
                 type: 'General',
               };
               setRowData(newRowData);
               setOpenAddDialog(false);
               setErrorMessage(null);
               setSelectedRowData([]);
               setLoading(false);
               setSearchInput('');
 
               const userToastOptions = {
                 hideAfter: 6,
                 ...toastOptions,
               };
             
               cogoToast.success(
                 'Edited the selected group successfully',
                 userToastOptions,
               );
             } else {
              
               let errorMessage = addingUserResponse.data.message
                 ? addingUserResponse.data.message
                 : addingUserResponse.data.error;
               if (!errorMessage) errorMessage = 'Error creating user';
               setErrorMessage(errorMessage);
               setLoading(false);
             }
           } else {
           
             let errorMessage = addingUserResponse.data.message
             ? 'A Group already exists with this name'
             : addingUserResponse.data.error;
           if (!errorMessage) errorMessage = "Error creating user group";
           setErrorMessage(errorMessage);
           setLoading(false);
           } 
         })
         .catch((addingUserError) => {
          
           cogoToast.error('Group Name cannot be empty', { position: 'top-right' })
         console.error('Error creating user', addingUserError);
           setLoading(false);
           const errorMessage = addingUserError.message
             ? addingUserError.message
             : 'Error creating user';
           setErrorMessage(errorMessage);
         });
     }
   };

  const handleSelectionClear=()=>{
    setSelectedRowData([]);
  }

  const onUserDialogClose = () => {
    setOpenUserDialog(false);
    setUserEmail('');
    setAccessType(' ');
  
  };

  const changeemail = (e) => {
    setUserEmail(e.target.value);
  };

  const changeAccessType = (event) => {
    setAccessType(event.target.value);
  };

  const onSelectionChanged = (selectedRows) => {
   
    setSelectedRowData(selectedRows);
    // const { groupClientRole } = { ...selectedRows[0] };

    // if (groupClientRole && groupClientRole[`${accountUser.companyId}`] && groupClientRole[`${accountUser.companyId}`][0]) {
    //   setGroupRole(groupClientRole[`${accountUser.companyId}`][0]);
    // }

    const includesDefaultClient=selectedRows.filter((data)=>{
      if(data.groupClientRole[`${accountUser.companyId}`][0].includes('default_client')){   
        return true;
      }
      return false;
    })
    setGroupRole(includesDefaultClient);
    
    // setPermissions(permissions);
  };
  // runs on click of plus icon in
  //       accessType,
  //       groupId: groupId,
  //       id:groupId,
  //       userName:email,
  //     }]);
  //     setUserList(true);
  //   } else {
  //     if (email === '' && accesstype === '') {
  //       cogoToast.error('Email and AccessType cannot be empty ', { position: 'top-right' });
  //     }
  //     if (email !== '') {
  //       cogoToast.error('AccesssType cannot be empty', { position: 'top-right' });
  //     }
  //     if (accesstype !== '') {
  //       cogoToast.error('Email cannot be empty', { position: 'top-right' });
  //     }
  //   }

  //   setUserEmail('');
  //   setAccessType('');
  // };


  const addUser = (emailSent, aT) => {
    if (selectedRowData) {
      const { groupId } = { ...selectedRowData[0] };
      // setLoading(true);
      // console.log('selected row data for ref', { ...selectedRowData[0] });
      // console.log('group id but outside if', groupId);
      const lowerCaseEmail = emailSent.toLowerCase();
      setLoadingScreen(true)
      if (groupId) {
        API.patch(`${APIURL}/groups/users?email=${lowerCaseEmail}&groupId=${groupId}&accessType=${aT}`)
          .then((addedUser) => {
            if (addedUser.data.status === 'success') {
              const newUser = addedUser.data.data;
            
              // console.log('+++', addedUserList, newUser, usersInGroup);

              const userlistValues = [];
              userlistValues.push(...addedUserList, {
                id: newUser.id, email: emailSent, accessType: aT, groupId: newUser.groupId,
              });

              setAddedUserList(userlistValues);
              setUsersInGroup(userlistValues);
              setLoadingScreen(false)

              // setAddedUserList(usersInGroup);


              // setOpenUserDialog(false);
              // setSelectedRowData([]);

              // setLoading(false);
              setUserEmail('');
              setAccessType('');
              cogoToast.success('Added user to group', toastOptions);
            } else {
              setLoading(false);
              cogoToast.error(addedUser.data.message, toastOptions);
              setUserEmail('');
              setAccessType('');
              setLoadingScreen(false)
            }
          }).catch((error) => {
            console.log('error adding user', error.message || error);
            setLoading(false);
            setUserEmail('');
            setAccessType('');
            setLoadingScreen(false)
          });
      }
    }
  };


  // to remove the selected user from Manage user list
  const handleRemovalUser = (deleteUser) => {
    const { groupId } = usersInGroup.find((u) => u.id === deleteUser);

    // setLoading(true);
  
    if (selectedRowData && selectedRowData.length > 0 && usersInGroup) {
      API.delete(`${APIURL}/groups/users?id=${deleteUser}&groupId=${groupId}`)
        .then((deletedUser) => {
          if (deletedUser.data.status === 'success') {
            cogoToast.success('Deleted user from group', toastOptions);

            // setSelectedRowData([]);
            // setOpenUserDialog(false);
            setLoading(false);
            setUserEmail('');
            setAccessType('');
            
            const newUsers = usersInGroup.filter((user) => (user.id !== deleteUser ));
            
            
            
            setAddedUserList([...newUsers]);
            setUsersInGroup([...newUsers]);
            // console.log('successfully deleted user', deletedUser);
          } else {
            console.error('Error deleting user');
            setLoading(false);
          }
        }).catch((error) => {
          console.log('error deleting user', error.message || error);
          setLoading(false);
        });
    }
  };

  const getUsersInGroup = () => {
    if (selectedRowData && selectedRowData.length > 0) {
      // console.log('Select row', selectedRowData[0])
     

      const userData = [];
      const selectedUserGroup = { ...selectedRowData[0] };
      if (selectedUserGroup) {
        const { groupId } = selectedUserGroup;
        // console.log('group id', groupId);
        API.get(`${APIURL}/groups/${groupId}/members`)
          .then((response) => {
            if (response.data && response.data.status === 'success'){
              const userInGroupResponse = response.data.data;
              if (userInGroupResponse) {
                // console.log('users response', userInGroupResponse);
                userInGroupResponse.map((user) => {
                  const userObject = {
                    groupId: user.groupId,
                    id: user.id,
                    userName: user.username,
                    email: user.email,
                    accessType: user.accessType,
                  };
                  userData.push(userObject);
                });
                // console.log('user data', userData);
                setUsersInGroup(userData);
                setAddedUserList(userData);
              }
              else{
                setLoading(false)
              }
            }
            
          })
          .catch((error) => {
            console.log('error getting users', error.message || error);
          });
      }
    }
  };

  const onUserManageButtonClick = () => {
    setOpenUserDialog(true);
    if (selectedRowData && selectedRowData.length > 0) {
    
  const selectedRow={...selectedRowData[0]}
  setSelectedUserGroupName(selectedRowData && selectedRowData[0].groupName)
      getUsersInGroup();
    }
  };

  const changeRole = (event) => {
    setSelectedRole(event.target.value);
  };

  

  const arrayofClientGroup =selectedRowData.filter(t=>
   t.type === "clientGroup"
  )
  

  
  // const enableEditAndDeleteButtons = !!(selectedRowData && selectedRowData.length >=1 && selectedRowData[0].type !== 'clientGroup');
const enableEditAndDeleteButtons = !!(selectedRowData && selectedRowData.length >=1 && ( arrayofClientGroup.length >= 1 ? false : true ));

  const enableAddOrDeleteUser = !!(
    selectedRowData && 
    selectedRowData.length === 1 && 
    selectedRowData[0].accessType === 'Admin'
    );
    
  
  const enableManageUsers = ((selectedRowData && selectedRowData.length > 1) || (selectedRowData && selectedRowData.length === 0) || !((userPermissions && userPermissions.groups && userPermissions.groups.read) || enableAddOrDeleteUser));
  // const enableManageUsers= (selectedRowData && selectedRowData.length > 1) ||  (selectedRowData && selectedRowData.length === 0) 
 
 
  return (

    <div className={classes.externalDiv}>
      {loading ? (
        <div className={classes.overlay}>
          <CircularProgress
            style={{ position: 'absolute', top: '50%', left: '50%' }}
          />
        </div>
      ) : (
        <>
          <Grid
            container
            justifyContent="center"
            className={classes.mainControlDiv}
          >
            <Grid item md={12} sm={12} xs={12} className={classes.controlDiv}>
              <PrimaryButton
                // variant="contained"
                // className={classes.addButton}
                // style={{ marginTop: "-3.5rem" }}
                onClick={onAddButtonClick}
                text="Add Group"
                disabled={userPermissions && userPermissions.groups && !(userPermissions.groups.create)}
              />
               
              
            </Grid>
          </Grid>

          <Grow in >
            <Grid  container justifyContent="center">
              <Grid item  xl={7} lg={7} md={5} sm={5} xs={7} style={{textAlign:'left', display:'flex'}} >
              <div style={{ display: 'flex' }}>
                    <Tooltip title="Delete" placement="top-end"  >
                      <Button
                        disabled={!(userPermissions && userPermissions.groups && userPermissions.groups.delete && enableEditAndDeleteButtons)}
                        className={userPermissions && userPermissions.groups && userPermissions.groups.delete
                          && enableEditAndDeleteButtons
                          ? classes.deleteDiv
                          : classes.disabledDeleteDiv}
                        onClick={
                        enableEditAndDeleteButtons
                          ? onDeleteButtonClick
                          : null
                      }
                      color="error"
                      >
                        <DeleteIcon />
                        {/* <span className={classes.buttonText}>Delete</span> */}
                      </Button>
                      </Tooltip>
                     <Tooltip title="Edit" placement="top-end" >
                      <Button
                        disabled={
                        !(userPermissions && userPermissions.groups && userPermissions.groups.update && enableEditAndDeleteButtons)
                      }
                       
                        onClick={
                        enableEditAndDeleteButtons
                          ? onEditButtonClick
                          : null
                      }
                      >
                        <EditIcon 
                         className={userPermissions && userPermissions.groups && userPermissions.groups.update
                          && enableEditAndDeleteButtons
                          ? classes.editDiv
                          : classes.disabledEditDiv}
                           />
                        {/* <span className={classes.buttonText}>Edit</span> */}
                      </Button>
                      </Tooltip>
                    
                      {/* {
                      ((userPermissions && userPermissions.groups && userPermissions.groups.read) || enableAddOrDeleteUser) && (
                      <Button 
                          
                        className={userPermissions && userPermissions.groups && userPermissions.groups.read
                        && enableAddOrDeleteUser 
                          ? classes.editmanageuserDiv
                          : classes.disabledmanageuserDiv}
                        disabled={enableManageUsers}
                        onClick={onUserManageButtonClick}
                       
                        sx={{ color: '#008081', textTransform: "capitalize",  }}
                        
                      >
                        <PeopleIcon style={{marginRight:'0.1rem' }} />
                        {' '}
                        
                        Manage Users
                      </Button>
                      )
                    } */}
                    <Button 
                          
                          className={userPermissions && userPermissions.groups && userPermissions.groups.read
                          && enableAddOrDeleteUser 
                            ? classes.editmanageuserDiv
                            : classes.disabledmanageuserDiv}
                          disabled={enableManageUsers}
                          onClick={onUserManageButtonClick}
                         
                          sx={{ color: '#008081', textTransform: "capitalize",  }}
                          
                        >
                          <PeopleIcon style={{marginRight:'0.1rem' }} />
                          {' '}
                          
                          { breakPoint ? 'Manage Users' :''}
                        </Button>
                       
                    {/* <div className={groupRole && groupRole.includes('default_client') && selectedRowData && selectedRowData.length > 0 ? classes.infoEnabled : classes.infoDisabled}> */}
                      <div className={groupRole && groupRole.length >= 1 && selectedRowData && selectedRowData.length > 0 ? classes.infoEnabled : classes.infoDisabled}>
                      <Tooltip
                        placement="top"
                        title="Default Client role is neither editable nor deletable"
                        arrow
                        disableInteractive
                      >
                        <InfoIcon className={classes.info} fontSize="medium" />
                      </Tooltip>
                    </div>

                    </div>
              </Grid>
              <Grid item  xl={3} lg={2} md={3} sm={3} xs={5}>
              <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                             
                              alignItems: 'center',
                              flexGrow: 1,
                            }}
                          >
                            <Tooltip title="use 'shift' or 'ctrl' for multi-select">
                              {/* <IconButton> */}
                              <div>
                                <span>Selected : </span>
                                <span className={classes.Selected}>
                                  {selectedRowData.length}
                                </span>
                              </div>
                              {/* </IconButton> */}
                            </Tooltip>
                            <Tooltip title="Clear Selection">
                              <IconButton
                                onClick={handleSelectionClear}
                          
                               
                              >
                                <HighlightOffIcon 
                                 className={
                                  selectedRowData && selectedRowData.length > 0
                                    ? classes.clearSelectionActive
                                    : classes.clearSelectionDisabled
                                }/>
                              </IconButton>
                            </Tooltip>
                          </div>
                </Grid>
                <Grid item  xl={2} lg={3} md={4} sm={4} xs={12} >
                <SearchOption
                          variant="standard"
                            id="userSearchInput"
                            value={searchInput}
                            onChange={onSearchChange}
                            placeholder="Search by user group name"
                          />
                </Grid>
            </Grid>
          </Grow>
          <UserTable
                    rowData={rowData || []}
                    onSelectionChanged={onSelectionChanged}
                     selectedRowData={selectedRowData}
                     searchvalue={searchInput}

                  />

<Typography variant="caption" align="left" color="textSecondary"> Note: Client Groups are deleted when clients are deleted.</Typography>
        

          <Dialog
            open={openDeleteDialog}
            onClose={onDeleteDialogClose}
            aria-labelledby="form-dialog-title"
            maxWidth="sm"
          >
            <DialogTitle id="form-dialog-title">
              <span className={classes.warning}>Warning</span>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete the selected group ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
            <SecondaryButton
                text="No"
                onClick={onDeleteDialogClose}
                color="primary"
              />
              <PrimaryButton text="Yes" onClick={onDeleteDialogSave} />
            </DialogActions>
          </Dialog>


          <Dialog
            open={openAddDialog}
            edit={edit || undefined}
            onClose={onAddDialogClose}
            aria-labelledby="form-dialog-title"
            maxWidth="xs"
            fullScreen={fullScreen}
            fullWidth
            PaperProps={{
              style: {
                backgroundColor: '#f2f2f2',
              },
            }}
          >
            <FormControl onSubmit={onAddDialogSave}>
            {fullScreen && (
                          <AppBar className={classes.appBar}>
                          <Toolbar style={{backgroundColor:'#008081'}}>
                          <IconButton
                          edge="start"
                          color="inherit"
                          onClick={onAddDialogClose}
                          aria-label="close"
                        >
                          <CloseIcon />
                        </IconButton>
                          <Typography variant="h6" className={classes.title}>
                          {edit ? 'Edit' : 'Add New' }
                {' '}
                Group
                        </Typography>
                          <Button color="inherit" type="submit" 
                            onClick={edit ? onEditButtonSave : onAddDialogSave}
                            >
                          {edit ? "Update" : "Save"}
                        </Button>
                        </Toolbar>
                        </AppBar>
                          )}

             {
              !fullScreen && (
                <DialogTitle id="form-gialog-title" className={classes.dialogtitle}>
                {edit ? 'Edit' : 'Add New' }
                {' '}
                Group
              </DialogTitle>
              )
             }



              <DialogContent>
                <Grid className={classes.modalDialogOuterDiv}>

                  <Grid container style={{marginTop: fullScreen ? "3rem" : '0rem' }}  xs={12} spacing={2}>
                    <Grid item xs={12} style={{width:'100%'}}>
                      <Input
                        id="standard-basic"
                        placeholder="Group name"
                        value={name}
                        onChange={changeName}
                        backgroundColor="#fff"
                      />
                    </Grid>
                    <Grid item xs={12}>


              
                    {/* <AutoField
                     
                     id="controllable-states-demo"
          options={roles || []}
          getOptionLabel={(option) => (option.name ? option.name : '')}
        //   inputValue={selectedRole}
        // onInputChange={(event, newInputValue) => {
        // console.log('Break', newInputValue)
        // if (newInputValue === "") return;
        //   setSelectedRole(newInputValue ? newInputValue : ' ' );
        // }}
          value={selectedRole ? selectedRole : ' ' }
          onChange={(event, newInputValue) => {
            console.log('On change is called' , event.target.value )
            if (event.target.value === "") return;
            setSelectedRole(newInputValue ? newInputValue : ' ');
          }}
          renderInput={(params) => {``
            const { inputProps } = params;
            // inputProps.autoComplete = "off";
            return (
              <TextField 
              {...params}
                  placeholder="Roles"
                variant="standard"

                  InputProps={{ ...params.InputProps, disableUnderline: true, }}
              />
            );
          }}
        /> */}


<FormControl className={classes.formControl}   >
                      <SelectField
                      variant="standard"
                        id="demo-mutiple-checkbox"
                        value={selectedRole}
                        onChange={changeSelection}
                        backgroundColor="#fff"
                      >
                        
                         <MenuItem value=' '  disabled >
                         <span style={{color:"#9E9E9E", marginLeft:'8px', fontSize:'14px', fontFamily:'Montserrat'}}>Roles</span>
          </MenuItem>
                        {roles
                      && roles.map(({ name }) => (
                        <MenuItem key={name} value={name} onChange={changeRole} 
                        
                        >
                          {name}
                        </MenuItem>
                      ))}
                      </SelectField>
                    </FormControl> 

                    
                     
                     
                    </Grid>

                     


                  </Grid>
                </Grid>
              </DialogContent>
              <div style={{ marginTop: "10px", paddingTop: "10px" , marginLeft:'10px'}}>
                {errorMessage ? (
                  <div className={classes.errorMessageDiv}>
                    <span className={classes.errorMessageText}>
                      *{errorMessage}
                    </span>
                  </div>
                ) : null}
              </div>
              {
                !fullScreen && (
                  <DialogActions>
                  <SecondaryButton
                      text="Cancel"
                      onClick={onAddDialogClose}
                      color="secondary"
                    />
                   <PrimaryButton
                      onClick={edit ? onEditButtonSave : onAddDialogSave}
                      type="submit"
                      text={edit ? "Update" : "Save"}
                    />
                  </DialogActions>
                )
              }
             
            </FormControl>
          </Dialog>


          <Dialog
            open={openUserDialog}
            onClose={onUserDialogClose}
            aria-labelledby="form-dialog-title"
            maxWidth="sm"
            fullScreen={fullScreen}
            fullWidth
            PaperProps={{
              style: {
                backgroundColor: '#f2f2f2',
              },
            }}
          >


            { fullScreen && (
              <AppBar className={classes.appBar}>
              <Toolbar style={{backgroundColor:'#008081'}}>
              <IconButton
              edge="start"
              color="inherit"
              onClick={onUserDialogClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
              <Typography variant="h6" className={classes.title}>
              Manage Users of {selectedUserGroup}
            </Typography>
            
            </Toolbar>
            </AppBar>
            )}

           { !fullScreen && (
            <DialogTitle className={classes.dialogtitle}>Manage Users of {selectedUserGroup} </DialogTitle>
           )} 


           
            <DialogContent>
              {((userPermissions && userPermissions.groups && userPermissions.groups.update) || enableAddOrDeleteUser)
          && (
            <>
             
             <Grid container  style={{marginTop: fullScreen ? "3rem" : '0rem',  padding:'1rem'}} xs={12}>
            <Grid item xs={3}>
              <span className={classes.dialogtitle} >Add User </span>
            </Grid>
            <Grid item xs={9}>
              <Divider style={{marginTop:'0.6rem'}}/>
              </Grid>
             </Grid>
             <Grid container xs={12} spacing={2}>
               <Grid item xs={12}>
               <Input value={userEmail}  
               onChange={changeemail} 
               backgroundColor="#fff" 
               className={classes.addUserForm} 
               placeholder="Email" />
               </Grid>
              
               <Grid item xs={12}>
               <FormControl className={classes.addUserForm}>
                    {/* <InputLabel id="access-type">Access Type</InputLabel> */}
                    <SelectField
                     backgroundColor="#fff"
                    variant="standard"
                      id="demo-mutiple-checkbox"
                      value={accessType}
                      onChange={changeAccessType}
                    >
 <MenuItem value=' ' disabled>
                         <span style={{color:"#9E9E9E", marginLeft:'8px', fontSize:'14px', fontFamily:'Montserrat'}}>Access type</span>
          </MenuItem>
                      <MenuItem value="Admin">Admin</MenuItem>
                      <MenuItem value="User">User</MenuItem>


                    </SelectField>
                  </FormControl>

                 </Grid>
                 {/* <Grid item xs={4}  style={{ textAlign:'right'}}>
                 <PrimaryButton
                  
                  onClick={() => {
                    addUser(userEmail, accessType);
                  }}
                  disabled={!((userPermissions && userPermissions.groups && userPermissions.groups.update) || enableAddOrDeleteUser)}
                  type="submit"
                 
                  text="Add"
                />
                 </Grid> */}
          
              

                 <Grid container xs={12} >
                   <Grid item xs={7}> </Grid>
                   <Grid item xs={5}   style={{paddingTop:'2rem', textAlign:'right'}} > 
                   <PrimaryButton
                  
                  onClick={() => {
                    addUser(userEmail, accessType);
                  }}
                  disabled={!((userPermissions && userPermissions.groups && userPermissions.groups.update) || enableAddOrDeleteUser)}
                  type="submit"
                 
                  text="Add"
                />
                 
               
                   </Grid>
                
                 </Grid>
                
             </Grid>
             </>

             )}
             <Grid container style={{padding:'1rem', paddingTop:'3.5rem' }} xs={12}>
            <Grid item xs={4}>
              <span className={classes.dialogtitle} > List of User(s)</span>
            </Grid>
            <Grid item xs={8}>
              <Divider style={{marginTop:'0.6rem'}}/>
              </Grid>
             </Grid>
{loadingScreen ? 
 <div className={classes.overlay}>
 <CircularProgress
   style={{ position: 'absolute', top: '50%', left: '50%' }}
 />
</div>
 :(
 <Grid >
   {

<List
sx={{
  width: '100%',
  maxWidth: '100%',
   position: 'relative',
  overflow: 'auto',
  maxHeight: 300,
 
}}
>


  { addedUserList && addedUserList.length > 0

    ? addedUserList.map((user) => (
      (
        <div key={user.id} style={{ marginLeft: '1em',  }}>

          <ListItem
  
            secondaryAction={
             <>
              <Tooltip title="Delete"  >
          <IconButton
                            style={{ color:
                              !(
                                (userPermissions &&
                                  userPermissions.groups &&
                                  userPermissions.groups.update) ||
                                enableAddOrDeleteUser
                              )?
                              'rgba(0,0,0,0.26)':'red'
                            }}
                            edge="end"
                            aria-label="delete"
                            disabled={
                              !(
                                (userPermissions &&
                                  userPermissions.groups &&
                                  userPermissions.groups.update) ||
                                enableAddOrDeleteUser
                              )
                            }
                          
                            onClick={() => handleRemovalUser(

                              user.id,
                            )}
                          >
                            <DeleteIcon  style={{paddingTop:'0.8rem'}} />
                          </IconButton>
          
            </Tooltip></>
            }
            disablePadding
          >
            <ListItemButton>
            <ListItemAvatar>

<PersonIcon />

</ListItemAvatar>
<ListItemText primary={user.email} secondary={user.accessType} />
            </ListItemButton>
          </ListItem>
        </div>

      )
    ))
    : (

      <div style={{ textAlign: 'center',fontFamily:'Montserrat' }}> No Users </div>
    )}


</List>

}
 </Grid>)
}
          

           
         


              <DialogActions>
                <SecondaryButton onClick={onUserDialogClose}  text="Close"/>
                    
              
                {/* <PrimaryButton
                  onClick={onUserDialogClose} 
                  disabled={!((userPermissions && userPermissions.groups && userPermissions.groups.update) || enableAddOrDeleteUser)}
                  type="submit"
               
                  variant="contained"
                  text="Save and Close"
                /> */}
                  
               
              </DialogActions>

              <h4 style={{ color: '#777' }}>
                  Note:An email containing temporary password would be send to user once you add the user
                  . The user would also recieve an email with a link to reset password. The email would be valid for 24 hours.
              </h4>

            </DialogContent>
          </Dialog>

        </>
      )}


    </div>
  );
};


export default (withStyles(styles)(UserGroups));

