import React, { useState, useEffect, useContext } from 'react';
import { withStyles } from '@mui/styles';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import './/style.css';
// import * as XLSX from "xlsx";
import PropTypes from 'prop-types';
import UserContext from 'Context/UserContext';
import './cellStyle.css';
import StripsofViolation from './StripsofViolation';
import StripsofHumidity from './StripsofHumidity';
import StripsofBattery from './StripsofBattery';
import API from 'API/apiConfig';
import Button from '@mui/material/Button';

// import axios from 'axios';
// import cogoToast from 'cogo-toast';


const moment = require('moment');
const APIURL = process.env.REACT_APP_APIURL;

const styles = {
  headerClass: {
    backgroundColor: '#008081',
    color: 'white',
    fontFamily: 'Montserrat', fontSize: '14px',
    borderRight: '1px solid white!important',
    '&:hover': {
      backgroundColor: '#008081!important',
    },
  },

};

function AssetGroupIdformatter(params) {

  if (params.data && params.data.assetGroupId) {
    return params.data.assetGroupId;
  } return ' - ';
}

function AssetIdformatter(params) {
  if (params.data && params.data.assetId) {
    return params.data.assetId;
  } return ' - ';
}



function lastUpdateFormatter(params) {
  if (params.data && params.data.lastPing && params.data.lastPing.timeStamp) {
    return moment(params.data.lastPing.timeStamp * 1000).format('DD-MMM-YY,  hh:mm A ')
  } return ' - ';
}

function noteFormatter(params) {
  if (params.value && params.value !== 'null') {
    return params.value;
  } return ' - ';
}

// function batteryFormatter(params) {
//   if (params.data.lastPing && params.data.lastPing.battery) {
//     return `${Math.round(params.data.lastPing.battery)}%`;
//   } return ' - ';
// }

function temperatureFormatter(params) {
  if (params.data.lastPing && params.data.lastPing.temperature && params.data.lastPing.temperature.length >= 1) {
    return `${(params.data.lastPing.temperature[params.data.lastPing.temperature.length - 1])} °C`;
  } return ' - ';


}

function humidityFormatter(params) {
  if (params.data.lastPing && params.data.lastPing.humidity && params.data.lastPing.humidity.length >= 1) {
    return `${(params.data.lastPing.humidity[params.data.lastPing.humidity.length - 1])} % `;
  } return ' - ';
}

//  function humidityFormatter(params){
//   if (params.data.lastPing && params.data.lastPing.humidity && params.data.lastPing.humidity.length >= 1){
//     return `${Math.round(params.data.lastPing.humidity[params.data.lastPing.humidity.length - 1] )} %`;
//   }return ' - ';
//  }

function typeFormatter(params) {
  if (params.data && params.data.type) {
    return params.data.type;
  } return ' - ';
}


function statusValueFormatter(params) {
  if (params.value && params.value === true) {
    return 'Paired';
  } return 'Unpaired';
}

function violationTemperatureRenderer(params) {
  return (
    <StripsofViolation params={params} />
  )
}

function violationHumidityRenderer(params) {
  return (
    <StripsofHumidity params={params} />
  )
}

function violationBatteryRenderer(params) {
  return (
    <StripsofBattery params={params} />
  )
}

function DevicesGrid({
  classes, rowData, onSelectionChanged, searchvalue, selectedRowData, downloadReport,
  //  vFilters, filters
}) {

  const companyDetails = useContext(UserContext);
  const { CompanyInfo, } = companyDetails;

  const calibrationRenderer = () =>
    <Button
      //  disabled={(params && params.data && !params.data.calibrationExpiry)} 
      style={{
        textTransform: "capitalize",
        fontSize: '16px',
        color: '#008081'
        // color:params && params.data && !params.data.calibrationExpiry ? '#33333396' :' #008081'
      }}

    >Download</Button>;


  // const calibrationValidityGetter = (params) => {
  //   const { data } = params;
  //   // console.log('data', data);
  //   let timeTillDate;
  //   if (data && data.calibrationExpiry) {
  //     timeTillDate = moment(data.calibrationExpiry * 1000).diff(moment(), "days");
  //     return `Valid Till ${timeTillDate} days `;
  //   }
  //   return `N/A `;
  // };



  const getCalibrationCertificate = (params) => {

    const { data } = params;
    const selectedUids = [];
    selectedUids.push(data.id);
    // console.log('deviceId', selectedUids, );
    API.get(`${APIURL}/v1.0/devicescalibrationreports`, {
      params: { devices: selectedUids },
    })
      .then((response) => {

        const { data } = response.data;

        if (response && response.data && response.data.data) {

          window.open(data, "_blank");

        }

      })

      .catch((error) => console.log("error getting reports", error.message || error));

  };



  const columnDefs = [{
    headerName: 'Device ID',
    field: 'id',
    sortable: true,
    filter: true,
    minWidth: 180,
    pinned: 'left',
    tooltipField: 'id',
    headerTooltip: 'Device ID',
    checkboxSelection: true,
  },
  {
    headerName: CompanyInfo && CompanyInfo.settings.warehouseType ? (CompanyInfo.settings.warehouseType + " ID") : "Warehouse ID",
    field: 'assetGroupId',
    valueFormatter: AssetGroupIdformatter,
    // valueGetter: Idformatter,
    minWidth: 160,
    // headerTooltip: CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.warehouseType && CompanyInfo.settings.warehouseType.length > 15 ? CompanyInfo.settings.warehouseType + " ID" : '',
    tooltipField: 'assetGroupId',
    headerTooltip: CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.warehouseType ? CompanyInfo.settings.warehouseType + " Id" : "Warehouse Id "
  },
  {
    headerName: CompanyInfo && CompanyInfo.settings.chamberType ? (CompanyInfo.settings.chamberType + " ID") : "Chamber ID",
    field: 'assetId',
    valueFormatter: AssetIdformatter,
    // valueGetter: params => {
    //   return params.assetId && params.assetId;
    // },
    minWidth: 160,
    // headerTooltip: CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.chamberType && CompanyInfo.settings.chamberType.length > 15 ? CompanyInfo.settings.chamberType + " ID" : '',
    tooltipField: 'assetId',
    headerTooltip: CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.chamberType ? CompanyInfo.settings.chamberType + " Id" : "Chamber Id",

  },
  {
    headerName: 'Status',
    field: 'paired',
    sortable: true,
    minWidth: 130,
    valueFormatter: statusValueFormatter,

  },
  {
    headerName: 'Last Updated',
    field: 'lastPing.timeStamp',
    sortable: true,
    headerTooltip: 'Last Updated',
    valueFormatter: lastUpdateFormatter,
    minWidth: 220,
    // tooltipField: `lastPing.timeStamp`,
  }
    , {
    headerName: 'Battery',
    field: 'lastPing.battery',
    // valueGetter:batteryFormatter,
    // valueFormatter: batteryFormatter,
    sortable: true,
    minWidth: 120,
    cellRenderer: violationBatteryRenderer,

  },
  {
    headerName: 'Latest Temperature',
    field: 'lastPing.temperature',
    valueGetter: temperatureFormatter,
    valueFormatter: temperatureFormatter,
    sortable: true,
    minWidth: 180,
    headerTooltip: 'Latest Temperature',
    cellRenderer: violationTemperatureRenderer,


  },
  {
    headerName: 'Latest Humidity',
    field: 'lastPing.humidity',
    valueGetter: humidityFormatter,
    valueFormatter: humidityFormatter,
    headerTooltip: 'Latest Humidity',
    sortable: true,
    minWidth: 150,
    cellRenderer: violationHumidityRenderer,


  },
  {
    headerName: "Calibration Certificate",
    minWidth: 165,
    sortable: true,
    cellStyle: {
      // "text-decoration": "underline",
      cursor: "pointer",
      textAlign: "center",
    },
    onCellClicked: getCalibrationCertificate,
    cellRenderer: calibrationRenderer,

  },
  // {
  //   headerName: "Validity",
  //   minWidth: 165,
  //   sortable: true,
  //   valueGetter: calibrationValidityGetter,
  //   comparator: (valueA, valueB) => {
  //     const valueA1 = valueA.split(" ")[2];
  //     const valueB1 = valueB.split(" ")[2];
  //     return valueA1 - valueB1;
  //   },

  // },
  {
    headerName: 'IMEI',
    field: 'IMEI',
    sortable: true,
    filter: true,
    minWidth: 150,
    tooltipField: 'IMEI',

  },
  {
    headerName: 'Device Type',
    field: 'type',
    sortable: true,
    filter: true,
    minWidth: 170,
    tooltipField: 'type',
    valueGetter: typeFormatter,
    valueFormatter: typeFormatter,
  },

  {
    headerName: 'Note',
    field: 'note',
    valueFormatter: noteFormatter,
    minWidth: 140,
    tooltipField: 'note',
  },
  ];


  const [gridApi, setGridApi] = useState(null);


  const onGridReady = (params) => {

    // const gridApi = params.api;
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };

  const handleSelectionChanged = () => {
    if (gridApi) {
      onSelectionChanged(gridApi.getSelectedRows());
    }
  };

  // `${handleDownloadReportName()}.xlsx`
  // const handleDownloadReportName=()=>{
  //   // const {vFilters, filters}= this.props;
  //   if(vFilters && vFilters.length === 0 || filters && filters.length === 0){
  //    return `Devices`
  //   }
  //   else{
  //     //check which filters are applied and name it accordingly
  //     console.log('else ',vFilters && vFilters.length, filters && filters.length )
  //     if((vFilters && vFilters.length >= 1) && (filters && filters.length )){
  //       return `Device report with ${vFilters} filter`
  //     }
  //     if((vFilters && vFilters.length >= 1) && (filters && filters.length > 1)){
  //       return `Device report with ${vFilters} and ${filters} filter`
  //     }

  //   }
  // }

  const handlereportdownload = () => {
    if (gridApi) {
      const params = {
        fileName: "Device Report",
        columnKeys: ["id", 'assetGroupId', "assetId", 'paired', 'lastPing.timeStamp', 'lastPing.battery', 'lastPing.temperature', 'lastPing.humidity', 'IMEI', 'type', 'note'],
        processCellCallback: (data) => {

          if (data.column && data.column.colDef) {
            if (
              data.column.colDef.field
              && (data.column.colDef.field === "lastPing.timeStamp")
            ) {
              return moment(data.value && data.value * 1000).format(
                "DD-MM-YYYY, hh:mm A",
              );
            }

            if (data.column.colDef.field
              && (data.column.colDef.field === "paired")) {

              if (data.value && data.value === true) {

                return "Paired";
              }
              return "Unpaired"

            }

          }

          return data.value;
        },
      };
      gridApi.exportDataAsCsv(params);
      //   const data = gridApi && gridApi.getDataAsCsv();

      // const wbTemp = XLSX.read(data, { type: "string"});
      // const wb = XLSX.utils.book_new();
      // const devices = XLSX.utils.sheet_to_json(wbTemp.Sheets.Sheet1);
      // console.log('Devices', devices,)

      // const ws = XLSX.utils.json_to_sheet(
      //   devices.map((device) => {

      //     device.Status = device.Status === "true" ? "Paired" : "Unpaired";
      //     device["Last Updated"] = device["Last Updated"]
      //       ? moment(device["Last Updated"]).format("DD-MMM-YY,  HH:mm ")
      //       : "";

      //     return device;
      //   }),
      // );
      // XLSX.utils.book_append_sheet(wb, ws, "devices");
      // XLSX.writeFile(wb,`Devices-${moment().format()}.xlsx` , {
      //   sheet: "devices",
      // });
    }

  }


  const onSearch = () => {
    if (gridApi) {
      gridApi.setQuickFilter(searchvalue);
    }
  };

  const handleSelectionCleared = () => {
    if (selectedRowData.length === 0 && gridApi) {
      gridApi.deselectAll();
    }
  };



  useEffect(() => {
    if (selectedRowData) {
      if (!selectedRowData.length) {
        handleSelectionCleared();
      }
    }

  }, [selectedRowData && selectedRowData.length]); // selectedRowData


  useEffect(() => {
    onSearch();
  }, [searchvalue]);

  useEffect(() => {
    // if(downloadReport){
    handlereportdownload();
    // }

  }, [downloadReport])






  const defaultColDef = {
    sortable: false,
    filter: false,
    resizable: true,
    cellStyle: { textAlign: 'left', fontFamily: 'Montserrat', fontSize: '14px' },
    headerClass: classes.headerClass,
    suppressMovable: true,

  };
  const overlayLoadingTemplate = '<span class="ag-overlay-loading-center"><img src="./../img/horizontalLoader.svg" /></span>';
  const overlayNoRowsTemplate = '<img src="./../img/emptybox.png" />';
  const rowSelection = 'multiple';
  return (
    <div
      className="ag-theme-material"
    >

      <AgGridReact
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        rowData={rowData}
        rowSelection={rowSelection}
        // onSelectionChanged={onSelectionChanged}
        rowMultiSelectWithClick="true"
        // onGridReady={onGridReady}
        onSelectionChanged={handleSelectionChanged}
        onGridReady={onGridReady}

        pagination
        paginationPageSize={10}
        domLayout="autoHeight"
        enableCellTextSelection
        overlayLoadingTemplate={overlayLoadingTemplate}
        overlayNoRowsTemplate={overlayNoRowsTemplate}
        minHeight="500px"


      />
    </div>
  );
}

DevicesGrid.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  rowData: PropTypes.instanceOf(Object),

};

DevicesGrid.defaultProps = {
  rowData: null,
  onSelectionChanged: [],
};

export default withStyles(styles)(DevicesGrid);
