import React , {useEffect, useState,useRef} from "react";
import { withStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import mapboxgl from "!mapbox-gl";
import MapboxglSpiderifier from "mapboxgl-spiderifier";
import { getAddressfromMap } from "./googleMapapi";
// import { Tooltip } from "@mui/material";

// import RouteRoundedIcon from "@mui/icons-material/RouteRounded";
const styles = {

};



mapboxgl.accessToken = "pk.eyJ1Ijoic3dhcm9vcC1hZGFwdCIsImEiOiJja3B6Ynd5cjIwdGV0Mndud3R6bHo0ODBqIn0.NWEQBqH0Qew7crwzdvhpGA";
const maxZoom = 18;



const MapBoxComp = (props) => {
  const {
    // classes,
    paths,
    markers,
    linesLayer,
markersLayer,
// initialCenter,
markerClustering,
initialZoom,
getMarkerInfoWindowHTML,
closeSpiderifyonCard,
myCluster,
   } = props;
  //  console.log('MapBox comp', paths, markers)
  // const [lng, setLng] = useState(initialCenter ? initialCenter[0] : 78);
  // const [lat, setLat] = useState(initialCenter ? initialCenter[1] : 17);
  // const [zoom, setZoom] = useState(initialZoom || 1.2);


  const [layersofSpiderify, setLayersofSpiderify] = useState();
  // const [devices, setDevices] = useState({});
  // const [showpopup, setShowpopup] = useState([]);
  // const [spiderifyPopup, setspideriftPopup] = useState([]);
  // const [stateofPopup, setStateofPopup] = useState({});
  // const [closeSpideronFilter, setcloseSpideronFilter] = useState();

  const [isMapLoaded, setIsMapLoaded] = useState(false);
   let showpopup=[]
   let spiderifyPopup=[];
   let stateofPopup={}
   let devices={}
   let spiderfeatures=[]
   const zoom=initialZoom || 1.2
  // const [boundsOfMap, setBoundsOfMap] = useState([]);

  const lng= 78;
  const lat=17
  // const zoom=1.2;

const mapContainer = useRef(null);
const map = useRef(null);
const pathsRef = useRef(paths);
const markersRef = useRef(markers);
const myClusterRef = useRef(myCluster);
const markerClusteringRef = useRef(markerClustering);

//function to center the map
const panToProps = () => {
  if (
    pathsRef.current
    && pathsRef.current.length
    && pathsRef.current[0].length
  ) {
    return map.current.easeTo({
      center: pathsRef.current[0][0],
    });
  }
  if (
    markersRef.current
    && markersRef.current.length
    && markersRef.current[0].length
  ) {
    return map.current.easeTo({
      center: markersRef.current[0],
    });
  }
  return 1;
};

// const fitBounds = () => {
//   map.current.fitBounds(boundsOfMap, {
//     padding: {
//       left: 50,
//       right: 50,
//       top: 50,
//       bottom: 50,
//     },
//     animate: false,
//   });
// };


const refreshMakers = () => {

  if (!markersRef.current || !map.current ) {
    return;
  }

  map.current.loadImage("../img/map/cluster2.png", (error, image) => {

    if (error) throw error;
    if (!map.current.hasImage("my-marker")) {
      map.current.addImage("my-marker", image);
    }

    const newData = {
      type: "FeatureCollection",
      features: markersRef.current.map((marker, index) => ({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [marker[0], marker[1]],
        },
        properties: {
          title: `Marker-${index}`,
          ...(marker[2] || {}),
        },
      })),
    };

    if (!map.current.getSource("points")) {
      map.current.addSource("points", {
        type: "geojson",
        data: newData,
        cluster: markerClusteringRef.current !== false,
        clusterMaxZoom: maxZoom,

      });
    }

    const geojsonSource = map.current.getSource("points");
    geojsonSource.workerOptions.cluster = myClusterRef.current;
    geojsonSource.setData({
      type: "FeatureCollection",
      features: markersRef.current.map((m, i) => ({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [m[0], m[1]],
        },
        properties: {
          title: `Marker-${i}`,
          ...(m[2] || {}),
        },
      })),
    });


    if (!map.current.getLayer("points")) {
      map.current.addLayer(
        markersLayer || {
          id: "points",
          type: "symbol",
          source: "points",
          layout: {
            "icon-image": ["coalesce", ["get", "icon"], "my-marker"],
            "icon-allow-overlap": true,
            "icon-ignore-placement": true,
            "text-allow-overlap": true,
            "text-ignore-placement": true,
            "text-field": "{point_count_abbreviated}",
            "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
            "text-size": 12,
            "icon-size": 1,
          },
          paint: {
            "text-color": "#000",
          },
        },
      );
    }
  });
};

const refreshPaths = () => {
  if (map.current) {
    // console.log('Route  1 ',!pathsRef.current ,!map.current.isStyleLoaded()  )
    if (!pathsRef.current || !map.current.isStyleLoaded()) {
      return;
    }

    const newData = {
      type: "Feature",
      properties: {},
      geometry: {
        type: "LineString",
        coordinates:  pathsRef.current[0] || []
      },
    };
  //  console.log('Route ',!map.current.getSource("route"),map.current.isStyleLoaded()  )
    if (!map.current.getSource("route") && map.current.isStyleLoaded() ) {

      if (map.current.isStyleLoaded()) {
        map.current.addSource("route", {
          type: "geojson",
          data: newData,
          lineMetrics: true,
        });
      }
    } else {
      map.current.getSource("route").setData(newData);
    }
      // console.log('pathsRef.current && pathsRef.current[0] && pathsRef.current[0][0]',pathsRef.current && pathsRef.current[0] && pathsRef.current[0][0])
    if (pathsRef.current && pathsRef.current[0] && pathsRef.current[0][0]) {
      const { coordinates } = newData.geometry;
      const bounds = new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]);

      for (const coord of coordinates) {
        bounds.extend(coord);
      }
      map.current.fitBounds(bounds, {
        padding: {
          left: 50,
          right: 50,
          top: 50,
          bottom: 50,
        },
        animate: false,
      });
    }



    // map.current.on('sourcedata',()=>{
      // console.log('R 3', !map.current.getLayer("route") , !map.current.isStyleLoaded(),  !map.current.getLayer("route") && !map.current.isStyleLoaded())

        if (!map.current.getLayer("route") && !map.current.isStyleLoaded()) {
          map.current.addLayer(
            linesLayer || {
              id: "route",
              type: "line",
              source: "route",
              layout: {
                "line-join": "round",
                "line-cap": "round",
              },
              paint: {
                "line-color": "#888",
                "line-width": 4,
                "line-gradient": [
                  "interpolate",
                  ["linear"],
                  ["line-progress"],
                  0,
                  "#777",
                ],
              },
            },
          );

          map.current.addLayer({
            id: "routearrows",
            type: "symbol",
            source: "route",
            layout: {
              "icon-allow-overlap": true,
              "symbol-placement": "line",
              "text-field": "▶",
              "text-size": ["interpolate", ["linear"], ["zoom"], 12, 24, 22, 60],
              "symbol-spacing": [
                "interpolate",
                ["linear"],
                ["zoom"],
                12,
                30,
                22,
                160,
              ],
              "text-keep-upright": false,
            },
            paint: {
              "text-color": "black",
              "text-halo-width": 3,
            },
          });
        }

    // })
  }
};



const removemypopup = (singlemarkerPopup) => {
  if (singlemarkerPopup) {
    for (let i = 0; i < singlemarkerPopup.length; i += 1) {
      if (
        singlemarkerPopup
        && singlemarkerPopup.length > i
        && singlemarkerPopup[i]
      ) {
        singlemarkerPopup[i].remove();
      }
    }
  }
};



useEffect(()=>{
  if (map.current) return; // initialize map only once

  map.current = new mapboxgl.Map({
    container: mapContainer.current,
    style: "mapbox://styles/swaroop-adapt/cl8u4czbc001015muia2zkthw",
    center: [lng, lat], // initial center
      zoom,
      maxZoom,
    // attributionControl: false
  });

  map.current.on("load",()=>{
    map.current.addControl(new mapboxgl.FullscreenControl());
    map.current.addControl(new mapboxgl.NavigationControl());

    setIsMapLoaded(true);
    map.current.resize();
    const initializeSpiderLeg = (spiderLeg) => {
      const pinElem = spiderLeg.elements.pin;
      const { feature } = spiderLeg;
      const { icon } = feature;
      if (icon) {
        pinElem.style.backgroundImage = `url(/img/${icon}.svg)`;
        pinElem.style.backgroundSize = "100%";
        pinElem.style.backgroundPosition = "unset";
      }
    };
    // popup on spiderify marker
    const spiderifier = new MapboxglSpiderifier(map.current, {
      onClick: async (e, spiderLeg) => {
        e.stopPropagation();
        if (getMarkerInfoWindowHTML) {
          const properties = spiderLeg.feature;
          const coordinatesofmarker = spiderLeg
          && spiderLeg.mapboxMarker && spiderLeg.mapboxMarker._lngLat;

          const arrayofCordinates = Object.values(coordinatesofmarker);

          const mapaddress = await getAddressfromMap(arrayofCordinates);

          properties.address = mapaddress;
          // console.log('spiderLeg',spiderLeg)
          const HTMLtext = getMarkerInfoWindowHTML(spiderLeg.feature || {});

          const { markerId, deviceId , id } = spiderLeg.feature;
          const deviceData = markerId || deviceId || id;
          if (deviceData) {
            // if (devices.hasOwnProperty(deviceData)) return;
            const popup = new mapboxgl.Popup({
              closeButton: true,
              closeOnClick: true,
              offset: MapboxglSpiderifier.popupOffsetForSpiderLeg(spiderLeg),
            });
            spiderifyPopup.push(popup);
            const position = spiderLeg.mapboxMarker.getLngLat();
            const coordinates = [position.lng, position.lat];

            popup.setLngLat(coordinates).setHTML(HTMLtext).addTo(map.current);
            devices[deviceData] = stateofPopup;
            popup.on("close", () => {
              delete devices[deviceData];
            });
          }
        }
      },
      markerWidth: 40,
      markerHeight: 40,
      initializeLeg: initializeSpiderLeg,
    });

    setLayersofSpiderify(spiderifier);


    //normal markers click
    map.current.on("click", "points", async (e) => {
      // e.preventDefault();
      const coordinates = e.features[0].geometry.coordinates.slice();

      const { properties } = e.features[0];
      const coordinatesofmarker = e.features[0]._geometry.coordinates;
      if (properties.cluster && properties.cluster_id) return;
      if (getMarkerInfoWindowHTML) {

        const mapaddress = await getAddressfromMap(coordinatesofmarker);
        properties.address = mapaddress;

        const HTMLtext = getMarkerInfoWindowHTML(properties || {});

        // Ensure that if the map is zoomed out such that
        // multiple copies of the feature are visible, the
        // popup appears over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        const currentZoom = map.current.getZoom();
        // const targetZoom = currentZoom >= 18 ? 18 : 12;
        const tZ=currentZoom > 12 ? 18 : 12

        const popup=new mapboxgl.Popup()
           .setLngLat(coordinates)
          .setHTML(HTMLtext)
          .addTo(map.current);

           showpopup.push(popup);
        // console.log('popup', popup)
        map.current.on("mouseenter", "points", () => {
          map.current.getCanvas().style.cursor = "pointer";
        });

        // Change it back to a pointer when it leaves.
        map.current.on("mouseleave", "points", () => {
          map.current.getCanvas().style.cursor = "";
        });

        map.current.easeTo({
          zoom:  tZ ,
          center:coordinates
        });

      }
    });


     // when we click cluster or outside the cluster.(Spiderify effect)
     map.current.on("click", (e) => {
      const features = map.current.queryRenderedFeatures(e.point, {
        layers: ["points"],
      });

      spiderifier.unspiderfy();
      if (!features.length) {
        return;
      }
      if (
        features
        && features[0]
        && features[0].properties.cluster
        && features[0].properties.cluster_id
      ) {
        const clusterId = features[0].properties.cluster_id;

        map.current
          .getSource("points")
          .getClusterExpansionZoom(clusterId, (err, expansionZoom) => {
            if (err) return console.error("Error getting clusterzoom!");
            if (maxZoom <= expansionZoom) {
              map.current
                .getSource("points")
                .getClusterLeaves(
                  clusterId,
                  100,
                  0,
                  (leaferr, leafFeatures) => {
                    if (leaferr) {
                      return;
                    }

                    const markersFromLeafFeatures = leafFeatures.map(
                      (data) => data.properties,
                    );

                    spiderfeatures.push(markersFromLeafFeatures);

                    map.current.easeTo({
                      zoom: maxZoom < expansionZoom ? maxZoom : expansionZoom,
                      center: leafFeatures[0].geometry.coordinates,
                    });

                    spiderifier.spiderfy(
                      features[0].geometry.coordinates,
                      markersFromLeafFeatures,
                    );


                  },
                );
            } else {
              map.current.easeTo({
                zoom: maxZoom < expansionZoom ? maxZoom : expansionZoom,
                center: features[0].geometry.coordinates,
              });
            }
          });
      }
    });




    map.current.on("zoom", () => {
      spiderifier.unspiderfy();
      removepopupofSpiderify(spiderifyPopup);
      removemypopup(showpopup);
    });





  })

},[])

useEffect(() => {
  refreshMakers(markersRef.current);
  refreshPaths();
  panToProps();
  map.current.on("styledata", () => {
    if (map.current.isStyleLoaded()) {
      refreshMakers(markersRef.current);
    }
  });
}, [isMapLoaded] );

useEffect(() => {
  if (!map.current) {
    return;
  }
  markersRef.current = markers;
  refreshMakers(markersRef.current);
  removemypopup(showpopup);
}, [markers]);

useEffect(() => {
  markerClusteringRef.current = markerClustering;
  myClusterRef.current = myCluster;
}, [ markerClustering,myCluster]);


useEffect(() => {
  if (!map.current) {
    return;
  }
  // console.log('Paths updated: 1', paths);
  pathsRef.current = paths;
  // console.log('Paths updated:', pathsRef.current );
  refreshPaths();
  refreshMakers(markersRef.current)
}, [paths]);


const removepopupofSpiderify = (spiderPopup) => {
  if (spiderPopup) {
    for (let i = 0; i < spiderPopup.length; i += 1) {
      if (spiderPopup && spiderPopup.length > i && spiderPopup[i]) {
        spiderPopup[i].remove();
      }
    }
  }
};


const removeSpiderFeature = (mapboxSpider) => {
  if (mapboxSpider) {
    mapboxSpider.unspiderfy();
    removepopupofSpiderify(spiderifyPopup);
  }
};

if (closeSpiderifyonCard) {
  removeSpiderFeature(layersofSpiderify);
}




  return (
    <>
    <Grid>
    {/* {paths && paths.length >= 1 && (
        <Tooltip title="click">
          <button
            className={classes.fit}
            label="path-btn"
            type="button"
            onClick={fitBounds}
          >
            <RouteRoundedIcon />
          </button>
        </Tooltip>
      )} */}
    <div
        ref={mapContainer}
        style={{
          paddingLeft: "1rem",
          height: "68vh",
          position: "relative",
          borderRadius: "10px",
          zIndex: "0",
        }}
      />
    </Grid>

    </>
  );
};

export default withStyles(styles)(MapBoxComp);
