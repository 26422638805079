import React, {useContext} from "react";
import UserContext from "Context/UserContext";
import { withStyles } from '@mui/styles';
import { Grid, Tooltip,  } from "@mui/material";
import { useMediaQuery, createTheme } from '@material-ui/core';
import {  useHistory } from "react-router-dom";
import InfoIcon from '@mui/icons-material/Info';



const styles = {
    outerdiv: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    outerspan:{
//   width: !hideContent ? " 1vh" :'0.8vw',
              //  height: !hideContent ? " 1vh" :'0.8vw', 
              // width:'1em',
              // height:'1em',
                border: '1px solid #D3D3D3',
              
                // backgroundColor: c.rating > 3 ? 'rgba(181,6,6, opacity)':  'rgba(228,55,55)',
                  display: 'inline-block',
                   marginRight: '3px',
                    cursor: 'pointer' ,
                    "&:hover": {
                      boxShadow: "0px 0px 3px 3px  rgba(191,191,191,191)",
                    },
    }

}

const CalendarContent = (props) => {
    const { eventInfo, classes } = props;
    const history = useHistory();
    const theme = createTheme({
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 900,
          lg: 1200,
          xl: 1536,
        },
      },
      customTooltip: {
        backgroundColor: "rgba(220, 0, 78, 0.8)"
      },
    });
    
    const companyDetails = useContext(UserContext);
    const {  CompanyInfo  } = companyDetails;
    // const { chamberType}= CompanyInfo && CompanyInfo.settings && CompanyInfo.settings

     const hideContent = useMediaQuery(theme.breakpoints.up('sm'));
    // const mediumScreen=useMediaQuery(theme.breakpoints.up('md'));

    const eventArray = [];
    eventArray.push(eventInfo)


      const handletime=(value)=>{
        if(value){
          const tolerancevalue=value;
          const sec = parseInt(tolerancevalue, 10); // convert value to number if it's string
            let hours   = Math.floor(sec / 3600); // get hours
            let minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
            let seconds = sec - (hours * 3600) - (minutes * 60); //  get seconds
            // add 0 if value < 10; Example: 2 => 02
            if (hours   < 10) {hours   = "0"+hours;}
            if (minutes < 10) {minutes = "0"+minutes;}
            if (seconds < 10) {seconds = "0"+seconds;}
  
          if(tolerancevalue.includes(("SI"))){
           
             return "(Instant) Sending Interval is " + `${hours + " hour " + minutes + " min "}`
            
          }else{
      
            return hours + "hour " + minutes + "min "
          }
        }
       return "N/A";
          

      }
     
      const handlePossibleVio=(tt)=>{
        const tolerance=tt;
        const possiblevio=86400/tolerance;
        return possiblevio;
      }

      const handleColor=(id, rating, tt)=>{
        const tolerance=tt;
        // const ratingVal=rating;
        const possiblevio=86400/tolerance;

        const range=possiblevio/3;   //to find the 1 parts in a day of tt
    
        const range2=range * 2;
        // const range3= range * 3;
        //288, 96,236
         if(rating > 0 && rating <= range ){
            return "#EFD1D1"
         }
         if( rating > range && rating <= range2){
          return "#F08D8D"
         }
         if(rating > range2){
          return "#E43737"
         }
         return "#fff";

      }

 const handleChamberClick=(chamberId, range, assetuid)=>{
 
  const startDate= range.start;
      const endDate=range.end;
      var startDay = new Date(startDate);
      startDay.setHours(0,0,0,0);
       const startDayUnix=Math.round((startDay) / 1000)
       var nextDay = new Date(endDate);
       nextDay.setHours(24,0,0,0);
       const nextDayUnix=Math.round((nextDay) / 1000)
      // const unixstartdate=Math.round((startDate) / 1000);
      // const unixenddate=Math.round((endDate) / 1000);
     
  history.push({pathname:'/dashboard', search: `?assetUid=${assetuid}&&startTime=${startDayUnix}&&endTime=${nextDayUnix}&&RS=ReportSection` , state: { isActive: true }, })
 }


    
    const chambers = eventInfo.event._def.extendedProps.data || [];
    const range=eventInfo.event._instance.range || {}
 
    return (
        <>

 <Grid style={{width:'100%', }} 
 // style={{backgroundColor:'yellow'}}
 >
     <Grid item xs={12}  >
       <span style={{fontSize:!hideContent ? '2vw' : '1vw',wordBreak:'break-all', }}> {eventInfo.event._def.title} </span>
     </Grid>
     {chambers.map(c => {
         //c.rating
        
        //  const opacity = (c.rating /  12) ;
        
         return (
          <>
             <Tooltip 
             PopperProps={{
              sx: {
                "& .MuiTooltip-tooltip": {
                  // height:'100px',
                  // width:'200px',
                  color: "#000",
                  // borderRadius:'22px',
                  border:'1px solid #cecece',
                  backgroundColor:'#fff',
                 position:'relative',
                //  zIndex:'9999'
                }
              }
            }}
             key={c.chamberId} 
             title={<>
             <Grid container >
              <Grid item xs={12} 
              style={{
               
                fontSize:'1.3em', 
                
                // fontWeight:'bold'
                }}>
              <span style={{fontWeight:600,color:'rgba(0,0,0,0.8)'}}>{CompanyInfo  && CompanyInfo.settings   && CompanyInfo.settings.chamberType 
              ? CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.chamberType
               : "Chamber " }  Id: </span> 
            <span style={{color:"rgba(0,0,0,0.8)"}}>{c.chamberId} </span> 
              </Grid>
              

                 {
                  Number(parseInt(c && c.tolerancesetting)) ? (<>
               <Grid  item xs={12}  style={{fontSize:'1.3em', }} >
               <span style={{fontWeight:600,color:'rgba(0,0,0,0.8)'}}> Tolerance Setting: </span>
               <span style={{color:"rgba(0,0,0,0.8)"}}> { Number(parseInt(c.tolerancesetting)) ? handletime(c.tolerancesetting): " N/A" }</span>
                 </Grid>
             
             <Grid  item xs={12}  style={{fontSize:'1.3em',}}>
             <span style={{fontWeight:600,color:'rgba(0,0,0,0.8)'}}> Maximum possible violations in a day: </span>
             <span style={{color:"rgba(0,0,0,0.8)"}}> { Number(parseInt(c.tolerancesetting)) ? handlePossibleVio(parseInt(c.tolerancesetting))  + " times": " N/A" } </span>
              </Grid>
             <Grid item xs={12}  style={{fontSize:'1.3em', }}>
             <span style={{fontWeight:600,color:'rgba(0,0,0,0.8)'}}>Number of Violations occured: </span>
              <span style={{color:"rgba(0,0,0,0.8)"}}>{ Number(parseInt(c.tolerancesetting)) ? c.rating : "N/A"}</span>
              </Grid>
                  </>) :
                  " "
                 }
                 
             
             {Number(parseInt(c.tolerancesetting)) ?  " " : 
             <Grid style={{display:'flex'}}>
              <Grid item xs={1}><InfoIcon fontSize="small"  /></Grid>
              <Grid item xs={11} style={{margin:'auto', fontSize:'1.2em', marginLeft:"0.5em"}}>The chamber has no device</Grid>
               </Grid>}
             
             
              </Grid>
              </>
            }
              >
               {/* <span
               className={classes.outerspan}
               onClick={()=>handleChamberClick(c.chamberId,range,c.assetUid )}
              
                >  */}
                  <span
                  className={classes.outerspan}
                   onClick={()=>handleChamberClick(c.chamberId,range,c.assetUid )}
                  style={{ 
                   width:'0.9vw',
                   height: '0.9vw', 
                  //  borderRadius:'8px',
                 
                  backgroundColor:handleColor(c.chamberId,c.rating,parseInt(c.tolerancesetting && c.tolerancesetting) ),
                   
                  //  border: '1px solid #D3D3D3',
                  //   //  opacity: opacity,
                        
                         }} >
                           
                        </span>
                      
                        {/* </span> */}
            </Tooltip>
            </>
         )
     })}
     
 </Grid>



           




        </>
    )
}

export default withStyles(styles)(CalendarContent);



