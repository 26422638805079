import React from 'react';

import { TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';

const InputforCountrycode = (props) =>
// const {
//   id, placeholder, onChange,value
// } = props;
  (
    <TextField
      {...props}
      fullWidth
      variant="standard"
      // id={id}
      // type="string"
      // onChange={onChange}
      // value={value}
      // placeholder={placeholder}
     
      InputProps={{
        disableUnderline: true,
        startAdornment: (
            <InputAdornment position="start">+</InputAdornment>
          ),
        style: {
          padding: '1rem',
          border: 'none',
          backgroundColor: props.backgroundColor,
          height: '2.25rem',
          borderRadius: '22px',
          fontSize: '14px',
          fontFamily: 'Montserrat',
        },
      }}
    />
  );
  
export default InputforCountrycode;

