import React from "react";
import { withStyles } from "@mui/styles";
import {
  Grid, Tooltip, Typography,
} from "@mui/material";
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';
import OpacityIcon from '@mui/icons-material/Opacity';
import Battery2BarIcon from '@mui/icons-material/Battery2Bar';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import WifiIcon from '@mui/icons-material/Wifi';
import WifiOffIcon from '@mui/icons-material/WifiOff';
// import SignalCellularAlt1BarIcon from '@mui/icons-material/SignalCellularAlt1Bar';
import { CtoF } from "../utils/helpers";
import IconButton from '@mui/material/IconButton';
// import Collapse from '@mui/material/Collapse';
import API from "API/apiConfig";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from "react";
import moment from "moment/moment";
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import UserContext from 'Context/UserContext';


const styles = {
  outerDiv: {
    borderRadius: "14px",
    boxShadow: "10px 10px 5px -6px rgba(169,208,208, 0.5)",
    overflow: "revert",
    "&:hover": {
      boxShadow: "10px 10px 5px -3px rgba(169,208,208)",
    },
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    padding: "0px",
    margin: "6px",
    cursor: "pointer",
    // borderTop: "1px solid #eee",
    // borderLeft: "1px solid #eee",
    border: '1px solid #CAE8E8',
    // height:'100%',

    position: 'relative'

  },
  outerDiv1: {
    "&:hover": {
      boxShadow: "10px 10px 5px -3px rgba(169,208,208)",
    },
    cursor: "pointer",
    borderRadius: "14px",
    boxShadow: "10px 10px 5px -6px rgba(169,208,208, 0.5)",
    overflow: "revert",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    padding: "0px",
    margin: "6px",
    height: '13.5rem',
    border: '1px solid #CAE8E8',


    position: 'relative'

  },
  colorDiv: {
    height: '0.2rem',
    backgroundColor: 'rgba(0, 128, 129, 0.5)',
    width: '30%',
    borderRadius: '20px',
  },
  colorDiv1: {
    height: '0.2rem',
    backgroundColor: 'rgba(0, 128, 129, 0.5)',
    width: '30%',
    borderRadius: '20px',
  },

  chamberGrid: {
    backgroundColor: '#EDF5FD',
    height: '3rem',
    width: '95%',
    margin: '0.5rem',
    borderRadius: '8px',
    paddingLeft: '0.5rem'

  },
  chamberGrid1: {
    backgroundColor: '#EDF5FD',
    height: '2.8rem',
    width: '95%',
    margin: '0.5rem',
    borderRadius: '8px',
    paddingLeft: '0.5rem'

  },
  chamberDetail: {
    width: '95%',
    border: '1px solid #CAE8E8',
    borderRadius: '8px',
    // margin: '0.3rem',///
    marginBottom: '0.4rem',
    // margin:'0.5rem',
    // height:'9rem'
  },
  chamberDetail1: {
    width: '95%',
    border: '1px solid #CAE8E8',
    borderRadius: '8px',
    // margin:'0.5rem',
    marginBottom: '1rem',
    height: '8.7rem'
  },
  consignmentContainer: {
    textAlign: "left",
    padding: "10px",
  },
  alertLine: {
    height: '2.5px',
    backgroundColor: 'red',
    width: '50%'
  },

  thresholdtext: {
    fontSize: '0.75rem',
    fontFamily: 'Montserrat',
    textAlign: 'left',
    color: 'rgba(0,0,0,0.9)',
    fontWeight: 500,

  },
  violatedRange: {
    color: 'rgba(197, 68, 68, 1)',
    fontSize: '0.8rem',
    fontFamily: 'Montserrat',
    fontWeight: 'bold'
  },
  warnedRange: {
    color: '#E2BF07',
    fontSize: '0.8rem',
    fontFamily: 'Montserrat',
    fontWeight: 'bold'
  },

  normalRange: {
    fontSize: '0.8rem',
    fontFamily: 'Montserrat',
    color: 'rgba(0, 0, 0, 0.9)'
  },
  normalDevice: {
    fontSize: '0.8rem',
    fontWeight: 400,
    fontFamily: 'Montserrat',
    textAlign: 'left',
    // margin:'auto'
  },
  alertDevice: {
    color: 'rgba(197, 68, 68, 1)',
    fontSize: '0.8rem',
    fontWeight: 'bold',
    fontFamily: 'Montserrat',

    textAlign: 'left'
  },
  warnedDevice: {
    color: '#E2BF07',
    fontSize: '0.8rem',
    fontWeight: 'bold',
    fontFamily: 'Montserrat',

    textAlign: 'left'
  },
  numberSize: {
    // fontSize: '0.6rem',
    fontFamily: 'Montserrat',
    color: 'rgba(0, 0, 0, 0.9)'
  },
  openCollapse: {
    // fontSize:'2rem' ,
    fontWeight: 'bold',
    // color:'black',
    cursor: 'pointer'
  },
  closeCollapse: {
    // fontSize:'2rem' ,
    fontWeight: 'bold',
    transform: 'rotate(-180deg)',
    cursor: 'pointer'
  },
  bellIcon: {

  },
  noViolationAsset: {
    backgroundColor: 'rgba(0, 128, 129, 0.5)',
    height: '0.2rem',

    width: '30%',
    borderRadius: '20px',
  },
  violationAsset: {
    backgroundColor: 'red',
    height: '0.2rem',

    width: '30%',
    borderRadius: '20px',
  },
  warnedAsset: {
    backgroundColor: '#E2BF07',
    height: '0.2rem',
    width: '30%',
    borderRadius: '20px',
  },
  tooltipTextOl: {
    fontWeight: 'bold',
    color: 'green'
  },
  tooltipTextOff: {
    fontWeight: 'bold',
    color: 'rgba(197, 68, 68, 1)'
  },
  tooltipText: {
    fontWeight: 'bold',
  }

}

const APIURL = process.env.REACT_APP_APIURL;

const UpdatedChamberCard = (props) => {

  const { chamberName, classes, devices,
    chamberUid, onClickChamber,
    maxTemp,
    minTemp,
    maxHumidity,
    minHumidity,
    maxBattery,
    minBattery,
    companySettings,
    // allAssets,
    CompanyInfo,
    is_Violated,
    is_Warned,
    notificationSetting,
    chamberType,
    temperatureSettings, humiditySettings, batterySettings
  } = props

  const [openCollapse, setOpenCollapse] = useState(false)
  const [notiStatus, setNotiStatus] = useState(notificationSetting && Object.prototype.hasOwnProperty.call(notificationSetting, "all") ? notificationSetting.all : true)

  console.log("company info in card", CompanyInfo);
  const deviceTypes = CompanyInfo &&
    CompanyInfo.settings && CompanyInfo.settings.data && CompanyInfo.settings.data.humidity &&
    CompanyInfo.settings.data.humidity.disableDeviceTypes ? CompanyInfo.settings.data.humidity.disableDeviceTypes : [];


  function checkTypeMatch(deviceTypes, objects) {
    let hasMatch = false;
    let hasNonMatchingObject = false;
    for (const obj of objects) {
      if (deviceTypes && deviceTypes.includes(obj.type)) {
        hasMatch = true;
      } else {
        hasNonMatchingObject = true;
      }
    }
    return hasMatch && !hasNonMatchingObject;
  }

  //if hasTypeMatch is true , hide humidity column
  const hasTypeMatch = checkTypeMatch(deviceTypes, devices || []);


  const theme = createTheme();

  theme.typography.p = {
    fontSize: '0.7rem',
    '@media (min-width:600px)': {
      fontSize: '0.6rem',
    },
    '@media (min-width:900px)': {
      fontSize: '0.75rem',
    },
    '@media (min-width:1200px)': {
      fontSize: '0.7rem',
    },
    '@media (min-width:1536px)': {
      fontSize: '0.65rem',
    },
    // [theme.breakpoints.up('sm')]: {
    //   fontSize: '0.6rem',
    // },
    // [theme.breakpoints.up('md')]: {
    //   fontSize: '0.6rem',
    // },
  };

  let tempSettingText;
  if (minTemp !== null && maxTemp !== null) {
    tempSettingText =
      companySettings &&
        companySettings.temperature &&
        companySettings.temperature === "F"
        ? `${CtoF(minTemp)} to ${CtoF(maxTemp)} °F`
        : ` ${minTemp} to ${maxTemp} °C`;
  } else if (minTemp === null && maxTemp !== null) {
    tempSettingText =
      companySettings &&
        companySettings.temperature &&
        companySettings.temperature === "F"
        ? ` < ${CtoF(maxTemp)} °F`
        : ` < ${maxTemp} °C`;
  } else if (maxTemp === null && minTemp !== null) {
    tempSettingText =
      companySettings &&
        companySettings.temperature &&
        companySettings.temperature === "F"
        ? ` > ${CtoF(minTemp)} °F`
        : ` > ${minTemp} °C`;
  } else {
    tempSettingText = "N/A";
  }

  let humiditySettingText;
  if (minHumidity !== null && maxHumidity !== null) {
    humiditySettingText = ` ${minHumidity} to ${maxHumidity} %`;
  } else if (minHumidity === null && maxHumidity !== null) {
    humiditySettingText = ` < ${maxHumidity} %`;
  } else if (maxHumidity === null && minHumidity !== null) {
    humiditySettingText = ` > ${minHumidity} %`;
  } else {
    humiditySettingText = "N/A";
  }

  let batterySettingsText;
  if (minBattery !== null && maxBattery !== null) {
    batterySettingsText = ` ${minBattery} to ${maxBattery} %`;
  } else if (minBattery === null && maxBattery !== null) {
    batterySettingsText = ` < ${maxBattery} %`;

  } else if (maxBattery === null && minBattery !== null) {
    batterySettingsText = ` < ${minBattery} %`;
  } else {
    batterySettingsText = "10 to 25 %";
  }






  // const [devicesCopy, setDevicesCopy ]= useState([])
  // const sliceOriginAndDestination = (string) => {
  //     console.log('String',string.length  )

  //      return string.length > 40 ? `${string.slice(0, 35)}...` : string;

  //   };

  const handleCollapse = () => {
    setOpenCollapse(!openCollapse)
  }



  //    useEffect(() => {
  // 	setDevicesCopy(devices)
  // }, []);


  const lastKnownTemperature = (t, companySettings) => {
    // const companySettings = props.settings;
    if (
      companySettings &&
      companySettings.temperature &&
      companySettings.temperature === "F"
    ) {
      return `${CtoF(t)}°F`;
    }
    return `${t}°C`;
  };

  const handleMuteNotifications = (uid) => {
    const chamberUId = uid;
    const patchArray = []
    const NotificationValues = {
      path: '/notificationSettings',
      op: 'replace',
      value: { all: !notiStatus }
    }
    patchArray.push(NotificationValues);

    API
      .patch(`${APIURL}/assets/${chamberUId}`, patchArray)
      .then((res) => {

        const notSettings = (res && res.data && res.data.data && res.data.data.notificationSettings) ? res.data.data.notificationSettings : {};

        if (notSettings && Object.prototype.hasOwnProperty.call(notSettings, "all")) {

          if (notSettings.all === notiStatus) {
            //toast msg failed to update
          } else {

            setNotiStatus(notSettings.all)
          }

        } else {

          //toast msg failed to update
        }
      })
      .catch(() => {

      })

  }






  const PING_WAIT_BUFFER_TIME = 3 * 60; // sec

  //  let assetStatus= handleAssetStatus(assetEventSummary)
  const handlenextpossibleUpdate = (lastPing, nextTransmit) => {

    if (nextTransmit) {
      const now = Date.now() / 1000;
      let nU = Number(nextTransmit) + PING_WAIT_BUFFER_TIME;
      if (nU <= now && lastPing) {
        while (nU < now) nU += Number(lastPing.interval.transmit) * 60;
      }
      const nextUpdate = moment((nU * 1000)).fromNow();
      const nextUpdateTime = moment(nU * 1000).format('hh:mm A, DD-MM-YYYY');
      return ` with  ${nextUpdate} | ${nextUpdateTime}`;
    }
    return 'N/A';
  }



  let offlineDuration = 5400;
  if (CompanyInfo && ["FTVP", "QTS"].includes(CompanyInfo.companyId)) offlineDuration = 7 * 24 * 60 * 60;

  const getTooltipTitle = (props) => {
    const { lastUpdate, lastPing, nextTransmit } = props;
    // (device.lastPing &&
    //   device.lastPing.timeStamp &&
    //   moment().unix() &&
    //   moment().unix() - device.lastPing.timeStamp <=
    //     5400) 

    return (
      <div >
        <div
        // className={status === "Online" ? classes.tooltipTextOl:classes.tooltipTextOff} 
        >
          {/* {status} */}
          {
            lastUpdate &&
              moment().unix() &&
              moment().unix() - lastUpdate <=
              offlineDuration ?
              <span className={classes.tooltipTextOl}>Online</span>
              : <span className={classes.tooltipTextOff}>Offline</span>
          }
        </div>
        <div>Last updated :
          <span className={classes.tooltipText}> {lastUpdate && Number(lastUpdate) ?
            `${moment.unix(Number(lastUpdate)).fromNow()} | ${moment(Number(lastUpdate) * 1000).format('hh:mm A, DD-MM-YYYY')}` : "N/A"}</span>

        </div>
        <div>Next possible update :
          <span className={classes.tooltipText}>{handlenextpossibleUpdate(lastPing, nextTransmit)}</span>

        </div>
        <div>
          {(lastUpdate &&
            moment().unix() &&
            moment().unix() - lastUpdate <=
            offlineDuration) ? "" : "Note : The device is considered offline if it has not sent data for 90 minutes or more"}
        </div>

      </div>
    )
  }


  const firstTwoDevices = devices && devices.slice(0, 2)
  const deviceToMapOn = openCollapse ? devices : firstTwoDevices

  return (<>
    <ThemeProvider theme={theme}>
      {/* dont specify height for grid which is having more than 2 devices */}
      <Grid container
        onClick={() => {
          onClickChamber(chamberUid);
        }}

        className={devices && devices.length > 2 ? classes.outerDiv : classes.outerDiv1}

      >



        <Grid item
          className={
            is_Violated ? classes.violationAsset : is_Warned ? classes.warnedAsset : classes.noViolationAsset
          }
          // className={devices && devices.length >= 2 ? classes.colorDiv : classes.colorDiv1}
          style={{ width: '50%' }}


        />

        <Grid container

          className={devices && devices.length >= 2 ? classes.chamberGrid : classes.chamberGrid1}
        >
          <Grid item xs={10} style={{ margin: 'auto' }}>
            <Typography
              //   variant="p"
              style={{
                // margin:'auto',
                color: 'rgba(4, 82, 200)',
                padding: '2px',
                textAlign: 'left',
                fontFamily: 'Montserrat',
                fontSize: '0.8rem',
                margin: 'auto'
              }}
            >
              <Tooltip title={chamberType ? chamberType + " Name" : "Chamber Name"}>
                <span>
                  {chamberType ? chamberType.length > 12 ? `${chamberType.slice(0, 12)}... : ` : chamberType + " Name : " : 'Chamber Name : '}
                </span></Tooltip>

              <span style={{ color: 'rgba(4, 82, 200, 0.7)', }}>
                <Tooltip title={chamberName}>
                  <span>
                    {chamberName && chamberName.length > 35 ? `${chamberName.slice(0, 35)}...` : chamberName}
                  </span>
                </Tooltip>
              </span>
              {/* {chamberName.length > 40 ? sliceOriginAndDestination(chamberName) : chamberName} */}

            </Typography>
          </Grid>
          <Grid item xs={2} style={{ margin: 'auto' }} >
            <Tooltip
              PopperProps={{
                sx: {
                  "& .MuiTooltip-tooltip": {
                    fontSize: '12px',
                    color: "#000",
                    backgroundColor: '#fff',
                    fontFamily: 'Montserrat',
                    border: '1px solid rgb(211, 211, 211)',
                    position: 'relative',

                  }
                }
              }}
              title={
                <>
                  <Grid>Mute Notifications for this chamber</Grid>
                  <Grid>Note: Once you mute the chamber, you will no longer receive any alert sms and email,
                    untill you unmute the {chamberType ? chamberType : "chamber"}.</Grid>
                </>
              }

            >
              {notiStatus ?
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation()
                    handleMuteNotifications(chamberUid)
                  }}
                >

                  <NotificationsActiveIcon
                    style={{
                      fontSize: '1.3rem',
                      color: '#468EE3',
                      cursor: "pointer",
                    }} />
                </IconButton>
                :
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation()
                    handleMuteNotifications(chamberUid)
                  }}

                >
                  <NotificationsOffIcon
                    onClick={() => { handleMuteNotifications(chamberUid) }}
                    style={{
                      fontSize: '1.3rem',
                      color: '#468EE3',
                      cursor: "pointer",
                    }} />
                </IconButton>

              }


            </Tooltip>

          </Grid>


        </Grid>

        <Grid
          className={devices && devices.length > 2 ? classes.chamberDetail : classes.chamberDetail1}

        >
          {/* threshold and icons */}
          {devices && devices.length >= 1 && (
            <>
              <Grid container style={{ paddingTop: '0.5rem' }}>
                {/* <Grid item xs={1} />
              <Grid item xs={5} />
              <Grid item xs={2}><DeviceThermostatIcon sx={{ fontSize: '1rem' }} /> </Grid>
              <Grid item xs={2}><OpacityIcon sx={{ fontSize: '1rem' }} /> </Grid>
              <Grid item xs={2}><Battery2BarIcon sx={{ fontSize: '1rem' }} /> </Grid> */}
                <Grid item xs={4} />
                <Grid item xs={8} style={{ display: 'flex', justifyContent: 'space-around' }}>
                  <Grid item ><Tooltip title="Temperature" ><DeviceThermostatIcon sx={{ fontSize: '1.2rem' }} /></Tooltip></Grid>
                  {!hasTypeMatch && <Grid item ><Tooltip title="Humidity" ><OpacityIcon sx={{ fontSize: '1.2rem' }} /></Tooltip></Grid>}
                  <Grid item ><Tooltip title="Battery" ><Battery2BarIcon sx={{ fontSize: '1.2rem' }} /></Tooltip> </Grid>
                </Grid>
              </Grid>
              <Grid container style={{ paddingTop: '0.5rem' }}>
                {/* <Grid item xs={2} />
              <Grid item xs={4} 
              className={classes.thresholdtext}
              >
                Threshold
                 </Grid>
              <Grid item xs={2} className={classes.numberSize}> {tempSettingText} </Grid>
              <Grid item xs={2} 
              className={classes.numberSize}
              >
              {humiditySettingText}
                </Grid>
              <Grid item xs={2} 
              className={classes.numberSize}
              >
                {batterySettingsText}
              </Grid> */}
                <Grid item xs={1} />
                <Grid item xs={3} sx={{ margin: 'auto' }} className={classes.thresholdtext}>
                  Threshold
                </Grid>
                <Grid item xs={8}>
                  <Grid container style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <Grid item className={classes.numberSize} >
                      <Typography variant="p" >{tempSettingText}</Typography>
                    </Grid>
                    {
                      !hasTypeMatch &&
                      <Grid item className={classes.numberSize}>
                        <Typography variant="p">{humiditySettingText}</Typography>
                      </Grid>
                    }
                    <Grid item className={classes.numberSize}>
                      <Typography variant="p"> {batterySettingsText}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>


            </>
          )

          }


          {

            devices && devices.length ?


              //code if 2 devices are there
              <>
                {deviceToMapOn.map((dev) => {
                  if (dev) {
                    // const isTempViolated = dev.violations && dev.violations.temperature;
                    const isTempViolated = (Number(dev.lastPing && dev.lastPing.t1 && dev.lastPing.t1[dev.lastPing.t1.length - 1]) > Number(temperatureSettings && temperatureSettings.maxViolation))
                      || (Number(dev.lastPing && dev.lastPing.t1 && dev.lastPing.t1[dev.lastPing.t1.length - 1]) < Number(temperatureSettings && temperatureSettings.minViolation))

                    // const isTempWarned = dev.warnings && dev.warnings.temperature
                    const isTempWarned = (Number(dev.lastPing && dev.lastPing.t1 && dev.lastPing.t1[dev.lastPing.t1.length - 1]) > Number(temperatureSettings && temperatureSettings.maxWarning))
                      || (Number(dev.lastPing && dev.lastPing.t1 && dev.lastPing.t1[dev.lastPing.t1.length - 1]) < Number(temperatureSettings && temperatureSettings.minWarning))


                    // const isHumidityViolated = dev.violations && dev.violations.humidity;
                    const isHumidityViolated = (Number(dev.lastPing && dev.lastPing.humidity && dev.lastPing.humidity[dev.lastPing.humidity.length - 1]) > Number(humiditySettings && humiditySettings.maxViolation))
                      || (Number(dev.lastPing && dev.lastPing.humidity && dev.lastPing.humidity[dev.lastPing.humidity.length - 1]) < Number(humiditySettings && humiditySettings.minViolation))

                    // const isHumidityWarned = dev.warnings && dev.warnings.humidity;

                    const isHumidityWarned = (Number(dev.lastPing && dev.lastPing.humidity && dev.lastPing.humidity[dev.lastPing.humidity.length - 1]) > Number(humiditySettings && humiditySettings.maxWarning))
                      || (Number(dev.lastPing && dev.lastPing.humidity && dev.lastPing.humidity[dev.lastPing.humidity.length - 1]) < Number(humiditySettings && humiditySettings.minWarning))

                    // const isBatteryViolated = dev.violations && dev.violations.battery;
                    const isBatteryViolated = Number(dev.lastPing && dev.lastPing.battery) < Number(batterySettings && batterySettings.critical)

                    // const isBatteryWarned = dev.warnings && dev.warnings.battery
                    const isBatteryWarned = Number(dev.lastPing && dev.lastPing.battery) < Number(batterySettings && batterySettings.warning)

                    return (
                      <>
                        <Grid container style={{ paddingTop: '0.5rem' }}>
                          <Grid item xs={1} >

                            <span >
                              {dev.lastPing &&
                                dev.lastPing.timeStamp &&
                                moment().unix() &&
                                moment().unix() - dev.lastPing.timeStamp <=
                                offlineDuration
                                ?
                                ////show icon based on device sendingMode if it is online
                                dev.lastPing && dev.lastPing.sendingMode && dev.lastPing.sendingMode.toLowerCase() === "wifi" || dev.type && dev.type === "PIXEL_W100" || dev.type && dev.type === "PIXEL_W300" ?

                                  <Tooltip
                                    PopperProps={{
                                      sx: {
                                        "& .MuiTooltip-tooltip": {
                                          fontSize: '12px',
                                          color: "#000",
                                          backgroundColor: '#fff',
                                          fontFamily: 'Montserrat',
                                          border: '1px solid rgb(211, 211, 211)',
                                          position: 'relative',

                                        }
                                      }
                                    }}

                                    title={getTooltipTitle({ lastUpdate: dev.lastPing && dev.lastPing.timeStamp, lastPing: dev && dev.lastPing, nextTransmit: dev && dev.nextTransmit })} >
                                    <WifiIcon sx={{ fontSize: '1.2rem', color: '#2379DE' }} />
                                  </Tooltip>
                                  :
                                  <Tooltip
                                    PopperProps={{
                                      sx: {
                                        "& .MuiTooltip-tooltip": {
                                          fontSize: '12px',
                                          color: "#000",
                                          backgroundColor: '#fff',
                                          fontFamily: 'Montserrat',
                                          border: '1px solid rgb(211, 211, 211)',
                                          position: 'relative',

                                        }
                                      }
                                    }}
                                    title={getTooltipTitle({ lastUpdate: dev.lastPing && dev.lastPing.timeStamp })} >
                                    < SignalCellularAltIcon sx={{ fontSize: '1.2rem', color: '#2379DE' }} />
                                  </Tooltip>
                                :
                                //show icon based on last sendingMode if it is offline
                                dev.lastPing && dev.lastPing.sendingMode && dev.lastPing.sendingMode.toLowerCase() === "wifi" || dev.type && dev.type === "PIXEL_W100" || dev.type && dev.type === "PIXEL_W300" ?
                                  <Tooltip
                                    PopperProps={{
                                      sx: {
                                        "& .MuiTooltip-tooltip": {
                                          fontSize: '12px',
                                          color: "#000",
                                          backgroundColor: '#fff',
                                          fontFamily: 'Montserrat',
                                          border: '1px solid rgb(211, 211, 211)',
                                          position: 'relative',

                                        }
                                      }
                                    }}
                                    title={getTooltipTitle({ lastUpdate: dev.lastPing && dev.lastPing.timeStamp })} >
                                    <WifiOffIcon sx={{ fontSize: '1.2rem', color: '#C54444' }} />
                                  </Tooltip>
                                  :
                                  <Tooltip
                                    PopperProps={{
                                      sx: {
                                        "& .MuiTooltip-tooltip": {
                                          fontSize: '12px',
                                          color: "#000",
                                          backgroundColor: '#fff',
                                          fontFamily: 'Montserrat',
                                          border: '1px solid rgb(211, 211, 211)',
                                          position: 'relative',

                                        }
                                      }
                                    }} title={getTooltipTitle({ lastUpdate: dev.lastPing && dev.lastPing.timeStamp })} >
                                    <img style={{ height: "20px", width: "20px" }} src="/img/newnocell.svg" alt="no-cell-tower" />
                                  </Tooltip>

                              }

                            </span>
                          </Grid>
                          <Grid item xs={3} className={classes.normalDevice} sx={{ margin: 'auto' }}>

                            <span
                              className={
                                isTempViolated ||
                                  isHumidityViolated ||
                                  isBatteryViolated
                                  ? classes.alertDevice
                                  : isTempWarned ||
                                    isHumidityWarned ||
                                    isBatteryWarned ?
                                    classes.warnedDevice : classes.normalDevice
                              }
                            >{dev.id}</span><br />

                          </Grid>
                          <Grid item xs={8}>
                            <Grid container style={{ display: 'flex', justifyContent: 'space-around' }}>
                              <Grid item>
                                <span
                                  className={
                                    isTempViolated ? classes.violatedRange : isTempWarned ? classes.warnedRange : classes.normalRange
                                  }

                                >
                                  {dev.lastPing &&
                                    dev.lastPing.t1 &&
                                    dev.lastPing.t1.length
                                    ? lastKnownTemperature(
                                      dev.lastPing.t1[
                                      dev.lastPing.t1.length - 1
                                      ],
                                      companySettings
                                    )
                                    : "---"}
                                </span>
                              </Grid>
                              {!hasTypeMatch &&
                                <Grid item sx={{ pr: '0.5rem' }}>
                                  <span
                                    className={
                                      isHumidityViolated ? classes.violatedRange : isHumidityWarned ? classes.warnedRange : classes.normalRange
                                    }
                                  >
                                    {dev.lastPing &&
                                      dev.lastPing.humidity &&
                                      dev.lastPing.humidity.length
                                      ? `${dev.lastPing.humidity[
                                        dev.lastPing.humidity.length - 1
                                      ].toFixed(1)}%`
                                      : "---"}
                                  </span>
                                </Grid>
                              }
                              <Grid item>
                                <span
                                  className={
                                    isBatteryViolated ? classes.violatedRange : isBatteryWarned ? classes.warnedRange : classes.normalRange
                                  }
                                >
                                  {dev.lastPing && dev.lastPing.battery
                                    ? `${(dev.lastPing.battery)}%`
                                    : "---"}
                                </span>
                              </Grid>
                            </Grid>
                          </Grid>
                          {/* <Grid item xs={3}>

                    <span
                      className={
                        isTempViolated === 1 ? classes.violatedRange : isTempWarned === 1 ? classes.warnedRange : classes.normalRange
                      }

                    >
                      {dev.lastPing &&
                        dev.lastPing.t1 &&
                        dev.lastPing.t1.length
                        ? lastKnownTemperature(
                          dev.lastPing.t1[
                          dev.lastPing.t1.length - 1
                          ],
                          companySettings
                        )
                        : "---"}
                    </span>



                  </Grid>
                  <Grid item xs={2}>

                    <span
                      className={
                        isHumidityViolated === 1 ? classes.violatedRange : isHumidityWarned === 1 ? classes.warnedRange : classes.normalRange
                      }
                    >
                      {dev.lastPing &&
                        dev.lastPing.humidity &&
                        dev.lastPing.humidity.length
                        ? `${dev.lastPing.humidity[
                          dev.lastPing.humidity.length - 1
                        ].toFixed(1)}%`
                        : "---"}
                    </span>

                  </Grid>
                  <Grid item xs={2}>

                    <span
                      className={
                        isBatteryViolated === 1 ? classes.violatedRange : isBatteryWarned === 1 ? classes.warnedRange : classes.normalRange
                      }
                    >
                      {dev.lastPing && dev.lastPing.battery
                        ? `${Math.round(dev.lastPing.battery)}%`
                        : "---"}
                    </span>
                  </Grid> */}

                        </Grid>

                      </>)
                  }

                }

                )}
                {/* show expand more arrow only if device length is more than 2     */}
                {
                  devices.length > 2 ?
                    (
                      <>
                        <Grid
                          style={{ height: '1.4rem' }}
                        >

                          <Tooltip title={openCollapse ? '' : "view more devices"}>
                            <ExpandMoreIcon
                              className={openCollapse ?
                                classes.closeCollapse :
                                classes.openCollapse}
                              onClick={(e) => {
                                e.stopPropagation()
                                handleCollapse()
                              }}
                            />
                          </Tooltip>

                        </Grid>
                      </>
                    )
                    :
                    ''
                }

              </>

              :
              <div style={{ paddingTop: '1rem', fontFamily: 'Montserrat' }}>
                No Devices
              </div>
          }


        </Grid>

      </Grid>
    </ThemeProvider>
  </>)

}

export default withStyles(styles)(UpdatedChamberCard);












