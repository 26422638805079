import React ,{useEffect, useState} from 'react';
import { withStyles } from '@mui/styles';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import PropTypes from 'prop-types';

const styles = {
  gridParams: {
    width: "100%",
    boxShadow: "1px 1.7px 7px 0 rgba(136, 156, 176, 0.1)",
  },
  headerClass: {
    backgroundColor: '#008081',
    fontFamily:'Montserrat',

    fontSize: "14px",
    color: 'white',
    borderRight: '1px solid white!important',
    '&:hover': {
      backgroundColor: '#008081!important',
    },
  },
};

function mobileNumberFormatter(params) {
  if (params.data && params.data.address) {
    // return `+${params.data.contactNo.countryCode}-${params.data.contactNo.number}`;
    return params.data.address;
  } return ' - ';
}


function ClientsGrid({
  classes, rowData, 
  // onGridReady, 
  selectedRowData,
  onSelectionChanged,
  searchvalue
}) {
  const columnDefs = [{
    headerName: 'Client Name',
    field: 'name',
    sortable: true,
    filter: true,
    minWidth: 200,
    pinned: 'left',
    checkboxSelection: true,
   
  },
  {
    headerName: 'Client Id',
    field: 'id',
    sortable: true,
    filter: true,
    minWidth: 200,
    
    // pinned: 'left',
  },
  {
    headerName: 'Email ID',
    field: 'contact',
    minWidth: 200,
  }, {
    headerName: 'Mobile Number',
    field: 'address',
    minWidth: 200,
    valueFormatter: mobileNumberFormatter,
  },

  ];

  const [gridApi, setGridApi] = useState(null);


  const onGridReady = (params) => {
    // const gridApi = params.api;
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };

  const handleSelectionChanged = () => {
    if (gridApi) {
      onSelectionChanged(gridApi.getSelectedRows());
    }
  };

  const onSearch = () => {
    if (gridApi) {
      gridApi.setQuickFilter(searchvalue);
    }
  };

  const handleSelectionCleared = () => {
    if (selectedRowData.length === 0 && gridApi) {
      gridApi.deselectAll();
    }
  };

  useEffect(() => {
    if(selectedRowData){
      if (!selectedRowData.length) {
        handleSelectionCleared();
      }
    }
    
  }, [selectedRowData && selectedRowData.length]); // selectedRowData


  useEffect(() => {
    onSearch();
  }, [searchvalue]);

  

  // const isFirstColumn = (params) => {
  //   var displayedColumns = params.columnApi.getAllDisplayedColumns();
  //   var thisIsFirstColumn = displayedColumns[0] === params.column;
  //   return thisIsFirstColumn;
  // };

  const defaultColDef = {
    sortable: false,
    filter: false,
    suppressMovable: true,
    resizable: true,
    cellStyle: {textAlign:'left', fontFamily:'Montserrat',fontSize: "14px"},
    headerClass: classes.headerClass,
    // headerCheckboxSelection: isFirstColumn,
    // checkboxSelection: isFirstColumn,
  };
  const overlayLoadingTemplate = '<span class="ag-overlay-loading-center"><img src="./../img/horizontalLoader.svg" /></span>';
  const overlayNoRowsTemplate = '<img src="./../img/emptybox.png" />';
  const rowSelection = 'multiple';
  return (
    <div
    className={`${classes.gridParams} ag-theme-material`}
    >
      <AgGridReact
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        rowData={rowData}
        rowSelection={rowSelection}
        // onSelectionChanged={onSelectionChanged}
        onSelectionChanged={handleSelectionChanged}
        rowMultiSelectWithClick="true"
        // onGridReady={onGridReady}
        pagination
        paginationPageSize={10}
        domLayout="autoHeight"
        onGridReady={onGridReady}
        overlayLoadingTemplate={overlayLoadingTemplate}
        overlayNoRowsTemplate={overlayNoRowsTemplate}
      />
    </div>
  );
}

ClientsGrid.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  rowData: PropTypes.instanceOf(Object),
  // onGridReady: PropTypes.func.isRequired,
  onSelectionChanged: PropTypes.instanceOf(Object),
};

ClientsGrid.defaultProps = {
  rowData: null,
  onSelectionChanged: [],
};

export default withStyles(styles)(ClientsGrid);
