import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker, isInclusivelyBeforeDay } from 'react-dates';
import moment from 'moment';
import { Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InfoIcon from '@mui/icons-material/Info';
import './Reactdatepicker.css';
import { Grid } from '@mui/material';

const styles = {
  selectionBox: {
    background: 'none',
    width: '15%',
    height: '35px',
    borderRadius: '30px',
    outline: 'none',
    '&:focus': {
      border: '2px solid #fff',
    },
    margin: '5px 10px',
    display: 'flex',
    flexWrap: 'wrap',

  },
  selectText: {
    fontSize: '12px',
    fontFamily: 'Montserrat',
  },
  timeLabel: {
    fontSize: '10px',
    fontFamily: 'Montserrat',
    marginRight: '10px',
    marginTop:'1rem'
  },
  dateDisplay: {
    height: '35px',
  },
  info: {
    margin: '9px',
    fontSize: 'medium',
    color: '#777',
    opacity: '0.7',
  },
  disable: {
    display: 'none',
  },
};

/*
@TO-DO: Improve the css of calendar component
*/
class DateRange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedInput: null,
    };
  }

  onDatesChanged = (startDate, endDate) => {
    const { handleRangeChange, handleQuickOptionsChange } = this.props;
    if (handleRangeChange && handleQuickOptionsChange) {
      handleRangeChange(startDate, endDate);
      // handleQuickOptionsChange(0);
    }
  }


  render() {
    const {
      classes, startDate,endDate,  selectedQuickOption, handleQuickOptionsChange, message, 
      // disable,
    } = this.props;
    const { focusedInput } = this.state;
   
    return (
      <div style={{ display: 'flex', flexDirection: 'row-reverse', }}>
        <Grid container  style={{ display: 'flex', flexDirection: 'row-reverse' , }} >

     <Grid item xl={6} lg={8} md={8} sm={8}  xs={12} style={{justifyContent:'center', display:'flex'}}  >
     <DateRangePicker
          startDateId="startDate"
          endDateId="endDate"
          displayFormat="DD-MM-YYYY"
          isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
          numberOfMonths={1}
          startDate={startDate}
          endDate={endDate}
          onDatesChange={({ startDate, endDate }) => { this.onDatesChanged(startDate, endDate); }}
          focusedInput={focusedInput}
          onFocusChange={(focusedInput) => { this.setState({ focusedInput }); }}
          showDefaultInputIcon
          inputIconPosition="after"
         
         readOnly
        />
     </Grid>
    
        <Grid item xl={2} lg={2} md={2} sm={2} xs={6}  style={{justifyContent:'center', display:'flex',paddingRight:'3rem'}}>
        <FormControl className={classes.selectionBox}>
          <Select
          variant='standard'
            disableUnderline
            className={classes.selectText}
            value={selectedQuickOption}
            onChange={(e) => { if (handleQuickOptionsChange) handleQuickOptionsChange(Number(e.target.value)); }}
            spacing={2}
            style={{fontSize:'16px', marginTop:'0.5rem'}}
          >
            <MenuItem value={0} disabled className={classes.timeLabel}>Custom</MenuItem>
            <MenuItem value={7}  className={classes.timeLabel}>Last Week</MenuItem>
            {/* <MenuItem value={15} className={disable ? classes.disable : classes.timeLabel}>Last 15 Days</MenuItem>
            <MenuItem value={30} className={disable ? classes.disable : classes.timeLabel}>Last Month</MenuItem> */}
          </Select>
        </FormControl>
        </Grid>
        <Grid item xl={2} lg={2} md={2} sm={2} xs={6} style={{display:'flex', flexDirection: 'row-reverse'}} >
        <p className={classes.timeLabel} ><b>TIME PERIOD</b></p>
        <Tooltip title={message}>
          <InfoIcon className={classes.info} />
        </Tooltip>
        </Grid>

        </Grid>
      </div>
    );
  }
}

DateRange.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any),
  // startDate: PropTypes.string,
  // endDate: PropTypes.string,
  handleRangeChange: PropTypes.func,
  handleQuickOptionsChange: PropTypes.func,
  selectedQuickOption: PropTypes.number,
};

DateRange.defaultProps = {
  classes: {},
  // startDate: '',
  // endDate: '',
  handleRangeChange: () => { },
  handleQuickOptionsChange: () => { },
  selectedQuickOption: 0,
};

export default withStyles(styles)(DateRange);
