import React, {useState, useEffect, useContext} from 'react';
import { withStyles } from '@mui/styles';
import { AgGridReact } from 'ag-grid-react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import UserContext from 'Context/UserContext';
import PropTypes from 'prop-types';

const styles = {
  headerClass: {
    backgroundColor: '#008081',
    borderRight: '1px solid white!important',
    textAlign: "center",
    fontFamily:'Montserrat',
    fontSize: "14px",
    color: 'white',
    '&:hover': {
      backgroundColor: '#008081!important',
    },
  },
};

function WarehousesGrid({
  classes, rowData, onSelectionChanged, onClick,selectedRowData,searchvalue
}) {
  /* const gridOptions = {
    // rowHeight: 100,
    suppressRowClickSelection: true,
    onCellClicked: (e) => {
      if (e.column.colId !== 'chambers') { // cell is from non-select column
        // console.log("e.node",e.node);
        e.node.setSelected(e.node.selected !== true);
      }
    },
  }; */


  const companyDetails = useContext(UserContext);
  const { CompanyInfo } = companyDetails;

  function customButtonRenderer(params) {
    return (
      <Tooltip title={ CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.chamberType ? 
        "View " + CompanyInfo.settings.chamberType + "(s)"
      : "View Chamber(s)"} 
       placement="right-end"> 
      <div>
      
        <span>{params.data && params.data.assets ? params.data.assets.length : 0}</span>
        <IconButton onClick={() => { onClick(params.data); }}>
        <ArrowForwardIosIcon />
        </IconButton>
       
      </div>
      </Tooltip>
    );
  }

  const [gridApi, setGridApi] = useState(null);


  

 

  const onGridReady = (params) => {
    // const gridApi = params.api;
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };

  const handleSelectionChanged = () => {
    if (gridApi) {
      onSelectionChanged(gridApi.getSelectedRows());
    }
  };

  const onSearch = () => {
    if (gridApi) {
      gridApi.setQuickFilter(searchvalue);
    }
  };

  const handleSelectionCleared = () => {
    if (selectedRowData.length === 0 && gridApi) {
      gridApi.deselectAll();
    }
  };

  useEffect(() => {
    if(selectedRowData){
      if (!selectedRowData.length) {
        handleSelectionCleared();
      }
    }
    
  }, [selectedRowData && selectedRowData.length]); // selectedRowData


  useEffect(() => {
    onSearch();
  }, [searchvalue]);



  const columnDefs = [{
    headerName:CompanyInfo && CompanyInfo.settings.warehouseType ? (CompanyInfo.settings.warehouseType + " ID") : "Warehouse Id",
    // headerName:"WAREHOUSE ID",
    field: 'id',
    sortable: true,
    filter: true,
    minWidth: 200,
    checkboxSelection: true,
    headerTooltip: CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.warehouseType && CompanyInfo.settings.warehouseType.length > 32 ?  CompanyInfo.settings.warehouseType + " ID" : '',
    tooltipField: 'id',
  }, {
    headerName: 'Address',
    field: 'note',
    minWidth: 700,
    cellClass: 'cell-wrap-text',
    autoHeight: true,
  }, {
    headerName: CompanyInfo &&  CompanyInfo.settings && CompanyInfo.settings.chamberType ? (CompanyInfo.settings.chamberType) : 'Chambers',
    // field: 'chambers',
    minWidth: 150,
    maxWidth: 150,
    pinned: 'right',
    cellRendererFramework: customButtonRenderer,
    headerTooltip: CompanyInfo &&  CompanyInfo.settings && CompanyInfo.settings.chamberType &&  CompanyInfo.settings.chamberType.length > 13 ?  CompanyInfo.settings.chamberType : '',
    // tooltipField: 'chambers',
  }];

  const gridOptions = {
    tooltipShowDelay: 0,
    // tooltipHideDelay: 0,
  };

  const defaultColDef = {
    sortable: false,
    filter: false,
    resizable: true,
    suppressMovable: true,
    cellStyle: { textAlign: "left",  fontFamily:'Montserrat',
    fontSize: "14px", },
    headerClass: classes.headerClass,
   
  };
  // src="./../img/horizontalLoader.svg"
  const overlayLoadingTemplate = '<span class="ag-overlay-loading-center"><img src="./../img/horizontalLoader.svg" /></span>';
  const overlayNoRowsTemplate = '<img src="./../img/emptybox.png" />';
  const rowSelection = 'multiple';
  return (
    <div
      className="ag-theme-material"
    >
      <AgGridReact
        // columnDefs={columnDefs}
        // defaultColDef={defaultColDef}
        // rowData={rowData}
        // // rowSelection={rowSelection}
        // // onSelectionChanged={onSelectionChanged}
        // // rowMultiSelectWithClick="true"
        // onGridReady={onGridReady}
        // pagination
        // paginationPageSize={10}
        // suppressRowClickSelection
        gridOptions={gridOptions}
        // enableCellTextSelection
        // domLayout="autoHeight"
        // overlayLoadingTemplate={overlayLoadingTemplate}
        // overlayNoRowsTemplate={overlayNoRowsTemplate}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        rowData={rowData}
        rowSelection={rowSelection}
        onSelectionChanged={handleSelectionChanged}
        rowMultiSelectWithClick="true"
        onGridReady={onGridReady}
        pagination
        paginationPageSize={10}
        domLayout="autoHeight"
        overlayLoadingTemplate={overlayLoadingTemplate}
        overlayNoRowsTemplate={overlayNoRowsTemplate}
      />
    </div>
  );
}

WarehousesGrid.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  rowData: PropTypes.instanceOf(Object),
  // onGridReady: PropTypes.func.isRequired,
  onSelectionChanged: PropTypes.instanceOf(Object),
  onClick: PropTypes.func,
};


WarehousesGrid.defaultProps = {
  rowData: null,
  onSelectionChanged: [],
  onClick: () => {},
};

export default withStyles(styles)(WarehousesGrid);
