/* eslint-disable react/prop-types */
import React, {useContext, useEffect} from "react";
import { withStyles } from "@mui/styles";
import Grid from '@mui/material/Grid';
import { Typography } from "@mui/material";
import Input from "components/Input";
import InfoIcon from '@mui/icons-material/Info';
import UserContext from 'Context/UserContext';
import {  Tooltip } from '@mui/material';
// import PrimaryButton from "components/PrimaryButton";

const styles={

}

const DashboardSettings =(props)=>{
 const {companysettingsState, companysettingsDispatchFn }= props;

 const { warehouseType , chamberType} = companysettingsState;

 const { CompanyInfo } = useContext(UserContext);

 useEffect(() => {
  if (CompanyInfo && CompanyInfo.settings) {
    
    companysettingsDispatchFn({ type: "WAREHOUSENAME", payload:CompanyInfo.settings.warehouseType || ''});
    companysettingsDispatchFn({ type: "CHAMBERNAME", payload:CompanyInfo.settings.chamberType || ''});
   
    
  }
}, [CompanyInfo]);


const titleCase=(str)=>{
   return str.toLowerCase().split(' ').map(function(word) {
    return (word.charAt(0).toUpperCase() + word.slice(1));
  }).join(' ');
}

 const handleWarehousename = (event) => {
  // setWarehouseType(event.target.value);
  
  const value1 = event.target.value;
  //function to convert input string into title case.
const convertToTitleCase1= titleCase(value1);
  companysettingsDispatchFn({ type: "WAREHOUSENAME", payload:  convertToTitleCase1});
};

const handleChambername = (event) => {
  // setChamberType(event.target.value);
  const value2=event.target.value;
  const convertToTitleCase2= titleCase(value2);
  companysettingsDispatchFn({ type: "CHAMBERNAME", payload: convertToTitleCase2 });
};



return(
    <>


        <Grid
                container
                item
                spacing={2}
                xs={12}
                lg={12}
                md={12}
                sm={12}
                alignItems="center"
                justifyContent="center"
              >
                <Grid container item  alignItems="center" >
                  <Grid item xl={2} lg={3} md={3} sm={4} xs={12} >
                  <Typography component="span" variant="p"  
                  sx={{
                    fontFamily: "Montserrat",
                    textAlign:'center',
                    fontSize: "16px",
                   
                  }} >
               Asset Group Name :
                    </Typography>
                  </Grid>
                  <Grid item xl={3} lg={3} md={4} sm={5} xs={9}>
                    <Input
                      id="name"
                      backgroundColor='#f2f2f2'
                      placeholder="Type something "
                      type="string"
                      margin="normal"
                      value={warehouseType}           
                      onChange={(event) => { handleWarehousename(event); }}
                    />
                  </Grid>
                  <Grid item xl={3}  lg={3} md={3} sm={2} xs={3} >
                  <Tooltip title="You can customise the field" placement="bottom-start">
                  <InfoIcon  style={{color:'#008081',  marginTop:'0.7rem', padding:'0.3rem'}}/>
                  </Tooltip>
                  </Grid>
                </Grid>

                <Grid container item   alignItems="center" >
                  <Grid item  xl={2} lg={3} md={3} sm={4} xs={12} >
                  <Typography component="span" variant="p"  
                  sx={{
                    fontFamily: "Montserrat",
                    textAlign:'left',
                    fontSize: "16px",
                    
                  }} >
               Asset Name :
                    </Typography>
                  </Grid>
                  <Grid item  xl={3} lg={3} md={4}  sm={5} xs={9}>
                    <Input
                      id="name"
                      placeholder="Type something "
                      backgroundColor='#f2f2f2'
                      type="string"
                      margin="normal"
                      value={chamberType}
                      onChange={(event) => { handleChambername(event); }}
                    />
                  </Grid>
                  <Grid item xl={2} lg={3} md={3} sm={2} xs={3} >
                  <Tooltip title="You can customise the field" placement="bottom-start">
                  <InfoIcon style={{color:'#008081', marginTop:'0.7rem', padding:'0.3rem'}} />
                  </Tooltip>
                  </Grid>
                </Grid>

                  {/* <Grid container item >
                    <Grid item xs={9} />
                   <Grid item xs={3} style={{justifyContent:'flex-end'}}>
                       <PrimaryButton text="Update Changes " />
                       </Grid>   
                  </Grid> */}
              </Grid> 



    </>
)
};

export default withStyles(styles)(DashboardSettings);