/* eslint-disable react/prop-types */
import React from 'react';
import 'flatpickr/dist/themes/dark.css';
import Flatpickr from 'react-flatpickr';
import { withStyles } from '@mui/styles';
// import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
// import { useMediaQuery, createTheme } from '@material-ui/core';
import moment from 'moment';

const styles = {
  Flatpickr: {
    width: '240px',
    fontSize:'11px',
    borderRadius: '22px',
    textAlign: 'center',
    // boxShadow: '5px 10px 15px  rgb(136, 136, 136, 0.5)',
    boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
    border: 'none',
    height: '2rem',
    margin: 'auto',
  },
  // '@media screen and (orientation: portrait)': {
  //   Flatpickr: {
  //     width: '220px',
  //     borderRadius: '22px',
  //     textAlign: 'center',
  //     // boxShadow: '5px 10px 15px  rgb(136, 136, 136, 0.5)',
  //     boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
  //     border: 'none',
  //     height: '2rem',
  //     margin: 'auto',
  //   },
  // },
 
 
  selectionBox: {
    background: 'none',
    // width: '20%',
    border:'none',
    height: '35px',
    borderRadius: '30px',
    outline: 'none',
    '&:focus': {
      border: '2px solid #00adef',
    },
    margin: '5px 10px',
    display: 'flex',
    flexWrap: 'wrap',
    fontSize:'10px'
  },
  selectText: {
    fontSize: '0.9rem !important',
    fontFamily: 'Montserrat !important',
    margin: 'auto !important',
    paddingTop:'5px !important'
  },
  // selectTextmd: {
  //   fontSize: '5px',
  //   fontFamily: 'Montserrat',
  //   margin: 'auto',
  //   paddingTop:'5px'
  // },
  timeLabel: {
    fontSize: '12px !important',
    fontFamily: 'Montserrat !important',
    marginRight: '10px !important',
  },
  '@media screen and (max-width: 1200px)' :{
    Flatpickr:{
      width: '200px',
      fontSize:'9px',
      borderRadius: '22px',
      textAlign: 'center',
      // boxShadow: '5px 10px 15px  rgb(136, 136, 136, 0.5)',
      boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
      border: 'none',
      height: '2rem',
      margin: 'auto',
    },
    selectText:{
      fontSize: '12px !important',
      fontFamily: 'Montserrat !important',
      margin: 'auto !important',
      padding: "0.2rem !important"

    },
    timeLabel:{
      fontSize: '10px !important',
      fontFamily: 'Montserrat !important',
      marginRight: '10px !important',
    }
   },
};

// const theme = createTheme({
//   breakpoints: {
//     values: {
//       xs: 0,
//       sm: 600,
//       md: 900,
//       lg: 1200,
//       xl: 1536,
//     },
//   },

// });
// const styles = (theme) => ({
//   selectText: {
//     fontSize: '8px',
//     fontFamily: 'Montserrat',
//     margin: 'auto',
//     paddingTop:'5px',
//     [theme.breakpoints.values('lg')]: {
//       fontSize: '4px',
//     },
//   },
// });
const DateAndTimeRangePicker=(props)=> 
{
  const {classes, date, onChange, handleQuickOptionsChange, selectedQuickOption, minDate,disableMenu,singleChamberValue}=props
  const menuItems = [
    // {
    //   type: 'Custom',
    //   value:0
    // },
  {
    type: 'Today',
    value:1
  },
  {
    type: 'Last 15 min',
    value:1.5
  },

  {
    type: 'Yesterday',
    value:2
  },
  {
    type: 'Last Week',
    value:7
  },
  {
    type: 'Last 15 Days',
    value:15
  },
  {
    type: 'Last Month',
    value:30
  }
]
 
if (singleChamberValue) {
  menuItems.push({ type: 'Last 3 Months', value: 92 });
}
  

  //  const mdbreakpoint = useMediaQuery(theme.breakpoints.up('md'));
  //  const smbreakpoint= useMediaQuery(theme.breakpoints.up('sm'));
  //  const lgbreakpoint= useMediaQuery(theme.breakpoints.up('lg'));
// console.log('mdbreakpoint', mdbreakpoint,lgbreakpoint)
  return (
    <div style={{ 
      // display: 'flex', justifyContent: 'flex-start', 
      // alignContent: 'center', alignItems: 'center'
      textAlign:'right'
       }}>
     {
      !disableMenu ?
      <FormControl
      className={classes.selectionBox}
      >
       <Select
       variant='standard'
       className={classes.selectText}
         disableUnderline
         value={selectedQuickOption}
         onChange={(e) => { if (handleQuickOptionsChange) handleQuickOptionsChange(Number(e.target.value)); }}
       >
         <MenuItem value={0}  
           className={classes.timeLabel}
          disabled>
           Custom
           </MenuItem>
         {
           menuItems.map((menu)=>(
             <MenuItem key={menu.value} value={menu.value} 
             className={classes.timeLabel}
               >{menu.type}</MenuItem>
           ))
         }
        
       </Select>
     </FormControl>

      :''
     }
     
      <Flatpickr
        data-enable-time
        value={date}
        onChange={onChange}
        className={classes.Flatpickr}
        data-time_24hr={true}
        options={{
          mode: disableMenu && !disableMenu ? 'single' :'range',
          enableTime: true, minDate, 
         //maxDate:new Date(),
           // minDate: date && date[0],
          // maxDate: new Date(Math.min(new Date(date[0].getTime() + 30 * 24 * 60 * 60 * 1000), new Date())),
          maxDate:  moment.min(moment(date && date[0]).add(singleChamberValue ? 92 : 31, 'days'),moment()).toDate(),
          altFormat: 'j M y, H:i K', altInput: true, defaultHour: 0,
        }}
        
      />
    </div>
  );
}

export default withStyles(styles)(DateAndTimeRangePicker);
