import React from "react"
import { Typography, } from "@mui/material";
import { withStyles } from "@mui/styles";
// import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import Grid from "@mui/material/Grid";
// import GppGoodIcon from '@mui/icons-material/GppGood';
// import GppMaybeIcon from '@mui/icons-material/GppMaybe';

const styles = {
    devicenameDiv:{
        // borderLeft:'1px solid rgba(169,208,208)',
        borderRight:'1px solid rgba(169,208,208)',
        padding:'1rem'
    
    
      },
      selectedStyles: {
        backgroundColor:'#fff',
        borderRadius: '8px',
        // height: '8rem',
        border: '1px solid #00808161',
        // width: '500px',
        fontsize: '500px  !important',
        cursor: "pointer",
        marginBottom:'1rem',
        '&:hover':{
          boxShadow:'1px 2px 2px 0px rgb(0 0 0 / 20%)'
        }
      },
      deselectedStyles: {
        backgroundColor:'#fff',
        marginBottom:'1rem',
        borderRadius: '8px',
        // height: '8rem',
        border: '2px solid #008081',
        // width: '500px',
        fontsize: '500px  !important',
        cursor: "pointer",
        boxShadow: "2px 7px 7px 0px rgb(0 0 0 / 20%)"
      },
};



const DeviceCard=(props)=>{
    // console.log('dev', props)
    const {device, selected,onclick,classes, 
    //    temperatureSettings,batterySettings,
    // humiditySettings
  }=props


    // const handleDeviceStatus=(device)=>{
    //   let deviceStatus ='noViolation' ;
    
    //   const pingLastObj= device && device.data && device.data.length > 0 ?  device.data[device.data.length - 1]  : undefined
    //   const tempLastPing=  pingLastObj && pingLastObj.t1 && pingLastObj.t1[pingLastObj.t1.length - 1]
    //   const humidityLastPing=pingLastObj && pingLastObj.humidity && pingLastObj.humidity[pingLastObj.humidity.length - 1]
    //   const batteryLastPing=pingLastObj && pingLastObj.battery
    //   // console.log('pingLastObj',device,pingLastObj,tempLastPing, humidityLastPing )
    //   // const tempLastPing=Number( device && device.lastPing && device.lastPing.t1 && device.lastPing.t1[device.lastPing.t1.length - 1] );
    //   // const humidityLastPing=Number( device && device.lastPing && device.lastPing.humidity && device.lastPing.humidity[device.lastPing.humidity.length - 1]);
    //   // const batteryLastPing=Number(device.lastPing && device.lastPing.battery);
     
    //   const maxTempVio =Number(temperatureSettings?.maxViolation);
    //   const minTempVio =Number(temperatureSettings?.minViolation);
    //   const maxHumVio=Number(humiditySettings?.maxViolation);
    //   const minHumVio =Number(humiditySettings?.minViolation);
    //   const criticalBattery=Number(batterySettings?.critical)
    
    //   const maxTempWarning =Number(temperatureSettings?.maxWarning);
    //   const minTempWarning =Number(temperatureSettings?.minWarning);
    //   const maxHumWarning=Number(humiditySettings?.maxWarning);
    //   const minHumWarning =Number(humiditySettings?.minWarning);
    //   const warningBattery=Number(batterySettings?.warning)
    
    //   const isVio=tempLastPing > maxTempVio
    //    || tempLastPing < minTempVio
    //    || humidityLastPing > maxHumVio
    //    ||  humidityLastPing < minHumVio
    //    || batteryLastPing < criticalBattery
    //    || batteryLastPing < 10
    
    //    const isWarned = tempLastPing > maxTempWarning 
    //    || tempLastPing < minTempWarning
    //    || humidityLastPing > maxHumWarning 
    //    || humidityLastPing < minHumWarning  
    //    || batteryLastPing < warningBattery 
    //    || batteryLastPing < 25 && batteryLastPing >= 10
    
    //    if(isVio){
    //     deviceStatus ='Violation'
    //   }
    //   if(!isVio && isWarned){
    //     deviceStatus = 'Warning'
    //   }
     
    //   return deviceStatus;
    
    // }
    


    return (
      <>
       <div  
            onClick={(e) => { onclick(device, e) }}
            className={(selected === true) ? classes.deselectedStyles : classes.selectedStyles} 
             key={device.id } 
            >
              <Grid container style={{padding:'0.3rem'}} >
                {/* <Grid item xs={2} style={{ margin: "auto" }}>
                {
      handleDeviceStatus(device) === "Violation" ?
    //  <Tooltip title={getTooltipTitle({lastUpdate:device.lastPing && device.lastPing.timeStamp })}>
      <GppMaybeIcon  sx={{ color: "#af4f4f", width: "40px", height: "40px" }} />
    //  </Tooltip> 
    
       :
       handleDeviceStatus(device) === "Warning" ? 
      //  <Tooltip title={getTooltipTitle({lastUpdate:device.lastPing && device.lastPing.timeStamp })}>
       <GppMaybeIcon  sx={{ color: "#dcac1980", width: "40px", height: "40px" }} /> 
      //  </Tooltip>
       : 
      //  <Tooltip title={getTooltipTitle({lastUpdate:device.lastPing && device.lastPing.timeStamp })}>
          <GppGoodIcon  sx={{ color: "#008081a1", width: "40px", height: "40px" }} /> 
        //  </Tooltip>
    }
                </Grid> */}
                <Grid item xs={10} className={classes.devicenameDiv}>
                  <Typography
                    variant="p"
                    sx={{
                      margin: "auto",
                      fontSize: "14px",
                      fontFamily: "Montserrat",
                      color: "rgba(0,0,0,0.6)",
                    }}
                  >
                    <span
                      style={{ fontSize: "14px", color: "rgba(0,0,0,0.5)" }}
                    >
                      Device Id :
                    </span>
                    {device.deviceId}
                  </Typography>
                </Grid>
                <Grid item xs={2} style={{ textAlign: "center" }}>
                  <img
                    src="../../img/battery.svg"
                    alt="empty"
                    style={{ width: "3rem", height: "2rem" }}
                  />
                  <Typography
                    variant="p"
                    sx={{ fontSize: "14px", color: "rgba(0,0,0,0.7)" }}
                  >
                   

                    {
                      device && device.data && device.data.length > 0 ?
                      device.data[device.data.length - 1].battery + "%"
                      :"N/A"
                    }
                    {/* {device?.lastPing?.battery
                      ? device.lastPing.battery + "%"
                      : "N/A"} */}
                  </Typography>
                </Grid>
              </Grid>
              </div>
      </>
    )
  }


  export default withStyles(styles)(DeviceCard);