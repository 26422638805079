import { withStyles } from "@mui/styles";

import React, { useState, useContext } from "react";
import UserContext from "Context/UserContext";
// import data from "./data";
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { Typography } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import { Tooltip } from '@mui/material';
// import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import { CircularProgress } from '@mui/material';
// import SmartAlertTest from "./SmartAlertTest";
import { useMediaQuery, createTheme } from '@material-ui/core';
import cogoToast from 'cogo-toast';

const styles = {
    imgGrid: {
        marginTop: '10%',
        marginLeft: '35%'
    },
    headertext: {
        color: '#fff',

    },
    headerDiv: {
        backgroundColor: '#008081',
        height: '3rem',
        color: '#fff',
        fontWeight: 'bold',
        fontSize: '14px',
        margin: 'auto'
    },
}

const GoogleNestTable = (params) => {
    const { classes, selectedRow, onRowCheckboxClick, isLoading, rowData, edit, nestSetupDialog, handleSelectionClear, /*deviceList */ } = params;
    const contextDetail = useContext(UserContext);
    const { CompanyInfo } = contextDetail;
    // const [myValue, setMyValue]= useState(false);
    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1536,
            },
        },
    });


    const smBreakPoint = useMediaQuery(theme.breakpoints.up('md'));

    const xsBreakPoint = useMediaQuery(theme.breakpoints.up('sm'));

    const handleRowSelect = (params) => {
        // console.log('Row', params)
        const { row, value } = params;
        const { checked } = value.target;

        if (checked) {
            if (edit || nestSetupDialog) {
                cogoToast.error('Close the dialog first', {
                    position: 'top-right',
                });
            } else {
                onRowCheckboxClick([...selectedRow, row])
            }



        }
        else {
            //close both edit or nest info dialog if unchecked
            handleSelectionClear()
            //remove selected row from selectedRow array..
            const arr = [...selectedRow]
            const index = selectedRow.findIndex((s) => s === row)

            if (index > -1) {
                const tableRow = arr[index]
                if (tableRow) { arr.splice(index, 1) }
                // setSelectedRow(arr)
                onRowCheckboxClick(arr)
            }
        }


    }

    function handleChcount(array) {
        let sum = 0;

        array.forEach(item => {
            sum += item;
        });
        return sum;
    }

    const handleChambercount = (assetGroup) => {

        const chambercountArray = assetGroup.map((ag) => {
            let assetcount;
            let assetArray = [];
            ag.assets && ag.assets.length && ag.assets.map((ast) => {
                if (ast.isSmartUserAssigned) {
                    assetArray.push(ast)

                    assetcount = assetArray.length;

                }
            })


            return assetcount
        }).filter(ag => ag)


        const count = handleChcount(chambercountArray)

        return count;
    }


   



    function RowCom(props) {
        const [open, setOpen] = useState(false)

        const { row, } = props;
        // console.log('row ', row,)
        const handleCollapseOpen = () => {

            setOpen(!open);

            //   setMyValue(!myValue)
        }

        return (
            <>
                <Grid container style={{ borderBottom: '2px solid rgba(0,0,0,0.2)', padding: '0.4rem' }} >
                    <Grid
                        item xl={1} lg={1} md={1} sm={1} xs={1}
                    >
                        {/* checkbox */}
                        <Checkbox
                            style={{ color: '#008081' }}

                            checked={selectedRow && selectedRow.length && selectedRow.some((sel) => sel.emailId === row.emailId)}
                            onChange={(e) => handleRowSelect({ value: e, row: row })}
                            inputProps={{
                                'aria-label': 'select all users',
                            }}
                        />
                    </Grid>
                    <Grid
                        item xl={4} lg={4} md={4} sm={5} xs={5}
                        sx={{ mt: '.6rem', wordBreak: 'break-all' }}
                    >
                        {/* email Id */}
                        <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', }} >

                            {row.emailId}
                        </Typography>

                    </Grid>
                    <Grid
                        item xl={6} lg={6} md={6} sm={4} xs={4}
                        sx={{ m: 'auto' }}
                    >
                        {
                            open ?
                                (
                                    smBreakPoint ?
                                        row && row.assetGroups && row.assetGroups.length && row.assetGroups ?
                                        row.assetGroups.map((ag) => (
                                            <Grid key={ag.uid} container style={{ paddingBottom: '1rem' }} >
                                                <Grid key={ag.uid} item xs={6} sx={{ pl: '2rem', color: 'rgba(0,0,0,0.6)', fontSize: '14px', padding: '0rem' }} >

                                                    {ag.id}
                                                </Grid>

                                                <Grid item xs={6}>


                                                    {
                                                        ag.assets && ag.assets.length ?
                                                            ag.assets.map((ast) => {
                                                                if (ast.isSmartUserAssigned) {
                                                                    return (
                                                                        <Grid key={ast.id} sx={{ pl: '2rem' }} >
                                                                            <Typography sx={{ fontSize: '14px', fontFamily: 'Montserrat', color: 'rgba(0, 0, 0, 0.6)' }}  >
                                                                                {ast.id}
                                                                                </Typography>
                                                                        </Grid>
                                                                    )
                                                                }

                                                            }
                                                            )
                                                            :
                                                            'No chambers found'
                                                    }
                                                </Grid>
                                            </Grid>

                                        ))
                                        :' '
                                        : ' '
                                )


                                :
                                <Grid container >
                                    {
                                        xsBreakPoint && (
                                            <>
                                                <Grid
                                                    item xl={6} lg={6} md={6} sm={6} xs={3}
                                                >
                                                    <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', paddingLeft: smBreakPoint ? "3rem" : '1rem', }} >

                                                        {row && row.assetGroups && row.assetGroups.length}
                                                    </Typography>

                                                </Grid>
                                                <Grid
                                                    item xl={6} lg={6} md={6} sm={6} xs={3}
                                                >
                                                    <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', paddingLeft: '5rem' }} >

                                                        {handleChambercount(row.assetGroups)}
                                                    </Typography>
                                                </Grid>
                                            </>
                                        )
                                    }

                                </Grid>
                            // 
                        }

                    </Grid>
                    <Grid
                        item xl={1} lg={1} md={1} sm={1} xs={1}
                    >
                        {/* arrow */}
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            // onClick={() => {
                            //     setOpen(!open);
                            //     setManisha(!manisha)
                            // }}
                            onClick={handleCollapseOpen}

                        >
                            {open ?
                                <Tooltip title="Hide detail" >
                                    <KeyboardArrowUpIcon sx={{ fontSize: '2rem', color: 'rgba(0,0,0,0.6)' }} />
                                </Tooltip> :
                                <Tooltip title="View detail" >
                                    <KeyboardArrowDownIcon sx={{ fontSize: '2rem', color: 'rgba(0,0,0,0.6)' }} />
                                </Tooltip>}
                        </IconButton>
                    </Grid>
                    {/* to show wh and ch below email id */}
                    {!smBreakPoint && open && (
                        <>

                            <Typography sx={{ color: '#008081', fontSize: '16px', pt: '1rem' }}>
                                {CompanyInfo && CompanyInfo.settings &&
                                    CompanyInfo.settings.warehouseType ?
                                    CompanyInfo.settings.warehouseType + " Assigned" :
                                    "Warehouse Assigned"}

                            </Typography>
                            {row && row.assetGroups && row.assetGroups.length && row.assetGroups ?
                                row.assetGroups.map((ag) => (
                                    <Grid key={ag.uid} container style={{ padding: '0.5rem' }} >

                                        <Grid key={ag.uid} item xs={12} sx={{ pl: '2rem', color: '#008081', fontSize: '14px', padding: '0rem' }} >

                                            {ag.id}
                                        </Grid>

                                        <Grid item xs={12}>

                                            {ag && ag.assets && ag.assets.length ?
                                                ag.assets.map((ast) => (
                                                    <>
                                                        <Grid key={ast.uid} sx={{ color: 'rgba(0,0,0,0.6)', fontSize: '14px' }}  >
                                                            - {ast.id}
                                                        </Grid>
                                                    </>
                                                ))

                                                :
                                                "No Chambers"}
                                        </Grid>
                                    </Grid>

                                ))
                            :
                            <Grid container sx={{ color: 'rgba(0,0,0,0.6)', fontSize: '14px' }}>
                             
                                {CompanyInfo && CompanyInfo.settings &&
                                    CompanyInfo.settings.warehouseType ?
                                   "No " + CompanyInfo.settings.warehouseType + " found" :
                                    "No Warehouse found"}
                                </Grid>}
                        </>
                    )}

                </Grid>
            </>
        )
    }

    return (<>
        {
            isLoading ?
                <>
                    <div
                        style={{
                            position: "fixed",
                            display: "block",
                            width: "100%",
                            height: "100%",
                            top: "0",
                            left: "0",
                            right: "0",
                            bottom: "0",
                            backgroundColor: "rgba(0,0,0,0.5)",
                            zIndex: "2",
                        }} >
                        <CircularProgress
                            style={{ position: 'absolute', top: '50%', left: '50%' }}
                        />
                    </div>
                </>
                :
                <>
                    <Grid>
                        {/* start */}

                        {/* end */}


                        {/* <div style={{  overflowX: 'auto',
                      overflowY: 'auto',
                   
                      display:'grid',gridTemplateColumns:`repeat(5, 50rem)`,
                     }} > */}
                        <Grid component={Paper}
                            style={{ height: '70vh', overflow: 'auto', }} >
                            <Grid
                                style={{ display: 'flex', }}
                                container
                                className={classes.headerDiv} >
                                <Grid
                                    // style={{width:'50rem',}}
                                    item xl={1} lg={1} md={1} sm={1} xs={1}
                                />
                                <Grid
                                    // style={{width:'50rem',}}
                                    item xl={4} lg={4} md={4} sm={5} xs={10}
                                    sx={{ m: 'auto' }}
                                >
                                    Email Id
                                </Grid>
                                {xsBreakPoint && (
                                    <>
                                        <Grid
                                            // style={{width:'50rem'}}
                                            item xl={3} lg={3} md={3} sm={3} xs={3}
                                            sx={{ m: 'auto' }}
                                        >
                                            {/* <Tooltip 
                                    title={CompanyInfo?.settings?.warehouseType ? CompanyInfo.settings.warehouseType + " Assigned" : ""}
                                    > */}


                                            {CompanyInfo && CompanyInfo.settings &&
                                                CompanyInfo.settings.warehouseType ?
                                                (CompanyInfo.settings.warehouseType.length > 11 ? CompanyInfo.settings.warehouseType.slice(0, 11) + "..." : CompanyInfo.settings.warehouseType + " Assigned")
                                                :
                                                " Warehouse Assigned"}

                                            {/* </Tooltip> */}
                                        </Grid>
                                        <Grid
                                            // style={{width:'20rem'}}
                                            item xl={3} lg={3} md={3} sm={3} xs={3}
                                            sx={{ m: 'auto' }}
                                        >
                                            <Tooltip
                                                title={CompanyInfo?.settings?.chamberType ? CompanyInfo.settings.chamberType + " Assigned" : ""}
                                            >

                                                <span>
                                                    {CompanyInfo && CompanyInfo.settings &&
                                                        CompanyInfo.settings.chamberType ?
                                                        (CompanyInfo.settings.chamberType.length > 10 ? CompanyInfo.settings.chamberType.slice(0, 10) + "..." : CompanyInfo.settings.chamberType + " Assigned")
                                                        :
                                                        "Chambers Assigned"}
                                                </span>
                                            </Tooltip>
                                        </Grid>
                                    </>
                                )}

                                <Grid
                                    // style={{width:'20rem'}}
                                    item xl={1} lg={1} md={1} sm={1} xs={1}
                                />
                            </Grid>
                            {
                                rowData && rowData.length ?

                                    rowData.map((row) => (
                                        <RowCom key={row.id} row={row} />
                                    ))

                                    :
                                    <img src="../../img/emptybox.png" alt="empty" className={classes.imgGrid}></img>}

                        </Grid>





                    </Grid>
                </>

        }

    </>)
}

export default withStyles(styles)(GoogleNestTable);


// {
//     rowData && rowData.length ?
//         <TableContainer component={Paper} 
//         style={{ height: '70vh' }}
//          >
//             <Table aria-label="collapsible table">
//                 <TableHead style={{ backgroundColor: '#008081', color: '#fff', }}>
//                     <TableRow >
//                         <TableCell />
//                         <TableCell align="left" style={{ color: '#fff', fontWeight: 'bold', fontFamily: 'Montserrat', fontSize: '14px' }} >Email Id</TableCell>
//                         <TableCell align="left" style={{ color: '#fff', fontWeight: 'bold', fontFamily: 'Montserrat', fontSize: '14px' }}> Warehouse Assigned</TableCell>
//                         <TableCell align="left" style={{ color: '#fff', fontWeight: 'bold', fontFamily: 'Montserrat', fontSize: '14px' }}>Chambers Assigned</TableCell>
//                         <TableCell />
//                     </TableRow>
//                 </TableHead>
//                 <TableBody>
//                     {
//                         rowData.map((row) => (
//                             <Row key={row.id} row={row} />
//                         ))
//                     }

//                 </TableBody>
//             </Table>
//         </TableContainer>
//         :
//         <>
//             <Grid>
//                 <Grid container className={classes.headerDiv}  >
//                     <Grid item xs={1} />
//                     <Grid item xs={3} style={{ margin: 'auto', fontFamily: 'Montserrat', fontSize: '14px' }}  >Email Id</Grid>
//                     <Grid item xs={4} style={{ margin: 'auto', fontFamily: 'Montserrat', fontSize: '14px' }}>Warehouse Assigned</Grid>
//                     <Grid item xs={3} style={{ margin: 'auto', fontFamily: 'Montserrat', fontSize: '14px' }}>Chamber Assigned</Grid>
//                     <Grid item xs={1} />
//                     {/* <Grid item  xs={4}style={{margin:'auto', fontFamily:'Montserrat', fontSize:'14px'}} >View Detail</Grid> */}
//                 </Grid>
//                 <img src="../../img/emptybox.png" alt="empty" className={classes.imgGrid}></img>
//                 {/* <div>No end user found,To add end user, click on Add User button on top...</div> */}
//             </Grid>
//         </>

// }