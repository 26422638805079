import React from 'react';
import { withStyles } from '@mui/styles';
import { Tooltip } from '@mui/material';
import { Search } from '@mui/icons-material';



const styles = {
  searchInputDiv: {
    width: '80%',
    minHeight: '34px',
    // minWidth: '15%',
    paddingTop: '8px',
    position: 'relative',
    border:'none'
  },
  searchInput: {
    // border: '1px solid #fff',
    borderRadius: '50px',
    border:'none',
    background: '#ffff',
    width: '100%',
    height: '34px',
    boxSizing: 'border-box',
    fontSize:'14px',
    padding: '0 15px',
    fontFamily:'Montserrat',
    "&:focus": {
      outline: 'none'
      
    },
  },
  searchIcon: {
    position: 'absolute',
    right: '12px',
    top: '12px',
    color: '#6d6d6d',
  },
};

const SearchOpt = (props) => {
  const {
    classes, id, value, onChange, placeholder,warehouseType,chamberType
  } = props;

const handleTooltip=()=>{
  
  if(warehouseType && chamberType ){
    return `Search by ${chamberType} or ${warehouseType} Id`
  }
  if(chamberType &&  !warehouseType){
   return `Search by ${chamberType} or Warehouse Id`
  }
  if(!chamberType &&  warehouseType){
   return `Search by Chamber or ${warehouseType} Id`
  }
  return "Search by Warehouse or Chamber Id"

}
  
  return (
    <div className={classes.searchInputDiv}>
     <Tooltip title={handleTooltip()}>
        <div>
      <input
        type="string"
       autoComplete='off'
        id={id}
        value={value}
        onChange={onChange}
        className={classes.searchInput}
        placeholder={placeholder}
      />
      <Search
  
        className={classes.searchIcon}
      />
      </div>
      </Tooltip>
    </div>
  );
};




export default withStyles(styles)(SearchOpt);