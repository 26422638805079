/* eslint-disable react/prop-types */
import React, { useState, useContext, useEffect } from "react";
import { withStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
// import Input from "components/Input";
import InfoIcon from "@mui/icons-material/Info";
import UserContext from "Context/UserContext";
import { Tooltip } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import SelectField from "components/SelectField";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import AutoField from "components/AutoField";
import API from "API/apiConfig";
// import PrimaryButton from "components/PrimaryButton";

const styles = {};

const APIURL = process.env.REACT_APP_APIURL;

const ITEM_HEIGHT = 30;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP,
    },
  },
};

const selectPeriodOptions = [
  {
    id: "None",
    value: "none",
    disabled: false,
  },
  {
    id: "Daily",
    value: "daily",
    disabled: false,
  },
  {
    id: "Weekly",
    value: "weekly",
    disabled: false,
  },
  {
    id: "Monthly",
    value: "monthly",
    disabled: false,
  },
];

const PeriodicReportSettings = (props) => {
  const { companysettingsDispatchFn,companysettingsState } = props;
  const [periodValue, setPeriodValue] = useState("");
  const [companyUsersList, setCompanyUsersList] = useState([]);
  // const [users, setUsers] = useState([]);

  const { CompanyInfo } = useContext(UserContext);
  const {users} = companysettingsState;
  useEffect(() => {
    API.get(`${APIURL}/usersList`).then((usersResponse) => {
        const usersInfo = usersResponse.data.data;
        const usersList = usersInfo.map(user => user.uid);
        // console.log(usersList);
      setCompanyUsersList(usersList);
   
    });
  }, []);

  useEffect(() => {
    if (CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.reports) {
    //   console.log("settings", CompanyInfo.settings.users);
      setPeriodValue(CompanyInfo.settings.reports.period);
      companysettingsDispatchFn({
        type: "PERIOD",
        payload: CompanyInfo.settings.reports.period || "",
      });
        // setUsers(CompanyInfo.settings.reports.users);
      companysettingsDispatchFn({
        type: "USERSLIST",
        payload: CompanyInfo.settings.reports.users || [],
      });
    }
  }, [CompanyInfo]);

  const handlePeriodChange = (event) => {
    // console.log('sdjs', event.target.value)
    setPeriodValue(event.target.value);
    const value1 = event.target.value;
    companysettingsDispatchFn({ type: "PERIOD", payload: value1 });
  };

    const handleUsersChange = (event, value) => {
    // if (value.length) {
      // setUsers(value);
      companysettingsDispatchFn({ type: "USERSLIST", payload: value });
    // } else {
    //   setUsers([]);
    // }
  };

//   console.log("company users state", companysettingsState);

  return (
    <>
      <Grid
        container
        item
        spacing={2}
        xs={12}
        lg={12}
        md={12}
        sm={12}
        alignItems="center"
        justifyContent="center"
      >
        <Grid container item alignItems="center">
          <Grid item xl={2} lg={3} md={3} sm={4} xs={12}>
            <Typography
              component="span"
              variant="p"
              sx={{
                fontFamily: "Montserrat",
                textAlign: "center",
                fontSize: "16px",
              }}
            >
              Period :
            </Typography>
          </Grid>
          <Grid item xl={3} lg={3} md={4} sm={5} xs={9}>
            <FormControl
              variant="standard"
              sx={{ minWidth:"100%", textAlign: "left" }}
            >
              <SelectField
                labelId="demo-simple-select-label"
                id="demo-simple-select-standard"
                backgroundColor="#f2f2f2"
                value={periodValue ? periodValue :"none" }
                onChange={handlePeriodChange}
                MenuProps={MenuProps}
              >
                {selectPeriodOptions.map(({ id, value, disabled }) => (
                  <MenuItem disabled={disabled} key={id} value={value}>
                    {id}
                  </MenuItem>
                ))}
              </SelectField>
            </FormControl>
          </Grid>
          <Grid item xl={3}  lg={3} md={4} sm={2} xs={3}>
            <Tooltip
              title="You can customise the field"
              placement="bottom-start"
            >
              <InfoIcon
                style={{
                  color: "#008081",
                  marginTop: "0.7rem",
                  padding: "0.3rem",
                }}
              />
            </Tooltip>
          </Grid>
        </Grid>

        <Grid container item alignItems="center">
          <Grid item xl={2}  lg={3} md={3} sm={4} xs={12}>
            <Typography
              component="span"
              variant="p"
              sx={{
                fontFamily: "Montserrat",
                textAlign: "left",
                fontSize: "16px",
              }}
            >
              Users :
            </Typography>
          </Grid>
          <Grid item xl={3}  lg={3} md={4} sm={5} xs={9}>
            <AutoField
            style={{width:'98%', marginTop:'1em'}}
              disablePortal
              backgroundColor="#f2f2f2"
              multiple
              id="users"
              value={users}
              onChange={handleUsersChange}
              options={companyUsersList}
              getOptionLabel={option => option}
              filterSelectedOptions
              renderOption={(props, option) => (
                <li {...props} key={option}>
                        {option}
                    </li>
                    )}
              renderInput={(params) => <TextField {...params} label="Users" />}
            />
          </Grid>
          <Grid item xl={3}  lg={3} md={4} sm={2} xs={3}>
            <Tooltip
              title="Add the users to receive the report"
              placement="bottom-start"
            >
              <InfoIcon
                style={{
                  color: "#008081",
                  marginTop: "0.7rem",
                  padding: "0.3rem",
                }}
              />
            </Tooltip>
          </Grid>
        </Grid>

     
      </Grid>
    </>
  );
};

export default withStyles(styles)(PeriodicReportSettings);
