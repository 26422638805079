import React, { useState, useEffect } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';

const styles = {
  gridParams: {
    width: '100%',
    boxShadow: '1px 1.7px 7px 0 rgba(136, 156, 176, 0.1)',
    height: '500px',
  },
  headerClass: {
    backgroundColor: '#008081',
    color: 'white',
    fontFamily:'Montserrat',
    fontSize:'14px',
    '&:hover': {
      backgroundColor: '#008081!important',
    },
  },
  emptyStateDiv: {
    height: '300px',
  },
  rowIcon: {
    maxHeight: '3.5em',
    paddingLeft: '0.8em',
    // fontSize: props.fontSize,
  },
};




function LogsTable({
  classes, rowData, onSelectionChanged,downloadReport
//    isSearchGoingOn,
}) {
//   const rowSelection = 'single';
  const [gridApi, setGridApi] = useState(null);

  const domLayout = 'autoHeight';
  const overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Please wait while your logs are loading</span>';
  const overlayNoRowsTemplate = '<img src="../../img/emptybox.png" alt="No roles" />';

  const userFormatter = (params) => {
    if (
      params
      && params.data
      && Object.keys(params.data).length > 0
      && params.data.details
      && params.data.details.email
    ) {
      return params.data.details.email;
    }
    return "No Email Found";
  };

  const infoFormatter = (params) => {
    let resource;
    if (params && params.data && params.data.type && params.data.details) {
      if (params.data.details.permission) {
        resource = params.data.details.permission.split("#");
        if (
          resource[1] === "update"
          || resource[1] === "create"
          || resource[1] === "delete"
        ) {
          resource[1] = `${resource[1]}d`;
        }
      }
      switch (params.data.type) {
        case "LOGIN":
          return "User logged in";
        case "LOGOUT":
          return "User logged out";
        case "PERMISSION_TOKEN":
          return `User ${resource[1]} ${resource[0]}`;
        case "CODE_TO_TOKEN":
          return "User was Authorized";
        case "UPDATE_PASSWORD":
          return "User updated password";
        default:
          return params.data.type;
      }
    }
    return "-";
  };

  const timeFormatter = (params) => {
    if (params && params.data && params.data.time) {
      return moment(params.data.time).format("MMM Do YYYY, h:mm:ss a");
    }
    return "-";
  };

  

  const columnDefs = [
    {
      headerName: 'Action Type',
      sortable: true,
      filter: true,
      field: 'type',
      minWidth: 300,
    },
    {
      headerName: 'User',
      sortable: true,
      filter: true,
      field: 'details.email',
      minWidth: 350,
      valueFormatter: userFormatter,
    },
    {
      headerName: 'Info',
      sortable: true,
      minWidth: 400,
      valueGetter:infoFormatter,
      filter: true,
      valueFormatter: infoFormatter,
    },
    {
      headerName: 'Time',
      sortable: true,
      // field:'time',
      valueGetter: params => {
        return moment(params.data.time).format("MMM Do YYYY, h:mm:ss a");
      },
      filter: true,
      minWidth: 320,
      valueFormatter: timeFormatter,
    },
  ];
  const defaultColDef = {
    sortable: false,
    filter: true,
    suppressMovable: true,
    resizable: true,
    cellStyle: { textAlign: 'left', fontFamily:'Montserrat',
    fontSize:'14px', },
    headerClass: classes.headerClass,
  };
  const onGridReady = (params) => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };

  const handleSelectionChanged = () => {
    onSelectionChanged(gridApi.getSelectedRows());
  };



  useEffect(() => {
    if (gridApi) {
      gridApi.exportDataAsCsv();
    }
  }, [downloadReport]);

//   const noRowsTemplate = getEmptyOverlayTemplate(classes, isSearchGoingOn);
  return (
    <div className={`${classes.gridParams} ag-theme-material`}>
      <AgGridReact
        enableBrowserTooltips
        columnDefs={columnDefs}
        rowData={rowData}
        defaultColDef={defaultColDef}
          // rowMultiSelectWithClick
        pagination
        domLayout={domLayout}
        paginationPageSize={10}
        overlayLoadingTemplate={overlayLoadingTemplate}
        // overlayNoRowsTemplate={noRowsTemplate}
        // rowSelection={rowSelection}
        onGridReady={onGridReady}
        onSelectionChanged={handleSelectionChanged}
        rowDeselection
        overlayNoRowsTemplate={overlayNoRowsTemplate}
      />
    </div>
  );
}

LogsTable.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any),
  rowData: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    emailId: PropTypes.string,
    role: PropTypes.string,
    company: PropTypes.string,
    address: PropTypes.string,
  })),
  onGridReady: PropTypes.func,
  onSelectionChanged: PropTypes.func,
  isSearchGoingOn: PropTypes.bool,
};

LogsTable.defaultProps = {
  classes: {},
  rowData: [],
  onGridReady: () => { },
  onSelectionChanged: () => { },
  isSearchGoingOn: false,
};

export default withStyles(styles)(LogsTable);
