/* eslint-disable react/prop-types */
/**
 * This grid doen't allow you to select a row
 */
import React, {useState, useEffect, useContext,} from 'react';
import { withStyles } from '@mui/styles';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTint, faThermometerThreeQuarters, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import Battery60RoundedIcon from '@mui/icons-material/Battery60Rounded';
import PropTypes from 'prop-types';
import { CtoF } from '../../utils/helpers';
import EditIcon from '@mui/icons-material/Edit';
import UserContext from 'Context/UserContext';
import { Tooltip } from '@material-ui/core';
import './aggrid.css';
// import { number } from 'yup/lib/locale';


const moment = require('moment');

const styles = {
  headerClass: {
    backgroundColor: '#008081',
    color: 'white',
    fontSize:'14px', fontFamily:'Montserrat',
    textAlign: "center",
    borderRight: '1px solid white!important',
    '&:hover': {
      backgroundColor: '#008081!important',
    },
  },
  buttonStyle:{
    height: '30px', 
    cursor:'pointer', 
     backgroundColor:'#fff' ,
     border:'1px solid rgba(0,0,0,0.25)',
     borderRadius:'4px',
     "&:hover": {
      boxShadow: "7px 7px 5px -3px rgba(169,208,208)",
    },
  }
};

function timeStampFormatter(params) {
  return moment.unix(params.value).format('DD-MM-YY,  hh:mm A ');
}

function currentValueFormatter(params, companySettings) {
 
  if (params.data && params.data.data &&  Object.prototype.hasOwnProperty.call(params.data, "setValue")) {
    if (params.data.type && params.data.type === 'temperature') {
      if (companySettings && companySettings.settings && companySettings.settings.temperature && companySettings.settings.temperature === 'F') {
        // console.log('sdsd yes', `${CtoF(params.data.data)}°F (${CtoF(params.data.setValue)}°F)`)
        return `${CtoF(params.data.data).toFixed(1)}°F (${CtoF(params.data.setValue).toFixed(1)}°F)`;
      }
      return `${params.data.data}°C (${params.data.setValue}°C)`;
    }
    if (params.data.type && params.data.type === 'humidity') {
      return `${params.data.data}% (${params.data.setValue}%)`;
    }
    return `${params.data.data}% (${params.data.setValue}%)`;
  }
  return ' - ';
}

function currentNoteFormatter(params){
// console.log('params', params, params.data.note)
  if(params && params.data && params.data.note){
    return params.data && params.data.note
  }
return "-"
}

function eventTypeFormatter(params) {
  if (params.data && params.data.type && params.data.level) {
    const { type } = params.data;
    const { level } = params.data;
    return (
      <div style={{ display: 'inline-flex', verticalAlign: 'left' }} title={`${type} ${level}`}>
        { type === 'temperature' && level === 'violation' && (
          <FontAwesomeIcon icon={faThermometerThreeQuarters} style={{ color: 'rgb(255, 86, 48)', fontSize: '2em', alignContent:'left',marginTop:'8px'}} />
        )}
        { type === 'temperature' && level === 'warning' && (
        <FontAwesomeIcon icon={faThermometerThreeQuarters} style={{ color: 'rgb(255, 171, 0)', fontSize: '2em', alignContent:'left',marginTop:'8px' }} />
        )}
        { type === 'humidity' && level === 'violation' && (
        <FontAwesomeIcon icon={faTint} style={{ color: 'rgb(255, 86, 48)', fontSize: '1.8em', alignContent:'left',marginTop:'8px' }} />
        )}
        { type === 'humidity' && level === 'warning' && (
        <FontAwesomeIcon icon={faTint} style={{ color: 'rgb(255, 171, 0)', fontSize: '1.8em', alignContent:'left',marginTop:'8px' }} />
        )}
        { type === 'battery' && level === 'critical' && (
        <Battery60RoundedIcon style={{ color: 'rgb(255, 86, 48)', fontSize: '2em',marginLeft:'-5px' ,marginTop:'8px'}} />
        )}
        { type === 'battery' && level === 'warning' && (
        <Battery60RoundedIcon style={{ color: 'rgb(255, 171, 0)', fontSize: '2em' ,marginLeft:'-5px',marginTop:'8px' }} />
        )}
      </div>
    );
  } return (
    <div style={{ display: 'inline-flex', verticalAlign: 'center' }}>
      <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: 'rgb(163, 6, 6)', fontSize: '2em' }} />
    </div>
  );
}






function EventLogsGrid({
  classes, rowData, companySettings,onSelectionChanged, selectedRowData,selectionClearTime,handleDeviceNoteChange,
}) {
  const [gridApi, setGridApi] = useState(null);
 
  const companyDetails = useContext(UserContext);
  const { CompanyInfo } = companyDetails;


  function deviceNoteChange(change){
    // console.log(change.data,change.data.note);
    if (handleDeviceNoteChange) {
      handleDeviceNoteChange(change.data.uid, change.newValue);
    }
  }






  function CellRenderer(props){
    const handleClick = () => {
      props.api.startEditingCell({
        rowIndex: props.rowIndex,
        colKey: props.column.getId(),
      });
    }; 
    
    return (
      <span>
        <Tooltip title="click to edit note">
        <button 
        className={classes.buttonStyle}
        style={{}} 
        onClick={handleClick}>
          {/* ✎ */}
          <EditIcon style={{color:' rgb(0 128 129 / 60%'}}/>
        </button>
  
        </Tooltip>
        <span style={{ paddingLeft: '4px' }}  >{props.value ? props.value : '-' }</span>
       
      </span>
    );
  }
  

  const columnDefs = [
    {
      headerName: 'Event',
      field: 'type',
      // filter: true,
      minWidth: 150,
      // maxWidth: 150,
      checkboxSelection: true,
      cellRendererFramework: eventTypeFormatter,
      // pinned:'left'
    },
    {
      headerName: 'Device Id',
      field: 'deviceId',
      minWidth: 170,
      sortable: true,
      filter: true,
      // pinned: 'left',
    },
    {
      headerName: CompanyInfo && CompanyInfo.settings.chamberType ? (CompanyInfo.settings.chamberType + " ID") : "Chamber Id",
      field: 'assetId',
      sortable: true,
      filter: true,
      minWidth: 300,
      
      headerTooltip: CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.chamberType && CompanyInfo.settings.chamberType.length > 25 ?  CompanyInfo.settings.chamberType + " ID" : '',
   
    },
    {
      headerName: CompanyInfo && CompanyInfo.settings.warehouseType ? (CompanyInfo.settings.warehouseType + " ID") : "Warehouse Id",
      field: 'assetGroupId',
      sortable: true,
      filter: true,
      minWidth: 300,
      headerTooltip: CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.warehouseType && CompanyInfo.settings.warehouseType.length > 25 ?  CompanyInfo.settings.warehouseType + " ID" : '',
      valueFormatter: params=>{return params.data.assetGroupId},
      cellClass:"assetName",
    
    },
    {
      headerName: 'Time',
      field: 'timeStamp',
      sortable: true,
      sort:  'desc' ,
      minWidth: 230,
      valueFormatter: timeStampFormatter,
    },
    {
      headerName: 'Value (Set Value)',
      // field: 'data',
      // sortable: 'true',
      // filter: true,
      minWidth: 200,
      valueFormatter: (params) => { return currentValueFormatter(params, companySettings); },
    },
    {
      headerName: 'Note',
      field:'note',
      minWidth: 200,
      // minHeight:200,
      editable: true,
      valueFormatter:  currentNoteFormatter,
      onCellValueChanged: deviceNoteChange,
      cellRenderer: CellRenderer,
    
    },
    

  ];

  

  const onGridReady = (params) => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };


  const handleSelectionChanged = () => {
    if (gridApi) {
      onSelectionChanged(gridApi.getSelectedRows());
    }
  };``

  const handleSelectionCleared = () => {

    if (selectedRowData.length === 0 && gridApi) {
      
      gridApi.deselectAll();
    }
  };

  useEffect(() => {
    if (!selectedRowData.length) {
    
      handleSelectionCleared();
    }
  }, [selectedRowData.length]);

  useEffect(() => {
    if (gridApi) {
      const params = {
                        fileName: "Event Logs",
                        processCellCallback: (data) => {
                        
                          if (data.value && data.column && data.column.colDef) {
                            if (
                              data.column.colDef.field
                              && (data.column.colDef.field === "timeStamp")
                            ) {
                              return moment(data.value * 1000).format(
                                "DD-MM-YYYY, hh:mm A",
                              );
                            }
                            if( data.column.colDef.field
                              && (data.column.colDef.field === "assetId")){
                               
                                return `"${data.value}"`;
                              }
                          }
                       
                          else if (data && data.column && data.column.colDef && data.column.colDef.headerName === "Value (Set Value)") {
                            if (data && data.node && data.node.data  && data.node.data.type === 'temperature') {
                              if (companySettings && companySettings.settings && companySettings.settings.temperature && companySettings.settings.temperature === 'F') {
        
                                return `${CtoF(data.node.data.data).toFixed(1)}°F (${CtoF(data.node.data.data).toFixed(1)}°F)`;
                              }
                                return `${data.node.data.data}°C (${data.node.data.setValue}°C)`;
                              }
                              if (data && data.node && data.node.data && data.node.data.type && data.node.data.type === 'humidity') {
                                return `${data.node.data.data}% (${data.node.data.setValue}%)`;
                            }   
                               return `${data.node.data.data}% (${data.node.data.setValue}%)`;
                          }
                          return data.value;
                        },
      };
      gridApi.exportDataAsCsv(params);
    }
  },[selectionClearTime])

// const pageChange=(event)=>{
  
//   const currentPage = gridApi && gridApi.paginationGetCurrentPage();
  
// }




  const defaultColDef = {
    sortable: false,
    
    filter: false,
    suppressMovable: false,
    resizable: true,
    cellStyle: { textAlign: 'left',fontSize:'14px', fontFamily:'Montserrat', height:'48px' },
    headerClass: classes.headerClass,
    
  };

 
  
  const overlayLoadingTemplate = '<span class="ag-overlay-loading-center"><img src="./../img/horizontalLoader.svg" /></span>';
  const overlayNoRowsTemplate = '<img src="./../img/emptybox.png" />';
  const rowSelection = 'single';
  

  return (
   
    <div className="ag-theme-material" style={{ width: 'auto' }}>
    
      <AgGridReact
        enableBrowserTooltips
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        rowData={rowData}
        onGridReady={onGridReady}
        pagination
        paginationPageSize={10}
        rowMultiSelectWithClick={true}
        rowSelection={rowSelection}
        enableCellTextSelection
        domLayout="autoHeight"
        overlayLoadingTemplate={overlayLoadingTemplate}
        overlayNoRowsTemplate={overlayNoRowsTemplate}
        onSelectionChanged={handleSelectionChanged}
        rowDeselection
        suppressClickEdit={true}
       
        // onPaginationChanged={pageChange}
       

      />
    </div>
  );
}

EventLogsGrid.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  rowData: PropTypes.instanceOf(Object),
  
};

EventLogsGrid.defaultProps = {
  rowData: null,
  // onSelectionChanged: [],
};

export default withStyles(styles)(EventLogsGrid);
