import React, { useState , useContext} from 'react';
import { withStyles } from '@mui/styles';
import { CircularProgress, Typography } from '@mui/material';
import { green } from '@mui/material/colors';
import moment from 'moment';
import cogoToast from 'cogo-toast';
import API from 'API/apiConfig';
import DateRange from 'components/DateRange';
import PrimaryButton from 'components/PrimaryButton';
import GetAppIcon from '@mui/icons-material/GetApp';
import UserContext from 'Context/UserContext';


const APIURL = process.env.REACT_APP_APIURL;

const styles = {
  innerGrid: {
    marginTop: '15vh',
    marginLeft: '15vw',
  },
  typography: {
    fontSize: "16px",
    marginBottom: "20px!important",
  },
  download: {
    cursor: 'pointer',
    marginTop: '200px',
    borderRadius: '20px',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
};


const DataExport = ({ classes }) => {

  const [startDate, setStartDate] = useState(moment().subtract(1, 'week'));
  const [endDate, setEndDate] = useState(moment());
  const [selectedQuickOption, setSelectedQuickOptions] = useState(7);
  const [fileLoading, setFileLoading] = useState(false);

  const contextDetail = useContext(UserContext); // react context
  const { userPermissions, } = contextDetail;

  const handleRangeChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
    setSelectedQuickOptions(0);
  };
  const handleQuickOptionsChange = (value) => {
    const start = moment().subtract(value, 'days');
    const end = moment();

    setStartDate(start);
    setEndDate(end);
    setSelectedQuickOptions(value)

  };


  const handleSevenDaysSelection = () => {
    const firstDate = new Date(startDate) 
    const secondDate = new Date(endDate) 
    const firstDateInMs = firstDate.getTime()
    const secondDateInMs = secondDate.getTime()

    const differenceBtwDates = secondDateInMs - firstDateInMs

    const aDayInMs = 24 * 60 * 60 * 1000

    const daysDiff = Math.round(differenceBtwDates / aDayInMs)
   
    return daysDiff
   
  }

  const getDataForExport = () => {
    const sD = moment(startDate).unix();
    const eD = moment(endDate).unix();

    setFileLoading(true);
    //check if selected range is within 7 days or not.
    const isSevenDays = handleSevenDaysSelection()
   
    if (isSevenDays <= 7) {
      // console.log('Hello', sD,eD )
      API.get(`${APIURL}/dataExport?from=${sD}&&to=${eD}`, {
        headers: {
          Accept: "application/vnd.ms-excel",
        },
        method: "GET",
        responseType: "blob",
      })
        .then(async (response) => {
          if (response && response.status === 200) {
            document.body.style.cursor = "default";
            // console.log("headers are ", response.headers, "data is, ", response.data);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            let filename = "Data";
            filename += ".xlsx";
            // console.log("filename is ", filename);
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
            link.remove();
            cogoToast.success("Data exported successfully");
            setFileLoading(false);
          } else {
            console.error("Error download data");

          }
        })
        .catch((error) => {
          console.log(
            "error getting data for data export",
            error.message || error,
          );
          cogoToast.error("error getting data for data export", {
            position: "top-right",
            heading: "Error",
          });
          setFileLoading(false);
        });
    }
    else {
      setFileLoading(false);
      cogoToast.error("Please select dates within a week to download data", {
        position: "top-right",
      });
    }
  };

  const routeToDashboard = () => {
    
    cogoToast.error('You do not have permission to view Data Export', { position: 'top-right' });
  };

  return (
    <>
    {userPermissions && userPermissions.logs && userPermissions.logs.read
        ?
       ( <div className={classes.root}>
      <div className={classes.innerGrid}>
        <Typography variant="p" component="div" className={classes.typography}>
          Please select the dates between which you wish to get data.
          {" "}
          <br />
          {`All Warehouse data of selected dates will also be shown in the downloaded data.`}
          {" "}
        </Typography>
        <DateRange
          startDate={startDate}
          endDate={endDate}
          handleRangeChange={handleRangeChange}
          handleQuickOptionsChange={handleQuickOptionsChange}
          selectedQuickOption={selectedQuickOption}
          message="Please select dates within a week to download data."
          disable
        />
        <PrimaryButton
          disabled={fileLoading}
          style={{ marginTop: "20px" }}
          text="Download"
          onClick={getDataForExport}

          icon={<GetAppIcon style={{ marginRight: "5px" }} />}

        />
        {fileLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>

    </div>) :
          
            routeToDashboard()
          
       }
    
    </>
  )
}

export default withStyles(styles)(DataExport);