import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import Grow from '@mui/material/Grow';
import Zoom from '@mui/material/Zoom';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import cogoToast from 'cogo-toast';
import Delete from '@mui/icons-material/Delete';
import Input from 'components/Input';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
// import Edit from '@mui/icons-material/Edit';
// import Checkbox from '@mui/material/Checkbox';
// import FormGroup from '@mui/material/FormGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import FormControl from '@mui/material/FormControl';
// import FormLabel from '@mui/material/FormLabel';
import * as yup from 'yup';
import axios from 'axios';
import Edit from '@mui/icons-material/Edit';
import API from 'API/apiConfig';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { CircularProgress,Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import UserContext from 'Context/UserContext';
import PrimaryButton from 'components/PrimaryButton';
import SecondaryButton from 'components/SecondaryButton';
import ClientsGrid from './ClientsGrid';
import ManageUsersForClient from './ManageUsersForClient';
import SearchOption from 'components/SearchOption';

const APIURL = process.env.REACT_APP_APIURL;

axios.defaults.withCredentials = true;

// Creating Schema to validate Adding clients using yup
const AddClientSchema = yup.object().shape({
  // clientId: yup.string().required('Client ID cannot be empty'),
  clientName: yup
    .string()
    .required('Client name cannot be empty'),
  // contact: yup.string().email().required('Email is required for giving Client access'),
  contact: yup.string().email().required('Email is required for giving Client access'),
  // mobileNumber: yup.string().required('Please Give mobile number of the client').length(10, 'Mobile number should not exceed or go below 10 digits'),
  mobileNumber: yup.string().required('Please Give mobile number of the client')
});

const styles = {
  topStrip: {
    textAlign: 'right',
    // borderRight: '1px solid white',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    // justifyContent: 'flex-end',
  },
  '@media screen and (max-width: 600px)': {
    topStrip: {
      // textAlign: 'right',
      // borderRight: '1px solid white',
      paddingTop: '1rem',
      paddingBottom: '1rem',
      justifyContent: 'flex-start',
      display: 'flex',
      alignItems: 'center',
    },
  },
  modalDialogOuterDiv: {
    display: 'flex',
    justifyContent: 'center',
  },
  modalDialogDiv: {
    display: 'flex',
    width: '50%',
    flexDirection: 'column',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  errorMessageDiv: {
    MarginTop: '20%',
    height: '20px',
    textAlign: 'left',
  },
  errorMessageText: {
    fontFamily: 'Montserrat',
    fontSize: '16px',
    textAlign: 'left',
    color: 'red',
    marginTop: '15px',
  },
  inputTextField: {
    border: 'none',
    borderBottom: '1px solid grey',
    width: '100%',
    height: '30px',
    outline: 'none',
    '&:focus': {
      borderBottom: '1px solid #00adef',
    },
  },
  inputLabel: {
    opacity: '0.5',
    fontFamily: 'Montserrat',
    fontSize: '12px',
    textAlign: 'left',
    marginTop: '10px',
    color: 'rgba(3, 0, 0, 0.5)',
  },
  warning: {
    color: 'red',
    fontWeight: '500',
  },
  topDiv: {
    width: '100%',
    height: '50px',
    display: 'flex',
    // backgroundColor: '#ffffff',
  },
  deleteDiv: {
    width: '50px',
    // borderRight: '1px solid #dedede',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    color: 'red',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  editDiv: {
    // width: '50px',
    // // borderRight: '1px solid #dedede',
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    // cursor: 'pointer',
    color: '#008081',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  disabledButton: {
    // width: '100px',
    // borderRight: '1px solid #dedede',
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    color: '#c2c2c2',
  },
  checkboxLabel: {
    fontFamily: 'Montserrat',
  },
  textField: {
    width: '100%',
  },
  appBar: {
    position: 'sticky',
  },
  title: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  deletebuttondiv:{
    // borderRight: '1px solid #dedede',
    width: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  addButton:{
    backgroundColor:'#008081',
    borderRadius:'20px',
    textAlign:'center',
    color:'white',
    fontWeight:'bold',
    // minWidth:'99px',
    '&:hover': {
      backgroundColor:'#008081',
    },
  },
  saveButton:{
    backgroundColor:'#008081',
    color:'white',
    borderRadius:'20px',
    margin:'10px !important',
    padding:'5px 15px',
    minWidth:'99px',
    '&:hover': {
      backgroundColor:'#008081',
    },
  },
  cancelButton:{
    color:'#008081',
    padding:'5px 15px',
    minWidth:'99px',
    borderRadius:'20px !important' ,
    border:'2px solid #008081 !important'
  },
  dialogtitle:{
    textAlign:'left',
    color:'#000',
    fontWeight:'bold',
    fontFamily:'Montserrat',
    fontSize:'20px'
    
    
  },
  editbuttondiv: {
    // borderRight: '1px solid #dedede',
    width: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    marginBottom:'12px'
  },
  clearSelectionDisabled: {
    display: 'block',
    position: 'relative',
  },
  clearSelectionActive: {
    color: 'red',
  },
};

// const history = useHistory();

class Clients extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rowData: null,
      openAddDialog: false,
      openDeleteDialog: false,
      openEditDialog: false,
      errorMessage: null,
      selectedRowData: [],
      fullScreen: false,
      loading: true,
      editClient:[],
      searchInput:'',
      // SMS: false,
      // EMAIL: false,
      // PUSH: false,
    };
  }


  static contextType = UserContext;


  componentDidMount = () => {
    this.getClients();
    if (window.innerWidth <= 600) {
      this.setState({
        fullScreen: true,
      });
    }
    const { rowData } = this;
    // axios request for getting rowData
    this.setState({
      rowData,
    });
  };

  getClients = () => {
    API
      .get(`${APIURL}/clients`)
      .then((response) => {
        // console.log('Resp', response);
        if (response.data && response.data.status && response.data.status === 'success') {
          this.setState({
            rowData: response.data.data,
            loading: false,
          });
         
        } else {
          cogoToast.error('unable to get Client(s)', { position: 'top-right' });
          this.setState({
            loading: false,
          });
        }
      })
      .catch((error) => {
        console.error('gettingDevicesError', error);
        this.setState({
          loading: false,
        });
      });
  }

  

   onSelectionChanged = (selectedRows) => {
    
    this.setState({
      selectedRowData:selectedRows
    })
    // setPermissions(permissions);
  };

  onAddButtonClick = () => {
    this.setState({
      // SMS: false,
      // EMAIL: false,
      // PUSH: false,
      loading:false,
      openAddDialog: true,
    });
    // console.log('clicked');
  };

  onAddDialogClose = () => {
    this.setState({
      openAddDialog: false,
      errorMessage: null,
    });
  };

  onAddDialogSave = (event) => {
    event.preventDefault();
  
    const clientId = event.target.clientId.value;
    const clientName = event.target.clientName.value;
    const contact = event.target.emailId.value;
    // const countryCode = event.target.countryCode.value;
    const mobileNumber = event.target.mobileNumber.value;
    // const password = event.target.password.value;
    // const confirmPassword = event.target.confirmPassword.value;
    const addClientAxiosObject = {
      id: clientId,
      name: clientName,
      contact,
      address: mobileNumber,


      // contactNo: {
      //   countryCode: Number(countryCode),
      //   number: Number(mobileNumber),
      // },
      // password,
      // role: 'level2',
    };
    // this.setState({
    //   loading: true,
    // });
    AddClientSchema.validate({
      clientId,
      clientName,
      contact,
      // password,
      // confirmPassword,
      mobileNumber,
      // countryCode,
    })
      .then(() => {
        this.setState({
          loading:true
        },()=>{
          API
          .post(`${APIURL}/clients`, addClientAxiosObject)
          .then((clientResponse) => {
            if (clientResponse.data
                && clientResponse.data.status
                && clientResponse.data.status === 'success') {
                  // console.log('After adding client', clientResponse);
              this.getClients();
              // console.log('After adding client', clientResponse);
              this.setState({
                openAddDialog: false,
                errorMessage: null,
                loading: false,
                searchInput:''
              });
              cogoToast.success('Added Client successfully', { position: 'top-right' });
            } else {
              cogoToast.error(
                clientResponse.data && clientResponse.data.message
                  ? clientResponse.data.message
                  : 'Failed Adding Client to Server',
                {
                  position: 'top-right',
                },
              );
              this.setState({
                loading: false,
              });
            }
          })
          .catch(() => {
            // console.error(error);
            this.setState({
              loading: false,
            });
          });
        })
       
      })
      .catch((err) => {
        // console.error('err', err.message);
        this.setState({
          errorMessage: err.message,
          loading: false,
        });
      });
  };

  onDeleteButtonClick = () => {
   
    const { selectedRowData } = this.state;
    if (selectedRowData && selectedRowData.length >= 1) {
      this.setState({
        openDeleteDialog: true,
      });
    } else {
      cogoToast.info('Please Select Client(s) to delete them', {
        position: 'top-right',
      });
    }
  };

  // Cancelling the delete action after clicking on delete
  onDeleteDialogClose = () => {
    this.setState({
      openDeleteDialog: false,
      selectedRowData:[]
    });
  };

  onDeleteDialogSave = () => {
    const { selectedRowData } = this.state;
    const selectedRowsUidsArray = selectedRowData.map((item) => item.uid);
    this.setState({
      loading: true,
    });
    // use selectedRowsUidsArray with axios to delete and then change state fo dialog
    API
      .delete(`${APIURL}/clients`, {
        params: { uids: selectedRowsUidsArray },
      })
      .then((deleteResponse) => {
        // console.log('deleteResponse', deleteResponse);
        if (deleteResponse.data && deleteResponse.data.status && deleteResponse.data.status === 'success') {
          this.getClients();
          this.setState({
            openDeleteDialog: false,
            loading: false,
            selectedRowData:[],
            searchInput:''
          });
          cogoToast.success('Successfully deleted selected Client(s)', { position: 'top-right' });
        } else {
          cogoToast.error(
            deleteResponse.data && deleteResponse.data.message
              ? deleteResponse.data.message
              : 'Failed Removing Client',
            {
              position: 'top-right',
            },
          );
          this.setState({
            loading: false,
          });
        }
      })
      .catch(() => {
        // console.error(err);
        this.setState({
          loading: false,
        });
      });
  };

  onEditButtonClick = () => {
  
    const { selectedRowData, } = this.state;
    const editClientRow = { ...selectedRowData[0] };
    if (selectedRowData && selectedRowData.length === 1) {
    
      this.setState({
        
        openEditDialog: true,
        editClient:editClientRow,
        errorMessage:null

      });
    } else {  
      
      cogoToast.info('Select a single row of Client to edit or View info', {
        position: 'top-right',
      });
    }
  };

  onEditDialogClose = () => {
    this.setState({
      openEditDialog: false,
      editClient:null,
      errorMessage:null,
      selectedRowData:[]
    });
  };

  onEditDialogSave = (event) => {
    // axios request for update and close edit dialog on sucessful save
   
    event.preventDefault();
    // this.setState({
    //   loading:true
    // })
    const {selectedRowData,
      //  editClient, 
      }= this.state;
    const editRowData = { ...selectedRowData[0] };
   
    const generatePatchBody=(params)=>{
      const validParams=['address', 'contact', 'name'];
      const body = Object.keys(params).map((p) => {
        if (validParams.includes(p)) return { op: 'replace', path: `/${p}`, value: params[p] };
        return null;
      }).filter(f => f);
      return body;
    }


    const clientName=event.target.clientName.value;
    const contact=event.target.emailId.value;
    const mobileNumber=event.target.mobileNumber.value;
  

    // const clientName=editClient.name;
    // const contact=editClient.contact;
    // const mobileNumber=editClient.address;

    const clientPatchObj = generatePatchBody({
      // address: editClient &&  editClient.address,
      // contact: editClient && editClient.contact,
      // name: editClient && editClient.name,
      address:mobileNumber,
      contact:contact,
      name:clientName
    });
 
   
    this.setState({
      openEditDialog: false,
    });
    AddClientSchema.validate({
     clientName,
     contact,
     mobileNumber,
    })
    .then(()=>{
      this.setState({
       loading: true,
      },()=>{
        API.patch(`${APIURL}/clients/${editRowData.uid}`, clientPatchObj)

        .then((response)=>{
           
            if (response.data.status === 'success') {
              const userResponse = response.data.data;
              
              this.setState({
                editClient:userResponse,
                loading:false,
                selectedRowData:[],
                searchInput:'',
                errorMessage:null,
              })
              this.getClients();
              cogoToast.success('Edited the selected client successfully', { position: 'top-right' });
            }
            else{
              console.log('Cannot edit client');
            }
        })
        .catch(()=>{
          // console.log('Err', err)
          cogoToast.error('Unable to edit Client(s)', { position: 'top-right' });
        })
      })
     
    })
    .catch((err)=>{
      // console.log('Err', err);
      
      this.setState({
        loading:false,
        errorMessage:err.message,
        openEditDialog: true,
        
      })
    })
    
  };

   handleSelectionClear = () => {
    this.setState({
      selectedRowData:[]
    })
  };

  onSearchChange=(event)=>{
    this.setState({
      searchInput:event.target.value
    })
  }

  handleCheckboxChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  changeClientName=(event)=>{
    const {editClient}= this.state;
   
    const newName=event.target.value;
this.setState({
  editClient:{...editClient, name:newName}
})
  }

  changeEmailId=(event)=>{
    const {editClient}= this.state;
    
    const newEmailId=event.target.value;
    this.setState({
      editClient:{...editClient, contact:newEmailId}
    })
  }

  changemobileNumber=(event)=>{
    const {editClient}= this.state;
    
    const newNumber=event.target.value;
    this.setState({
      editClient:{...editClient, address:newNumber}
    })
  }

  routeToDashboard=() => {
const {history}= this.props;
    history.push('/dashboard');
    cogoToast.error('You do not have access to clients page, please contact your admin', { position: 'top-right' });
  }

  
  render() {
    const {
      rowData,
      openAddDialog,
      errorMessage,
      openDeleteDialog,
      openEditDialog,
      selectedRowData,
      // SMS,
      // EMAIL,
      // PUSH,
      fullScreen,
      loading,
      searchInput
    } = this.state;
    const { classes } = this.props;
    const { userPermissions } = this.context;

    
    return (
      <>
        {loading ? (
          <div className={classes.overlay}>
            <CircularProgress
              style={{ position: 'absolute', top: '50%', left: '50%' }}
            />
          </div>
        ) : (
          
          (userPermissions && userPermissions.clients && userPermissions.clients.read)
            ? (
              <>
                <Grow in>
                  <div>
                    <Grid container spacing={0} className={classes.topStrip}>
                      <Grid item md={12} sm={12} xs>
                        {/* BreadCrumbs with left float in classes */}
                        <PrimaryButton
                         text="Add Client"
                          onClick={this.onAddButtonClick}
                          disabled={!(userPermissions && userPermissions.clients && userPermissions.clients.create)}
                        />
              
                      </Grid>
                    </Grid>



                  <Grid container spacing={0}  justifyContent="center" style={{paddingBottom:'5px'}}>
                  <Grid item xl={7} lg={7} md={5} sm={5} xs={7} style={{textAlign:'left', display:'flex'}}>
                  <div>
                          <div className={classes.deletebuttondiv}>
                           

                           
                            <Button
                  
                            // className={(userPermissions && userPermissions.clients && userPermissions.clients.delete && selectedRowData && selectedRowData.length === 1)
                            // ? classes.deleteDiv
                            // : classes.disabledButton}
                            onClick={this.onDeleteButtonClick}
                            onKeyPress={this.onDeleteButtonClick}
                            role="button"
                            tabIndex="0"
                            color='error'
                            disabled={!(userPermissions && userPermissions.clients && userPermissions.clients.delete && selectedRowData && selectedRowData.length >= 1)}
                          >

                      <span> <Tooltip title="Delete"  placement="top-end"><Delete /></Tooltip> </span>  
                            
                          </Button>
                         
                                </div>
                          </div>

                          <div className={classes.editbuttondiv}>
                            
                          <Button
                  onClick={this.onEditButtonClick}
                  onKeyPress={this.onEditButtonClick}
                  role="button"
                  tabIndex="0"
                 
                  disabled={!(userPermissions && userPermissions.clients && userPermissions.clients.update && selectedRowData && selectedRowData.length >= 1)}
                >
                <span>
                <Tooltip title="Edit"  placement="top-end">
                   <Edit  className={userPermissions && userPermissions.clients && userPermissions.clients.update
                    && selectedRowData && selectedRowData.length >= 1
                              ? classes.editDiv
                              : classes.disabledButton} />
                                </Tooltip>
                              </span> 
                 
                </Button>
              
                          </div>
                       
                          <ManageUsersForClient
                          loading={loading}
                          selectedRowData={selectedRowData}
                          fullScreen={fullScreen}
                         />


                  </Grid>
                 < Grid item  xl={3}  lg={2} md={3} sm={3} xs={5}>
                 <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              alignItems: 'center',
                              flexGrow: 1,
                            }}
                          >
                            <Tooltip title="use 'shift' or 'ctrl' for multi-select">
                              {/* <IconButton> */}
                              <div>
                                <span>Selected : </span>
                                <span className={classes.Selected}>
                                  {selectedRowData.length}
                                </span>
                              </div>
                              {/* </IconButton> */}
                            </Tooltip>
                            <Tooltip title="Clear Selection">
                              <IconButton
                                onClick={this.handleSelectionClear}
                           // onClick={selectionClear}
                               
                              >
                                <HighlightOffIcon
                                 className={
                                  selectedRowData && selectedRowData.length > 0
                                    ? classes.clearSelectionActive
                                    : classes.clearSelectionDisabled
                                }
                                />
                              </IconButton>
                            </Tooltip>
                          </div>
                   </Grid>
                   <Grid item  xl={2}  lg={3} md={4} sm={4} xs={12}> 
                   <SearchOption
                          variant="standard"
                            id="userSearchInput"
                            value={searchInput}
                            onChange={this.onSearchChange}
                            placeholder="Search by client name"
                          />
                   </Grid>


                  </Grid>

                  <ClientsGrid
                          rowData={rowData}
                          searchvalue={searchInput}
                          selectedRowData={selectedRowData}
                          onSelectionChanged={this.onSelectionChanged}
                        />

                        

                  

                    <Dialog
                      open={openAddDialog}
                      TransitionComponent={Zoom}
                      onClose={this.onAddDialogClose}
                      aria-labelledby="form-dialog-title"
                      maxWidth="xs"
                      fullWidth
                      scroll="body"
                      fullScreen={fullScreen}
                      PaperProps={{
                        style: {
                          backgroundColor: '#f2f2f2',
                        },
                      }}
                    >
                      <Grow in={openAddDialog}>
                        <form onSubmit={this.onAddDialogSave}>
                          {fullScreen && (
                          <AppBar className={classes.appBar}>
                          <Toolbar style={{backgroundColor:'#008081'}}>
                          <IconButton
                          edge="start"
                          color="inherit"
                          onClick={this.onAddDialogClose}
                          aria-label="close"
                        >
                          <CloseIcon />
                        </IconButton>
                          <Typography variant="h6" className={classes.title}>
                        Add Client
                        </Typography>
                          <Button color="inherit" type="submit">
                        save
                        </Button>
                        </Toolbar>
                        </AppBar>
                          )}
                          {!fullScreen && (
                          <DialogTitle
                          id="form-dialog-title"
                        className={classes.dialogtitle}
                        >
                    Add Client
                        </DialogTitle>
                          )}
                          <DialogContent>
                            <Grid container style={{marginTop: fullScreen ? "3rem" : '0rem' }} spacing={1}>
                            <Grid item xs={12}>
                            <Input
                            id="clientId"
                            placeholder="Client id"
                            // className={classes.textField}
                            margin="normal"
                            backgroundColor="#fff"
                          />
                          </Grid>
                            <Grid item xs={12}>
                            <Input
                            id="clientName"
                            placeholder="Client name"
                            // className={classes.textField}
                            margin="normal"
                            backgroundColor="#fff"
                          />
                          </Grid>
                            <Grid item xs={12}>
                            <Input
                            id="emailId"
                            placeholder="Email"
                            // className={classes.textField}
                            type="email"
                            backgroundColor="#fff"
                            margin="normal"

                          />
                          </Grid>

                           
                            
                          
                            <Grid item xs={12}>
                            <Input
                            id="mobileNumber"
                            placeholder="Mobile number"
                            // className={classes.textField}
                            type="number"
                            margin="normal"
                            backgroundColor="#fff"
                          />
                          </Grid>
                          </Grid>
                           
                           

                            <div style={{ marginTop: '10px', paddingTop: '10px' }}>
                            {errorMessage && errorMessage !== null ? (
                            <div className={classes.errorMessageDiv}>
                            <span className={classes.errorMessageText}>
                          *
                              {errorMessage}
                            </span>
                          </div>
                          ) : null}
                          </div>
                          </DialogContent>
                          {!fullScreen && (
                          <DialogActions>
                          <SecondaryButton onClick={this.onAddDialogClose} text="Cancel" />
                     
                          <PrimaryButton text="Save" type="submit" />
                     
                        </DialogActions>
                          )}
                        </form>
                      </Grow>
                    </Dialog>

                    <Dialog
                      open={openDeleteDialog}
                      TransitionComponent={Zoom}
                      onClose={this.onDeleteDialogClose}
                      aria-labelledby="form-dialog-title"
                      maxWidth="sm"
                      scroll="body"
                    >
                      <DialogTitle id="form-dialog-title">
                        <span className={classes.warning}>Warning</span>
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                Are you sure you want to delete the selected Clients ?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <SecondaryButton text="No" onClick={this.onDeleteDialogClose} />
               
                        <PrimaryButton  text="Yes" onClick={this.onDeleteDialogSave} />
                
                      </DialogActions>
                    </Dialog>

                    <Dialog
                      open={openEditDialog}
                      TransitionComponent={Zoom}
                      onClose={this.onEditDialogClose}
                      aria-labelledby="form-dialog-title"
                      maxWidth="xs"
                      fullWidth
                      scroll="body"
                      fullScreen={fullScreen}
                      PaperProps={{
                        style: {
                          backgroundColor: '#f2f2f2',
                        },
                      }}
                    >
                      
                      <Grow in={openEditDialog}>
                        <form onSubmit={this.onEditDialogSave}>
                          {fullScreen && (
                          <AppBar className={classes.appBar}>
                          <Toolbar style={{backgroundColor:'#008081'}}>
                          <IconButton
                          edge="start"
                          color="inherit"
                          onClick={this.onEditDialogClose}
                          aria-label="close"
                        >
                          <CloseIcon />
                        </IconButton>
                          <Typography variant="h6" className={classes.title}>
                        Edit Client
                        </Typography>
                          <Button color="inherit" type="submit">
                        update
                        </Button>
                        </Toolbar>
                        </AppBar>
                          )}
                          {!fullScreen && (
                          <DialogTitle
                          id="form-dialog-title"
                         className={classes.dialogtitle}
                        >
                    Edit Client
                        </DialogTitle>
                          )}
                          <DialogContent>
                            <Grid container style={{marginTop: fullScreen ? "3rem" : '0rem' }} spacing={1}>
                            <Grid item xs={12}>
                            <Grid item xs={12}>
                            <Input
                            id="clientId"
                            backgroundColor="#fff"
                            placeholder="Client id"
                            // className={classes.textField}
                            margin="normal"
                            defaultValue={
                              selectedRowData && selectedRowData.length === 1 && selectedRowData[0].id
                                ? selectedRowData[0].id
                                : 'N/A'
                            }
                                disabled
                          />
                          </Grid>
                            <Grid item xs={12}>
                            <Input
                            id="clientName"
                            placeholder="Client name"
                            // onChange={(e)=>{this.changeClientName(e)}}
                            onChange={this.changeClientName}
                            // className={classes.textField}
                            margin="normal"
                            defaultValue={
                              selectedRowData && selectedRowData.length === 1 && selectedRowData[0].name
                                ? selectedRowData[0].name
                                : 'N/A'
                            }
                            backgroundColor="#fff"
                          />
                          </Grid>
                            <Grid item xs={12}>
                            <Input
                            id="emailId"
                            placeholder="Email"
                            onChange={this.changeEmailId}
                            // className={classes.textField}
                            backgroundColor="#fff"
                            type="email"
                            margin="normal"
                            defaultValue={
                              selectedRowData
                              && selectedRowData.length === 1
                              && selectedRowData[0].contact
                                ? selectedRowData[0].contact
                                : 'N/A'
                            }
                          />
                          </Grid>
                          <Grid item xs={12}>
                            <Input
                            id="mobileNumber"
                            placeholder="Mobile number"
                            onChange={this.changemobileNumber}
                            // className={classes.textField}
                            backgroundColor="#fff"
                            type="number"
                            margin="normal"
                            defaultValue={
                              selectedRowData
                              && selectedRowData.length === 1
                              && selectedRowData[0].address
                            
                                ? selectedRowData[0].address
                                : 'N/A'
                            }
                          />
                          </Grid>

                         
                           
                         
                           
                          </Grid>
                          </Grid>
                            

                            <div style={{ marginTop: '10px', paddingTop: '10px' }}>
                            {errorMessage && errorMessage !== null ? (
                            <div className={classes.errorMessageDiv}>
                            <span className={classes.errorMessageText}>
                          *
                              {errorMessage}
                            </span>
                          </div>
                          ) : null}
                          </div>
                          </DialogContent>
                          {!fullScreen && (
                          <DialogActions>
                          <SecondaryButton text="Cancel" onClick={this.onEditDialogClose}  />
                   
                          <PrimaryButton  text="Save" type="submit"  variant="contained" />
                    
                        </DialogActions>
                          )}
                        </form>
                      </Grow>
                    </Dialog>

                  </div>
                </Grow>
              </>
            )
            : (
              this.routeToDashboard()
            )
            

        )}


      </>
    );
  }
}

Clients.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(Clients);




