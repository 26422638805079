import React, { useState, useContext } from "react";
import { withStyles } from "@mui/styles";
import * as yup from 'yup';
// import Dialog from '@mui/material/Dialog';
import UserContext from "Context/UserContext";
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Zoom from '@mui/material/Zoom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
// import Paper from '@mui/material/Paper';
// import Grow from '@mui/material/Grow';
import cogoToast from 'cogo-toast';
import { Dialog, DialogActions, DialogTitle, Typography } from "@mui/material";
import SecondaryButton from "components/SecondaryButton";
import PrimaryButton from "components/PrimaryButton";
import Input from "components/Input";
import ReactSelect from 'react-select';
import InputforCountrycode from "components/InputforCountrycode";
import { useEffect } from "react";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import moment from "moment/moment";
import API from "API/apiConfig";
import DialogContentText from '@mui/material/DialogContentText';
import { useMediaQuery, createTheme } from '@material-ui/core';

const APIURL = process.env.REACT_APP_APIURL;

const AddEndUserSchema = yup.object().shape({
    userName: yup.string().required('User Name should not be empty'),
    countryCode: yup.string().required('Country code should not be empty'),
    number: yup.string().required('Number should not be empty'),
    // assetUidList:yup.array().min(1).required("Asset Group should not be empty"),
});

const styles = {

    imgGrid: {
        marginTop: '10%'
    },
    paperDiv: {
        height: '60vh',
        marginTop: '15px'
    },
    dialogtitle: {
        textAlign: 'left',
        color: '#000',
        fontWeight: 'bold',
        fontSize: '20px',
        fontFamily: 'Montserrat',
    },
    errorMessageText: {
        fontFamily: 'Montserrat',
        fontSize: '12px',
        textAlign: 'left',
        color: 'red',
        marginTop: '15px',
    },
    warning: {
        color: 'red',
        fontWeight: '500',
    },
    appBar: {
        position: 'sticky',
    },
    title: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        fontFamily: 'Montserrat',
    },
}

const selectStyles = {
    control: () => ({
        alignItems: 'center',
        backgroundColor: '#fff',
        marginTop: '1rem',
        borderRadius: '21px',

        borderBottom: '1px solid #dedede',
        cursor: 'default',

        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',

        outline: '0 !important',
        position: 'relative',

        transition: 'all 100ms',
        boxSizing: 'border-box',
    }),
};


const EndUserForm = (props) => {
    const { classes, openAddDialog,
        onCancleButtonClick, onSaveButtonClick,
        edit, selectedRow, deleteDialog,
        onDeleteDialogClose, onSaveDeleteClick } = props
    const [selectedOption, setSelectedOption] = useState(null);
    const [fullScreen, setFullScreen] = useState(false)
    // const [date, setDate] = useState([moment().startOf('day').toDate(), moment().toDate()])
    const [selectList, setSelectList] = useState([]);
    const [selectedAssetGroup, setSelectedAssetGroup] = useState([])
    // const [addSelectedAG, setAddSelelectedAG]=useState([]);
    const [selectedAGtoAdd, setSelectedAGtoAdd] = useState([]);
    const [userName, setUserName] = useState({});
    const [countryCode, setCountryCode] = useState('');
    const [number, setNumber] = useState('');
    const [errorMessage, setErrorMessage] = useState('');


    const contextDetail = useContext(UserContext);
    const { CompanyInfo } = contextDetail;



    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1536,
            },
        },
    });

    const mdBreakPoint = useMediaQuery(theme.breakpoints.up('md'));
    const smBreakPoint = useMediaQuery(theme.breakpoints.up('sm'));
    // const userDetails = useContext(UserContext);
    // const { assetGroups } = userDetails;

    useEffect(() => {
        setFullScreen(smBreakPoint)
    }, [smBreakPoint])


    useEffect(() => {
        if (edit) {
            // setSelectList([])

            let userName = selectedRow && selectedRow[0] && selectedRow[0].userName;
            let countryCode = selectedRow && selectedRow[0] && selectedRow[0].countryCode;
            let number = selectedRow && selectedRow[0] && selectedRow[0].number;
            const AssetGroupList = selectedRow && selectedRow[0] && selectedRow[0].assetGroups;
            // const previousAssetGroup=[...selectedRowData[0].assetGroup]
            setSelectedAGtoAdd(AssetGroupList ? AssetGroupList : []);
            setUserName({ ...selectedRow[0], name: userName });
            setCountryCode(countryCode);
            setNumber(number);

        }

    }, [openAddDialog])

    const handleDialogClose = () => {
        onCancleButtonClick()
        setSelectedAGtoAdd([]);
        setUserName({});
        setCountryCode(' ');
        setNumber(' ');
        setErrorMessage('');

    }

    const handleDeleteDialogClose = () => {
        onDeleteDialogClose();
        onCancleButtonClick()
    }

    const handleEditDialogSave = (event) => {
        event.preventDefault();
        let ListOfAssetUid = []
        selectedAGtoAdd && selectedAGtoAdd.length && selectedAGtoAdd.forEach((ag) => {
            if (ag.assets && ag.assets.length) {
                ag.assets.forEach((ast) => {
                    if (ast.isEndUserAssigned) {
                        ListOfAssetUid = [...ListOfAssetUid, ast.uid]
                    }

                })
            }
        })

        const endUserObjectEdit = {
            userName: userName.name,
            number: number,
            countryCode: countryCode,
            assetUids: ListOfAssetUid

        }
        // console.log('endUserObjectEdit', endUserObjectEdit)
        AddEndUserSchema.validate({
            userName: endUserObjectEdit.userName,
            number: endUserObjectEdit.number,
            countryCode: countryCode,
            // assetUidList:selectedAGtoAdd,
        })
            .then(() => {
                API.patch(`${APIURL}/enduser`, endUserObjectEdit)
                    .then((res) => {
                        // console.log('res', res)
                        if (res && res.data && res.data.status === "success") {
                            onSaveButtonClick();
                            setUserName({});
                            setCountryCode(' ');
                            setNumber(' ');
                            setErrorMessage('');
                            setSelectedAGtoAdd([]);
                            cogoToast.success('Successfully updated selected end user', { position: 'top-right' });
                        }
                        else {
                            const errMsg = res.data && res.data.message
                            cogoToast.error(errMsg, {
                                position: 'top-right',
                            });
                        }
                    })
                    .catch((err) => {
                        const errMsg = err.data.data.message

                        cogoToast.error(errMsg, {
                            position: 'top-right',
                        });
                        //toast msg
                    })
            })
            .catch((error) => {
                const errorMessage = error.message ? error.message : 'Error adding role';
                setErrorMessage(errorMessage);
            })


    }



    //to add end user
    const handleAddDialogSave = (event) => {
        event.preventDefault();

        //filter assetUi list from selectedAGtoAdd...
        let ListOfAssetUid = []
        selectedAGtoAdd && selectedAGtoAdd.length && selectedAGtoAdd.forEach((ag) => {
            if (ag.assets && ag.assets.length) {
                ag.assets.forEach((ast) => {
                    if (ast.isEndUserAssigned) {
                        ListOfAssetUid = [...ListOfAssetUid, ast.uid]
                    }

                })
            }
        })

        const endUserObject = {
            userName: userName.name,
            number: number,
            countryCode: countryCode,
            assetUids: ListOfAssetUid

        }

        AddEndUserSchema.validate({
            userName: endUserObject.userName,
            number: endUserObject.number,
            countryCode: countryCode,
            // assetUidList:selectedAGtoAdd,
        })
            .then(() => {

                API.post(`${APIURL}/enduser`, endUserObject)
                    .then((res) => {
                        // console.log('res', res)
                        if (res && res.data && res.data.status === "success") {
                            onSaveButtonClick();
                            setUserName({});
                            setCountryCode(' ');
                            setNumber(' ');
                            setErrorMessage('');
                            setSelectedAGtoAdd([]);
                            cogoToast.success('Successfully added end user', { position: 'top-right' });
                        } else {
                            const errMsg = res.data && res.data.message
                            cogoToast.error(errMsg, {
                                position: 'top-right',
                            });
                        }
                    })
                    .catch((err) => {
                        const errMsg = err.data.data.message

                        cogoToast.error(errMsg, {
                            position: 'top-right',
                        });
                    })

            })
            .catch((error) => {
                // console.log('err', error)
                const errorMessage = error.message ? error.message : 'Error adding role';
                setErrorMessage(errorMessage);
            })
    }

    //to delete end user
    const onDeleteDialogSave = () => {
        let ListOfMobileNumber = []
        selectedRow && selectedRow.length && selectedRow.forEach((ag) => {
            const countryCode = ag.countryCode
            const mobile = ag.number
            const mergeNumber = countryCode.concat(mobile);
            ListOfMobileNumber = [...ListOfMobileNumber, mergeNumber]

        })

        API
            .delete(`${APIURL}/enduser`, {
                params: { contactUids: ListOfMobileNumber },
            })
            .then((resp) => {
                if (resp && resp.data && resp.data.status === "success") {
                    onSaveDeleteClick();
                    cogoToast.success('Successfully deleted selected end user(s)', { position: 'top-right' });
                }
                else {
                    const errMsg = resp.data && resp.data.message
                    cogoToast.error(errMsg, {
                        position: 'top-right',
                    });
                }
            })
            .catch((err) => {
                console.log('err', err)
            })

    }

    const handleChangeForAssetGroup = (selectedOption) => {
        // let assetGroupArr=[];
        // assetGroupArr=[...selectedAssetGroup,selectedOption]

        setSelectedAssetGroup([...selectedAssetGroup, selectedOption])

        if (openAddDialog) {

            const agGroupObj1 = {
                uid: selectedOption.value,
                id: selectedOption.label,

                assets: selectedOption.assets.map((ast) => {
                    const assetObj = {
                        uid: ast.uid,
                        id: ast.id,
                        isEndUserAssigned: true
                    }
                    return assetObj
                })
            }

            setSelectedAGtoAdd([...selectedAGtoAdd, agGroupObj1])


        } else {
            setSelectedOption(null)
        }
    }


    const getAssetGroupList = () => {
        const date = [moment().startOf('day').toDate(), moment().toDate()]
        const startInUnix = moment(date[0]).unix()
        const endInUnix = moment(date[1]).unix()
        API.get(`${APIURL}/assetDashboard?startTime=${startInUnix}&endTime=${endInUnix}&eventSummary=${false}`)
            .then((response) => {
                if (response.data.status === 'success') {

                    const assetsGroup = response.data.assetGroups;


                    const filterassetGpList = assetsGroup.map((ag) => {
                        let assetGroupObj;
                        assetGroupObj = { value: ag.uid, label: ag.id, uid: ag.uid, assets: ag.assets && ag.assets.length ? ag.assets : [] }
                        return assetGroupObj
                    })

                    setSelectList(filterassetGpList)

                }

            })
            .catch((err) => {
                console.log('Err', err)
            })
    }

    useEffect(() => {

        getAssetGroupList()

    }, [])





    const handleAssetGroupselection = (params) => {
        const { assetgpUid, value } = params;
        const { checked } = value.target;

        // if(checked){
        const newselectedAGtoAdd = [...selectedAGtoAdd];
        newselectedAGtoAdd.map((ag, agindex) => {
            if (ag.uid === assetgpUid) {
                newselectedAGtoAdd[agindex].assets.map((ast) => {
                    ast.isEndUserAssigned = checked
                })
            }

        })
        setSelectedAGtoAdd(newselectedAGtoAdd)

        // }

    }

    const handleAssetsSelection = (params) => {
        const { assetgpUid, value, assetUid } = params;
        const { checked } = value.target;
        const newselectedAGtoAdd = [...selectedAGtoAdd];
        newselectedAGtoAdd.map((ag, agindex) => {
            if (ag.uid === assetgpUid) {
                ag.assets.map((ast, astindex) => {
                    if (ast.uid === assetUid) {
                        newselectedAGtoAdd[agindex].assets[astindex].isEndUserAssigned = checked
                    }

                })
            }
        })
        setSelectedAGtoAdd(newselectedAGtoAdd)
    }




    //to filter react select list.
    const filterSelectList = selectList.filter(
        (item) =>
            !selectedAGtoAdd.map((el) => el.uid).includes(item.uid)
    );

    return (
        <>

            <Grid>
                <Dialog
                    open={openAddDialog}
                    TransitionComponent={Zoom}
                    //  onClose={this.onAddDialogClose}
                    aria-labelledby="form-dialog-title"
                    maxWidth="lg"
                    fullWidth
                    scroll="body"
                    fullScreen={!fullScreen}
                    PaperProps={{
                        style: {
                            backgroundColor: '#f2f2f2',
                            //  height:'60vh'
                        },
                    }}
                >

                    {/* <Grow open={openAddDialog}>
                    <Paper
                        className={classes.paperDiv}
                    > */}

                    {!fullScreen && (
                        <AppBar className={classes.appBar}>
                            <Toolbar style={{ backgroundColor: '#008081' }}>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleDialogClose}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                                <Typography variant="h6" className={classes.title}>
                                    {edit ? 'Edit' : 'Add '}
                                    {' '}
                                    User
                                </Typography>
                                <Button color="inherit" onClick={edit ? handleEditDialogSave : handleAddDialogSave}>
                                    update
                                </Button>
                            </Toolbar>
                        </AppBar>
                    )}
                    {
                        fullScreen && (
                            <DialogTitle className={classes.dialogtitle}>
                                {edit ? 'Edit' : 'Add '}
                                {' '}
                                User
                            </DialogTitle>
                        )
                    }


                    <DialogContent style={{ height: '68%' }} >
                        <Grid container style={{ paddingTop: !fullScreen ? '4rem' : '0rem', }} spacing={mdBreakPoint ? 8 : 0}>
                            <Grid item xl={4} lg={4} md={3} sm={12} xs={12} >
                                <Input
                                    id="userName"
                                    // label="User Name "
                                    placeholder="User Name"
                                    backgroundColor="#fff"
                                    type="string"
                                    margin="normal"
                                    value={userName.name || ''}
                                    onChange={(e) => { setUserName({ ...userName, name: e.target.value }); }}
                                />
                            </Grid>
                            <Grid container spacing={2} item xl={4} lg={4} md={5} sm={12} xs={12} style={{ display: 'flex' }}>
                                <Grid item xs={4}>
                                    <InputforCountrycode
                                        disabled={edit}
                                        id="countryCode"
                                        backgroundColor="#fff"
                                        placeholder="Country code"
                                        onWheel={(e) => e.target.blur()}
                                        margin="normal"
                                        type="number"
                                        value={countryCode === 0 ? " " : countryCode}
                                        onChange={(e) => { setCountryCode(e.target.value) }}
                                        className={classes.textField}
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                    <Input
                                        disabled={edit}
                                        id="name"
                                        placeholder="Contact Number"
                                        onWheel={(e) => e.target.blur()}
                                        type="number"
                                        margin="normal"
                                        value={!number ? " " : number}
                                        onChange={(e) => { setNumber(e.target.value) }}
                                        backgroundColor="#fff"
                                    />
                                </Grid>



                            </Grid>
                            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>

                                <ReactSelect
                                    styles={selectStyles}
                                    value={selectedOption || ''}
                                    onChange={handleChangeForAssetGroup}

                                    options={filterSelectList}
                                    placeholder={CompanyInfo && CompanyInfo.settings &&
                                        CompanyInfo.settings.warehouseType ?
                                        CompanyInfo && CompanyInfo.settings &&
                                        CompanyInfo.settings.warehouseType + " Id" :
                                        "Warehouse Id"}
                                    className={classes.inputTextField}
                                />
                            </Grid>

                        </Grid>
                        <Grid >
                            <Typography

                                sx={{
                                    textAlign: 'left',
                                    marginTop: '1rem',
                                    fontWeight: 'bold'
                                }}
                            >
                                Selected
                                {CompanyInfo && CompanyInfo.settings &&
                                    CompanyInfo.settings.warehouseType ?
                                    CompanyInfo && CompanyInfo.settings &&
                                    CompanyInfo.settings.warehouseType :
                                    " Warehouse"} :
                            </Typography>
                        </Grid>
                        <Grid style={{ height: '30vh' }}>
                            {
                                selectedAGtoAdd && selectedAGtoAdd.length ?
                                    selectedAGtoAdd.map((ag) => (
                                        <>
                                            <Grid >
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={ag.assets.some((ast) => ast.isEndUserAssigned === true)}
                                                                //    checked={selectedAGtoAdd && selectedAGtoAdd.length && selectedAGtoAdd.some((aG)=>aG.isUserAssigned) }
                                                                onChange={(e) => { handleAssetGroupselection({ value: e, assetgpUid: ag.uid, assets: ag.assets }) }}
                                                                sx={{ '& .MuiSvgIcon-root': { fontSize: 20, color: '#008081' } }}
                                                            />

                                                        }
                                                        label={ag.id}
                                                        style={{ color: '#008081' }}
                                                    />

                                                </FormGroup>

                                            </Grid>

                                            <Grid style={{ display: "grid", gridTemplateColumns: `repeat(${smBreakPoint ? 3 : 1}, 1fr)`, paddingLeft: '2rem', gridGap: '1rem' }} >

                                                {
                                                    ag.assets && ag.assets.length ?
                                                        ag.assets.map((ast) => (


                                                            <Grid key={ast.uid}>
                                                                <FormGroup>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                // checked={addSelectedAG && addSelectedAG.length && addSelectedAG.some((sel) => sel.assets.includes(ast.uid))}
                                                                                checked={ast.isEndUserAssigned}
                                                                                // onChange={(e) => { handleAssetsSelection({ value: e, assetgpUid: ag.value, assetUid: ast.value, uid:ast.uid }) }}
                                                                                onChange={(e) => { handleAssetsSelection({ value: e, assetgpUid: ag.uid, assetUid: ast.uid }) }}
                                                                                sx={{ '& .MuiSvgIcon-root': { fontSize: 16, color: '#008081', } }}
                                                                            />

                                                                        }
                                                                        label={ast.id}

                                                                    />

                                                                </FormGroup>

                                                            </Grid>



                                                        ))
                                                        : 'No chambers found'
                                                }
                                            </Grid>
                                        </>
                                    ))
                                    :
                                    <Grid sx={{ textAlign: 'center', paddingTop: '6rem' }}>
                                        <Typography variant="div" >No {CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.warehouseType ? CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.warehouseType : " Warehouse"} selected </Typography>
                                    </Grid>

                            }
                        </Grid>
                    </DialogContent>
                    {
                        fullScreen && (
                            <DialogActions>
                                <SecondaryButton text="Cancel" onClick={handleDialogClose} />

                                <PrimaryButton text="Save" type="submit" onClick={edit ? handleEditDialogSave : handleAddDialogSave} className={classes.saveButton} variant="contained" />
                            </DialogActions>
                        )
                    }

                    {errorMessage ? (
                        <div style={{ textAlign: 'left', padding: '1rem' }} >
                            <span className={classes.errorMessageText}>
                                *
                                {errorMessage}
                            </span>
                        </div>
                    ) : null}
                    {/* </Paper>
                </Grow> */}
                </Dialog>


                {/* --------------Dialog to delete -------------- */}
                <Dialog
                    open={deleteDialog}
                    TransitionComponent={Zoom}
                    // onClose={this.onDeleteDialogClose}
                    aria-labelledby="form-dialog-title"
                    maxWidth="sm"
                    scroll="body"
                >
                    <DialogTitle id="form-dialog-title">
                        <span className={classes.warning}>Warning</span>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to delete the selected End User ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <SecondaryButton text="No"
                            onClick={handleDeleteDialogClose}
                        />

                        <PrimaryButton text="Yes"
                            onClick={onDeleteDialogSave}
                        />

                    </DialogActions>
                </Dialog>


            </Grid>





        </>
    )
}




export default withStyles(styles)(EndUserForm);

