/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
// import Select from '@mui/material/Select';
import ReactSelect from 'react-select';

import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import List from '@mui/material/List';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import PersonIcon from '@mui/icons-material/Person';
import ListSubheader from '@mui/material/ListSubheader';
import Delete from '@mui/icons-material/Delete';
import PeopleIcon from '@mui/icons-material/People';
import {Tooltip } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import cogoToast from 'cogo-toast';
import API from 'API/apiConfig';
import zIndex from '@mui/material/styles/zIndex';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    // backgroundColor:'#fff'
    // maxWidth: 360,
    // backgroundColor: theme.palette.background.paper,
  },
  formControl: {

    width: '100%',


  },
  userlist: {
    height: '100%',
  },
  boxDiv: {
    // padding: '0.5rem',
    paddingBottom: '1rem !important',
    marginBottom: '0.5rem',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    flex: 1,
    display: 'flex',
    justifyContent: 'left',
    marginBottom: '5px',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight:'bold'
  },
  hoverClass: {
    color: 'red',
    cursor: 'pointer',
  marginTop:'8px'
  },
  nested: {
    // paddingLeft: theme.spacing(4),
    paddingLeft: "4px"
  },
  outerdiv: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  deletebutton: {
    marginTop: '5px',
  },
  divider: {
    marginTop: '0.6rem',
  },
}));


const selectStyles = {
  control: () => ({
    alignItems: 'center',
    backgroundColor: 'hsl(0,0%,100%)',
    marginTop: '1rem',
    borderRadius: '21px',
    borderBottom: '1px solid #dedede',
    cursor: 'default',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    minHeight: '38px',
    outline: '0 !important',
    position: 'relative',
    transition: 'all 100ms',
    boxSizing: 'border-box',
    width:'100%',
    zIndex:0,
  }),
};


const UserGroupList = (props) => {
  const {
    userGroupList, onGroupSelectionChange, onNotifictionSettingsChange, userGroupsfromWarehouse, groupuid,
    existingUserGroup, onexistingUserGroupchange, updateExistingUserList, isInWarehouses,changeNotiIcon
  } = props;
  const classes = useStyles();


  const [openedGroupId, setOpenedGroupId] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const selectedlist = (userGroupsfromWarehouse || []).filter((g) => (g.selected));
  const generaltypeUserGroupList = (userGroupsfromWarehouse || []).filter((t) => (t.type !== 'clientGroup'));
  //  const generaltypeUserGroupList=userGroupsfromWarehouse.filter(type)=>(type !== clientGroup);

  const APIURL = process.env.REACT_APP_APIURL;


  const changeSelection = (event) => {
    // console.log('Event is ', event)
    // if (onGroupSelectionChange) {
    //   onGroupSelectionChange({ ...event.target.value, selected: true });
    //   setShow(true);
    // }
    if (event && event.value) {
      // console.log('Event is@ ', event.value)
      const list = [];
      // list.push(...existingUserGroup, event.target.value);
      // setSelectedUserGroup(list);

      // list.push(event.target.value);
      list.push({ userGroupUid: event.userGroupUid, userGroupName: event.userGroupName || event.groupUid, notificationSettingsByAdmin: event.notificationSettingsByAdmin });
      onexistingUserGroupchange(list);
    }
  };


  const noUsersFound = () => {
    cogoToast.error('Users does not exists', { position: 'top-right' });
  };

  const handleClick = (groupUidfromWarehouse, groupuidfromchamber) => {
    let groupUid;
    if (groupUidfromWarehouse) {
      groupUid = groupUidfromWarehouse;
    } else {
      groupUid = groupuidfromchamber;
    }
    if (openedGroupId && openedGroupId === groupUid) {
      setOpenedGroupId(null);
      return;
    }
    setOpenedGroupId(groupUid);

    // if (groupuid) {
    //   groupuid(groupUid,userGroupsfromWarehouse);
    // }

    if (groupUid) {
      API.get(`${APIURL}/groups/${groupUid}/members`)
        .then((response) => {
          const userInGroupResponse = response.data.data;

          // const tochangeusergroup = userGroupsfromWarehouse.find((findid) => (findid.uid === groupUid));     // use find
          // console.log('tochangeusergroup', tochangeusergroup);
          // const requiredGroup = { ...tochangeusergroup, users: userInGroupResponse };
          // console.log('say', requiredGroup, userGroupsfromWarehouse);

          // to add users to particular useGroup.
          if (groupUidfromWarehouse) {
            const newGroups = existingUserGroup.map((a) => { // for warehouse
              if (a.userGroupUid !== groupUid) return a;
              a.users = userInGroupResponse;
              return a;
            });
            // console.log('say** from Warehouse', newGroups);
            updateExistingUserList(newGroups);
          } else {
            const newGroups = existingUserGroup.map((a) => { // for Chamber
              if (a.groupUid !== groupUid) return a;
              a.users = userInGroupResponse;
              return a;
            });
            // console.log('say** from Chamber', newGroups);
            updateExistingUserList(newGroups);
          }

          // setSelectedUserGroup(newGroups)
        })
        .catch((error) => {
          console.log('error getting users', error.message || error);
        });
    }
  };

  let filteredlist;
  let showUserslist = [];

  if (isInWarehouses) {
    if (openedGroupId) {
      // const selectedGroup = existingUserGroup.filter((gp) => (gp.uid === openedGroupId))[0];  // selected usergp to show USerlist
      const selectedGroup = existingUserGroup.filter((gp) => (gp.userGroupUid === openedGroupId))[0];

      if (!selectedGroup) {
        setOpenedGroupId(null);
        return;
      }
      const selectedlistofUsers = (selectedGroup.users || []).map((user) => ({
        ...user,
        // notificationSettings: (selectedGroup.notificationSettings || {})[user.emailId] || {},
      }));

      showUserslist = selectedlistofUsers;
    }

    // to delete the selected userGroup from the list of UserGroups
    // const filteredlist = (userGroupList || []).filter((data) => !selectedlist.map((g) => g.uid).includes(data.uid));

    if (existingUserGroup && existingUserGroup.length >= 0) {
      // console.log('EXIST BEFore filter', existingUserGroup);
      filteredlist = (userGroupsfromWarehouse || []).filter((data) => !existingUserGroup.map((g) => g.userGroupUid).includes(data.userGroupUid));
    }
  } else {
    if (openedGroupId) {
      // console.log('opengroupId ::: *', openedGroupId,existingUserGroup);
      const selectedGroup = existingUserGroup.filter((gp) => ((gp.groupUid || gp.userGroupUid) === openedGroupId))[0];
      // console.log('Selected gp', selectedGroup);
      if (!selectedGroup) {
        setOpenedGroupId(null);
        return;
      }
      const selectedlistofUsers = (selectedGroup.users || []).map((user) => ({
        ...user,
      }));

      showUserslist = selectedlistofUsers;
    }
    if (existingUserGroup && existingUserGroup.length >= 0) {
      filteredlist = (generaltypeUserGroupList || []).filter((data) => !existingUserGroup.map((g) => g.groupUid || g.userGroupUid).includes(data.groupUid || data.userGroupUid));
    }
  }


  // fun runs when we click delete button of USerGroup
  const handleRemoval = (deletedItem) => {
    // console.log('Deleted item ', deletedItem);
    // if (onGroupSelectionChange) {
    //   onGroupSelectionChange({ ...deletedItem, selected: false });
    // }
    if (onGroupSelectionChange) {
      onGroupSelectionChange(deletedItem);
    }
  };


  const handleCheckbox = (e, {
    email, notificationType, groupUidfromWarehouse, groupUidfromChamber,
  }) => {
    const groupUid = groupUidfromWarehouse || groupUidfromChamber;

    if (onNotifictionSettingsChange) {
      onNotifictionSettingsChange({
        groupUid, type: notificationType, value: e.target.checked, email,
      });
    }
  };
  // console.log('show User lsit ',existingUserGroup, filteredlist )
  // const userGroupList1 = filteredlist.filter(
  //   (item) => !existingUserGroup.map((el) => el.userGroupUid).includes(item.userGroupUid),
  // );

  const userGroupList1 = filteredlist.map((a) => ({
    label: a.userGroupName, userGroupName: a.userGroupName, userGroupUid: a.userGroupUid || a.groupUid, value: a.userGroupUid || a.groupUid, notificationSettingsByAdmin: {},
  }));


  // eslint-disable-next-line consistent-return
  return (
    <>
      <Grid item xs={12}>
        <div className={classes.boxDiv}>
          <Grid container xs={12}>
            <Grid item  lg={3} md={3} sm={5}  xs={7}>
              <Typography variant="h6" className={classes.title}>
                {/* <img src="../img/client.svg" alt="client" /> */}
          User Group settings

              </Typography>
            </Grid>
            <Grid item lg={9}  md={9}  sm={7} xs={5} sx={{margin:'auto'}}>
              <Divider  />
            </Grid>
          </Grid>


          {existingUserGroup && existingUserGroup.length >= 1 ? (
            <div style={{ overflow: 'auto', width: '100%' }}>
              <List
              sx={{
                width: '100%',
                maxWidth: '100%',
                 position: 'relative',
                overflow: 'auto',
                maxHeight: 300,
               
              }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={(
                  <ListSubheader style={{backgroundColor:'#f2f2f2', zIndex:0}} component="div" id="nested-list-subheader">
                    List of User Group(s)
                  </ListSubheader>
                )}
                className={classes.root}
              >
                {existingUserGroup
                  && existingUserGroup.length >= 1
                  && existingUserGroup.map((item) => (

                    <>

                      <div key={item.uid}>
                        <div className={classes.outerdiv}>
                          <div style={{ width: '100%' }}>
                            <ListItem
                              key={item}
                              button
                              onClick={(e) => { e.stopPropagation(); handleClick(item.userGroupUid, item.groupUid); }}
                            >

                              <ListItemIcon>
                                <PeopleIcon />
                              </ListItemIcon>

                              <ListItemText primary={item.userGroupName || item.groupName} />

                              {openedGroupId === (item.userGroupUid ? item.userGroupUid : item.groupUid) ? (<ExpandLess />) : (
                                <>
                                  <span style={{ color: '#008081' }}> 
                                  {
                                    changeNotiIcon ?
                                    <Tooltip title="Notification Settings" >
                                   <NotificationsActiveIcon />
                                   </Tooltip>
                                    :
                                   ' Notification settings'
                                  }
                                 
                                  </span>
                                  <ExpandMore />
                                </>
                              )}


                            </ListItem>
                          </div>
                          <div className={classes.deletebutton}>
                            <Delete
                              className={classes.hoverClass}
                              onClick={() => handleRemoval(
                                item.userGroupUid || item.groupUid,
                              )}
                            />
                          </div>
                        </div>
                        <Divider />

                        <Collapse
                          key={item.userGroupUid}
                          component="li"
                          in={(openedGroupId === (item.userGroupUid ? item.userGroupUid : item.groupUid))}

                          timeout="auto"
                          unmountOnExit
                        >
                          <List disablePadding>

                            {(showUserslist && showUserslist.length >= 1)
                              ? showUserslist.map((user) => {
                                const labelId = `checkbox-list-secondary-label-${user.email}`;
                                const { email } = user;
                                return (

                                  <ListItem  key={user.email} button>

                                    <ListItemAvatar>
                                      <PersonIcon />
                                    </ListItemAvatar>
                                    <ListItemText key={user.emailId} id={labelId} primary={user.email} 
                                     secondary={
                                      changeNotiIcon? 
                                      <>
                                      <FormControlLabel
                                      control={(
                                        <Checkbox
                                          color="primary"
                                          value="SMS"
                                          onChange={(e) => handleCheckbox(e, {
                                            email, notificationType: 'SMS', groupUidfromWarehouse: item.userGroupUid, groupUidfromChamber: item.groupUid,
                                          })}
                                          // checked={(user.notificationSettings && user.notificationSettings.SMS) ? !!(user.notificationSettings.SMS) : false}
                                          checked={(item.notificationSettingsByAdmin && item.notificationSettingsByAdmin[email] && item.notificationSettingsByAdmin[email].SMS) ? !!(item.notificationSettingsByAdmin[email].SMS) : false}
                                          style={{ color: '#008081' }}
                                        />
                                      )}
                                      label="SMS"

                                    />
                                    <FormControlLabel
                                    control={(
                                      <Checkbox
                                        // color="primary"
                                        value="email"

                                        onChange={(e) => handleCheckbox(e, {
                                          email, notificationType: 'email', groupUidfromWarehouse: item.userGroupUid, groupUidfromChamber: item.groupUid,
                                        })}

                                        // checked={(user.notificationSettings && user.notificationSettings.email) ? !!(user.notificationSettings.email) : false}
                                        checked={(item.notificationSettingsByAdmin && item.notificationSettingsByAdmin[email] && item.notificationSettingsByAdmin[email].email) ? !!(item.notificationSettingsByAdmin[email].email) : false}
                                        style={{ color: '#008081' }}
                                      />
                                    )}
                                    label="EMAIL"
                                  />

                                  {/* <FormControlLabel
                                    control={(
                                      <Checkbox
                                        color="primary"
                                        value="push"
                                        onChange={(e) => handleCheckbox(e, {
                                          email, notificationType: 'push', groupUidfromWarehouse: item.userGroupUid, groupUidfromChamber: item.groupUid,
                                        })}

                                        // checked={(user.notificationSettings && user.notificationSettings.push) ? !!(user.notificationSettings.push) : false}
                                        checked={(item.notificationSettingsByAdmin && item.notificationSettingsByAdmin[email] && item.notificationSettingsByAdmin[email].push) ? !!(item.notificationSettingsByAdmin[email].push) : false}
                                        style={{ color: '#008081' }}
                                      />
                                    )}
                                    label="PUSH"
                                  /> */}
                                  </>:' '

                                     }/>
                                     {!changeNotiIcon ? 
<>
<ListItemSecondaryAction>
                                      <FormControlLabel
                                        control={(
                                          <Checkbox
                                            color="primary"
                                            value="SMS"
                                            onChange={(e) => handleCheckbox(e, {
                                              email, notificationType: 'SMS', groupUidfromWarehouse: item.userGroupUid, groupUidfromChamber: item.groupUid,
                                            })}
                                            // checked={(user.notificationSettings && user.notificationSettings.SMS) ? !!(user.notificationSettings.SMS) : false}
                                            checked={(item.notificationSettingsByAdmin && item.notificationSettingsByAdmin[email] && item.notificationSettingsByAdmin[email].SMS) ? !!(item.notificationSettingsByAdmin[email].SMS) : false}
                                            style={{ color: '#008081' }}
                                          />
                                        )}
                                        label="SMS"

                                      />

                                      <FormControlLabel
                                        control={(
                                          <Checkbox
                                            // color="primary"
                                            value="email"

                                            onChange={(e) => handleCheckbox(e, {
                                              email, notificationType: 'email', groupUidfromWarehouse: item.userGroupUid, groupUidfromChamber: item.groupUid,
                                            })}

                                            // checked={(user.notificationSettings && user.notificationSettings.email) ? !!(user.notificationSettings.email) : false}
                                            checked={(item.notificationSettingsByAdmin && item.notificationSettingsByAdmin[email] && item.notificationSettingsByAdmin[email].email) ? !!(item.notificationSettingsByAdmin[email].email) : false}
                                            style={{ color: '#008081' }}
                                          />
                                        )}
                                        label="EMAIL"
                                      />

                                      {/* <FormControlLabel
                                        control={(
                                          <Checkbox
                                            color="primary"
                                            value="push"
                                            onChange={(e) => handleCheckbox(e, {
                                              email, notificationType: 'push', groupUidfromWarehouse: item.userGroupUid, groupUidfromChamber: item.groupUid,
                                            })}

                                            // checked={(user.notificationSettings && user.notificationSettings.push) ? !!(user.notificationSettings.push) : false}
                                            checked={(item.notificationSettingsByAdmin && item.notificationSettingsByAdmin[email] && item.notificationSettingsByAdmin[email].push) ? !!(item.notificationSettingsByAdmin[email].push) : false}
                                            style={{ color: '#008081' }}
                                          />
                                        )}
                                        label="PUSH"
                                      /> */}
                                    </ListItemSecondaryAction>
</>
                                     :
                                     ''
                                     }
                                    

                                  </ListItem>

                                );
                              })

                              : (
                                <ListItem button>
                                  <ListItemText style={{ textAlign: 'center' }} primary="No Users Found" />
                                </ListItem>
                              )}


                          </List>
                        </Collapse>

                      </div>

                    </>
                  ))}


              </List>


            </div>
          ) : null}


          {/* <FormControl className={classes.formControl}>
            <InputLabel htmlFor="role">ADD UserGroup</InputLabel>
            <Select

              id="demo-mutiple-checkbox"
              // value={usegroupList}
              // options={userGroupList1}
              onChange={changeSelection}
              placeholder="Add UserGroup"
            >
              {(filteredlist && filteredlist.length >= 1)
                ? filteredlist.map((data) => (
                  <MenuItem key={data.userGroupUid} value={data}>
                    {data.userGroupName }
                  </MenuItem>
                )) : (
                  <MenuItem>

                    <ListItemText style={{ textAlign: 'center' }} primary="No Options" />


                  </MenuItem>
                )}

            </Select>

          </FormControl> */}
          <ReactSelect
            styles={selectStyles}
            value={selectedOption}
            onChange={changeSelection}

            options={userGroupList1}
            placeholder="Select user group to add"
          />
        </div>
      </Grid>
    </>
  );
};


export default UserGroupList;
