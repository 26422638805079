/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, useContext } from 'react';
import { withStyles } from '@mui/styles';
import Menu from 'material-ui-popup-state/HoverMenu';
import { NavLink, useLocation } from 'react-router-dom';
import UserContext from 'Context/UserContext';
import MenuItem from '@mui/material/MenuItem';

import {
  usePopupState,
  bindHover,
  bindMenu,
} from 'material-ui-popup-state/hooks';

const ParentPopupState = React.createContext(null);
const styles = {
  NavLink: {
    textDecoration: 'none',
    pointer: 'cursor',
    fontSize: '14px',
    fontFamily: 'Montserrat',
    color: 'black',
    marginBottom: '10px',
  },
  menuItems: {
    marginTop: '10px',
  },
};





const CascadingMenuItem = ({ classes }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const location = useLocation();
  const popupState = usePopupState({
    popupId: 'demoMenu',
    variant: 'popover',
    disableAutoFocus: true,
    deferOpenClose: false,
  });


  const userContext1 = useContext(UserContext);
  const { userPermissions , CompanyInfo } = userContext1;

  const ControlPanel = [
    {
      name: 'assetGroups',
      id: 'assetGroups',
      path: CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.warehouseType ? 
      CompanyInfo.settings.warehouseType
      :'Warehouse',
      pathname: '/controlpanel/assetGroups',
    },
    {
      name: 'devices',
      id: 'devices',
      path: 'Devices',
      pathname: '/controlpanel/devices',
    },
    {
      name: 'clients',
      id: 'cients',
      path: 'Clients',
      pathname: '/controlpanel/clients',
    },
    {
      name: 'admin',
      id: 'admin',
      path: 'Access Management',
      pathname: '/controlpanel/admin/UserGroups',
    },
    // audit option
    // {
    //   name: 'audit',
    //   path: 'Audit',
    //   pathname: '/controlpanel/audit',
    // },
  ];

  useEffect(() => {
   
    if (location.pathname) {
     
      // updateSingleChamber(true);
      setSelectedIndex(ControlPanel.findIndex((a) => a.pathname === location.pathname));
    }
    if(location.pathname === '/dashboard'){
     
      // updateSingleChamber(true);

    }
  }, [location]);

  //  console.log('ControlPanel is ', ControlPanel,selectedIndex )
  return (
    <div>
      <div {...bindHover(popupState)}>
        Control Panel
      </div>
      <ParentPopupState.Provider value={popupState}>
        <Menu
          // className={classes.menuItems}
          {...bindMenu(popupState)}
        anchorOrigin={ {vertical: 'bottom', horizontal: 'bottom'} }
        transformOrigin={ {vertical: 'top', horizontal: 'left' }}
          getContentAnchorEl={null}
        >


          {
            ControlPanel.map((item, index) => {
              if (item.name && item.name !== 'admin') {
                return (
                  userPermissions && userPermissions[item.name] && (userPermissions[item.name].read || userPermissions[item.name].create || userPermissions[item.name].update || userPermissions[item.name].delete)
                  && (
                  <NavLink to={`${item.pathname}`} className={classes.NavLink} key={item.name}>
                    <MenuItem key={item.id} selected={index === selectedIndex}>
                      {item.path}
                    </MenuItem>
                  </NavLink>

                  )
                );
              }
              if (item.name === 'admin') {
                return (
                  <NavLink to={`${item.pathname}`} className={classes.NavLink} key={item.name}>
                    <MenuItem
                      key={item.id}
                      selected={index === selectedIndex}
                    >
                      {item.path}
                    </MenuItem>
                  </NavLink>
                );
              }
            })
          }

        </Menu>
      </ParentPopupState.Provider>
    </div>
  );
};

export default (withStyles(styles)(CascadingMenuItem));
