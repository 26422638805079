// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://bit.ly/CRA-PWA

const axios = require('axios');

const API_URL = process.env.REACT_APP_APIURL;
const PUBLIC_VAPID_KEY = 'BNvoj3X_5ImLe21XPbTzjtGQh8p3teS2xAu0nXeHo8HrEqMpqH8WQaMVPHDYNT3S96x5EyAiZjg8FQ4-0mNVIG8';

const isLocalhost = Boolean(
  window.location.hostname === 'localhost'
  // [::1] is the IPv6 localhost address.
  || window.location.hostname === '[::1]'
  // 127.0.0.1/8 is considered localhost for IPv4.
  || window.location.hostname.match(
    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
  ),
);

function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

function registerServiceWorker(swUrl) {
  return new Promise((resolve, reject) => {
    navigator.serviceWorker.register(swUrl)
      .then((registration) => {
        resolve(registration);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

async function askPermission() {
  return new Promise((resolve, reject) => {
    const permissionResult = Notification.requestPermission((result) => {
      resolve(result);
    });

    if (permissionResult) {
      permissionResult.then(resolve, reject);
    }
  })
    .then((permissionResult) => {
      if (permissionResult !== 'granted') {
        throw new Error('Permission not granted');
      }
    });
}

function subscribeToPushService(registration, subscribeOptions) {
  return registration.pushManager.subscribe(subscribeOptions);
}

async function sendSubscriptionToBackend(pushSubscription) {
  return new Promise((resolve, reject) => {
    axios.patch(`${API_URL}/users`,
      [{
        op: 'replace',
        path: '/pushSubscription',
        value: pushSubscription,
      },
      ],{
        headers:{
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        }
      })
      .then((response) => {
        // console.log('response from server ', response.data);
        resolve(response.data);
      })
      .catch((error) => reject(error));
  });
}

function checkValidServiceWorker(swUrl, config) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl)
    .then((response) => {
      // console.log('fetch response ', response);
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get('content-type');
      // console.log('content type ', contentType);
      if (
        response.status === 404
        || (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        // No service worker found. Probably a different app. Reload the page.
        // console.log('no service worker found');
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // Service worker found. Proceed as normal.
        registerServiceWorker(swUrl, config)
          .then((registration) => {
            askPermission()
              .then(() => {
                const subscribeOptions = {
                  userVisibleOnly: true,
                  applicationServerKey: urlBase64ToUint8Array(
                    PUBLIC_VAPID_KEY,
                  ),
                };
                subscribeToPushService(registration, subscribeOptions)
                  .then(async (pushSubscription) => {
                    // console.log('Received PushSubscription: ', JSON.stringify(pushSubscription));
                    // return pushSubscription;
                    const backendResponse = await sendSubscriptionToBackend(JSON.stringify(pushSubscription))
                    .catch((err) => {
                      console.log('Error sending subscription to backend ', err);
                    });
                      
                    if (backendResponse.status === 'fail') {
                      console.error('Updating subscription failed');
                    }
                      
                  });
              })
              .catch((err) => {
                console.warn('User denied permission ', err);
              });
          })
          .catch(() => {
            console.error('failed to register service worker');
          });
      }
    })
    .catch(() => {
      console.log(
        'No internet connection found. App is running in offline mode.',
      );
    });
}

export function register(config) {
  if (/* process.env.NODE_ENV === 'production' &&  */'serviceWorker' in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    // console.log('publicUrl ', publicUrl);
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return;
    }
    window.addEventListener('load', () => {
      const swUrl = `${process.env.PUBLIC_URL}/pushNotifications.js`;
      // console.log('swUrl', swUrl)

      if (isLocalhost) {
        // This is running on localhost. Let's check if a service worker still exists or not.
        checkValidServiceWorker(swUrl, config);

        // Add some additional logging to localhost, pointing developers to the
        // service worker/PWA documentation.
        navigator.serviceWorker.ready.then(() => {
          // console.log(
          //   'This web app is being served cache-first by a service '
          //   + 'worker. To learn more, visit https://bit.ly/CRA-PWA',
          // );
        });
      } else {
        // Is not localhost. Just register service worker
        registerServiceWorker(swUrl, config)
          .then((registration) => {
            askPermission()
              .then(() => {
                const subscribeOptions = {
                  userVisibleOnly: true,
                  applicationServerKey: urlBase64ToUint8Array(
                    PUBLIC_VAPID_KEY,
                  ),
                };
                subscribeToPushService(registration, subscribeOptions)
                  .then(async (pushSubscription) => {
                    // console.log('Received PushSubscription: ', pushSubscription);
                    // return pushSubscription;
                    const backendResponse = await sendSubscriptionToBackend(JSON.stringify(pushSubscription))
                    .catch((err) => {
                      console.error('Error sending subscription to backend ', err);
                    });
                       
                  if (backendResponse.status === 'fail') {
                        console.error('Updating subscription failed');
                      }
                 
                  });
              })
              .catch((err) => {
                console.warn('User denied permission ', err);
              });
          })
          .catch(() => {
            console.error('failed to register service worker');
          });
      }
    });
  }
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
      registration.unregister();
    });
  }
}
