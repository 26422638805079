import React,  { useContext,useState
  }  from "react";
  import UserContext from 'Context/UserContext';
import { withStyles } from '@mui/styles';
import {
    Button, CircularProgress, Dialog, DialogTitle, DialogContent,
    DialogActions,
     MenuItem, List, ListItem, ListItemAvatar,FormControl,
     Divider
  } from '@mui/material';
  import IconButton from '@mui/material/IconButton';
 import PeopleIcon from '@mui/icons-material/People';
 import Grid from '@mui/material/Grid';
 import PrimaryButton from "components/PrimaryButton";
 import SecondaryButton from "components/SecondaryButton";
 import Input from "components/Input";
 import SelectField from "components/SelectField";
 import PersonIcon from '@mui/icons-material/Person';
 import DeleteIcon from '@mui/icons-material/Delete';
 import ListItemText from '@mui/material/ListItemText';
 import API from 'API/apiConfig';
 import cogoToast from 'cogo-toast';
 import toastOptions from 'utils/toast';
 import { useMediaQuery, createTheme } from '@material-ui/core';
 import { Tooltip } from "@mui/material";
 import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
 

const styles={
externalDiv:{
    width: '90%',
    margin: '5%',
    height: 'auto',
},
dialogtitle: {
    textAlign: 'left',
    color: '#000',
    fontWeight: 'bold',
    fontFamily:'Montserrat',
    fontSize:'20px'
  },
  addUserForm: {

    width:'100%'
   

  },
  hoverClass: {
    color: 'red',
    cursor: 'pointer',
    marginRight: '1em',
  },
  appBar: {
    position: 'sticky',
    
  },
}

const APIURL = process.env.REACT_APP_APIURL;

const ManageUsersForClients=(props)=>{

    const {loading, classes,selectedRowData, fullScreen}= props;
    // const theme = useTheme();
    const companyDetails = useContext(UserContext); // react context
  const { userPermissions } = companyDetails;

  const [openAddUserDialog, setOpenAddUserDialog] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [accessType, setAccessType] = useState(' ');
  const [addedUserList, setAddedUserList] = useState([]);
  const [usersInGroup, setUsersInGroup] = useState([]);
const [selectedClientName, setSelectedClientName]= useState('')
 const [loadingscreen, setLoadingscreen]=useState(false)

 const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 670,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

 const breakPoint= useMediaQuery(theme.breakpoints.up('sm'));


const addOrDeleteUsers = !!(selectedRowData && selectedRowData.accessType === 'Admin');

  const getUsersInGroup = () => {
    if (selectedRowData && selectedRowData.length > 0) {
    
      const userData = [];
      const { groupId } = { ...selectedRowData[0] };
      if (groupId) {
        API.get(`${APIURL}/groups/${groupId}/members`)
          .then((response) => {
          
            const userInGroupResponse = response.data.data;
            if (userInGroupResponse) {
              userInGroupResponse.map((user) => {
                const userObject = {
                  groupId: user.groupId,
                  id: user.id,
                  userName: user.username,
                  email: user.email,
                  accessType: user.accessType,
                };
                userData.push(userObject);
              });
              // setLoading(false);
              setUsersInGroup(userData);
              setAddedUserList(userData);
            }
          })
          .catch((error) => {
            console.log("error getting users", error.message || error);
            // setLoading(false);
          });
      }
    }
  };



  const onUserManageButtonClick=()=>{
    setOpenAddUserDialog(true);
    if (selectedRowData && selectedRowData.length > 0) {
      
        getUsersInGroup();
        setSelectedClientName(selectedRowData[0].name)
      }
  }

  const onCloseUsersDialog = () => {
    setOpenAddUserDialog(false);
  };

  const onUserDialogClose = () => {
    setOpenAddUserDialog(false);
    setUserEmail('');
    setAccessType(' ');
  };

  const changeemail = (e) => {
    setUserEmail(e.target.value);
  };

  const changeAccessType = (event) => {
    setAccessType(event.target.value);
  };


  const handleRemovalUser = (deleteUser) => {
    const { groupId } = usersInGroup.find((u) => u.id === deleteUser);
    if (selectedRowData && selectedRowData.length > 0 && usersInGroup) {
      API.delete(`${APIURL}/groups/users?id=${deleteUser}&groupId=${groupId}`)
        .then((deletedUser) => {
          if (deletedUser.data.status === 'success') {
            cogoToast.success('Deleted user from group', toastOptions);

           
            setUserEmail('');
            setAccessType('');

            const newUsers = usersInGroup.filter((user) => user.id !== deleteUser);

            
            setAddedUserList([...newUsers]);
            setUsersInGroup([...newUsers]);
            
          } else {
            console.error('Error deleting user');
          
          }
        }).catch((error) => {
          console.log('error deleting user', error.message || error);
        
        });
    }
  };

  const addUser = (emailSent, aT) => {
    if (selectedRowData) {
      const { groupId } = { ...selectedRowData[0] };

      const lowerCaseEmail = emailSent.toLowerCase();
      setLoadingscreen(true)
      if (groupId) {
        API.patch(`${APIURL}/groups/users?email=${lowerCaseEmail}&groupId=${groupId}&accessType=${aT}`)
          .then((addedUser) => {
            if (addedUser.data.status === 'success') {
              const newUser = addedUser.data.data;
             

              const userlistValues = [];
              userlistValues.push(...addedUserList, {
                id: newUser.id, email: emailSent, accessType: aT, groupId: newUser.groupId,
              });

              setAddedUserList(userlistValues);
              setUsersInGroup(userlistValues);


              setLoadingscreen(false)
              setUserEmail('');
              setAccessType('');
              cogoToast.success('Added user to group', toastOptions);
            } else {
              
              cogoToast.error(addedUser.data.message, toastOptions);
              setUserEmail('');
              setAccessType('');
              setLoadingscreen(false)
            }
          }).catch((error) => {
            console.log('error adding user', error.message || error);
            setLoadingscreen(false)
            setUserEmail('');
            setAccessType('');
          });
      }
    }
  };

    return(
        <>
      {loading ? (
        <div className={classes.overlay}>
          <CircularProgress
            style={{ position: 'absolute', top: '50%', left: '50%' }}
          />
        </div>
      ) : (
        <>
          {
            ((userPermissions && userPermissions.groups && userPermissions.groups.read)) && (
                <Button
                variant="text"
                disabled={!(selectedRowData && selectedRowData.length === 1)}
                onClick={onUserManageButtonClick}
                sx={{ color: '#008081', textTransform: "capitalize", fontSize: "16px"  , marginBottom:'10px', }}
              >
                   <PeopleIcon style={{marginRight:'0.1rem'  }} />
               { breakPoint ?  'Manage Users' : ''} 
              </Button>
            )
            }
          <Dialog
            open={openAddUserDialog}
            onClose={onCloseUsersDialog}
           
            aria-labelledby="form-dialog-title"
            maxWidth="sm"
            fullWidth
            fullScreen={fullScreen}
            scroll="body"
           
            PaperProps={{
                style: {
                  backgroundColor: '#f2f2f2',
                },
              }}
          >

{!fullScreen ? 
 <DialogTitle className={classes.dialogtitle}>Manage Users of {selectedClientName} </DialogTitle> 
 :  (
                          <AppBar  className={classes.appBar}>
                          <Toolbar style={{backgroundColor:'#008081'}}>
                          <IconButton
                          edge="start"
                          color="inherit"
                          
                          onClick={onCloseUsersDialog}
                          aria-label="close"
                        >
                          <CloseIcon />
                        </IconButton>
                          <Typography variant="h6" className={classes.title}>
                          Manage Users of {selectedClientName}
                        </Typography>
                         
                        </Toolbar>
                        </AppBar>
                          )}
              
             
               <DialogContent>
              {((userPermissions && userPermissions.groups && userPermissions.groups.update) || (addOrDeleteUsers))
          && (
            <>
           
             <Grid container style={{padding:'1rem', paddingTop :breakPoint ? " 0rem" : "4rem" }} >
            <Grid item xs={5}>
              <span className={classes.dialogtitle} >Add User </span>
            </Grid>
            <Grid item xs={7}>
              <Divider style={{marginTop:'0.6rem'}}/>
              </Grid>
             </Grid>
             <Grid container  spacing={2}>
               <Grid item xs={12}>
               <Input 
               value={userEmail} 
             onChange={changeemail}
             backgroundColor="#fff"
                className={classes.addUserForm} placeholder="Email" />
               </Grid>
               <Grid item xs={12}>
               <FormControl className={classes.addUserForm}>
                   
                    <SelectField
                    variant="standard"
                    // placeholder="Access Type"
                      labelId="access-type"
                      id="access-type"
                      value={accessType}
                      backgroundColor="#fff"
                      onChange={changeAccessType}
                    >
                            <MenuItem value=" "  disabled >
                         <span style={{color:"#9E9E9E", marginLeft:'8px', fontSize:'14px', fontFamily:'Montserrat'}}>Accesss type</span>
          </MenuItem>
                      <MenuItem value="Admin">Admin</MenuItem>
                      <MenuItem value="User">User</MenuItem>


                    </SelectField>
                  </FormControl>
                 </Grid>
                 <Grid container >
                   <Grid item xs={7}> </Grid>
                   <Grid item xs={5}   style={{paddingTop:'2rem', textAlign:'right'}} > 
                   <PrimaryButton
                  
                  onClick={() => {
                    addUser(userEmail, accessType);
                  }}
                  disabled={!((userPermissions && userPermissions.groups && userPermissions.groups.update) )}
                  type="submit"
                 
                  text="Add "
                />
                 
               
                   </Grid>
                
                 </Grid>
                
             </Grid>
             </>
          )}
             <Grid container style={{padding:'1rem', paddingTop:'3.5rem'}} >
            <Grid item lg={4} md={4} sm={4} xs={6}>
              <span className={classes.dialogtitle} > List of User(s) </span>
            </Grid>
            <Grid item lg={8} md={8} sm={8} xs={6}>
              <Divider style={{marginTop:'0.6rem'}}/>
              </Grid>
             </Grid>
{loadingscreen ? 
  <div className={classes.overlay}>
          <CircularProgress
            style={{ position: 'absolute', top: '50%', left: '50%' }}
          />
        </div>
: (
  <> {

    <List
     sx={{
      width: '100%',
      maxWidth: '100%',
       position: 'relative',
      overflow: 'auto',
      maxHeight: 300,
     
    }}>
      
    
      { addedUserList && addedUserList.length > 0
    
        ? addedUserList.map((user) => (
          (
            <div key={user.id} style={{ marginLeft: '1em' }}>
              <ListItem>
                <ListItemAvatar>
    
                  <PersonIcon />
    
                </ListItemAvatar>
    
                <ListItemText primary={user.email} secondary={user.accessType} />
                <Tooltip title="Delete"  >
                <IconButton
                            style={{ color:
                              !(
                                (userPermissions &&
                                  userPermissions.groups &&
                                  userPermissions.groups.update) ||
                                  addOrDeleteUsers
                              )?
                              'rgba(0,0,0,0.26)':'red'
                            }}
                            edge="end"
                            aria-label="delete"
                            disabled={(!((userPermissions && userPermissions.groups && userPermissions.groups.update) || (addOrDeleteUsers)))}
                            onClick={() => handleRemovalUser(
    
                              user.id,
                            )}
                          >
                            <DeleteIcon style={{paddingTop:'0.8rem'}}/>
                          </IconButton>
              
                </Tooltip>
    
              </ListItem>
            </div>
    
          )
        ))
        : (
    
          <div style={{ textAlign: 'center',fontFamily:'Montserrat' }}> No Users </div>
        )}
    
    
    </List>
    
    }

  </>
)}
            
              
            


              <DialogActions>
                <SecondaryButton 
                onClick={onUserDialogClose} 
                 text="Close"/>
                    
              
                {/* <PrimaryButton
                  onClick={onUserDialogClose} 
                  disabled={!((userPermissions && userPermissions.groups && userPermissions.groups.update) )}
                  type="submit"
               
                  variant="contained"
                  text="Save and Close"
                /> */}
                  
               
              </DialogActions>

              <h4 style={{ color: '#777' }}>
                  Note:An email containing temporary password would be send to user once you add the user
                  . The user would also recieve an email with a link to reset password. The email would be valid for 24 hours.
              </h4>

            </DialogContent>
          </Dialog>











        
        </>
      )}
    </>
    )
}
export default withStyles(styles)(ManageUsersForClients);