/* eslint-disable react/prop-types */
/*
@author: Priya Agarwal
@email: priya@adaptideations.com
@version: 1.0
*/

import React, { Component } from 'react';
// import Autosuggest from 'react-autosuggest';
import { withStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
// import Select from '@mui/material/Select';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';

import PropTypes from 'prop-types';
import axios from 'axios';
import { Tooltip } from '@mui/material';

// css styles
const styles = {
  searchIcon: {
    color: 'grey',
    position: 'relative',
    top: '10px',
    left: '-30px',
  },
  filterMenu: {
    height: '42px',
    padding: '5px',
    borderBottom: 'none !important',
    '&:hover': {
      borderBottom: 'none !important',
    },
    background: 'white',
    border: '1px solid #868585',
    borderTopLeftRadius: '3px',
    borderBottomLeftRadius: '3px',
    // borderRadius:'50px'
  },
  selectionBox: {


    // background: 'none',
    // width: '20%',
    // height: '35px',
    // borderRadius: '30px',
    // outline: 'none',
    // '&:focus': {
    //   border: '2px solid #00adef',
    // },
    // margin: '5px 10px',
    // display: 'flex',
    // flexWrap: 'wrap',
  },
  searchInputDiv: {
    // width: '25%',
    minHeight: '34px',
    minWidth: '100%',
   
    // padding: '8px',
    position: 'relative',
  },
  searchInput: {
    backgroundColor:'#f2f2f2',
    borderRadius: '50px',
    width: '18.3rem',
    border:'1px solid rgba(97,97,97,0.5)',
    height: '34px',
    boxSizing: 'border-box',
    padding: '0.8rem',
   
    "&:focus":{
      outline: 'none'
    }
  },
  '@media screen and (max-width: 1200px)':{
    searchInput: {
      backgroundColor:'#f2f2f2',
      borderRadius: '50px',
      width: '15rem',
      border:'1px solid rgba(97,97,97,0.5)',
      height: '34px',
      boxSizing: 'border-box',
      padding: '0.8rem',
     
      "&:focus":{
        outline: 'none'
      }
    },
   },
   '@media screen and (max-width: 900px)':{
    searchInput: {
      backgroundColor:'#f2f2f2',
      borderRadius: '50px',
      width: '12rem',
      border:'1px solid rgba(97,97,97,0.5)',
      height: '34px',
      boxSizing: 'border-box',
      padding: '0.8rem',
     
      "&:focus":{
        outline: 'none'
      }
    },
   },
 '@media screen and (max-width: 600px)':{
  searchInputDiv: {
    // width: '25%',
    minHeight: '34px',
    minWidth: '280px',
   
    padding: '8px',
    position: 'relative',
  },
  searchInput: {
    backgroundColor:'#f2f2f2',
    borderRadius: '50px',
    width: '15rem',
    border:'1px solid rgba(97,97,97,0.5)',
    height: '34px',
    boxSizing: 'border-box',
    padding: '0.8rem',
   
    "&:focus":{
      outline: 'none'
    }
  },
  
 },
 
 


};

/**
 * General component for search bar.
 */
class SearchInput extends Component {
  constructor(props) {
    super(props);
    // Autosuggest is a controlled component.
    // This means that you need to provide an input value
    // and an onChange handler that updates this value (see below).
    // data we will be getting from the response of apiurl
    // Suggestions also need to be provided to the Autosuggest,
    // and they are initially empty because the Autosuggest is closed.
    this.state = {
      value: '',
      suggestions: [],
      searchValue:'',

    };
    
  }


  /**
* This is a function.
* Editing the suggestions on changing the input value
* @param {string} value - A string param
* @example
*    onChange(event, {value})
*/
  onChange = ({ newValue }) => {
    this.setState({
      value: newValue,
    });
  };

//   handleSearchChange=(event)=>{
// console.log('Hii', event.target.value)
//   }

  /**
    * This is a function.
    * Autosuggest to calculate suggestions for any given input value.
    * @param {string} value - A string param
    * @example
    *     getSuggestions('hello')
    */
  getSuggestions = (value) => new Promise((resolve, reject) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    const {
      apiUrl,
      data,
      filterKey,
    } = this.props;
    if (apiUrl) {
      axios.get(`${apiUrl}${value}`)
        .then((response) => {
          if (response.data.success === true) {
            resolve(response.data.data);
          } else {
            reject(response.data.message);
          }
        })
        .catch((err) => {
          console.error(err.message);
          reject(err);
        });
    } else if (data) {
      if (filterKey) {
        const filteredValues = inputLength === 0
          ? data
          : data.filter((info) => info[filterKey].toLowerCase().includes(inputValue));
        resolve(filteredValues);
      } else {
        console.error('Please provide the filterKey');
      }
    } else {
      reject('No data found');
      console.error('Please provide either any apiUrl or data.');
    }
  });

  /**
* This is a function.
*  Autosuggest will call this function every time you need to update suggestions.
 You already implemented this logic above, so just use it.
* @param {string} value - A string param
* @example
*    onSuggestionsFetchRequested('hel')
*/
  onSuggestionsFetchRequested = ({ value }) => {
    this.getSuggestions(value).then((suggestions) => {
      const {
        onValueChange,
      } = this.props;
      this.setState({
        suggestions,
      }, () => {
        if (onValueChange) {
          onValueChange(value, suggestions);
        }
      });
    })
      .catch((err) => {
        const {
          onValueChange,
        } = this.props;
        console.error(err.message);
        if (onValueChange) {
          onValueChange('', []);
        }
      });
  };


  /**
 * This is a function.
 *  When suggestion is clicked, Autosuggest needs to populate the input
    based on the clicked suggestion.Autosuggest to calculate the
    input value for every given suggestion.
 * @param {object} suggestion - A object param
 * @example
 *    getSuggestionValue([{id: '1', name: 'one'},{id: '2', name: 'two'}])
 */
  getSuggestionValue = (suggestion) => {
    const {
      filterKey,
    } = this.props;
    if (filterKey) return suggestion[filterKey];
  }


  /**
* This is a function.
* Use to render suggestions.
* @param {object} suggestion - A object param
* @example
*     renderSuggestion([{id: '1', name: 'one'},{id: '2', name: 'two'}])
*/
  renderSuggestion = (suggestion) => {
    const {
      filterKey,
    } = this.props;
    if (filterKey) {
      return (
        <div>
          {suggestion[filterKey]}
        </div>
      );
    }
  };


  /**
* This is a function.
*  Autosuggest will call this function every time you need to clear suggestions.
*/
  onSuggestionsClearRequested = () => {
    const {
      onValueChange,
    } = this.props;
    this.setState({
      suggestions: [],
    }, () => {
      if (onValueChange) {
        onValueChange('', []);
      }
    });
  };

  getPlaceholdervalue=()=>{
    const { warehouseType, chamberType } = this.props;
        if (warehouseType && chamberType) {
          return `Search by ${warehouseType} Or ${chamberType}`;
        }
    if (warehouseType) {
      return `Search by ${warehouseType} Or chamber Id`
    }
    if (chamberType) {
      return `Search by Warehouse Id or ${chamberType}`
    }
    return "Search by Warehouse or Chamber Id"
  }

  getTooltipvalue=()=>{
    const {warehouseType, chamberType} = this.props;
       if(warehouseType && chamberType){
        const string = warehouseType &&  warehouseType.concat(chamberType)
     if(string && string.length > 31){
       return "Search by " + warehouseType  +  " or " + chamberType;
     }
       }
       if(warehouseType){
        const string1= warehouseType.length;
        if(string1 && string1 > 20){
          return "Search by " + warehouseType + " or Chamber Id " ;
        }
       }
       if(chamberType){
        const string2= chamberType.length;
        if(string2 && string2 > 20){
          return "Search by Warehouse Id or " + chamberType ; 
        }
       }
       return ''

  }



  render() {
    // const { 
    //   value, 
    //   suggestions
    // }
    //    = this.state;
    const {
      classes,
      // placeholder,
      // needToFilter,
      // filterBy,
      // filterItems,
      // onFilterChange,
      handleSearchInput,
      searchInput
    } = this.props;
    // Autosuggest will pass through all these props to the input.
    // const inputProps = {
    //   placeholder,
    //   value,
    //   onChange: this.onChange,
    // };

    // Finally, render it!
    return (

      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        {/* {(needToFilter && filterItems && filterItems.length)
          ? (
            <FormControl className={classes.selectionBox}>
              <Select value={filterBy} 
             
               sx={{ 
                height: '42px',
                padding: '5px',
                borderBottom: 'none !important',
                '&:hover': {
                  borderBottom: 'none !important',
                },
                background: 'white',
                border:'none',
                // border: '1px solid #868585',
                // borderTopLeftRadius: '22px',
                // borderBottomLeftRadius: '22px',
              }}
               className={classes.filterMenu} 
               onChange={(e) => onFilterChange(e.target.value)}>
                {
                  filterItems.map((filter) => (
                    <MenuItem value={filter.value} key={filter.value}>
                      {filter.name}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          ) : ''}
        <div style={{ display: 'flex', flexDirection: 'row',  }}>
          <Autosuggest
            // theme={theme}
            suggestions={suggestions}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            // onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            alwaysRenderSuggestions
            getSuggestionValue={this.getSuggestionValue}
            renderSuggestion={this.renderSuggestion}
            inputProps={inputProps}
           
          />
          

          <SearchIcon className={classes.searchIcon} />

          </div> */}


<Tooltip title={this.getTooltipvalue()}>
          <div className={classes.searchInputDiv}>
           
      <input
        type="string"
      
        value={searchInput}
        onChange={handleSearchInput}
        className={classes.searchInput}
        // placeholder="Search by Warehouse or Chamber Id"
       placeholder={this.getPlaceholdervalue()}
      />
      <SearchIcon
        className={classes.searchIcon}
      />
    </div>
    </Tooltip>
        {/* </div> */}
     </div>



    );
  }
}


SearchInput.propTypes = {
  /**
* A prop that should not be visible in the documentation.
*
* @ignore
*/
  classes: PropTypes.instanceOf(Object).isRequired,
  apiUrl: PropTypes.string,
  placeholder: PropTypes.string,
  // filterKey: PropTypes.string.isRequired,
  // data: PropTypes.instanceOf(Object).isRequired,
  // needToFilter: PropTypes.bool.isRequired,
  filterBy: PropTypes.string,
  // onValueChange: PropTypes.func.isRequired,
  // onFilterChange: PropTypes.func.isRequired,
};

SearchInput.defaultProps = {
  apiUrl: '',
  placeholder: 'Search',
  filterBy: '',
};
export default withStyles(styles)(SearchInput);
