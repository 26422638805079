import React from 'react';


const UserContext = React.createContext({
  userDetails: [],
  getDevicesFromServer: [],
  setClient: [],
  getUsersFromServer: [],
  getassetsGroup: [],
  getassets: [],
  userpermissions: null,
  companyInfo: null,

});

// const UserProvider = UserContext.Provider;
// const UserConsumer = UserContext.Consumer;

// export { UserProvider, UserConsumer };

export default UserContext;
