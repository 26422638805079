/* eslint-disable no-unused-vars */
import {
   Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip,Typography
} from '@mui/material';
import React, {
  useEffect, useState, useContext,
} from 'react';
import Grid from '@mui/material/Grid';
import Grow from '@mui/material/Grow';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
// import axios from 'axios';
import cogoToast from 'cogo-toast';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import * as yup from 'yup';
// import { faIgloo } from '@fortawesome/free-solid-svg-icons';
// import { GridApi } from 'ag-grid-community';
import SearchOption from 'components/SearchOption';
import Input from 'components/Input';
// import { StylesContext } from '@mui/material/node_modules/@mui/material';
// import { connect } from 'react-redux';
import UserContext from 'Context/UserContext';
import API from '../../../../API/apiConfig';
import toastOptions from '../../../../utils/toast';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';
// import roledata from './roledata';
import RolesTable from './RolesTable';
import Permissions from './Permissions';
import PrimaryButton from 'components/PrimaryButton';
import SecondaryButton from 'components/SecondaryButton';


const APIURL = process.env.REACT_APP_APIURL;

const AddRoleSchema = yup.object().shape({
  roleName: yup.string().required('Role Name should not be empty'),
  permissions:yup.object({}).required("Permissions should not be empty"),
});

const useStyles = makeStyles((theme) => ({
  warning: {
    color: 'red',
    fontWeight: '500',
  },
  errorMessageDiv: {
    MarginTop: '20%',
    height: '20px',
    textAlign: 'left',
  },
  errorMessageText: {
    fontFamily: 'Montserrat',
    fontSize: '16px',
    textAlign: 'left',
    color: 'red',
    marginTop: '15px',
  },
  inputTextField: {
    border: 'none',
    borderBottom: '1px solid grey',
    width: '100%',
    height: '30px',
    outline: 'none',
    '&:focus': {
      borderBottom: '1px solid #00adef',
    },
  },
  inputLabel: {
    opacity: '0.5',
    fontFamily: 'Montserrat',
    fontSize: '12px',
    textAlign: 'left',
    marginTop: '10px',
    color: 'rgba(3, 0, 0, 0.5)',
  },
  modalDialogOuterDiv: {
    display: 'flex',
    justifyContent: 'center',
  },
  modalDialogDiv: {
    display: 'flex',
    width: '50%',
    flexDirection: 'column',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  modalDisplayDiv: {
    height: '35px',
    display: 'flex',
    alignItems: 'center',
  },
  externalDiv: {
    width: '100%',
    height: 'auto',
  },
  outerDiv: {
    width: '100%',
    // float: 'left',
    // boxShadow: '1px 1.7px 7px 0 rgba(136, 156, 176, 0.1)',
    // backgroundColor: '#ffffff',
  },
  topDiv: {
    width: '100%',
    height: '50px',
    // marginTop: '-10%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonText: {
    fontFamily: 'Montserrat',
    fontSize: '13px',
    fontWeight: '500',
    marginLeft: '10px',
    marginRight: '10px',
  },
  editDiv: {
    width: '100px',
    color:'rgb(0, 128, 129)',
    // borderRight: '1px solid #dedede',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  disabledEditDiv: {
    width: '100px',
    // borderRight: '1px solid #dedede',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#c2c2c2',
  },
  deleteDiv: {
    width: '50px',
    // borderRight: '1px solid #dedede',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    color: 'red',
  },
  disabledDeleteDiv: {
    width: '50px',
    // borderRight: '1px solid #dedede',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#c2c2c2',
  },
  tableHeadingDiv: {
    marginBottom: '30px',
    textAlign: 'left',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  tableHeading: {
    fontSize: '30px',
    color: '#000000',
    fontWeight: '300',
    lineHeight: '1',
  },
  addUsersDiv: {
    display: 'flex',
    float: 'right',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '50px',
  },
  addButtonDiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '30px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  importantField: {
    color: 'red',
  },
  mainControlDiv: {
    // height: '50px',
    marginTop:'15px'
  },
  controlDiv: {
    height: 'inherit',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  addButtonRoot: {
    // marginTop: '-20%',
    borderRadius: '20px',
    boxShadow: '0px 2px 12px 0 rgba(0, 0, 0, 0.1)',
    backgroundColor: '#00adef',
    '&:hover': {
      backgroundColor: '#00adef',
    },
  },
  addButtonLabel: {
    textTransform: 'capitalize',
  },
  clearSelectionDisabled: {
    display: 'block',
    position: 'relative',
  },
  clearSelectionActive: {
    color: 'red',
  },
  Selected: {
    cursor: 'auto',
  },
  permissionsGrid: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '0.1rem',
  },
  permissionsLabel: {
    opacity: '0.5',
    fontFamily: 'Montserrat',
    fontSize: '15px',
    textAlign: 'left',
    marginLeft: '10px',
    color: 'rgba(3, 0, 0, 0.5)',
  },
  overlay: {
    position: 'fixed',
    display: 'block',
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    backgroundColor: 'rgba(0,0,0,0.5)',
    zIndex: '2',
  },
  addButton:{
    backgroundColor:'#008081',
    borderRadius:'20px',
    textAlign:'center',
    color:'white',
    fontWeight:'bold',
    // minWidth:'99px',
    '&:hover': {
      backgroundColor:'#008081',
    },
  },
  saveButton:{
    backgroundColor:'#008081',
    color:'white',
    borderRadius:'20px',
    margin:'10px !important',
    padding:'5px 15px',
    minWidth:'99px',
    '&:hover': {
      backgroundColor:'#008081',
    },
  },
  cancelButton:{
    color:'#008081',
    padding:'5px 15px',
    minWidth:'99px',
    borderRadius:'20px !important' ,
    border:'2px solid #008081 !important'
  },
  dialogtitle:{
    textAlign:'left',
    color:'#000',
    fontWeight:'bold',
    fontSize:'20px',
    fontFamily:'Montserrat'
  },
  enableOptiontext:{
    fontFamily:'Montserrat',
    fontSize:'14px',
  },
  title: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
  },
}));


const Roles = (props) => {
  const classes = useStyles();
  const [loading, setloading] = useState(true); // will be true initally
  const [selectedRowData, setselectedRowData] = useState([]);
  const [openAddDialog, setopenAddDialog] = useState(false);
  const [openDeleteDialog, setopenDeleteDialog] = useState(false);
  const [edit, setEdit] = useState(false);
  // const [gridApi, setGridApi] = useState(null);
  const [filterUsersBySearch, setFilterUsersBySearch] = useState(false);
  const [tempRole, setTempRole] = useState({});
  const [searchInput, setSearchInput] = useState('');
  const [editRole, setEditRole] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [roles, setRoles] = useState([]);
  const [ fullScreen , setFullScreen]= useState(false);
  // const [roles, setRoles] = useState([{
  //   name: 'name1',
  // }, { name: 'name2' }]);


  const HiddenRoles = ['uma_protection'];

  const companyDetails = useContext(UserContext); // react context
  const { userPermissions, accountUser } = companyDetails;

 


  const getRolesFromServer = () => {
    API.get(`${APIURL}/roles`)
      .then((response) => {
        // console.log('Roles form server', response);
        if (accountUser) {
          HiddenRoles.push(`${accountUser.companyId}_SuperUser`);
        }
        // console.log('Hidden', HiddenRoles);
        if (response.data && response.data.status === 'success') {
          // console.log('Roles from server', response.data.data)
          const rolesFromServer = response.data.data;
          const requireroles = rolesFromServer.filter((r) => !HiddenRoles.includes(r.name)).map((role) => ({
            ...role,
            fullAccess: (role.attributes && role.attributes.fullAccess) ? role.attributes.fullAccess[0] : {},
            permissions: (role.attributes && role.attributes.permissions) ? role.attributes.permissions[0] : {},
            
          }));
          setRoles(requireroles);
          setloading(false);
        }
        else{
          setloading(false);
        }
      })
      .catch((err) => {
        setloading(false);
        console.log('error getting roles', err.message || err);
      });
  };

  useEffect(() => {
    getRolesFromServer();
    if (window.innerWidth <= 600) {
      setFullScreen(true);
    }
  }, []);

 

  // This function runs on cancelling the delete option (clicking no on the delete dialog)
  const onDeleteDialogClose = () => {
    setopenDeleteDialog(false);
    setselectedRowData([])
  };



  // This function runs on confirming the delete action
  // (sends a delete request to server & removes the selected users from store)
  const onDeleteDialogSave = () => {
    setloading(true);
    const selectedRowsIdsArray = selectedRowData.map((item) => item.id);
  API.delete(`${APIURL}/roles`, {
    params: { roleIds: selectedRowsIdsArray },
  }) // api to delete the selected group
    .then((deletingRolesResponse) => {
      if (deletingRolesResponse.data.status === 'success') {
        // this.onDeleteRoles(selectedRowsUidsArray);
        const newRolesList = roles.filter((r) => !selectedRowsIdsArray.includes(r.id));
        setopenDeleteDialog(false);
        setselectedRowData([]);
        setRoles([...newRolesList]);
        setFilterUsersBySearch(false);
        setSearchInput('');
        setloading(false);
        cogoToast.success('Deleted Role', toastOptions);
      } else {
        cogoToast.error(deletingRolesResponse.data.message ? deletingRolesResponse.data.message : 'Error deleting users', toastOptions);
        setloading(false);
      }
    })
    .catch((errorDeletingUsers) => {
      console.error(errorDeletingUsers.message ? errorDeletingUsers.message : 'Error deleting role');
      setloading(false);
    });

   
  };


  const onSelectionChanged = (selectedRows) => {
    setselectedRowData(selectedRows);
    // setPermissions(permissions);
  };


  const handleSelectionClear = () => {
    setselectedRowData([]);
  };


  // This function runs on clicking Delete button
  const onDeleteButtonClick = () => {
    setopenDeleteDialog(true);
  };

  // This function runs when the edit button is clicked
  const onEditButtonClick = () => {
    
    if(selectedRowData && selectedRowData.length === 1){
      let roleName = selectedRowData[0].name;
      if (roleName.startsWith(accountUser.companyId[0])) {
        roleName = roleName.substring(accountUser.companyId[0].length + 1); // remove companyId_
      }
      // const showPermissions = permissions;
      // const editRole = { ...selectedRowData[0], name: roleName, showPermissions };
      const editRole = {...selectedRowData[0], name: roleName };
      setopenAddDialog(true);
      setTempRole({ ...editRole });
      setEdit(true);
      setEditRole(editRole);
    }else{
      cogoToast.info('Select a single row to edit or View info', {
        position: 'top-right',
      });
    }
    
  };

  // This runs on saving the edit dialog
  const onEditDialogSave = (event) => {
  
    event.preventDefault();
    const generatePatchBody = (params) => {
      const validParams = ['name', 'permissions', 'fullAccess'];
      const body = Object.keys(params).map((p) => {
        if (validParams.includes(p)) return { op: 'replace', path: `/${p}`, value: params[p] };
        return null;
      }).filter((f) => f);
      return body;
    };
    const editAPIObj = generatePatchBody({
      name: tempRole.name,
      permissions: tempRole.permissions,
      fullAccess: tempRole.fullAccess,
    });
    // setloading(true);

    const checkPermissionObject=tempRole.permissions;
  
    
    const ObjKeys=Object.keys(checkPermissionObject)
    
    const perarray=["create","read", "update","delete"];
   
  //when we edit role , to make sure permissions are assigned to role or not, without 
  // permission do not allow user to edit the role.
    const value= ObjKeys.filter((obj)=>{
     
      const Value = perarray.find((per) => {
       
        return checkPermissionObject[obj][per] === true
      })
      return Value;
    })
   

if(value && value.length > 0){
  API.patch(`${APIURL}/roles/${editRole.id}`, editAPIObj) // api to save edit details for row...
  .then((editRoleResponse) => {
    if (editRoleResponse.data.status === 'success') {
      setloading(true);
      // console.log('Permi in role 1', editRoleResponse.data.data)
      const userResponse = editRoleResponse.data.data;
      const savedUser = userResponse;
      const roleIndex = roles.findIndex((r) => r.id === tempRole.id);
      const newRoles = [...roles];
      if (roleIndex >= 0) newRoles[roleIndex] = tempRole;
      setopenAddDialog(false);
      setErrorMessage(null);
      setselectedRowData([]);
      setTempRole({});
      setRoles(newRoles);
      setloading(false);
      setFilterUsersBySearch(false);
      setSearchInput('');
      cogoToast.success('Updated role', toastOptions);
    } else {
      let errorMessage = editRoleResponse.data.error ? editRoleResponse.data.error : editRoleResponse.data.mesage;
      if (!errorMessage) errorMessage = 'Error creating role';
      setErrorMessage(errorMessage);
      setloading(false);
    }
  })
  .catch((editRoleError) => {
    console.error('Error creating user', editRoleError);
    const errorMessage = editRoleError.message ? editRoleError.message : 'Error editing roles';
    setErrorMessage(errorMessage);
    setloading(false);
  });
}else{
  cogoToast.error('Permissions should not be empty', { position: 'top-right' });
}
      
  };


  // This function is used when the new role details are submitted
  const onAddDialogSave = (event) => {
    event.preventDefault();
   
    const APIRoleObject = {
      roleName: tempRole.name,
      permissions: tempRole.permissions,
      fullAccess: tempRole.fullAccess || {},
    };
    setloading(true);
    
    AddRoleSchema.validate({
      roleName: APIRoleObject.roleName,
      permissions:APIRoleObject.permissions
    }).then(() => {
      API.post(`${APIURL}/roles`, APIRoleObject)
        .then((response) => {
          if (response.data.status === 'success') {
            const newRolesData = [...roles, { name: tempRole.name, fullAccess: tempRole.fullAccess || {} , permissions: tempRole.permissions , id: response.data.data[0].id}];
            setloading(false);
            setRoles(newRolesData);
            setopenAddDialog(false);
            setselectedRowData([]);
            setTempRole({});
            setSearchInput('');
            setFilterUsersBySearch(false);
            setErrorMessage(null);
            const userToastOptions = {
              hideAfter: 6,
              ...toastOptions,
            };
            cogoToast.success(`Created ${tempRole.name} role`, userToastOptions);
          } else {
            let errorMessage = response.data.message ? response.data.message : response.data.error;
            if (!errorMessage) errorMessage = 'Error creating user';
            setErrorMessage(errorMessage);
            setloading(false);
            cogoToast.error(errorMessage, toastOptions);
          }
        })
        .catch((addingUserError) => {
          console.error('Error creating user', addingUserError);
          const errorMessage = addingUserError.message ? addingUserError.message : 'Error adding role';
          setErrorMessage(errorMessage);
          setloading(false);
        });
    }).catch((error) => {
      const errorMessage = error.message ? error.message : 'Error adding role';
      setErrorMessage(errorMessage);
      setloading(false);
    });
  };


  // This function runs on clicking add button
  const onAddRoleButtonClick = () => {
    setopenAddDialog(true);
    setEdit(false);
    setTempRole({});
  };


  // This function runs on Cancelling the adding of user without submitting to server
  const onAddDialogClose = () => {
    setopenAddDialog(false);
    setTempRole({});
    setErrorMessage(null);
    setselectedRowData([])
  };


  // This function runs while searching the group name
  const onSearchChange = (event) => {
    setSearchInput(event.target.value);
  };


  const onPermissionChange = (newPermissions) => {
    const updatedper = { ...tempRole, permissions: newPermissions };
    setTempRole(updatedper);
  };

   const onAccessChange = (value) => {
    // this.setState({ tempRole: { ...tempRole, client: { enabled: value } } });
    if(value){
      cogoToast.success('User would be able to read all AssetGroups and Assets ', { position: 'top-right' });
    }
    // console.log('enableAccessOption 1', tempRole)
     const updateaccess ={...tempRole, fullAccess: { enabled: value } }
     setTempRole(updateaccess);
  };

  // const enableAccessOption=()=>{
  //   console.log('enableAccessOption 2', tempRole)
  //   const updateaccess ={...tempRole, fullAccess: { enabled: true } }
  //   setTempRole(updateaccess);
  // }

  const routeToDashboard = () => {
    const { history } = props;    // go to dashboard page again
    // console.log('history', history);
    history.push('/dashboard');

    cogoToast.error('You do not have access to Roles page, please contact your admin', { position: 'top-right' });
  };



const defaultRoleArray= selectedRowData.filter((name)=>name.name.startsWith(accountUser.companyId[0]))

  const enableDeleteButtons = !!(selectedRowData && selectedRowData.length >= 1 && ( defaultRoleArray.length >= 1 ? false : true) );
  // const isEditable = (enableEditAndDeleteButtons && !selectedRowData[0].name.startsWith(accountUser.companyId[0])) || !edit;
  

  const enableEditButtons = !!(selectedRowData && selectedRowData.length >= 1 && ( defaultRoleArray.length >= 1 ? true : true) );
  const isEditable = (enableEditButtons && !selectedRowData[0].name.startsWith(accountUser.companyId[0])) || !edit;
  
 
  return (

    <div className={classes.externalDiv}>
      {loading ? (
        <div className={classes.overlay}>
          <CircularProgress
            style={{ position: 'absolute', top: '50%', left: '50%' }}
          />
        </div>
      )
        : (
          <>
            {userPermissions && userPermissions.roles && userPermissions.roles.read ? (
              <>
                <Grid container spacing={12} justifyContent="center" className={classes.mainControlDiv}>
                  <Grid item md={12} sm={12} xs={12} className={classes.controlDiv}>
                    <PrimaryButton
                      variant="contained"
                     text="Add Role"
                      onClick={onAddRoleButtonClick}
                      disabled={userPermissions && userPermissions.roles  && !(userPermissions.roles.create)}
                    />
               
                  </Grid>
                </Grid>

<Grow in>
<Grid container justifyContent="center">
                    <Grid item  xl={7} lg={7} md={5} sm={5} xs={7} style={{textAlign:'left', display:'flex'}}  >
                    <div style={{ display: 'flex' }}>
                            <Tooltip title="Delete" placement="top-end">
                            <Button
                            color="error"
                              disabled={!(userPermissions &&  userPermissions.roles && userPermissions.roles.delete && enableDeleteButtons && selectedRowData && selectedRowData.length >= 1)}
                              className={(userPermissions && userPermissions.roles && userPermissions.roles.delete && enableDeleteButtons) ? classes.deleteDiv : classes.disabledDeleteDiv}
                              onClick={(selectedRowData && selectedRowData.length >= 1) ? onDeleteButtonClick : null}
                            >
                              <DeleteIcon />
                              {/* <span className={classes.buttonText}>Delete</span> */}
                            </Button>
                            </Tooltip>
                            <Tooltip title="Edit" placement="top-end">

                          
                            <Button
                              disabled={
                      !(userPermissions && userPermissions.roles &&  userPermissions.roles.update && enableEditButtons && selectedRowData && selectedRowData.length >= 1)
                    }
                            
                              onClick={(selectedRowData && selectedRowData.length >= 1) ? onEditButtonClick : null}
                            >
                              <EditIcon
                                className={(userPermissions &&  userPermissions.roles && userPermissions.roles.update && enableEditButtons) ? classes.editDiv : classes.disabledEditDiv}
                               />
                              {/* <span className={classes.buttonText}>Edit</span> */}
                            </Button>
                            </Tooltip>
                          </div>
                    </Grid>
                    <Grid item  xl={3} lg={2} md={3} sm={3} xs={5}>
                    <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              alignItems: 'center',
                              flexGrow: 1,
                            }}
                          >
 <Tooltip title="use 'shift' or 'ctrl' for multi-select">
                             
                              <div>
                                <span>Selected : </span>
                                <span className={classes.Selected}>
                                  {selectedRowData.length}
                                </span>
                              </div>
                             
                            </Tooltip>
                            <Tooltip title="Clear Selection">
                              <IconButton
                                onClick={handleSelectionClear}
                          
                                
                              >
                                <HighlightOffIcon
                                className={
                                  selectedRowData && selectedRowData.length > 0
                                    ? classes.clearSelectionActive
                                    : classes.clearSelectionDisabled
                                }
                                 />
                              </IconButton>
                            </Tooltip>

                            </div>
                      


                  
                    </Grid>
                    <Grid item  xl={2} lg={3} md={4} sm={4} xs={12}>
                      
                    <SearchOption
                          variant="standard"
                            id="userSearchInput"
                            value={searchInput}
                            onChange={onSearchChange}
                            placeholder="Search by role name"
                          />
                    </Grid>
                  </Grid>

</Grow>
<RolesTable
                          rowData={roles}
                          searchvalue={searchInput}
                          onSelectionChanged={onSelectionChanged}
                          isSearchGoingOn={filterUsersBySearch}
                          selectedRowData={selectedRowData}
                        />

                

                <Dialog open={openDeleteDialog} onClose={onDeleteDialogClose} aria-labelledby="form-dialog-title" maxWidth="sm">
                  <DialogTitle id="form-dialog-title"><span className={classes.warning}>Warning</span></DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                 Are you sure you want to delete the selected role?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <SecondaryButton  text="No"  onClick={onDeleteDialogClose } />
                 
                    
                    <PrimaryButton  text="Yes" variant="contained" onClick={onDeleteDialogSave} />
                
                  </DialogActions>
                </Dialog>

                <Dialog 
                open={openAddDialog}
                 edit={edit || undefined} 
                 onClose={onAddDialogClose} 
                 aria-labelledby="form-dialog-title" 
                 maxWidth="sm"
                  fullWidth 
                 fullScreen={fullScreen}
                PaperProps={{
                  style: {
                    backgroundColor: '#f2f2f2',
                  },
                }}>
                  <form onSubmit={onAddDialogSave}>

               { fullScreen && (
                <AppBar className={classes.appBar}>
                          <Toolbar style={{backgroundColor:'#008081'}}>
                          <IconButton
                          edge="start"
                          color="inherit"
                          onClick={onAddDialogClose}
                          aria-label="close"
                        >
                          <CloseIcon />
                        </IconButton>
                          <Typography variant="h6" className={classes.title}>
                          {edit ? 'Edit' : 'Add New'}
                      {' '}
                 Role
                        </Typography>
                          <Button color="inherit" type="submit" 
                            onClick={edit ? onEditDialogSave : onAddDialogSave}
                            >
                          {edit ? "Update" : "Save"}
                        </Button>
                        </Toolbar>
                        </AppBar>
                   )}
                    {!fullScreen && (
                      <DialogTitle className={classes.dialogtitle}>
                      {edit ? 'Edit' : 'Add New'}
                      {' '}
                 Role
                    </DialogTitle>
                    )} 
                    <DialogContent>
                   
                      <div>
                        <Grid container style={{marginTop: fullScreen ? "4rem" : '0rem' }}  justifyContent="space-around">
                          <Grid item lg={4} md={4} sm={5} xs={12}>
                          {
                    fullScreen  && errorMessage && (
                      <div 
                      style={{  padding: '1rem' }}
                      >
                    
                      {errorMessage ? (
                        <div 
                        // className={classes.errorMessageDiv1}
                        >
                          <span className={classes.errorMessageText}>
                            *
                            {errorMessage}
                          </span>
                        </div>
                      ) : null}
                    </div>
                    )
                   }
                            <Input
                              placeholder="Role name*"
                              onChange={(e) => { setTempRole({ ...tempRole, name: e.target.value }); }}
                              value={tempRole.name || ''}
                              disabled={!isEditable}
                              backgroundColor="#fff"
                            />
                          </Grid>
                          <Grid item  lg={6} md={6} sm={6} xs={12} style={{fontFamily:'Montserrat'}}>
                            <FormControlLabel
                              control={
                        (
                          <Checkbox
                            checked={!!(tempRole.fullAccess && tempRole.fullAccess.enabled)}
                            style={{ color: "#008081" }}
                            name="fullaccess"
                            onChange={(e) => {onAccessChange(e.target.checked)}}
                           
                            color="primary"
                          
                          />
                        )
                      }
                              label="Full Access"
                            />
                            <FormHelperText className={classes.enableOptiontext} style={{fontFamily:'Montserrat'}}>Click to enable create option </FormHelperText>
                          </Grid>
                        </Grid>
                      </div>
                      <Permissions onPermissionChange={onPermissionChange} initialState={tempRole.permissions} fullAccessvalue={tempRole.fullAccess} fullScreen={fullScreen}  />
                    </DialogContent>
                   {
                    !fullScreen && (
                      <div style={{ marginTop: '10px', paddingTop: '10px' ,  marginLeft:'10px'}}>
                      {errorMessage ? (
                        <div className={classes.errorMessageDiv}>
                          <span className={classes.errorMessageText}>
                            *
                            {errorMessage}
                          </span>
                        </div>
                      ) : null}
                    </div>
                    )
                   }
                    

                    {
                      !fullScreen && (
                      <DialogActions>
                      <SecondaryButton  text="Cancel" onClick={onAddDialogClose} />
                  
                      <PrimaryButton  text= {edit ? 'Update' : 'Save'}  onClick={edit ? onEditDialogSave : onAddDialogSave} type="submit" variant="contained" />
                       
                      
                    </DialogActions>
                      )
                    }
                    
                  </form>
                </Dialog>


              </>




            ) : (
              routeToDashboard()
            )}
          </>

        )}
    </div>
  );
};

export default Roles;



