import React, {useState, useContext ,useEffect} from "react";
import { withStyles } from "@mui/styles";
import Grid from '@mui/material/Grid';
import {
    Button, Tooltip
} from '@mui/material';
import UserContext from 'Context/UserContext';
import PrimaryButton from "components/PrimaryButton";
import Grow from '@mui/material/Grow';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import IconButton from '@mui/material/IconButton';
import SearchOption from "components/SearchOption";
import EndUserTable from "./EndUserTable";
import EndUserForm from "./EndUserForm";
import cogoToast from 'cogo-toast';
import API from "API/apiConfig";


// import Paper from '@mui/material/Paper';

const styles = {
    mainControlDiv: {
        // height: '50px',
        marginTop: '15px'
    },
    controlDiv: {
        height: 'inherit',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    externalDiv: {
        width: '100%',
        height: 'auto',
    },
    tableGrid:{
        height:'70vh',
        backgroundColor:'white'
    },
    paperDiv:{
        height:'50vh',
        marginTop:'15px'
    },
    editDiv: {
        width: '100px',
        color:'rgb(0, 128, 129)',
        // borderRight: '1px solid #dedede',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
      },
      disabledEditDiv: {
        width: '100px',
        // borderRight: '1px solid #dedede',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#c2c2c2',
      },
      deleteDiv: {
        width: '50px',
        // borderRight: '1px solid #dedede',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        color: 'red',
      },
      disabledDeleteDiv: {
        width: '50px',
        // borderRight: '1px solid #dedede',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#c2c2c2',
      },
      clearSelectionDisabled: {
        display: 'block',
        position: 'relative',
      },
      clearSelectionActive: {
        color: 'red',
      },
}

const APIURL = process.env.REACT_APP_APIURL;

const EndUser = (props) => {
    const { classes } = props
    const [openAdddialog, setOpenAddDialog]=useState(false);
    // const [selectedRowData, setSelectedRowData]=useState([]);
    const [edit, setEdit]=useState(false);
    const [rowData, setRowData]= useState([]);
    const [rowDataCopy, setRowDataCopy]=useState([]);
    const [selectedRow, setSelectedRow]= useState([]);
    const [isLoading, setIsLoading]= useState(false)
    const [deleteDialog, setDeleteDialog]=useState(false)

    const contextDetail = useContext(UserContext);
    const {userPermissions } = contextDetail;

    const onRowCheckboxClick=(params)=>{
        setSelectedRow(params)
    }


    const getEndUserfromServer=()=>{
        setIsLoading(true)
        API.get(`${APIURL}/enduser`)
        .then((response)=>{
            if(response.data && response.data.status === 'success'){
               
                const resp=response.data.data;
                //to filter assets having isEndUserAssign true
                // const filterAssetGroup=resp.map((ag)=>{
                //     console.log('Came inside 1', ag,resp, resp.assetGroups )
                //     let filterAsset=[];
                //     if(ag.assets && ag.assets.length){
                //         filterAsset=ag.assets.filter((ast)=>ast.isEndUserAssigned)
                //         console.log('filterAsset',filterAsset )
                //         return filterAsset;

                //     }
                //     return {...ag, assets:filterAsset}
                // })
                // const filterAssetGroup=resp.map((res)=>{
                //     let assetGroups=[];
                //     if(res.assetGroups && res.assetGroups.length){
                       
                //         let filterAsset=[];
                //         assetGroups= res.assetGroups.map((ag)=>{
                //             if(ag.assets && ag.assets.length){
                //                 filterAsset=ag.assets.filter((ast)=>ast.isEndUserAssigned)
                //             }
                //             return { ...ag, assets:filterAsset}
                //         })
                //     }
                //     return { ...res, assetGroups: assetGroups}
                // })
                // console.log('getEndUserfromServer',response,filterAssetGroup )
                setRowData(resp);
                setRowDataCopy(resp)
                setIsLoading(false)
               
            }
            else{
                setRowData([]);
                setRowDataCopy([])
                setIsLoading(false)
            }
            
        })
        .catch((err)=>{
          console.log('error from end user from server', err)
          setIsLoading(false)
        })
    }

    useEffect(()=>{
        getEndUserfromServer()
    },[])

    const onSearchChange=(event)=>{
        const searchUser=event.target.value;
        if(searchUser && searchUser.length){
           
            const searchRow = rowDataCopy && rowDataCopy.filter((d) => (searchUser 
                ? (d.userName || ' ').toLowerCase().trim().includes(searchUser.toLowerCase())  
              : true
              ));
            // console.log('searchName', event.target.value,rowDataCopy,  rowData, searchRow);
            setRowData(searchRow)
        }
        else{
            setRowData(rowDataCopy)
        }
        
    }

    const onAddButtonClick=()=>{
        setOpenAddDialog(true)
    }

    const onCancleButtonClick=()=>{
        setOpenAddDialog(false)
        getEndUserfromServer();
        setSelectedRow([])
        setEdit(false)
    }

    const onSaveButtonClick=()=>{
        setOpenAddDialog(false)
        getEndUserfromServer()
        setSelectedRow([])
    }
   
    const onSaveDeleteClick=()=>{
        setDeleteDialog(false)
        getEndUserfromServer()
        setSelectedRow([])
    }

    const handleSelectionClear=()=>{
        setSelectedRow([])
    }


    const onDeleteDialogClose=()=>{
        setDeleteDialog(false)
    }

    const onEditButtonClick=()=>{
        if(selectedRow &&  selectedRow.length === 1 ){
            setEdit(true);
            setOpenAddDialog(true)
            
        }
        else{
            cogoToast.info('Select a single row to edit or View info', {
                position: 'top-right',
              }); 
        }
       
    }

    const onDeleteButtonClick=()=>{
        setDeleteDialog(true)
    }

    return (
        <>
            <div className={classes.externalDiv}>
                <Grid container spacing={12} justifyContent="center" className={classes.mainControlDiv}>
                    <Grid item md={12} sm={12} xs={12} className={classes.controlDiv}>
                        <PrimaryButton
                            variant="contained"
                            text="Add End User"
                          onClick={onAddButtonClick}
                          disabled={userPermissions &&
                             !userPermissions.assetGroups?.create  &&
                              !userPermissions.assets?.create 
                            }
                        />

                    </Grid>
                </Grid>

               {/* {
                openAdddialog && (   */}
                 <EndUserForm openAddDialog={openAdddialog} 
                onCancleButtonClick={onCancleButtonClick}
                onSaveButtonClick={onSaveButtonClick}
                // selectedRowData={selectedRow}
                selectedRow={selectedRow}
                edit={edit}
                deleteDialog={deleteDialog}
                onDeleteDialogClose={onDeleteDialogClose}
                onSaveDeleteClick={onSaveDeleteClick}
                 /> 
                
                {/* )
               } */}
               

              

          
               
                <Grow in>
                    <Grid container justifyContent="center">
                        <Grid item xl={7} lg={7} md={5} sm={5} xs={7} style={{ textAlign: 'left', display: 'flex' }}  >
                            <div style={{ display: 'flex' }}>
                                <Tooltip title="Delete" placement="top-end">
                                    <Button
                                        color="error"
                                    //   disabled={!(userPermissions &&  userPermissions.roles && userPermissions.roles.delete && enableDeleteButtons && selectedRowData && selectedRowData.length >= 1)}
                                      className={(selectedRow && selectedRow.length ) ? classes.deleteDiv : classes.disabledDeleteDiv}
                                      disabled={!(userPermissions && 
                                         userPermissions.assetGroups?.delete &&
                                         userPermissions.assets?.delete &&
                                          selectedRow && selectedRow.length >= 1)}
                                    //   onClick={(selectedRowData && selectedRowData.length >= 1) ? onDeleteButtonClick : null}
                                    
                                    >
                                        <DeleteIcon
                                        onClick={(selectedRow && selectedRow.length >= 1) ? onDeleteButtonClick : null}
                                         />

                                    </Button>
                                </Tooltip>
                                <Tooltip title="Edit" placement="top-end">


                                    <Button
                                        onClick={(selectedRow && selectedRow.length >= 1) ? onEditButtonClick : null}
                                    disabled={!(
                                        userPermissions && 
                                         userPermissions.assetGroups?.create &&
                                         userPermissions.assets?.create &&
                                          selectedRow && selectedRow.length >= 1)}
                                   
                                    >
                                        <EditIcon
                                        className={(
                                            userPermissions && 
                                         userPermissions.assetGroups?.create &&
                                         userPermissions.assets?.create  &&
                                            selectedRow && selectedRow.length 
                                            ) ? classes.editDiv : classes.disabledEditDiv}
                                        />
                                       
                                    </Button>
                                </Tooltip>
                            </div>
                        </Grid>
                        <Grid item xl={3} lg={2} md={3} sm={3} xs={5}>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                    flexGrow: 1,
                                }}
                            >
                                {/* <Tooltip title="use 'shift' or 'ctrl' for multi-select"> */}

                                    <div>
                                        <span>Selected : </span>
                                        <span className={classes.Selected}>
                                            {selectedRow.length}
                                        </span>
                                    </div>

                                {/* </Tooltip> */}
                                <Tooltip title="Clear Selection">
                                    <IconButton
                                    onClick={handleSelectionClear}


                                    >
                                        <HighlightOffIcon
                                        className={
                                            selectedRow && selectedRow.length > 0
                                            ? classes.clearSelectionActive
                                            : classes.clearSelectionDisabled
                                        }
                                        />
                                    </IconButton>
                                </Tooltip>

                            </div>




                        </Grid>
                        <Grid item xl={2} lg={3} md={4} sm={4} xs={12}>

                            <SearchOption
                                variant="standard"
                                id="userSearchInput"
                                // value={searchInput}
                                onChange={onSearchChange}
                                placeholder="Search by end user name"
                            />
                        </Grid>
                    </Grid>

                </Grow>

               
                <Grid className={classes.tableGrid}>
                <EndUserTable
                rowData={rowData}
                onRowCheckboxClick={onRowCheckboxClick}
                selectedRow={selectedRow}
                isLoading={isLoading}
                 />
                </Grid>

               

            </div>
        </>
    )
}
export default withStyles(styles)(EndUser);