const SETTINGS = {
  TEMPERATURE: ['C', 'F'],
};

const CtoF = (temperature) => {
  if (!Number(temperature)) return false;
  return ((temperature * 1.8) + 32);
};

const FtoC = (temperature) => {
  if (!Number(temperature)) return false;
  return ((temperature - 32) * (5 / 9));
};

const convertTempSettingsToC = (settings) => {
  if (!settings) return false;
  const newSettings = {};
  /*
  if (typeof args.minViolation === 'number') settingsArray.push(args.minViolation);
  if (typeof args.minWarning === 'number') settingsArray.push(args.minWarning);
  if (typeof args.maxWarning === 'number') settingsArray.push(args.maxWarning);
  if (typeof args.maxViolation === 'number') settingsArray.push(args.maxViolation);
  */
  if (Number(settings.minViolation)) newSettings.minViolation = FtoC(Number(settings.minViolation));
  if (Number(settings.minWarning)) newSettings.minWarning = FtoC(Number(settings.minWarning));
  if (Number(settings.maxWarning)) newSettings.maxWarning = FtoC(Number(settings.maxWarning));
  if (Number(settings.maxViolation)) newSettings.maxViolation = FtoC(Number(settings.maxViolation));
  return newSettings;
};

const sendToLogin = () => {
  const tokenName = (process.env.REACT_APP_applicationName && process.env.REACT_APP_applicationEnv) ? `${process.env.REACT_APP_applicationName}-${process.env.REACT_APP_applicationEnv}` : 'pixeltoken';
  localStorage.removeItem(tokenName);
  const url = new URL(window.location.href);
  const search = new URLSearchParams(window.location.search);
  const token = search.get('token');
  if (token) {
    search.delete('token');
    url.search = search.toString();
  }
  const loginUrl = new URL(process.env.REACT_APP_LOGIN_URL || 'https://pixel-login.adaptideations.com');
  window.location.href = loginUrl.href;
};

module.exports = {
  SETTINGS,
  CtoF,
  FtoC,
  convertTempSettingsToC,
  sendToLogin,
};
