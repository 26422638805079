/* eslint-disable react/prop-types */
import React from "react";
import { withStyles } from "@mui/styles";
import { Tooltip, Typography } from "@mui/material";
import moment from "moment";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import PropTypes from "prop-types";
import { CtoF } from "../utils/helpers";
// import ThermostatIcon from '@mui/icons-material/Thermostat';
// import OpacityIcon from '@mui/icons-material/Opacity';
// import ThermostatIcon from '@mui/icons-material/Thermostat';
// import OpacityIcon from '@mui/icons-material/Opacity';
import BatteryRoundedIcon from "@mui/icons-material/Battery60Rounded";

const styles = {
  overallDiv: {
    borderRadius: "22px",
    boxShadow: "10px 10px 5px -6px #ccc",
    overflow: "hidden",
    "&:hover": {
      boxShadow: "10px 10px 5px -3px rgba(191,191,191,1)",
    },
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    padding: "0px",
    margin: "6px",
    cursor: "pointer",
    borderTop: "1px solid #eee",
    borderLeft: "1px solid #eee",
  },
  overallDiv_violated: {
    borderTop: "1px solid #eee",
    borderRadius: "22px",
    // boxShadow: '1px 1.7px 20px 0 rgba(0, 0, 0, 0.15)',
    boxShadow: "10px 10px 5px -8px #ccc",
    // borderLeft: '3px solid #FF5630',
    borderLeft: "1px solid #eee",
    overflow: "hidden",
    "&:hover": {
      // background: '#FFEBE6',
      // boxShadow: '1px 1.7px 20px 0 #000',
      boxShadow: "10px 10px 5px -3px rgba(191,191,191,1)",
    },
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    padding: "0px",
    margin: "6px",
    cursor: "pointer",
  },
  overallDiv_title: {
    height: "15px",
    padding: "5px 10px 10px 10px",
  },
  overallDiv_title_text: {
    margin: "0",
    fontSize: "12px",
    fontWeight: "500",
    marginRight: "10px",
    fontFamily: "Montserrat",
  },
  overallBottom: {
    // maxHeight: '100px',
    // overflowX: 'hidden',
    // overflowY: 'auto',
  },
  overallDiv_info: {
    display: "flex",
    flexWrap: "wrap",
    // alignContent: 'space-between',
    justifyContent: "space-around",
    alignItems: "center",
    marginRight: "25%",
  },
  overallDiv_info_box: {
    margin: "15px 7px 5px",
    fontFamily: "Montserrat",
    fontWeight: "900",
    color: "#777",
    fontSize: "14px",
  },
  titleSmall: {
    color: "#000",
    fontSize: "12px",
    paddingRight: "1em",
    paddingTop: "0.5em",
  },
  overallDiv_info_box_violated: {
    margin: "15px 7px 5px",
    fontFamily: "Montserrat",
    color: "#FF5630",
    fontWeight: "900",
    fontSize: "14px",
  },
  bottomdiv: {
    display: "flex",
    justifyContent: "flex-end",
  },
  liveIndicator: {
    margin: "13px 5px 8px 5px",
    fontSize: "12px",
  },
  violatedColor: {
    color: "#FF5630",
    fontSize: "0.8em",
    textAlign:'left',
    // fontWeight: 1000,
    fontWeight:900,
    // textShadow:'2px  #FF5630'
  },
  normalColor: {
    fontSize: "0.8em",
    textAlign:'left',
    fontWeight: 900,
  },
  
  root: {
    padding: "0px",
    "&:last-child": {
      padding: "0px",
    },
  },
  violatedDiv: {
    display: "flex",
    alignItems: "left",
    // justifyContent: 'flex-start',
    backgroundColor: "rgb(255, 158, 158)",
  },

  normalDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundColor: "rgb(169, 208, 208)",
  },
  devicestext: {
    color: "#000",
    fontFamily: "Montserrat",
    fontSize: "1em",
    textAlign: "left",
    maxWidth: "100%",
  },
  deviceNo: {
    fontWeight: 700,
    fontFamily: "Montserrat",
    fontSize: "20px",
    // textAlign:'left',
    maxWidth: "100%",
  },

  alertDevice: {
    color: "red",
    fontSize: "0.9em",
    textAlign:'left',
    fontFamily: "Montserrat",
    // testSizeAdjust: "auto",
    // width: '90px',
    // padding:'1rem'
  },
  normalDevice: {
    color: "#000",
    fontSize: "0.9em",
    textAlign:'left',
    fontFamily: "Montserrat",
    // testSizeAdjust: "auto",
    // width: '90px',
    // padding:'1rem'
  },

textStyles: {
fontSize: "14px",
fontFamily: "Montserrat",
textAlign: "left",
},
chambernameCard: {
textAlign: "left",
// margin: "10px",
paddingLeft:'0.5rem',
fontWeight: "bold",
fontFamily: "Montserrat",
fontSize: "0.8em",
},
warehousenameCard: {
textAlign: "left",
margin: "10px",
fontWeight: 500,
fontSize: "0.8em",
fontFamily: "Montserrat",
},
thresholdsGrid: {
textAlign: "left",
paddingTop: "0.8em",
fontSize: "0.8em",
fontFamily: "Montserrat",
},
thresholdsGrid1: {
textAlign: "center",
paddingTop: "0.8em",
fontSize: "0.8em",
fontFamily: "Montserrat",
},
onlineText: {
color: "green",
fontSize: "0.8em",
fontWeight: 700,
fontFamily: "Montserrat",
// padding: "0.5rem",
// wordWrap:'break-word'
},
offlineText: {
color: "red",
fontSize: "0.8em",
fontWeight: 700,
fontFamily: "Montserrat",
// padding: "0.5rem",
// wordWrap:'break-word'
},
};

function sortDevices(devices) {
if (devices && devices.length && devices.length > 1) {
devices.sort((deviceA, deviceB) => {
if (deviceA && deviceB) {
let deviceAViolations = 0;
let deviceBViolations = 0;
if (
deviceA.violations &&
(deviceA.violations.temperature || deviceA.violations.humidity)
) {
deviceAViolations =
deviceA.violations.temperature + deviceA.violations.humidity;
}
if (
deviceB.violations &&
(deviceB.violations.temperature || deviceB.violations.humidity)
) {
deviceBViolations =
deviceB.violations.temperature + deviceB.violations.humidity;
}
// console.log('deviceA ', deviceAViolations, 'deviceB ', deviceBViolations);
return deviceBViolations - deviceAViolations;
}
});
return devices;
}
return devices;
}

const lastKnownTemperature = (t, companySettings) => {
// const companySettings = props.settings;
if (
companySettings &&
companySettings.temperature &&
companySettings.temperature === "F"
) {
return `${CtoF(t).toFixed(1)}°F`;
}
return `${t.toFixed(1)}°C`;
};

// console.log(lastKnownTemperature)
function ChamberCard(props) {
const {
// warehouse,
chamberUid,
chamberName,
warehouseName,
maxTemp,
minTemp,
maxHumidity,
minHumidity,
devices,
onClickChamber,
// onDashboardStateChange,
violated,
warning,
classes,
companySettings,
chamberType
} = props;

  const sortedDevices = sortDevices(devices);
  // console.log('Sorted device', devices)
  let tempSettingText;
  if (minTemp !== null && maxTemp !== null) {
    tempSettingText =
      companySettings &&
      companySettings.temperature &&
      companySettings.temperature === "F"
        ? `${CtoF(minTemp)} to ${CtoF(maxTemp)} °F`
        : ` ${minTemp} to ${maxTemp} °C`;
  } else if (minTemp === null && maxTemp !== null) {
    tempSettingText =
      companySettings &&
      companySettings.temperature &&
      companySettings.temperature === "F"
        ? ` < ${CtoF(maxTemp)} °F`
        : ` < ${maxTemp} °C`;
  } else if (maxTemp === null && minTemp !== null) {
    tempSettingText =
      companySettings &&
      companySettings.temperature &&
      companySettings.temperature === "F"
        ? ` > ${CtoF(minTemp)} °F`
        : ` > ${minTemp} °C`;
  } else {
    tempSettingText = " N/A ";
  }

let humiditySettingText;
if (minHumidity !== null && maxHumidity !== null) {
humiditySettingText = ` ${minHumidity} - ${maxHumidity} %`;
} else if (minHumidity === null && maxHumidity !== null) {
humiditySettingText = ` < ${maxHumidity} %`;
} else if (maxHumidity === null && minHumidity !== null) {
humiditySettingText = ` > ${minHumidity} %`;
} else {
humiditySettingText = " N/A ";
}

const adjustWarehouseName = () => {
if (warehouseName) {
if (warehouseName && warehouseName.length > 12) {
return warehouseName.slice(0, 12) + "...";
}
if (warehouseName && warehouseName.length <= 12) {
return warehouseName;
}
}
};

const adjustChamberName = () => {
      if (chamberName) {
      if (chamberName && chamberName.length > 12) {
     return chamberName.slice(0, 12) + "...";
       }
  if (chamberName && chamberName.length <= 12) {
       return chamberName;
}
}
};
return (
<div className={classes.cardGrid}>
<Card
sx={{ borderRadius: "22px" }}
className={
violated || warning ? classes.overallDiv_violated : classes.overallDiv
}
onClick={() => {
onClickChamber(chamberUid);
}}
>
        <CardMedia>
<Grid
container
className={
violated || warning ? classes.violatedDiv : classes.normalDiv
}
>
<Grid container>
<Grid item xs={5}>
<Tooltip title={warehouseName ? warehouseName : ""}>
<Grid className={classes.warehousenameCard}>
{adjustWarehouseName()}
</Grid>
</Tooltip>
<Tooltip title={chamberName ? chamberName : ""}>
<Grid className={classes.chambernameCard}>
{adjustChamberName()}
</Grid>
</Tooltip>
</Grid>
<Grid item xs={1} />
<Grid item xs={6} style={{ textAlign: "left" }}>
<Grid className={classes.thresholdsGrid}>Thresholds :</Grid>

                <Grid container >
                  <Grid item xs={6}  className={classes.thresholdsGrid}>
                    {tempSettingText}
                    {/* <ThermostatIcon  style={{fontSize:'1.2em',}} /> */}
                  </Grid>
                  <Grid item xs={6} className={classes.thresholdsGrid1}>
                    {humiditySettingText}
                    {/* <OpacityIcon style={{fontSize:'1.2em',}} /> */}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardMedia>
        <Tooltip title={
        violated ? 
        `This ${chamberType ? chamberType : "chamber"}  is under violation`
        : warning? `This ${chamberType ? chamberType : "chamber"} is under warning` :""}>
        <CardContent
          sx={{ height: "70%" }}
          classes={{
            root: classes.root,
          }}
        >
          <div className={classes.overallBottom}>
            <div style={{ padding: "0.8rem" }}>
              <Grid container style={{ Width: "100%" }}>
                <Grid item  lg={6} md={6} sm={6} xs={12} className={classes.devicestext}>
                  Devices : {devices && devices.length}
                </Grid>
              </Grid>
            </div>

            {sortedDevices && sortedDevices.length
              ? sortedDevices.map((device) => {
                  if (device) {
                    const isTempViolated =
                      device.violations && device.violations.temperature || 
                      device.warnings && device.warnings.temperature
                      ;
                    const isHumidityViolated =
                      device.violations && device.violations.humidity || 
                      device.warnings && device.warnings.humidity
                      ;
                    const isBatteryViolated =
                      device.violations && device.violations.battery ||
                      device.warnings && device.warnings.battery
                      ;

                    return (
                      <>
                     


<Grid container>
  <Grid item xs={2}>
  {(device.lastPing &&
                            device.lastPing.timeStamp &&
                            moment().unix() &&
                            moment().unix() - device.lastPing.timeStamp <=
                              5400)  ? 
                              <span className={classes.onlineText}>Online</span>
                               : 
                               <span className={classes.offlineText}>
                               Offline
                             </span>

                            }
  </Grid>


  <Grid item xs={4} style={{textAlign:'left'}}>
   <span  className={
                  isTempViolated === 1 ||
                 isHumidityViolated === 1 ||
                   isBatteryViolated === 1
                      ? classes.alertDevice
                        : classes.normalDevice
                   }   >{device.id} </span> <br/>
     <span style={{fontSize:'0.7em',fontFamily: "Montserrat",}} >Last Updated:
      {device.lastPing &&
                            device.lastPing.timeStamp &&
                            moment().unix() -  Number(device.lastPing.timeStamp) <=
                            864000
                              ? ` ${moment
                                  .unix(Number(device.lastPing.timeStamp))
                                  .fromNow()}`
                              : "N/A"}
                              </span> 
    </Grid>

    <Grid xs={6} style={{textAlign:'left' ,}}>
    <Typography
    
                              variant="caption"
                              component="div"
                              sx={{
                                // fontStyle: "60px",
                                fontFamily: "Montserrat",

                               
                              }}
                            >
                              Current Status:{" "}
                            </Typography>
<Grid container>
                            <Grid item xs={4} 
                            className={
                              isTempViolated
                                ? classes.violatedColor
                                : classes.normalColor
                            }>
                            {device.lastPing &&
                              device.lastPing.t1 &&
                              device.lastPing.t1.length
                                ? lastKnownTemperature(
                                    device.lastPing.t1[
                                      device.lastPing.t1.length - 1
                                    ],
                                    companySettings
                                  )
                                : "---"}
                                {/* <ThermostatIcon  style={{fontSize:'1.2em',}}/> */}
                            </Grid>
                            <Grid item xs={4} 
                             className={
                              isHumidityViolated
                                ? classes.violatedColor
                                : classes.normalColor
                            }>
                            {device.lastPing &&
                              device.lastPing.humidity &&
                              device.lastPing.humidity.length
                                ? `${device.lastPing.humidity[
                                    device.lastPing.humidity.length - 1
                                  ].toFixed(1)}%`
                                : "---"}
                                {/* <OpacityIcon  style={{fontSize:'1.2em'}} /> */}
                            </Grid>
                            <Grid item xs={4} className={
                              isBatteryViolated
                                ? classes.violatedColor
                                : classes.normalColor
                            }>
                            {device.lastPing && device.lastPing.battery
                                ? `${Math.round(device.lastPing.battery)}%`
                                : "---"}
                                <BatteryRoundedIcon  style={{fontSize:'1em'}}/>
                            </Grid>

                            </Grid>





                            
    </Grid>
</Grid>
</>
                    );
                  }
                })
              : ""}
          </div>
        </CardContent>
        </Tooltip>
      </Card>
    </div>
  );
}

ChamberCard.propTypes = {
classes: PropTypes.instanceOf(Object).isRequired,
warehouse: PropTypes.string.isRequired,
chamberName: PropTypes.string.isRequired,
chamberUid: PropTypes.string.isRequired,
maxTemp: PropTypes.number,
minTemp: PropTypes.number,
maxHumidity: PropTypes.number,
minHumidity: PropTypes.number,
devices: PropTypes.instanceOf(Object),
// onDashboardStateChange: PropTypes.func.isRequired,
onClickChamber: PropTypes.func.isRequired,
violated: PropTypes.bool,
warning: PropTypes.bool,
};

ChamberCard.defaultProps = {
maxTemp: null,
minTemp: null,
maxHumidity: null,
minHumidity: null,
violated: false,
warning: null,
devices: [],
};

export default withStyles(styles)(ChamberCard);


// 2/4/6
// fontsize: em 

 {/* <Grid
                        container
                        spacing={{ md: 2, xl: 1, xs: 2 }}
                        style={{ padding: "5px" }}
                      >
                        <Grid item >

not rep
                          {device.lastPing &&
                            device.lastPing.timeStamp &&
                            moment().unix() &&
                            moment().unix() - device.lastPing.timeStamp <=
                              5400 && (
                              <span className={classes.onlineText}>Online</span>
                            )}
                          {device.lastPing &&
                            device.lastPing.timeStamp &&
                            moment().unix() &&
                            moment().unix() - device.lastPing.timeStamp >
                              5400 && (
                              <span className={classes.offlineText}>
                                Offline
                              </span>
                            )}
not req
                            {(device.lastPing &&
                            device.lastPing.timeStamp &&
                            moment().unix() &&
                            moment().unix() - device.lastPing.timeStamp <=
                              5400)  ? 
                              <span className={classes.onlineText}>Online</span>
                               : 
                               <span className={classes.offlineText}>
                               Offline
                             </span>

                            }
                        </Grid>
                        <Grid item style={{ textAlign: "left" }}>
                          <Grid
                            item
                            className={
                              isTempViolated === 1 ||
                              isHumidityViolated === 1 ||
                              isBatteryViolated === 1
                                ? classes.alertDevice
                                : classes.normalDevice
                            }
                          >
                            {device.id}
                          </Grid>
                          <Grid
                            item
                            style={{
                              textAlign: "left",
                              fontSize: "12px",
                            }}
                          >
                            show last update upto only 10 days
                            Last Updated : <br />
                            {device.lastPing &&
                            device.lastPing.timeStamp &&
                            moment().unix() -  Number(device.lastPing.timeStamp) <=
                            864000
                              ? ` ${moment
                                  .unix(Number(device.lastPing.timeStamp))
                                  .fromNow()}`
                              : " N/A"}
                          </Grid>
                        </Grid>
                        <Grid item style={{ textAlign: "left" }}>
                          <Grid item>
                            <Typography
                              variant="caption"
                              component="div"
                              sx={{
                                fontStyle: "12px",
                                fontFamily: "Montserrat",
                               
                              }}
                            >
                              Current Status:{" "}
                            </Typography>
                          </Grid>
                          <Grid
                            container
                            item
                            spacing={2}
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <Grid
                              item
                              className={
                                isTempViolated
                                  ? classes.violatedColor
                                  : classes.normalColor
                              }
                            >
                              {device.lastPing &&
                              device.lastPing.t1 &&
                              device.lastPing.t1.length
                                ? lastKnownTemperature(
                                    device.lastPing.t1[
                                      device.lastPing.t1.length - 1
                                    ],
                                    companySettings
                                  )
                                : "--"}
                            </Grid>
                            <Grid
                              item
                              className={
                                isHumidityViolated
                                  ? classes.violatedColor
                                  : classes.normalColor
                              }
                            >
                              {device.lastPing &&
                              device.lastPing.humidity &&
                              device.lastPing.humidity.length
                                ? `${device.lastPing.humidity[
                                    device.lastPing.humidity.length - 1
                                  ].toFixed(1)}%`
                                : "--"}
                            </Grid>
                            <Grid
                              item
                              style={{ display: "flex", alignItems: "center" }}
                              className={classes.normalColor}
                            >
                              {device.lastPing && device.lastPing.battery
                                ? `${Math.round(device.lastPing.battery)}%`
                                : " --"}
                              <BatteryRoundedIcon />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid> */}
