
import React, { useContext } from "react";
// import ReactDOM from 'react-dom';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { Grid, } from "@mui/material";
import styled from "@emotion/styled";
import CalendarContent from "./CalendarContent";
// import Search from "./EventSearch";
// import response from "./response";
import interactionPlugin from "@fullcalendar/interaction";
import UserContext from 'Context/UserContext';
import { useEffect } from "react";
import API from 'API/apiConfig';
import { useState } from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { CardActionArea } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useMediaQuery, createTheme } from '@material-ui/core';
import {
  CircularProgress,
} from '@mui/material';
import Slide from '@mui/material/Slide';
import Zoom from '@mui/material/Zoom';
// import "@fullcalendar/core/main.css";
// import "@fullcalendar/daygrid/main.css";
// import "@fullcalendar/timegrid/main.css";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { withStyles } from '@mui/styles';
import { useRef } from 'react';
import PrimaryButton from "components/PrimaryButton";

import InfoIcon from '@mui/icons-material/Info';


// const queryString = require('query-string'); 
const APIURL = process.env.REACT_APP_APIURL;

export const StyleWrapper = styled.div`
  .fc .fc-daygrid-day-number {
    font-size: 1.5rem;
  }
  .fc td {
    borderRadius:22px;
   
  },
  .fc .fc-toolbar-title{
    color:rgba(0,0,0,0.5);
    font-size:1.5rem
  }
  .fc-theme-standard td{
    border:1px solid rgba(0,0,0,0.3);
    border-radius: 0.5em;
    margin: 1em;
   
  },
  .fc table {
    border-collapse: separate;
  },
  .css-1ner6ea .fc-theme-standard td {
    borderRadius:22px;
  },
  .fc-basic-view .fc-body .fc-row { min-height: 1em; } ,
  .fc-theme-standard tr{
    padding:3em
  },
  .fc .fc-daygrid-body {
    cursor: pointer;
}
.fc-view-container { 
    overflow-x: scroll; 
  }
  .fc-view.fc-agendaDay-view.fc-agenda-view {
    width: 500%;
  }
  /* **For 2 day view** */
  .fc-view.fc-agendaTwoDay-view.fc-agenda-view {
    width: 500%;
  } 
  .fc .fc-button-primary {
    color:rgba(0,0,0,0.5);
    border-color:#33333352 !important;
    background-color:white;
  }
  .fc-daygrid-event {
    white-space:normal;
  }
  .day-highlight{
    background: green !important;
  }
  .fc .fc-daygrid-day.fc-day-today{
    background: rgba(0,128,129,0.2) !important;
} 
.fc .fc-daygrid-more-link {
  font-size:10px
}
.fc .fc-popover{
  z-index: 1
}
  @media screen and (max-width:900px) { .fc-toolbar.fc-header-toolbar {font-size: 60%}}
  @media screen and (max-width:767px) {  .fc .fc-daygrid-day-number {
    font-size: 1rem;
    // border: 3px solid pink ;
  }}
  @media screen and (max-width:767px){.fc .fc-col-header-cell-cushion{ font-size: 0.8rem; }}
  @media screen and (max-width:600px){ .fc-theme-standard td {border:2px solid white;  }}
  @media screen and (max-width:600px){   .fc .fc-toolbar-title{
    color:rgba(0,0,0,0.5);
    font-size:1rem
  }}}
`

const styles = () => ({
  eventgrid: {
    fontWeight: 'bold',
    paddingTop: '1em'
  },
  outerSpan: {
    width: '14px',
    height: '14px',
    border: '1px solid #D3D3D3',
    display: 'inline-block',
    marginRight: '3px',
    cursor: 'pointer',
    "&:hover": {
      boxShadow: "0px 0px 3px 3px  rgba(191,191,191,191)",
    },
  },
  textstyle: {
    marginBottom: '1em',
    fontFamily: "Montserrat",
    fontSize: '1em',
    width: '100%',
    display: 'block'
  },
  hidden: { display: 'none' }
});

const durationShades = [
  {
    opacity: 0,
    text: 'None'
  },

  {
    opacity: 1,
    text: 'Low'
  },
  {
    opacity: 3,
    text: 'Medium'
  },

  {
    opacity: 6,
    text: 'High'
  },



]

const CalendarComp = (props) => {
  const { classes } = props;
  const { menuItem, searchvalue, handleDownload, handleMonthDates } = props
  // const [eventSummary, setEventSummary] = useState([])
  // const todayDate = new Date()


  // const [selectedDates, setSelectedDates] = useState({ start: todayDate.setDate(todayDate.getDate() - 40), end: todayDate.setDate(todayDate.getDate() + 10) });
  const [selectedDates, setSelectedDates] = useState({ start: 0, end: 0 });
  const [eventsForCalender, setEventsForCalender] = useState([]);
  const [emptySearch, setEmptySearch] = useState([]);
  const [dateClick, setDateClick] = useState(false)
  const [eventstoShow, setEventtoShow] = useState([])
  const [selectedDay, setSelectedDay] = useState('');
  const [mobileDialog, setMobileDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState();
  const [loading, setLoading] = useState(true);

  const companyDetails = useContext(UserContext);
  const { CompanyInfo, } = companyDetails;
  // const {chamberType }= CompanyInfo && CompanyInfo.settings && CompanyInfo.settings

  var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"]
  const history = useHistory();

  const myref = useRef(null);

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
  });

  const smallScreen = useMediaQuery(theme.breakpoints.up('sm'));
  //  const parsed = queryString.parse(location.search);

  // console.log('assetGroupsList', assetGroupsList);

  // const assets = assetGroupsList.filter(a => a.assetGroupId).map(a => ({ assetId: a.id, assetGroupId: a.assetGroupId }));
  // const assetGroups = {};
  // assets.forEach((a) => {
  //     if (assetGroups[a.assetGroupId]) {
  //         assetGroups[a.assetGroupId].push(a);
  //     } else {
  //         assetGroups[a.assetGroupId] = [a];
  //     }
  // });




  const getEventSummary = () => {


    if (!selectedDates.start || !selectedDates.end) return;

    const startInUnix = Math.round((selectedDates.start) / 1000);
    const endInUnix = Math.round((selectedDates.end) / 1000);


    // const companyString=accountUser && accountUser.companyId[0]
    // const companyName=companyString.endsWith("PIXEL") 
    // ? accountUser.companyId[0].replace('~PIXEL','') : accountUser
    const companyName = CompanyInfo && CompanyInfo.companyId;

    if (companyName) {
      const timezone = moment.tz.guess()
      // setLoading(true)
      API.get(`${APIURL}/events/summary?companyId=${companyName}&startTime=${startInUnix}&endTime=${endInUnix}&timezone=${timezone}&response=${'calendar'}`)
        .then((response) => {

          if (response.data.status === "success") {
            const res = response.data.data;

            // console.log('Resp is ', res)

            //   // {
            //                             //     title: 'Warehouse 1',
            //                             //     start: getDate('2022-08-03'),
            //                             //     data: [
            //                             //         { chamberId: 'c1', rating: 2 },
            //                             //         { chamberId: 'c2', rating: 4 },
            //                             //         { chamberId: 'c3', rating: 2 },
            //                             //         { chamberId: 'c4', rating: 0 },
            //                             //     ],

            //                             // },
            // if(!searchvalue){
            const EVENTS_FOR_CALENDER = [];


            Object.keys(res).forEach(date => {

              const agId = res[date];

              Object.keys(agId).forEach((assetgpId) => {

                const agIdValue = agId[assetgpId];
                const events1 = {
                  title: assetgpId,
                  start: new Date(date * 1000),
                  color: 'red',
                  data: Object.keys(agIdValue).map((a) => {
                    const rat = agIdValue[a];

                    let rating = 0;
                    if (Object.keys(rat).length !== 0) {


                      if (menuItem === "All Violations") {
                        if (rat.T && rat.H) {
                          if (rat.T > rat.H) {
                            rating = rat.T             // if both are present compare both and pick T
                          }
                          else rating = rat.H
                        } else {
                          if (rat.T) {
                            rating = rat.T            //if only T is present, take T
                          }
                          if (rat.H) {
                            rating = rat.H          //if only H is present, take H
                          }
                        }

                      }

                      if (menuItem === "Temperature Violations") {
                        rating = rat.T    //to filter only temperature violation 
                      }
                      if (menuItem === "Humidity Violations") {
                        rating = rat.H      //to filter only humidity violation 
                      }


                    }
                    const splitContent = a.split(" | ");

                    return {
                      chamberId: splitContent && splitContent[0],
                      assetUid: splitContent && splitContent[1],
                      tolerancesetting: Object.keys(rat).length ? splitContent[2] : "undefined",
                      rating: rating,
                      range: { T: rat.T, H: rat.H }
                    }
                  }),

                }

                if (events1.data.filter(e => e.rating).length) {
                  EVENTS_FOR_CALENDER.push(events1);
                }

                //   EVENTS_FOR_CALENDER.push(events1);



              })

            })

            setEventsForCalender(EVENTS_FOR_CALENDER);
            setEmptySearch(EVENTS_FOR_CALENDER);            //stoaring duplicate value , for search 
            // setEventSummary(res);

            setLoading(false);

            // }

          } else {
            setLoading(false);

          }
        })
        .catch((err) => {
          console.log('Err is ', err)
          setLoading(false);
        })
    }


  }


  useEffect(() => {
    let eventstoShow = []
    if (searchvalue && searchvalue.length > 1) {

      //  console.log('eventsForCalender',eventsForCalender,emptySearch )
      const findSearchevent = eventsForCalender.filter((eve) => {
        if (eve.title.toLowerCase().includes(searchvalue.toLowerCase())
        ) {
          const events1 = emptySearch.filter((a) =>
            a.title.toLowerCase().includes(eve.title.toLowerCase()))
          eve.chambers = events1.data;
          eventstoShow = eve;
          return eventstoShow;
        }
        const filterCh = eve.data && eve.data.filter((a) => a.chamberId.toLowerCase().includes(searchvalue.toLowerCase()));
        if (filterCh && filterCh.length) {
          const filterChCopy = { ...eve };
          filterChCopy.data = filterCh;
          return filterChCopy;
        }
        return null;
      }
      ).filter((m) => m)
      eventstoShow = findSearchevent;
      setEventsForCalender(eventstoShow)

      // setEventsForCalender(findSearchevent)
    }
    if (!searchvalue) {
      setEventsForCalender(emptySearch)
    }


  }, [searchvalue])





  useEffect(() => {

    getEventSummary();
    setLoading(true);
  }, [selectedDates, menuItem, CompanyInfo])

  // useEffect(() => {
  // }, [eventSummary]);

  // const eventsArray = Object.keys(eventSummary)

  // const a1 = eventsArray.map((key) => (eventSummary[key]))
  // const b1 = a1.map((f) => Object.keys(f))






  const handleClick = () => {
    myref.current.scrollIntoView({ behavior: 'smooth' });
  };








  const EventDetail = (eventInfo) => {
    // setMobileContent(eventInfo);

    return (
      <Grid xs={12}>
        <CalendarContent eventInfo={eventInfo} />
      </Grid>

    )
  };


  const handleReportDownload = (datesinfo) => {
    // console.log('handleReportDownload')
    handleDownload(datesinfo);
  }

  const openDialog = (id, tt, rating, uid, date) => {
    setMobileDialog(true);

    const assetContent = [];
    assetContent.push(id, tt, rating, uid, date)
    setDialogContent(assetContent);
  }

  const closeMobileDialog = () => {
    setMobileDialog(false);
  }




  const handleTime = (value) => {

    const tolerancevalue = value;
    if (tolerancevalue.includes(("SI"))) {
      return "Disabled"
    } else {
      const sec = parseInt(value, 10); // convert value to number if it's string
      let hours = Math.floor(sec / 3600); // get hours
      let minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
      let seconds = sec - (hours * 3600) - (minutes * 60); //  get seconds
      // add 0 if value < 10; Example: 2 => 02
      if (hours < 10) { hours = "0" + hours; }
      if (minutes < 10) { minutes = "0" + minutes; }
      if (seconds < 10) { seconds = "0" + seconds; }
      // return hours+':'+minutes+':'+seconds; // Return is HH : MM : SS
      return hours + "hour " + minutes + "min "
    }


  }


  const handlePossibleVio = (tt) => {
    const tolerance = tt;
    const possiblevio = 86400 / tolerance;
    return possiblevio;
  }

  const handleColorforMv = (id, rating, tt) => {
    const tolerance = tt;
    // const ratingVal=rating;
    const possiblevio = 86400 / tolerance;

    const range = possiblevio / 3;   //to find the 1 parts in a day of tt

    const range2 = range * 2;
    // const range3= range * 3;
    //288, 96,236
    if (rating > 0 && rating <= range) {
      return "rgb(230 194 194)"
    }
    if (rating > range && rating <= range2) {
      return "#F08D8D"
    }
    if (rating > range2) {
      return "#E43737"
    }
    return "#fff";

  }


  const handleDateClick = (c) => {

    const date = c.date;

    const unixTime = moment(date).unix();
    // const dateee= new Date(unixTime * 1000).getDate() 
    const dateee = new Date(unixTime * 1000).getDate() + " " + months[date.getMonth()]
    setSelectedDay(dateee)
    const dayEvent = eventsForCalender.filter((a) => Math.round((a.start) / 1000) === unixTime)
    setEventtoShow(dayEvent);
    //  setEventsToFilter(dayEvent);

    setDateClick(true)
    handleClick()
  }

  const closeCard = () => {
    setDateClick(false)
  }

  const handleChamberdet = (uid, date) => {

    const assetUid = uid && uid;
    const st = date;
    const startTime = Math.round((st) / 1000);
    const endTime = moment(st).add(1, 'day').unix()

    history.push({
      pathname: '/dashboard',
      search: `?assetUid=${assetUid}&&startTime=${startTime}&&endTime=${endTime}&&RS=ReportSection`, state: { isActive: true },
    })

  }

  // console.log('Events for cal', eventsForCalender)
  return (
    <>

      <>
        <Grid style={{ width: '100%', backgroundColor: '#fff', padding: '1em', borderRadius: '10px' }}>


          <StyleWrapper>


            <FullCalendar

              height={!smallScreen ? "40vh" : "90vh"}
              //  eventDidMount={()=>{
              //   console.log('Event is mounted', )

              //  }}
              //  width={"100px"}
              //  eventLimit="true"
              //  dayMaxEventRows={!smallScreen ? 0 : 5}
              viewClassNames={loading ? classes.hidden : ""}
              dayMaxEvents={!smallScreen ? 0 : 3}

              //  dayMaxEventRows={true}
              // views={{
              //   timeGrid:{
              //     dayMaxEventRows: 8
              //   }
              // }}
              // contentHeight={'auto'}
              // contentWidth={"auto"}
              moreLinkClick={(e) => {
                if (!smallScreen) {
                  e.preventDefault()
                }

              }}
              aspectRatio={'2'}

              selectable="true"
              eventOverlap="false"
              //  initialDate= {new Date()}
              dateClick={handleDateClick}

              // contentHeight={600}
              //  unselect="true"
              select={handleReportDownload}

              //  defaultView="dayGridMonth"
              headerToolbar={{
                right: "prevYear,prev,next,nextYear",
                center: "",
                left: "title"
              }}
              events={eventsForCalender}

              // eventClick={handleEventClick}
              datesSet={(dateInfo) => {
                setSelectedDates({ start: dateInfo.start.getTime(), end: dateInfo.end.getTime() })
                handleMonthDates({ start: dateInfo.start.getTime(), end: dateInfo.end.getTime() })

              }}
              // validRange={{
              //   end:new Date()
              // }}
              eventContent={EventDetail}
              plugins={[dayGridPlugin, interactionPlugin]}

            />
            {loading && <div className={classes.overlay}>
              <div
                style={{
                  position: "fixed",
                  display: "block",
                  width: "100%",
                  height: "100%",
                  top: "0",
                  left: "0",
                  right: "0",
                  bottom: "0",
                  backgroundColor: "rgba(0,0,0,0.5)",
                  zIndex: "2",
                }}
              >
                <CircularProgress
                  style={{ position: "absolute", top: "50%", left: "50%" }}
                />

              </div>
            </div>}
            {/* {loading && <LoadingScreen />} */}
          </StyleWrapper>




        </Grid>
      </>

      {/* for mobile view */}
      {
        !smallScreen ?
          <>
            <Grid item xs={12} style={{ padding: '0.6rem', height: '2.5rem', marginTop: '1rem', border: '2px solid rgba(0,0,0, 0.2)', borderRadius: '15px', backgroundColor: '#fff' }} >
              {
                durationShades.map((shade) => {
                  const opa = (shade.opacity / 6);
                  return (
                    <>
                      <span
                        style={{
                          width: '15px',
                          height: '15px',
                          border: '1px solid #D3D3D3',
                          display: 'inline-block',
                          marginRight: '2px',
                          marginLeft: '2px',

                        }}
                      >
                        <span
                          style={{
                            width: '15px',
                            height: '15px',

                            display: 'inline-block',
                            marginRight: '2px',

                            cursor: 'pointer',
                            opacity: opa,
                            backgroundColor: "#E43737",

                          }}
                        >

                        </span>
                      </span>

                      <span style={{ fontSize: '3vw', fontWeight: 'bold', marginRight: '1em' }}>{shade.text}</span>
                    </>
                  )
                })
              }
            </Grid>
            <Grid container className={classes.eventgrid} >
              Events :-

            </Grid>
            <Grid item xs={12} ref={myref}>
              {
                dateClick ? <>
                  <Slide direction="up" in={dateClick} style={{ width: '100%' }}>
                    <Card sx={{ maxWidth: "100% ", borderRadius: '20px', height: '15rem', overflowY: 'auto' }}>
                      <CardActionArea  >
                        <CardMedia style={{
                          backgroundColor: '#008081',
                          color: '#fff',

                        }}>
                          <Grid style={{ textAlign: 'right', padding: '0.5em' }} >
                            <HighlightOffIcon onClick={closeCard} />
                          </Grid>
                          <Grid container style={{ display: 'flex' }}>
                            <Grid item xs={12} style={{ textAlign: 'left', fontSize: '1.5em', marginLeft: '0.5em' }}>
                              {selectedDay}

                            </Grid>

                          </Grid>

                        </CardMedia>

                        {
                          eventstoShow && eventstoShow.length > 0 ?
                            eventstoShow.map((eve) => {
                              return (<>

                                <CardContent key={eve.title} >
                                  <Grid container  >
                                    <Grid item xs={12} style={{ textAlign: 'left' }}>
                                      {eve.title}
                                    </Grid>
                                    <Grid item xs={12} style={{ textAlign: 'left' }} >
                                      {eve.data && eve.data.map((c) => {
                                        //  const opacity = (c.rating /  6) ;

                                        return (
                                          <>
                                            {/* <Tooltip key={c.chamberId} title={c.chamberId}> */}
                                            {/* <span
                                   key={c.chamberId} 
                                  //  className={classes.outerSpan}
                                   onClick={()=>{openDialog(c.chamberId, c.tolerancesetting, c.rating, c.assetUid, eve.start)}}
                                    >  */}
                                            <span
                                              onClick={() => { openDialog(c.chamberId, c.tolerancesetting, c.rating, c.assetUid, eve.start) }}
                                              style={{
                                                width: '14px',
                                                height: '14px',

                                                backgroundColor: handleColorforMv(c.chamberId, c.rating, parseInt(c.tolerancesetting)),
                                                // backgroundColor:'rgba(240, 52, 52, 1, opacity )',
                                                border: '1px solid #D3D3D3',
                                                //  opacity: opacity,
                                                display: 'inline-block',
                                                marginRight: '3px',
                                                cursor: 'pointer'
                                              }} >

                                            </span>

                                            {/* </span> */}
                                            {/* // </Tooltip> */}

                                          </>

                                        )
                                      })}

                                    </Grid>
                                  </Grid>
                                </CardContent>


                              </>

                              )
                            }) :
                            < CardContent>
                              No events
                            </CardContent>
                        }


                      </CardActionArea>
                    </Card>
                  </Slide>



                </> :
                  <Grid style={{ padding: '2rem', fontSize: '1em' }}>
                    Click on the date to see the event
                  </Grid>

              }
            </Grid>
          </> :
          <></>
      }






      {/* for mobile view dialog */}
      <Dialog
        open={mobileDialog}
        maxWidth="xs"
        TransitionComponent={Zoom}>
        <Grid style={{ textAlign: 'right', padding: '0.5em' }} >
          <HighlightOffIcon onClick={closeMobileDialog} />
        </Grid>
        <DialogContent style={{ padding: '10px' }} >

          <div item xs={12} className={classes.textstyle} > <span style={{ fontWeight: 'bold' }}>
            {CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.chamberType
              ? CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.chamberType
              : "Chamber "}  Id: :
          </span> {dialogContent && dialogContent[0]}</div>
          {
            Number(parseInt(dialogContent && dialogContent[1])) ? (<>
              <div item xs={12} className={classes.textstyle} ><span style={{ fontWeight: 'bold' }}> Tolerance Setting:</span> {dialogContent && Number(parseInt(dialogContent[1])) ? handleTime(dialogContent[1]) : "N/A"}</div>
              <div item xs={12} className={classes.textstyle} > <span style={{ fontWeight: 'bold' }}>Maximum possible violations in a day: </span>{dialogContent && Number(parseInt(dialogContent[1])) ? handlePossibleVio(parseInt(dialogContent[1])) + " times" : "N/A"}</div>
              <div item xs={12} className={classes.textstyle} ><span style={{ fontWeight: 'bold' }}> Number of violation occured :</span> {dialogContent && dialogContent[2]}</div>

            </>) : ""
          }

          {/* <Grid>{dialogContent && Number(parseInt(dialogContent[1]))  ?  " " : <Alert style={{textAlign:'left'}} severity="info">The chamber has no device</Alert>  }</Grid> */}
          {dialogContent && Number(parseInt(dialogContent[1])) ? " " :
            // <Grid style={{display:'flex'}}>
            //  <Grid item xs={1}><InfoIcon fontSize="small"  /></Grid>
            //   <Grid item xs={11} style={{margin:'auto', fontSize:'1.2em'}}>The chamber has no device</Grid>
            //     </Grid>
            <div><InfoIcon fontSize="small" /><span style={{ margin: 'auto', fontSize: '0.9em' }}>The chamber has no device</span> </div>

          }

          <div style={{ textAlign: 'right', padding: '0.5em' }}><PrimaryButton onClick={() => { handleChamberdet(dialogContent && dialogContent[3], dialogContent[4]) }} sx={{ height: '2em', }} text="View Detail" ></PrimaryButton></div>

        </DialogContent>

      </Dialog>


    </>
  )

}

export default withStyles(styles)(CalendarComp);