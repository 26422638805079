import React, { useState } from "react";
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TableContent from "./TableContent";
import { Grid } from "@material-ui/core";
import { ThemeProvider, createTheme } from '@mui/material/styles';
// import DatePicketInput from "components/DatePickerInput";
import './font.css';


const theme = createTheme({
  typography: {
    // ...
    // Tell Material-UI what's the font-size on the html element is.
    htmlFontSize:13,
    // ...
  },
});


const TableComp=(props)=>{
   
    var d = new Date();
           var currMonth = d.getMonth();
           var currYear = d.getFullYear();
           var startDate = new Date(currYear, currMonth, 1);

    const {menuItem, handleMonthDatesTable, searchvalue}=props
    const [value, setValue]= useState(startDate );
    // const [daysno, setDaysNo]=useState(daysInMonth(new Date().getMonth(),new Date().getFullYear()))
    const [daysno, setDaysNo]=useState(new Date().getDate())

    
// console.log('startDate' , startDate, )


    function daysInMonth (month, year) {
        return new Date(year, month, 0).getDate();
    }
      



    const MonthChange=(m)=>{
        //to render columns based on month selected
        if(m.getMonth() === new Date().getMonth() && m.getFullYear() === new Date().getFullYear()){
          setDaysNo(new Date().getDate());  // if month and year is same as present, show rows till date.
        }else{
          const monthvalue= m.getMonth() + 1;
          const yearvalue=m.getFullYear();   
          const days= daysInMonth(monthvalue,yearvalue )
          setDaysNo(days);  
        }
        
                                             
       
    }

    const handleMonthDatesT=(sT,eT)=>{
      handleMonthDatesTable(sT,eT);
    }

    const onKeyDown = (e) => {
      e.preventDefault();
   };
// const color="#008081";
// const fontSize="60px";
return(
    <>
    <Grid lg={1} style={{fontSize:'20px'}}  >
    <ThemeProvider theme={theme}>
   <LocalizationProvider  dateAdapter={AdapterDateFns}>
   <DatePicker
          views={['year', 'month']}
          openTo='month'
        //   minDate={new Date('2012-03-01')}
          maxDate={new Date(startDate)}
      
          value={value}
          onChange={(newValue) => {
        
            setValue(newValue);
          }}

         onMonthChange={MonthChange}
         
          renderInput={(params) => <TextField 
            variant="standard"
            onKeyDown={onKeyDown} 
            InputProps={{
              disableUnderline: true,
             
              style: {
                fontSize: '200px',
                fontFamily: 'Montserrat',
              },
            }}
            // inputProps={{style: {fontSize: 40}}}
            style={{width:'12rem' }}
             {...params}  helperText={null} />}
        />
   </LocalizationProvider>
   </ThemeProvider>
   </Grid>
   <Grid lg={12} md={12}sm={12} xs={12} >
   <TableContent  menuItem={menuItem} daysno={daysno} value={value} 
   searchvalue={searchvalue} 
   handleMonthDatesT={handleMonthDatesT}
   />
   </Grid>

    </>
)
}
export default TableComp;