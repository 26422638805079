import React, { useEffect, useState,  } from "react";
import { withStyles } from "@mui/styles";
import MapBoxComp from "./MapBoxComp";
import DeviceCard from "./DeviceCard";
import cogoToast from 'cogo-toast';
import Grid from "@mui/material/Grid";
import moment from "moment";
import InfoIcon from "@mui/icons-material/Info";

import {  Tooltip } from "@mui/material";

import { List as VirtualizedList, AutoSizer } from 'react-virtualized';


const markerTypes = [
  {
    id: 1,
    name: "Origin",
    icon: "/img/Origin.png",
  },
  {
    id: 2,
    name: "Destination",
    icon: "/img/Destination.png",
  },


];


const styles = {
    devicenameDiv:{
        borderLeft:'1px solid rgba(169,208,208)',
        borderRight:'1px solid rgba(169,208,208)',
        padding:'1rem'


      },
      selectedStyles: {
        backgroundColor:'#fff',
        borderRadius: '8px',
        // height: '8rem',
        border: '1px solid #00808161',
        // width: '500px',
        fontsize: '500px  !important',
        cursor: "pointer",
        marginBottom:'1rem',
        '&:hover':{
          boxShadow:'1px 2px 2px 0px rgb(0 0 0 / 20%)'
        }
      },
      deselectedStyles: {
        backgroundColor:'#fff',
        marginBottom:'1rem',
        borderRadius: '8px',
        // height: '8rem',
        border: '2px solid #008081',
        // width: '500px',
        fontsize: '500px  !important',
        cursor: "pointer",
        boxShadow: "2px 7px 7px 0px rgb(0 0 0 / 20%)"
      },
      deviceList: {
       height:'68vh'
      },
      infostyle:{
        color: "#777",
        padding: "0px 0px 0px 5px",
        zIndex: "0",
        position: "absolute",
        top: "0rem",
        // left: "2rem",
        cursor: "pointer",
      },
      '@media screen and (max-width: 600px)': {
        deviceList: {
         height:'40vh'
        },
        infostyle:{
          color: "#777",
          padding: "0px 0px 0px 5px",
          zIndex: "0",
          position: "absolute",
          top: "22rem",
          // left: "2rem",
          cursor: "pointer",
        }
      },

};

const DeviceLocation = (props) => {
  const { classes, temperatureSettings,humiditySettings,batterySettings } = props;
  // console.log('deviceInfoForMaps',deviceInfoForMaps )
  const chamberDevices=props.deviceInfoForMaps && props.deviceInfoForMaps.devices ? props.deviceInfoForMaps.devices  : [];
  const devicePingsforPath=props.deviceInfoForMaps && props.deviceInfoForMaps.devicePings ? props.deviceInfoForMaps.devicePings  : [];


  // const [deviceList, setDeviceList]=useState([])
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [markers, setMarkers]=useState([])
  const [path, setPath]=useState()
  const [showCluster, setShowCluster]=useState(true);
  const [showyellowcluster, setShowYellowCluster]=useState(true);
  const [closeSpiderifyonCard, setCloseSpideronFilter]=useState(false);
  // const [searchValue, setSearchValue]=useState()






  // const filterdevice = chamberDevices && chamberDevices.filter((d) => (searchValue
  //   ? (d.id || ' ').toLowerCase().trim().includes(deviceList.toLowerCase()) ||
  //   (d.assetId || ' ').toLowerCase().trim().includes(deviceList.toLowerCase()) ||
  //   (d.assetGroupId || ' ').toLowerCase().trim().includes(deviceList.toLowerCase())
  //   : true
  // ));
  const filterdevice=devicePingsforPath



  const handleDeviceRowClick = (device) => {

    if (selectedDevices.filter((d) => d.deviceId === device.deviceId).length) {
      const newSelectedDevices = selectedDevices.filter((d) => (d.id !== device.id));

      setSelectedDevices(newSelectedDevices);

    } else {

      const newSelectedDevices = [device]
      // console.log('SD',newSelectedDevices )
      setSelectedDevices(newSelectedDevices);
    }

  };



//  const getIconforPath1=(lastTemp,lastHum,lastBatt)=>{
//   const tempLastPing=lastTemp;
//   const humidityLastPing=lastHum;
//   const batteryLastPing=lastBatt;
//   const tempSettings=temperatureSettings;
//   const humSettings=humiditySettings;
//   const battSettings= batterySettings

//   const maxTempVio =Number(tempSettings?.maxViolation);
//   const minTempVio =Number(tempSettings?.minViolation);
//   const maxHumVio=Number(humSettings?.maxViolation);
//   const minHumVio =Number(humSettings?.minViolation);
//   const criticalBattery=Number(battSettings?.critical)

//   const maxTempWarning =Number(tempSettings?.maxWarning);
//   const minTempWarning =Number(tempSettings?.minWarning);
//   const maxHumWarning=Number(humSettings?.maxWarning);
//   const minHumWarning =Number(humSettings?.minWarning);
//   const warningBattery=Number(battSettings?.warning)

//   const isVio=tempLastPing > maxTempVio
//        || tempLastPing < minTempVio
//        || humidityLastPing > maxHumVio
//        ||  humidityLastPing < minHumVio
//        || batteryLastPing < criticalBattery
//        || batteryLastPing < 10

//        const isWarned = tempLastPing > maxTempWarning
//        || tempLastPing < minTempWarning
//        || humidityLastPing > maxHumWarning
//        || humidityLastPing < minHumWarning
//        || batteryLastPing < warningBattery
//        || batteryLastPing < 25 && batteryLastPing >= 10


//   if(isVio)return "reddots1";
//   if(isWarned)return "yellowdots";
//   return "greendots1"

//     // if(Number(LastTemp) > Number(tempSettings?.maxViolation) || Number(LastTemp) < Number(tempSettings?.minViolation)){

//     //  return "reddots1"
//     // }
//     // if((Number(LastTemp) > Number(humSettings?.maxWarning) && Number(LastTemp) < Number(tempSettings?.maxViolation)) ||
//     //   (Number(LastTemp) < Number(humSettings?.minWarning) && Number(LastTemp) > Number(tempSettings?.minViolation) ) ){
//     //    return "yellowdots1"
//     // }
//     // return  "greendots1"
//  }

  // const getIconsForMarkers=(lastTemp, lastHum, lastBatt)=>{
  //   const tempLastPing=lastTemp;
  //   const humidityLastPing=lastHum
  //   const batteryLastPing=lastBatt;
  //   const tempSettings=temperatureSettings;
  //   const humSettings=humiditySettings;
  //   const battSettings= batterySettings

  //   const maxTempVio =Number(tempSettings?.maxViolation);
  //   const minTempVio =Number(tempSettings?.minViolation);
  //   const maxHumVio=Number(humSettings?.maxViolation);
  //   const minHumVio =Number(humSettings?.minViolation);
  //   const criticalBattery=Number(battSettings?.critical)

  //   const maxTempWarning =Number(tempSettings?.maxWarning);
  //   const minTempWarning =Number(tempSettings?.minWarning);
  //   const maxHumWarning=Number(humSettings?.maxWarning);
  //   const minHumWarning =Number(humSettings?.minWarning);
  //   const warningBattery=Number(battSettings?.warning)

  //   const isVio=tempLastPing > maxTempVio
  //      || tempLastPing < minTempVio
  //      || humidityLastPing > maxHumVio
  //      ||  humidityLastPing < minHumVio
  //      || batteryLastPing < criticalBattery
  //      || batteryLastPing < 10

  //      const isWarned = tempLastPing > maxTempWarning
  //      || tempLastPing < minTempWarning
  //      || humidityLastPing > maxHumWarning
  //      || humidityLastPing < minHumWarning
  //      || batteryLastPing < warningBattery
  //      || batteryLastPing < 25 && batteryLastPing >= 10

  //   if(isVio)return "redDeviceMarker";
  //   if(isWarned)  return "yellowDeviceMarker";
  //   return "blueDeviceMarker"

  //     // if(Number(LastTemp) > Number(tempSettings?.maxViolation) || Number(LastTemp) < Number(tempSettings?.minViolation)){
  //     //  return "redDeviceMarker"
  //     // }
  //     // if((Number(LastTemp) > Number(humSettings?.maxWarning) && Number(LastTemp) < Number(tempSettings?.maxViolation)) ||
  //     //   (Number(LastTemp) < Number(humSettings?.minWarning) && Number(LastTemp) > Number(tempSettings?.minViolation )) ){
  //     //   return "yellowDeviceMarker"
  //     // }
  //     // return  "blueDeviceMarker"


  // }

  const getRouteMarkers=(DeviceData)=>{
    const {data}=DeviceData[0];

    let routeMarkers = [];
    if (!data || !data.length) {
      console.error(`No device pings found `);//change to toast
      return routeMarkers;
    }
    const filteredPings = data.filter(
      (ping) => ping.bestLocation && ping.bestLocation.lat,
    ); // filter device pings that don't have location

    routeMarkers = filteredPings.map((ping, index) => {

      // const temp=ping.temperature
      //     && ping.temperature.length
      //     && ping.temperature[ping.temperature.length - 1]
      // const humidity=ping.humidity
      // && ping.humidity.length
      // && ping.humidity[ping.humidity.length - 1]
      //  const battery=ping && ping.battery;



      if (index === 0) {
        return [

          ping.bestLocation.lng,
          ping.bestLocation.lat,
          {
            battery: ping.battery,
            id: ping.deviceId,
            temperature:
              ping?.temperature?.length
              && ping.temperature[ping.temperature.length - 1]
                ? ping.temperature[ping.temperature.length - 1]
                : "N/A",
            timeStamp: ping.timeStamp,
            text:"Origin",
            icon:"Origin"
          },
        ];
      }


      if (index === filteredPings.length - 1) {
        return [

          ping.bestLocation.lng,
          ping.bestLocation.lat,
          {
            battery: ping.battery,
            id: ping.deviceId,
            temperature:
              ping?.temperature?.length
              && ping.temperature[ping.temperature.length - 1]
                ? ping.temperature[ping.temperature.length - 1]
                : "N/A",
            timeStamp: ping.timeStamp,
            text:"Destination",
            icon:"Destination"
          },
        ];
      }



      return [

        ping.bestLocation.lng,
        ping.bestLocation.lat,
        {
          battery: ping.battery,
          id: ping.deviceId,
          temperature:
            ping?.temperature?.length
            && ping.temperature[ping.temperature.length - 1]
              ? ping.temperature[ping.temperature.length - 1]
              : "N/A",
            timeStamp: ping.timeStamp,
            // icon:getIconforPath1(temp, humidity, battery)
            icon:"blackdot"


        },
      ];
    });
    return routeMarkers;
  }


//  const getMarkers=(dev)=>{
//   console.log('dev&&&&&&&&&&&&&', dev)
//   const deviceMarkers = [];
//   let tempArr = [];
//   if (dev && dev.length) {
//     for (let i = 0; i < dev.length; i += 1) {
//       if (
//         dev[i].lastPing
//         && dev[i].lastPing.bestLocation
//         && dev[i].lastPing.bestLocation.lat
//       ) {
//         const t1Arr = dev[i]?.lastPing?.t1 || [0];
//         if (t1Arr[t1Arr.length - 1]) {
//           tempArr = dev[i].lastPing.t1[dev[i].lastPing.t1.length - 1];
//         }
//         deviceMarkers.push([
//           dev[i].lastPing.bestLocation.lng,
//           dev[i].lastPing.bestLocation.lat,
//           {
//             markerId: `device-${dev[i].id}`,
//             battery: dev[i].lastPing.battery,
//             temperature: tempArr,
//             icon: "blueDeviceMarker",
//             // timeStamp: devices[i].lastPing.timeStamp,
//             // consignmentId: devices[i].consignmentId,
//             // icon: this.getIcon(devices[i]),
//             // url: "/img/routeMarker.svg",
//           },
//         ]);
//       } else if (
//         dev[i].lastKnownLocation
//         && dev[i].lastKnownLocation.location
//         && dev[i].lastKnownLocation.location.lat
//         && dev[i].lastKnownLocation.location.lng
//       ) {
//         const t1Arr = dev[i]?.lastPing?.t1 || [0];
//         if (t1Arr[t1Arr.length - 1]) {
//           tempArr = dev[i].lastPing.t1[dev[i].lastPing.t1.length - 1];
//         } else {
//           tempArr = [];
//         }
//         dev.push([
//           dev[i].lastKnownLocation.location.lng,
//           dev[i].lastKnownLocation.location.lat,
//           {
//             markerId: `device-${dev[i].id}`,
//             battery: dev[i].lastPing.battery,
//             temperature: tempArr,
//             icon: "blueDeviceMarker",
//             // timeStamp: devices[i].lastKnownLocation.timeStamp,
//             // consignmentId: devices[i].consignmentId,
//             // icon: this.getIcon(devices[i]),
//             // url: "/img/routeMarker.svg",
//           },
//         ]);
//       } else {
//         console.debug("No location found ");
//       }
//     }
//     return deviceMarkers;
//   }

//    return deviceMarkers;
//  }

  //  const getMarkers1=(deviceArrays)=>{
  //   //  let mar=[]
  //   //  console.log('Mar', deviceArrays)
  //   //  mar=deviceArrays.map((devarr)=>{
  //   //   return devarr && devarr.data && devarr.data.length &&
  //   //   devarr.data.map((ping, index)=>{
  //   //     if (index ===  devarr.data.length - 1) {
  //   //       console.log('Mar 2', index, devarr.data.length -1 ,  ping.bestLocation.lng,
  //   //       ping.bestLocation.lat,)
  //   //       return [

  //   //         ping.bestLocation.lng,
  //   //         ping.bestLocation.lat,
  //   //         {
  //   //           battery: ping.battery,
  //   //           // markerId: `device${data.deviceId}`,
  //   //           temperature:
  //   //             ping?.temperature?.length
  //   //             && ping.temperature[ping.temperature.length - 1]
  //   //               ? ping.temperature[ping.temperature.length - 1]
  //   //               : "N/A",
  //   //           timeStamp: ping.timeStamp,
  //   //           // warehouseId: data.consignmentId,
  //   //           // for last location show marker
  //   //           icon: "redDeviceMarker",
  //   //         },
  //   //       ];
  //   //     }

  //   //   });
  //   //   // return mar;
  //   //   })


  //   // return mar;

  //   let a=[];
  //    a= deviceArrays.map((devarr)=>{
  //     devarr &&  devarr.data && devarr.data.length  > 0 &&
  //     devarr.data.map((ping, index)=>{
  //       let lastLocdetail=[];
  //       if(ping.bestLocation){
  //         if (index ===  devarr.data.length - 1){
  //           lastLocdetail=[

  //             ping.bestLocation.lng,
  //             ping.bestLocation.lat,
  //             {
  //               battery: ping.battery,
  //               // markerId: `device${data.deviceId}`,
  //               temperature:
  //                 ping?.temperature?.length
  //                 && ping.temperature[ping.temperature.length - 1]
  //                   ? ping.temperature[ping.temperature.length - 1]
  //                   : "N/A",
  //               timeStamp: ping.timeStamp,
  //               // warehouseId: data.consignmentId,
  //               // for last location show marker
  //               icon: "redDeviceMarker",
  //             },
  //           ];
  //         }
  //          return lastLocdetail
  //       }

  //     })

  //   })
  //   return a;
  //  }


  function getMarkers1(devarr) {
    return devarr.map(item => {
      if (item.data && item.data.length > 0) {
        const lastData = item.data[item.data.length - 1];

        if (lastData.bestLocation) {

          const { lat, lng } = lastData.bestLocation;
          const deviceId = lastData.deviceId;
          const temp=lastData && lastData.t1 && lastData.t1[lastData.t1.length -1] ;
        //   const humidity=lastData && lastData.humidity &&  lastData.humidity[lastData.humidity.length -1]
        //  const battery=lastData && lastData.battery

          const timeStmp=lastData.timeStamp
          return [
            lng,
            lat,
            {
              id: deviceId,
              battery:lastData.battery,
              temperature:temp !== "" ? temp : 'N/A',
              timeStamp:timeStmp,
              // icon: getIconsForMarkers(temp,humidity, battery),
              icon:"blueDeviceMarker"
            }
          ];
        }
      }
      return null;
    }).filter(Boolean);
  }




   const getMarkersforDevice=(devices)=>{
    let initialMarkers=[];
    if(devices && devices.length){
      initialMarkers = getMarkers1(devices);
      // console.log('initialMarkers',initialMarkers)
      if(initialMarkers && initialMarkers.length){
        //  console.log('routte', )
          setPath([])
          setMarkers(initialMarkers)
          setShowCluster(true);
          setShowYellowCluster(true);
          setCloseSpideronFilter(false)
        }
        else {
          console.error("No Loc found");
          setPath([])
          setMarkers([])
        }

    }
   }

 const getPathsforDevice=async(devdata)=>{
  //get paths for all device
  let route=[];
  if(devdata && devdata.length){
    route = await getRouteMarkers(devdata);

    if(route && route.length >=1){
    //  console.log('routte', route)
      setPath([route])
      setMarkers(route)
      setShowCluster(false);
      setShowYellowCluster(false)
    }
    else {
      cogoToast.error(
        'No Location found',
        {
          position: 'top-right',
        },
      );
      console.error("No Loc found");
      setPath([])
      setMarkers([])
      setShowCluster(false);
    }
  }

 }


 const handleLocationPings= ()=>{   // this was async

    if(selectedDevices && selectedDevices.length > 0 ){

      // const filter_selected_device=devicePingsforPath.filter((dev)=>dev.deviceId === selectedDevices.id)
      const filtered_selected_device =devicePingsforPath.filter((d=>d.deviceId === selectedDevices[0].deviceId))


      // const devPath= await getPathsforDevice( filtered_selected_device ) // doing unnecessary
      // setPath(devPath)
      getPathsforDevice( filtered_selected_device )

    }
    else{
      getMarkersforDevice(devicePingsforPath)


      // getMarkersforDevice(chamberDevices) // give deviceList as arg, state of all device details
      // console.log('else  ................',chamberDevices, deviceMarkers)
      // setMarkers(deviceMarkers)
      // setMarkers([])
      // setPath([])
    }
 }

 useEffect(()=>{
  handleLocationPings()
 },[selectedDevices, chamberDevices])

//  useEffect(()=>{
//   handleLocationPings()
//  },[])

  function renderRow({
    index, style,
  }) {

    const device = filterdevice[index];
    const selected = !!selectedDevices.find((d) => (d.deviceId === device.deviceId));

    return (
      <div key={index} style={{
        ...style,
        // backgroundColor: '#f2f2f2', width: '96%',
      }}>
        <DeviceCard device={device}
          onclick={handleDeviceRowClick}
          selected={selected}
          // devicePingsforPath={devicePingsforPath}
          temperatureSettings={temperatureSettings}
          humiditySettings={humiditySettings}
          batterySettings={batterySettings}
           />

      </div>
    );
  }

  const rowHeightRenderer = () => {

    return 80;
  };




  const deviceInfoWindow = (properties) => `<div>
  <span style="color:blue;font-size:1rem;justify-content: left; font-weight:bold" >
  ${
    properties && properties.text ? properties.text  : ""
 }</span>  <br />
  <span style="font-weight:bold "> Device Id:</span> ${
    properties && properties.id ? properties.id  : ""
 } <br />
  <span style="font-weight:bold">Battery:</span> ${
    properties && properties.battery ? `${properties.battery}%` : "N/A"
 } <br />

     <span style="font-weight:bold">Updated at:</span> ${
      moment(properties && properties.timeStamp && properties.timeStamp * 1000).format("DD/MM/YY HH:mm:ss ") || " "
    } <br />
    <span style="font-weight:bold">Address:</span>
    ${properties.address || " "}
  </div>`;



  // useEffect(()=>{
  //   setSelectedDevices([])

  // },[devicePingsforPath])


// console.log('In device location',deviceInfoForMaps,batterySettings)

  return (
    <>

      <Grid container  >
        <Grid item xl={3} lg={3.5} md={4.5} sm={5.5} xs={12} className={classes.deviceList} >

        <AutoSizer>
                    {({ height = 200, width }) => (
                      <VirtualizedList
                        rowRenderer={renderRow}
                        height={height}

                        rowCount={filterdevice.length}
                        rowHeight={rowHeightRenderer}
                        width={width}
                      />
                    )}
                  </AutoSizer>


        </Grid>
        <Grid item xl={9} lg={8.5}  md={7.5} sm={6.5} xs={12} style={{paddingLeft:'1.8rem'}} >

          <MapBoxComp
          markers={markers}
          paths={path}
          closeSpiderifyonCard={closeSpiderifyonCard}
          markerClustering={showCluster}
          myCluster={showyellowcluster}
          getMarkerInfoWindowHTML={deviceInfoWindow}
          // myCluster={showCluster}
          />

          <div>
          <Tooltip
            PopperProps={{
              sx: {
                "& .MuiTooltip-tooltip": {
                  color: "#000",
                  backgroundColor: "#fff",
                  border: "1px solid black",
                },
              },
            }}
            placement="bottom-start"
            style={{ cursor: "pointer" }}
            title={markerTypes.map((a) => (
              <Grid container key={a.name} style={{ paddingBottom: "0.5em" }}>
                <Grid item xs={6}>
                  {" "}
                  <img alt="icon" src={a.icon} />
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{ margin: "auto", textAlign: "left" }}
                >
                  {a.name}
                </Grid>
              </Grid>
            ))}
          >
            <InfoIcon
              fontSize="medium"
              className={classes.infostyle}
              style={{

              }}
            />
          </Tooltip>
          </div>
        </Grid>
      </Grid>


    </>
  );
};

export default withStyles(styles)(DeviceLocation);
