/* eslint-disable react/prop-types */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { withStyles } from "@mui/styles";
import { Tooltip } from "@mui/material";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import Grow from "@mui/material/Grow";
import Zoom from "@mui/material/Zoom";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import cogoToast from "cogo-toast";
import Delete from "@mui/icons-material/Delete";
import ReactSelect from "react-select";
import Edit from "@mui/icons-material/Edit";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import IconButton from "@mui/material/IconButton";
import Input from "components/Input";
// import Select from 'react-select';
import { Select } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import update from "immutability-helper";
import axios from "axios";
import API from "API/apiConfig";
import * as yup from "yup";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import UserContext from "Context/UserContext";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListSubheader from "@mui/material/ListSubheader";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import PersonIcon from "@mui/icons-material/Person";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Divider from "@mui/material/Divider";
import PeopleIcon from "@mui/icons-material/People";
import ListItemIcon from "@mui/material/ListItemIcon";
import Switch from '@mui/material/Switch';

import { CtoF } from "utils/helpers";
// import { CodeSharp } from '@mui/icons-material';
import InputForChamber from "components/InputForChamber";
import WarehousesGrid from "./WarehousesGrid";
import ChambersGrid from "./ChambersGrid";
import UserGroupList from "./UserGroupList";
import TemperatureSettings from "./TemperatureSettings";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import HumiditySettings from "./HumiditySettings";
import PrimaryButton from "components/PrimaryButton";
import SecondaryButton from "components/SecondaryButton";
import SearchOption from "components/SearchOption";
import ToleranceSetting from "./ToleranceSetting";
import InputforCountrycode from "components/InputforCountrycode";
import Alert from "@mui/lab/Alert";

const APIURL = process.env.REACT_APP_APIURL;

axios.defaults.withCredentials = true;

const AddWarehouseSchema = yup.object().shape({
  warehouseId: yup.string().required("ID cannot be empty"),
  address: yup.string().required(" Address cannot be empty"),
});

const AddChamberSchema = yup.object().shape({
  // chamberId: yup.string().required('Chamber ID is manditory').max(10, 'Chamber ID cannot have more than 10 characters'),
  chamberId: yup.string().required("ID is mandantory"),
});

const styles = {
  topStrip: {
    textAlign: "right",
    // borderRight: '1px solid white',
    paddingTop: "1rem",
    paddingBottom: "1rem",
    // justifyContent: 'flex-end',
    // display: 'flex',
    // alignItems: 'center',
  },
  overlay: {
    position: "fixed",
    display: "block",
    width: "100%",
    height: "100%",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    backgroundColor: "rgba(0,0,0,0.5)",
    zIndex: "1400",
  },
  floatLeft: {
    float: "left",
    "&:hover": {
      cursor: "pointer",

    },
  },
  // '@media screen and (max-width: 600px)': {
  //   topStrip: {
  //     // textAlign: 'right',
  //     borderRight: '1px solid white',
  //     paddingTop: '1rem',
  //     paddingBottom: '1rem',
  //     justifyContent: 'flex-start',
  //     display: 'flex',
  //     alignItems: 'center',
  //   },
  //   floatLeft: {
  //     float: 'none',
  //   },
  // },
  modalDialogOuterDiv: {
    display: "flex",
    justifyContent: "center",
  },
  modalDialogDiv: {
    display: "flex",
    width: "50%",
    flexDirection: "column",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  errorMessageDiv: {
    MarginTop: "20%",
    height: "20px",
    textAlign: "left",
  },
  errorMessageText: {
    fontFamily: "Montserrat",
    fontSize: "16px",
    textAlign: "left",
    color: "red",
    marginTop: "15px",
  },
  inputTextField: {
    border: "none",
    borderBottom: "1px solid grey",
    width: "100%",
    height: "30px",
    outline: "none",
    "&:focus": {
      borderBottom: "1px solid #00adef",
    },
    color: "black !important",
  },
  inputTextFieldSmall: {
    border: "none",
    borderBottom: "1px solid grey",
    width: "40%",
    margin: "5%",
    height: "30px",
    outline: "none",
    "&:focus": {
      borderBottom: "1px solid #00adef",
    },
    color: "black !important",
  },
  inputLabel: {
    opacity: "0.5",
    fontFamily: "Montserrat",
    fontSize: "12px",
    textAlign: "left",
    marginTop: "10px",
    marginBottom: "5px",
    color: "rgba(3, 0, 0, 0.5)",
  },
  inputLabelSmall: {
    opacity: "0.5",
    fontFamily: "Montserrat",
    fontSize: "12px",
    textAlign: "left",
    marginTop: "10px",
    marginBottom: "5px",
    color: "rgba(3, 0, 0, 0.5)",
  },
  warning: {
    color: "red",
    fontWeight: "500",
  },
  topDiv: {
    width: "100%",
    height: "50px",
    display: "flex",
    // backgroundColor: '#ffffff',
  },
  deletebuttondiv: {
    // borderRight: '1px solid #dedede',
    textAlign: "left",
    width: "50px",
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    // cursor: 'pointer',
  },
  editbuttondiv: {
    textAlign: "left",
    // borderRight: '1px solid #dedede',
    width: "50px",
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    // cursor: 'pointer',
    // color:'red'
  },
  boxDiv: {
    // borderWidth: '1px',
    // borderStyle: 'solid',
    // borderColor: '#dedede',
    // backgroundColor: '#ffffff',
    // backgroundImage: 'url("../img/temperature.svg")',
    // backgroundSize: 'contain',
    // backgroundRepeat: 'no-repeat',
    padding: "0.5rem",
    paddingBottom: "1rem !important",
    // borderRadius: '9px',
    marginBottom: "0.5rem",

    // boxShadow: '0 0 1px rgba(34, 25, 25, 0.4)',
    justifyContent: "center",
    alignItems: "center",
  },
  deleteDiv: {
    width: "100px",
    borderRight: "1px solid #dedede",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    // color: 'red',
    "&:hover": {
      cursor: "pointer",
    },
  },
  editDiv: {
    // width: '100px',
    // borderRight: '1px solid #dedede',
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    cursor: "pointer",
    color: "#008081",
    "&:hover": {
      cursor: "pointer",
    },
  },
  disabledButton: {
    // width: '100px',
    // borderRight: '1px solid #dedede',
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    color: "#c2c2c2",
  },

  heading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  hoverClass: {
    color: "red",
    cursor: "pointer",
    marginTop: "7px",
  },
  textField: {
    width: "100%",
    // height:'30px',
    // backgroundColor:'#f2f2f2',
    "& fieldset": {
      borderRadius: 22,
      // height:'2rem'
    },
  },
  appBar: {
    position: "sticky",
  },
  title: {
    flex: 1,
    display: "flex",
    justifyContent: "left",
    marginBottom: "5px",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "bold",
  },
  // dialog:{
  //   display:'grid',
  //   gridTemplateColumns:'repeat(4,10fr)',
  //   width:'100%'
  // },
  outerdivforClient: {
    display: "flex",
    justifyContent: "space-between",
  },
  devicelistsetting: {
    display: "flex",
    // justifyContent: 'space-between',

  },
  addButton: {
    backgroundColor: "#008081",
    borderRadius: "20px",
    color: "white",
    fontWeight: "bold",
    // minWidth:'99px',
    "&:hover": {
      backgroundColor: "#008081",
    },
  },
  saveButton: {
    backgroundColor: "#008081",
    color: "white",
    borderRadius: "20px",
    font: "bold",
    margin: "10px !important",
    padding: "5px 15px",
    minWidth: "99px",
    "&:hover": {
      backgroundColor: "#008081",
    },
  },
  cancleButton: {
    color: "#008081",
    padding: "5px 15px",
    minWidth: "99px",
    borderRadius: "20px !important",
    border: "2px solid #008081 !important",
  },
  dialogtitle: {
    textAlign: "left",
    color: "#000",
    fontWeight: "bold",
    fontSize: "20px",
    fontFamily: "Montserrat",

  },
  smsListstyle: {
    padding: "15px",
    fontSize: "1rem",
    textAlign: "center",
  },
  divider: {
    marginTop: "0.6rem",
  },
  subHeadings: {
    textAlign: "left",
    fontSize: "14px",
    fontFamily: "Montserrat",
    fontWeight: "bold",

  },
  clearSelectionDisabled: {
    display: "block",
    position: "relative",
  },
  clearSelectionActive: {
    color: "red",

  },
};

const selectStyles = {
  control: () => ({
    alignItems: "center",
    backgroundColor: "hsl(0,0%,100%)",
    marginTop: "1rem",
    borderRadius: "21px",
    // borderStyle: 'solid',
    // borderWidth: '1px',
    borderBottom: "1px solid #dedede",
    cursor: "default",
    // display: -webkit-box;
    // display: -webkit-flex;
    // display: -ms-flexbox;
    display: "flex",
    // -webkit-flex-wrap: wrap;
    // -ms-flex-wrap: wrap;
    flexWrap: "wrap",
    // -webkit-box-pack: justify;
    // -webkit-justify-content: space-between;
    // -ms-flex-pack: justify;
    justifyContent: "space-between",
    minHeight: "38px",
    outline: "0 !important",
    position: "relative",
    // -webkit-transition: all 100ms;
    transition: "all 100ms",
    boxSizing: "border-box",
  }),
};

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 200,
      // width: 150,
      // marginTop:'4rem',
      marginRight: "5rem",
    },
  },
};

class Warehouses extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      isInWarehouses: true,
      openAddDialog: false,
      openDeleteDialog: false,
      openEditDialog: false,
      openAddDialogForChambers: false,
      errorMessage: null,
      selectedRowData: [],
      selectedOption: null, // for selecting options in react-select
      navigatedWarehouseData: null, // on clicking next button from a warehouse row
      warehousesRowData: null,
      chambersRowData: null,
      clientSharing: [],
      userSharing: [],
      devicesAdded: [],
      fullScreen: false,
      allDevices: [],
      allUsers: [],
      allClients: [],
      singleChamberData: null,
      SMS: false,
      email: false,
      push: false,
      isLoading: false,
      userGroupDetail: {},
      userGroups: [],
      userGroupNames: [],
      existingUserGroup: [],
      setUsersListinClients: null,
      usersinClients: [],
      opened: null,
      matchingswitch: null,
      allremdevices: [],
      messageCount: "5",
      minDuration: "1",
      timeFormat: "2",
      searchInput: "",
      temperatureSettingError: null,
      batterySettingError: null,
      minBatterVio: 0,
      immediateAlert:0,
      toleranceValueinSeconds: 0,
      changeNotiIcon: false,
      userEmail: "",
      countryCode: "",
      phoneNumber: "",
      userswithNoAccount: [],
    };
  }

  componentDidMount() {
    /**
     * This is used for opening the dialogs in fullscreen mode in case of small screen sizes
     */
    this.getWarehouses();

    // this.getGroups();
    if (window.innerWidth <= 600) {
      this.setState({
        fullScreen: true,
      });
    }
    if (window.innerWidth <= 900) {
      this.setState({
        changeNotiIcon: true,
      });
    }
  }

  getGroups = () => {
    const { isInWarehouses } = this.state;
    if (isInWarehouses) {
      API.get(`${APIURL}/groups`)
        .then((response) => {
          const groupResponse = response.data.data;
          const groups = [];
          // console.log('groups', groupResponse);
          groupResponse.forEach((group) => {
            groups.push({
              groupId: group.id,
              groupName: group.name,

              notificationSettings: group.notificationSettingsByAdmin ? group.notificationSettingsByAdmin : {},

            });
          });
          const names = groups.map((names) => ({ userGroupName: names.groupName, userGroupUid: names.groupId, notificationSettingsByAdmin: names.notificationSettings }));
          this.setState({
            userGroups: groups,
            userGroupNames: names,
          });
        })
        .catch((error) => console.log("error getting groups", error.message || error));
    } else {
      API.get(`${APIURL}/groups`)
        .then((response) => {
          const groupResponse = response.data.data;
          const groups = [];

          groupResponse.forEach((group) => {
            groups.push({
              groupId: group.id,
              groupName: group.name,

              groupType: (group.attributes && group.attributes.type) ? group.attributes.type[0] : "",
              notificationSettings: group.notificationSettingsByAdmin ? group.notificationSettingsByAdmin : {},

            });
          });
          const names = groups.map((names) => ({
            userGroupName: names.groupName, groupUid: names.groupId, notificationSettingsByAdmin: names.notificationSettings, type: names.groupType,
          }));
          this.setState({
            userGroups: groups,
            userGroupNames: names,
          });
        })
        .catch((error) => console.log("error getting groups", error.message || error));
    }
  };

  getWarehouses = () => new Promise((resolve, reject) => {
    API
      .get(`${APIURL}/assetGroups`)

      .then((response) => {
        if (
          response.data
          && response.data.status
          && response.data.status === "success"

        ) {
          this.setState({
            warehousesRowData: response.data.data,
          });

          resolve(response.data.data);
        } else {
          reject(new Error(response));
          this.setState({
            warehousesRowData: [],
          });
          cogoToast.error(
            response.data.message ? response.data.message : "Internal Error",
            { position: "top-right" },
          );
        }
      })
      .catch((error) => {
        // This is for developer to implement and find out the problem with axios request
        // May be he can use custom console
        if (window.location.hostname === "localhost") {
          cogoToast.error("Internal Error", {
            position: "top-right",
          });
          // console.error('gettingDevicesError', error);
        }
        this.setState({
          isLoading: false,
        });
      });
  });

  getChambers = (assetGroupUid) => new Promise((resolve, reject) => {
    // console.log('Aset UId in chamber', assetGroupUid);
    const url = assetGroupUid ? `${APIURL}/assets/?assetGroupUid=${assetGroupUid}` : `${APIURL}/assets`;
    API
      .get(url)
      .then((response) => {
        if (response.data && response.data.status && response.data.status === "success") {
          resolve(response.data.data);
        } else {
          reject(new Error(response));
          // console.error(response);
        }
      })
      .catch((err) => {
        reject(new Error(err));
        // console.error(err);
      });
  });

  getSingleChamber = (queryParam) => new Promise((resolve, reject) => {
    const url = queryParam ? `${APIURL}/assets/?uid=${queryParam}` : `${APIURL}/assets`;
    API
      .get(url)
      .then((response) => {
        // console.log('getSingleChamber', response);
        if (response.data && response.data.status && response.data.status === "success") {
          resolve(response.data.data);
        } else {
          reject(new Error(response));
          // console.error(response);
        }
      })
      .catch((err) => {
        reject(new Error(err));
        // console.error(err);
      });
  });

  getDevices = (assetUid) => new Promise((resolve, reject) => {
    const url = assetUid ? `${APIURL}/devices/?assetUid=${assetUid}` : `${APIURL}/devices`;

    API
      .get(url)
      .then((response) => {
        if (response.data && response.data.status && response.data.status === "success") {
          resolve(response.data.data);
        } else {
          reject(new Error(response.data.message ? response.data.message : "internal error"));
          // console.error(response);
        }
      })
      .catch((err) => {
        reject(new Error(err));
        // console.error(err);
      });
  });

  getUsers = (assetUid) => new Promise((resolve, reject) => {
    const url = assetUid ? `${APIURL}/users/?assetUid=${assetUid}` : `${APIURL}/users`;
    API
      .get(url)
      .then((response) => {
        // console.log('getUsers', response);
        if (response.data && response.data.status && response.data.status === "success") {
          resolve(response.data.data);
        } else {
          reject(new Error(response));
          // console.error(response);
        }
      })
      .catch((err) => {
        reject(new Error(err));
        // console.error(err);
      });
  });

  getClients = (assetUid) => new Promise((resolve, reject) => {
    const url = assetUid ? `${APIURL}/clients/?assetUid=${assetUid}` : `${APIURL}/clients`;
    const { clientSharing } = this.state;

    API
      .get(url)
      .then((response) => {
        if (response.data && response.data.status && response.data.status === "success") {
          resolve(response.data.data);
        } else {
          reject(new Error(response));
          // console.error(response);
        }
      })
      .catch((err) => {
        reject(new Error(err));
        // console.error(err);
      });
  });

  getUserGroupforClients = () => new Promise((resolve, reject) => {
    // const url = assetUid ? `${APIURL}/clients/?assetUid=${assetUid}` : `${APIURL}/clients`;
    API
      .get(`${APIURL}/groups`)
      .then((response) => {
        if (response.data && response.data.status && response.data.status === "success") {
          resolve(response.data.data);
        } else {
          reject(new Error(response));
          // console.error(response);
        }
      })
      .catch((err) => {
        reject(new Error(err));
        // console.error(err.response.data);
      });
  });

  onSelectionChanged = (selectedRows) => {
    this.setState({
      selectedRowData: selectedRows,
    });
  };

  onAddButtonClick = (event) => {
    event.preventDefault();
    const { isInWarehouses, existingUserGroup } = this.state;
    if (isInWarehouses) {
      this.getGroups();
      this.setState({
        openAddDialog: true,
        existingUserGroup: [],

      });
    } else {
      this.setState({
        isLoading: true,
      }, () => {
        this.getGroups();
        this.getDevices()
          .then((devices) => {
            this.getUserGroupforClients()
              .then((users) => {
                const dropdownGroupClients = users.filter((t) => (t.attributes && t.attributes.type[0] !== "general"));

                this.setState({
                  allDevices: devices && devices !== null ? devices.map((item) => (item.paired === false ? { label: item.id, value: item.IMEI } : null)).filter((x) => x) : [],
                  // allClients: users && users !== null ? users.map((item) => (item.role === 'level2' ? { label: item.name, value: item.uid } : null)).filter((x) => x) : [],
                  allClients: dropdownGroupClients && dropdownGroupClients !== null ? dropdownGroupClients.map((item) => ({ label: item.name, value: item.id })).filter((x) => x) : [],
                  // allClients: users && users !== null ? users,map((item=>item. attributes && item.attributes.type[0] !== 'general')).filter((x)=>x) : [],
                  // allUsers: users && users !== null ? users.map((item) => (item.role === 'level1' ? { label: item.name, value: item.uid } : null)).filter((x) => x) : [],
                  isLoading: false,
                  existingUserGroup: [],
                }, () => {
                  this.setState({
                    openAddDialogForChambers: true,
                    toleranceValue: "instant",
                  });
                });
              })
              .catch((usersError) => {
                cogoToast.error("Failed to load Users**", { position: "top-right" });
                this.setState({
                  isLoading: false,
                });
                // console.error('usersError', usersError);
              });
          })
          .catch((devicesError) => {
            cogoToast.error("Failed to load devices", { position: "top-right" });
            this.setState({
              isLoading: false,
            });
            // console.error('devciesError', devicesError);
          });
      });
    }
  };

  onAddDialogClose = () => {
    const { isInWarehouses, existingUserGroup } = this.state;
    if (isInWarehouses) {
      this.setState({
        openAddDialog: false,
        errorMessage: null,
        existingUserGroup: [],
      });
    } else {
      this.setState({
        openAddDialogForChambers: false,
        errorMessage: null,
        userSharing: [],
        clientSharing: [],
        devicesAdded: [],
        existingUserGroup: [],
        immediateAlert: 0,
        // toleranceCheckboxValue:'instant',
        toleranceValueinSeconds: 0,

      });
    }
  };

  // this function will give usergroupuid and notificationSetting list from UserGroupList Comp
  updateUserGroupNotification = (notificationSettingUpdate) => {
    const {
      groupUid, type, value, email,
    } = notificationSettingUpdate;
    const { existingUserGroup } = this.state;

    const currentGroupNotificationSettings = existingUserGroup.find((g) => ((g.groupUid || g.userGroupUid) === groupUid)).notificationSettingsByAdmin || {};

    const currentUserNotificationSettings = currentGroupNotificationSettings[email] || {};

    const newUserNotificationSettings = { ...currentUserNotificationSettings, [type]: value }; // to update the type and value
    const newGroupNotificationSettings = { ...currentGroupNotificationSettings, [email]: newUserNotificationSettings }; // to update the notificationsetting

    const groupsCopy = JSON.parse(JSON.stringify(existingUserGroup));

    // to update that particular group
    const newGroups = groupsCopy.map((g) => {
      const newGroupId = g.groupUid || g.userGroupUid;
      if (newGroupId !== groupUid) {
        return g;
      }

      g.notificationSettingsByAdmin = newGroupNotificationSettings;
      return g;
    });

    this.setState({ existingUserGroup: newGroups });

    // const { groups } = this.state;
    // console.log('NotificationSett::', notificationSettingUpdate, '\n group::', groups.find((g) => (g.uid === groupUid)));
    // const currentGroupNotificationSettings = groups.find((g) => (g.uid === groupUid)).notificationSettings || {};
    // const currentUserNotificationSettings = currentGroupNotificationSettings[email] || {};

    // const newUserNotificationSettings = { ...currentUserNotificationSettings, [type]: value }; // to update the type and value
    // const newGroupNotificationSettings = { ...currentGroupNotificationSettings, [email]: newUserNotificationSettings }; // to update the notificationsetting
    // console.log('newGroupNotificationSettings', newGroupNotificationSettings);
    // const groupsCopy = JSON.parse(JSON.stringify(groups));
    // const newGroups = groupsCopy.map((g) => { // to update that particular group
    //   if (g.uid !== groupUid) return g;
    //   g.notificationSettings = newGroupNotificationSettings;
    //   return g;
    // });
    // this.setState({ groups: newGroups });
  };

  onGroupSelectionChange = (todeleteuids) => {
    const {
      existingUserGroup, selectedRowData, openEditDialog, isInWarehouses,
    } = this.state;
    const { CompanyInfo } = this.context;
    if (isInWarehouses) {
      if (openEditDialog === true) {
        const RemovalPatch = [{
          path: "/groups",
          op: "remove",
          value: [todeleteuids],
        }];

        API.patch(`${APIURL}/assetGroups/${selectedRowData[0].uid}`, RemovalPatch)
        //  API.delete(`${APIURL}/assetGroups?uids=${RemovalPatch}`)
          .then((deletedUserGroup) => {
            if (deletedUserGroup.data.status === "success") {
              cogoToast.success(CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.warehouseType ? `Deleted userGroup from ${CompanyInfo.settings.warehouseType}` : " Deleted userGroup from warehouse", { position: "top-right" });
              const newUsers = existingUserGroup.filter((user) => user.userGroupUid !== todeleteuids);
              this.setState({
                existingUserGroup: newUsers,
              });
            } else {
              console.error("Error deleting userGroup");
            }
          }).catch((error) => {
            console.log("error deleting userGroup", error.message || error);
          });
      } else {
        const newUsers = existingUserGroup.filter((user) => user.userGroupUid !== todeleteuids);
        this.setState({
          existingUserGroup: newUsers,
        });
      }
    } else {
      // console.log('Delete in Chamber');
      if (openEditDialog === true) {
        const RemovalPatch = [{
          path: "/groups",
          op: "remove",
          value: [todeleteuids],
        }];

        API.patch(`${APIURL}/assets/${selectedRowData[0].uid}`, RemovalPatch)
          .then((deletedUserGroup) => {
            if (deletedUserGroup.data.status === "success") {
              cogoToast.success(CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.chamberType ? `Deleted userGroup from ${CompanyInfo.settings.chamberType}` : "Deleted userGroup from Chamber", { position: "top-right" });
              const newUsers = existingUserGroup.filter((user) => (user.groupUid || user.userGroupUid) !== todeleteuids);

              this.setState({
                existingUserGroup: newUsers,
              });
            } else {
              console.error("Error deleting userGroup");
            }
          }).catch((error) => {
            console.log("error deleting userGroup", error.message || error);
          });
      } else {
        const newUsers = existingUserGroup.filter((user) => (user.groupUid || user.userGroupUid) !== todeleteuids);

        this.setState({
          existingUserGroup: newUsers,
        });
      }
    }

    // const groupsCopy = JSON.parse(JSON.stringify(existingUserGroup));
    // const newGroups = existingUserGroup.map((g) => {
    //   if (g.uid !== z.uid) return g;
    //   return z;
    // });
    // this.setState({ existingUserGroup: newGroups });

    // initially doing...
    // const { userGroups } = this.state;
    // const groupsCopy = JSON.parse(JSON.stringify(userGroups));
    // const newGroups = groupsCopy.map((g) => {
    //   if (g.uid !== z.uid) return g;
    //   return z;
    // });
    // this.setState({ userGroups: newGroups });
  };

  //

  // this function runs when we add new warehouse.
  onAddDialogSave = (event) => {
    event.preventDefault();
    const {
      isInWarehouses,
      navigatedWarehouseData,
      devicesAdded,
      clientSharing,
      userSharing,
      SMS,
      push,
      email,
      userGroupDetail,
      groups,
      existingUserGroup,
      allClients,
      timeFormat,
      toleranceTimeinSeconds,
      toleranceTime,
      toleranceValue,
      immediateAlert,
      toleranceCheckboxValue,
      toleranceValueinSeconds,

    } = this.state;
    const { CompanyInfo } = this.context;
    if (isInWarehouses) {
      // axios request for saving the data;
      // if success then close the dialog box otherwise show the error as cogoToast
      const newWarehouseId = event.target.warehouseId.value;
      const newAddress = event.target.address.value;

      const groupObjecttosend = existingUserGroup.map((send) => ({
        userGroupUid: send.userGroupUid,
        notificationSettingsByAdmin: send.notificationSettingsByAdmin,
      }));

      const addWarehouseAxiosObject = {
        id: newWarehouseId,
        note: newAddress,
        groups: groupObjecttosend,
      };

      AddWarehouseSchema.validate({
        warehouseId: newWarehouseId,
        address: newAddress,
      })
        .then(() => {
          this.setState({ isLoading: true }, () => {
            API
              .post(`${APIURL}/assetGroups `, addWarehouseAxiosObject)
              .then((addWarehouseResponse) => {
                if (
                  addWarehouseResponse
                  && addWarehouseResponse.data
                  && addWarehouseResponse.data.status
                  && addWarehouseResponse.data.status === "success"
                ) {
                  this.getWarehouses();
                  this.setState({
                    openAddDialog: false,
                    errorMessage: null,
                    isLoading: false,
                    existingUserGroup: [],
                    searchInput: "",
                  });
                  cogoToast.success(CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.warehouseType ? "Added a" + ` ${CompanyInfo.settings.warehouseType} ` + "Successfully" : " Added a Warehouse Successfuly", {
                    position: "top-right",
                  });
                } else {
                  // console.error(
                  //   addWarehouseResponse.data.message
                  //     ? addWarehouseResponse.data.message
                  //     : 'internal Error',
                  // );
                  cogoToast.error(
                    addWarehouseResponse.data.message
                      ? addWarehouseResponse.data.message
                      : "Failed Adding Warehouse to Server",
                    {
                      position: "top-right",
                    },
                  );
                }
              })
              .catch((error) => {
                // console.log(error);
              });
          });
        })
        .catch((err) => {
          // console.error('err', err.message);
          this.setState({
            errorMessage: err.message,
          });
        });
    } else {
      const chamberId = event.target.chamberId.value;
      const commodity = event.target.commodity.value;
      const temperatureMinimum = event.target.temperatureMinimum.value;
      const temperatureMaximum = event.target.temperatureMaximum.value;
      const temperatureWarningMinimum = event.target.temperatureWarningMinimum.value;
      const temperatureWarningMaximum = event.target.temperatureWarningMaximum.value;
      const humidityMinimum = event.target.humidityMinimum.value;
      const humidityMaximum = event.target.humidityMaximum.value;
      const humidityWarningMinimum = event.target.humidityWarningMinimum.value;
      const humidityWarningMaximum = event.target.humidityWarningMaximum.value;

      const batteryViolation = event.target.critical.value;
      const batteryWarning = event.target.warning.value;

      // const devices = devicesAdded.map((item) => item.value);
      // const devices = devicesAdded;

      const devices = devicesAdded.map((info) => {
        const {
          smsMinDuration, timeFormat, smsCount, value,
        } = info;
          // if (!smsMinDuration) info.smsMinDuration = 1;
          // if (!timeFormat) info.timeFormat = '2';

        return {

          uid: info.value,
          // smsLimitOverride: info.smsLimitOverride,
          smsCount: info.smsCount ? info.smsCount : "20",
          // smsMinDuration: info.smsMinDuration ? ((info.timeFormat === 2) ? info.smsMinDuration * 60 : info.smsMinDuration) : '60',
          smsMinDuration: info.smsMinDuration ? info.smsMinDuration : "60",
        };
      });
      const users = userSharing.map((item) => item.value);
      const clients = clientSharing.map((item) => item.value);

      // console.log('CLients to add', clients, clientSharing, devices, allClients);

      const groupAddedtoChamber = [];

      const userGroupinClient = clientSharing.map((send) => ({
        groupUid: send.value,
        notificationSettingsByAdmin: (send.notificationSettingsByAdmin ? send.notificationSettingsByAdmin : {}),
      }));
      if (userGroupinClient) {
        groupAddedtoChamber.push(...userGroupinClient);
      }

      const userGroupinUserssetting = existingUserGroup.map((send) => ({
        groupUid: send.groupUid || send.userGroupUid,
        notificationSettingsByAdmin: send.notificationSettingsByAdmin,
      }));
      if (userGroupinUserssetting) {
        groupAddedtoChamber.push(...userGroupinUserssetting);
      }

      const addChamberAxiosObject = {
        id: chamberId,
        note: commodity,
        // notificationSettings: { SMS, push, email },
        devices,
        clients,
        // users: users.concat(clients),
        groups: groupAddedtoChamber,
        // toleranceTime: toleranceValue === 'custom' ? toleranceTimeinSeconds: 0,
        toleranceTime: toleranceValueinSeconds,
        immediateAlert: immediateAlert? 1 : 0

      };

      const temperatureSettings = {};
      const humiditySettings = {};
      const batterySettings = {};
      if (temperatureMinimum.length) { temperatureSettings.minViolation = Number(temperatureMinimum); }
      if (temperatureMaximum.length) { temperatureSettings.maxViolation = Number(temperatureMaximum); }
      if (temperatureWarningMinimum.length) { temperatureSettings.minWarning = Number(temperatureWarningMinimum); }
      if (temperatureWarningMaximum.length) { temperatureSettings.maxWarning = Number(temperatureWarningMaximum); }
      if (Object.keys(temperatureSettings).length) { addChamberAxiosObject.temperatureSettings = temperatureSettings; }
      if (humidityMinimum.length) { humiditySettings.minViolation = Number(humidityMinimum); }
      if (humidityMaximum.length) { humiditySettings.maxViolation = Number(humidityMaximum); }
      if (humidityWarningMinimum.length) { humiditySettings.minWarning = Number(humidityWarningMinimum); }
      if (humidityWarningMaximum.length) { humiditySettings.maxWarning = Number(humidityWarningMaximum); }
      if (Object.keys(humiditySettings).length) { addChamberAxiosObject.humiditySettings = humiditySettings; }

      if (batteryViolation.length) { batterySettings.critical = Number(batteryViolation); }
      if (Object.keys(batteryViolation).length) { addChamberAxiosObject.batterySettings = batterySettings; }
      if (batteryWarning.length) { batterySettings.warning = Number(batteryWarning); }
      if (Object.keys(batteryWarning).length) { addChamberAxiosObject.batterySettings = batterySettings; }

      // console.log('addChamberAxiosObject', addChamberAxiosObject);

      AddChamberSchema.validate({
        chamberId,
      })
        .then(() => {
          this.setState(
            {
              isLoading: true,
            },
            () => {
              API
                .post(`${APIURL}/assets`, addChamberAxiosObject)
                .then((addassetResponse) => {
                  if (
                    addassetResponse
                    && addassetResponse.data
                    && addassetResponse.data.status
                    && addassetResponse.data.status === "success"
                  ) {
                    const addChamberToWarehouse = [
                      {
                        path: "/assets",
                        op: "add",
                        value: [addassetResponse.data.data[0].uid],
                      },
                    ];
                    API
                      .patch(
                        `${APIURL}/assetGroups/${navigatedWarehouseData.uid}`,
                        addChamberToWarehouse,
                      )
                      .then((patchAssetGroup) => {
                        if (
                          patchAssetGroup.data
                          && patchAssetGroup.data.status
                          && patchAssetGroup.data.status === "success"
                        ) {
                          this.getChambers(navigatedWarehouseData.uid)
                            .then((chambersRowData) => {
                              this.setState(
                                {
                                  chambersRowData,
                                  openAddDialogForChambers: false,
                                  errorMessage: null,
                                  userSharing: [],
                                  clientSharing: [],
                                  devicesAdded: [],
                                  SMS: false,
                                  push: false,
                                  email: false,
                                  isLoading: false,
                                  existingUserGroup: [],
                                  timeFormat: "2",
                                  searchInput: "",
                                  minBatterVio: 0,
                                  immediateAlert: 0,
                                  // toleranceCheckboxValue:'instant',
                                  toleranceValueinSeconds: 0,

                                },
                                () => {
                                  cogoToast.success(
                                    CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.chamberType ? "Added a" + ` ${CompanyInfo.settings.chamberType} Successfully` : "Added a Chamber Successfully",
                                    {
                                      position: "top-right",
                                    },
                                  );
                                },
                              );
                            })
                            .catch((chambersError) => {
                              cogoToast.error("Failed to get Chambers", {
                                position: "top-right",
                              });
                              // console.error('chambersError', chambersError);
                            });
                        } else {
                          cogoToast.error(
                            patchAssetGroup.data.message
                              ? patchAssetGroup.data.message
                              : "Internal error",
                            { position: "top-right" },
                          );
                        }
                      })
                      .catch((errorpatch) => {
                        cogoToast.error("Error adding Chamber to warehouse", {
                          position: "top-right",
                        });
                        // console.error('patchingAssetstoAssetGroups error', errorpatch);
                      });
                  } else {
                    cogoToast.error(
                      addassetResponse.data.message
                        ? addassetResponse.data.message
                        : "Failed creating new chamber",
                      {
                        position: "top-right",
                      },
                    );
                    this.setState({ isLoading: false });
                  }
                })
                .catch((error) => {
                  this.setState({
                    isLoading: false,
                  });
                  // console.log(error);
                });
            },
          );
        })
        .catch((err) => {
          this.setState({
            errorMessage: err.message,
          });
        });
    }
  };

  onDeleteButtonClick = () => {
    const { isInWarehouses } = this.state;
    if (isInWarehouses) {
      const { selectedRowData } = this.state;
      if (selectedRowData && selectedRowData.length >= 1) {
        this.setState({
          openDeleteDialog: true,
        });
      } else {
        cogoToast.info("Please Select Warehouse(s) to delete them", {
          position: "top-right",
        });
      }
    } else {
      const { selectedRowData } = this.state;
      if (selectedRowData && selectedRowData.length >= 1) {
        this.setState({
          openDeleteDialog: true,
        });
      } else {
        cogoToast.info("Please Select Chambers(s) to delete them", {
          position: "top-right",
        });
      }
    }
  };

  // Cancelling the delete action after clicking on delete
  onDeleteDialogClose = () => {
    this.setState({
      openDeleteDialog: false,
      selectedRowData: [],
    });
  };

  onDeleteDialogSave = () => {
    const { selectedRowData, isInWarehouses, navigatedWarehouseData } = this.state;
    const selectedRowsUidsArray = selectedRowData.map((item) => item.uid);
    // use selectedRowsUidsArray with API to delete and then change state fo dialog

    const { CompanyInfo } = this.context;

    if (!isInWarehouses) {
      API
        .delete(`${APIURL}/assets`, {
          params: { uids: selectedRowsUidsArray },
        })
        .then((deleteResponse) => {
          if (
            deleteResponse.data
            && deleteResponse.data.status
            && deleteResponse.data.status === "success"
          ) {
            this.getChambers(navigatedWarehouseData.uid)
              .then((chambersRowData) => {
                this.setState({
                  chambersRowData,
                  openDeleteDialog: false,
                  selectedRowData: null,
                  searchInput: "",
                }, () => {
                  cogoToast.success(CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.chamberType ? `Deleted the ${CompanyInfo.settings.chamberType}(s)` + " Successfully" : "Deleted the selected Chamber(s) successfully", {
                    position: "top-right",
                  });
                });
              })
              .catch((err) => {
                cogoToast.error("Failed to get Chambers data", { position: "top-right" });
                // console.error('chamberResponseError', err);
              });
          } else {
            cogoToast.error("Unable to delete Chamber", {
              position: "top-right",
            });
            // console.error('deleteResponse fail Message', deleteResponse);
          }
        })
        .catch((deleteError) => {
          // cogoToast.error(deleteError, { position: 'top-right' });
          // console.error(deleteError);
        });
    }
    if (isInWarehouses) {
      const deleteWarehouse = selectedRowData.map((item) => item.uid);
      API
        .delete(`${APIURL}/assetGroups`, {
          params: { uids: deleteWarehouse },
        })
        .then(async (response) => {
          if (response.data) {
            await this.getWarehouses()
              .then((warehousesRowData) => {
                this.setState({
                  warehousesRowData,
                  openDeleteDialog: false,
                  selectedRowData: null,
                  searchInput: "",
                }, () => {
                  cogoToast.success(CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.warehouseType ? `Deleted the ${CompanyInfo.settings.warehouseType}(s)` + " Successfully" : "Deleted the selected Warehouse(s) successfully", {
                    position: "top-right",
                  });
                });
              })
              .catch((err) => {
                cogoToast.error("Failed to get Warehouse data", { position: "top-right" });
              });
          } else {
            cogoToast.error("Unable to delete Warehouse", {
              position: "top-right",
            });
          }
        })
        .catch((deleteError) => {
          // cogoToast.error(deleteError, { position: 'top-right' });
          // console.error(deleteError);
        });
    }
  };

  onexistingUserGroupchange = (newlist) => {
    const {
      selectedRowData, openEditDialog, existingUserGroup, isInWarehouses,
    } = this.state;
    const { CompanyInfo } = this.context;
    // this.setState({
    //   existingUserGroup:newlist,
    // })
    if (isInWarehouses) {
      const newgroupadded = newlist.map((v) => ({
        userGroupUid: v.userGroupUid,
        notificationSettingsByAdmin: v.notificationSettingsByAdmin,
      }
      ));

      if (openEditDialog) {
        const addingPatch = [{
          path: "/groups",
          op: "add",
          value: newgroupadded,
        }];
        API
          .patch(`${APIURL}/assetGroups/${selectedRowData[0].uid}`, addingPatch)
          .then((addingPatchResponse) => {
            if (addingPatchResponse.data && addingPatchResponse.data.status && addingPatchResponse.data.status === "success") {
              this.setState({
                existingUserGroup: [...existingUserGroup, ...newlist],
              });

              cogoToast.success(CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.warehouseType
                ? `Added UserGroup  ${newlist[0].userGroupName} to ${CompanyInfo.settings.warehouseType} ${selectedRowData[0].id} `
                : `Added UserGroup ${newlist[0].userGroupName} to warehouse ${selectedRowData[0].id}`, { position: "top-right" });
            } else {
              cogoToast.error(CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.warehouseType
                ? `unable to add userGroup${newlist[0].userGroupName} to ${CompanyInfo.settings.warehouseType} ${selectedRowData[0].id}`
                : `unable to add userGroup${newlist[0].userGroupName} to warehouse ${selectedRowData[0].id}`, { position: "top-right" });
            }
          })
          .catch((addingPatchError) => {
            cogoToast.error(`unable to add userGroup ${newlist[0].userGroupName} to warehouse ${selectedRowData[0].id}`, { position: "top-right" });
          });
      } else {
        this.setState({
          existingUserGroup: [...existingUserGroup, ...newlist],
        });
        // console.log('Add Warehouseee');
      }
    } else {
      const newgroupaddedinChamber = newlist.map((v) => ({
        groupUid: v.groupUid || v.userGroupUid,
        notificationSettingsByAdmin: v.notificationSettingsByAdmin,
      }
      ));
      if (openEditDialog) {
        // console.log('Patch array**', newgroupaddedinChamber);
        const addingPatch = [{
          path: "/groups",
          op: "add",
          value: newgroupaddedinChamber,
        }];

        API
          .patch(`${APIURL}/assets/${selectedRowData[0].uid}`, addingPatch)
          .then((addingPatchResponse) => {
            if (addingPatchResponse.data && addingPatchResponse.data.status && addingPatchResponse.data.status === "success") {
              // console.log('Existing List', existingUserGroup, newlist);
              this.setState({
                existingUserGroup: [...existingUserGroup, ...newlist],
              });

              cogoToast.success(
                CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.chamberType
                  ? `Added UserGroup  ${newlist[0].userGroupName} to ${CompanyInfo.settings.chamberType} ${selectedRowData[0].id} `
                  : `Added UserGroup ${newlist[0].userGroupName} to chamber ${selectedRowData[0].id}`,
                { position: "top-right" },
              );
            } else {
              cogoToast.error(`unable to add userGroup${newlist[0].userGroupName} to chamber ${selectedRowData[0].id}`, { position: "top-right" });
            // console.error('addingDevicesPatchError', addingPatchResponse);
            }
          })
          .catch((addingPatchError) => {
            cogoToast.error(`unable to add userGroup ${newlist[0].userGroupName} to warehouse ${selectedRowData[0].id}`, { position: "top-right" });
          // console.error('addingDevicesPatchError', addingPatchError);
          });
      } else {
        this.setState({
          existingUserGroup: [...existingUserGroup, ...newlist],
        });
      }
    }
  };

  updateExistingUserList = (newlist) => {
    this.setState({
      existingUserGroup: newlist,
    });
  };

  existingUserGroup = () => {
    const { selectedRowData, isInWarehouses } = this.state;
    if (isInWarehouses) {
      const selectedWarehouse = { ...selectedRowData[0] };
      const { uid } = selectedWarehouse;

      API.get(`${APIURL}/groups?assetGroupUid=${uid}`)
        .then((response) => {
          const userGroup = response.data.data;

          if (userGroup) {
            // console.log('users response', userGroup);
            // const userGrouparray=[];

            // userGroup.forEach((group) => {
            //   userGrouparray.push({

            //     assetGroupUid: group.assetGroupUid,
            //     userGroupUid: group.userGroupUid,

            //     notificationSettingsByAdmin: group.notificationSettingsByAdmin ? group.notificationSettingsByAdmin : [],

            //   });                                         // send name as well......
            // });
            this.setState({
              existingUserGroup: userGroup,
            });
          }
        })
        .catch((error) => {
          console.log("error getting users", error.message || error);
        });
    } else if (!isInWarehouses && selectedRowData && selectedRowData.length === 1) {
      const { uid } = { ...selectedRowData[0] };

      API.get(`${APIURL}/groups?assetUid=${uid}`)
        .then((response) => {
          const userGroup = response.data.data;

          if (userGroup) {
            const generalType = userGroup.filter((t) => (t.userGroupType === "general"));
            const clientType = userGroup.filter((t) => (t.userGroupType === "clientGroup"));

            if (generalType) {
              this.setState({
                existingUserGroup: generalType,
              });
            }
            if (clientType) {
              this.setState({
                clientSharing: clientType,
              });
            }
          }
        })
        .catch((error) => {
          console.log("error getting users", error.message || error);
        });
    }
  };

  onEditButtonClick = () => {
    const {
      isInWarehouses, selectedRowData, clientSharing, allClients, toleranceValue,
    } = this.state;

    const { CompanyInfo } = this.context;

    if (isInWarehouses) {
      if (selectedRowData && selectedRowData.length === 1) {
        this.existingUserGroup();
        this.getGroups();
        this.setState({
          openEditDialog: true,
        });
      } else {
        cogoToast.info(
          CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.warehouseType
            ? `Select a single row of ${CompanyInfo.settings.warehouseType} to edit or view info `
            : "Select a single row of Warehouse to edit or view info",
          { position: "top-right" },
        );
      }
    } else if (!isInWarehouses && selectedRowData && selectedRowData.length === 1) {
      this.setState({
        isLoading: true,
      }, () => {
        this.existingUserGroup();
        this.getGroups();
        this.getSingleChamber(selectedRowData[0].uid)
          .then((singleChamberData) => {
            this.getDevices(selectedRowData[0].uid)
              .then((devices) => {
                this.getUserGroupforClients()
                  .then((users) => {
                    this.getDevices()
                      .then((allDevices) => {
                        // this.getClients()
                        //   .then((allUsers) => {
                        const dropdownGroupClients = users.filter((t) => (t.attributes && t.attributes.type[0] !== "general"));

                        this.setState({
                          singleChamberData,
                          SMS: singleChamberData && singleChamberData[0].notificationSettings && singleChamberData[0].notificationSettings.SMS ? singleChamberData[0].notificationSettings.SMS : false,
                          push: singleChamberData && singleChamberData[0].notificationSettings && singleChamberData[0].notificationSettings.push ? singleChamberData[0].notificationSettings.push : false,
                          email: singleChamberData && singleChamberData[0].notificationSettings && singleChamberData[0].notificationSettings.email ? singleChamberData[0].notificationSettings.email : false,
                          devicesAdded: devices && devices !== null ? devices.map((item) => ({
                            label: item.id, value: item.uid, smsCount: item.smsCount, smsMinDuration: item.smsMinDuration,
                          })) : [],

                          // clientSharing: users && users !== null ? users.map((item) => (item.role === 'level2' ? { label: item.name, value: item.uid } : null)).filter((x) => x) : [],
                          // clientSharing: users && users !== null ?  users.map((item)=>({label:item.name, value:item.uid})).filter((x)=>x):[],
                          userSharing: users && users !== null ? users.map((item) => (item.role === "level1" ? { label: item.name, value: item.uid } : null)).filter((x) => x) : [],
                          allDevices: allDevices && allDevices !== null ? allDevices.map((item) => (item.paired === false ? { label: item.id, value: item.IMEI } : null)).filter((x) => x) : [],
                          // list of allClients needs to be changed..!
                          allClients: dropdownGroupClients && dropdownGroupClients !== null ? dropdownGroupClients.map((item) => ({ label: item.name, value: item.id })).filter((x) => x) : [],
                          // allClients: dropdownGroupClients && dropdownGroupClients !== null ? dropdownGroupClients.map((item) => ({ label: item.name, value: item.id })).filter((x) => x) : [],
                          // allClients: allUsers && allUsers !== null ? allUsers.map((item) => (item.role === 'level2' ? { label: item.name, value: item.uid } : null)).filter((x) => x) : [],
                          // allClients: allUsers && allUsers !== null ? allUsers.map((item) => ({ label: item.name, value: item.uid })).filter((x) => x) : [],
                          // allUsers: allUsers && allUsers !== null ? allUsers.map((item) => (item.role === 'level1' ? { label: item.name, value: item.uid } : null)).filter((x) => x) : [],
                          isLoading: false,
                        }, () => {
                          this.setState({
                            openEditDialog: true,
                            opened: null,

                          });
                        });
                        // })
                        // .catch((allUsersError) => {
                        //   this.setState({
                        //     isLoading: false,
                        //   });
                        //   cogoToast.error('Failed to load users available', { position: 'top-right' });
                        //   // console.error('allUsersError', allUsersError);
                        // });
                      })
                      .catch((allDevicesError) => {
                        this.setState({
                          isLoading: false,
                        });
                        cogoToast.error("Failed to load devices available", { position: "top-right" });
                        // console.error('allDevicesError', allDevicesError);
                      });
                  })
                  .catch((usersError) => {
                    this.setState({
                      isLoading: false,
                    });
                    cogoToast.error("Failed to load users", { position: "top-right" });
                    // console.error('usersError', usersError);
                  });
              })
              .catch((devicesError) => {
                this.setState({
                  isLoading: false,
                });
                cogoToast.error("Failed to load devices", { position: "top-right" });
                // console.error('devicesErrror', devicesError);
              });
          })
          .catch((singleChamberError) => {
            this.setState({
              isLoading: false,
            });
            cogoToast.error("Failed to load chamber details", { position: "top-right" });
            // console.error('singleChamberError', singleChamberError);
          });
      });
    } else {
      cogoToast.info(
        CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.chamberType
          ? `Select a single row of ${CompanyInfo.settings.chamberType} to edit or view info `
          : "Select a single row of Chamber to edit or View info",
        {
          position: "top-right",
        },
      );
    }
  };

  onEditDialogClose = () => {
    const { isInWarehouses } = this.state;
    if (!isInWarehouses) {
      this.setState({
        openEditDialog: false,
        devicesAdded: [],
        clientSharing: [],
        userSharing: [],
        SMS: false,
        push: false,
        email: false,
        allDevices: [],
        allClients: [],
        allUsers: [],
        existingUserGroup: [],
        toleranceCheckboxValue: "instant",
        toleranceValueinSeconds: 0,
        immediateAlert: 0,
        selectedRowData: [],
      });
    } else {
      this.setState({
        openEditDialog: false,
        errorMessage: null,
        selectedRowData: [],
      });
    }
  };

  objectComparator = (obj1, obj2) => {
    if (obj1 || obj2) {
      if (obj1.hasOwnProperty("minViolation") && obj2.hasOwnProperty("minViolation") && obj2.minViolation !== obj1.minViolation) {
        return true;
      }
      if ((obj1.hasOwnProperty("minViolation") && !obj2.hasOwnProperty("minViolation")) || (!obj1.hasOwnProperty("minViolation") && obj2.hasOwnProperty("minViolation"))) {
        return true;
      }
      if (obj1.hasOwnProperty("maxViolation") && obj2.hasOwnProperty("maxViolation") && obj2.maxViolation !== obj1.maxViolation) {
        return true;
      }
      if ((obj1.hasOwnProperty("maxViolation") && !obj2.hasOwnProperty("maxViolation")) || (!obj1.hasOwnProperty("maxViolation") && obj2.hasOwnProperty("maxViolation"))) {
        return true;
      }
      if (obj1.hasOwnProperty("minWarning") && obj2.hasOwnProperty("minWarning") && obj2.minWarning !== obj1.minWarning) {
        return true;
      }
      if ((obj1.hasOwnProperty("minWarning") && !obj2.hasOwnProperty("minWarning")) || (!obj1.hasOwnProperty("minWarning") && obj2.hasOwnProperty("minWarning"))) {
        return true;
      }
      if (obj1.hasOwnProperty("maxWarning") && obj2.hasOwnProperty("maxWarning") && obj2.maxWarning !== obj1.maxWarning) {
        return true;
      }
      if ((obj1.hasOwnProperty("maxWarning") && !obj2.hasOwnProperty("maxWarning")) || (!obj1.hasOwnProperty("maxWarning") && obj2.hasOwnProperty("maxWarning"))) {
        return true;
      }
      if (obj1.hasOwnProperty("critical") && obj2.hasOwnProperty("critical") && obj2.critical !== obj1.critical) {
        return true;
      }
      if (obj1.hasOwnProperty("warning") && obj2.hasOwnProperty("warning") && obj2.warning !== obj1.warning) {
        return true;
      }
      if ((obj1.hasOwnProperty("critical") && !obj2.hasOwnProperty("critical")) || (!obj1.hasOwnProperty("critical") && obj2.hasOwnProperty("critical"))) {
        return true;
      }
      if ((obj1.hasOwnProperty("warning") && !obj2.hasOwnProperty("warning")) || (!obj1.hasOwnProperty("warning") && obj2.hasOwnProperty("warning"))) {
        return true;
      }

      return false;
    }
    return false;
  };

  onEditDialogSave = (event) => {
    event.preventDefault();
    const {
      singleChamberData, isInWarehouses, navigatedWarehouseData, SMS, push, email, Device, User, selectedRowData, existingUserGroup, clientSharing, devicesAdded,
      toleranceValue, toleranceTimeinSeconds, toleranceTime,
    } = this.state;

    const { CompanyInfo } = this.context;

    if (!isInWarehouses) {
      const patchArray = [];
      const newChamberId = event.target.chamberId.value;

      const newCommodity = event.target.commodity.value;

      const newTemperatureSettings = {};
      if (event.target.temperatureMinimum.value.length) { newTemperatureSettings.minViolation = Number(event.target.temperatureMinimum.value); }
      if (event.target.temperatureMaximum.value.length) { newTemperatureSettings.maxViolation = Number(event.target.temperatureMaximum.value); }
      if (event.target.temperatureWarningMinimum.value.length) { newTemperatureSettings.minWarning = Number(event.target.temperatureWarningMinimum.value); }
      if (event.target.temperatureWarningMaximum.value.length) { newTemperatureSettings.maxWarning = Number(event.target.temperatureWarningMaximum.value); }
      const newHumiditySettings = {};
      if (event.target.humidityMinimum.value.length) { newHumiditySettings.minViolation = Number(event.target.humidityMinimum.value); }
      if (event.target.humidityMaximum.value.length) { newHumiditySettings.maxViolation = Number(event.target.humidityMaximum.value); }
      if (event.target.humidityWarningMinimum.value.length) { newHumiditySettings.minWarning = Number(event.target.humidityWarningMinimum.value); }
      if (event.target.humidityWarningMaximum.value.length) { newHumiditySettings.maxWarning = Number(event.target.humidityWarningMaximum.value); }
      const newBatterySettings = {};

      if (event.target.critical.value.length) {
        newBatterySettings.critical = Number(event.target.critical.value);
      }

      if (event.target.warning.value.length) { newBatterySettings.warning = Number(event.target.warning.value); }

      // const oldSMS = singleChamberData && singleChamberData[0].notificationSettings && singleChamberData[0].notificationSettings.SMS ? singleChamberData[0].notificationSettings.SMS : false;
      // const oldPush = singleChamberData && singleChamberData[0].notificationSettings && singleChamberData[0].notificationSettings.push ? singleChamberData[0].notificationSettings.push : false;
      // const oldEmail = singleChamberData && singleChamberData[0].notificationSettings && singleChamberData[0].notificationSettings.email ? singleChamberData[0].notificationSettings.email : false;
      if (newChamberId !== singleChamberData && singleChamberData[0].id) {
        const patchedId = {
          path: "/id",
          op: "replace",
          value: newChamberId,
        };
        patchArray.push(patchedId);
      }

      // if (newCommodity !== singleChamberData && singleChamberData[0].note) {

      const patchedNote = {
        path: "/note",
        op: "replace",
        value: newCommodity,
      };
      patchArray.push(patchedNote);
      // }

      const temperatureChange = this.objectComparator(singleChamberData[0].temperatureSettings ? singleChamberData[0].temperatureSettings : {}, newTemperatureSettings);
      if (temperatureChange) {
        const patchTemperature = {
          path: "/temperatureSettings",
          op: "replace",
          value: newTemperatureSettings,
        };
        patchArray.push(patchTemperature);
      }
      const humidityChange = this.objectComparator(singleChamberData[0].humiditySettings ? singleChamberData[0].humiditySettings : {}, newHumiditySettings);
      if (humidityChange) {
        const patchHumidity = {
          path: "/humiditySettings",
          op: "replace",
          value: newHumiditySettings,
        };
        patchArray.push(patchHumidity);
      }

      const batteryChange = this.objectComparator(singleChamberData[0].batterySettings ? singleChamberData[0].batterySettings : {}, newBatterySettings);

      if (batteryChange) {
        const patchBattery = {
          path: "/batterySettings",
          op: "replace",
          value: newBatterySettings,
        };
        patchArray.push(patchBattery);
      }

      // if (SMS !== oldSMS || push !== oldPush || email !== oldEmail) {
      //   const patchNotifications = {
      //     op: 'replace',
      //     path: '/notificationSettings',
      //     value: { SMS, push, email },
      //   };
      //   patchArray.push(patchNotifications);
      // }

      // SMS Settings for Device

      const newSettingsforDevice = [];

      const objofDevice = devicesAdded.map((info) => {
        const {
          smsMinDuration, timeFormat, smsCount, value,
        } = info;

        return {
          uid: info.uid,
          smsCount: info.smsCount ? info.smsCount : "20",
          // smsMinDuration: (info.timeFormat === '2') ? info.smsMinDuration * 60 : info.smsMinDuration,
          smsMinDuration: info.smsMinDuration ? info.smsMinDuration : "60",
        };
      });

      if (objofDevice) {
        newSettingsforDevice.push(...objofDevice);
      }

      const patchsmsSettings = {
        path: "/devices",
        op: "replace",
        value: newSettingsforDevice,
      };
      patchArray.push(patchsmsSettings);

      // Notification settings for Clients and User Group
      const objtosend = [];

      const objofClient = clientSharing.map((obj) => ({
        groupUid: obj.groupUid,
        notificationSettingsByAdmin: obj.notificationSettingsByAdmin,
      }));
      if (objofClient) {
        objtosend.push(...objofClient);
      }

      const objofUserGroup = existingUserGroup.map((v) => ({
        groupUid: v.groupUid || v.userGroupUid,
        notificationSettingsByAdmin: v.notificationSettingsByAdmin,
      }
      ));
      if (objofUserGroup) {
        objtosend.push(...objofUserGroup);
      }

      const patchusernotification = {
        path: "/users",
        op: "replace",
        value: objtosend,
      };
      patchArray.push(patchusernotification);

      //   const timeTolerance=[{
      //     path:'/isTolerance',
      //     op:'replace',
      //     value: toleranceValue === "custom" ? true :false
      //   },{
      //     path:'/toleranceTime',
      //     op:'replace',
      //     value:toleranceValue === "custom" ? toleranceTimeinSeconds: 0
      //   }
      // ]

      const immediateAlert = {
        path: "/immediateAlert",
        op: "replace",
        value: (singleChamberData && singleChamberData[0].immediateAlert)? 1: 0,
      };
      patchArray.push(immediateAlert);

      const isTolerance = {
        path: "/isTolerance",
        op: "replace",
        value: !!(singleChamberData && singleChamberData[0].toleranceTime),
      };
      patchArray.push(isTolerance);

      const timeToleranceValue = {
        path: "/toleranceTime",
        op: "replace",
        value: singleChamberData && singleChamberData[0].toleranceTime || 0,
      };
      patchArray.push(timeToleranceValue);
      //  console.log('Patch Array', patchArray)
      this.setState({
        isLoading: true,
      }, () => {
        if (newChamberId === "") {
          cogoToast.error(" ID cannot be empty", { position: "top-right" });
          this.setState({
            isLoading: false,
          });
        } else {
          API
            .patch(`${APIURL}/assets/${singleChamberData[0].uid}`, patchArray)
            .then((patchArrayResponse) => {
              if (patchArrayResponse.data && patchArrayResponse.data.status && patchArrayResponse.data.status === "success") {
                this.getChambers(navigatedWarehouseData.uid)
                  .then((response) => {
                    this.setState({
                      chambersRowData: response,
                      SMS: false,
                      email: false,
                      push: false,
                      allDevices: [],
                      allClients: [],
                      allUsers: [],
                      devicesAdded: [],
                      clientSharing: [],
                      userSharing: [],
                      openEditDialog: false,
                      isLoading: false,
                      existingUserGroup: [],
                      opened: null,
                      selectedRowData: [],
                      searchInput: "",
                      minBatterVio: 0,
                      toleranceCheckboxValue: "instant",
                      immediateAlert: 0,
                      toleranceValueinSeconds: 0,
                    }, () => {
                      cogoToast.success(CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.chamberType
                        ? `${CompanyInfo.settings.chamberType} settings have been updated successfully`
                        : "Chamber settings have been updated successfully", { position: "top-right" });
                    });
                  })
                  .catch((chamberDataError) => {
                    cogoToast.error("unable to get chambers information", { position: "top-right" });
                  // console.error('chamberDataError', chamberDataError);
                  });
              } else {
                this.setState({
                  isLoading: false,

                });

                cogoToast.error(`Error updating  ${patchArrayResponse.data.data[0].path}`, { position: "top-right" });
                console.log("error patching update to asset", patchArrayResponse.data.data[0].path);
              }
            })
            .catch((err) => {
              this.setState({
                isLoading: false,

              });
              cogoToast.error("editing chamber settings threw an error", { position: "top-right" });
            // console.error('patchingArraySettings', err);
            });
        }
      });
    }
    if (isInWarehouses) {
      // for warehouse edit
      // console.log('In Ware house', event, event.target, event.target[0].value, event.target[1].value);
      // const patchNewwarehouse = [];
      const patchNewwarehouse = [];
      // const newwarehouseid = event.target[0].value;
      // const newwarehouseaddress = event.target[1].value;

      const newwarehouseid = event.target.warehouseId.value;
      const newwarehouseaddress = event.target.address.value;
      const selectedRow = { ...selectedRowData[0] };
      if (newwarehouseid !== selectedRowData[0].id) {
        const patchid = {
          path: "/id",
          op: "replace",
          value: newwarehouseid,
        };
        patchNewwarehouse.push(patchid);
      }
      if (newwarehouseaddress !== selectedRowData[0].note) {
        const patchaddress = {
          path: "/address",
          op: "replace",
          value: newwarehouseaddress,
        };
        patchNewwarehouse.push(patchaddress);
      }
      const valuetosend = existingUserGroup.map((v) => ({
        userGroupUid: v.userGroupUid,
        notificationSettingsByAdmin: v.notificationSettingsByAdmin,
      }
      ));
      const patchnotifications = {
        path: "/users",
        op: "replace",
        value: valuetosend,
      };
      patchNewwarehouse.push(patchnotifications);

      AddWarehouseSchema.validate({
        warehouseId: newwarehouseid,
        address: newwarehouseaddress,
      })
        .then(() => {
          this.setState({
            isLoading: true,
          }, () => {
            API
              .patch(`${APIURL}/assetGroups/${selectedRowData[0].uid}`, patchNewwarehouse)
              .then(async (newwarehouseresponse) => {
                if (newwarehouseresponse.data && newwarehouseresponse.data.status && newwarehouseresponse.data.status === "success") {
                  await this.getWarehouses()
                    .then((response) => {
                      this.setState({
                        isLoading: false,
                        warehousesRowData: response,
                        openEditDialog: false,
                        selectedRowData: [],
                        searchInput: "",
                        errorMessage: null,

                      }, () => {
                        cogoToast.success(CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.warehouseType ? `${CompanyInfo.settings.warehouseType} ` + " have been updated successfully" : "Warehouse have been updated successfully", { position: "top-right" });
                      });
                    })
                    .catch((chamberDataError) => {
                      cogoToast.error("unable to get warehouse information", { position: "top-right" });
                    });
                } else {
                  this.setState({
                    isLoading: false,
                    // selectedRowData:[],
                  });
                  cogoToast.error("Error updating warehouse", { position: "top-right" });
                }
              })
              .catch((err) => {
                this.setState({
                  isLoading: false,

                });
                cogoToast.error("editing warehouse threw an error", { position: "top-right" });
              });
          });
        })
        .catch((err) => {
          // console.log('Error', err);
          this.setState({
            errorMessage: err.message,
            isLoading: false,

          });
        });
    }
  };

  opencollapse = (devicename) => {
    const { opened } = this.state;
    if (opened && opened === devicename) {
      this.setState({
        opened: null,
        matchingswitch: devicename,
      });
      return;
    }
    this.setState({
      opened: devicename,
      matchingswitch: null,
    });
  };

  handlenotificationchange = (devicename) => {
    // this.setState({
    //   selecteddevicefornoti: devicename,
    // });

    const { matchingswitch } = this.state;
    if (matchingswitch && matchingswitch === devicename) {
      this.setState({
        matchingswitch: null,
      });
      return;
    }
    this.setState({
      matchingswitch: devicename,
    });
  };

  onWarehouseButtonClick = (params) => {
    this.setState({
      isInWarehouses: false,
      navigatedWarehouseData: params,
    }, () => {
      this.getChambers(params.uid)
        .then((chambersRowData) => {
          this.setState({
            chambersRowData,
            selectedRowData: [],
            searchInput: "",
          });
        })
        .catch((err) => {
          cogoToast.error("Unable to get Chambers of this Warehouse", { position: "top-right" });
          // console.error('gettingChambers Error', err);
        });
    });
  };

  onBackButtonClick = () => {
    this.setState({
      navigatedWarehouseData: null,
      chambersRowData: null,
      isInWarehouses: true,
      selectedRowData: [],
    }, () => {
      this.getWarehouses();
    });
  };

  // This is for auto suggestion when adding clients or users
  handleChangeForClients = (selectedOption) => {
    const { openEditDialog, selectedRowData } = this.state;
    const { CompanyInfo } = this.context;
    const arraytofilter = [];
    arraytofilter.push(selectedOption);
    const tosend = arraytofilter.map((item) => ({
      groupUid: item.value,
      notificationSettingsByAdmin: (item.notificationSettingsByAdmin ? item.notificationSettingsByAdmin : {}),
    }));

    if (openEditDialog) {
      const addingPatch = [{
        path: "/groups",
        op: "add",
        // value: [selectedOption.value],
        value: tosend,
      }];
      API
        .patch(`${APIURL}/assets/${selectedRowData[0].uid}`, addingPatch)
        .then((addingPatchResponse) => {
          if (addingPatchResponse.data && addingPatchResponse.data.status && addingPatchResponse.data.status === "success") {
            const newState = update(this.state, {
              clientSharing: {
                $push: [
                  {
                    groupName: selectedOption.label,
                    // value: selectedOption.value,
                    groupUid: selectedOption.value,
                  },
                ],
              },
              selectedOption: {
                $set: [null],
              },
            });
            this.setState(newState, () => {
              cogoToast.success(
                CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.chamberType
                  ? `Added client ${selectedOption.label}  to ${CompanyInfo.settings.chamberType} ${selectedRowData[0].id} `
                  : `Added client ${selectedOption.label} to chamber ${selectedRowData[0].id}`,

                { position: "top-right" },
              );
            });
          } else {
            cogoToast.error(`unable to add client ${selectedOption.label} to chamber ${selectedRowData[0].id}`, { position: "top-right" });
            // console.error('addingUsersPatchError', addingPatchResponse);
          }
        })
        .catch((addingPatchError) => {
          cogoToast.error(`unable to add client ${selectedOption.label} to chamber ${selectedRowData[0].id}`, { position: "top-right" });
          // console.error('addingUsersPatchError', addingPatchError);
        });
    } else {
      const newState = update(this.state, {
        clientSharing: {
          $push: [
            {
              label: selectedOption.label,
              value: selectedOption.value,
              // notificationSettingsByAdmin: selectedOption.notificationSettingsByAdmin,
            },
          ],
        },
        selectedOption: {
          $set: [null],
        },
      });
      this.setState(newState);
    }
  };

  // auto suggestion for adding users
  handleChangeForUsers = (selectedOption) => {
    const { openEditDialog, selectedRowData } = this.state;
    if (openEditDialog) {
      const addingPatch = [{
        path: "/users",
        op: "add",
        value: [selectedOption.value],
      }];
      API
        .patch(`${APIURL}/assets/${selectedRowData[0].uid}`, addingPatch)
        .then((addingPatchResponse) => {
          if (addingPatchResponse.data && addingPatchResponse.data.status && addingPatchResponse.data.status === "success") {
            const newState = update(this.state, {
              userSharing: {
                $push: [
                  {
                    label: selectedOption.label,
                    value: selectedOption.value,
                  },
                ],
              },
              selectedOption: {
                $set: [null],
              },
            });
            this.setState(newState, () => {
              cogoToast.success(`Added user ${selectedOption.label} to chamber ${selectedRowData[0].id}`, { position: "top-right" });
            });
          } else {
            cogoToast.error(`unable to add user ${selectedOption.label} to chamber ${selectedRowData[0].id}`, { position: "top-right" });
            // console.error('addingUsersPatchError', addingPatchResponse);
          }
        })
        .catch((addingPatchError) => {
          cogoToast.error(`unable to add user ${selectedOption.label} to chamber ${selectedRowData[0].id}`, { position: "top-right" });
          // console.error('addingUsersPatchError', addingPatchError);
        });
    } else {
      const newState = update(this.state, {
        userSharing: {
          $push: [
            {
              label: selectedOption.label,
              value: selectedOption.value,
            },
          ],
        },
        selectedOption: {
          $set: [null],
        },
      });
      this.setState(newState, () => {
        // console.log(this.state.clientSharing);
      });
    }
  };

  handletempChangeforDevices = (selectedOption) => {
    const { openEditDialog, selectedRowData, randomnotiSettings } = this.state;

    if (openEditDialog) {
      const selectedDevice = [];
      selectedDevice.push(...randomnotiSettings, selectedOption);
      const newDeviceList = randomnotiSettings.filter((data) => data.imei !== selectedOption.imei);

      this.setState({
        randomnotiSettings: selectedDevice,
      // allremdevices:newDeviceList
      });
    } else {
      const selectedDevice = [];
      selectedDevice.push(...randomnotiSettings, selectedOption);
      const newDeviceList = randomnotiSettings.filter((data) => data.imei !== selectedOption.imei);

      this.setState({
        randomnotiSettings: selectedDevice,
      // allremdevices:newDeviceList
      });
    }
  };

  handleCheckboxclick = (notificationSettings) => {
    const {
      groupUid, notificationType, email, value,
    } = notificationSettings;

    const { allClients, clientSharing } = this.state;

    const currentGroupNotificationSettings = clientSharing.find((g) => ((g.value || g.groupUid) === groupUid)).notificationSettingsByAdmin || {};
    const currentUserNotificationSettings = currentGroupNotificationSettings[email] || {};

    const newUserNotificationSettings = { ...currentUserNotificationSettings, [notificationType]: value }; // to update the type and value
    const newGroupNotificationSettings = { ...currentGroupNotificationSettings, [email]: newUserNotificationSettings }; // to update the notificationsetting

    const groupsCopy = JSON.parse(JSON.stringify(clientSharing));
    // to update that particular group
    const newGroups = groupsCopy.map((g) => {
      const newGroupId = g.value || g.groupUid;
      if (newGroupId !== groupUid) {
        return g;
      }
      g.notificationSettingsByAdmin = newGroupNotificationSettings;
      return g;
    });

    this.setState({ clientSharing: newGroups });
  };

  handleSMSSettinginChamber = (notificationSettings) => {
    const { allDevices, devicesAdded } = this.state;
    const {
      uid, changeType, value,
    } = notificationSettings;

    const selectedDevicefromList = devicesAdded.find((g) => (g.value === uid)) || {};

    const updateSelectedDevice = {
      ...selectedDevicefromList, uid, [changeType]: value,
    // smsLimitOverride: true,
    };

    const groupsCopy = JSON.parse(JSON.stringify(devicesAdded));

    const newGroups = groupsCopy.map((g) => {
      if (g.value !== uid) return g;
      g = updateSelectedDevice;
      return g;
    });
    this.setState({ devicesAdded: newGroups });
    // if (timeFormat) {
    //   this.setState({ timeFormat });
    // }

  // this.setState({
  //   messageCount: event.target.value,
  // });
  };

  handleTimeFormat = (event, uid) => {
    this.setState({
      timeFormat: event.target.value,
    });
  };

  handleChangeForDevices = (selectedOption) => {
    const { openEditDialog, selectedRowData, devicesAdded } = this.state;
    const { CompanyInfo } = this.context;
    const newDevice = [];
    newDevice.push(selectedOption);
    const tosend = newDevice.map((item) => ({
      uid: item.value,
      smsLimitOverride: (item.smsLimitOverride ? item.smsLimitOverride : false),
      smsCount: (item.smsCount ? item.smsCount : 5),
      smsMinDuration: (item.smsMinDuration ? item.smsMinDuration : 60),

    }));

    if (openEditDialog) {
      const addingPatch = [{
        path: "/devices",
        op: "add",
        // value: [selectedOption.value],
        value: tosend,
      }];

      API
        .patch(`${APIURL}/assets/${selectedRowData[0].uid}`, addingPatch)
        .then((addingPatchResponse) => {
          if (addingPatchResponse.data && addingPatchResponse.data.status && addingPatchResponse.data.status === "success") {
            // console.log('After adding device', addingPatchResponse);
            const newState = update(this.state, {
              devicesAdded: {
                $push: [
                  {
                    label: selectedOption.label,
                    value: selectedOption.value,
                  },
                ],
              },
              selectedOption: {
                $set: [null],
              },
            });
            this.setState(newState, () => {
              cogoToast.success(CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.chamberType
                ? `Added device ${selectedOption.label} to ${CompanyInfo.settings.chamberType} ${selectedRowData[0].id}`
                : `Added device ${selectedOption.label} to chamber ${selectedRowData[0].id}`, { position: "top-right" });
            });
          } else {
            cogoToast.error(`unable to add device ${selectedOption.label} to chamber ${selectedRowData[0].id}`, { position: "top-right" });
            // console.error('addingDevicesPatchError', addingPatchResponse);
          }
        })
        .catch((addingPatchError) => {
          cogoToast.error(`unable to add device ${selectedOption.label} to chamber ${selectedRowData[0].id}`, { position: "top-right" });
          // console.error('addingDevicesPatchError', addingPatchError);
        });
    } else {
      const newState = update(this.state, {
        devicesAdded: {
          $push: [
            {
              label: selectedOption.label,
              value: selectedOption.value,
            },
          ],
        },
        selectedOption: {
          $set: [null],
        },
      });
      this.setState(newState);
    }
  };

  handletempRemoval = (label, value) => {
    const { randomnotiSettings, allremdevices } = this.state;

    const filterDeleteList = randomnotiSettings.filter(((data) => data.imei !== value));
    const remDeviceArray = [];
    const remainingDevices = randomnotiSettings.filter(((data) => data.imei === value));
    remDeviceArray.push(...allremdevices, ...remainingDevices);

    this.setState({
      randomnotiSettings: filterDeleteList,
      allremdevices: remDeviceArray,
    });
  };

  handleRemoval = (label, value, type) => {
    const {
      selectedRowData, devicesAdded, userSharing, clientSharing, openEditDialog,
    } = this.state;
    const { CompanyInfo } = this.context;
    if (openEditDialog === true) {
      const RemovalPatch = [{
        path: `/${type}`,
        op: "remove",
        value: [value],
      }];

      API
        .patch(`${APIURL}/assets/${selectedRowData[0].uid}`, RemovalPatch)
        .then((RemovalPatchResponse) => {
          if (RemovalPatchResponse.data && RemovalPatchResponse.data.status && RemovalPatchResponse.data.status === "success") {
            if (type === "devices") {
              this.getDevices()
                .then((devices) => {
                  this.setState({
                    allDevices: devices && devices !== null ? devices.map((item) => (item.paired === false ? { label: item.id, value: item.IMEI } : null)).filter((x) => x) : [],
                  }, () => {
                    const updatedDevicesAdded = devicesAdded.filter(
                      (item) => item.value !== value,
                    );

                    this.setState({ devicesAdded: updatedDevicesAdded }, () => {
                      cogoToast.success(CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.chamberType
                        ? `Removed the device ${label} from ${CompanyInfo.settings.chamberType} `
                        : `Removed the device ${label} from chamber ${selectedRowData[0].id}`, { position: "top-right" });
                    });
                  });
                })
                .catch((devicesError) => {
                  cogoToast.error("Error getting devices from server", { position: "top-right" });
                  // console.error('gettingDevicesError', devicesError);
                });
            }
            if (type === "groups") {
              cogoToast.success(CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.chamberType
                ? `Removed the client ${label} from ${CompanyInfo.settings.chamberType} `
                : `Removed the client ${label} from chamber ${selectedRowData[0].id}`, { position: "top-right" });

              // const newUsers = existingUserGroup.filter((user) => user.groupUid !== todeleteuids);
              const updatedClientSharing = clientSharing.filter(
                (item) => item.groupUid !== value,
              );
              this.setState({
                clientSharing: updatedClientSharing,
              });

              // then((deletedUserGroup) => {
              //   if (deletedUserGroup.data.status === 'success') {
              //     cogoToast.success('Deleted userGroup from Warehouse', { position: 'top-right' });
              //     const updatedClientSharing = clientSharing.filter(
              //               (item) => item.value !== value,
              //             );
              //     this.setState({
              //       clientSharing : updatedClientSharing,
              //     });
              //   } else {
              //     console.error('Error deleting userGroup');
              //   }
              // }).catch((error) => {
              //   console.log('error deleting userGroup', error.message || error);
              // });

              // this.getUsers()
              //   .then((users) => {
              //     this.setState({
              //       allClients: users && users !== null ? users.map((item) => (item.role === 'level2' ? { label: item.name, value: item.uid } : null)).filter((x) => x) : [],
              //       allUsers: users && users !== null ? users.map((item) => (item.role === 'level1' ? { label: item.name, value: item.uid } : null)).filter((x) => x) : [],
              //     }, () => {
              //       const updatedClientSharing = clientSharing.filter(
              //         (item) => item.value !== value,
              //       );
              //       const updatedUserSharing = userSharing.filter(
              //         (item) => item.value !== value,
              //       );
              //       this.setState({ userSharing: updatedUserSharing, clientSharing: updatedClientSharing }, () => {
              //         cogoToast.success(`Removed the user ${label} from chamber ${selectedRowData[0].id}`, { position: 'top-right' });
              //       });
              //     });
              //   })
              //   .catch((usersError) => {
              //     cogoToast.error('Error getting users from server', { position: 'top-right' });
              //     // console.error('gettingUsersError', usersError);
              //   });
            }
          } else {
            cogoToast.error(`unable to remove ${type} from chamber`, { position: "top-right" });
            // console.error('RemovalpatchError', RemovalPatchResponse);
          }
        })
        .catch((err) => {
          cogoToast.error(`unable to remove ${type} from chamber`, { position: "top-right" });
          // console.error('RemovalpatchError', err);
        });
    } else if (type === "devices") {
      const updatedDevicesAdded = devicesAdded.filter(
        (item) => item.value !== value,
      );
      this.setState({ devicesAdded: updatedDevicesAdded });
    } else if (type === "groups") {
      const updatedClientSharing = clientSharing.filter(
        (item) => item.value !== value,
      );
      const updatedUserSharing = userSharing.filter(
        (item) => item.value !== value,
      );
      this.setState({ userSharing: updatedUserSharing, clientSharing: updatedClientSharing });
    }
  };

  handleUsersinClients = (name, uid) => {
    const { setUsersListinClients, openEditDialog, isInWarehouses } = this.state;

    // const uid = uid1 || uid2;
    if (openEditDialog && !isInWarehouses) {
      if (setUsersListinClients && setUsersListinClients === uid) {
        this.setState({
          setUsersListinClients: null,
          // usersinClients:[],
        });
        return;
      }
      this.setState({
        setUsersListinClients: uid,
        // usersinClients:[],
      }, () => {
        const { allClients, clientSharing } = this.state;
        if (uid) {
          API.get(`${APIURL}/groups/${uid}/members`)
            .then((response) => {
              const newGroups = clientSharing.map((a) => {
                if (a.groupUid !== uid) {
                  return a;
                }
                a.users = response.data.data;
                return a;
              });
              this.setState({
                clientSharing: newGroups,

              });
            });
        }
      });
    } else {
      if (setUsersListinClients && setUsersListinClients === uid) {
        this.setState({
          setUsersListinClients: null,
          // usersinClients:[],
        });
        return;
      }
      this.setState({
        setUsersListinClients: uid,
        // usersinClients:[],
      });
      const { allClients, clientSharing } = this.state;
      if (uid) {
        API.get(`${APIURL}/groups/${uid}/members`)
          .then((response) => {
            const newGroups = clientSharing.map((a) => {
              if (a.value !== uid) {
                return a;
              }
              a.users = response.data.data;
              return a;
            });
            this.setState({
              clientSharing: newGroups,

            });
          });
      }
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.clientSharing !== prevState.clientSharing) {
      this.giveUsers(this.state.setUsersListinClients);
    }
  }

  giveUsers = (myuid) => {
    const { setUsersListinClients, allClients, clientSharing } = this.state;

    if (setUsersListinClients) {
      const selectedGroup = clientSharing.find((g) => ((g.value || g.groupUid) === setUsersListinClients));
      //  const selectedGroup= clientSharing.filter((g)=>((g.value || g.groupUid) ===  setUsersListinClients ))[0];
      if (!selectedGroup) {
        this.setState({
          setUsersListinClients: null,
        });

        return;
      }
      const selectedlistofUsers = (selectedGroup.users || []).map((user) => ({
        ...user,
      }));

      this.setState({
        usersinClients: selectedlistofUsers,
      });
    }
  };

  onCheckboxClick = (props) => {
    if (props === "SMS") {
      this.setState((prevState) => ({
        SMS: !prevState.SMS,
      }));
    }
    if (props === "push") {
      this.setState((prevState) => ({
        push: !prevState.push,
      }));
    }
    if (props === "email") {
      this.setState((prevState) => ({
        email: !prevState.email,
      }));
    }
  };

  getTemperatureInUnits = (temperature, settings) => {
    // if (!Number(temperature)) return '';
    if (settings && settings.temperature === "F") return CtoF(temperature).toFixed(1);
    return temperature;
  };

  handleSelectionClear = () => {
    this.setState({
      selectedRowData: [],
    });
  };

  onSearchChange = (event) => {
    this.setState({
      searchInput: event.target.value,
    });
  };

  convertintoHours = (timeinSeconds) => {
    const sec = parseInt(timeinSeconds, 10); // convert value to number if it's string
    let hours = Math.floor(sec / 3600); // get hours
    let minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
    const seconds = sec - (hours * 3600) - (minutes * 60); //  get seconds
    // add 0 if value < 10; Example: 2 => 02
    if (hours < 10) { hours = `0${hours}`; }
    if (minutes < 10) { minutes = `0${minutes}`; }
    // if (seconds < 10) {seconds = "0"+seconds;}
    const convertedHours = `${hours}:${minutes}`;

    return convertedHours;
  };

  handledefaultTolerance = (value) => {
    const { openEditDialog, singleChamberData } = this.state;

    this.setState({
      toleranceValueinSeconds: value,
    });
    if (openEditDialog) {
      const newSingleChamberData = { ...singleChamberData[0], toleranceTime: value };
      this.setState({
        singleChamberData: [newSingleChamberData],

      });
    }
  };

  handleImmediateAlertChange = (value) => {
    const { openEditDialog, singleChamberData } = this.state;
    this.setState({
      immediateAlert: value,
    });
    if (openEditDialog) {
      const newSingleChamberData = { ...singleChamberData[0], immediateAlert: value };
      this.setState({
        singleChamberData: [newSingleChamberData],
      });
    }
  };


  // handleswitchOptionChange=(switchvalue)=>{

  //   const {openEditDialog, singleChamberData}= this.state;

  //   if(openEditDialog){

  //     const newSingleChamberData={...singleChamberData[0],isTolerance:!(singleChamberData[0].isTolerance),toleranceTime:0}
  //     this.setState({
  //       singleChamberData:[newSingleChamberData],

  //     })
  //   }else{
  //     this.setState({
  //       toleranceCheckboxValue:switchvalue
  //     })
  //   }
  // }

  handleuserEmail = (e) => {
    this.setState({
      userEmail: e.target && e.target.value,
    });
  };

  handleCountryCode = (e) => {
    // console.log('CC', e.target.value)
    this.setState({
      countryCode: e.target && e.target.value,
    });
  };

  render() {
    const {
      isInWarehouses,
      openAddDialog,
      openAddDialogForChambers,
      errorMessage,
      openDeleteDialog,
      openEditDialog,
      selectedRowData,
      navigatedWarehouseData,
      selectedOption,
      clientSharing,
      userSharing,
      devicesAdded,
      fullScreen,
      warehousesRowData,
      chambersRowData,
      allDevices,
      allClients,
      allUsers,
      singleChamberData,
      SMS,
      push,
      email,
      isLoading,
      groups,
      userGroups,
      userGroupNames,
      existingUserGroup,
      setUsersListinClients,
      usersinClients,
      selectedlistofUsers,
      randomnotiSettings,
      matchingswitch,
      opened,
      allremdevices,
      messageCount,
      minDuration,
      timeFormat,
      toleranceValue,
      toleranceTime,
      searchInput,
      temperatureSettingError,
      batterySettingError,
      minBatterVio,
      changeNotiIcon,
      immediateAlert,
      toleranceValueinSeconds,
      toleranceCheckboxValue,
      userEmail,
      countryCode,
      phoneNumber,
    } = this.state;
    const { classes, user } = this.props;
    let companySettings = null;
    if (user && user.companySettings) companySettings = user.companySettings;

    const clients = allClients.filter(
      (item) => !clientSharing.map((el) => el.label || el.groupName).includes(item.label),
    );
    const users = allUsers.filter(
      (item) => !userSharing.map((el) => el.label).includes(item.label),
    );
    // left over list of devices
    const devices = allDevices.filter(
      (item) => !devicesAdded.map((el) => el.label).includes(item.label),
    );

    const { userPermissions } = this.context;
    const { CompanyInfo } = this.context;

    return (
      <div>
        {isLoading ? (
          <div className={classes.overlay}>
            <CircularProgress
              style={{
                position: "absolute", top: "30%", left: "50%", zIndex: 100,
              }}
              value={25}
            />
          </div>
        ) : ""}
        {isInWarehouses && userPermissions && userPermissions.assetGroups && userPermissions.assetGroups.read && (
          <div>
            <Grid container spacing={0} className={classes.topStrip}>
              <Grid item md={12} sm={12} xs className={classes.item}>
                {/* BreadCrumbs with left float in classes */}
                <PrimaryButton
                  text={CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.warehouseType ? "Add" + ` ${CompanyInfo.settings.warehouseType}` : " Add Warehouse"}

                  // color="primary"
                  onClick={this.onAddButtonClick}
                  disabled={!(userPermissions && userPermissions.assetGroups && userPermissions.assetGroups.create && userPermissions.groups && userPermissions.groups.read)}
                />

              </Grid>
            </Grid>

            <Grow in={isInWarehouses}>
              <Grid container spacing={0} justifyContent="center" style={{ paddingBottom: "5px" }}>
                <Grid item lg={7} md={5} sm={2} xs={6} style={{ textAlign: "left", display: "flex" }}>
                  <div className={classes.deletebuttondiv}>

                    <Button
                      disabled={!(userPermissions && userPermissions.assetGroups && userPermissions.assetGroups.delete && selectedRowData && selectedRowData.length >= 1)}
                        // className={(userPermissions && userPermissions.assetGroups.delete && selectedRowData && selectedRowData.length === 1) ? classes.deleteDiv : classes.disabledButton}
                      onClick={(selectedRowData && selectedRowData.length >= 1) ? this.onDeleteButtonClick : null}
                      onKeyPress={(selectedRowData && selectedRowData.length >= 1) ? this.onDeleteButtonClick : null}
                      color="error"
                      tabIndex="0"
                    >

                      <Tooltip title="Delete" placement="top-end">
                        <span><Delete /></span>
                      </Tooltip>
                      {/* <span className={classes.buttonText}>Delete</span> */}

                    </Button>

                  </div>
                  <div className={classes.editbuttondiv}>

                    <Button
                      disabled={!(userPermissions && userPermissions.assetGroups && userPermissions.assetGroups.update && userPermissions.groups && userPermissions.groups.read && selectedRowData && selectedRowData.length >= 1)}

                      onClick={(selectedRowData && selectedRowData.length >= 1) ? this.onEditButtonClick : null}
                      onKeyPress={(selectedRowData && selectedRowData.length >= 1) ? this.onEditButtonClick : null}
                    >
                      <Tooltip title="Edit" placement="top-end">
                        <span>
                          <Edit
                            className={(userPermissions && userPermissions.assetGroups.update && selectedRowData && selectedRowData.length >= 1) ? classes.editDiv : classes.disabledButton}
                          />
                        </span>
                      </Tooltip>
                      {/* <span className={classes.buttonText}>Edit</span> */}
                    </Button>

                  </div>
                </Grid>
                <Grid item lg={2} md={3} sm={4} xs={6}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      flexGrow: 1,
                    }}
                  >
                    <Tooltip title="use 'shift' or 'ctrl' for multi-select">

                      <div>
                        <span>Selected : </span>
                        <span className={classes.Selected}>
                          {selectedRowData && selectedRowData.length}
                        </span>
                      </div>

                    </Tooltip>

                    <Tooltip title="Clear Selection">
                      <IconButton
                        onClick={this.handleSelectionClear}
                      >
                        <HighlightOffIcon
                          className={
                                  selectedRowData && selectedRowData.length > 0
                                    ? classes.clearSelectionActive
                                    : classes.clearSelectionDisabled
                                }
                        />
                      </IconButton>
                    </Tooltip>
                  </div>
                </Grid>
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <SearchOption
                    variant="standard"
                    id="userSearchInput"
                    value={searchInput}
                    onChange={this.onSearchChange}
                    Type={CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.warehouseType}
                    placeholder={CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.warehouseType ? `Search by ${CompanyInfo.settings.warehouseType} Id` : "Search by warehouse Id"}
                  />
                </Grid>
              </Grid>
            </Grow>

            <WarehousesGrid
              rowData={warehousesRowData}

              onSelectionChanged={this.onSelectionChanged}
              onClick={this.onWarehouseButtonClick}
              searchvalue={searchInput}
              selectedRowData={selectedRowData}
            />
            {/* start */}

            {/* end */}

            <Dialog
              open={openAddDialog}
              TransitionComponent={Zoom}
              onClose={this.onAddDialogClose}
              aria-labelledby="form-dialog-title"
              maxWidth="md"
              fullWidth
              scroll="body"
              fullScreen={fullScreen}
              PaperProps={{
                style: {
                  backgroundColor: "#f2f2f2",
                },
              }}
              // disableBackdropClick

            >
              <Grow in={openAddDialog}>
                <form onSubmit={this.onAddDialogSave}>
                  {fullScreen && (
                    <AppBar className={classes.appBar}>
                      <Toolbar style={{ backgroundColor: "#008081" }}>
                        <IconButton
                          edge="start"
                          color="inherit"
                          onClick={this.onAddDialogClose}
                          aria-label="close"
                        >
                          <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                          {CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.warehouseType ? "Add" + ` ${CompanyInfo.settings.warehouseType}` : "Add Warehouse " }
                        </Typography>
                        <Button color="inherit" type="submit">
                          save
                        </Button>
                      </Toolbar>
                    </AppBar>
                  )}
                  {!fullScreen && (
                    <DialogTitle
                      id="form-dialog-title"

                      className={classes.dialogtitle}
                    >

                      {CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.warehouseType ? "Add" + ` ${CompanyInfo.settings.warehouseType}` : "Add Warehouse " }
                    </DialogTitle>
                  )}
                  <DialogContent>
                    <Grid container style={{ paddingTop: fullScreen ? "4rem" : "0rem" }} spacing={2}>
                      <Grid item xs={12}>
                        <Input
                          id="warehouseId"
                          placeholder={CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.warehouseType ? `${CompanyInfo.settings.warehouseType} ` + "ID" : "Warehouse ID "}
                          // className={classes.textField}
                          backgroundColor="#fff"
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Input
                          id="address"
                          placeholder="Address"
                          // multiline
                          // className={classes.textField}
                          backgroundColor="#fff"
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <UserGroupList
                          onNotifictionSettingsChange={(a) => this.updateUserGroupNotification(a)} // to change the notifications of Users
                          onGroupSelectionChange={(z) => this.onGroupSelectionChange(z)} // when we delete any UserGRoup from existing list
                          userGroupList={userGroups} // we can remove this ..
                          userGroupsfromWarehouse={userGroupNames} // list of all UserGroups
                          existingUserGroup={existingUserGroup} // selected userGroup list
                          onexistingUserGroupchange={(list) => this.onexistingUserGroupchange(list)} // on Selection Change in Dropdown
                          updateExistingUserList={(updatedlist) => this.updateExistingUserList(updatedlist)} // we are adding users from api call
                          isInWarehouses={isInWarehouses}
                          changeNotiIcon={changeNotiIcon}
                          // groupuid= {(id, groupusers)=> this.groupusers(id,groupusers)}

                        />
                      </Grid>
                      <div style={{ marginTop: "10px", paddingTop: "10px" }}>
                        {errorMessage && errorMessage !== null ? (
                          <div className={classes.errorMessageDiv}>
                            <span className={classes.errorMessageText}>
                              *
                              {errorMessage}
                            </span>
                          </div>
                        ) : null}
                      </div>
                    </Grid>
                  </DialogContent>
                  {!fullScreen && (
                    <DialogActions>
                      <SecondaryButton onClick={this.onAddDialogClose} text="Cancel" />

                      <PrimaryButton
                        type="submit"
                        text="Save"
                      />

                    </DialogActions>
                  )}
                </form>
              </Grow>
            </Dialog>

            <Dialog
              open={openDeleteDialog}
              TransitionComponent={Zoom}
              onClose={this.onDeleteDialogClose}
              aria-labelledby="form-dialog-title"
              maxWidth="sm"
              scroll="body"
            >
              <DialogTitle id="form-dialog-title">
                <span className={classes.warning}>Warning</span>
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {/* Are you sure you want to delete the selected Warehouse(s) ? */}
                  Are you sure you want to delete the selected
                  {" "}
                  {CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.warehouseType
                    ? `${CompanyInfo.settings.warehouseType}(s)` : "Warehouse(s)" }
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <SecondaryButton onClick={this.onDeleteDialogClose} text="No" />

                <PrimaryButton onClick={this.onDeleteDialogSave} text="Yes" />
              </DialogActions>
            </Dialog>

            <Dialog
              open={openEditDialog}
              TransitionComponent={Zoom}
              onClose={this.onEditDialogClose}
              aria-labelledby="form-dialog-title"
              maxWidth="md"
              fullWidth
              scroll="body"
              fullScreen={fullScreen}
              PaperProps={{
                style: {
                  backgroundColor: "#f2f2f2",
                },
              }}
              // disableBackdropClick
              // className={classes.dialog}
            >
              <Grow in={openEditDialog}>
                <form onSubmit={this.onEditDialogSave}>
                  {fullScreen && (
                    <AppBar className={classes.appBar}>
                      <Toolbar style={{ backgroundColor: "#008081" }}>
                        <IconButton
                          edge="start"
                          color="inherit"
                          onClick={this.onEditDialogClose}
                          aria-label="close"
                        >
                          <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                          {CompanyInfo && CompanyInfo.settings.warehouseType ? "Edit" + ` ${CompanyInfo.settings.warehouseType}` : "Edit Warehouse " }
                        </Typography>
                        <Button color="inherit" type="submit">
                          update
                        </Button>
                      </Toolbar>
                    </AppBar>
                  )}
                  {!fullScreen && (
                    <DialogTitle
                      id="form-dialog-title"

                      className={classes.dialogtitle}
                    >
                      {CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.warehouseType ? "Edit" + ` ${CompanyInfo.settings.warehouseType}` : "Edit Warehouse " }

                    </DialogTitle>
                  )}
                  <DialogContent>
                    <Grid container style={{ paddingTop: fullScreen ? "4rem" : "0rem" }} spacing={2}>
                      <Grid item xs={12}>
                        <Input
                          id="warehouseId"
                          placeholder={CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.warehouseType ? `${CompanyInfo.settings.warehouseType} ` + "ID" : "Warehouse ID"}
                          defaultValue={
                            selectedRowData && selectedRowData.length === 1
                              ? selectedRowData[0].id
                              : "N/A"
                          }
                          backgroundColor="#fff"
                          // className={classes.textField}
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12}>

                        <Input
                          id="address"
                          placeholder="Address"
                          // multiline
                          defaultValue={
                            selectedRowData && selectedRowData.length === 1
                              ? selectedRowData[0].note
                              : "N/A"
                          }
                          backgroundColor="#fff"
                          // className={classes.textField}
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <UserGroupList
                          onNotifictionSettingsChange={(a) => this.updateUserGroupNotification(a)} // to change the notifications of Users
                          onGroupSelectionChange={(z) => this.onGroupSelectionChange(z)} // when we delete any UserGRoup from existing list
                          userGroupList={userGroups} // we can remove this ..
                          userGroupsfromWarehouse={userGroupNames} // list of all UserGroups in dropdown
                          existingUserGroup={existingUserGroup} // selected userGroup list
                          onexistingUserGroupchange={(list) => this.onexistingUserGroupchange(list)} // on Selection Change in Dropdown
                          updateExistingUserList={(updatedlist) => this.updateExistingUserList(updatedlist)} // we are adding users from api call
                          isInWarehouses={isInWarehouses}
                          changeNotiIcon={changeNotiIcon}
                        />

                      </Grid>
                      <div style={{ marginTop: "10px", paddingTop: "10px" }}>
                        {errorMessage && errorMessage !== null ? (

                          <div className={classes.errorMessageDiv}>
                            <span className={classes.errorMessageText}>
                              *
                              {errorMessage}
                            </span>
                          </div>
                        ) : null}
                      </div>
                    </Grid>
                  </DialogContent>
                  {!fullScreen && (
                    <DialogActions>
                      <SecondaryButton onClick={this.onEditDialogClose} text="Cancel" />

                      <PrimaryButton type="submit" text="Update" />

                    </DialogActions>
                  )}
                </form>
              </Grow>
            </Dialog>
          </div>

        )}

        {!isInWarehouses && userPermissions && userPermissions.assets && userPermissions.assets.read && (
          <div>
            <Grid container spacing={0} className={classes.topStrip}>
              <Grid item md={12} sm={12} xs>
                {/* BreadCrumbs with left float in classes */}
                <IconButton
                  onClick={this.onBackButtonClick}
                  className={classes.floatLeft}
                >
                  <Tooltip
                    title={CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.warehouseType
                      ? `Back to ${CompanyInfo.settings.warehouseType}`
                      : "Back to  warehouse(s)"}
                  >
                    <ArrowBackIosIcon />
                  </Tooltip>
                  {navigatedWarehouseData && navigatedWarehouseData.id
                    ? navigatedWarehouseData.id
                    : "N/A"}
                </IconButton>
                <PrimaryButton
                  text={CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.chamberType
                    ? `Add ${CompanyInfo.settings.chamberType}`
                    : "Add Chamber"}
                  onClick={this.onAddButtonClick}
                  disabled={!(userPermissions && userPermissions.assets && userPermissions.assets.create && userPermissions.groups && userPermissions.groups.read
                    && userPermissions.devices && userPermissions.devices.read)}
                />

              </Grid>
            </Grid>

            <Grow in={!isInWarehouses}>
              <Grid container spacing={0} justifyContent="center">
                <Grid item lg={7} md={5} sm={2} xs={6} style={{ textAlign: "left", display: "flex" }}>
                  <div className={classes.deletebuttondiv}>

                    <Button

                      color="error"
                      disabled={!(userPermissions && userPermissions.assets && userPermissions.assets.delete && selectedRowData && selectedRowData.length >= 1)}
                      onClick={this.onDeleteButtonClick}
                      onKeyPress={this.onDeleteButtonClick}
                      role="button"
                      tabIndex="0"
                    >
                      <Tooltip title="Delete" placement="top-end">
                        <span><Delete /></span>
                      </Tooltip>
                      {/* <span className={classes.buttonText}>Delete</span> */}
                    </Button>

                  </div>
                  <div className={classes.editbuttondiv}>

                    <Button

                      disabled={!(userPermissions && userPermissions.assets && userPermissions.assets.update && userPermissions.groups && userPermissions.groups.read
                          && userPermissions.devices && userPermissions.devices.read
                           && selectedRowData && selectedRowData.length >= 1)}
                        // color="primary"
                      onClick={this.onEditButtonClick}
                      onKeyPress={this.onEditButtonClick}
                      role="button"
                      tabIndex="0"
                    >
                      <Tooltip title="Edit" placement="top-end">
                        <span>
                          <Edit
                            className={(userPermissions && userPermissions.assets.update && selectedRowData && selectedRowData.length >= 1) ? classes.editDiv : classes.disabledButton}
                          />
                        </span>
                      </Tooltip>
                      {/* <span className={classes.buttonText}>Edit</span> */}
                    </Button>

                  </div>
                </Grid>
                <Grid item lg={2} md={3} sm={4} xs={6}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      flexGrow: 1,
                    }}
                  >
                    <Tooltip title="use 'shift' or 'ctrl' for multi-select">
                      {/* <IconButton> */}
                      <div>
                        <span>Selected : </span>
                        <span className={classes.Selected}>
                          {selectedRowData && selectedRowData.length}
                        </span>
                      </div>
                      {/* </IconButton> */}
                    </Tooltip>
                    <Tooltip title="Clear Selection">
                      <IconButton
                        onClick={this.handleSelectionClear}
                      >
                        <HighlightOffIcon
                          className={
                                  selectedRowData && selectedRowData.length > 0
                                    ? classes.clearSelectionActive
                                    : classes.clearSelectionDisabled
                                }
                        />
                      </IconButton>
                    </Tooltip>
                  </div>
                </Grid>
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <SearchOption
                    variant="standard"
                    id="userSearchInput"
                    value={searchInput}
                    onChange={this.onSearchChange}
                    Type={CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.chamberType}
                    placeholder={CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.chamberType ? `Search by ${CompanyInfo.settings.chamberType} Id` : "Search by chamber Id"}
                  />
                </Grid>
              </Grid>

            </Grow>
            <ChambersGrid
              rowData={chambersRowData}

              searchvalue={searchInput}
              selectedRowData={selectedRowData}
              onSelectionChanged={this.onSelectionChanged}
              companySettings={companySettings}
            />

            <Dialog
              fullScreen={fullScreen}
              open={openAddDialogForChambers}
              TransitionComponent={Zoom}
              onClose={this.onAddDialogClose}
              aria-labelledby="form-dialog-title"
              // maxWidth="sm"
              maxWidth="md"
              fullWidth
              scroll="paper"
              PaperProps={{
                style: {
                  backgroundColor: "#f2f2f2",
                },
              }}
              // style={{backgroundColor:'#f2f2f2'}}
              // disableBackdropClick
            >
              <Grow in={openAddDialogForChambers}>
                <form onSubmit={this.onAddDialogSave}>
                  {fullScreen && (
                    <AppBar className={classes.appBar}>
                      <Toolbar style={{ backgroundColor: "#008081" }}>
                        <IconButton
                          edge="start"
                          color="inherit"
                          onClick={this.onAddDialogClose}
                          aria-label="close"
                        >
                          <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                          Add & Configure
                          {" "}
                          {CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.chamberType
                            ? CompanyInfo.settings.chamberType
                            : "Chamber"}
                        </Typography>
                        <Button color="inherit" type="submit">
                          save
                        </Button>
                      </Toolbar>
                    </AppBar>
                  )}
                  {!fullScreen && (
                    <DialogTitle
                      id="form-dialog-title"

                      className={classes.dialogtitle}
                    >
                      Add & Configure
                      {" "}
                      {CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.chamberType
                        ? CompanyInfo.settings.chamberType
                        : "Chamber"}
                    </DialogTitle>
                  )}
                  <DialogContent>
                    <div>
                      <Grid container style={{ paddingTop: fullScreen ? "3rem" : "0rem" }} spacing={1}>
                        <Grid item xs={12}>
                          <div className={classes.boxDiv}>
                            <Grid container>
                              <Grid item lg={3} md={3} sm={5} xs={6}>
                                <Typography variant="h6" className={classes.title}>
                                  {/* <img
                                src="../img/chamber.svg"
                                alt="chamber"
                              /> */}

                                  {CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.chamberType
                                    ? `${CompanyInfo.settings.chamberType} Settings`
                                    : " Chamber Settings"}
                                </Typography>
                              </Grid>
                              <Grid item lg={9} md={9} sm={7} xs={6} sx={{ margin: "auto" }}>
                                <Divider />
                              </Grid>
                            </Grid>

                            <Grid container spacing={2} style={{ marginTop: "1rem" }}>
                              <Grid container>
                                <Grid
                                  item
                                  lg={2}
                                  md={2}
                                  sm={3}
                                  xs={12}
                                  style={{
                                    padding: !fullScreen ? "1.5rem" : "0rem",
                                    paddingLeft: !fullScreen ? "1rem" : "1rem",
                                    textAlign: "left",

                                    margin: "auto",
                                    //  height: '2.25rem',
                                    // fontWeight:700,
                                    fontSize: "14px",
                                    fontFamily: "Montserrat",
                                  }}
                                >

                                  {CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.chamberType
                                    ? `${CompanyInfo.settings.chamberType} ID`
                                    : " Chamber ID"}
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                  <InputForChamber
                                    id="chamberId"
                                    placeholder={CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.chamberType
                                      ? `${CompanyInfo.settings.chamberType} Id`
                                      : " Chamber Id"}
                                    margin="normal"
                                    autoComplete="off"
                                  />
                                </Grid>
                                <Grid item lg={4} md={4} sm={3} xs={0} />

                              </Grid>
                              <Grid container>
                                <Grid
                                  item
                                  lg={2}
                                  md={2}
                                  sm={3}
                                  xs={12}
                                  style={{
                                    padding: !fullScreen ? "1.5rem" : "0rem",
                                    paddingLeft: !fullScreen ? "1rem" : "1rem",
                                    textAlign: "left",

                                    margin: "auto",

                                    fontSize: "14px",
                                    fontFamily: "Montserrat",
                                  }}
                                >
                                  Commodity
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                  <InputForChamber
                                    id="commodity"
                                    autoComplete="off"
                                    placeholder="Commodity"
                                    margin="normal"
                                  />
                                </Grid>
                                <Grid item lg={4} md={4} sm={3} xs={0} />

                              </Grid>
                            </Grid>

                          </div>
                        </Grid>
                        <Grid item xs={12}>
                          <div className={classes.boxDiv}>
                            <Grid container>
                              <Grid item lg={4} md={4} sm={6} xs={8}>
                                <Typography variant="h6" className={classes.title}>
                                  {/* <img
                                src="../img/temperature.svg"
                                alt="Temperature"
                              /> */}
                                  {(companySettings && companySettings.temperature && companySettings.temperature === "F") ? "Temperature settings (°F)" : "Temperature settings (°C)"}
                                </Typography>
                              </Grid>
                              <Grid item lg={8} md={8} sm={6} xs={4} sx={{ margin: "auto" }}>
                                <Divider />
                              </Grid>
                            </Grid>
                            <TemperatureSettings />

                          </div>
                        </Grid>
                        <Grid item xs={12}>
                          <div className={classes.boxDiv}>

                            <Grid container>
                              <Grid item lg={4} md={4} sm={6} xs={8}>
                                <Typography variant="h6" className={classes.title}>
                                  {/* <img src="../img/humidity.svg" alt="Humidity" /> */}
                                  Humidity settings (%)
                                </Typography>
                              </Grid>
                              <Grid item lg={8} md={8} sm={6} xs={4} sx={{ margin: "auto" }}>
                                <Divider />
                              </Grid>
                            </Grid>

                            <HumiditySettings />

                          </div>
                        </Grid>
                        <Grid item xs={12}>
                          <div className={classes.boxDiv}>

                            <Grid container>
                              <Grid item lg={4} md={4} sm={6} xs={8}>
                                <Typography variant="h6" className={classes.title}>
                                  {/* <img src="../img/humidity.svg" alt="Humidity" /> */}
                                  {/* <BatteryCharging20Icon /> */}
                                  Battery settings (%)
                                </Typography>
                              </Grid>
                              <Grid item lg={8} md={8} sm={6} xs={4} sx={{ margin: "auto" }}>
                                <Divider />
                              </Grid>
                            </Grid>

                            <Grid container spacing={2} style={{ marginTop: "1rem" }}>
                              <Grid
                                item
                                lg={2}
                                md={2}
                                sm={3}
                                xs={12}
                                style={{
                                  //  padding: '1.5rem',
                                  // tex tAlign:'center',

                                  margin: "auto",

                                  fontSize: "14px",
                                  fontFamily: "Montserrat",
                                }}
                              >
                                Battery Violation
                              </Grid>
                              <Grid item lg={6} md={6} sm={6} xs={12}>
                                <InputForChamber
                                  id="critical"
                                  // placeholder=" Battery Violation"
                                  placeholder="0"
                                  className={classes.textField}
                                  margin="normal"
                                  type="number"
                                  autoComplete="off"

                                />
                              </Grid>
                              <Grid item lg={4} md={4} sm={3} xs={0} />
                            </Grid>

                            <Grid container spacing={2}>
                              <Grid
                                item
                                lg={2}
                                md={2}
                                sm={3}
                                xs={12}
                                style={{
                                  //  padding: '1.5rem',
                                  // textAlign:'center',

                                  margin: "auto",

                                  fontSize: "14px",
                                  fontFamily: "Montserrat",
                                }}
                              >
                                Battery Warning
                              </Grid>
                              <Grid item lg={6} md={6} sm={6} xs={12}>
                                <InputForChamber
                                  id="warning"
                                  // placeholder="Battery Warning"
                                  placeholder="0"
                                  className={classes.textField}
                                  margin="normal"
                                  type="number"
                                  autoComplete="off"
                                  // inputProps={{min : minBatterVio? parseInt(minBatterVio) + 1 : null}}
                                  // onChange={this.minBatteryWarning}
                                />
                              </Grid>
                              <Grid item lg={4} md={4} sm={3} xs={0} />

                            </Grid>

                          </div>
                        </Grid>
                        <Grid item xs={12}>
                          <div className={classes.boxDiv}>

                            <Grid container>
                              <Grid item lg={3} md={3} sm={5} xs={8}>
                                <Typography variant="h6" className={classes.title}>
                                  Alert settings
                                </Typography>
                              </Grid>
                              <Grid item lg={9} md={9} sm={7} xs={4} sx={{ margin: "auto" }}>
                                <Divider />
                              </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                              <Grid item xs={12}>

                                <ToleranceSetting
                                  immediateAlert = {immediateAlert}
                                  toleranceValueinSeconds={toleranceValueinSeconds}
                                  handledefaultTolerance={this.handledefaultTolerance}
                                  handleImmediateAlertChange={this.handleImmediateAlertChange}
                                />
                              </Grid>
                            </Grid>

                          </div>
                        </Grid>
                        <Grid item xs={12}>
                          <div className={classes.boxDiv}>
                            <Grid container>
                              <Grid item lg={3} md={3} sm={4} xs={8}>
                                <Typography variant="h6" className={classes.title}>
                                  {/* <img src="../img/device.svg" alt="Device" /> */}
                                  Device settings
                                </Typography>
                              </Grid>
                              <Grid lg={9} md={9} sm={8} xs={4} sx={{ margin: "auto" }}>
                                <Divider />
                              </Grid>
                            </Grid>

                            {devicesAdded && devicesAdded.length >= 1 ? (
                              <div style={{ overflow: "auto" }}>
                                <Table size="small">
                                  <List
                                    sx={{
                                      width: "100%",
                                      maxWidth: "100%",
                                      position: "relative",
                                      overflow: "auto",
                                      maxHeight: 300,
                                      backgroundColor: "#f2f2f2",
                                    }}
                                    component="nav"
                                    aria-labelledby="nested-list-subheader"
                                    subheader={(
                                      <ListSubheader style={{ backgroundColor: "#f2f2f2" }} component="div" id="nested-list-subheader">
                                        List of Device(s)
                                      </ListSubheader>
                                       )}
                                    className={classes.root}
                                  />
                                </Table>

                                {/* addd */}
                                {devicesAdded
                                      && devicesAdded.length >= 1
                                      && devicesAdded.map((item, i) => (
                                        <div key={item.imei} className={classes.devicelistsetting}>
                                          <div style={{ width: "100%" }}>
                                            <ListItem
                                              key={item.label}
                                            >
                                              <ListItemText primary={item.label} />

                                              {/* {opened === item.label ? (<><ExpandLess /></>) : (
                                                  <>
                                                    <span style={{ color: '#008081' }}>Notification settings</span>
                                                    <ExpandMore />
                                                  </>
                                                )} */}
                                            </ListItem>
                                            <Divider />
                                            {/* <Collapse key={item} in={(opened === item.label)} timeout="auto" unmountOnExit>
                                                <List

                                                  component="div"
                                                  disablePadding
                                                >

                                                  <ListItem>
                                                    <ListItemText primary="Number of SMS per day :"> </ListItemText>
                                                    <TextField
                                                     variant='standard'
                                                      style={{ width: '5rem' }}
                                                      defaultValue={item.smsCount
                                                        ? item.smsCount
                                                        : '20'}
                                                      inputProps={{ min: 0, style: { textAlign: 'center' } }}

                                                      onChange={(e) => {
                                                        this.handleSMSSettinginChamber({
                                                          uid: item.value,
                                                          changeType: 'smsCount',
                                                          value: e.target.value,
                                                        });
                                                      }}
                                                      id="standard-number"

                                                      type="number"
                                                      InputLabelProps={{
                                                        shrink: true,
                                                      }}
                                                    />

                                                  </ListItem>
                                                  <ListItem>
                                                    <ListItemText primary="Interval between SMS : "> </ListItemText>

                                                    <FormControl>
                                                      <InputLabel id="demo-simple-select-placeholder-label-label" />
                                                      <Select
                                                       variant='standard'
                                                        labelId="demo-simple-select-placeholder-label-label"
                                                        id="demo-simple-select-placeholder-label"
                                                        MenuProps={MenuProps}
                                                        style={{ width: '7rem' }}
                                                      // value={item.msgduration}
                                                      // value={minDuration}
                                                        value={item.smsMinDuration
                                                          ? item.smsMinDuration
                                                          : '60'}

                                                        onChange={(e) => {
                                                          this.handleSMSSettinginChamber({
                                                            uid: item.value,
                                                            changeType: 'smsMinDuration',
                                                            value: e.target.value,
                                                          });
                                                        }}
                                                      >

                                                        {durationOptioninSMS.map((info) => (
                                                          <MenuItem key={info.value} value={info.value}>{info.hours}</MenuItem>
                                                        ))}
                                                      </Select>
                                                    </FormControl>

                                                  </ListItem>

                                                </List>
                                                {((item.smsCount > 20)
                                                  ? (
                                                    <>
                                                      <div sx={{ width: '100%', marginTop: '1rem' }} spacing={2}>
                                                        <Alert style={{ width: '100%' }} severity="info">
                                                          {' '}
                                                      Selected settings comes under premium services.
                                                       First 20 SMS are free, beyond which INR 0.25 will be charged per SMS.
                                                        </Alert>
                                                      </div>
                                                    </>
                                                  ) : ' ')}
                                              </Collapse> */}
                                          </div>
                                          <div style={{ marginTop: "1vh" }}>
                                            <Delete
                                              className={classes.hoverClass}
                                              onClick={() => this.handleRemoval(item.label, item.value, "devices")}
                                            />
                                          </div>
                                        </div>
                                      ))}

                              </div>
                            ) : null}

                            <ReactSelect
                              styles={selectStyles}
                              value={selectedOption}
                              onChange={this.handleChangeForDevices}
                              // onChange={this.handletempChangeforDevices}
                              options={devices}
                              placeholder="Select device to add"
                              className={classes.inputTextField}
                            />
                            {/* <div style={{ marginTop: '1rem' }}>
                              <Grid sx={{ width: '100%', marginTop: '1rem' }} spacing={2}>

                                <Alert severity="info">
The daily SMS limit set for a device is cumulative for all users.
                                In case no events occur in the certain given interval , SMS alert will be paused till next event occurs.

                                </Alert>

                              </Grid>
                            </div> */}
                          </div>
                        </Grid>
                        <Grid item xs={12}>
                          <div className={classes.boxDiv}>
                            <Grid container>
                              <Grid item lg={3} md={3} sm={4} xs={8}>
                                <Typography variant="h6" className={classes.title}>
                                  {/* <img src="../img/client.svg" alt="client" /> */}
                                  Client settings
                                </Typography>
                              </Grid>
                              <Grid item lg={9} md={9} sm={8} xs={4} sx={{ margin: "auto" }}>
                                <Divider />
                              </Grid>
                            </Grid>

                            {clientSharing && clientSharing.length >= 1 ? (
                              <div style={{ overflow: "auto" }}>
                                <Table size="small">

                                  <List
                                    sx={{
                                      width: "100%",
                                      maxWidth: "100%",
                                      position: "relative",
                                      overflow: "auto",
                                      maxHeight: 300,
                                      backgroundColor: "#f2f2f2",

                                    }}
                                    component="nav"
                                    aria-labelledby="nested-list-subheader"
                                    subheader={(
                                      <ListSubheader style={{ backgroundColor: "#f2f2f2", zIndex: 0 }} component="div" id="nested-list-subheader">
                                        List of Client(s)
                                      </ListSubheader>
                                       )}
                                    className={classes.root}
                                  >
                                    {clientSharing
                  && clientSharing.length >= 1
                  && clientSharing.map((item) => (

                    <div key={item.uid}>
                      <div className={classes.outerdivforClient}>
                        <div style={{ width: "100%" }}>
                          <ListItem
                            key={item}
                            button
                            onClick={(e) => { e.stopPropagation(); this.handleUsersinClients(item.label, item.value); }}
                          >

                            <ListItemIcon>
                              <PeopleIcon />
                            </ListItemIcon>
                            <ListItemText primary={item.label} />

                            {setUsersListinClients === item.value ? (<ExpandLess />) : (
                              <>
                                <span style={{ color: "#008081" }}>
                                  { changeNotiIcon
                                    ? (
                                      <Tooltip title="Notification Settings">
                                          <NotificationsActiveIcon />
                                        </Tooltip>
                                    )
                                    : "Notification settings"}

                                </span>
                                <ExpandMore />
                              </>
                            )}

                          </ListItem>
                        </div>
                        <div style={{ marginTop: "5px" }}>
                          <Delete
                            className={classes.hoverClass}
                            onClick={() => this.handleRemoval(item.label, item.value, "groups")}
                          />
                        </div>
                      </div>
                      <Divider />

                      <Collapse key={item.value} component="li" in={(setUsersListinClients === item.value)} timeout="auto" unmountOnExit>
                        <List disablePadding>

                          {(usersinClients && usersinClients.length >= 1)
                            ? usersinClients.map((user) => {
                              const labelId = `checkbox-list-secondary-label-${user.email}`;
                              const { email } = user;

                              return (

                                <ListItem key={user.email} button>

                                  <ListItemAvatar>
                                    <PersonIcon />
                                  </ListItemAvatar>
                                  <ListItemText
                                    key={user.emailId}
                                    id={labelId}
                                    primary={user.email}
                                    secondary={
                                     changeNotiIcon
                                       ? (
                                         <>
                                           <FormControlLabel
                                             control={(
                                               <Checkbox
                                                 color="primary"
                                                 value="SMS"
                                                 checked={(item.notificationSettingsByAdmin && item.notificationSettingsByAdmin[email]
                                               && item.notificationSettingsByAdmin[email].SMS) ? !!(item.notificationSettingsByAdmin[email].SMS)
                                                   : false}
                                                 onChange={(e) => {
                                                   this.handleCheckboxclick({
                                                     email,
                                                     notificationType: "SMS",
                                                     groupUid: item.value,
                                                     value: e.target.checked,
                                                   });
                                                 }}
                                                 style={{ color: "#008081" }}
                                               />
                                        )}
                                             label="SMS"
                                           />
                                           <FormControlLabel
                                             control={(
                                               <Checkbox
                                                 color="primary"
                                                 value="email"

                                                 checked={(item.notificationSettingsByAdmin && item.notificationSettingsByAdmin[email]
                                               && item.notificationSettingsByAdmin[email].email) ? !!(item.notificationSettingsByAdmin[email].email)
                                                   : false}
                                                 onChange={(e) => {
                                                   this.handleCheckboxclick({
                                                     email,
                                                     notificationType: "email",
                                                     groupUid: item.value,
                                                     value: e.target.checked,
                                                   });
                                                 }}
                                                 style={{ color: "#008081" }}
                                               />
                                        )}
                                             label="EMAIL"
                                           />

                                           {/* <FormControlLabel
                                             control={(
                                               <Checkbox
                                                 color="primary"
                                                 value="push"
                                                 checked={(item.notificationSettingsByAdmin && item.notificationSettingsByAdmin[email]
                                               && item.notificationSettingsByAdmin[email].push) ? !!(item.notificationSettingsByAdmin[email].push)
                                                   : false}
                                                 onChange={(e) => {
                                                   this.handleCheckboxclick({
                                                     email,
                                                     notificationType: "push",
                                                     groupUid: item.value,
                                                     value: e.target.checked,
                                                   });
                                                 }}
                                                 style={{ color: "#008081" }}
                                               />
                                        )}
                                             label="PUSH"
                                           /> */}

                                         </>
                                       )
                                       : " "
                                    }
                                  />
                                  { !changeNotiIcon
                                    ? (
                                      <ListItemSecondaryAction>
                                        <FormControlLabel
                                            control={(
                                              <Checkbox
                                                color="primary"
                                                value="SMS"
                                                checked={(item.notificationSettingsByAdmin && item.notificationSettingsByAdmin[email]
                                               && item.notificationSettingsByAdmin[email].SMS) ? !!(item.notificationSettingsByAdmin[email].SMS)
                                                  : false}
                                                onChange={(e) => {
                                                  this.handleCheckboxclick({
                                                    email,
                                                    notificationType: "SMS",
                                                    groupUid: item.value,
                                                    value: e.target.checked,
                                                  });
                                                }}
                                                style={{ color: "#008081" }}
                                              />
                                        )}
                                            label="SMS"
                                          />

                                        <FormControlLabel
                                            control={(
                                              <Checkbox
                                                color="primary"
                                                value="email"

                                                checked={(item.notificationSettingsByAdmin && item.notificationSettingsByAdmin[email]
                                               && item.notificationSettingsByAdmin[email].email) ? !!(item.notificationSettingsByAdmin[email].email)
                                                  : false}
                                                onChange={(e) => {
                                                  this.handleCheckboxclick({
                                                    email,
                                                    notificationType: "email",
                                                    groupUid: item.value,
                                                    value: e.target.checked,
                                                  });
                                                }}
                                                style={{ color: "#008081" }}
                                              />
                                        )}
                                            label="EMAIL"
                                          />

                                        {/* <FormControlLabel
                                            control={(
                                              <Checkbox
                                                color="primary"
                                                value="push"
                                                checked={(item.notificationSettingsByAdmin && item.notificationSettingsByAdmin[email]
                                               && item.notificationSettingsByAdmin[email].push) ? !!(item.notificationSettingsByAdmin[email].push)
                                                  : false}
                                                onChange={(e) => {
                                                  this.handleCheckboxclick({
                                                    email,
                                                    notificationType: "push",
                                                    groupUid: item.value,
                                                    value: e.target.checked,
                                                  });
                                                }}
                                                style={{ color: "#008081" }}
                                              />
                                        )}
                                            label="PUSH"
                                          /> */}
                                      </ListItemSecondaryAction>
                                    )
                                    : " "}

                                </ListItem>

                              );
                            })

                            : (
                              <ListItem button>
                                <ListItemText style={{ textAlign: "center" }} primary="No Users Found" />
                              </ListItem>
                            )}

                        </List>
                      </Collapse>

                    </div>
                  ))}

                                  </List>

                                </Table>
                              </div>
                            ) : null}
                            <ReactSelect
                              styles={selectStyles}
                              value={selectedOption}
                              onChange={this.handleChangeForClients}
                              options={clients}
                              placeholder="Select client to add"
                              className={classes.inputTextField}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12}>
                          <UserGroupList
                            onNotifictionSettingsChange={(a) => this.updateUserGroupNotification(a)}
                            onGroupSelectionChange={(z) => this.onGroupSelectionChange(z)}
                            userGroupList={groups}
                            userGroupsfromWarehouse={userGroupNames}
                            existingUserGroup={existingUserGroup}
                            onexistingUserGroupchange={(list) => this.onexistingUserGroupchange(list)}
                            updateExistingUserList={(updatedlist) => this.updateExistingUserList(updatedlist)}
                            isInWarehouses={isInWarehouses}
                            changeNotiIcon={changeNotiIcon}
                          />

                        </Grid>

                        <Grid item xs={12}>
                          <div
                            style={{ marginTop: "10px", paddingTop: "10px" }}
                          >
                            {errorMessage && errorMessage !== null ? (
                              <div className={classes.errorMessageDiv}>
                                <span className={classes.errorMessageText}>
                                  *
                                  {errorMessage}
                                </span>
                              </div>
                            ) : null}
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </DialogContent>
                  {!fullScreen && (
                    <DialogActions>
                      <SecondaryButton onClick={this.onAddDialogClose} text="Cancel" />

                      <PrimaryButton type="submit" text="Save" />

                    </DialogActions>
                  )}
                </form>
              </Grow>
            </Dialog>

            <Dialog
              open={openDeleteDialog}
              TransitionComponent={Zoom}
              onClose={this.onDeleteDialogClose}
              aria-labelledby="form-dialog-title"
              maxWidth="sm"
              scroll="body"
            >
              <DialogTitle id="form-dialog-title">
                <span className={classes.warning}>Warning</span>
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {/* Are you sure you want to delete the selected Chamber(s) ? */}
                  Are you sure you want to delete the selected
                  {" "}
                  {CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.chamberType
                    ? `${CompanyInfo.settings.chamberType}(s)` : "Chamber(s)" }
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <SecondaryButton text="No" onClick={this.onDeleteDialogClose} />

                <PrimaryButton text="Yes" onClick={this.onDeleteDialogSave} />

              </DialogActions>
            </Dialog>

            <Dialog
              fullScreen={fullScreen}
              open={openEditDialog}
              TransitionComponent={Zoom}
              onClose={this.onEditDialogClose}
              aria-labelledby="form-dialog-title"
              maxWidth="md"
              fullWidth
              scroll="paper"
              PaperProps={{
                style: {
                  backgroundColor: "#f2f2f2",
                },
              }}
              // disableBackdropClick
            >
              <Grow in={openEditDialog}>
                <form onSubmit={this.onEditDialogSave}>
                  {fullScreen && (
                    <AppBar className={classes.appBar}>
                      <Toolbar style={{ backgroundColor: "#008081" }}>
                        <IconButton
                          edge="start"
                          color="inherit"
                          onClick={this.onEditDialogClose}
                          aria-label="close"
                        >
                          <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                          {CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.chamberType
                            ? ` Edit & Configure ${CompanyInfo.settings.chamberType}`
                            : "  Edit & Configure Chamber"}

                        </Typography>
                        <Button color="inherit" type="submit">
                          update
                        </Button>
                      </Toolbar>
                    </AppBar>
                  )}
                  {!fullScreen && (
                    <DialogTitle
                      id="form-dialog-title"
                      className={classes.dialogtitle}
                    >
                        {CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.chamberType
                          ? ` Edit & Configure ${CompanyInfo.settings.chamberType}`
                          : "  Edit & Configure Chamber"}
                    </DialogTitle>
                  )}
                  <DialogContent>
                    <div>
                      <Grid container spacing={1} style={{ paddingTop: fullScreen ? "3rem" : "0rem" }}>
                        <Grid item xs={12}>
                          <div className={classes.boxDiv}>
                            <Grid container>
                              <Grid item lg={3} md={3} sm={5} xs={6}>
                                <Typography variant="h6" className={classes.title}>
                                  {/* <img
                                src="../img/chamber.svg"
                                alt="chamber"
                              /> */}
                                  {CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.chamberType
                                    ? `${CompanyInfo.settings.chamberType} Settings`
                                    : "Chamber Settings"}

                                </Typography>
                              </Grid>
                              <Grid item lg={9} md={9} sm={7} xs={6} sx={{ margin: "auto" }}>
                                <Divider />
                              </Grid>
                            </Grid>

                            <Grid container spacing={2} style={{ marginTop: "1rem" }}>
                              <Grid container>
                                <Grid
                                  item
                                  lg={2}
                                  md={2}
                                  sm={3}
                                  xs={12}
                                  style={{
                                    padding: !fullScreen ? "1.5rem" : "0rem",
                                    paddingLeft: !fullScreen ? "1rem" : "1rem",
                                    textAlign: "left",

                                    margin: "auto",

                                    fontSize: "14px",
                                    fontFamily: "Montserrat",
                                  }}
                                >
                                  {CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.chamberType
                                    ? `${CompanyInfo.settings.chamberType} ID`
                                    : "  Chamber ID"}

                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                  <InputForChamber
                                    id="chamberId"
                                    placeholder={CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.chamberType
                                      ? `${CompanyInfo.settings.chamberType} Id`
                                      : "  Chamber Id"}
                                    margin="normal"
                                    autoComplete="off"
                                    defaultValue={
                                      singleChamberData && singleChamberData[0] && singleChamberData[0].id
                                        ? singleChamberData[0].id
                                        : ""
                                    }
                                    className={classes.textField}
                                  />
                                </Grid>
                                <Grid item xs={4} />

                              </Grid>
                              <Grid container>
                                <Grid
                                  item
                                  lg={2}
                                  md={2}
                                  sm={3}
                                  xs={12}
                                  style={{
                                    padding: !fullScreen ? "1.5rem" : "0rem",
                                    paddingLeft: !fullScreen ? "1rem" : "1rem",
                                    textAlign: "left",

                                    margin: "auto",

                                    fontSize: "14px",
                                    fontFamily: "Montserrat",
                                  }}
                                >
                                  Commodity
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                  <InputForChamber
                                    id="commodity"
                                    autoComplete="off"
                                    placeholder="Commodity"
                                    defaultValue={
                                      singleChamberData && singleChamberData[0] && singleChamberData[0].note
                                        ? singleChamberData[0].note
                                        : ""
                                    }
                                    className={classes.textField}

                                    margin="normal"
                                  />
                                </Grid>
                                <Grid item xs={4} />

                              </Grid>
                            </Grid>

                          </div>
                        </Grid>
                        <Grid item xs={12}>
                          <div className={classes.boxDiv}>

                            <Grid container>
                              <Grid item lg={4} md={4} sm={6} xs={8}>
                                <Typography variant="h6" className={classes.title}>
                                  {/* <img
                                src="../img/temperature.svg"
                                alt="Temperature"
                              /> */}
                                  {(companySettings && companySettings.temperature && companySettings.temperature === "F") ? "Temperature settings (°F)" : "Temperature settings (°C)"}
                                </Typography>
                              </Grid>
                              <Grid item lg={8} md={8} sm={6} xs={4} sx={{ margin: "auto" }}>
                                <Divider />
                              </Grid>
                            </Grid>
                            <TemperatureSettings
                              defaultValueMinViolation={
                              singleChamberData && singleChamberData[0] && singleChamberData[0].temperatureSettings && singleChamberData[0].temperatureSettings.hasOwnProperty("minViolation")
                                ? this.getTemperatureInUnits(singleChamberData[0].temperatureSettings.minViolation, companySettings)
                                : ""
                            }
                              defaultValueMaxViolation={
                              singleChamberData && singleChamberData[0] && singleChamberData[0].temperatureSettings && singleChamberData[0].temperatureSettings.hasOwnProperty("maxViolation")
                                ? this.getTemperatureInUnits(singleChamberData[0].temperatureSettings.maxViolation, companySettings)
                                : ""
                            }
                              defaultValueMinWarning={
                              singleChamberData && singleChamberData[0] && singleChamberData[0].temperatureSettings && singleChamberData[0].temperatureSettings.hasOwnProperty("minWarning")
                                ? this.getTemperatureInUnits(singleChamberData[0].temperatureSettings.minWarning, companySettings)
                                : ""
                            }
                              defaultValueMaxWarning={
                              singleChamberData && singleChamberData[0] && singleChamberData[0].temperatureSettings && singleChamberData[0].temperatureSettings.hasOwnProperty("maxWarning")
                                ? this.getTemperatureInUnits(singleChamberData[0].temperatureSettings.maxWarning, companySettings)
                                : ""
                            }
                            />

                            {/* <TextField
                              autoComplete="off"
                              id="temperatureMinimum"
                              label="Minimum  Violation Temperature "
                              defaultValue={
                                singleChamberData && singleChamberData[0].temperatureSettings && singleChamberData[0].temperatureSettings.hasOwnProperty('minViolation')
                                  ? this.getTemperatureInUnits(singleChamberData[0].temperatureSettings.minViolation, companySettings)
                                  : ''
                              }
                              className={classes.textField}
                              margin="normal"
                            />
                            <TextField
                              id="temperatureMaximum"
                              autoComplete="off"
                              label="Maximum Violation Temperature "
                              defaultValue={
                                singleChamberData && singleChamberData[0].temperatureSettings && singleChamberData[0].temperatureSettings.hasOwnProperty('maxViolation')
                                  ? this.getTemperatureInUnits(singleChamberData[0].temperatureSettings.maxViolation, companySettings)
                                  : ''
                              }
                              className={classes.textField}
                              margin="normal"
                            />
                            <TextField
                              id="temperatureWarningMinimum"
                              autoComplete="off"
                              label="Minimum Warning Temperature"
                              defaultValue={
                                singleChamberData && singleChamberData[0].temperatureSettings && singleChamberData[0].temperatureSettings.hasOwnProperty('minWarning')
                                  ? this.getTemperatureInUnits(singleChamberData[0].temperatureSettings.minWarning, companySettings)
                                  : ''
                              }
                              className={classes.textField}
                              margin="normal"
                            />
                            <TextField
                              id="temperatureWarningMaximum"
                              autoComplete="off"
                              label="Maximum Warning Temperature"
                              defaultValue={
                                singleChamberData && singleChamberData[0].temperatureSettings && singleChamberData[0].temperatureSettings.hasOwnProperty('maxWarning')
                                  ? this.getTemperatureInUnits(singleChamberData[0].temperatureSettings.maxWarning, companySettings)
                                  : ''
                              }
                              className={classes.textField}
                              margin="normal"
                            /> */}
                          </div>
                        </Grid>
                        <Grid item xs={12}>
                          <div className={classes.boxDiv}>
                            <Grid container>
                              <Grid item lg={4} md={4} sm={6} xs={8}>
                                <Typography variant="h6" className={classes.title}>
                                  {/* <img src="../img/humidity.svg" alt="Humidity" /> */}
                                  Humidity settings (%)
                                </Typography>
                              </Grid>
                              <Grid item lg={8} md={8} sm={6} xs={4} sx={{ margin: "auto" }}>
                                <Divider />
                              </Grid>
                            </Grid>

                            <HumiditySettings
                              defaultValueMinViolation={
                              singleChamberData && singleChamberData[0] && singleChamberData[0].humiditySettings && singleChamberData[0].humiditySettings.hasOwnProperty("minViolation")
                                ? singleChamberData[0].humiditySettings.minViolation
                                : ""
                            }
                              defaultValueMaxViolation={
                              singleChamberData && singleChamberData[0] && singleChamberData[0].humiditySettings && singleChamberData[0].humiditySettings.hasOwnProperty("maxViolation")
                                ? singleChamberData[0].humiditySettings.maxViolation
                                : ""
                            }
                              defaultValueMinWarning={
                              singleChamberData && singleChamberData[0] && singleChamberData[0].humiditySettings && singleChamberData[0].humiditySettings.hasOwnProperty("minWarning")
                                ? singleChamberData[0].humiditySettings.minWarning
                                : ""
                            }
                              defaultValueMaxWarning={
                              singleChamberData && singleChamberData[0] && singleChamberData[0].humiditySettings && singleChamberData[0].humiditySettings.hasOwnProperty("maxWarning")
                                ? singleChamberData[0].humiditySettings.maxWarning
                                : ""
                            }
                            />

                            {/* <TextField
                              id="humidityMinimum"
                              autoComplete="off"
                              label="Minimum Violation Humidity "
                              defaultValue={
                                singleChamberData && singleChamberData[0].humiditySettings && singleChamberData[0].humiditySettings.hasOwnProperty('minViolation')
                                  ? singleChamberData[0].humiditySettings.minViolation
                                  : ''
                              }
                              className={classes.textField}
                              margin="normal"
                            />
                            <TextField
                              id="humidityMaximum"
                              autoComplete="off"
                              label="Maximum  Violation Humidity"
                              defaultValue={
                                singleChamberData && singleChamberData[0].humiditySettings && singleChamberData[0].humiditySettings.hasOwnProperty('maxViolation')
                                  ? singleChamberData[0].humiditySettings.maxViolation
                                  : ''
                              }
                              className={classes.textField}
                              margin="normal"
                            />
                            <TextField
                              id="humidityWarningMinimum"
                              autoComplete="off"
                              label="Minimum Warning Humidity"
                              defaultValue={
                                singleChamberData && singleChamberData[0].humiditySettings && singleChamberData[0].humiditySettings.hasOwnProperty('minWarning')
                                  ? singleChamberData[0].humiditySettings.minWarning
                                  : ''
                              }
                              className={classes.textField}
                              margin="normal"
                            />
                            <TextField
                              id="humidityWarningMaximum"
                              autoComplete="off"
                              label="Maximum Warning Humidity"
                              defaultValue={
                                singleChamberData && singleChamberData[0].humiditySettings && singleChamberData[0].humiditySettings.hasOwnProperty('maxWarning')
                                  ? singleChamberData[0].humiditySettings.maxWarning
                                  : ''
                              }
                              className={classes.textField}
                              margin="normal"
                            /> */}
                          </div>
                        </Grid>
                        <Grid item xs={12}>
                          <div className={classes.boxDiv}>
                            <Grid container>
                              <Grid item lg={4} md={4} sm={6} xs={8}>
                                <Typography variant="h6" className={classes.title}>
                                  {/* <img src="../img/humidity.svg" alt="Humidity" /> */}
                                  {/* <BatteryCharging20Icon /> */}
                                  Battery settings (%)
                                </Typography>
                              </Grid>
                              <Grid item lg={8} md={8} sm={6} xs={4} sx={{ margin: "auto" }}>
                                <Divider />
                              </Grid>
                            </Grid>

                            <Grid container spacing={2} style={{ marginTop: "1rem" }}>
                              <Grid
                                item
                                lg={2}
                                md={2}
                                sm={3}
                                xs={12}
                                style={{

                                  margin: "auto",

                                  fontSize: "14px",
                                  fontFamily: "Montserrat",
                                }}
                              >
                                Battery Violation
                              </Grid>
                              <Grid item lg={6} md={6} sm={6} xs={12}>
                                <InputForChamber
                                  id="critical"
                                  placeholder="0"
                                  className={classes.textField}
                                  margin="normal"
                                  type="number"
                                  autoComplete="off"

                                  defaultValue={
                                singleChamberData && singleChamberData[0] && singleChamberData[0].batterySettings && singleChamberData[0].batterySettings.hasOwnProperty("critical")
                                  ? singleChamberData[0].batterySettings.critical
                                  : ""
                              }
                                />
                              </Grid>
                              <Grid item xs={4} />
                            </Grid>

                            <Grid container spacing={2}>
                              <Grid
                                item
                                lg={2}
                                md={2}
                                sm={3}
                                xs={12}
                                style={{

                                  margin: "auto",

                                  fontSize: "14px",
                                  fontFamily: "Montserrat",
                                }}
                              >
                                Battery Warning
                              </Grid>
                              <Grid item lg={6} md={6} sm={6} xs={12}>
                                <InputForChamber
                                  id="warning"
                                  placeholder="0"
                                  className={classes.textField}
                                  margin="normal"
                                  type="number"
                                  autoComplete="off"

                                  defaultValue={
                                singleChamberData && singleChamberData[0] && singleChamberData[0].batterySettings && singleChamberData[0].batterySettings.hasOwnProperty("warning")
                                  ? singleChamberData[0].batterySettings.warning
                                  : ""
                              }
                                />
                              </Grid>
                              <Grid item xs={4} />
                            </Grid>

                          </div>
                        </Grid>
                        <Grid item xs={12}>
                          <div className={classes.boxDiv}>

                            <Grid container>
                              <Grid item lg={3} md={3} sm={5} xs={8}>
                                <Typography variant="h6" className={classes.title}>

                                  Tolerance settings
                                </Typography>
                              </Grid>
                              <Grid item lg={9} md={9} sm={7} xs={4} sx={{ margin: "auto" }}>
                                <Divider />
                              </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <ToleranceSetting
                              // toleranceCheckboxValue={singleChamberData && singleChamberData[0] && singleChamberData[0].isTolerance === true ? "custom" :'instant' }
                                  immediateAlert= {(singleChamberData && singleChamberData[0] && singleChamberData[0].immediateAlert) || 0}
                                  toleranceValueinSeconds={(singleChamberData && singleChamberData[0] && singleChamberData[0].toleranceTime) || 0}
                                // handleswitchOptionChange={this.handleswitchOptionChange}
                                  handledefaultTolerance={this.handledefaultTolerance}
                                  handleImmediateAlertChange={this.handleImmediateAlertChange}
                                />
                              </Grid>
                            </Grid>

                          </div>
                        </Grid>
                        <Grid item xs={12}>
                          <div className={classes.boxDiv}>
                            <Grid container>
                              <Grid item lg={3} md={3} sm={4} xs={8}>
                                <Typography variant="h6" className={classes.title}>
                                  {/* <img src="../img/device.svg" alt="Device" /> */}
                                  Device settings
                                </Typography>
                              </Grid>
                              <Grid item lg={9} md={9} sm={8} xs={4} sx={{ margin: "auto" }}>
                                <Divider />
                              </Grid>
                            </Grid>
                            {devicesAdded && devicesAdded.length >= 1 ? (
                              <div
                              // style={{ overflow: 'auto' }}
                                style={{ width: "100%" }}
                              >

                                <Table size="small">
                                  <List
                                    sx={{
                                      width: "100%",
                                      maxWidth: "100%",
                                      position: "relative",
                                      overflow: "auto",
                                      maxHeight: 300,
                                      backgroundColor: "#f2f2f2",
                                    }}
                                    component="nav"
                                    aria-labelledby="nested-list-subheader"
                                    subheader={(
                                      <ListSubheader style={{ backgroundColor: "#f2f2f2", zIndex: 0 }} component="div" id="nested-list-subheader">
                                        List of  Device(s)
                                      </ListSubheader>
                                       )}
                                    className={classes.root}
                                  />

                                </Table>

                                {devicesAdded
                  && devicesAdded.length >= 1
                  && devicesAdded.map((item, i) => (

                    <div key={item.imei} className={classes.devicelistsetting}>
                      <div style={{ width: "100%" }}>
                        <ListItem
                          key={item}
                        >
                          <ListItemText primary={item.label} />

                          {/* {opened === item.label ? (<ExpandLess />) : (
                              <>
                                <span style={{ color: '#008081' }}>Notification settings</span>
                                <ExpandMore />
                              </>
                            )} */}
                        </ListItem>
                        <Divider />
                        {/* <Collapse key={item} in={(opened === item.label)} timeout="auto" unmountOnExit>
                            <List

                              component="div"
                              disablePadding
                            >

                              <ListItem>
                                <ListItemText primary="Number of SMS per day :"> </ListItemText>
                                <TextField
                                variant='standard'
                                  style={{ width: '5rem' }}
                                  defaultValue={item.smsCount
                                    ? item.smsCount
                                    : '20'}
                                  inputProps={{ min: 0, style: { textAlign: 'center' } }}

                                  onChange={(e) => {
                                    this.handleSMSSettinginChamber({
                                      uid: item.value,
                                      changeType: 'smsCount',
                                      value: e.target.value,
                                    });
                                  }}
                                  id="standard-number"

                                  type="number"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />

                              </ListItem>
                              <ListItem>
                                <ListItemText primary="Interval between SMS : "> </ListItemText>

                                <FormControl>
                                  <InputLabel id="demo-simple-select-placeholder-label-label" />
                                  <Select
                                    variant='standard'
                                    labelId="demo-simple-select-placeholder-label-label"
                                    id="demo-simple-select-placeholder-label"
                                    MenuProps={MenuProps}
                                    style={{ width: '7rem' }}

                                    value={item.smsMinDuration
                                      ? item.smsMinDuration
                                      : '60'}

                                    onChange={(e) => {
                                      this.handleSMSSettinginChamber({
                                        uid: item.value,
                                        changeType: 'smsMinDuration',
                                        value: e.target.value,
                                      });
                                    }}
                                  >

                                    {durationOptioninSMS.map((info) => (
                                      <MenuItem key={info.value} value={info.value}>{info.hours}</MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>

                              </ListItem>
                            </List>

                            {((item.smsCount > 20)
                              ? (
                                <>
                                  <div sx={{ width: '100%', marginTop: '1rem' }} spacing={2}>
                                    <Alert style={{ width: '100%' }} severity="info">
                                      {' '}
                                                      Selected settings comes under premium services.
                                                       First 20 SMS are free, more will be charged at INR 0.25 per SMS.
                                    </Alert>
                                  </div>
                                </>
                              ) : ' ')}

                          </Collapse> */}
                      </div>
                      <div style={{ marginTop: "1vh" }}>
                        <Delete
                          className={classes.hoverClass}
                          onClick={() => this.handleRemoval(item.label, item.value, "devices")}
                        />
                      </div>
                    </div>
                  ))}

                              </div>
                            ) : null}
                            <ReactSelect
                              styles={selectStyles}
                              value={selectedOption}
                              onChange={this.handleChangeForDevices}

                              options={devices}
                              placeholder="Select device to add"
                              className={classes.inputTextField}
                            />
                            {/* <div style={{ marginTop: '1rem' }}>
                              <Grid sx={{ width: '100%', marginTop: '1rem' }} spacing={2}>

                                <Alert severity="info">
                                The daily SMS limit set for a device is cumulative for all users.
                                In case no events occur in the certain given interval , SMS alert will be paused till next event occurs.
                                </Alert>

                              </Grid>
                            </div> */}
                          </div>
                        </Grid>
                        <Grid item xs={12}>
                          <div className={classes.boxDiv}>
                            <Grid container>
                              <Grid item lg={3} md={3} sm={4} xs={8}>
                                <Typography variant="h6" className={classes.title}>
                                  {/* <img src="../img/client.svg" alt="client" /> */}
                                  Client settings
                                </Typography>
                              </Grid>
                              <Grid item lg={9} md={9} sm={8} xs={4} sx={{ margin: "auto" }}>
                                <Divider />
                              </Grid>
                            </Grid>
                            {clientSharing && clientSharing.length >= 1 ? (
                              <div style={{ overflow: "auto" }}>
                                <Table size="small">
                                  <List
                                    sx={{
                                      width: "100%",
                                      maxWidth: "100%",
                                      position: "relative",
                                      overflow: "auto",
                                      maxHeight: 300,
                                      backgroundColor: "#f2f2f2",
                                    }}
                                    component="nav"
                                    aria-labelledby="nested-list-subheader"
                                    subheader={(
                                      <ListSubheader style={{ backgroundColor: "#f2f2f2", zIndex: 0 }} component="div" id="nested-list-subheader">
                                        List of Client(s)
                                      </ListSubheader>
                                       )}
                                    className={classes.root}
                                  >
                                    {clientSharing
                  && clientSharing.length >= 1
                  && clientSharing.map((item) => (

                    <div key={item.uid}>
                      <div className={classes.outerdivforClient}>
                        <div style={{ width: "100%" }}>
                          <ListItem
                            key={item}
                            button
                            onClick={(e) => { e.stopPropagation(); this.handleUsersinClients(item.groupName, item.groupUid); }}
                          >

                            <ListItemIcon>
                              <PeopleIcon />
                            </ListItemIcon>
                            <ListItemText primary={item.groupName} />

                            {setUsersListinClients === item.groupUid ? (<ExpandLess />) : (
                              <>
                                <span style={{ color: "#008081" }}>
                                  {
                                      changeNotiIcon
                                        ? <NotificationsActiveIcon />
                                        : "Notification settings"
                                    }
                                </span>
                                <ExpandMore />
                              </>
                            )}

                          </ListItem>
                        </div>
                        <div style={{ marginTop: "5px" }}>
                          <Delete
                            className={classes.hoverClass}
                            onClick={() => this.handleRemoval(item.groupName, item.groupUid, "groups")}
                          />
                        </div>
                      </div>
                      <Divider />

                      <Collapse key={item.groupUid} component="li" in={(setUsersListinClients === item.groupUid)} timeout="auto" unmountOnExit>
                        <List disablePadding>
                          {(usersinClients && usersinClients.length >= 1)
                            ? usersinClients.map((user) => {
                              const labelId = `checkbox-list-secondary-label-${user.email}`;
                              const { email } = user;

                              return (

                                <ListItem key={user.email} button>

                                  <ListItemAvatar>
                                    <PersonIcon />
                                  </ListItemAvatar>
                                  <ListItemText
                                    key={user.emailId}
                                    id={labelId}
                                    primary={user.email}

                                    secondary={
                                      changeNotiIcon
                                        ? (
                                          <>
                                            <FormControlLabel
                                              control={(
                                                <Checkbox
                                                  color="primary"
                                                  value="SMS"
                                                  checked={(item.notificationSettingsByAdmin
                                              && item.notificationSettingsByAdmin[email] && item.notificationSettingsByAdmin[email].SMS)
                                                    ? !!(item.notificationSettingsByAdmin[email].SMS)
                                                    : false}
                                                  onChange={(e) => {
                                                    this.handleCheckboxclick({
                                                      email,
                                                      notificationType: "SMS",
                                                      groupUid: item.groupUid,
                                                      value: e.target.checked,
                                                    });
                                                  }}
                                                  style={{ color: "#008081" }}
                                                />
                                        )}
                                              label="SMS"
                                            />

                                            <FormControlLabel
                                              control={(
                                                <Checkbox
                                                  color="primary"
                                                  value="email"
                                                  checked={(item.notificationSettingsByAdmin
                                            && item.notificationSettingsByAdmin[email] && item.notificationSettingsByAdmin[email].email)
                                                    ? !!(item.notificationSettingsByAdmin[email].email) : false}

                                                  onChange={(e) => {
                                                    this.handleCheckboxclick({
                                                      email,
                                                      notificationType: "email",
                                                      groupUid: item.groupUid,
                                                      value: e.target.checked,
                                                    });
                                                  }}
                                                  style={{ color: "#008081" }}
                                                />
                                        )}
                                              label="EMAIL"
                                            />

                                            {/* <FormControlLabel
                                              control={(
                                                <Checkbox
                                                  color="primary"
                                                  value="push"
                                                  checked={(item.notificationSettingsByAdmin
                                              && item.notificationSettingsByAdmin[email] && item.notificationSettingsByAdmin[email].push)
                                                    ? !!(item.notificationSettingsByAdmin[email].push)
                                                    : false}
                                                  onChange={(e) => {
                                                    this.handleCheckboxclick({
                                                      email,
                                                      notificationType: "push",
                                                      groupUid: item.groupUid,
                                                      value: e.target.checked,
                                                    });
                                                  }}
                                                  style={{ color: "#008081" }}
                                                />
                                        )}
                                              label="PUSH"
                                            /> */}
                                          </>
                                        )
                                        : ""
                                    }
                                  />
                                  {
                                    !changeNotiIcon
                                      ? (
                                        <ListItemSecondaryAction>
                                          <FormControlLabel
                                            control={(
                                              <Checkbox
                                                color="primary"
                                                value="SMS"
                                                checked={(item.notificationSettingsByAdmin
                                              && item.notificationSettingsByAdmin[email] && item.notificationSettingsByAdmin[email].SMS)
                                                  ? !!(item.notificationSettingsByAdmin[email].SMS)
                                                  : false}
                                                onChange={(e) => {
                                                  this.handleCheckboxclick({
                                                    email,
                                                    notificationType: "SMS",
                                                    groupUid: item.groupUid,
                                                    value: e.target.checked,
                                                  });
                                                }}
                                                style={{ color: "#008081" }}
                                              />
                                        )}
                                            label="SMS"
                                          />

                                          <FormControlLabel
                                            control={(
                                              <Checkbox
                                                color="primary"
                                                value="email"
                                                checked={(item.notificationSettingsByAdmin
                                            && item.notificationSettingsByAdmin[email] && item.notificationSettingsByAdmin[email].email)
                                                  ? !!(item.notificationSettingsByAdmin[email].email) : false}

                                                onChange={(e) => {
                                                  this.handleCheckboxclick({
                                                    email,
                                                    notificationType: "email",
                                                    groupUid: item.groupUid,
                                                    value: e.target.checked,
                                                  });
                                                }}
                                                style={{ color: "#008081" }}
                                              />
                                        )}
                                            label="EMAIL"
                                          />

                                          {/* <FormControlLabel
                                            control={(
                                              <Checkbox
                                                color="primary"
                                                value="push"
                                                checked={(item.notificationSettingsByAdmin
                                              && item.notificationSettingsByAdmin[email] && item.notificationSettingsByAdmin[email].push)
                                                  ? !!(item.notificationSettingsByAdmin[email].push)
                                                  : false}
                                                onChange={(e) => {
                                                  this.handleCheckboxclick({
                                                    email,
                                                    notificationType: "push",
                                                    groupUid: item.groupUid,
                                                    value: e.target.checked,
                                                  });
                                                }}
                                                style={{ color: "#008081" }}
                                              />
                                        )}
                                            label="PUSH"
                                          /> */}
                                        </ListItemSecondaryAction>
                                      )
                                      : ""
}

                                </ListItem>

                              );
                            })

                            : (
                              <ListItem button>
                                <ListItemText style={{ textAlign: "center" }} primary="No Users Found" />
                              </ListItem>
                            )}

                        </List>

                      </Collapse>

                    </div>
                  ))}

                                  </List>

                                </Table>
                              </div>
                            ) : null}
                            <ReactSelect
                              styles={selectStyles}
                              value={selectedOption}
                              // onChange={this.handleChangeForClients()}
                              onChange={(e) => this.handleChangeForClients(e)}
                              options={clients}
                              placeholder="Select client to add"
                              className={classes.inputTextField}
                            />

                          </div>
                        </Grid>
                        <Grid item xs={12}>
                          <UserGroupList
                            onNotifictionSettingsChange={(a) => this.updateUserGroupNotification(a)}
                            onGroupSelectionChange={(z) => this.onGroupSelectionChange(z)}
                            userGroupList={groups}
                            userGroupsfromWarehouse={userGroupNames}
                            existingUserGroup={existingUserGroup}
                            onexistingUserGroupchange={(list) => this.onexistingUserGroupchange(list)}
                            updateExistingUserList={(updatedlist) => this.updateExistingUserList(updatedlist)}
                            isInWarehouses={isInWarehouses}
                            changeNotiIcon={changeNotiIcon}
                          />

                        </Grid>
                        <Grid item xs={12}>
                          <div
                            style={{ marginTop: "10px", paddingTop: "10px" }}
                          >
                            {errorMessage && errorMessage !== null ? (
                              <div className={classes.errorMessageDiv}>
                                <span className={classes.errorMessageText}>
                                  *
                                  {errorMessage}
                                </span>
                              </div>
                            ) : null}
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </DialogContent>
                  {!fullScreen && (
                    <DialogActions>
                      <SecondaryButton text="Cancel" onClick={this.onEditDialogClose} />

                      <PrimaryButton text="Update" type="submit" className={classes.saveButton} variant="contained" />

                    </DialogActions>
                  )}
                </form>
              </Grow>
            </Dialog>

          </div>

        ) }

      </div>
    );
  }
}

Warehouses.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(Warehouses);

{ /* <Grow in={isInWarehouses}>

<Grid container spacing={0} justifyContent="center">
  <Grid item md={12} sm={12} xs>
    <div className={classes.topDiv}>
      <div className={classes.deletebuttondiv}>
        <Tooltip  title="Delete"  placement="top-end">
        <Button
          disabled={!(userPermissions && userPermissions.assetGroups && userPermissions.assetGroups.delete && selectedRowData && selectedRowData.length >= 1)}
          // className={(userPermissions && userPermissions.assetGroups.delete && selectedRowData && selectedRowData.length === 1) ? classes.deleteDiv : classes.disabledButton}
          onClick={(selectedRowData && selectedRowData.length >= 1) ? this.onDeleteButtonClick : null}
          onKeyPress={(selectedRowData && selectedRowData.length >= 1) ? this.onDeleteButtonClick : null}
          color="error"
          tabIndex="0"
        >

          <Delete />

        </Button>
        </Tooltip>
      </div>
      <div className={classes.editbuttondiv}>
      <Tooltip  title="Edit"  placement="top-end">
        <Button
          disabled={!(userPermissions && userPermissions.assetGroups && userPermissions.assetGroups.update && userPermissions.groups && userPermissions.groups.read && selectedRowData && selectedRowData.length >= 1)}

          onClick={(selectedRowData && selectedRowData.length >= 1) ? this.onEditButtonClick : null}
          onKeyPress={(selectedRowData && selectedRowData.length >= 1) ? this.onEditButtonClick : null}

        >
          <Edit
           className={(userPermissions && userPermissions.assetGroups.update && selectedRowData && selectedRowData.length >= 1) ? classes.editDiv : classes.disabledButton}
            />

        </Button>
        </Tooltip>
      </div>
        <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                flexGrow: 1,
              }}
            >
              <Tooltip title="use 'shift' or 'ctrl' for multi-select">

                <div>
                  <span>Selected : </span>
                  <span className={classes.Selected}>
                    {selectedRowData && selectedRowData.length}
                  </span>
                </div>

              </Tooltip>

              <Tooltip title="Clear Selection">
                <IconButton
                  onClick={this.handleSelectionClear}

                >
                  <HighlightOffIcon
                   className={
                    selectedRowData && selectedRowData.length > 0
                      ? classes.clearSelectionActive
                      : classes.clearSelectionDisabled
                  }
                  />
                </IconButton>
              </Tooltip>
            </div>
            <Grid item xs={12}>
            <SearchOption
            variant="standard"
              id="userSearchInput"
              value={searchInput}
              onChange={this.onSearchChange}
              placeholder= {CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.warehouseType ? "Search by " + CompanyInfo.settings.warehouseType + " Id" : "Search by warehouse Id"}
            />
            </Grid>
    </div>
    <WarehousesGrid
      rowData={warehousesRowData}

      onSelectionChanged={this.onSelectionChanged}
      onClick={this.onWarehouseButtonClick}
      searchvalue={searchInput}
      selectedRowData={selectedRowData}
    />
  </Grid>
</Grid>
</Grow> */ }

{ /* <Grow in={!isInWarehouses}>
<Grid container spacing={0} justifyContent="center">
  <Grid item md={12} sm={12} xs>
    <div className={classes.topDiv}>
      <div className={classes.deletebuttondiv}>
        <Tooltip title="Delete"  placement="top-end">
        <Button

          color="error"
          disabled={!(userPermissions && userPermissions.assets && userPermissions.assets.delete && selectedRowData && selectedRowData.length >= 1)}
          onClick={this.onDeleteButtonClick}
          onKeyPress={this.onDeleteButtonClick}
          role="button"
          tabIndex="0"
        >
          <Delete />

        </Button>
        </Tooltip>
      </div>
      <div className={classes.editbuttondiv}>
        <Tooltip title="Edit"  placement="top-end"  >

        <Button

          disabled={!(userPermissions && userPermissions.assets && userPermissions.assets.update && userPermissions.groups && userPermissions.groups.read
            && userPermissions.devices && userPermissions.devices.read
             && selectedRowData && selectedRowData.length >= 1)}

          onClick={this.onEditButtonClick}
          onKeyPress={this.onEditButtonClick}
          role="button"
          tabIndex="0"
        >
          <Edit
           className={(userPermissions && userPermissions.assets.update && selectedRowData && selectedRowData.length >= 1) ? classes.editDiv : classes.disabledButton}
            />

        </Button>
        </Tooltip>
      </div>
      <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                flexGrow: 1,
              }}
            >
              <Tooltip title="use 'shift' or 'ctrl' for multi-select">

                <div>
                  <span>Selected : </span>
                  <span className={classes.Selected}>
                    {selectedRowData && selectedRowData.length}
                  </span>
                </div>

              </Tooltip>
              <Tooltip title="Clear Selection">
                <IconButton
                  onClick={this.handleSelectionClear}

                >
                  <HighlightOffIcon
                  className={
                    selectedRowData && selectedRowData.length > 0
                      ? classes.clearSelectionActive
                      : classes.clearSelectionDisabled
                  }
                   />
                </IconButton>
              </Tooltip>
            </div>
            <SearchOption
            variant="standard"
              id="userSearchInput"
              value={searchInput}
              onChange={this.onSearchChange}
              placeholder={CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.chamberType ? "Search by " + CompanyInfo.settings.chamberType + " Id" : "Search by chamber Id"}
            />
    </div>
    <ChambersGrid
      rowData={chambersRowData}

      searchvalue={searchInput}
      selectedRowData={selectedRowData}
      onSelectionChanged={this.onSelectionChanged}
      companySettings={companySettings}
    />
  </Grid>
</Grid>
</Grow> */ }
