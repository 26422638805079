/* eslint-disable react/prop-types */
import React from 'react';
import Temperaturehumidity from './Temperaturehumidity';


function Charts(props) {
  const { devices } = props;

  const temperaturevalue = [];
  const humidityvalue = [];
  devices.map((data) => {
    const a = data.devicePings.length;
    // console.log('a is', a, data.devicePings[0]);
    if (data.devicePings && data.devicePings.length) {
      for (let i = 0; i < a; i++) {
        if (data.devicePings[i] && data.devicePings[i].temperature) {
          data.devicePings[i].temperature.map((value, index) => {
            const { length } = data.devicePings[i].temperature;
            if
            (data.devicePings[i].timeStamp && data.devicePings[i].interval && data.devicePings[i].interval.collection) {
              temperaturevalue.push([(data.devicePings[i].timeStamp - ((length - index - 1) * data.devicePings[i].interval.collection * 60)) * 1000, value]);
            }
          });
        }
      }
    }
  });

  devices.map((data) => {
    const a = data.devicePings.length;
    // console.log('a is', a, data.devicePings[0]);
    if (data.devicePings && data.devicePings.length) {
      for (let i = 0; i < a; i++) {
        if
        (data.devicePings[i] && data.devicePings[i].humidity) {
          data.devicePings[i].humidity.map((value, index) => {
            const { length } = data.devicePings[i].humidity;
            if
            (data.devicePings[i].timeStamp && data.devicePings[i].interval && data.devicePings[i].interval.collection) {
              humidityvalue.push([(data.devicePings[i].timeStamp - ((length - index - 1) * data.devicePings[i].interval.collection * 60)) * 1000, value]);
            }
          });
        }
      }
    }
  });
 
  return (
<div>
  {/* pass temp setting to this comp */}
<Temperaturehumidity 
 seriesData={temperaturevalue} 
 humidityData={humidityvalue} 
 temperatureSettings={devices &&  devices[0] && devices[0].temperatureSettings ? devices[0].temperatureSettings : {} }
 humiditySettings={devices &&  devices[0] && devices[0].humiditySettings ? devices[0].humiditySettings : {} } 
  />
</div>
    

  );
}
export default Charts;
