import React, {useEffect,  useState, } from 'react';
import { createStyles } from '@mui/material';
import { makeStyles } from '@mui/styles';
import cogoToast from 'cogo-toast';
import toastOptions from 'utils/toast';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
// import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import Grid from '@mui/material/Grid';

const useStyles = makeStyles(() => createStyles({
  checkboxes: {
    // marginTop: '-0..7rem',
    marginTop: '-1.3rem',

  },
  outerDiv: {
    marginLeft: '1rem',
    marginTop: '1rem',
    overflow: 'hidden',
  },
  innerGrid: {
    fontFamily: 'Montserrat',
    fontSize: '14px',
    color: '#000 ',
    
  },
innerGrid1:{
  fontFamily: 'Montserrat',
    fontSize: '14px',
    color: '#000 ',
    fontWeight:'bold',
    paddingBottom:'1rem',
    paddingTop:'1rem',
    justifyContent:'left'


}

 
}));

const permissionsArray = [
  {
    name: 'Asset Groups',
    id: 'assetGroups',
  },
  {
    name: 'Asset',
    id: 'assets',
  },
  {
    name: 'Devices',
    id: 'devices',
  },
  {
    name: 'User Groups',
    id: 'groups',
  },
  {
    name: 'Roles',
    id: 'roles',
  },
  {
    name: 'Clients',
    id: 'clients',
  },
  {
    name: 'Audit Logs',
    id: 'logs',
  },
];


const dependencyArray = [
  {
    assetGroups: {
      update: {
        read: true,
      },
      create: {
        read: true,
      },
      delete: {
        read: true,
      },
      devices: {
        read: true,
      },
      assets: {
        read: true,
      },
      groups: {
        read: true,
      },
    },
    assets: {
      update: {
        read: true,
      },
      create: {
        read: true,
      },
      delete: {
        read: true,
      },
      devices: {
        read: true,
      },
      groups: {
        read: true,
      },
      assetGroups: {
        read: true,
      },
    },
    logs: {
      update: {
        read: true,
      },
      create: {
        read: true,
      },
      delete: {
        read: true,
      },
    },
    devices: {
      update: {
        read: true,
      },
      create: {
        read: true,
      },
      delete: {
        read: true,
      },
    },
    groups: {
      update: {
        read: true,
      },
      create: {
        read: true,
      },
      delete: {
        read: true,
      },
      roles: {
        read: true,
      },
      clients: {
        read: true,
      },
    },
    roles: {
      update: {
        read: true,
      },
      create: {
        read: true,
      },
      delete: {
        read: true,
      },
    },
    clients: {
      update: {
        read: true,
      },
      create: {
        read: true,
      },
      delete: {
        read: true,
      },
    },
    

  },
];


const getInitialState = (settings) => {
  const p = {};
  permissionsArray.forEach((i) => {
    p[i.id] = {
      create: !!(settings && settings[i.id] && settings[i.id].create),
      read: !!(settings && settings[i.id] && settings[i.id].read),
      update: !!(settings && settings[i.id] && settings[i.id].update),
      delete: !!(settings && settings[i.id] && settings[i.id].delete),

    };
  });
  return p;
 
};


function Permissions(props) {
  const classes = useStyles();
  const { initialState, onPermissionChange, fullAccessvalue,fullScreen } = props;
  const [permissions, setPermissions] = useState(getInitialState(initialState) || {});
  // const [selectAll, setSelectAll]=useState(false)

  // console.log('Permissions state', permissions);
  // console.log('Permissions props', props );

 
  
  const handleChange = (params) => {
   
    // if (selectAll
    //   && !params.value ) {
    //   setSelectAll(true);
    // }
  
    // if (Object.keys(params) && Object.keys(params).length === 0  ) {
    //   if(Object.keys(params) && Object.keys(params).length === 0 && fullAccessvalue){
    //   const newPermissions = { ...permissions, assetGroups: { read: true , }, assets: { read: true } };
    //   setPermissions(newPermissions);
    //   if (onPermissionChange) onPermissionChange(newPermissions);
    // }
    // if(Object.keys(params) && Object.keys(params).length === 0 && fullAccessvalue &&  selectAll){
    //   const newPermissions = { ...permissions, assetGroups: { read: true , update:true, delete:true}, assets: { read: true,update:true, delete:true } };
    //   setPermissions(newPermissions);
    //   if (onPermissionChange) onPermissionChange(newPermissions);
    // }
    if (Object.keys(params) && Object.keys(params).length === 0 && fullAccessvalue ) {
     
      if(Object.keys(params) && Object.keys(params).length === 0 && fullAccessvalue && fullAccessvalue.enabled){
  
        const newPermissions = { ...permissions, assetGroups: { ...permissions.assetGroups,read: true  }, assets: { ...permissions.assets,read: true   },  };
        setPermissions(newPermissions);
        if (onPermissionChange) onPermissionChange(newPermissions);
      }
      //even if the fullAC was off , in frontend it was showing create perm are false, but in request it was still true.
      if(Object.keys(params) && Object.keys(params).length === 0 && fullAccessvalue && fullAccessvalue.enabled === false){
       
        const newPermissions={...permissions , assetGroups:{...permissions.assetGroups, create:false}, assets:{...permissions.assets, create:false}, 
        devices:{...permissions.devices , create:false}, clients:{...permissions.clients , create:false}, 
        groups:{...permissions.groups , create:false},roles:{...permissions.roles , create:false} 
      }
      setPermissions(newPermissions);
      if (onPermissionChange) onPermissionChange(newPermissions);
      }

    

    } else {
     
      const newPermissions = { ...permissions, [params.resource]: { ...permissions[params.resource], [params.actionType]: params.value } };
      if (params && params.resource) {
        if ((params.actionType === 'update' && params.value === true) || (params.actionType === 'create' && params.value === true) || (params.actionType === 'delete' && params.value === true)) {
          // cogoToast.success('All checked permissions are mandantory, please do not remove them', toastOptions);
          const checkedResource=params.resource;
          const readValue=permissions[checkedResource].read;
          if(!readValue){
            cogoToast.success('All checked permissions are mandantory, please do not remove them', toastOptions);
          }
         
          const permissionInArray = dependencyArray.map((arr) => arr[params.resource].update.read);
          newPermissions[params.resource].read = (permissionInArray.toString() === 'true');

        
        }
      }
      if (params && params.resource === 'assetGroups') {
       
        if(params.actionType === 'read' &&  fullAccessvalue && fullAccessvalue.enabled === true){
          if (newPermissions) {
            newPermissions.assetGroups.read = true;
            cogoToast.error('Please remove the Full Access permission first', toastOptions);
          }
        }
        if ((params.actionType === 'read' && params.value === true) || (params.actionType === 'update' && params.value === true) || (params.actionType === 'create' && params.value === true)) {
          if (newPermissions) {
            newPermissions.assets.read = true;
            newPermissions.devices.read = true;
          }
          const checkedResource=params.resource;
          const readValue=permissions[checkedResource].read;
          if(!readValue){
            cogoToast.success('All checked permissions are mandantory, please do not remove them', toastOptions);
          }
         
          if(readValue && fullAccessvalue && fullAccessvalue.enabled){
           
            cogoToast.success('All checked permissions are mandantory, please do not remove them', toastOptions);
          }

          // const permissionInArray = dependencyArray.map((arr) => arr.assetGroups.assets.read);
          // newPermissions.assets.read = (permissionInArray.toString() === 'true');
          // cogoToast.success('All checked permissions are mandantory, please do not remove them', toastOptions);
        }
        if ((params.actionType === 'update' && params.value === true) || (params.actionType === 'create' && params.value === true)) {
          const permissionInArray = dependencyArray.map((arr) => arr.assetGroups.groups.read);
          newPermissions.groups.read = (permissionInArray.toString() === 'true');
         
         
          // cogoToast.success('All checked permissions are mandantory, please do not remove them', toastOptions);
        }
      }
      if (params && params.resource === 'assets') {
        if(params.actionType === 'read' &&  fullAccessvalue && fullAccessvalue.enabled === true){
          if (newPermissions) {
            newPermissions.assets.read = true;
            
            cogoToast.error('Please remove the Full Access permission first', toastOptions);
          }
        }
        if ((params.actionType === 'read' && params.value === true) || (params.actionType === 'update' && params.value === true) || (params.actionType === 'create' && params.value === true)) {
          if (newPermissions) {
            newPermissions.assetGroups.read = true;
            newPermissions.devices.read = true;
          }
        // const permissionInArray = dependencyArray.map((arr) => arr.assets.devices.read );
        // newPermissions.devices.read = (permissionInArray.toString() === 'true');
        }
        if ((params.actionType === 'update' && params.value === true) || (params.actionType === 'create' && params.value === true)) {
          const permissionInArray = dependencyArray.map((arr) => arr.assets.groups.read);
          newPermissions.groups.read = (permissionInArray.toString() === 'true');
        }
        // cogoToast.success('All checked permissions are mandantory, please do not remove them', toastOptions);
      }

      if ((params.resource === 'groups' && params.actionType === 'read' && params.value === true) || (params.resource === 'groups' && params.actionType === 'update' && params.value === true) || (params.resource === 'groups' && params.actionType === 'create' && params.value === true)) {
        if (newPermissions) {
          // cogoToast.success('All checked permissions are mandantory, please do not remove them', toastOptions);
          const checkedResource=params.resource;
          const readValue=permissions[checkedResource].read;
          if(readValue){
           
            cogoToast.success('All checked permissions are mandantory, please do not remove them', toastOptions);
          }
          // if(permissions.group.read && permissions.assetgroups.update){
          //   cogoToast.success('All checked permissions are mandantory, please do not remove them', toastOptions);
          // }

          newPermissions.roles.read = true;
          newPermissions.clients.read = true;

        }
        // cogoToast.success('All checked permissions are mandantory, please do not remove them', toastOptions);
      }

    
      setPermissions(newPermissions);
      if (onPermissionChange)onPermissionChange(newPermissions);
    }
  };

// const handleSelectAllChange=(e)=>{
//   console.log('Change toggle ', fullAccessvalue);
//   // enableAccessOption();
// const {onPermissionChange}= props;
//   setSelectAll(!selectAll);
//   const newPermissions = {};
//   permissionsArray.map((item) => {
//     newPermissions[item.id] = {};
//     if (e.target.value === "all" && e.target.checked) {
//       newPermissions[item.id] = {
//         // create: true,
//         read: true,
//         update: true,
//         delete: true,
//       };
//     }
//   });
//   setPermissions(newPermissions);
//   if (onPermissionChange) onPermissionChange(newPermissions);
//   // handleChange({});
// }
  

  useEffect(() => {
    if (fullAccessvalue) {
      handleChange({});
    }
  }, [fullAccessvalue]);

  


  return (
    <div>
       {/* <FormGroup sx={{
        marginTop: "1rem", display: "flex", alignItems: "center",
      }}
      >
        <FormControlLabel
          control={<Switch value="all" 
          checked={selectAll} 
          onChange={handleSelectAllChange}
           />}
          label="Select All Permissions"
        />
      </FormGroup> */}
      <Typography
        variant="h5"
        align="center"
        color="inherit"
        style={{
          marginTop: '1em', color: '#777', fontFamily: 'Montserrat', marginBottom: '1em',
        }}
      >
Permissions
      </Typography>

        { !fullScreen && (
           <Grid container className={classes.permissionsGrid} spacing={2}>
           <Grid item xs={3} />
           <Grid item xs={9}>
             <Grid container justify="space-between" spacing={7}>
               <Grid item>Create</Grid>
               <Grid item>Read</Grid>
               <Grid item>Update</Grid>
               <Grid item>Delete</Grid>
             </Grid>
           </Grid>
         </Grid>

        )}
     { !fullScreen && (
permissionsArray.map((item) => (
  <Grid container className={classes.outerDiv} key={item.name}>
    <Grid item xs={3} className={classes.innerGrid}>
      {item.name}
    </Grid>
    <Grid item xs={9}>
      <Grid container spacing={8} justify="space-between" className={classes.checkboxes}>
        <Grid item >
          <Checkbox
            name="create"
            disabled={!(fullAccessvalue && fullAccessvalue.enabled) || undefined}
            checked={permissions[item.id].create && (fullAccessvalue && fullAccessvalue.enabled)}
            onChange={(e) => handleChange({ value: e.target.checked, resource: item.id, actionType: 'create' })}
            
            style={{color:!(fullAccessvalue && fullAccessvalue.enabled) ? 'rgba(0,0,0,0.26)' : '#008081'}}
          />
        </Grid>
        <Grid item>
          <Checkbox
            name="read"
            checked={permissions[item.id].read}
            onChange={(e) => handleChange({ value: e.target.checked, resource: item.id, actionType: 'read' })}
            style={{ color: '#008081' }}
          />
        </Grid>
        <Grid item>
          <Checkbox
            name="update"
            checked={permissions[item.id].update}
            onChange={(e) => handleChange({ value: e.target.checked, resource: item.id, actionType: 'update' })}
            style={{ color: '#008081' }}
          />
        </Grid>
        <Grid item>
          <Checkbox
            name="delete"
            checked={permissions[item.id].delete}
            onChange={(e) => handleChange({ value: e.target.checked, resource: item.id, actionType: 'delete' })}
            style={{ color: '#008081' }}
          />
        </Grid>
      </Grid>
    </Grid>
  </Grid>
))
     )}
      
  { fullScreen && (
    permissionsArray.map((item)=>(
      <>
      <Grid container key={item.name} className={classes.innerGrid1}>
      {item.name}
    </Grid>
    {/* For create */}
    <Grid container >
      <Grid item style={{padding:'0.2rem'}} xs={6}>
Create
      </Grid>
      <Grid item xs={6} >
      <Checkbox
            name="create"
            disabled={!(fullAccessvalue && fullAccessvalue.enabled) || undefined}
            checked={permissions[item.id].create && (fullAccessvalue && fullAccessvalue.enabled)}
            onChange={(e) => handleChange({ value: e.target.checked, resource: item.id, actionType: 'create' })}
            
            style={{color:!(fullAccessvalue && fullAccessvalue.enabled) ? 'rgba(0,0,0,0.26)' : '#008081'}}
          />
      </Grid>
    </Grid>

{/* For read */}
    <Grid container>
    <Grid item style={{padding:'0.2rem'}}  xs={6}>
Read
      </Grid>
      <Grid item xs={6}>
      <Checkbox
            name="read"
            checked={permissions[item.id].read}
            onChange={(e) => handleChange({ value: e.target.checked, resource: item.id, actionType: 'read' })}
            style={{ color: '#008081' }}
          />
      </Grid>
    </Grid>

    {/* For Udpate  */}
    <Grid container>
    <Grid item style={{padding:'0.2rem'}}  xs={6}>
Update
      </Grid>
      <Grid item xs={6}>
      <Checkbox
            name="update"
            checked={permissions[item.id].update}
            onChange={(e) => handleChange({ value: e.target.checked, resource: item.id, actionType: 'update' })}
            style={{ color: '#008081' }}
          />
      </Grid>
    </Grid>

    {/* For delete  */}
    <Grid container>
    <Grid item style={{padding:'0.2rem'}}  xs={6}>
Delete
      </Grid>
      <Grid item xs={6}>
      <Checkbox
            name="delete"
            checked={permissions[item.id].delete}
            onChange={(e) => handleChange({ value: e.target.checked, resource: item.id, actionType: 'delete' })}
            style={{ color: '#008081' }}
          />
      </Grid>
    </Grid>


</>
    ))
  )}

    </div>
  );
}


export default Permissions;



