import { withStyles } from "@mui/styles";
import React , {useState, useEffect} from "react";
import Zoom from '@mui/material/Zoom';
import SelectField from "components/SelectField";
import Input from "components/Input";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
// import UserContext from "Context/UserContext";
import SecondaryButton from "components/SecondaryButton";
import PrimaryButton from "components/PrimaryButton";
import { useMediaQuery, createTheme } from '@material-ui/core';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import * as yup from 'yup';
import cogoToast from "cogo-toast";
import {
    Typography,
    Grid,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
    MenuItem,
    Dialog,
    DialogActions,
    Divider,
    DialogContent,
  } from "@mui/material";
  import API from "API/apiConfig";


  const AddValidationSchema = yup.object().shape({
    minVio: yup.string().required('Minimum Temperature cannot be empty'),
    maxVio:yup.string().required('Maximum Temperature cannot be empty'),
    
  });

  const APIURL = process.env.REACT_APP_APIURL;

const styles={
  appBar: {
    position: 'sticky',
  },
  title: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    fontFamily:'Montserrat',
  },
}

const Config=(props)=>{
const {classes,openDeviceConfigDialog, selectedRowData, onDeviceConfigDialogClose}=props;
const [sendingIntervalTrip, setSendingIntervalTrip] = useState(60);
const [customSendingIntervalTrip, setCustomSendingIntervalTrip] = useState(false);
const [customSITripValue, setCustomSITripValue] = useState("");

const [CI, setCI] = useState(5);
  const [customCI, setCustomCI] = useState(false);
  const [customCIValue, setCustomCIValue] = useState("");

  const [temperatureLowerViolationLimit, setTemperatureLowerViolationLimit] = useState("-299");
  const [temperatureHigherViolationLimit, setTemperatureHigherViolationLimit] = useState("-299");
  const [mktOrAvg, setMktOrAvg] = useState("MKT");
  const [primaryTempSensor, setPrimaryTempSensor] = useState("INT");
  const [TZ, setTZ] = useState(0);

  const [wifimode, setWifiMode]=useState("PERSONAL")
  const [userName, setUserName]=useState();
  const [ssid, setSSID]=useState();
  const [password, setPassword]=useState();

const [pendingUpdates, setPendingUpdates] = useState({});
const [fullScreen , setFullScreen]=useState(false);

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

  // const mdBreakPoint = useMediaQuery(theme.breakpoints.up('md'));
 const smBreakPoint = useMediaQuery(theme.breakpoints.up('sm'));
//  const userDetails = useContext(UserContext);
//  const { assetGroups } = userDetails;

useEffect(()=>{
  setFullScreen(smBreakPoint)
},[smBreakPoint])

const handleSendingIntervalTripChange = (e) => {
  if (e.target.value === "custom") setCustomSendingIntervalTrip(true);
  else setCustomSendingIntervalTrip(false);
  setSendingIntervalTrip(e.target.value);
};

const handleCIChange = (e) => {
  if (e.target.value === "custom") {
    setCustomCI(true);
  } else setCustomCI(false);
  setCI(e.target.value);
};

const handleWiFiChange=(e)=>{
setWifiMode(e.target.value)
}


const onSubmit= async()=>{
 
  const selectedDeviceUids = selectedRowData
      .map((device) => device.uid)
      .toString();
      const deviceConfigData = {
        transmitInterval: sendingIntervalTrip === "custom" ? Number(customSITripValue) : sendingIntervalTrip,
        collectionInterval: CI === "custom" ? Number(customCIValue) : CI,
        timeZoneOffset: TZ,
        temperatureConfig: {
          violationMin: Number(temperatureLowerViolationLimit),
          violationMax: Number(temperatureHigherViolationLimit),
          meanTemperatureType: mktOrAvg,
          primarySensor: primaryTempSensor,
        },
        // wifiSettings:{
        //   WPA2_MODE:wifimode,
        //   WPA2_SSID:ssid,
        //   WPA2_PASSWORD:password,
        //   WPA2_USERNAME:userName
        // }
      };
      //check if ssid and password is entered or not, if empty dont send wifiSettings.
      if(ssid && ssid.length > 0 && password && password.length > 0){
        deviceConfigData.wifiSettings={
          WPA2_MODE:wifimode,
            WPA2_SSID:ssid,
            WPA2_PASSWORD:password,
            WPA2_USERNAME:userName
        }
      }
     
      AddValidationSchema.validate({
        minVio: temperatureLowerViolationLimit,
        maxVio: temperatureHigherViolationLimit,
        
      })
      .then(()=>{
        if(Number(temperatureLowerViolationLimit) <= Number(temperatureHigherViolationLimit) ){
          API.post(
            `${APIURL}/device/configuration?uid=${selectedDeviceUids}`,
            deviceConfigData,
          )
          .then((resp)=>{
            if (resp?.data?.status === "success") {
              onDeviceConfigDialogClose();
              cogoToast.success(`Changed configuration of device ${selectedRowData && selectedRowData[0] && selectedRowData[0].id}`, { position: "top-right" });
            } else {
              cogoToast.error(resp?.data?.message, { position: "top-right" });
            }
          })
          .catch((error)=>{
            console.error("Error changing config", error);
            cogoToast.error("Error while changing configuration", { position: "top-right" });
          })
        }
        else{
          cogoToast.error("Minimum Temperature should be lesser than Maximum Temperature", { position: "top-right" });
        }
        
      })
      .catch((err)=>{
        const er=err.message
        cogoToast.error(er, { position: 'top-right' });
      })
      
}

useEffect(()=>{
  if (selectedRowData.length === 1){
  
    const selectedDeviceUid = selectedRowData
    .map((device) => device.uid)
    .toString();
    //  const selectedDeviceIds = selectedRowData.map((device) => device.id || device.deviceId).toString();
    API
    .get(`${APIURL}/device/configuration?uid=${selectedDeviceUid}`)
    .then((resp)=>{
      // console.log('resp', resp)
      if (resp?.data?.status === "success"){
        const {
          collectionInterval,
          timeZoneOffset,
          transmitIntervalTrip,
          transmitIntervalNotInUse,
          temperatureConfig: {
            meanTemperatureType,
            primarySensor,
            violationMax,
            violationMin,
          },
          wifiSettings:{
            WPA2_MODE,
            WPA2_SSID,
            WPA2_PASSWORD,
            WPA2_USERNAME
          },
          parametersToBeUpdatedInConfig,
        } = resp.data.data;
       
        if ([5, 10, 15].includes(collectionInterval)) {
          setCI(collectionInterval);
        } else {
          setCustomCI(true);
          setCustomCIValue(collectionInterval);
          setCI("custom");
        }
        if ([30, 60, 120].includes(transmitIntervalNotInUse)) {
          setSendingIntervalTrip(transmitIntervalNotInUse);
        } else {
          setCustomSITripValue(transmitIntervalTrip);
          setCustomSendingIntervalTrip(true);
          setSendingIntervalTrip("custom");
        }
        setPendingUpdates(parametersToBeUpdatedInConfig);
        setTZ(timeZoneOffset);
        setTemperatureHigherViolationLimit(Number(violationMax));
          setTemperatureLowerViolationLimit(Number(violationMin));
          setMktOrAvg(meanTemperatureType ? meanTemperatureType : "MKT");
          setWifiMode(WPA2_MODE);
          setSSID(WPA2_SSID);
          setPassword(WPA2_PASSWORD);
          setUserName( WPA2_USERNAME) 
          setPrimaryTempSensor(primarySensor === "undefined" ? "INT" : primarySensor );

      }else{
        console.error("Error changing config",);
      }
    })
    .catch((err)=>{
      
      console.log('error', err)
    })
  }

},[selectedRowData])


    return (
        <>
        <Dialog
          open={openDeviceConfigDialog}
          TransitionComponent={Zoom}
          onClose={onDeviceConfigDialogClose}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
          fullWidth
          scroll="body"
          fullScreen={!fullScreen}
          PaperProps={{
            style: {
              backgroundColor: '#f2f2f2',
            },
          }}
        >
          
          <DialogContent style={{paddingTop: !fullScreen ? '5rem' : '2rem', }}>
            {
              selectedRowData && selectedRowData[0] && selectedRowData[0].type  && selectedRowData[0].type !== "PIXEL"  ? 
              <Grid container  >
              {!fullScreen && (
                                <AppBar className={classes.appBar}>
                                  <Toolbar style={{backgroundColor:'#008081'}}>
                                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onDeviceConfigDialogClose}
                        aria-label="close"
                      >
                        <CloseIcon />
                      </IconButton>
                                    <Typography variant="h6" className={classes.title}>
                                  
                                    Configuration for Device:
                      {" "}
                      {selectedRowData[0].deviceId || selectedRowData[0].id}
                      </Typography>
                                    <Button color="inherit" 
                                     onClick={onSubmit}
                                    >
                                   save
                      </Button>
                                  </Toolbar>
                                </AppBar>
                                )} 
                                {
                                  fullScreen && (
                                    <>
                                     <Grid container item md={12}>
                  <Grid item md={12}>
                    <Typography variant="h6" sx={{pb:'1rem'}}>
                      Configuration for Device:
                      {" "}
                      {selectedRowData[0].deviceId || selectedRowData[0].id}
                    </Typography>
                  </Grid>
                  
                </Grid>
                                    </>
                                  )
                                }
               
                 
        
        
                        {/* -------------------SI and CI settings 1 ---------------- */}   
        
                         <Grid container >
                  <Grid item  xl={5} lg={5} md={5} sm={8} xs={9}>
                  <Typography variant="p" sx={{fontWeight:500, fontSize:'1.1rem'}} >Data Collection and Sending Settings</Typography> 
                  </Grid>
                  <Grid item xl={7} lg={7} md={7} sm={4} xs={3} style={{margin:'auto'}}>
                    <Divider />
                  </Grid>
                </Grid>
        
                       
                            
                            <Grid container spacing={2}  sx={{p:'1.5rem'}} >
                            <Grid container item xl={6} lg={6} md={6} sm={12} xs={12}>
                              <Grid item xl={6} lg={6} md={6} sm={6} xs={6}
                               style={{
                           
                                textAlign:'left', 
                                  // margin:'auto',
                                   fontSize: '14px',
                                   fontFamily: 'Montserrat',
                                }}
                              >
                              <Typography variant="p"  sx={{p:"0.5rem", }} >Data Collection Interval</Typography>
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={6}  xs={6}>
                            <SelectField
                              variant="standard"
                                id="demo-mutiple-checkbox"
                                value={CI}
                                onChange={handleCIChange}
                                backgroundColor="#fff"
                              >
                                 <MenuItem value={5}>5 mins</MenuItem>
                          <MenuItem value={10}>10 mins</MenuItem>
                          <MenuItem value={15}>15 mins</MenuItem>
                          <MenuItem value="custom">Custom</MenuItem>
                                 
                               
                              </SelectField>
                              {customCI && (
                                 <div style={{paddingTop:'1rem'}}>
                          <Input
                            placeholder="Custom Collection Interval"
                            size="small"
                            type="number"
                            value={customCIValue}
                            backgroundColor="#fff"
                            onChange={(e) => setCustomCIValue(e.target.value)}
                          />
                          </div>
                          
                        )}
                         <Typography
                          variant="caption"
                          component="div"
                          style={{ color: "#888" }}
                        >
                          {pendingUpdates
                            && pendingUpdates?.collectionInterval
                            && `Will be Updated to ${pendingUpdates.collectionInterval}`}
                        </Typography>
                            </Grid> 
                                </Grid>
                              <Grid container item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={6}
                             style={{
                           
                              textAlign:'left', 
                                // margin:'auto',
                               //  paddingTop:'0.7rem',
                                 fontSize: '14px',
                                 fontFamily: 'Montserrat',
                              }}>
                            <Typography variant="p" sx={{p:"0.5rem",}}>Data Sending Interval</Typography>
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                            <SelectField
                              variant="standard"
                                id="demo-mutiple-checkbox"
                                value={sendingIntervalTrip}
                                onChange={handleSendingIntervalTripChange}
                                backgroundColor="#fff"
                              >
                                 <MenuItem value={30}>30 mins</MenuItem>
                          <MenuItem value={60}>1 hour</MenuItem>
                          <MenuItem value={120}>2 hours</MenuItem>
                          <MenuItem value="custom">Custom</MenuItem>
                                 
                               
                              </SelectField>
                              {customSendingIntervalTrip && (
                                <div style={{paddingTop:'1rem'}}>
                          <Input
                            placeholder="Custom Sending Interval Trip "
                            size="small"
                            type="number"
                            backgroundColor="#fff"
                            value={customSITripValue}
                            onChange={(e) => setCustomSITripValue(e.target.value)}
                          />
                          </div>
                          
                        )}
                          <Typography
                          variant="caption"
                          style={{ color: "#888" }}
                          component="div"
                        >
                          {pendingUpdates
                            && pendingUpdates?.transmitIntervalTrip 
                            && `Will be Updated to ${pendingUpdates.transmitIntervalTrip}`}
                        </Typography>
                            </Grid>    
                              </Grid>
                             
                            </Grid>
        
                        {/* -------------------Threshold settings 1 ---------------- */}
        
                      <Grid container>
                     <Grid item xl={5} lg={5} md={5} sm={7} xs={9}>
                     <Typography variant="p" sx={{fontWeight:500, fontSize:'1.1rem'}} > Threshold and Offset Settings</Typography> 
                   
                     </Grid>
                   <Grid item xl={7} lg={7} md={7} sm={5} xs={3}  style={{margin:'auto'}}>
                    <Divider />
                     </Grid>
                        </Grid>
        
        
                      
        
                       
        
                          <Grid container spacing={3} sx={{p:'1.5rem'}} >
                            <Grid container item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={6}
                             style={{
                              //  padding:'1.5rem' ,
                              //  paddingLeft:  '1rem',
                              paddingTop:'0.7rem',
                           textAlign:'left', 
                            //  margin:'auto',
                              fontSize: '14px',
                              fontFamily: 'Montserrat',
                           }}>
                            <Typography variant="p" sx={{p:"0.5rem"}}>Minimum Temperature</Typography>
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                            <Input
                          backgroundColor="#fff"
                         size="small"
                         type="number"
                         onWheel={(e)=> e.target.blur()} 
                         helperText={
                           pendingUpdates
                           && pendingUpdates?.temperatureConfig?.violationMin !== undefined
                           && `Will be Updated to ${pendingUpdates.temperatureConfig.violationMin}`
                         }
                         value={temperatureLowerViolationLimit}
                         onChange={(e) => setTemperatureLowerViolationLimit(
                           e.target.value
                         )}
                       />
                              </Grid>
                            </Grid>
                            <Grid container item xl={6} lg={6} md={6} sm={12} xs={12}>
                              <Grid item xl={6} lg={6} md={6} sm={6} xs={6}
                               style={{
                           
                                textAlign:'left', 
                                 //  margin:'auto',
                                  paddingTop:'0.7rem',
                                   fontSize: '14px',
                                   fontFamily: 'Montserrat',
                                }}>
                              <Typography variant="p" sx={{p:"0.5rem"}}>Maximum Temperature</Typography>
                              </Grid>
                              <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                              <Input
                          backgroundColor="#fff"
                         size="small"
                         type="number"
                         onWheel={(e)=> e.target.blur()} 
                         helperText={
                           pendingUpdates
                           && pendingUpdates?.temperatureConfig?.violationMax !== undefined
                           && `Will be Updated to ${pendingUpdates.temperatureConfig.violationMax}`
                         }
                         value={temperatureHigherViolationLimit}
                         onChange={(e) => setTemperatureHigherViolationLimit(
                           e.target.value
                         )}
                       />
                              </Grid>
                              </Grid>
                              <Grid container item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={6} 
                                 style={{
                                  textAlign:'left', 
                                    // margin:'auto',
                                     fontSize: '14px',
                                     fontFamily: 'Montserrat',
                                  }}>
                                <Typography variant="p" sx={{p:"0.5rem"}}>MKT/AVG</Typography>
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={6} >
                                <SelectField
                              variant="standard"
                                id="demo-mutiple-checkbox"
                                value={mktOrAvg}
                                onChange={(e) => setMktOrAvg(e.target.value)}
                                backgroundColor="#fff"
                              >
                                  <MenuItem value="MKT">MKT</MenuItem>
                          <MenuItem value="AVG">AVG</MenuItem>
                          
                              </SelectField>
                              <Typography
                          variant="caption"
                          component="div"
                          style={{ color: "#888" }}
                        >
                          {pendingUpdates
                            && pendingUpdates?.temperatureConfig?.meanTemperatureType 
                            && `Will be Updated to ${pendingUpdates.temperatureConfig.meanTemperatureType}`}
                        </Typography>
                                  </Grid>
                              </Grid>
                              <Grid container item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={6}
                                 style={{
                                  //  padding:'1.5rem' ,
                                  //  paddingLeft:  '1rem',
                               textAlign:'left', 
                                //  margin:'auto',
                                paddingTop:'0.7rem',
                                  fontSize: '14px',
                                  fontFamily: 'Montserrat',
                               }} >
                                <Typography variant="p" sx={{p:"0.5rem"}} 
                                >TimeZone Offset</Typography>
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                                <Input
                               placeholder="enter timezone offset"
                               size="small"
                               type="number"
                               backgroundColor="#fff"
                               value={TZ}
                               onWheel={(e)=> e.target.blur()} 
                               helperText={
                                 pendingUpdates && pendingUpdates?.timeZoneOffset >= 0 
                                   ? `Will be Updated to ${pendingUpdates.timeZoneOffset}`
                                   : ""
                               }
                               onChange={(e) => setTZ(e.target.value)}
                       />
                                </Grid>
                              </Grid>
                          </Grid>
                        <Stack sx={{pb:"1rem"}}>
                        <Alert severity="info">Set Temperature thresholds -299 to disable temperature violations.</Alert>
                        </Stack> 
        
                         {/* ------------------ TimeZone Offset  settings  1---------------- */}
        
                        
        
                          {/* -------------------Temperature Sensor settings if device is 300 type 1---------------- */}
        
                         {
                          selectedRowData && 
                          selectedRowData[0] && 
                          selectedRowData[0].type && 
                          (selectedRowData[0].type === "PIXEL_W300"  || selectedRowData[0].type === "PIXEL_C300" ) &&
                          ( 
                          <>
                            <Grid container>
                  <Grid item  xl={5} lg={5} md={5} sm={6} xs={8} >
                  <Typography variant="p"sx={{fontWeight:500, fontSize:'1.1rem'}}>  Temperature Sensor Settings</Typography> 
                
                  </Grid>
                <Grid item xl={7} lg={7} md={7} sm={6} xs={4} style={{margin:'auto'}}>
                 <Divider />
                  </Grid>
                </Grid>
         
                <Grid container xs={12} sx={{p:'0.8rem'}} >
                          <Grid item xl={4} lg={6} md={6} sm={6} xs={6}
                          style={{
                           
                         textAlign:'left', 
                           margin:'auto',
                            fontSize: '14px',
                            fontFamily: 'Montserrat',
                         }}>
                          <Typography variant="p" sx={{p:"0.5rem"}}>Primary Temperature sensor</Typography>
                          </Grid>
                          <Grid item xl={4} lg={6} md={6} sm={6} xs={6}>
                          <FormControl>
                       <RadioGroup
                         aria-labelledby="primaryTempSensor"
                         defaultValue="PRB"
                         name="PrimaryTempSensor"
                         row
                         value={primaryTempSensor}
                         onChange={(e) => setPrimaryTempSensor(e.target.value)}
                       >
                         <FormControlLabel
                           value="PRB"
                           control={<Radio style={{color:'#008081'}} />}
                           label="Probe"
                         />
                         <FormControlLabel
                           value="INT"
                           control={<Radio style={{color:'#008081'}}  />}
                           label="Internal"
                         />
                       </RadioGroup>
                     </FormControl>
                     <Typography
                       style={{ color: "#888" }}
                       variant="caption"
                       component="div"
                     >
                       {pendingUpdates
                         && pendingUpdates?.temperatureConfig?.primarySensor 
                         && `Will be Updated to ${pendingUpdates.temperatureConfig.primarySensor}`}
                     </Typography>
                          </Grid>
                        <Grid item xl={4} />
                        </Grid>
        
                            </> 
                           )
                         } 
        
                          {/* -------------------SSID Settings 1---------------- */}
        
                      <Grid container >
                     <Grid item   xl={5} lg={5} md={5} sm={6} xs={8}  >
                     <Typography variant="p"sx={{fontWeight:500, fontSize:'1.1rem'}} >SSID and Password Settings</Typography> 
                
                     </Grid>
                   <Grid item xl={7} lg={7} md={7} sm={6} xs={4} style={{margin:'auto'}}>
                    <Divider />
                     </Grid>
                          </Grid>
        
                          <Grid container xs={12} sx={{p:'0.8rem'}} >
                          <Grid item xl={4} lg={6} md={6} sm={6} xs={6}
                          style={{
                            
                         textAlign:'left', 
                           margin:'auto',
                            fontSize: '14px',
                            fontFamily: 'Montserrat',
                         }}>
                          <Typography variant="p" sx={{p:"0.5rem"}}>Wifi Type</Typography>
                          </Grid>
                          <Grid item xl={4} lg={6} md={6} sm={6} xs={6}>
                          <SelectField
                              variant="standard"
                                id="demo-mutiple-checkbox"
                                value={wifimode}
                                onChange={handleWiFiChange}
                                backgroundColor="#fff"
                              >
                                 <MenuItem value="PERSONAL">Personal</MenuItem>
                          <MenuItem value="ENTERPRISE">Enterprise</MenuItem>
                                   
                              </SelectField>
                              <Typography
                       style={{ color: "#888" }}
                       variant="caption"
                       component="div"
                     >
                       {pendingUpdates
                         && pendingUpdates?.wifiSettings?.WPA2_MODE
                         && `Will be Updated to ${pendingUpdates.wifiSettings.WPA2_MODE}`}
                     </Typography>
                          </Grid>
                          <Grid item xl={4} />
                        </Grid>
        
                         {wifimode === "ENTERPRISE" && (
                           <Grid container xs={12} sx={{p:'0.5rem'}} >
                           <Grid item xl={4} lg={6} md={6} sm={6} xs={6}
                           style={{
                           
                          textAlign:'left', 
                            margin:'auto',
                             fontSize: '14px',
                             fontFamily: 'Montserrat',
                          }}>
                           <Typography variant="p" sx={{p:"0.5rem"}}>User Name</Typography>
                           </Grid>
                           <Grid item xl={4} lg={6} md={6} sm={6} xs={6}>
                           <Input
                         placeholder="enter user name"
                         size="small"
                         type="string"
                         value={userName}
                         backgroundColor="#fff"
                         helperText={
                          pendingUpdates && pendingUpdates?.wifiSettings?.WPA2_USERNAME 
                            ? `Will be Updated to ${pendingUpdates.wifiSettings.WPA2_USERNAME}`
                            : ""
                        }
                         onChange={(e) => setUserName(
                           e.target.value,
                         )}
                       />
                           </Grid>
                           <Grid item xl={4} />
                         </Grid>
                         ) }
                       
                       <Grid container xs={12} sx={{p:'0.5rem'}} >
                           <Grid item xl={4} lg={6} md={6} sm={6} xs={6}
                           style={{
                          
                          textAlign:'left', 
                            margin:'auto',
                             fontSize: '14px',
                             fontFamily: 'Montserrat',
                          }}>
                           <Typography variant="p" sx={{p:"0.5rem"}}>SSID</Typography>
                           </Grid>
                           <Grid item xl={4} lg={6} md={6} sm={6} xs={6}>
                           <Input
                               placeholder="enter SSID "
                                size="small"
                             type="string"
                             backgroundColor="#fff"
                         value={ssid}
                         onChange={(e) => setSSID(
                           e.target.value,
                         )}
                         helperText={
                          pendingUpdates && pendingUpdates?.wifiSettings?.WPA2_SSID 
                            ? `Will be Updated to ${pendingUpdates.wifiSettings.WPA2_SSID}`
                            : ""
                        }
                       />
                           </Grid>
                           <Grid item xl={4} />
                         </Grid>
        
                         <Grid container xs={12} sx={{p:'0.5rem'}} >
                           <Grid item xl={4} lg={6} md={6} sm={6} xs={6}
                           style={{
                            
                          textAlign:'left', 
                            margin:'auto',
                             fontSize: '14px',
                             fontFamily: 'Montserrat',
                          }}>
                           <Typography variant="p" sx={{p:"0.5rem"}}>Password</Typography>
                           </Grid>
                           <Grid item xl={4} lg={6} md={6} sm={6} xs={6}>
                           <Input
                               placeholder="enter password "
                                size="small"
                             type="string"
                             backgroundColor="#fff"
                         value={password}
                         onChange={(e) => setPassword(
                           e.target.value,
                         )}
                         helperText={
                          pendingUpdates && pendingUpdates?.wifiSettings?.WPA2_PASSWORD 
                            ? `Will be Updated to ${pendingUpdates.wifiSettings.WPA2_PASSWORD}`
                            : ""
                        }
                       />
                           </Grid>
                           <Grid item xl={4} />
                         </Grid> 
        
       
        
        
                    </Grid>
               :
               <Typography >
                 Sorry, old devices cannot be configured :( <br />
                  Contact Adapt ideations for more info
               </Typography>
             
            }
         
       
            </DialogContent>


                  {
                    fullScreen && (
                      <DialogActions >
                    <SecondaryButton text="Cancel" 
                  onClick={onDeviceConfigDialogClose}
                         />
                        <PrimaryButton text="Submit" 
                      onClick={onSubmit} 
                             />   
                   </DialogActions>
                    )
                  }
        
        </Dialog>

   
        </>
    )
}
export default withStyles(styles)(Config);


 
 
     