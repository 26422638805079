const FAQ=[
    {
    id:1,
    question:'What is Asset?',
    ans:`A place or container where pixel devices are kept for monitoring purpose can be treated as asset.Different companies have different naming conventions.This is similar to chamber, freezer where you have a reference id for goods kept for monitoring.
    eg: Freezers, Store Unit`,
    link:'https://adapt29.trainn.co/share/dqw4WNVJhuKPav58SNis6A/embed?autoplay=false%22'
},
{
    id:2,
    question:'What is Asset Group?',
    ans:`Asset Group is nothing but the group of assets.
     eg: Bloodbank , Store House`,
     link:'https://adapt29.trainn.co/share/dqw4WNVJhuKPav58SNis6A/embed?autoplay=false%22'
},
{
    
    id:3,
    question:'What is Role ? How to create Role ? ',
    ans: `Roles are nothing but set of permissions.

    How to crete Role?

    1. To create roles , go to Control Panel tab, then go to Access Management section.
    2. Go to Roles section, Click on Add Role button.
    3. Give a desire name to Role and give permissions as per your requirement and click on Save button.Hence you are done with creating the role.

    Note: Default client role is created whenever a client is created from clients tab of the control panel section.
    `,
    link:'https://adapt29.trainn.co/share/5Eo0sBjdC0g9CYI476BMYA/embed?autoplay=false%22'
},
{
    id:4,
    question:'What is UserGroup in pixel?How to create UserGroup?',
    ans:`User Group is simply a group which is linked to some role. A User Group is then assigned to asset group level or asset level. 

    How to crete User Group?

    1. To create User Group, go to Control Panel tab, then go to Access Management section.
    2. Go to User Group section, Click on Add Group button.
    3. Enter the User Group name  and assign role to that User Group.
    4. Click on Save button and you are done with creating the User Group.
    `,
    link:'https://adapt29.trainn.co/share/5Eo0sBjdC0g9CYI476BMYA/embed?autoplay=false%22'
},
{
    id:5,
    question:'How to customize the Asset group and Asset name ? ',
    ans:`A User can customize the name of Asset and Asset Group as per the requirement.

    How to do that ?
    1. Go to Company Settings.
    2. In the Dashboard Settings, you will see the fields for Asset And Asset Group.
    3. Enter the required Asset and Asset Group name and click on Update All Changes button.
    4. You can go to Dashboard and verify the Asset and Asset Group name.

    Note: Only SuperUser is having the permission to view Company Setting section`
    
},
{
    id:6,
    question:'How to download the report of chambers?',
    ans:`On Dashboard , click on any chamber you wish to download the report of .You will see a Report download button.
    
    1. Click on Report download button
    2. Select the parameters and click on download button.
    
    Or 

    If you wish to download the report of all Asset Groups and all Assets. 
    Go to Dashboard, click on that button and download the required Asset Group and Asset report. `
},
{
    id:7,
    question:'What is the difference between violation and warnings thresholds?',
    ans:`When you create an alert condition, you can set up a violation threshold and a warning threshold.
    Violation thresholds are used to identify critical issues that must be addressed before the code can be considered acceptable. These issues may include things like critical security vulnerabilities or violations of  standards. Violations typically require immediate attention and action to be taken to bring the code up to an acceptable standard.

    Warning thresholds, on the other hand, indicate potential issues that should be reviewed and improved, but may not require immediate action.

    Note: Warning threshold should always be in between Violation threshold. `
},
{
    id:8,
    question:'How to add Users to user group? ',
    ans:`After creating roles and User Group, it's time to add Users to UserGroup.
    1. Go to Control Panel.
    2. Go to Access Management section.
    3. Select the User Group, to which you wish to add the user.
    4. Click on Manage Users button.
    5. Enter email id and access type, and click on Save button. `
},
{
    id:9,
    question:'How to enable the notification settings for user?',
    ans:`Once you are done with adding users to User Group
    1. Go to Control Panel.
    2. Go to Warehouse tab.
    3. Assign that User Group to the Warehouse you wish the user(s)  to see the data of.
    4. Click on Notification Setting.
    5. Select the Notifications type(SMS, email, push). `
},
{
    id:10,
    question:'Who are Clients ? How to add them? ',
    ans:`Clients are your customers or users who ever is using our devices through you. You can also be client. 
    You can create clients by going down to client's tab in control panel section. 

    Note: While creating a client, a client id is required which is a non-editable field. 
    
    Once a client is created, a user group with role of default client is created. Default client has limited permissions, but still can have users inside it, as it is a group in the end. All the users in the default client group would have default client role by default. 
    The default client role is non-editable and non-deletable. It will be deleted once the client is deleted. `,
    link:"https://adapt29.trainn.co/share/exEcWY8xtm6LGAeiigXhWA/embed?autoplay=false%22"
},
{
    id:11,
    question:'What does User access type in user group means? ',
    ans:`User Access type is of two types. Admin and User. 

    Admin is the one who can create and add user and also manage notification settings of the users. 
    
    The user is the one who can only see the other users present in his group but cannot add users or check the notification settings of other users. 
    
    Once the user is successfully added, the group admin or the anyone who has permissions to update the group can enable notification settings for users present in a group.`
},
{
    id:12,
    question:'I am done with creating a role. How do I proceed? ',
    ans:`Now all you have to do is go to the user groups tab of the admin section which is present right above the roles tab and then create a new user group. 

    Once that user group is created then you can give that user groups the required Role and add Users to that User Group. `
},
{
    id:13,
    question:'What happens if you do not create a role and directly create a user group? ',
    ans:`You will not be able to create a user group without giving it a role. `
},
{
    id:14,
    question:'How to add your own logo in reports?',
    ans:`To add your own logo in reports  

    1. Go to Company Settings
    2. Go to Company Logo settings.
    3. Upload your own logo (.png/.jpg only).
    4. Uploaded logo would reflect in reports.
    
    Note: Only Superuser is having the permission to view Company Setting section `
},
{
    id:15,
    question:'I want to change the password, how to do that? ',
    ans:`1. Go to My Profile section
         2. At the bottom there is an option to change the password.
         3. Click on that link and you will receive an email to change the password.`
},
{
    id:16,
    question:'Can we send chamber reports to some users periodically ? How to do that? ',
    ans:`Yes, we can. To do so,  
    1. Go to Company Settings.
    2. Go to Periodic Report Settings.
    3. Select the period.
    4. Select the users you wish to send report.
    5. Click on Update All Changes button
   `
},

]

export default FAQ;