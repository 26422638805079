import React from "react";
import { withStyles } from "@mui/styles";
// import Radio from '@mui/material/Radio';
// import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";
import Alert from "@mui/material/Alert";

const styles = {};

const ITEM_HEIGHT = 30;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP,
    },
  },
};

const ToleranceSetting = (props) => {
  const {
    toleranceValueinSeconds,
    handledefaultTolerance,
    immediateAlert,
    handleImmediateAlertChange,
    // toleranceCheckboxValue,
    // handleswitchOptionChange
  } = props;

  const selectOptions = [
    {
      id: 0,
      time: "Disabled",
      seconds: 0,
    },
    {
      id: 900,
      time: "15 minutes",
      seconds: 900,
    },
    {
      id: 1800,
      time: "30 minutes",
      seconds: 1800,
    },
    {
      id: 3600,
      time: "1 hour",
      seconds: 3600,
    },
    {
      id: 7200,
      time: "2 hours",
      seconds: 7200,
    },
    {
      id: 14400,
      time: "4 hours",
      seconds: 14400,
    },
    {
      id: 21600,
      time: "6 hours",
      seconds: 21600,
    },
    {
      id: 28800,
      time: "8 hours",
      seconds: 28800,
    },
    {
      id: 36000,
      time: "10 hours",
      seconds: 36000,
    },
  ];
  const handleChange = (event) => {
    handledefaultTolerance(event.target.value);
  };

  const immediateAlertChange = (event) => {
    handleImmediateAlertChange(event.target.checked? 1 : 0);
  };

  return (
    <div style={{ padding: "0.5rem" }}>
      <Grid container spacing={2} style={{ paddingTop: "0.7rem" }}>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={!!immediateAlert} // Bind this to your state
                onChange={immediateAlertChange} // Update this with your handler
                name="Immediate Alert"
                color="primary"
              />
            }
            label="Immediate Alert:"
            labelPlacement="start"
          />
        </Grid>
        <Grid item xs={12}>
          <Alert severity="info">
            {immediateAlert
              ? "Notifications will be sent as soon as the violation occurs for the device."
              : "Notifications will be sent based on the Sending Interval set for the device from device configuration."}
          </Alert>
        </Grid>

        <Grid item>
          <span style={{ margin: "auto" }}>Time Tolerance:</span>
        </Grid>
        <FormControl
          variant="standard"
          sx={{ m: 1, minWidth: 120, textAlign: "left" }}
        >
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={toleranceValueinSeconds}
            onChange={handleChange}
            // disabled={toleranceCheckboxValue=== 'instant'? true: false}
            MenuProps={MenuProps}
          >
            {selectOptions.map((val) => (
              <MenuItem key={val.id} value={val.id}>
                {val.time}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Alert severity="info">
        Minimum time duration based on the time tolerance that violation
        persists for the device.
      </Alert>
    </div>
  );
};
export default withStyles(styles)(ToleranceSetting);
