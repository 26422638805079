const videos=[
    {
    id:1,
    title:'Dashboard Walk-through',
    src: "./../img/Dashboard.png",
    link:"https://adapt29.trainn.co/share/Mx2jq9dQA3EBp2rknhC4Iw/embed?autoplay=false%22"
},
{
    id:2,
    title:'Chamber Detail Walk-through',
    src: "./../img/Chamber Details.png",
    link:"https://adapt29.trainn.co/share/SnbMZo8R7oOBBbo27VfPsA/embed?autoplay=false%22"
},

{
    id:3,
    title:'Device View Walk-through',
    src: "./../img/Device view.png",
    link:"https://adapt29.trainn.co/share/404YqzuQ25pXTLa9CcnOCA/embed?autoplay=false%22"
},
{
    id:4,
    title:'Reports Calendar View Walk-through',
    src: "./../img/CV.png",
    link:"https://adapt29.trainn.co/share/xbFC4AdqL8OMRr3uZnRpvg/embed?autoplay=false%22"
},
{
    id:5,
    title:'Reports Table View Walk-through',
    src: "./../img/TV.png",
    link:"https://adapt29.trainn.co/share/mWyGKXlBB11WlJG1StT1aA/embed?autoplay=false%22"
},
{
    id:6,
    title:'Event Logs Walk-through',
    src: "./../img/Event Logs.png",
    link:"https://adapt29.trainn.co/share/GHBaNOIB8GqyqTgvDOno7Q/embed?autoplay=false"
},

{
    id:7,
    title:'Asset Group &  Assets Walk-through',
    src: "./../img/Asset Group.png",
    link:"https://adapt29.trainn.co/share/dqw4WNVJhuKPav58SNis6A/embed?autoplay=false%22"
},
{
    id:8,
    title:'Devices Walk-through',
    src: "./../img/Control Panel Devices.png",
    link:"https://adapt29.trainn.co/share/0XpoAT2xKYm9KQqaGyCTzQ/embed?autoplay=false%22"
},
{
    id:9,
    title:'Clients Walk-through',
    src: "./../img/Clients.png",
    link:"https://adapt29.trainn.co/share/exEcWY8xtm6LGAeiigXhWA/embed?autoplay=false%22"
},
{
    id:10,
    title:'User Group & Roles Walk-through',
    src: "./../img/Access Management.png",
    link:"https://adapt29.trainn.co/share/5Eo0sBjdC0g9CYI476BMYA/embed?autoplay=false%22"
},
{
    id:11,
    title:'My Account Walk-through',
    src: "./../img/My Profile.png",
    link:"https://adapt29.trainn.co/share/1pe5FgwQuW2HCBXPsD6VUg/embed?autoplay=false%22"
},
{
    id:12,
    title:'Company Settings Walk-through',
    src: "./../img/Company Settings.png",
    link:"https://adapt29.trainn.co/share/NEJuahGZCbspr1cbp7Rufg/embed?autoplay=false%22"
},
{
    id:13,
    title:'Google Nest Walk-through',
    src: "./../img/Google nest.png",
    link:"https://adapt29.trainn.co/share/1IT4xX5wUOzaHTpq8ZaBbw/embed?autoplay=false"
},


]

export default videos;