/* eslint-disable react/prop-types */
import React from 'react';

import { makeStyles } from '@mui/styles';

import { styled } from '@mui/system';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';

import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';



const useStyles = makeStyles(() => ({
  toggleoption: {
    position: "fixed",
    height: "100vh",

    display: "flex",
    justifyContent: "space-around",
    flexDirection: "column",
    marginBottom: "100px",
    left: "-5rem"
  },
  toggleButton: {

    padding: "20px",
    borderRadius: "30px",
    width: "17rem",
    height: "0.5rem",
    display: "flex",
    transform: "rotate(90deg)",
  },
  '@media screen and (max-width: 1200px)': {
    toggleoption: {
      position: "fixed",
      height: "100vh",

      display: "flex",
      justifyContent: "space-around",
      flexDirection: "column",
      marginBottom: "100px",
      left: "-6rem"
    },
  },
  '@media screen and (max-width: 900px)': {
    toggleoption: {
      display: 'none'
    },
  },
  '@media screen and (max-width: 600px)': {
    toggleoption: {
      display: 'none'
    },
  },
}));



const Tab = styled(TabUnstyled)`
  font-family: Montserrat;
  color: #000;
  cursor: pointer;
  
  font-size:14px;
 
  background-color: transparent;
  width: 100%;
  // padding: 14px 18px;
  
  border: none;
  border-radius: 22px;
  display: flex;
  justify-content: center;

  &:hover {
    // background-color: #fff;
    font-weight:bold;
    color:#000;
    font-size:14px;
    padding: 0.3rem;
  }

  &:focus {
    color: #fff;
    border-radius: 3px;
    padding: 0.3rem;
    
  }

  &.${tabUnstyledClasses.selected} {
    background-color: #fff;
    color: black;
    font-size:14px;
    font-weight:bold;
    font-family:Montserrat;
    padding: 0.3rem;
    border-radius:22px
    
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;


const TabsList = styled(TabsListUnstyled)`
  width: 300px;
  //  height:33px;
  background-color: #A9D0D0;
  border-radius: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3rem
`;




const DashboardOption = (props) => {
  const { toggleoption, viewstate } = props;



  const classes = useStyles();

  const handleChange = (event, nextView) => {
    toggleoption(nextView);
  };

  return (
    <>

      <>
        <div className={classes.toggleoption}

        >



          <div
            className={classes.toggleButton}>
            <TabsUnstyled
              value={viewstate}
              onChange={handleChange}
              selectionFollowsFocus

            >
              <TabsList >
                <Tab value="DashboardView" style={{ transform: "rotate(-180deg)", }} >Dashboard View</Tab>
                <Tab value="DeviceView" style={{ transform: "rotate(-180deg)", }} >Device View</Tab>

              </TabsList>
            </TabsUnstyled>
          </div>


        </div>
      </>



    </>


  );


}
export default DashboardOption;