import React , {useState} from "react";
import { withStyles } from "@mui/styles";
import Grid from '@mui/material/Grid';
import Slider from "react-slick";
import { 
    IconButton,
     Typography,  } from "@mui/material";
import videos from "./videos";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Zoom from '@mui/material/Zoom';
import CloseIcon from '@mui/icons-material/Close';
// import MailOutlineIcon from '@mui/icons-material/MailOutline';
// import CallIcon from '@mui/icons-material/Call';

const styles={
    videoOuterDiv:{
        marginTop:'1rem'
    },
    iframeDiv:{
        borderRadius:'8px', 
        width:'80%', 
        height:'13rem',
        margin:'auto',
        // display:'inline-block'
        border:'1px solid rgba(0,0,0,0.3)',
        "&:hover": {
          boxShadow: "10px 10px 5px -3px rgba(0,0,0,0.2)",
        },
    },
    iframeDiv1:{
        borderRadius:'8px', 
        width:'100%', 
        height:'100%',

    },
    dialogTitle:{
        display:'flex', 
        justifyContent:'space-between'
    },
    icon:{
        cursor:'pointer'
    }
}

// const info=[
//     {
//         icon:<MailOutlineIcon />,
//         text:"info@adaptideations.com"
// },
// {
//     icon:<CallIcon />,
//     text:"info@adaptideations.com"
// },
// {
//     icon:<MailOutlineIcon />,
//     text:"info@adaptideations.com"
// },
// {
//     icon:<MailOutlineIcon />,
//     text:"info@adaptideations.com"
// },


// ]

const Carosel=(props)=>{
const {classes}= props;
    const [openDialog, setOpenDialog]=useState(false);
    const [videoDetails, setVideoDetails]= useState({});

    
      
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
       
        // autoplay: true,
        // autoplaySpeed: 2000,
      };

      const handleOpenDialogClick=(params)=>{
        setOpenDialog(true);
        setVideoDetails(params)
        // const vidId=videoId;

      }

      const handleCloseDialogClick=()=>{
        setOpenDialog(false);
      }

    return(
        <>
       
        <Grid container className={classes.videoOuterDiv}>
    <Grid item xs={9} style={{margin:'auto'}}   >
    <Slider {...settings}>
          
            {
                videos.map((vid)=>(
                    <>
                    <div >
                     <div  >
                     {/* <iframe width="100%" height="100%"
                className={classes.iframeDiv}
                allowFullScreen
               src= {vid.link}
               />  */}
            
            <img  src={vid.src} alt="faq"  className={classes.iframeDiv} 
            onClick={()=>handleOpenDialogClick({title:vid.title,link: vid.link})} 
            />
      
          
          
            <Typography  variant="h6" 
            sx={{fontSize:'16px', 
            fontFamily:'Montserrat',
             color:'rgba(0,0,0,0.7)',
             fontWeight:'bold',
              cursor:'pointer'
              }}
            //    onClick={()=>handleOpenDialogClick({title:vid.title,link: vid.link})} 
               >
               {/* {vid.title} */}
                 </Typography>
            </div>

            </div>
                    </>
                ))
            }
           
       
          </Slider>
    </Grid>
    {/* <Grid item xs={2}   >
    <img  src="./../img/book.png" alt="faq" width="100%" height="100%" />
        </Grid> */}
</Grid>
{/* info  */}

<Grid container>

</Grid>

 <Dialog 
open={openDialog}
TransitionComponent={Zoom}
// onClose={this.onAddDialogClose}
aria-labelledby="form-dialog-title"
maxWidth="lg"
fullWidth
scroll="body"

PaperProps={{
  style: {
    backgroundColor: '#f2f2f2',
  },
}}
>
    <DialogTitle>
        <Grid container className={classes.dialogTitle}>
    <Grid>
    {videoDetails &&  videoDetails.title}
    </Grid>
    <Grid>
        <IconButton onClick={handleCloseDialogClick} className={classes.icon}>
        <CloseIcon  />
        </IconButton>
       
        </Grid>
        </Grid>
       
   
    </DialogTitle>
    <DialogContent  style={{padding:'2rem'}} >
        <Grid container style={{height:'40rem', }}>
            
        <iframe width="100%" height="100%"
            className={classes.iframeDiv1}
          allowFullScreen
           src= {videoDetails &&  videoDetails.link}
           >
           </iframe>
          
        </Grid>
   
    </DialogContent>
</Dialog> 
</>
    )
}

export default withStyles(styles)(Carosel);