/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useContext } from 'react';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import SearchIcon from '@mui/icons-material/Search';
// import NotificationIcon from '@mui/icons-material/NotificationsNone';
import AccountIcon from '@mui/icons-material/AccountCircleRounded';
import Avatar from '@mui/material/Avatar';
import { NavLink } from 'react-router-dom';
import {
  Badge, Tooltip, Divider,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import PropTypes from 'prop-types';
// import ClickAwayListener from '@mui/material/ClickAwayListener';
// import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import axios from "axios";


import {
  usePopupState,
  bindHover,
  bindMenu,
} from 'material-ui-popup-state/hooks';
import UserContext from 'Context/UserContext';
import HoverMenu from 'material-ui-popup-state/HoverMenu';
import Notification from './LiveNotification/Notification';
import CascadingMenuItem from './CascadingMenuItem';


axios.defaults.withCredentials = true;

/*
  @to-do
  1. Introduce gridSpacing as props - this will maintain consistency across the app
  2. The sm, md settings should be taken care by the root app. Not the top bar
*/
const ParentPopupState = React.createContext(null);
const styles = {
  navbarContainer: {
    background: '#fff',
    fontSize: '4rem',

    height: '7rem',
    display: 'flex',
    justifyContent: 'center',
    zIndex: '100',
    position: 'fixed',
    width: '100%',
  },

  navbarLeft: {
    width: '100%',
    height: '100%',
    display: 'flex',
    paddingLeft: '1rem'
  },
  navbarRight: {
    width: '18rem',
    height: '100%',
    display: 'flex',
    // flexDirection: 'row',
    justifyContent: 'flex-end',
    color: '#000',
    fontSize: '14px',
    opacity: '0.78',
    marginLeft: '-7rem',
    marginTop: '1.5rem',

  },
  navbarRight1: {
    width: '200%',
    height: '80%',
    display: 'flex',
    // flexDirection: 'row',
    justifyContent: 'flex-end',
    color: '#000',
    // fontSize: '14px',
    opacity: '0.78',
    // marginLeft: '-0.8rem',
    marginTop: '1.5vh',
  },
  navbarRight2: {
    width: '200%',
    height: '80%',
    display: 'flex',
    // flexDirection: 'row',
    justifyContent: 'flex-end',
    color: '#000',
    // fontSize: '14px',
    opacity: '0.78',
    // marginLeft: '-1rem',
    marginTop: '1.3vh',
  },

  logoWrapper: {
    width: '160px',
    marginRight: '20px',
    display: 'flex',
    alignItems: 'center',
    marginTop: '18px',
    justifyContent: 'center',
  },
  logo: {
    maxWidth: '100%',
    maxHeight: '100%',
  },

  xsLogoWrapper: {
    width: '100%',
    height: '4rem',
    marginRight: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

  },

  navigationMenu: {
    display: 'flex',
    alignItems: 'center',
    '& a': {
      margin: '0px 1em',
      textDecoration: 'none',
      color: '#000',
      fontSize: '14px',

    },
  },

  xsNavigationMenu: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '20px',
    '& a': {
      margin: '20px 10px',
      textDecoration: 'none',
      color: '#3e3e3e',
      width: '100%',
      textAlign: 'center',
    },
  },

  activeLink: {
    color: '#000 !important',
    fontWeight: 'bold',
    position: 'relative'
  },

  xsActiveLink: {
    color: '#000 !important',
    fontWeight: 'bold',
    position: 'relative'
  },

  navItemRight: {
    width: '500px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fcfcfc',
    // marginRight: '0.7em',
    fontSize: '1.2em',
  },
  userProfile: {
    width: '70px',
    display: 'flex',
    alignItems: 'center',
    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
    },
  },
  navbarRightCollapse: {
    display: 'flex',
    alignItems: 'center',
    margin: 'auto',


    // justifyContent:'center'
  },
  showMenuButton: {
    display: 'flex',
    flexDirection: 'column',
    width: '3px',
    height: '27px',
    justifyContent: 'space-around',
    cursor: 'pointer',
  },
  dotButton: {
    height: '4px',
    width: '100%',
    background: '#000',
  },
  burgerIcon: {
    color: '#000',
  },
  sidebarDrawer: {
    display: 'flex',
    flexDirection: 'column',
    width: '250px',
    height: '100vh',
    background: '#fbfbfb',
  },
  imgWrap: {
    width: '40px',
    cursor: 'pointer',
  },
  navLink: {
    textDecoration: 'none',
    color: 'black',
  },
  menuItem: {
    fontSize: '1rem',
    fontWeight: 'bold',
    color: 'black',
  },
  menu: {
    marginTop: '1.6rem',
  },
  accordion: {
    display: 'inline-block',
    top: -10,
    position: 'fixed',
    // marginLeft: '20px',
  },
  closedAccordion: {
    display: 'inline-block',
    top: -10,
    position: 'fixed',
    // marginLeft: '20px',
  },
  extendedIcon: {
    borderRadius: '20px',
  },
  activeNotif: {
    backgroundColor: '#fff!important',
    color: '#FF0000!important',
    boxShadow: '0px 0px 0px 0px #fff!important',
    // marginLeft: '2rem',
    fontSize: '2rem!important',
    cursor: 'pointer',

  },
  notif: {
    color: '#000',
    // fontSize: '1.5rem!important',
    backgroundColor: '#fff!important',
    boxShadow: '0px 0px 0px 0px #fff!important',
    cursor: 'pointer',

  },
  notificationDivforLg: {
    width: '20%',
    marginLeft: '63%',
    top: '3.8rem',
    position: 'fixed',
    opacity: '1',
  },
  notificationDivformd: {
    width: '30%',
    marginLeft: '55%',
    top: '3.8rem',
    position: 'fixed',
    opacity: '1',
  },
  notificationDivforsm: {
    width: '35%',
    marginLeft: '60%',
    top: '3.8rem',
    position: 'fixed',
    opacity: '1',
  },
  notificationDivforxs: {
    width: '60%',
    marginLeft: '40%',
    top: '3.8rem',
    position: 'fixed',
    opacity: '1',
  },

  userNameRole: {
    width: '400px',
    color: 'white',
    fontSize: '14px',
    display: 'flex',
    flexDirection: 'row',
    // justifyItems: 'center',
    // alignItems: 'center',
    // marginTop: '1.2rem',
  },
  avatar1: {
    height: '2px',
    width: '5px'
  },
  moveNotiIcon1: {
    paddingLeft: '3rem'
  },
  moveNotiIcon2: {
    paddingLeft: '0rem'
  }
};


const TopNavbar = (props) => {
  const {
    classes, handleLogout, warningAndViolationData, counter, handleChange, expanded, getvalueofDeviceView
  } = props;
  // console.log('Props', props);
  const [menuEl, setMenuEl] = useState(null); // this is used as an anchor for dropdown of navigation options in case of md and sm devices
  const [showSidebar, setShowSidebar] = useState(false); // used for showing navigation options in case of xs
  const [userMenu, setUserMenu] = useState(false);
  const [deviceType, setDeviceType] = useState('lg')
  // const [expanded, setExpanded] = useState(false);

  const companyDetails = useContext(UserContext);
  const { accountUser, updateSingleChamber } = companyDetails;

  // componentDidMount() {
  //   window.addEventListener('resize', () => {
  //     this.setState({});
  //   });
  // }

  useEffect(() => {
    window.addEventListener('resize', getDeviceType);
  }, []);

  const handleToggle = (event) => {
    setUserMenu((prevState) => {
      setUserMenu(!prevState);
    });
  };

  const handleClose = () => {
    setUserMenu(false);
  };

  const handleShowMenu = (event) => {
    setMenuEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuEl(null);
  };

  const handleSidebarOpen = () => {

    setShowSidebar(true);
    getvalueofDeviceView(true);
  };

  const handleSidebarClose = () => {
    setShowSidebar(false);
    getvalueofDeviceView(false);
  };



  const renderNavigationMenu = (navItems, classes, deviceType) => {

    const navItemsList = navItems.map((navItem) => (
      <NavLink to={`/${navItem.path}`} key={navItem.path} activeClassName={(deviceType === 'xs') ? classes.xsActiveLink : classes.activeLink}>
        {' '}
        {navItem.path === 'controlpanel' ? (<CascadingMenuItem />) : navItem.name}
        {navItem.beta && <span style={{ position: "absolute", fontWeight: "normal", top: -5, right: -10, "font-size": "8px", color: "grey" }}>beta</span>}
        {' '}
      </NavLink>
    ));
    return navItemsList;
  };

  // const handleLogout = () => {
  //   axios.post(`${APIURL}/logout`).then((res) => {
  //     if (res.data.status === 'success') {
  //       window.location = res.data.data.url;
  //     }
  //   });
  // };

  // handleProfileClick = () => {
  //   // console.log('hello profile', window.location.origin);
  //   if (window.location.origin) {
  //     window.location = `${window.location.origin}/myaccount`;
  //   }
  // }

  // const onFullScreenClick = () => {
  //   // console.log('Full screen clicked');
  //   // const { isFullScreen } = this.state;
  //   if (isFullScreen) {
  //     if (document.exitFullscreen) {
  //       document.exitFullscreen();
  //     } else if (document.webkitExitFullscreen) {
  //       document.webkitExitFullscreen();
  //     } else if (document.mozCancelFullScreen) {
  //       document.mozCancelFullScreen();
  //     } else if (document.msExitFullscreen) {
  //       document.msExitFullscreen();
  //     } else {
  //       console.log('Fullscreen API is not supported.');
  //     }
  //   } else {
  //     const ele = document.getElementById('root');
  //     if (ele.requestFullscreen) {
  //       ele.requestFullscreen();
  //     } else if (ele.webkitRequestFullscreen) {
  //       ele.webkitRequestFullscreen();
  //     } else if (ele.mozRequestFullScreen) {
  //       ele.mozRequestFullScreen();
  //     } else if (ele.msRequestFullscreen) {
  //       ele.msRequestFullscreen();
  //     } else {
  //       console.log('Fullscreen API is not supported.');
  //     }
  //   }
  //   this.setState({ isFullScreen: !isFullScreen });
  // };

  const deviceTypes = {
    lg: 'lg',
    md: 'md',
    sm: 'sm',
    xs: 'xs',
  };


  const getUserRole = () => {
    let userRole;
    let company;
    if (accountUser && accountUser.resource_access && accountUser.companyId) {
      const resources = accountUser.resource_access;
      const companyId = accountUser.companyId.map((id) => id);
      const groupAttribute = accountUser.groupAttributes;
      const groupName = Object.keys(groupAttribute);
      const clientArr = [];
      if (groupName && groupName.length > 0) {
        groupName.map((group) => {
          const groupRole = group.split('#');
          groupRole[0] = groupRole[0].substring(accountUser.companyId[0].length + 1);
          groupRole[0] = `${groupRole[0]}[${groupRole[1]}]`;
          clientArr.push(groupRole[0]);
        });
      }
      userRole = resources[companyId.map((id) => id)] ? resources[companyId.map((id) => id)].roles : [];
      return userRole.length ? `[${userRole.map((r) => {
        if (r.startsWith(accountUser.companyId[0])) {
          if ((r.substring(accountUser.companyId[0].length + 1)) === 'default_client') {
            return (clientArr);
          } return (r.substring(accountUser.companyId[0].length + 1));
        }
        return r;
      }).join(',')}]` : 'No Role Assigned';
    }
    userRole = 'No Role Assigned';
    return userRole;
  };


  const getDeviceType = () => {

    const deviceWidth = window.innerWidth;

    // if (deviceWidth >= 1200) return deviceTypes.lg;
    // if (deviceWidth >= 992 && deviceWidth < 1200) return deviceTypes.md;
    // if (deviceWidth >= 768 && deviceWidth < 992) return deviceTypes.sm;
    // return deviceTypes.xs;


    // if(deviceWidth >= 1200){
    //   setDeviceType('lg')
    // }
    // if(deviceWidth >= 768 && deviceWidth < 992 || deviceWidth >= 992 && deviceWidth < 1200){
    //   setDeviceType('sm')
    // }
    // if(deviceWidth <= 767 ){

    //   setDeviceType('xs')
    // }


    if (deviceWidth > 1200) {
      setDeviceType('lg')
    }
    if (deviceWidth >= 900 && deviceWidth < 1200) {
      setDeviceType('md');

    }
    if (deviceWidth >= 600 && deviceWidth < 900) {
      setDeviceType('sm')

    }
    if (deviceWidth < 600) {
      setDeviceType('xs')
    }

  };

  const popupState = usePopupState({
    popupId: 'demoMenu',
    variant: 'popover',
    disableAutoFocus: true,
    deferOpenClose: false,
  });

  useEffect(() => {
    getDeviceType();
  }, []);

  useEffect(() => {

    getDeviceType();
  }, [window.innerWidth])

  // render() {
  // const deviceType = getDeviceType();
  //   // let props = this.props;
  const { initials } = props;
  const { navItems } = props;
  // const { anchorEl } = state;


  return (
    <>

      <div
        className={`${classes.navbarContainer} ${classes.navbarClass ? classes.navbarClass : ''}`}
        style={{ height: classes.height ? classes.height : '4rem' }}>
        <Grid container spacing={0}>
          {/* 1 space  */}
          <Grid item lg={1} md={1} sm={1} xs={1} >
            {/* Side drawer button */}
            {(deviceType === 'sm' || deviceType === 'xs')
              && (
                <div className={classes.navbarLeft}>
                  <div className={classes.navigationMenu}>
                    <IconButton
                      color="inherit"
                      aria-label="Open sidebar"
                      onClick={handleSidebarOpen}
                      className={classes.burgerIcon}
                    >
                      <MenuIcon />
                    </IconButton>
                  </div>
                </div>
              )}
          </Grid>
          <Grid item style={{ textAlign: 'left', fontSize: '30px' }} lg={8} md={8} sm={7} xs={6} >
            {/*  show Adapt Img for lg and md screen */}
            {(deviceType === 'lg' || deviceType === 'md')
              && (
                <div className={classes.navbarLeft}>
                  <div className={classes.logoWrapper}>

                    {/* <a href="https://adaptloggers.com" target="_blank" rel="noreferrer"> */}
                      <img
                        className={classes.logo}
                        // src={classes.logoUrl}
                        src="./../img/adaptlogo.png"
                      // alt="pixel"
                      />
                    {/* </a> */}

                  </div>
                  <div className={classes.navigationMenu}>
                    {renderNavigationMenu(navItems, classes)}
                  </div>
                </div>
              )}



          </Grid>
          <Grid item style={{ fontSize: '30px' }} lg={1} md={1} sm={2} xs={2}>
            {/* For lg device show the emai id  */}
            {(deviceType === 'lg')
              && (
                <>
                  <div>

                    <div className={classes.navbarRight} {...bindHover(popupState)}>
                      {accountUser.preferred_username}
                      @
                      {getUserRole()}

                      <ArrowDropDownIcon />

                    </div>

                  </div>


                  <div>
                    <ParentPopupState.Provider value={popupState}>
                      <HoverMenu
                        {...bindMenu(popupState)}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        className={classes.menu}
                        //                 anchorOrigin={ {vertical: 'bottom', horizontal: 'bottom'} }
                        // transformOrigin={ {vertical: 'top', horizontal: 'left' }}
                        getContentAnchorEl={null}

                      >
                        <MenuItem disabled className={classes.menuItem}>
                          {' '}
                          {accountUser.preferred_username}
                        </MenuItem>
                        <MenuItem disabled className={classes.menuItem}>
                          Role:
                          {' '}
                          {getUserRole()}
                        </MenuItem>
                        <MenuItem disabled className={classes.menuItem}>
                          Company :
                          {' '}
                          {accountUser.companyId}
                        </MenuItem>
                        <Divider />


                        <NavLink
                          to="/myaccount"
                          key="personalSettings"
                          activeClassName="active"
                          className={classes.navLink}
                        >
                          <MenuItem>My Account</MenuItem>

                        </NavLink>


                        {getUserRole().includes('SuperUser') && (
                          <NavLink
                            to="/company"
                            key="company"
                            activeClassName="active"
                            className={classes.navLink}
                          >
                            <MenuItem>Company Settings</MenuItem>
                          </NavLink>
                        )}



                        <NavLink
                          to="/documentation"
                          key="documentation"
                          activeClassName="active"
                          className={classes.navLink}
                        >
                          <MenuItem>Document</MenuItem>

                        </NavLink>

                        <MenuItem onClick={handleLogout}>Logout</MenuItem>


                      </HoverMenu>
                    </ParentPopupState.Provider>
                  </div>
                </>

              )}

            {/* For md and sm show avtar symbol */}
            {(deviceType === 'sm' || deviceType === 'md' || deviceType === 'xs')
              && (
                <div
                  className={deviceType === 'sm' || deviceType === 'md' ? classes.navbarRight1 : classes.navbarRight2}
                >
                  <div className={classes.navbarRightCollapse}>
                    <div className={classes.showMenuButton} onClick={handleShowMenu}  {...bindHover(popupState)}>
                      <Avatar style={{ height: '2rem', width: '2rem' }} ></Avatar>
                    </div>

                    <Menu
                      id="simple-menu"
                      anchorEl={menuEl}
                      getContentAnchorEl={null}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      variant="menu"
                      onClick={handleMenuClose}
                      open={Boolean(menuEl)}
                    >
                      <MenuItem disabled className={classes.menuItem}>
                        {' '}
                        {accountUser.preferred_username}
                      </MenuItem>
                      <MenuItem disabled className={classes.menuItem}>
                        Role:
                        {' '}
                        {getUserRole()}
                      </MenuItem>
                      <MenuItem disabled className={classes.menuItem}>
                        Company :
                        {' '}
                        {accountUser.companyId}
                      </MenuItem>
                      <Divider />


                      <NavLink
                        to="/myaccount"
                        key="personalSettings"
                        activeClassName="active"
                        className={classes.navLink}
                      >
                        <MenuItem>My Account</MenuItem>

                      </NavLink>


                      {getUserRole().includes('SuperUser') && (
                        <NavLink
                          to="/company"
                          key="company"
                          activeClassName="active"
                          className={classes.navLink}
                        >
                          <MenuItem>Company Settings</MenuItem>
                        </NavLink>
                      )}
                      <NavLink
                        to="/documentation"
                        key="documentation"
                        activeClassName="active"
                        className={classes.navLink}
                      >
                        <MenuItem>Document</MenuItem>

                      </NavLink>
                      <MenuItem onClick={handleLogout}>Logout</MenuItem>
                    </Menu>
                  </div>

                </div>
              )}


          </Grid>

          <Grid item
            className={deviceType === 'lg' || deviceType === 'md' || deviceType === 'xs' ? classes.moveNotiIcon1 : classes.moveNotiIcon2}
            lg={1} md={1} sm={2} xs={2}>
            <Grid
              className={expanded ? `${classes.closedAccordion}` : `${classes.accordion}`}
            >
              <Grid>
                <Tooltip title="Open to view violations in Warehouse">
                  <Badge badgeContent={counter} color="primary">
                    <NotificationsActiveIcon
                      style={{ fontSize: '1.5rem', }}
                      className={
                        counter >= 1 > 0
                          ? classes.activeNotif
                          : classes.notif
                      }
                      // className={classes.extendedIcon}
                      onClick={handleChange}
                    />

                  </Badge>
                </Tooltip>
              </Grid>

            </Grid>

          </Grid>






          {/* 1 space  */}
          <Grid style={{ textAlign: 'center', }} item lg={1} md={1} sm={1} xs={1} />


        </Grid>


        {
          deviceType === 'lg' && (
            <Grid className={
              classes.notificationDivforLg
            }>
              {expanded && (
                <Notification
                  expanded={expanded}
                  handleClose={handleChange}
                  warningAndViolationData={warningAndViolationData}
                />
              )}

            </Grid>
          )
        }

        {
          deviceType === 'md' && (
            <Grid className={
              classes.notificationDivformd
            }>
              {expanded && (
                <Notification
                  expanded={expanded}
                  handleClose={handleChange}
                  warningAndViolationData={warningAndViolationData}
                />
              )}

            </Grid>
          )
        }
        {
          deviceType === 'sm' && (
            <Grid className={
              classes.notificationDivforsm
            }>
              {expanded && (
                <Notification
                  expanded={expanded}
                  handleClose={handleChange}
                  warningAndViolationData={warningAndViolationData}
                />
              )}

            </Grid>
          )
        }
        {
          deviceType === 'xs' && (
            <Grid className={
              classes.notificationDivforxs
            }>
              {expanded && (
                <Notification
                  expanded={expanded}
                  handleClose={handleChange}
                  warningAndViolationData={warningAndViolationData}
                />
              )}

            </Grid>
          )
        }







        <Drawer
          anchor="left"
          open={showSidebar}
          onClick={handleSidebarClose}
          onKeyDown={handleSidebarClose}
        >
          <div className={classes.sidebarDrawer}>
            <div className={classes.xsLogoWrapper}>
              <img
                className={classes.logo}
                src="./../img/smallLogo.png"
                alt="Pixel"
              />
            </div>
            <div className={classes.xsNavigationMenu}>
              {renderNavigationMenu(navItems, classes, deviceType)}
            </div>
          </div>
        </Drawer>

      </div>
    </>
  );
  // }
};


export default withStyles(styles)(TopNavbar);

TopNavbar.propTypes = {
  navItems: PropTypes.arrayOf(
    PropTypes.shape(
      {
        path: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      },
    ),
  ).isRequired,
  showSearchbar: PropTypes.bool,
  showNotificationsIcon: PropTypes.bool,
  logoUrl: PropTypes.string,
  userImgSrc: PropTypes.string,
};


TopNavbar.defaultProps = {
  navItems: [
    {
      path: 'navitem1',
      name: 'Nav Item 1',
    },
    {
      path: 'navitem2',
      name: 'Nav Item 2',
    },
    {
      path: 'navitem3',
      name: 'Nav Item 3',
    },
  ],
  showSearchbar: false,
  showNotificationsIcon: false,
  logoUrl: './../img/logo.png',
};




