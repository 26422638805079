import React from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Search } from '@mui/icons-material';
import { Tooltip } from '@mui/material';


const styles = {
  searchInputDiv: {
    // width: '25%',
    minHeight: '34px',
    minWidth: '15%',
    paddingTop: '1rem',
    position: 'relative',
    border:'none'
  },
  searchInput: {
    // border: '1px solid #fff',
    fontFamily:'Montserrat',
    borderRadius: '50px',
    border:'none',
    background: '#ffff',
    width: '100%',
    height: '44px',
    boxSizing: 'border-box',
    padding: '0 15px',
    fontSize:'16px',
    "&:focus": {
      outline: 'none'
      
    },
  },
  searchIcon: {
    position: 'absolute',
    right: '12px',
    top: '28px',
    color: '#6d6d6d',
  },
};

const FAQsearch = (props) => {
  const {
    classes, id, value, onChange, placeholder,Type
  } = props;



 
  
  return (
    <div className={classes.searchInputDiv}>
      <Tooltip title={ Type && Type.length > 53 ? "Search by " + Type + " Id"  : '' } >
        <div>
      <input
        type="string"
       
        id={id}
        value={value}
        onChange={onChange}
        className={classes.searchInput}
        placeholder={placeholder}
      />
      <Search
  
        className={classes.searchIcon}
      />
      </div>
      </Tooltip>
    </div>
  );
};

FAQsearch.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any),
  id: PropTypes.string.isRequired,
  // value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

FAQsearch.defaultProps = {
  classes: {},
  placeholder: 'Search',
};

export default withStyles(styles)(FAQsearch);
