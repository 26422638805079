const APIKEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const APIURL = process.env.REACT_APP_GOOGLE_MAPS_GEOCODING_URL;

export function getAddressfromMap(coordinates) {
    return new Promise((resolve, reject) => {
      const Lat = coordinates && coordinates[1];
      const Long = coordinates && coordinates[0];
      fetch(
        `${APIURL}?latlng=${Lat},${Long}&key=${APIKEY}`,
        )
        .then((response) => response.json())
        .then((sol) => {
          // console.log('gApi',sol )
          const Addressresponse = sol.results[0] && sol.results[0].formatted_address
            ? sol.results[0].formatted_address
            : "N/A";
          resolve(Addressresponse);
        })
        .catch((error) => {
          console.error("Error getting location from map: ", error);
          reject(error);
        });
    });
  }
