import React, { useEffect, useContext } from 'react';
import { withStyles } from '@mui/styles';
import {
  Route, Link, Redirect, Switch, useLocation,
} from 'react-router-dom';
// import Typography from '@mui/material/Typography';
// import Tabs from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';
import { Tab, Tabs, styled } from "@mui/material";
import UserContext from 'Context/UserContext';
import PropTypes from 'prop-types';
import Warehouses from './Warehouses/Warehouses';
import Devices from './Devices/Devices';
import Clients from './Clients/Clients';
import Admin from './Admin/Admin';




const styles = {
  navigationMenu: {
    textDecoration: 'none',
    pointer: 'cursor',
    paddingRight: '15px',
    paddingLeft: '15px',
    paddingBottom: '10px',
    fontSize: '14px',
    fontFamily: 'Montserrat',
    color: 'black',
    marginBottom: '10px',
    
  },
  list: {
    textAlign: 'center',
    display: 'inline-block',
    // marginRight: '15px',
  },
  // activeLink: {
  //   borderBottom: '2px solid blue',
  //   color: 'blue',
  // },
  tabStyle: {
    color: '#000',
    boxShadow: 'none',
  },
};

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "capitalize",
    color: "#000",
    fontWeight: "700",
    fontSize: theme.typography.pxToRem(15),
    fontFamily: "Montserrat",
    marginRight: theme.spacing(1),
  }),
);

function ControlPanel({
  match, pingFromSocket, user,
}) {
  // console.log('props in controlPanel', pingFromSocket);
  const props = {
    pingFromSocket,
    user,

  };

  const location = useLocation();

  // const tabValue = controlPanel.findIndex((a) => a.pathname === location);


  const [value, setValue] = React.useState(0);
  const themeValue = useContext(UserContext);
  const { userPermissions,updateSingleChamber, CompanyInfo  } = themeValue;


  const controlPanel = [
    {
      name: CompanyInfo && CompanyInfo.settings &&   CompanyInfo.settings.warehouseType ? 
      CompanyInfo.settings.warehouseType :
      'Warehouses',
      id: 'assetGroups',
      path: 'assetGroups',
      pathname: '/controlpanel/assetGroups',
      component: (props) => (<Warehouses {...props} />),
    }, {
      name: 'Devices',
      id: 'devices',
      path: 'devices',
      pathname: '/controlpanel/devices',
      component: (props) => (<Devices {...props} />),
    }, {
      name: 'Clients',
      id: 'clients',
      path: 'clients',
      pathname: '/controlpanel/clients',
      component: (props) => (<Clients {...props} />),
    },
    {
      name: 'Admin',
      id: 'admin',
      path: 'admin',
      pathname: '/controlpanel/admin',
      component: () => (<Admin />),
    },
    //audit part
    // {
    //   name: 'Audit',
    //   id: 'audit',
    //   path: 'audit',
    //   pathname: '/controlpanel/audit',
    //   component: () => (<Logs />),
    // },
  ];

  const userControlPanel = controlPanel;


  useEffect(() => {
    if (location === '/controlpanel') {
      setValue(0);
    }
   
    const routeValue = location.pathname.split('/')[2];

    const tabtoShow = controlPanel.find((tab) => tab.path === routeValue);
    setValue(tabtoShow ? tabtoShow.id : 1);
  }, [location.pathname]);


  useEffect(()=>{
    updateSingleChamber(false);
  },[])

  return (

    <div>
      <Switch>
        <Redirect exact from={`${match.path}`} to={`${match.path}/${controlPanel[0].id}`} />
      </Switch>

      <Tabs
        
        style={{ marginTop: "15px" , color:'#000'}}
        variant="scrollable"
        allowScrollButtonsMobile
        textColor="#000"
        value={value}
        TabIndicatorProps={{
          style: {
            backgroundColor: "#000",
            color:'#000'
           
          },
        }}
      >
        {userControlPanel.map(({ name, id }) => {
          if (name &&  (name !== 'Admin' && name !== 'Audit')) {
            return (userPermissions && userPermissions[id] && (userPermissions[id].read || userPermissions[id].create || userPermissions[id].update || userPermissions[id].delete)
              && <StyledTab  key={name} component={Link} label={name} value={id} to={`${match.url}/${id}`} />);
          }
          if (name === 'Admin') {
            return <StyledTab  key={name} component={Link} label="Access Management" value={id} to={`${match.url}/${id}`} />;
          }
          // return (userPermissions && userPermissions.logs && (userPermissions.logs.read || userPermissions.logs.create || userPermissions.logs.update || userPermissions.logs.delete)
          //   && <Tab  key={name}  component={Link} label="Audit" value={id} to={`${match.url}/${id}`} />);
        })}
      </Tabs>

      {userControlPanel.map(({
        id, component, pathname,
      }) => (
        <Route
          key={id}
          path={pathname}
          // path={`${match.path}/${name}`}
          // component={component}
          render={
            (routeProps) => (component({ ...routeProps, ...props }))
          }
        />
      ))}
    </div>

  );
}

ControlPanel.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(ControlPanel);
