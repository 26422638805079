import React from 'react';

import { TextField } from '@mui/material';


const InputForChamber = (props) =>
// const {
//   id, placeholder, onChange,value
// } = props;

(
  <TextField
    {...props}
    fullWidth
    variant="standard"
    // id={id}
    // type="string"
    // onChange={onChange}
    // value={value}
    // placeholder={placeholder}
    InputProps={{
      disableUnderline: true,
      onWheel: (e)=> e.target.blur(),

style: {
  padding: '1rem',
    // width:'100%',
    border: '1px solid #f2f2f2',
      backgroundColor: '#fff',
        height: '2.25rem',
          borderRadius: '22px',
            fontSize: '14px',
              fontFamily: 'Montserrat',
        },
      }}
/>
  );

export default InputForChamber;

