/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import { Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledPrimaryButton = styled(Button)(() => ({
  transition: "0.5s",
  color: "white",
  textTransform: "capitalize",
  fontSize: "1em",
  borderRadius: "16px",
  backgroundColor: "#008081",
  "&:hover": {
    backgroundColor: "#008081",
    backgroundImage: "linear-gradient(to bottom, #02ACAE 50%, #008081 100%)",
  },
}));

function PrimaryButton({
  onClick, disabled, text, type, icon, ...props
}) {
  return (
    <StyledPrimaryButton
      {...props}
      onClick={onClick}
      variant="contained"
      disabled={disabled}
      type={type}
      startIcon={icon}
    >
      <Typography variant="p" component="span" sx={{ fontWeight: "bold" }}>
        {text}
      </Typography>
    </StyledPrimaryButton>
  );
}

export default PrimaryButton;
