import React, {useState, useEffect} from "react";
import { withStyles } from '@mui/styles';
import {Tooltip } from '@mui/material';
import {
  
    Menu,
    MenuItem,
    Checkbox,
    FormControlLabel,
  } from "@mui/material";
  import IconButton from '@mui/material/IconButton';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

const typeOptions = {
    temperature: false,
    humidity: false,
    battery: false,
   
  };

 const styles={
  disableIcon:{
    color: '#c2c2c2',
  },
enableIcon:{
  color:'#008081',
  
},
}

const TypesofFilters=(props)=>{
    const {classes,onDeviceTypeFilterChange, clearCheckbox,selectedRowData}= props
    const [anchorEl, setAnchorEl] = useState(null);
    const [deviceTypesChecked, setDeviceTypesChecked] = useState(typeOptions);


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
      const handleClose = () => {
        setAnchorEl(null);
      };
      const handleChange = (event) => {
       
        setDeviceTypesChecked({
          ...deviceTypesChecked,
          [event.target.name]: event.target.checked,
        });
      };

      useEffect(() => {
        
        onDeviceTypeFilterChange(deviceTypesChecked);
      }, [deviceTypesChecked]);

     
      useEffect(() => {
        setDeviceTypesChecked(typeOptions)
      }, [clearCheckbox]);

      const titleCase=(str)=>{
        return str.toLowerCase().split(' ').map(function(word) {
         return (word.charAt(0).toUpperCase() + word.slice(1));
       }).join(' ');
     }

     
return (
    <>
      <Tooltip  title="Filter alerts" placement="top-end" >
                          <IconButton
                            role="button"
                            tabIndex="0"
                            onClick={handleClick}
                            disabled={(selectedRowData && selectedRowData.length >= 1)}
                          >
                            <FilterAltIcon
                             className={
                              selectedRowData && selectedRowData.length >= 1
                                  ? classes.disableIcon
                                  : classes.enableIcon
                              }
                            
                              />
                           
                          </IconButton>
                          </Tooltip>

                          <Menu
        id="customized-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{ maxHeight: "400px" }}
      >
        {Object.keys(typeOptions).map((type) => (
          <MenuItem key={type}>
            <FormControlLabel
              control={(
                <Checkbox
                style={{color:'#008081'}}
                  name={type}
                  checked={deviceTypesChecked[type]}
                  onChange={handleChange}
                />
              )}
              label={titleCase(type)}
            />
          </MenuItem>
        ))}
      </Menu>

    </>
)
}



export default withStyles(styles)(TypesofFilters);