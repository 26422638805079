import React, { useReducer, useContext, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import API from 'API/apiConfig';
import cogoToast from 'cogo-toast';
import UserContext from 'Context/UserContext';
import UserProfile from './UserProfile';
import Notifications from './Notifications';
import * as yup from 'yup';
// import Configuration from './Configuration';
// import Integration from './Integration';
import toastOptions from 'utils/toast';
import PrimaryButton from 'components/PrimaryButton';
import { useMediaQuery, createTheme } from '@material-ui/core';
const APIURL = process.env.REACT_APP_APIURL;


const UpdateProfileSchema = yup.object().shape({
 
  name: yup
    .string()
    .required(' name cannot be empty'),
  
  // countryCode: yup.string().required('Country code is required '),
  // contactNo: yup.string().required('Please Give mobile number')
});


const styles = {
  // tabText: {
  //   fontSize: '20px',
  //   fontFamily: 'Montserrat',
  //   textTransform: 'capitalize',
  // },
  tabStyle: {
    backgroundColor: 'transparent',
    color: 'Black',
    boxShadow: 'none',
  },
  outerDiv: {
    boxShadow: '0px 0px 16px -5px #898989',
    // width: '34%',
    // minWidth: '200px',
    margin: 'auto',
    padding: '15px',
  },
  heading: {
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    fontSize: '36px',
    marginTop: '2rem',
    textAlign: 'left',
    marginLeft: '2rem',
  },
  subheading: {
    fontSize: '10px',
    fontWeight: 'bold',
    fontFamily: 'Montserrat',
    textAlign: 'left',
    margin: '2rem',
  },
  divider: {
    marginTop: '0.6rem',
    color: '#000',
  },
  updateButton: {
    backgroundColor: '#008081',
    color: 'white',
    borderRadius: '20px',
    font: 500,
    margin: '10px !important',
    padding: '5px 15px',
    minWidth: '99px',
    '&:hover': {
      backgroundColor: '#008081',
    },
  },
};
function TabContainer(props) {
  const { children } = props;
  return (
    <Typography component="div" style={{ padding: 8 * 1 }}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};




const Settings = () => {
  const userDetails = useContext(UserContext);
  const { UserDetails, updatUserProfile , updateSingleChamber,} = userDetails;
//  console.log('context',CompanyInfo )
//  const theme = useTheme();
 const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

 const showImg = useMediaQuery(theme.breakpoints.up('md'));
 const breakPoint= useMediaQuery(theme.breakpoints.up('sm'));

  const settingsReducer = (prevSettingsState, action) => {
   
    switch (action.type) {
      case 'NAME':
        return { ...prevSettingsState, name: action.payload };
      case 'USER_EMAIL': return { ...prevSettingsState, email: action.payload };
      case 'COUNTRY_CODE':
        return { ...prevSettingsState, countryCode: action.payload };
      case 'CONTACT_NUMBER':
        return { ...prevSettingsState, contactNo: action.payload };
      case 'SMS': return { ...prevSettingsState, SMS: action.payload };
      case 'EMAIL': return { ...prevSettingsState, EMAIL: action.payload };
      case 'PUSH': return { ...prevSettingsState, PUSH: action.payload };
      default:
        return { ...prevSettingsState };
    }
  };

 

  const resetPassword = (e) => {
    e.preventDefault();
    API.put(`${APIURL}/users/reset-password`)
      .then((res) => {
        // console.log('res', res);
        if (res.status === 200 && res.data.status === 'success') cogoToast.success('Email successfully sent');
        else cogoToast.error('Error sending email');
      });
  };

  const [settingsState, settingsDispatchFn] = useReducer(settingsReducer, {
    name: '',
    contactNo: '',
    countryCode: '',
    SMS: false,
    EMAIL: false,
    PUSH: false,
  });


  useEffect(()=>{
    updateSingleChamber(false);
  }, [])

  const handleUpdateUserProfile = (event) => {
   
    const {
      name, contactNo, countryCode,
      //  SMS, EMAIL,PUSH
    } = settingsState;
    event.preventDefault();
    const patchArray = [];
  
    if (name !== UserDetails.name) {
      const patchName = {
        op: 'replace',
        path: '/name',
        value: name,
      };
      patchArray.push(patchName);
    }

   
     {
      const patchContactNumber = {
        op: 'replace',
        path: '/contactNo',
        value: {
          countryCode:countryCode ? Number(countryCode) :0,
          number:contactNo ? Number(contactNo) : 0,
        },
      };
      patchArray.push(patchContactNumber);
    }
      
    {
      const patchNotifications = {
        op: 'replace',
        path: '/notificationSettings',
        value: { SMS: settingsState.SMS, email: settingsState.EMAIL, push: settingsState.PUSH },
      };
      patchArray.push(patchNotifications);
    }
 

  UpdateProfileSchema.validate({
    name,
   
    
  })
  .then(()=>{
    if((!countryCode && !!contactNo )|| (!!countryCode && !contactNo) ){
      cogoToast.error('Empty field is mandantory', toastOptions);
    }
   else{


      API.patch(`${APIURL}/users`, patchArray)
      .then((resp) => {
        if (resp.data.status === 'success') {
        
          const responseData = resp.data.data;
         
          // updateUserDetails(responseData);
          updatUserProfile(responseData);
          cogoToast.success(
            'User Profile is updated Successfully',
            toastOptions,
          );
        } else {
          cogoToast.error('User Profile is not updated', toastOptions);
        }
      })
      .catch((err) => {
        cogoToast.error('User Profile is not updated', toastOptions);
        console.error('error patching userProfile got caught', err);
      });
    }
    

  })
  .catch(()=>{
    // console.log('Err', err)
    cogoToast.error('Name cannot be empty', toastOptions);
  })

    
  };


  return (
    
    <>
    <Grid  style={{ textAlign: 'left' }}>
      <Typography
        variant="h4"
        component="div"
        sx={{
          fontFamily: "Montserrat",
          fontWeight: "bold",
          fontSize: "36px",
          // margin: "2rem",
          marginTop:'2rem'
        }}
      >
      My Profile
      </Typography>
      <Grid container style={{ marginTop: '2rem' }}>
        <Grid container item lg={8} md={8} sm={12} xs={12} display="flex" alignItems="center">
          <Grid container xl={12} lg={12} md={12}  sm={12} xs={12} item display="flex" alignItems="center">
            <Grid item xl={3} lg={4} md={5} sm={5} xs={7} >
              <Typography
                variant="h4"
                component="div"
                sx={{
                  fontFamily: "Montserrat",
                  fontWeight: "bold",
                  fontSize: "1.2rem",
                  margin:breakPoint ?  "2rem" : "0.8rem",
                  // margin:"2rem"
                }}
              >
              Basic Details
              </Typography>
            </Grid>
            <Grid item xl={7} lg={6} md={7}  sm={7} xs={5}>
              <Divider fullWidth light={false} style={{color: '#000' }} />
            </Grid>
          </Grid>
          {/* User Profile comp */}
          <Grid container item style={{ 
            marginLeft:breakPoint ?  "3rem" : "0.8rem",
             }}>
            <UserProfile
              settingsState={settingsState}
              settingsDispatchFn={settingsDispatchFn}
              defaultValues={UserDetails}
            />

          </Grid>

          <Grid container xl={12} lg={12} md={12}  sm={12} xs={12}  item display="flex" alignItems="center">
            <Grid item xl={4} lg={5} md={6} sm={6} xs={8}>
              <Typography
                variant="h4"
                component="div"
                sx={{
                  fontFamily: "Montserrat",
                  fontWeight: "bold",
                  fontSize: "1.2rem",
                  margin:breakPoint ?  "2rem" : "0.8rem",
                }}
              >
              Notification Settings
              </Typography>
            </Grid>
            <Grid item xl={6} lg={5} md={6}  sm={6} xs={4}>
              <Divider fullWidth light={false} />
            </Grid>
          </Grid>
          {/* Notification Comp */}
          <Grid container item style={{
             marginLeft:breakPoint ?  "3rem" : "0.8rem",
             }}>

            <Notifications
              settingsState={settingsState}
              settingsDispatchFn={settingsDispatchFn}
            />
          </Grid>

          <Grid
            container
            item
            alignItems="center"
            style={{
              marginTop: '5rem', marginLeft: '2rem', fontSize: '16px', fontFamily: 'Montserrat',
            }}
          >
            <Grid item >
              <Typography variant="p" component="div">
              Want to update password?
             
                {" "}
              <Link href="/resetPassword" onClick={resetPassword}>
                Click Here
                {" "}
              </Link>
              </Typography>
            </Grid>
            {/* <Grid item xl={5} xs={5} justifyContent="flex-end" /> */}
          </Grid>

          <Grid
            container
            item
            alignItems="center"
            style={{
              marginTop: '5rem', 
              marginLeft: '2rem',
               fontSize: '16px', fontFamily: 'Montserrat',
            }}
          >
            <Grid item lg={8} sm={8} xs={7} />
            <Grid item  lg={4} sm={4} xs={5}>
              <PrimaryButton variant="contained" text="Update Changes" onClick={handleUpdateUserProfile}></PrimaryButton>
            </Grid>

          </Grid>

        </Grid>
       {showImg ? 
       <Grid container item lg={4} md={4} alignItems="center">
       <img style={{width:'100%', height:'100%'}} src="/img/myProfile.svg" alt="my-profile" />
     </Grid>
        :
       <div></div>
        } 
      </Grid>
    </Grid>
  </>
 
  );
};


Settings.propTypes = {
  classes: PropTypes.object,
  user: PropTypes.object,
  updateUser: PropTypes.func,
};

Settings.defaultProps = {
  classes: {},
  user: {},
  updateUser: () => {},
};

export default withStyles(styles)(Settings);


