import React, { Component } from 'react';
import update from 'immutability-helper';
import {CircularProgress,Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import Grow from '@mui/material/Grow';
import Zoom from '@mui/material/Zoom';
import * as yup from 'yup';
import axios from 'axios';
import API from 'API/apiConfig';

import DialogActions from '@mui/material/DialogActions';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SearchOption from 'components/SearchOption';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import cogoToast from 'cogo-toast';
import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
// import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import UserContext from 'Context/UserContext';
import Input from '../../../components/Input';
import IconButton from '@mui/material/IconButton';
import Chip from "@mui/material/Chip";
import PrimaryButton from 'components/PrimaryButton';
import SecondaryButton from 'components/SecondaryButton';
import DevicesGrid from './DevicesGrid';
import TypesofFilters from './TypesofFilters';
import BuildCircleIcon from "@mui/icons-material/BuildCircle";
import Config from './Config';

const APIURL = process.env.REACT_APP_APIURL;

axios.defaults.withCredentials = true;

// Creating Schema to validate Adding devices using yup
const AddDevicesSchema = yup.object().shape({
  deviceId: yup.string().required('DEVICE ID cannot be empty'),
  imei: yup
    .string()
    .required('IMEI cannot be empty')
    .min(8, 'IMEI requires minimum of 8 digits'),
});

const styles = {
  topStrip: {
    textAlign: 'right',
    // borderRight: '1px solid white',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    // justifyContent: 'flex-end',
  },
  '@media screen and (max-width: 600px)': {
    topStrip: {
      // textAlign: 'right',
      // borderRight: '1px solid white',
      paddingTop: '1rem',
      paddingBottom: '1rem',
      justifyContent: 'flex-start',
      display: 'flex',
      alignItems: 'center',
    },
  },
  modalDialogOuterDiv: {
    display: 'flex',
    justifyContent: 'center',
  },
  modalDialogDiv: {
    display: 'flex',
    width: '50%',
    flexDirection: 'column',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  errorMessageDiv: {
    MarginTop: '20%',
    height: '20px',
    textAlign: 'left',
  },
  errorMessageText: {
    fontFamily: 'Montserrat',
    fontSize: '16px',
    textAlign: 'left',
    color: 'red',
    marginTop: '15px',
  },
  inputTextField: {
    border: 'none',
    borderBottom: '1px solid grey',
    width: '100%',
    height: '30px',
    outline: 'none',
    '&:focus': {
      borderBottom: '1px solid #00adef',
    },
  },
  inputLabel: {
    opacity: '0.5',
    fontFamily: 'Montserrat',
    fontSize: '12px',
    textAlign: 'left',
    marginTop: '10px',
    color: 'rgba(3, 0, 0, 0.5)',
  },
  warning: {
    color: 'red',
    fontWeight: '500',
  },
  topDiv: {
    width: '100%',
    height: '50px',
    display: 'flex',
    // backgroundColor: '#ffffff',
  },
  deleteDiv: {
    width: '100px',
    borderRight: '1px solid #dedede',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    color: 'red',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  editDiv: {
    // width: '100px',
    // borderRight: '1px solid #dedede',
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    // cursor: 'pointer',
   
    color: '#008081',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  disabledButton: {
    // width: '100px',
    // borderRight: '1px solid #dedede',
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    color: '#c2c2c2',
  },
  disableIcon:{
    color: '#c2c2c2',
  },
enableIcon:{
  color:'#008081',
  
},
  textField: {
    width: '100%',
    backgroundColor: '#fff',

    // height:'20px',
    //     [`& fieldset`]: {
    //       borderBottom:'none',
    //       // borderRadius: 22,
    //       // height:'60px'
    // },

  },
  appBar: {
    position: 'sticky',
  },
  title: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    fontFamily:'Montserrat',
  },
  deletebuttondiv: {
    // borderRight: '1px solid #dedede',
    width: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  editbuttondiv: {
    // borderRight: '1px solid #dedede',
    width: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  chipdiv:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    gap: 6,
  },
  addButton: {
    backgroundColor: '#008081',
    borderRadius: '20px',
    textAlign: 'center',
    color: 'white',
    fontWeight: 'bold',
    // minWidth:'99px',
    '&:hover': {
      backgroundColor: '#008081',
    },
  },
  saveButton: {
    backgroundColor: '#008081',
    color: 'white',
    borderRadius: '20px',
    margin: '10px !important',
    padding: '5px 15px',
    minWidth: '99px',
    '&:hover': {
      backgroundColor: '#008081',
    },
  },
  cancleButton: {
    color: '#008081',
    padding: '5px 15px',
    minWidth: '99px',
    borderRadius: '20px !important',
    border: '2px solid #008081 !important',
  },
  dialogtitle: {
    textAlign: 'left',
    color: '#000',
    fontWeight: 'bold',
    fontSize: '20px',
    fontFamily: 'Montserrat',

  },
  clearSelectionDisabled: {
    display: 'block',
    position: 'relative',
  },
  clearSelectionActive: {
    color: 'red',
    
  },
  filterselected:{
    backgroundColor:'#008081', 
    color:'white'
  },
  filterunselected:{
    backgroundColor:'grey', 
  },
  selectedText:{
    fontSize:'14px'
  }
};
class Devices extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rowData: null,
      openAddDialog: false,
      openDeleteDialog: false,
      openEditDialog: false,
      errorMessage: null,
      selectedRowData: [],
      fullScreen: false,
      loading: true,
      searchInput:'',
      downloadReport:false,
      filters: "",
      lowBattery:false,
      devicestoShowinTable:[],
      isAlertFilterOn:[],
      vFilters:[],
      clearCheckbox:[],
      openDeviceConfigDialog: false,
    
    };
  }


  static contextType = UserContext;

  componentDidMount = () => {
    /**
     * This is used for opening the dialogs in fullscreen mode in case of small screen sizes
     */
    this.getDevices();
    if (window.innerWidth <= 600) {
      this.setState({
        fullScreen: true,
      });
    }
  };

 
  getDevices=()=>{
    API
    .get(`${APIURL}/devices`)
    .then((response)=>{
      if (
        response.data
        && response.data.status
        && response.data.status === 'success'
        ){
          // console.log('getDeviceResponse', response);
          this.setState({
            rowData: response.data.data,
            devicestoShowinTable: response.data.data,
            loading: false,
            });
        }else{
          this.setState({
            loading: false,
            rowData:[]
            });
            cogoToast.error(
            response.data.message ? response.data.message : 'Internal Error',
            { position: 'top-right' },
            
            );
        }
    })
    .catch((error)=>{
      console.log('gettingDevicesError', error);
      this.setState({
      loading: false,
      });
    })
  }

   
 

  onAddButtonClick = () => {
    this.setState({
      openAddDialog: true,
    });
    // console.log('clicked');
  };

  onAddDialogClose = () => {
    this.setState({
      openAddDialog: false,
      errorMessage: null,
     
    });
  };

  onAddDialogSave = (event) => {
    event.preventDefault();
    const newDeviceId = event.target.deviceId.value;
    const newImei = event.target.imei.value;
    const newNote = event.target.note.value;
    const addDeviceAxiosObject = {
      IMEI: newImei,
      id: newDeviceId,
      note: newNote,
    };
    this.setState({
      loading: true,
    });
    AddDevicesSchema.validate({
      deviceId: newDeviceId,
      imei: newImei,
    })
      .then(() => {
        API
          .post(`${APIURL}/devices`, addDeviceAxiosObject)
          .then((addDeviceResponse) => {
            // console.log('addDeviceResponse',addDeviceResponse)
            // let newDeviceObject = { ...addDeviceResponse.data.data[0] };
            if (
              addDeviceResponse.data
              && addDeviceResponse.data.status
              && addDeviceResponse.data.status === 'success'
            ) {
              this.getDevices();
              this.setState({
                openAddDialog: false,
                errorMessage: null,
                loading: false,
                searchInput:''
              });
              cogoToast.success('Added a Device Successfully', {
                position: 'top-right',
              });
            } else {
              // console.error(
              //   addDeviceResponse.data.message
              //     ? addDeviceResponse.data.message
              //     : 'internal Error',
              // );
              cogoToast.error(
                addDeviceResponse.data && addDeviceResponse.data.message
                  ? addDeviceResponse.data.message
                  : 'Failed Adding device to Server',
                {
                  position: 'top-right',
                },
              );
              this.setState({
                loading: false,
              });
            }
          })
          .catch(() => {
            // console.error(error);
            this.setState({
              loading: false,
            });
          });
      })
      .catch((err) => {
        // console.error('err', err.message);
        this.setState({
          errorMessage: err.message,
          loading: false,
        });
      });
  };

  onDeleteButtonClick = () => {
    const { selectedRowData } = this.state;
    if (selectedRowData && selectedRowData.length >= 1) {
      this.setState({
        openDeleteDialog: true,
      });
    } else {
      cogoToast.info('Please Select device(s) to delete them', {
        position: 'top-right',
      });
    }
  };

  // Cancelling the delete action after clicking on delete
  onDeleteDialogClose = () => {
    this.setState({
      openDeleteDialog: false,
      selectedRowData: []
    });
  };

  onDeleteDialogSave = () => {
    this.setState({
      loading: true,
    });
    const { selectedRowData } = this.state;
    const selectedRowsUidsArray = selectedRowData.map((item) => item.uid);
    // console.log('item.uid', selectedRowData);
    let toDeleteDevices = true;
    selectedRowData.forEach((device) => {
      if (device.paired) {
        toDeleteDevices = false;
      }
    });
    // console.log('Delete device',selectedRowsUidsArray )
    if (toDeleteDevices) {
      API
        .delete(`${APIURL}/devices`, {
          params: { uids: selectedRowsUidsArray },
        })
        .then((deleteResponse) => {
          // console.log('deleteResponse', deleteResponse);
          if (
            deleteResponse.data
            && deleteResponse.data.status
            && deleteResponse.data.status === 'success'
          ) {
            this.getDevices();
            this.setState({
              openDeleteDialog: false,
              selectedRowData: null,
              loading: false,
              searchInput:'',

            });
            cogoToast.success('Deleted the selected devices successfully', {
              position: 'top-right',
            });
          } else {
            cogoToast.error('Unable to delete device', { position: 'top-right' });
            this.setState({
              loading: false,
            });
            // console.error('deleteResponse fail Message', deleteResponse);
          }
        })
        .catch((deleteError) => {
          cogoToast.error(deleteError, { position: 'top-right' });
          this.setState({
            loading: false,
            
          });
          // console.error(deleteError);
        });
    } else {
      const deleteError = 'Please make sure that the selected devices are removed from chambers before deleting';
      cogoToast.error(deleteError, { position: 'top-right' });
      this.setState({
        loading: false,
        openDeleteDialog: false,
      });
    }
  };

  onEditButtonClick = () => {
    const { selectedRowData } = this.state;
    if (selectedRowData && selectedRowData.length === 1) {
      this.setState({
        openEditDialog: true,
        selectedRowData,
      });
    } else {
      cogoToast.info('Select a single row of Device to edit or View info', {
        position: 'top-right',
      });
    }
  };

  onEditDialogClose = () => {
    this.setState({
      openEditDialog: false,
      selectedRowData: []
    });
  };

  onEditDialogSave = (event) => {
    event.preventDefault();
    const { selectedRowData } = this.state;
    if (event.target.note.value !== selectedRowData[0].note) {
      const editDeviceAxiosElement = [{
        path: '/note',
        // op: event.target.note.value && event.target.note.value !== null && event.target.note.value === selectedRowData[0].note ? 'replace' : 'remove',
        op: 'replace',
        value: event.target.note.value,
      }];
      this.setState({
        loading: true,
      });
      API
        .patch(`${APIURL}/devices/${selectedRowData[0].uid}`, editDeviceAxiosElement)
        .then((response) => {
          // console.log('edit response', response);
          if (response && response.data && response.data.status && response.data.status === 'success') {
            this.getDevices();
            this.setState({
              openEditDialog: false,
              selectedRowData: [],
              loading: false,
              searchInput:''
            });
            cogoToast.success('Edited the selected device successfully', { position: 'top-right' });
          } else {
            cogoToast.error(
              response && response.data && response.data.message
                ? response.data.message
                : 'Unable to edit the selected device',
              { position: 'top-right' },
            );
            this.setState({
              loading: false,
            });
          }
        })
        .catch(() => {
          // console.error('editingDeviceError', err);
          this.setState({
            loading: false,
          });
        });
    } else {
      this.setState({
        openEditDialog: false,
        loading: false,
      });
    }
  };

  onReportdownload=()=>{
    const{ downloadReport}= this.state
 this.setState({
  downloadReport:!downloadReport
 })
  
  
  }

  toggleFilter = (filterName) => {
    const { filters } = this.state;
    if (filterName === filters) {
      this.setState((prevState) => ({
        filters: !prevState.filters,
      }));
    } else {
      this.setState({
        filters: filterName,
      });
    }
  };

  toggleFilterforBattery=()=>{
    this.setState((prevState) => ({
      lowBattery: !prevState.lowBattery,
    }));
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.filters !== prevState.filters || this.state.vFilters !== prevState.vFilters  ) {
      this.applyFilters()
    }
  }

 
  
 
  isViolatedOrWarned = (violationsOrWarnings, type)=>{
    return violationsOrWarnings && violationsOrWarnings[type] > 0;
}

   //to filter violations
   tempFilterDevices=(allDevices, filterArray)=>{
    if(filterArray && filterArray.length){
      return allDevices.filter(dev => {
          return filterArray.some(type=>{
            // return this.isViolatedOrWarned(device.violations, type,) || this.isViolatedOrWarned(device.warnings, type, )

            if(type === "temperature"){
              return ( Number(dev.lastPing &&  dev.lastPing.t1 &&  dev.lastPing.t1[dev.lastPing.t1.length - 1]) > Number(dev.assetTemperatureSettings && dev.assetTemperatureSettings.maxViolation)) 
               || (Number(dev.lastPing &&  dev.lastPing.t1 &&  dev.lastPing.t1[dev.lastPing.t1.length - 1]) < Number(dev.assetTemperatureSettings && dev.assetTemperatureSettings.minViolation)) 
               ||  ( Number(dev.lastPing &&  dev.lastPing.t1 &&  dev.lastPing.t1[dev.lastPing.t1.length - 1]) > Number(dev.assetTemperatureSettings && dev.assetTemperatureSettings.maxWarning)) 
               ||  ( Number(dev.lastPing &&  dev.lastPing.t1 &&  dev.lastPing.t1[dev.lastPing.t1.length - 1]) < Number(dev.assetTemperatureSettings && dev.assetTemperatureSettings.minWarning))
            }
            if(type === "humidity"){
              return ( Number(dev.lastPing &&  dev.lastPing.humidity &&  dev.lastPing.humidity[dev.lastPing.humidity.length - 1]) > Number(dev.assetHumiditySettings && dev.assetHumiditySettings.maxViolation)) 
           || (Number(dev.lastPing &&  dev.lastPing.humidity &&  dev.lastPing.humidity[dev.lastPing.humidity.length - 1]) < Number(dev.assetHumiditySettings && dev.assetHumiditySettings.minViolation))
           ||  ( Number(dev.lastPing &&  dev.lastPing.humidity &&  dev.lastPing.humidity[dev.lastPing.humidity.length - 1]) > Number(dev.assetHumiditySettings && dev.assetHumiditySettings.maxWarning)) 
           || (Number(dev.lastPing &&  dev.lastPing.humidity &&  dev.lastPing.humidity[dev.lastPing.humidity.length - 1]) < Number(dev.assetHumiditySettings && dev.assetHumiditySettings.minWarning))
            }
            if( type === "battery"){
             return Number(Math.round(dev.lastPing && dev.lastPing.battery)) < Number(dev.assetBatterySettings && dev.assetBatterySettings.critical)
            || Number(Math.round(dev.lastPing && dev.lastPing.battery)) < Number(dev.assetBatterySettings && dev.assetBatterySettings.warning)
            || Number(Math.round(dev.lastPing && dev.lastPing.battery)) <  25
         
            }
          })
          
    
      })
    }
   }
  

   //to filter chips 
   tempFilterChips=(devices, status)=>{
      const {vFilters}= this.state;
      const isViolationFiltered = vFilters && vFilters.length
      if(status === 'paired'){
        return devices.filter((e) => ( e.paired));  
      }else if(status !== 'paired'){
        if(isViolationFiltered){
          return []
        }else{
          return devices.filter((e) => (!e.paired));
        }
      }else{
        return devices;
      }
   }

   handleSlectedFilter=(selectedAlerts)=>{
    
    const filtersArr = Object.keys( selectedAlerts);
    const trueFilters = filtersArr.filter((filter) => selectedAlerts[filter] === true);
    this.setState({
      vFilters:trueFilters
    })
   
    
   }

   applyFilters= ()=>{
    const {vFilters, rowData, filters}= this.state;
    let devices = rowData;
    if(vFilters && vFilters.length){
      devices = this.tempFilterDevices(devices, vFilters);
    }
    if(filters && filters.length){
      devices = this.tempFilterChips(devices, filters);
    }
   
    // update the selected devices state
    this.setState({
      devicestoShowinTable:devices,
      selectedRowData:[]
    })
   }

  onSearchChange=(event)=>{

    this.setState({
      searchInput:event.target.value
    })
  }

   handleSelectionClear = () => {
    this.setState({
      selectedRowData:[]
    })
  };

  handleclearCheckbox=()=>{
    const {vFilters}= this.state;
    
   this.setState({
    // vFilters:[]
    clearCheckbox:vFilters
   })
  }

  onSelectionChanged = (selectedRows) => {
    
    this.setState({
      selectedRowData:selectedRows
    })
    // setPermissions(permissions);
  };

  updateLatestPing = (ping) => {
    const { rowData } = this.state;
    if (ping !== null && ping.IMEI && ping.timeStamp) {
      const rowDataOfIMEI = rowData.map((e) => e.IMEI);
      const index = rowDataOfIMEI.indexOf(ping.IMEI);
      // console.log('in update function index', index);
      if (index !== -1) {
        // console.log('index is  not -1');
        if (rowData[index].lastPing && rowData[index].lastPing.timeStamp && ping.timeStamp !== rowData[index].lastPing.timeStamp) {
          const newState = update(this.state, { rowData: { [index]: { lastPing: { $set: ping } } } });
          this.setState(newState);
        }
        if (!rowData[index].lastPing) {
          const newState = update(this.state, { rowData: { [index]: { lastPing: { $set: ping } } } });
          this.setState(newState);
        }
      }
    }
    // console.log('updating control panel ping data', ping);
    return false;
  };

  routeToDashboard=() => {
    const { history } = this.props;
    history.push('/dashboard');
    cogoToast.error('You do not have access to device page, please contact your admin', { position: 'top-right' });
  }

  shouldComponentUpdate = (nextProps) => {
    const { pingFromSocket } = this.props;
    // console.log('in should component update');
    if (nextProps.pingFromSocket && nextProps.pingFromSocket !== pingFromSocket) {
      this.updateLatestPing(nextProps.pingFromSocket);
    }
    return true;
  }

  onDeviceConfigDialogOpen=()=>{
  
    this.setState({
      openDeviceConfigDialog:true
    })
   
   
  }
 
  onDeviceConfigDialogClose=()=>{
    this.setState({
      openDeviceConfigDialog:false
    })
  }

  render() {
    const {
      // rowData,
      filters,
      openAddDialog,
      errorMessage,
      openDeleteDialog,
      openEditDialog,
      selectedRowData,
      fullScreen,
      loading,
      searchInput,
      downloadReport,
      // lowBattery,
      devicestoShowinTable,
      vFilters,
      clearCheckbox,
      openDeviceConfigDialog
    } = this.state;
    const { classes } = this.props;
    const { userPermissions } = this.context;

    
    return (
      <>
        {loading ? (
          <div className={classes.overlay}>
            <CircularProgress
              style={{ position: 'absolute', top: '50%', left: '50%' }}
            />
          </div>
        ) : (
          (userPermissions && userPermissions.devices && userPermissions.devices.read)

            ? (
              <Zoom in>
                <div>
                  <Grid container spacing={0} className={classes.topStrip}>
                    <Grid item md={12} sm={12} xs>
                      {/* BreadCrumbs with left float in classes */}
                      <PrimaryButton
                        text="Add Device"
                        onClick={this.onAddButtonClick}
                    // disabled={userPermissions && userPermissions.devices && !(userPermissions.devices.create)}
                        disabled={userPermissions && userPermissions.devices && !(userPermissions.devices.create)}
                      />
              
                    </Grid>
                  </Grid>


              <Grid container spacing={0}  justifyContent="center" style={{paddingBottom:'5px'}}>
                <Grid item xl={7} lg={7} md={12} sm={12} xs={12} style={{textAlign:'left', display:'flex'}} >
                <div className={classes.deletebuttondiv}>
                          <Tooltip title="Delete" placement="top-end">
                          <IconButton
                      //   className={userPermissions &&  userPermissions.devices &&  userPermissions.devices.delete
                      // && selectedRowData && selectedRowData.length >= 1
                      //     ? classes.deleteDiv
                      //     : classes.disabledButton}
                            onClick={this.onDeleteButtonClick}
                            onKeyPress={this.onDeleteButtonClick}
                            role="button"
                            tabIndex="0"
                            color="error"
                            disabled={!(userPermissions && userPermissions.devices && userPermissions.devices.delete
                      && selectedRowData && selectedRowData.length >= 1)}
                          >
                            <Delete />
                            {/* <span className={classes.buttonText}>Delete</span> */}
                          </IconButton>
                          </Tooltip>
                        </div>
                        <div className={classes.editbuttondiv}>
                          <Tooltip  title="Edit" placement="top-end" >
                          <IconButton
                          
                            onClick={this.onEditButtonClick}
                            onKeyPress={this.onEditButtonClick}
                            role="button"
                            tabIndex="0"
                        // color="primary"
                            disabled={!(userPermissions && userPermissions.devices && userPermissions.devices.update
                    && selectedRowData && selectedRowData.length >= 1)}
                          >
                            <Edit   className={userPermissions && userPermissions.devices && userPermissions.devices.update
                    && selectedRowData && selectedRowData.length >= 1
                              ? classes.editDiv
                              : classes.disabledButton} />
                            {/* <span className={classes.buttonText}>Edit</span> */}
                          </IconButton>
                          </Tooltip>
                        </div>
                        <div className={classes.editbuttondiv}>
                        <Tooltip  title="Configure" placement="top-end" >
                          <IconButton
                             disabled={
                              !(userPermissions && userPermissions.devices && userPermissions.devices.update
                                && selectedRowData && selectedRowData.length === 1)
                            }
                             onClick={this.onDeviceConfigDialogOpen}
                            // onKeyPress={this.onReportdownload}
                            role="button"
                            tabIndex="0"
                          >
                            <BuildCircleIcon
                            
                             className={
                              userPermissions.devices.update
                              && selectedRowData && selectedRowData.length === 1
                                ? classes.editDiv
                                : classes.disabledButton
                            }
                            //  style={{color:'#008081'}}
                              />
                           
                          </IconButton>
                          </Tooltip>
                        </div>
                        <div className={classes.editbuttondiv}>
                        <Tooltip  title="Download device report" placement="top-end" >
                          <IconButton
                            onClick={this.onReportdownload}
                            onKeyPress={this.onReportdownload}
                            role="button"
                            tabIndex="0"
                            disabled={(selectedRowData && selectedRowData.length >= 1)}
                          >
                            
                            <FileDownloadOutlinedIcon
                               className={
                              selectedRowData && selectedRowData.length >= 1
                                  ? classes.disableIcon
                                  : classes.enableIcon
                              }
                             sx={{fontSize:'1.8rem' }} 
                              />
                          </IconButton>
                          </Tooltip>
                          </div>
                         
                              <div className={classes.editbuttondiv}>
                            
                              <TypesofFilters 
                              onDeviceTypeFilterChange={this.handleSlectedFilter}
                             //  handleclearCheckbox={this.handleclearCheckbox}
                              clearCheckbox={clearCheckbox}
                              selectedRowData={selectedRowData}
                              />
                               </div>
                          
                         
                         

                         {
                          !fullScreen && (
                            <div  className={classes.chipdiv}>
                          <Chip
                          label="Paired Devices"
                          // color={
                          //   filters && filters === "paired"
                          //     ? "primary"
                          //     : "default"
                          // }
                          // className={filters && filters === "paired" ? classes.filterselected : classes.filterunselected}
                        sx={{backgroundColor:filters && filters === "paired" ? '#008081': "grey",
                        color:filters && filters === "paired" ? '#fff': "#000",
                        "&:hover": {
                         backgroundColor:filters && filters === "paired" ? '#008081': "grey"
                        },
                      }}
                          onClick={() => this.toggleFilter("paired")}
                          clickable
                        
                        />
                        <Chip
                          label="Unpaired Devices"
                          sx={{backgroundColor:filters && filters === "unpaired" ? '#008081': "grey",
                          color:filters && filters === "unpaired" ? '#fff': "#000",
                        "&:hover": {
                         backgroundColor:filters && filters === "unpaired" ? '#008081': "grey"
                        },
                      }}
                          onClick={() => this.toggleFilter("unpaired")}
                          clickable
                         
                        />
                        { 
                        vFilters && vFilters.length ? 
                        <>
                        <span className={classes.selectedText} > Selected filter(s): {vFilters && vFilters.length}</span>
                        <Tooltip title="Clear Selection">
                        <IconButton
                          onClick={this.handleclearCheckbox}

                        >
                          <HighlightOffIcon
                             className={
                              vFilters && vFilters.length > 0
                                ? classes.clearSelectionActive
                                : classes.clearSelectionDisabled
                            } 
                          />
                        </IconButton>
                      </Tooltip>
                      </>

                      : "" }
                       

                         {/* <Chip
                          label="Low Battery Devices"
                          sx={{backgroundColor:lowBattery ? '#008081': "grey",
                          color:lowBattery ? '#fff': "#000",
                        "&:hover": {
                         backgroundColor:lowBattery ? '#008081': "grey"
                        },
                      }}
                          onClick={() => this.toggleFilterforBattery("lowBattery")}
                          clickable
                         
                        />
                         */}

                            </div>
                          )
                         } 


                </Grid>
                {
                          fullScreen && (
                            <Grid  xs={12} style={{display:'flex', justifyContent:'left'}}>
                            <div  className={classes.chipdiv}>
                          <Chip
                          label="Paired Devices"
                          // color={
                          //   filters && filters === "paired"
                          //     ? "primary"
                          //     : "default"
                          // }
                          // className={filters && filters === "paired" ? classes.filterselected : classes.filterunselected}
                        sx={{backgroundColor:filters && filters === "paired" ? '#008081': "grey",
                        color:filters && filters === "paired" ? '#fff': "#000",
                        "&:hover": {
                         backgroundColor:filters && filters === "paired" ? '#008081': "grey"
                        },
                      }}
                          onClick={() => this.toggleFilter("paired")}
                          clickable
                        
                        />
                        <Chip
                          label="Unpaired Devices"
                          sx={{backgroundColor:filters && filters === "unpaired" ? '#008081': "grey",
                          color:filters && filters === "unpaired" ? '#fff': "#000",
                        "&:hover": {
                         backgroundColor:filters && filters === "unpaired" ? '#008081': "grey"
                        },
                      }}
                          onClick={() => this.toggleFilter("unpaired")}
                          clickable
                         
                        />
                        { 
                        vFilters && vFilters.length ? 
                        <>
                        <span className={classes.selectedText} > Selected filter(s): {vFilters && vFilters.length}</span>
                        <Tooltip title="Clear Selection">
                        <IconButton
                          onClick={this.handleclearCheckbox}

                        >
                          <HighlightOffIcon
                             className={
                              vFilters && vFilters.length > 0
                                ? classes.clearSelectionActive
                                : classes.clearSelectionDisabled
                            } 
                          />
                        </IconButton>
                      </Tooltip>
                      </>

                      : "" }
                       

                        

                            </div>
                            </Grid>
                          )
                         } 
                <Grid item xl={3} lg={2} md={8} sm={7} xs={12}>
                <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              alignItems: 'center',
                              flexGrow: 1,
                         
                            }}
                          >
                            <Tooltip title="use 'shift' or 'ctrl' for multi-select">
                              {/* <IconButton> */}
                              <div >
                                <span className={classes.selectedText} >Selected : </span>
                                <span className={classes.Selected}>
                                  {selectedRowData && selectedRowData.length}
                                </span>
                              </div>
                              {/* </IconButton> */}
                            </Tooltip>
                            <Tooltip title="Clear Selection">
                              <IconButton
                                onClick={this.handleSelectionClear}
                           // onClick={selectionClear}
                             
                              >
                                <HighlightOffIcon
                                   className={
                                    selectedRowData && selectedRowData.length > 0
                                      ? classes.clearSelectionActive
                                      : classes.clearSelectionDisabled
                                  } 
                                />
                              </IconButton>
                            </Tooltip>
                          </div>
                </Grid>
                <Grid item xl={2} lg={3} md={4} sm={5} xs={12}>
                <SearchOption
                          variant="standard"
                            id="userSearchInput"
                            value={searchInput}
                            onChange={this.onSearchChange}
                            placeholder="Search by device Id"
                          />
                </Grid>

              </Grid>
              <DevicesGrid
                        rowData={devicestoShowinTable}
                        vFilters={vFilters}
                        filters={filters}
                        // rowData={this.filterDevices()}
                        // rowData={devicestoShowinTable}
                        // onGridReady={this.onGridReady}
                        searchvalue={searchInput}
                        selectedRowData={selectedRowData}
                        downloadReport={downloadReport}
                        onSelectionChanged={this.onSelectionChanged}
                       
                      />

                  <Dialog
                    open={openAddDialog}
                    TransitionComponent={Zoom}
                    onClose={this.onAddDialogClose}
                    aria-labelledby="form-dialog-title"
                    maxWidth="xs"
                    fullWidth
                    scroll="body"
                    fullScreen={fullScreen}
                    PaperProps={{
                      style: {
                        backgroundColor: '#f2f2f2',
                      },
                    }}
                  >
                    <Grow in={openAddDialog}>
                      <form onSubmit={this.onAddDialogSave}>
                        {fullScreen && (
                        <AppBar className={classes.appBar}>
                          <Toolbar style={{backgroundColor:'#008081'}}>
                            <IconButton
                edge="start"
                color="inherit"
                onClick={this.onAddDialogClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
                            <Typography variant="h6" className={classes.title}>
                        Add Device
              </Typography>
                            <Button color="inherit" type="submit">
                        save
              </Button>
                          </Toolbar>
                        </AppBar>
                        )}
                        {!fullScreen && (
                        <DialogTitle
                          id="form-dialog-title"
                          className={classes.dialogtitle}
                        >
                    Add Device
                        </DialogTitle>
                        )}
                        <DialogContent >
                          <Grid container style={{paddingTop: fullScreen ? '4rem' : '0rem'}} spacing={2}>
                            <Grid item xs={12} >
                              <Input
                              // required
                  id="deviceId"
                  placeholder="Device ID"
                  backgroundColor="#fff"
                />

                            </Grid>
                            <Grid item xs={12} >
                             
                              <Input
                                // required
                  id="imei"
                            // label="IMEI"
                  placeholder="IMEI"
                  // className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  backgroundColor="#fff"
                />
                            </Grid>
                            <Grid item xs={12}>
                              <Input
                                // required
                  id="note"
                  backgroundColor="#fff"
                            // label="Note"
                  placeholder="Note"
                  // className={classes.textField}
                  margin="normal"
                  variant="outlined"
                />
                            </Grid>
                            <div style={{ marginTop: '10px', paddingTop: '10px' }}>
                              {errorMessage && errorMessage !== null ? (
                  <div className={classes.errorMessageDiv}>
                                <span className={classes.errorMessageText}>
                            *
                                {errorMessage}
                              </span>
                              </div>
                ) : null}
                            </div>
                          </Grid>
                        </DialogContent>
                        
                        
                        {!fullScreen && (
                        <DialogActions>
                          <SecondaryButton  text="Cancel" onClick={this.onAddDialogClose} />
                    
                          <PrimaryButton text="Save" type="submit" className={classes.saveButton} variant="contained" />
                   
                        </DialogActions>
                        )}
                      </form>
                    </Grow>
                  </Dialog>

                  <Dialog
                    open={openDeleteDialog}
                    TransitionComponent={Zoom}
                    onClose={this.onDeleteDialogClose}
                    aria-labelledby="form-dialog-title"
                    maxWidth="sm"
                    scroll="body"
                  >
                    <DialogTitle id="form-dialog-title">
                      <span className={classes.warning}>Warning</span>
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                Are you sure you want to delete the selected Devices ?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <SecondaryButton text="No" onClick={this.onDeleteDialogClose}  />
              
                      <PrimaryButton  text="Yes" onClick={this.onDeleteDialogSave} />
               
                    </DialogActions>
                  </Dialog>

                  <Dialog
                    open={openEditDialog}
                    TransitionComponent={Zoom}
                    onClose={this.onAddDialogClose}
                    aria-labelledby="form-dialog-title"
                    maxWidth="xs"
                    fullWidth
                    scroll="body"
                    fullScreen={fullScreen}
                    PaperProps={{
                      style: {
                        backgroundColor: '#f2f2f2',
                      },
                    }}

                  >
                    <Grow in={openEditDialog}>
                      <form onSubmit={this.onEditDialogSave}>
                        {fullScreen && (
                        <AppBar className={classes.appBar}>
                          <Toolbar style={{backgroundColor:'#008081'}}>
                            <IconButton
                edge="start"
                color="inherit"
                onClick={this.onEditDialogClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
                            <Typography variant="h6" className={classes.title}>
                        Edit Device
              </Typography>
                            <Button color="inherit" type="submit">
                        update
              </Button>
                          </Toolbar>
                        </AppBar>
                        )}
                        {!fullScreen && (
                        <DialogTitle
                          id="form-dialog-title"
                          className={classes.dialogtitle}
                        >
                    Edit Device
                        </DialogTitle>
                        )}
                        <DialogContent>
                          <Grid container style={{paddingTop: fullScreen ? '4rem' : '0rem'}} spacing={2}>
                            <Grid item xs={12}>
                              <Input
                  id="deviceId"
                  placeholder="Device ID"
                  backgroundColor="#fff"
                  value={
                          selectedRowData && selectedRowData.length === 1 && selectedRowData[0].id
                            ? selectedRowData[0].id
                            : ''
                        }
                  disabled
                  // className={classes.textField}
                  margin="normal"
                />
                            </Grid>
                            <Grid item xs={12}>
                              <Input
                  id="imei"
                  backgroundColor="#fff"
                  placeholder="IMEI"
                  
                  value={
                          selectedRowData && selectedRowData.length === 1
                            ? selectedRowData[0].IMEI
                            : ''
                        }
                  disabled
                 
                  margin="normal"
                />
                            </Grid>
                            <Grid item xs={12}>
                              <Input
                  id="note"
                  backgroundColor="#fff"
                  placeholder="Note"
                  defaultValue={
                          selectedRowData && selectedRowData.length === 1 && selectedRowData[0].note && selectedRowData[0].note !== 'null'
                            ? selectedRowData[0].note
                            : ''
                        }
                
                  margin="normal"
                />
                            </Grid>
                            <div style={{ marginTop: '10px', paddingTop: '10px' }}>
                              {errorMessage && errorMessage !== null ? (
                  <div className={classes.errorMessageDiv}>
                                <span className={classes.errorMessageText}>
                            *
                                {errorMessage}
                              </span>
                              </div>
                ) : null}
                            </div>
                          </Grid>
                        </DialogContent>
                        {!fullScreen && (
                        <DialogActions>
                          <SecondaryButton  text="Cancel" onClick={this.onEditDialogClose} />
                   
                          <PrimaryButton  text="Save" type="submit" className={classes.saveButton} variant="contained" />
                     
                        </DialogActions>
                        )}
                      </form>
                    </Grow>
                  </Dialog>

                </div>
              </Zoom>
            )
            : (
              this.routeToDashboard()
            )


        )}

            {openDeviceConfigDialog && (
            <Config 
            openDeviceConfigDialog={openDeviceConfigDialog} 
            selectedRowData={selectedRowData}
            onDeviceConfigDialogClose={this.onDeviceConfigDialogClose}
             />
            )
             
            }
      </>
    );
  }
}

Devices.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(Devices);













 