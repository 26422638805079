/* eslint-disable no-unused-vars */
/* eslint-disable no-prototype-builtins */
/* eslint-disable react/prop-types */
import React, { Component, useContext, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { useMediaQuery, createTheme } from '@material-ui/core';
import update from 'immutability-helper';
import CircularProgress from '@mui/material/CircularProgress';
import { withStyles } from '@mui/styles';
import cogoToast from 'cogo-toast';
import axios from 'axios';
import API from 'API/apiConfig';
import UserContext from 'Context/UserContext';
import DeviceView from './DeviceView';
import MainPage from './MainPage';
import SingleChamber from './SingleChamber';
import Grid from "@mui/material/Grid";
import { styled } from '@mui/system';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import DateAndTimeRangePicker from 'components/DateAndTimeRangePicker';
import SearchInput from "../../components/SearchBar";
// import Search from 'components/Search';
import DetailNew from './LiveDataNew';
import Detail from './LiveData';
import Summary from './Summary';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Chip, IconButton, Tooltip, } from "@mui/material";
import { useHistory } from "react-router-dom";
import GlobalDownload from './GlobalDownload';
import UpdateIcon from '@mui/icons-material/Update';
import TextField from '@mui/material/TextField';
import AutoField from "components/AutoField";
import './autocomplete.css';
// import Autocomplete from "@mui/material/Autocomplete";/

axios.defaults.withCredentials = true;
const APIURL = process.env.REACT_APP_APIURL;

const queryString = require('query-string');

const styles = {
  overlay: {
    position: 'fixed',
    display: 'block',
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    backgroundColor: 'rgba(0,0,0,0.5)',
    zIndex: '1400',
  },
  toggleoption: {
    position: "relative",
    // height: "100vh",
    // left: deviceType === 'md' ? "-2%" : '-5%',
    // left: 0,
    // right: 0,
    // display: "flex",
    // justifyContent: "space-around",
    // flexDirection: "column",
    // marginBottom: "100px",
  },
  selectionBox: {
    margin: 'auto'
  },
  toggleButton: {

    // padding: "20px",
    borderRadius: "30px",
    width: "17rem",
    height: "0.5rem",
    // display: "flex",
    // transform: "rotate(90deg)",
  },
  chipGrid: {
    // textAlign:'right',
    justifyContent: 'right',
    paddingRight: '1.5rem'
    // marginTop:'1rem'
  },
  content: {
    margin: 'auto'
  },
  datecontent: {
    margin: 'auto'
  },
  chipStyles: {
    backgroundColor: '#008081'
  },
  autoCompleteGrid: {
    display: 'flex',
    justifyContent: 'right'
  },
  '@media screen and (max-width: 1200px)': {
    content: {
      paddingTop: '1.7rem',

    },
    datecontent: {
      paddingTop: '1.7rem',

    },
  },
  '@media screen and (max-width: 600px)': {
    content: {
      paddingTop: '0.8rem',
    },
    datecontent: {
      paddingTop: '1.8rem',

    },
    autoCompleteGrid: {
      display: 'grid',
      justifyContent: 'center'
    },
    autocompleteitem1: {
      height: '2rem', borderRadius: '16px', marginRight: '1rem', border: '1px solid #00000040', width: '200px', margin: '0.3rem'
    }
  }

};




const Tab = styled(TabUnstyled)`
  font-family: Montserrat;
  color: #000;
  cursor: pointer;

  font-size:14px;

  background-color: transparent;
  width: 100%;
  // padding: 14px 18px;

  border: none;
  border-radius: 22px;
  display: flex;
  justify-content: center;
  '@media screen and (max-width: 900px)' :{
    font-size:10px;
  }


  &:hover {
    // background-color: #fff;
    font-weight:bold;
    color:#000;
    font-size:14px;
    padding: 0.3rem;
    width: 100%;
  }

  &:focus {
    color: #fff;
    border-radius: 3px;
    padding: 0.3rem;

  }

  &.${tabUnstyledClasses.selected} {
    background-color: #fff;
    color: black;
    font-size:14px;
    font-weight:bold;
    font-family:Montserrat;
    padding: 0.3rem;
    border-radius:22px

  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;




const TabsList = styled(TabsListUnstyled)(() => ({
  width: "170px",
  //  height:33px;
  backgroundColor: " #A9D0D0",
  borderRadius: "22px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "0.2rem",
  // '@media screen and (max-width: 900px)' :{
  //   width: "120px"
  // }
}));

// const StyledChip = withStyles({
//   root: {
//     display: "flex",
//     justifyContent: "center",
//     flexWrap: "wrap",
//     '&:active': {
//       boxShadow: 'none',
//       backgroundColor:'#008081' ,
//       // borderColor:
//   },
//   }
// })(Chip);


const Dashboard = (props) => {

  const history = useHistory();
  const { classes, currentView, pingFromSocket, deviceAlertStatus } = props;
  const { CompanyInfo, userPermissions, singleChamberValue, updateSingleChamber, assetGrouplistforContext } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [warehouseType, setWarehouseType] = useState(' ')
  const [chamberType, setchamberType] = useState(' ')
  const [warehouseSearchname, setWarehouseSearchname] = useState('warehousename')
  const [chamberSearchname, setChamberSearchname] = useState('chambername')
  const [toggleView, setToggleView] = useState('DetailView');
  const [date, setDate] = useState([moment().startOf('day').toDate(), moment().toDate()]);
  const [selectedQuickOption, setSelectedQuickOption] = useState(0);
  const [searchId, setSearchId] = useState("");
  const [menuItem, setMenuItem] = useState('');
  // const [chipFilters, setChipFilters] = useState({ temperature: false, humidity: false });
  // const [chipFiltersDetail, setChipFiltersDetail] = useState({ temperature: false, humidity: false, battery: false });
  const [filterItems, setFilterItems] = useState([
    { value: 'assetGroupId', name: 'warehouse' },
    { value: 'assetId', name: 'chamber' },
  ])
  const [refreshData, setRefreshData] = useState(false)
  const [dashboardData, setDashboarData] = useState([]);
  const [filterAssetGroup, setFilteredAssetGroup] = useState(null);
  const [filterAsset, setFilterAsset] = useState(null);


  // comparing with length for hotfix , we can make it better
  const disableDeviceTypes_Array = CompanyInfo &&
    CompanyInfo.settings && CompanyInfo.settings.data && CompanyInfo.settings.data.humidity &&
    CompanyInfo.settings.data.humidity.disableDeviceTypes ?
    CompanyInfo.settings.data.humidity.disableDeviceTypes.length === 4 : false

  const [chipFiltersDetail, setChipFiltersDetail] = useState(
    disableDeviceTypes_Array ? { temperature: false, battery: false } : { temperature: false, humidity: false, battery: false });
  const [chipFilters, setChipFilters] = useState(disableDeviceTypes_Array ? { temperature: false } : { temperature: false, humidity: false });

  useEffect(() => {
    // console.log('use effect',disableDeviceTypes_Array)
    if (disableDeviceTypes_Array) {
      setChipFiltersDetail({ temperature: false, battery: false })
      setChipFilters({ temperature: false })
    } else {
      setChipFiltersDetail({ temperature: false, humidity: false, battery: false })
      setChipFilters({ temperature: false, humidity: false })
    }

  }, [CompanyInfo])

  const menuItems = [

    {
      type: 'All Alerts'
    },
    {
      type: 'Violations'
    },

    {
      type: 'Warnings'
    },


  ]




  const DashboardType = localStorage.getItem("currentView");


  useEffect(() => {

    // if(DashboardType === "DashboardView"){
    setMenuItem('All Alerts');
    setSearchId("")
    setFilterAsset(null);
    setFilteredAssetGroup(null);
    setDate([moment().startOf('day').toDate(), moment().toDate()])
    setSelectedQuickOption(0)
    setChipFiltersDetail(disableDeviceTypes_Array ? { temperature: false, battery: false } : { temperature: false, humidity: false, battery: false })
    setChipFilters(disableDeviceTypes_Array ? { temperature: false } : { temperature: false, humidity: false })
    // }
  }, [DashboardType, singleChamberValue])

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
  });


  const mdbreakpoint = useMediaQuery(theme.breakpoints.up('md'));
  const smbreakpoint = useMediaQuery(theme.breakpoints.up('sm'));
  const lgbreakpoint = useMediaQuery(theme.breakpoints.up('lg'));


  const getCompanies = () => {

    API.get(`${APIURL}/companies`)
      .then((companyRes) => {
        if (companyRes.data && companyRes.data.status === 'success') {
          const companyInfo = companyRes.data.data.settings;
          const warehouseName = companyInfo && companyInfo.warehouseType;
          const chamberName = companyInfo && companyInfo.chamberType;
          if (warehouseName) {
            const assetgroup = filterItems.find((tabvalue) => tabvalue.value === 'assetGroupId');

            assetgroup.name = warehouseName;
          }

          if (chamberName) {
            const asset = filterItems.find((tabvalue) => tabvalue.value === 'assetId');

            asset.name = chamberName;
          }

          setWarehouseType(warehouseName);
          setchamberType(chamberName);
          setWarehouseSearchname(warehouseName);
          setChamberSearchname(chamberName)
        } else {
          console.error('Failed to get company info');
        }
      })
      .catch((companyErr) => {
        console.error('Http error. Failed to get company info', companyErr);
      });
  }

  const handleViewChange = (event, nextView) => {
    setToggleView(nextView);
    setSearchId("");
    setFilterAsset(null);
    setFilteredAssetGroup(null);
    setDate([moment().startOf('day').toDate(), moment().toDate()]);
    setSelectedQuickOption(1);
    setMenuItem('All Alerts');
    // setChipFilters({ temperature: false, humidity: false, })
    // setChipFiltersDetail({ temperature: false, humidity: false, battery: false, })
    setChipFilters(disableDeviceTypes_Array ? { temperature: false } : { temperature: false, humidity: false, })
    setChipFiltersDetail(disableDeviceTypes_Array ? { temperature: false, battery: false } : { temperature: false, humidity: false, battery: false })
  };

  const handleOnDateClick = (params) => {
    // const st=moment(params[0]).startOf('day').toDate()
    // const et=moment(params[1]).endOf('day').toDate()

    setSelectedQuickOption(0)
    setDate(params)
  }

  const handleQuickOptionsChange = (value) => {

    if (value === 1) {
      setDate([moment().startOf('day').toDate(), moment().toDate()])
      setSelectedQuickOption(1)
    }
    else if (value === 1.5) {

      setDate([moment().subtract(15, 'm').toDate(), moment().toDate()])
      setSelectedQuickOption(1.5)
    }
    else {
      setDate([moment().subtract(value - 1, 'd').startOf('day').toDate(), moment().toDate()])
      setSelectedQuickOption(value)
    }
  }

  const handleSearchInput = (event) => {
    const searchValue = event.target.value
    setSearchId(searchValue)
  }



  const handleFilterTypeChanged = (event) => {
    setSearchId("");
    setMenuItem(event.target.value)
    setFilterAsset(null);
    setFilteredAssetGroup(null);
  }

  const handleChipToggle = (item) => {
    const newFilters = { ...chipFilters };
    newFilters[item] = !chipFilters[item];
    setSearchId("");
    setFilterAsset(null);
    setFilteredAssetGroup(null)
    setChipFilters(newFilters)
  }

  const handleChipToggleForDetail = (item) => {

    // const { chipFiltersDetail } = this.state;
    const newFilters = { ...chipFiltersDetail };
    newFilters[item] = !chipFiltersDetail[item];
    setChipFiltersDetail(newFilters);
    setSearchId("");
    setFilterAsset(null);
    setFilteredAssetGroup(null)
  }

  const handlchipInitialstate = (initialState) => {
    setChipFilters(initialState)
  }

  const handlchipInitialstateforDetail = (initialState) => {
    // console.log('Initial state',initialState )
    setChipFiltersDetail(initialState)
  }
  const onDashboardStateChange = () => {
    updateSingleChamber(!singleChamberValue);
    history.push({ pathname: '/dashboard' })
  }

  const handleRefreshData = () => {
    setRefreshData(!refreshData)
    setMenuItem('All Alerts');
    setSearchId("")
    setFilterAsset(null);
    setFilteredAssetGroup(null);
    // setChipFilters({ temperature: false, humidity: false });
    // setChipFiltersDetail({ temperature: false, humidity: false, battery: false })
    setChipFilters(disableDeviceTypes_Array ? { temperature: false } : { temperature: false, humidity: false });
    setChipFiltersDetail(disableDeviceTypes_Array ? { temperature: false, battery: false } : { temperature: false, humidity: false, battery: false })
  }

  const handleAssetGroupChange = (event, value) => {
    // console.log('function called', )
    const selectedAssetGroup = value;
    setFilterAsset(null);
    setFilteredAssetGroup(selectedAssetGroup)


  }

  const handleAssetChange = (event, value) => {
    const searchValue = value
    setFilterAsset(searchValue)

  }



  useEffect(() => {
    getCompanies();
    if (currentView === 'DeviceView') {
      cogoToast.success(
        CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.warehouseType ?
          'You are in device view, please switch to dashboard view to view the details of all  ' + CompanyInfo.settings.warehouseType
          : 'You are in device view, please switch to dashboard view to view the details of all warehouses'
        , {
          onClick: (hide) => {
            hide();
          },
          position: 'top-right',
          hideAfter: 5,
        });
    }
  }, [])


  const getAssetList = () => {


    const m = [];
    if (filterAssetGroup !== null) {
      //first filter that assetgroup that user has selected and then show assets related to that assetgroup
      const MatchedAssetGroup = assetGrouplistforContext && assetGrouplistforContext.filter((ag) => ag.id === filterAssetGroup)
      MatchedAssetGroup.map((ag) => {
        if (ag && ag.assets && ag.assets.length) {
          ag.assets.map((ast) => {
            m.push(ast.id)
          })
        }
      })
      return m
    } else {
      assetGrouplistforContext && assetGrouplistforContext.map((ag) => {
        if (ag && ag.assets && ag.assets.length) {
          ag.assets.map((ast) => {
            m.push(ast.id)
          })
        }
      })
      return m
    }



  }

  const getAsstGroupPlaceholder = () => {
    if (CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.warehouseType) {
      return "Select " + CompanyInfo.settings.warehouseType;
    }
    return "Select Warehouse"
  }

  const getAsstPlaceholder = () => {
    if (CompanyInfo && CompanyInfo.settings && CompanyInfo.settings.chamberType) {
      return "Select " + CompanyInfo.settings.chamberType;
    }
    return "Select Chamber"
  }


  const parsed = queryString.parse(location.search);
  const SC = parsed.SC;
  const RS = parsed.RS;


  const [newViewCompanies, setNewViewCompanies] = useState(["QTS", "FTVP"]);

  window.setNewViewCompanies = (cList) => setNewViewCompanies(cList);
  const getDetail = () => {
    const companyId = CompanyInfo ? CompanyInfo.companyId : null;
    if (newViewCompanies.includes(companyId)) {
      return (<DetailNew
      date={date}
      searchId={searchId}
      chipFilters={chipFiltersDetail}
      menuItem={menuItem}
      warehouseType={warehouseType}
      chamberType={chamberType}
      handlchipInitialstateforDetail={handlchipInitialstateforDetail}
      refreshData={refreshData}
      filterAssetGroup={filterAssetGroup}
      filterAsset={filterAsset}
    />)
    }
    return (<Detail
      date={date}
      searchId={searchId}
      chipFilters={chipFiltersDetail}
      menuItem={menuItem}
      warehouseType={warehouseType}
      chamberType={chamberType}
      handlchipInitialstateforDetail={handlchipInitialstateforDetail}
      refreshData={refreshData}
      filterAssetGroup={filterAssetGroup}
      filterAsset={filterAsset}
    />)
  }

  return (
    <>

      {currentView && currentView === 'DashboardView'
        ? (singleChamberValue && SC === "showsinglechamber" || RS === "ReportSection"
          && userPermissions && userPermissions.devices && userPermissions.devices.read ? (
          <div style={{ marginTop: '40px' }}>
            <SingleChamber
              onBackClick={onDashboardStateChange}
              location={location}
              history={history}
            />
          </div>
        )
          : (
            (userPermissions && userPermissions.assets && userPermissions.assets.read
              ? (
                <>
                  <div style={{ marginTop: '1.2rem', backgroundColor: '#fff', height: smbreakpoint ? '100vh' : '120vh', borderRadius: '8px' }} >


                    <Grid container style={{ paddingTop: '0.8rem' }}>
                      {/* toggle button */}
                      <Grid item xl={3} lg={2} md={12} sm={12} xs={6} style={{ paddingLeft: '0.5rem' }}>

                        <div className={classes.toggleoption} >

                          <div
                            className={classes.toggleButton}>
                            <TabsUnstyled
                              value={toggleView}
                              onChange={handleViewChange}
                              selectionFollowsFocus

                            >
                              <TabsList >
                                <Tab value="DetailView" >Live Data</Tab>
                                <Tab value="SummaryView"  >Summary</Tab>


                              </TabsList>
                            </TabsUnstyled>
                          </div>


                        </div>


                      </Grid>
                      {!smbreakpoint && (
                        <Grid item xs={6} style={{ display: 'flex', justifyContent: 'right' }}>
                          <GlobalDownload
                            warehouseType={warehouseType}
                            chamberType={chamberType}
                            disableDeviceTypes_Array={disableDeviceTypes_Array}
                          />
                          <IconButton onClick={handleRefreshData}>
                            <Tooltip title="Refresh data">
                              <UpdateIcon sx={{ fontSize: '1.8rem' }} />
                            </Tooltip>
                          </IconButton>
                        </Grid>)
                      }
                      {/*date button */}

                      <Grid item xl={4} lg={5} md={7} sm={7} xs={0} className={classes.datecontent}  >
                        {
                          toggleView === "SummaryView" ?
                            <DateAndTimeRangePicker
                              style={{ backgroundColor: 'grey' }}
                              date={date}
                              onChange={handleOnDateClick}
                              selectedQuickOption={selectedQuickOption}
                              handleQuickOptionsChange={handleQuickOptionsChange}
                            // disableMenu={true}
                            /> :
                            ''
                        }


                      </Grid>
                      {/*search */}
                      <Grid item xl={4} lg={4} md={5} sm={5} xs={10} sx={{ textAlign: 'right' }} className={classes.content} >
                        {/* {
                          mdbreakpoint ? */}
                        <Grid className={classes.autoCompleteGrid}   >


                          {
                            toggleView !== "SummaryView" ?
                              <>
                                <div className="custom-autocomplete">
                                  <AutoField
                                    // disablePortal
                                    className={classes.autocompleteitem1}
                                    style={{ height: '2.3rem', borderRadius: '16px', marginRight: '1rem', border: '1px solid #00000040', width: '200px', }}
                                    id="combo-box-demo"
                                    backgroundColor="#f2f2f269"
                                    getOptionLabel={(option) => option}
                                    value={filterAssetGroup}
                                    options={assetGrouplistforContext && assetGrouplistforContext.map((ag) => ag.id)}
                                    // sx={{ width: 300, }}
                                    renderInput={(params) => <TextField
                                      {...params}
                                      placeholder={getAsstGroupPlaceholder()}
                                      inputProps={{
                                        ...params.inputProps,
                                        style: { textAlign: 'left' }, // Align the text inside the input field to the left
                                      }}
                                      InputLabelProps={{ style: { textAlign: 'left' } }}
                                    />}
                                    onChange={handleAssetGroupChange}

                                  />
                                </div>
                                <div className="custom-autocomplete">
                                  <AutoField
                                    //  disablePortal
                                    style={{ height: '2.3rem', borderRadius: '16px', border: '1px solid #00000040', width: '200px' }}
                                    id="combo-box-demo"
                                    options={getAssetList()}
                                    backgroundColor="#f2f2f269"
                                    value={filterAsset}

                                    renderInput={(params) => <TextField
                                      {...params}
                                      placeholder={getAsstPlaceholder()}
                                      inputProps={{
                                        ...params.inputProps,
                                        style: { textAlign: 'left' }, // Align the text inside the input field to the left
                                      }}
                                      InputLabelProps={{ style: { textAlign: 'left' } }} />}
                                    onChange={handleAssetChange}
                                  />
                                </div>
                              </> :
                              <SearchInput
                                handleSearchInput={handleSearchInput}
                                searchInput={searchId}
                                warehouseType={warehouseType}
                                chamberType={chamberType}

                              />
                          }
                          {/* } */}
                        </Grid>



                        {/* <SearchInput
                          handleSearchInput={handleSearchInput}
                          searchInput={searchId}
                          warehouseType={warehouseType}
                          chamberType={chamberType}

                        /> */}





                      </Grid>
                      {/*all alerts */}
                      <Grid item xl={1} lg={1} md={0} sm={0} className={classes.content} sx={{ margin: 'auto' }}  >
                        {
                          lgbreakpoint ?
                            <FormControl className={classes.selectionBox}  >
                              <Select
                                variant='standard'
                                className={classes.selectText}
                                disableUnderline
                                value={menuItem}
                                onChange={handleFilterTypeChanged}
                              //  style={{fontSize:'1wv'}}
                              >

                                {menuItems.map(({ type }) => (
                                  <MenuItem key={type} value={type}>
                                    <div
                                      style={{ fontSize: '1rem', }}
                                    // className={!breakPoint ? classes.menuItemsStyle1 : classes.menuItemsStyle}
                                    >

                                      <span>{type}</span>
                                    </div>
                                  </MenuItem>

                                ))}



                              </Select>

                            </FormControl>
                            :
                            ' '
                        }

                      </Grid>

                    </Grid>

                    <Grid container className={classes.chipGrid}>

                      {
                        !lgbreakpoint ?
                          <FormControl sx={{ paddingTop: '0.5rem' }} className={classes.selectionBox}   >
                            <Select
                              variant='standard'
                              className={classes.selectText}
                              disableUnderline
                              value={menuItem}
                              onChange={handleFilterTypeChanged}
                            //  style={{fontSize:'1wv'}}
                            >

                              {menuItems.map(({ type }) => (
                                <MenuItem key={type} value={type}>
                                  <div
                                    style={{ fontSize: '1rem' }}
                                  // className={!breakPoint ? classes.menuItemsStyle1 : classes.menuItemsStyle}
                                  >

                                    <span>{type}</span>
                                  </div>
                                </MenuItem>

                              ))}



                            </Select>
                          </FormControl>
                          :
                          ' '
                      }


                      {
                        toggleView === "SummaryView" ?
                          <div >
                            {Object.keys(chipFilters).map((filter) => (
                              <Chip

                                sx={{
                                  backgroundColor: chipFilters[filter] ? "#a9d0d0" : '#fff',
                                  border: chipFilters[filter] ? "0px" : '1px solid #008081',
                                  color: "#000",
                                  margin: "0.5rem",
                                  textTransform: "capitalize",
                                  fontSize: '14px',

                                  "&:hover": {

                                    backgroundColor: chipFilters[filter] ? "#a9d0d0" : '#fff',
                                  },
                                }}
                                key={filter}
                                label={filter}
                                onClick={() => handleChipToggle(filter)}
                              // color={chipFilters[filter] ? "primary" : "default"}

                              //  className={classes.chipStyles}

                              />
                            ))}
                          </div>
                          :
                          <div>
                            {Object.keys(chipFiltersDetail).map((filter) => (
                              <Chip
                                style={{


                                  // height:'2rem'
                                }}
                                sx={{
                                  backgroundColor: chipFiltersDetail[filter] ? "#a9d0d0" : '#fff',
                                  border: chipFiltersDetail[filter] ? "0px" : '1px solid #008081',
                                  color: "#000",
                                  margin: "0.5rem",
                                  textTransform: "capitalize",
                                  fontSize: '14px',

                                  "&:hover": {

                                    backgroundColor: chipFiltersDetail[filter] ? "#a9d0d0" : '#fff',
                                  },
                                }}
                                key={filter}
                                label={filter}
                                onClick={() => handleChipToggleForDetail(filter)}
                              // color={chipFiltersDetail[filter] ? "primary" : "default"}
                              //  className={classes.chipStyles}

                              />
                            ))}
                          </div>
                      }

                      <Grid item xl={1} lg={1} md={2} sm={2} xs={0} sx={{ display: 'flex', justifyContent: 'right' }}>
                        {smbreakpoint && (<><GlobalDownload
                          warehouseType={warehouseType}
                          chamberType={chamberType}
                        />
                          <IconButton onClick={handleRefreshData}>
                            <Tooltip title="Refresh data">
                              <UpdateIcon sx={{ fontSize: '1.8rem' }} />
                            </Tooltip>
                          </IconButton> </>)
                        }
                      </Grid>
                    </Grid>




                    <Grid container style={{ backgroundColor: '#fff' }}>
                      {
                        toggleView === "SummaryView" ?
                          <Summary
                            date={date}
                            searchId={searchId}
                            menuItem={menuItem}
                            chipFilters={chipFilters}
                            warehouseType={warehouseType}
                            chamberType={chamberType}
                            handlchipInitialstate={handlchipInitialstate}
                            refreshData={refreshData}
                            filterAssetGroup={filterAssetGroup}
                            filterAsset={filterAsset}
                          /> :
                          getDetail()

                      }
                    </Grid>

                  </div>

                </>
              )
              : (
                <div>
                  {/* <h3>You do not have permission to view assets, Please contact your Admin.</h3> */}
                  <div className={classes.overlay}>
                    <CircularProgress
                      style={{
                        position: 'absolute', top: '30%', left: '50%', zIndex: 100,
                      }}
                      value={25}
                    />
                  </div>
                </div>
              )
            )

          ))

        : <DeviceView />}

    </>
  )
}

export default withStyles(styles)(Dashboard);
