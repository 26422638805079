import React, { useState, useContext, } from "react";
import { withStyles } from '@mui/styles';
import { Grid, Tooltip, } from "@mui/material";
import Search from 'components/Search';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
// import FormGroup from "@mui/material/FormGroup";
import UserContext from 'Context/UserContext';
import { useMediaQuery, createTheme } from '@material-ui/core';
import CalendarComp from "./CalendarComp";
// import InfoIcon from '@mui/icons-material/Info';
import TableComp from "./TableComp";
import moment from "moment";
import PrimaryButton from "components/PrimaryButton";
import GetAppIcon from '@mui/icons-material/GetApp';
import { styled } from '@mui/system';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import API from 'API/apiConfig';
const queryString = require('query-string');
import { CircularProgress, } from "@mui/material";
import cogoToast from 'cogo-toast';

const styles = {
  outerdiv: {
    marginTop: '1rem'
  },
  heading: {
    fontSize: '1.8em',
    fontWeight: 'bold',

  },
  togglebutton: {
    justifyContent: 'right',
    padding: '0.8rem 0rem 0.8rem 0rem'
  },
  outerspan: {
    backgroundColor: "#fff",
    paddingTop: '0.5em',
    borderRadius: '22px',
    border: '1px solid #D3D3D3',
    height: '60%',
    fontWeight: 'bold',
    fontSize: '16px',
    width: '100%',
    textAlign: 'center',
  },

  durationinnerspan: {
    width: '15px',
    height: '15px',
    display: 'inline-block',
    marginRight: '2px',
    // marginLeft:'1px',

  },
  downloadButton: {
    color: '#008081',
    paddingLeft: '1em',
    width: '2rem',
    height: '2rem'

  },


  menuItemsStyle: {
    display: "flex",
    justifyContentContent: "center",
    alignItems: "center",
    fontSize: '1.2em',
  },
  menuItemsStyle1: {
    display: "flex",
    justifyContentContent: "center",
    alignItems: "center",
    fontSize: '3vw',
  },
  menuItemsStylemd:{
    display: "flex",
    justifyContentContent: "center",
    alignItems: "center",
    fontSize: '0.8rem',
  },
  toggleoption: {
    position: "fixed",
    height: "100vh",
    // left: deviceType === 'md' ? "-2%" : '-5%',
    // left: 0,
    // right: 0,
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "column",
    marginBottom: "100px",
  },
  toggleButton: {

    padding: "20px",
    borderRadius: "30px",
    width: "17rem",
    height: "0.5rem",
    display: "flex",
    transform: "rotate(90deg)",
  },
};


const menuItems = [

  {
    type: 'All Violations'
  },
  {
    type: 'Humidity Violations'
  },

  {
    type: 'Temperature Violations'
  },


]


const durationShades = [
  {
    opacity: 0,
    text: 'None'
  },
  // {
  //   opacity:1,
  //   text:'Once'
  // },
  {
    opacity: 1,
    // text:'Twice'
    text: 'Low'
  },
  {
    opacity: 3,
    // text:'Thrice'
    text: 'Medium'
  },

  {
    opacity: 6,
    // text:'More than 3 times'
    text: 'High'
  },



]
const APIURL = process.env.REACT_APP_APIURL;

const Tab = styled(TabUnstyled)`
  font-family: Montserrat;
  color: #fff;
  cursor: pointer;
  font-weight:900;
  font-size:14px;
 
  background-color: transparent;
  width: 100%;
  // padding: 14px 18px;
  
  border: none;
  border-radius: 22px;
  display: flex;
  justify-content: center;

  &:hover {
    // background-color: #fff;
    font-weight:bold;
    color:#fff;
    font-size:14px;
    padding: 0.3rem;
  }

  &:focus {
    color: #fff;
    border-radius: 3px;
    padding: 0.3rem;
    
  }

  &.${tabUnstyledClasses.selected} {
    background-color: #fff;
    color: black;
    font-size:14px;
    font-weight:bold;
    font-family:Montserrat;
    padding: 0.3rem;
    border-radius:22px
    
  }
  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
 
`;


const TabsList = styled(TabsListUnstyled)`
  width: 15rem;
   height:1.9rem;
  background-color: #008081;
  border-radius: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3rem
`;

// const AntSwitch = styled(Switch)(({ theme }) => ({
//     width: 50,
//     height: 26,
//     padding: 0,
//     display: "flex",
//     "&:active": {
//       "& .MuiSwitch-thumb": {
//         width: 15
//       },
//       "& .MuiSwitch-switchBase.Mui-checked": {
//         transform: "translateX(9px)"
//       }
//     },
//     "& .MuiSwitch-switchBase": {
//       padding: 2,
//       "&.Mui-checked": {
//         transform: "translateX(24px)",
//         color: "#008081",
//         "& + .MuiSwitch-track": {
//           opacity: 1,
//           backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#f2f2f2",
//           border: theme.palette.mode === "dark" ? "#177ddc" :  '1px solid #606060',

//         }
//       }
//     },
//     "& .MuiSwitch-thumb": {
//       boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
//       width: 20,
//       height: 20,
//       borderRadius: 10,
//       color: "#008081",
//       transition: theme.transitions.create(["width"], {
//         duration: 200
//       })
//     },
//     "& .MuiSwitch-track": {
//       borderRadius: 50 / 2,
//       opacity: 1,
//       backgroundColor:
//         theme.palette.mode === "dark"
//           ? "#008081"
//           : "#f2f2f2",
//           border: theme.palette.mode === "dark" ? "#177ddc" :  '1px solid #606060',
//       boxSizing: "border-box"
//     }
//   }));
const EventSummary = (props) => {
  const { classes } = props;
  const [menuItem, setMenuItem] = useState('All Violations');
  const [toggleValue, setToggleValue] = useState('CalendarView')
  const [searchvalue, setSearchValue] = useState('');
  const [searchLength, setSearchLength] = useState();

  const [downloadstartdate, setDownloadStartDate] = useState('');
  const [downloadenddate, setDownloadEndDate] = useState('');
  const [storeStartDate, setStoreStartDate] = useState('');
  const [storeEndDate, setStoreEndDate] = useState('');

  const [startdatetable, setStartDateTable] = useState('');
  const [enddatetable, setEndDateTable] = useState('');
  const [loading, setLoading]=useState(false);

  const companyDetails = useContext(UserContext);
  const { CompanyInfo ,} = companyDetails;
  const history = useHistory();


  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
  });



  const mediumScreen = useMediaQuery(theme.breakpoints.up('md'));
  const breakPoint = useMediaQuery(theme.breakpoints.up('sm'));

  const changetoggleValue = (event, nextView) => {

    setMenuItem('All Violations')
    setSearchValue('')
    // setToggleValue('TableView')
    setToggleValue(nextView);
    {
      toggleValue === "CalendarView" ?
      (history.push({ pathname: '/dailyEvents', search: `?&&view=TableView` }))
      : (history.push({ pathname: '/dailyEvents', search: `?&&view=CalendarView` }))
    }

  }

  const onFilterTypeChanged = (event) => {
    setMenuItem(event.target.value)
  }

  const searchValue = (event) => {
    
    setSearchValue(event.target.value)
    setSearchLength((event.target.value).length)
  }

  

  useEffect(()=>{
  //clear search if there is any change in filters
    setSearchValue('')
  },[menuItem])

  const handleDownload = (dateInfo) => {

    const startTime = Math.round((dateInfo.start) / 1000);
    const EndTime = Math.round((dateInfo.end) / 1000);
    setDownloadStartDate(startTime);
    setDownloadEndDate(EndTime);


  }

  const handleMonthDates = (monthdates) => {
    // console.log('Month dates is running', monthdates)
    const monthstartdate = (monthdates.start / 1000);  // milisec to sec
    const monthenddate = (monthdates.end / 1000);          // milisec to sec
    setDownloadStartDate(monthstartdate);
    setDownloadEndDate(monthenddate);
    setStoreStartDate(monthstartdate);
    setStoreEndDate(monthenddate);
    setMenuItem('All Violations');
    setSearchValue('')
  }

  const handleMonthDatesTable = (sT, eT) => {
    setStartDateTable(sT)
    setEndDateTable(eT)
    setSearchValue('')
  }


  const downloadReport = () => {

    const companyName = CompanyInfo && CompanyInfo.companyId;
    const timezone = moment.tz.guess()
    setLoading(true);
    if (toggleValue === "CalendarView") {

      const url = `${APIURL}/events/summary?companyId=${companyName}&startTime=${downloadstartdate}&endTime=${downloadenddate}&timezone=${timezone}&response=report`
      // console.log('url', url)
      // window.open(url);
      // setDownloadStartDate(storeStartDate);
      // setDownloadEndDate(storeEndDate)

      API({
        url: url,
        method: 'GET',
        headers: {
          Accept: 'application/vnd.ms-excel',
        },
        responseType: 'blob',
      })
        .then((response) => {
          if (response.data) {
            document.body.style.cursor = 'default';
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;   
             let filename=` ${companyName}_events_Summary- ${new Date(downloadstartdate * 1000).toLocaleDateString()} - ${new Date(downloadenddate * 1000).toLocaleDateString()}`;
            filename += '.xlsx';
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            link.remove();
           setLoading(false)
            setDownloadStartDate(storeStartDate);
            setDownloadEndDate(storeEndDate)
      
          } else {
            cogoToast.error('Enable to download reports', {
              position: 'top-right',
            });
            setLoading(false)
          }
        })
        .catch((err) => {
          setLoading(false)
          console.error('Error downloading report ', err);
        });

    } else {

      const url = `${APIURL}/events/summary?companyId=${companyName}&startTime=${startdatetable}&endTime=${enddatetable}&timezone=${timezone}&response=report`
      // window.open(url)

      API({
        url: url,
        method: 'GET',
        headers: {
          Accept: 'application/vnd.ms-excel',
        },
        responseType: 'blob',
      })
        .then((response) => {
          if (response.data) {
            document.body.style.cursor = 'default';
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;   
             let filename=` ${companyName}_events_Summary- ${new Date(startdatetable * 1000).toLocaleDateString()} - ${new Date(enddatetable * 1000).toLocaleDateString()}`;
            filename += '.xlsx';
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            link.remove();
           setLoading(false)
           
      
          } else {
            cogoToast.error('Enable to download reports', {
              position: 'top-right',
            });
            setLoading(false)
          }
        })
        .catch((err) => {
          setLoading(false)
          console.error('Error downloading report ', err);
        });


    }


  }

  const handlePlaceholdervalue=()=>{
   
    if(CompanyInfo?.settings?.chamberType && CompanyInfo?.settings?.warehouseType ){
      return `Search by ${CompanyInfo.settings.chamberType} or ${CompanyInfo.settings.warehouseType} Id`
    }
    if(CompanyInfo?.settings?.chamberType &&  !CompanyInfo?.settings?.warehouseType){
     return `Search by ${CompanyInfo.settings.chamberType} or Warehouse Id`
    }
    if(!CompanyInfo?.settings?.chamberType &&  CompanyInfo?.settings?.warehouseType){
     return `Search by Chamber or ${CompanyInfo.settings.warehouseType} Id`
    }
    return "Search by Warehouse or Chamber Id"
  }

  const parsed = queryString.parse(location.search);
  const viewValue = parsed.view

  useEffect(() => {
    if (viewValue === "TableView") {
      setToggleValue("TableView")
    }

  }, [viewValue])



  return (
    <div className={classes.outerdiv}>
      {
   loading && (
   <div
   style={{
     position: "fixed",
     display: "block",
     width: "100%",
     height: "100%",
     top: "0",
     left: "0",
     right: "0",
     bottom: "0",
     backgroundColor: "rgba(0,0,0,0.5)",
     zIndex: "2",
   }}
 >
   <CircularProgress
     style={{ position: "absolute", top: "50%", left: "50%" }}
   />
 </div>)
}
      {
        !breakPoint ?
          <Grid container style={{ justifyContent: 'right' }}>

            <TabsUnstyled
              value={toggleValue}
              onChange={changetoggleValue}
              selectionFollowsFocus

            >
              <TabsList >
                <Tab value="CalendarView"  >Calendar View</Tab>
                <Tab value="TableView"   >Table View</Tab>

              </TabsList>
            </TabsUnstyled>

          </Grid> :
          ''

      }
      <Grid>
        <Grid item style={{ textAlign: 'left', width: '100%', fontSize: !breakPoint ? '2vw' : '1.1rem' }} lg={5} md={6} sm={12} xs={12} >
          <span className={classes.heading}>
            {toggleValue === "CalendarView" ? "Calendar View " : " Table View"}
          </span>
        </Grid>
      </Grid>
      {/* { breakPoint ? 
          <> */}
      <Grid container>

        <Grid item lg={4} md={4} sm={4} xs={7} >
         
          <Search
            variant="standard"
            id="userSearchInput"
            // placeholder={!breakPoint ? "Search " : 
            // "Search by Warehouse or Chamber Id"
            placeholder={handlePlaceholdervalue()}
            warehouseType={CompanyInfo?.settings?.warehouseType ? CompanyInfo.settings.warehouseType :''}
            chamberType={CompanyInfo?.settings?.chamberType ? CompanyInfo.settings.chamberType :''}
            onChange={searchValue}
           value={searchvalue}
          />
      
        </Grid>



        <Grid item lg={4} md={4} sm={3} xs={5} style={{ textAlign: 'left', margin: 'auto' }}  >

          <FormControl className={classes.selectionBox} >
            <Select
              variant='standard'
              className={classes.selectText}
              disableUnderline
              value={menuItem}
              onChange={onFilterTypeChanged}
            //  style={{fontSize:'1wv'}}
            >

              {menuItems.map(({ type }) => (
                <MenuItem key={type} value={type}>
                  <div
                    // className={!breakPoint ? classes.menuItemsStyle1 : !mediumScreen ? classes.menuItemsStylemd : classes.menuItemsStyle}
                    className={!mediumScreen ? classes.menuItemsStylemd : !breakPoint ? classes.menuItemsStyle1 : classes.menuItemsStyle }
                  >

                    <span>{type}</span>
                  </div>
                </MenuItem>

              ))}
              {/* {
                          (toggleValue === "TableView" && (
                            <MenuItem value="Only Alerted Warehouse" >
                            <div className={classes.menuItemsStyle} >
                            Only Alerted Warehouse
                            </div>
                            
                            </MenuItem>
                          ))
                        } */}


            </Select>

          </FormControl>

        </Grid>
        {breakPoint ?
          <Grid item lg={4} md={4} sm={5} xs={12}  >

            <div className={classes.outerspan}>
              {/* <span style={{}} >Continous Violation for 4 hours : </span>  */}
              {durationShades.map((shade) => {
                const opa = (shade.opacity / 6);
                return (<>

                  <span
                    style={{
                      width: '15px',
                      height: '15px',
                      border: '1px solid rgba(0,0,0,0.4)',
                      display: 'inline-block',
                      marginRight: '2px',
                      marginLeft: '2px',
                      // borderRadius:'10px'

                    }}
                  >
                    <span className={classes.durationinnerspan}
                      style={{
                        opacity: opa,
                        backgroundColor: "#E43737" ,
                      }} >

                    </span>
                  </span>

                  <span style={{ fontSize: mediumScreen ? '0.9vw' : "1.2vw", fontWeight: 'bold', marginRight: mediumScreen || breakPoint ? '2em' : '2.5em' }}>{shade.text}</span>
                </>
                )
              })}
            </div>
          </Grid>
          :
          <></>}



      </Grid>

      {/* for table view */}
      {
        (toggleValue === "TableView" && !breakPoint) ?
          <>
            <Grid container >


            </Grid>
            <Grid xs={12} style={{ padding: '0.6rem', height: '2.5rem', marginTop: '1rem', border: '2px solid rgba(0,0,0, 0.2)', borderRadius: '15px', backgroundColor: '#fff' }} >
              {
                durationShades.map((shade) => {
                  const opa = (shade.opacity / 6);
                  return (
                    <>
                      <span
                        style={{
                          width: '15px',
                          height: '15px',
                          border: '1px solid #D3D3D3',
                          display: 'inline-block',
                          marginRight: '2px',
                          //  marginLeft:'1px',

                        }}
                      >
                        <span
                          style={{
                            width: '14px',
                            height: '15px',

                            display: 'inline-block',
                            marginRight: '2px',

                            cursor: 'pointer',
                            opacity: opa,
                            backgroundColor: "#E43737"
                          }}
                        >

                        </span>
                      </span>

                      <span style={{ fontSize: '3vw', fontWeight: 'bold', marginRight: '1em' }}>{shade.text}</span>
                    </>
                  )
                })
              }
            </Grid>
          </> :
          <></>
      }


      <Grid container className={classes.togglebutton}>

        <Tooltip title={
          toggleValue === "CalendarView" ?
          "You can also download the report by selecting the week/day of calendar":''
          }>
          <span  style={{paddingRight:"1rem"}}>
        <PrimaryButton
         
          text="Report"
          icon={<GetAppIcon style={{ marginRight: "5px" }} />}
          onClick={downloadReport}
        />
        </span>
       </Tooltip>

        <div>


          {breakPoint ?
            <div
            // className={classes.toggleButton}
            >
              <TabsUnstyled
                value={toggleValue}
                onChange={changetoggleValue}
                selectionFollowsFocus

              >
                <TabsList >
                  <Tab value="CalendarView"  >Calendar View</Tab>
                  <Tab value="TableView"   >Table View</Tab>

                </TabsList>
              </TabsUnstyled>
            </div>
            :
            <></>}



        </div>

      </Grid>
          <Grid container
           sx={{textAlign:'left', 
           paddingBottom:'0.7rem',
           color:'rgba(0,0,0,0.5)',
           fontSize:'16px',
           fontFamily:'Montserrat',
          }}
           >Note: Hover over the square boxes({CompanyInfo&& CompanyInfo.settings && CompanyInfo.settings.chamberType ? CompanyInfo.settings.chamberType : "chambers"}) to see the violation details.
           </Grid>
      <Grid container>
        {
          (toggleValue === "TableView" && viewValue === "TableView") ?
            <TableComp menuItem={menuItem}
              searchvalue={searchvalue}
              handleMonthDatesTable={handleMonthDatesTable} />
            :
            <CalendarComp 
              menuItem={menuItem}
              searchvalue={searchvalue}
              searchLength={searchLength}
              handleDownload={handleDownload}
              handleMonthDates={handleMonthDates}
            
               />
        }
      </Grid>

    </div>
  )
}


export default withStyles(styles)(EventSummary);