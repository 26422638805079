/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState} from "react";
import moment from 'moment';
import Grow from '@mui/material/Grow';
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import cogoToast from 'cogo-toast';
import API from 'API/apiConfig';
import UserContext from 'Context/UserContext';
import {
  CircularProgress,
} from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
// import { useHistory } from 'react-router';
// import Calendar from '../../components/Calendar';
import EventLogsGrid from './EventLogsGrid';
import DateAndTimeRangePicker from '../../components/DateAndTimeRangePicker';
import PrimaryButton from "components/PrimaryButton";
import { useLocation, useHistory } from "react-router-dom";

const APIURL = process.env.REACT_APP_APIURL;
const queryString = require('query-string');

axios.defaults.withCredentials = true;

const styles = {
  topStrip: {
    textAlign: 'right',
    // borderRight: '1px solid white',
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
  modalDialogOuterDiv: {
    display: 'flex',
    justifyContent: 'center',
  },
  modalDialogDiv: {
    display: 'flex',
    width: '50%',
    flexDirection: 'column',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  errorMessageDiv: {
    MarginTop: '20%',
    height: '20px',
    textAlign: 'left',
  },
  errorMessageText: {
    fontFamily: 'Montserrat',
    fontSize: '12px',
    textAlign: 'left',
    color: 'red',
    marginTop: '15px',
  },
  inputTextField: {
    border: 'none',
    borderBottom: '1px solid grey',
    width: '100%',
    height: '30px',
    outline: 'none',
    '&:focus': {
      borderBottom: '1px solid #00adef',
    },
    color: 'black !important',
  },
  inputTextFieldSmall: {
    border: 'none',
    borderBottom: '1px solid grey',
    width: '40%',
    margin: '5%',
    height: '30px',
    outline: 'none',
    '&:focus': {
      borderBottom: '1px solid #00adef',
    },
    color: 'black !important',
  },
  inputLabel: {
    opacity: '0.5',
    fontFamily: 'Montserrat',
    fontSize: '12px',
    textAlign: 'left',
    marginTop: '10px',
    marginBottom: '5px',
    color: 'rgba(3, 0, 0, 0.5)',
  },
  warning: {
    color: 'red',
    fontWeight: '500',
  },
  topDiv: {
    width: '100%',
    height: '50px',
    display: 'flex',
    backgroundColor: '#ffffff',
  },
  boxDiv: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#dedede',
    // backgroundImage: 'url("../img/temperature.svg")',
    // backgroundSize: 'contain',
    // backgroundRepeat: 'no-repeat',
    padding: '0.5rem',
    paddingBottom: '1rem !important',
    borderRadius: '1rem',
    marginBottom: '0.5rem',
    boxShadow: '0 0 1px rgba(34, 25, 25, 0.4)',
  },
  deleteDiv: {
    width: '100px',
    borderRight: '1px solid #dedede',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    color: 'red',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  editDiv: {
    width: '100px',
    borderRight: '1px solid #dedede',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    color: 'navy',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  disabledButton: {
    width: '100px',
    borderRight: '1px solid #dedede',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#c2c2c2',
  },
  floatLeft: {
    float: 'left',
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    marginRight: '0.5rem',
    width: 20,
    height: 20,
  },
  link: {
    display: 'flex',
  },
  item: {
    flexDirection: 'row-reverse',
  },
 
  '@media screen and (max-width:600px)': {
    buttonStyle:{
      paddingTop:'10px'
    }
  },
 
};


const EventLogs =(props)=>{
   const [date, setDate]=useState([moment().startOf('day').toDate(), moment().toDate()]);
   const [selectedQuickOption, setSelectedQuickOption]=useState(1);
   const [rowData, setRowData]=useState(null);
   const [selectedRowData, setSelectedRowData] = useState([]);
   const [selectionClearTime, setSelectionClearTime] = useState(0);
   const [isLoading, setisLoading]=useState(true);
  
   const location = useLocation();
   const history = useHistory();
   const {classes}=props;
   const { userPermissions, CompanyInfo , updateSingleChamber} = useContext(UserContext);

   const parsed = queryString.parse(location.search);
// const st=parsed.startTime;
// const et=parsed.endTime;
const ELvalue=parsed.EL;

 
  const getEvents = (queryParams) => {
  
    API 
      .get(`${APIURL}/events/?startTime=${queryParams.startTime}&endTime=${queryParams.endTime}`)
      .then((response) => {
       
        if (
          response.data
          && response.data.status
          && response.data.status === 'success'
        ) {
        //  console.log('Resp',response.data.data )
          setRowData(response.data.data);
          setisLoading(false);
         
        } else {
          
          cogoToast.error('Unable to get events from server', { position: 'top-right' });
          setisLoading(false);
          setRowData([]);
         
          console.error('event response failed', response);
        }
      })
      .catch((
        error,
      ) => {
       
        // cogoToast.error('Unable to get events from the server', { position: 'top-right' });
        setisLoading(false);
        // this.setState({
        //   isLoading: false,
        // });
        console.error('event response failed & caught in catch', error);
      });
  }


  useEffect(()=>{
  // console.log('UseEffect', date)
        if(date && date.length === 2){
         
          const queryParams = {
            startTime: Math.round(Number(date[0].getTime() / 1000)),
            endTime: Math.round(Number(date[1].getTime() / 1000)),
          };
         getEvents(queryParams);
        }
        updateSingleChamber(false);
        setSelectedQuickOption(0);
        localStorage.setItem('startTime',date[0]);
        localStorage.setItem('endTime',date[1])
  },[]);

  useEffect(() => {
    // const parsed = queryString.parse(location.search);
    // const st = parsed.startTime;
    // const et = parsed.endTime;
    // const timeS = moment(st * 1000);
    // const timeE = moment(et * 1000);
   

    if (location.search === "" && location.pathname === "/eventlogs") {
      history.push({
        pathname: "/eventlogs",
        replace: true,
        search: `?startTime=${moment().subtract(12, "hour").unix() }&&endTime=${moment().unix()}`,
        state: { isActive: true },
      });

    }
   
   
  }, [location]);



  useEffect(()=>{
  if(ELvalue === 'events'){
    
    const parsed = queryString.parse(location.search);
    const st= parsed.startTime;
    const et= parsed.endTime;
    const newst= new Date(st * 1000);
    const newet = new Date(et * 1000)

    setDate([newst,newet]);
    setSelectedQuickOption(0);
    const queryParams = {
      startTime:st,
      endTime: et,
    };
    
    getEvents(queryParams);

  }

  },[ELvalue])

  // useEffect(()=>{
  //   console.log('Selected ****', selectedRowData && selectedRowData.length)
  //     // const chamberId=selectedRowData.assetId
  //     if(!(selectedRowData && selectedRowData.length >= 1)){
  
  //     }

  //     // history.push({pathname:'/dashboard', search: `?assetId=${chamberId}`})
  // },[selectedRowData])

  const handleOnViewData=()=>{
    updateSingleChamber(true);
    const chamberId=selectedRowData[0].assetUid;
    // const parsed = queryString.parse(location.search);
    const startTime=selectedRowData[0].timeStamp;
    const endTime = selectedRowData[0].timeStamp;
    const newst= new Date(startTime * 1000);
    const subtractday=moment(newst).subtract(1, "day");
    const unixtime=subtractday.unix();
    
    const newet=new Date(endTime * 1000);
    const checktodaysDate=newet.getDate()
    const subtractdayend= checktodaysDate === moment().date() ? moment() : moment(newet).add(1, "day");
    const unixendtime=subtractdayend.unix();
    // console.log('New st',newst.getTime() ,startTime, subtractday.unix(),unixendtime );
    history.push({pathname:'/dashboard', search: `?assetUid=${chamberId}&&startTime=${unixtime}&&endTime=${unixendtime}&&EL=singlechamber&&SC=showsinglechamber` , state: { isActive: true }, })
   
  }

const handleOnDateClick=(params)=>{
//  console.log('Params are ', params)
  setDate(params);
  setSelectedQuickOption(0);
  history.push(`?startTime=${moment(params[0]).unix()}&&endTime=${moment(params[1]).unix()}`);
  localStorage.setItem('startTime',params[0]);
  localStorage.setItem('endTime',params[1])

}


const handleQuickOptionsChange=(value)=>{
       
      if(value=== 1){
        setDate([moment().startOf('day').toDate(), moment().toDate()]);
        setSelectedQuickOption(1);
        history.push(`?startTime=${moment().subtract(12, "hour").unix()}&&endTime=${moment().unix()}`);
        localStorage.setItem('startTime',moment().subtract(12, "hour"))
        localStorage.setItem('endTime',moment())
      }
      else if (value === 1.5) {
       
        setDate([moment().subtract(15, 'm').toDate(), moment().toDate()])
        setSelectedQuickOption(1.5)   
        history.push(`?startTime=${moment().subtract(15, 'm').unix()}&&endTime=${moment().unix()}`);
        
        const startTime=moment().subtract(15, 'm');
        const endTime=moment();
       
        localStorage.setItem('startTime',startTime)
        localStorage.setItem('endTime',endTime) 
      }
      else{
        
        setDate([moment().subtract(value -1, 'd').startOf('day').toDate(), moment().toDate()]);
         setSelectedQuickOption(value);
         history.push(`?startTime=${moment().subtract(value, "day").unix()}&&endTime=${moment().unix()}`);
        
         const startTime=moment().subtract(value, "day");
         const endTime=moment();
        
         localStorage.setItem('startTime',startTime)
         localStorage.setItem('endTime',endTime)
      }

      // if(value=== 2){
      //   setDate([moment().subtract(1, 'd').startOf('day').toDate(), moment().toDate()]);
      //   setSelectedQuickOption(2);
      //   history.push(`?startTime=${moment().subtract(1, "day").unix()}&&endTime=${moment().unix()}`);
      // }
      // if(value=== 7){

      //   setDate([moment().subtract(7, 'd').startOf('day').toDate(), moment().toDate()]);
      //   setSelectedQuickOption(7);
      
      //   history.push(`?startTime=${moment().subtract(7, "day").unix()}&&endTime=${moment().unix()}`);

        
      // }
      // if(value=== 15){
      //   setDate([moment().subtract(15, 'd').startOf('day').toDate(), moment().toDate()]);
      //   setSelectedQuickOption(15);
      //   history.push(`?startTime=${moment().subtract(15, "day").unix()}&&endTime=${moment().unix()}`);
      // }
      // if(value=== 30){
      //   setDate([moment().subtract(30, 'd').startOf('day').toDate(), moment().toDate()]);
      //   setSelectedQuickOption(30);
      //   history.push(`?startTime=${moment().subtract(30, "day").unix()}&&endTime=${moment().unix()}`);
      // }

      // setDate([moment().subtract(value, 'd').startOf('day').toDate(), moment().toData()]);
      // setSelectedQuickOption(value);
}
  
  const handleExcelDownload = () => {
    setSelectionClearTime(new Date().getTime());
   
  }
   
  useEffect(()=>{
 
  
    if (date.length === 2) {
      if(selectedQuickOption === 0) {
     
              const queryParams = {
                startTime: Math.round(Number(date[0].getTime() / 1000)),
                endTime: Math.round(Number(date[1].getTime() / 1000)),
              };
              getEvents(queryParams);

            }
          }

          if(selectedQuickOption !== 0){
        
            const queryParams = {
          startTime: moment(date[0]).unix(),
          endTime: moment(date[1]).unix()
        }
        getEvents(queryParams);
          }


  }, [date, selectedQuickOption])


  const onSelectionChanged = (selectedRows) => {
  
    setSelectedRowData(selectedRows);
   
  };

  const handleDeviceNoteChange=(uid, note)=>{
   
   const deviceUid=uid

    var data = JSON.stringify([
      {
        "path": "/note",
        "op": "replace",
        "value": note.length ? note : "-"
      }
    ]);
    const encodeDeviceUid = (text) => encodeURIComponent(text).replace(/'/g,"%27").replace(/"/g,"%22")
    
    API
    .patch(`${APIURL}/events/${encodeDeviceUid(deviceUid)}`,data)
      .then((response) => {
        // console.log('edit response', response);
        if (response && response.data && response.data.status && response.data.status === 'success') {
         //if 
         setSelectedRowData([])
        } else {
          //else
        }
      })
      .catch((err) => {
        console.error('editingDeviceError', err);
        setisLoading(false);
      });
  }

  
    return (
      
      <>
 
       { isLoading
        ? (
          <div className={classes.overlay}>
            <CircularProgress
              style={{ position: 'absolute', top: '50%', left: '50%' }}
            />
          </div>
        )
        : (
          <>
          {userPermissions && userPermissions.devices &&  userPermissions.devices.read ?
            (<Grow in>
            <div>
              
              <Grid container style={{ display: 'flex', justifyContent: 'space-between' }} spacing={0} className={classes.topStrip}>
             {/* {console.log('Selected rw ', selectedRowData && selectedRowData.length)} */}

                <Grid item >
                  <PrimaryButton
                    disabled={!(selectedRowData && selectedRowData.length === 1)}
                    variant="contained" color="secondary"
                    onClick={handleOnViewData}
                    text="View Data"
                  />
                        
                  <PrimaryButton
                    text="Report"
                    icon={<GetAppIcon style={{ marginRight: "5px" }} />}
                    style = {{margin: '0 5px'}}
                    onClick={handleExcelDownload}
                  />
                </Grid>
                <Grid item className={classes.buttonStyle}  >
                  <DateAndTimeRangePicker
                    date={date}
                    onChange={handleOnDateClick}
                    selectedQuickOption={selectedQuickOption}
                    handleQuickOptionsChange={handleQuickOptionsChange}
                    disableMenu={false}
                  />
                    </Grid>
              
              </Grid>

              <Grow in>
                <Grid container spacing={0} justifyContent="center">
                  <Grid item md={12} sm={12} xs>
                    <EventLogsGrid
                      rowData={rowData}
                      companySettings={CompanyInfo}
                      onSelectionChanged={onSelectionChanged}
                      selectedRowData={selectedRowData}
                      excelDownload={handleExcelDownload}
                      selectionClearTime={selectionClearTime}
                      handleDeviceNoteChange={handleDeviceNoteChange}
                   
                    />
                  </Grid>
                </Grid>
              </Grow>
            </div>
          </Grow>)
           :
          (
            <div><h3>You do not have permisssion to view this page, please contact your Admin.</h3></div>
          ) }
         </>

        )} 
    </>

    )
    

}

export default withStyles(styles)(EventLogs);









