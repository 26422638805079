/*
@author - Priya Agarwal
@email - priya@adaptideations.com
@version 0.1

@About this:
This is a calendar component.
Built using material-ui, react-dates & react-jss.
Using this module we can select the custom dates from calendar.
And there is one more dropdown in which we can select combined dates like
1. last week
2. last 15 days
3. last month.

*/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker, isInclusivelyBeforeDay } from 'react-dates';
import './Reactdatepicker.css';
// import '../components/Reactdatepicker.css';
import moment from 'moment';
import { withStyles } from '@mui/styles';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

const styles = {
  selectionBox: {
    background: 'none',
    width: '5rem',
    height: '35px',
    borderRadius: '30px',
    outline: 'none',
    '&:focus': {
      border: '2px solid #00adef',
    },
    margin: '5px 10px',
    display: 'flex',
    flexWrap: 'wrap',
  },
  selectText: {
    fontSize: '12px',
    fontFamily: 'Montserrat',
  },
  timeLabel: {
    fontSize: '10px',
    fontFamily: 'Montserrat',
    marginRight: '10px',
  },
  dateDisplay: {
    height: '35px',
  },
  info: {
    margin: '9px',
    fontSize: 'medium',
    color: '#777',
    opacity: '0.7',
  },
  disable: {
    display: 'none',
  },
  mystyles:{
    color:'red'
  }
  
};

/*
@TO-DO: Improve the css of calendar component
*/
class UpdateCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedInput: null,
    };
  }

  onDatesChanged = (startDate, endDate) => {
    const { handleRangeChange, handleQuickOptionsChange } = this.props;
    if (handleRangeChange && handleQuickOptionsChange) {
     
      handleRangeChange(startDate.startOf('day'), endDate.endOf('day'));
      // handleQuickOptionsChange(0);
    }
  }

  render() {
    const {
      classes, startDate, endDate, selectedQuickOption, handleQuickOptionsChange, disable
    } = this.props;
    const { focusedInput } = this.state;
    return (
      <div style={{ display: 'flex', flexDirection: 'row-reverse', justifyContent:'flex-end' }}>
         <FormControl className={classes.selectionBox}>
           <Select
           variant='standard'
             className={classes.selectText}
             value={selectedQuickOption}
             onChange={(e) => { if (handleQuickOptionsChange) handleQuickOptionsChange(Number(e.target.value)); }}
             spacing={2}
             disableUnderline
             SelectDisplayProps={{
               // disableUnderline: true,
               style: {
                backgroundColor: '#f2f2f2',
                borderRadius: '22px',
                color: '#000',
                fontSize: '14px',
                margin:'auto'
                
              },
            }}
          >
            <MenuItem value={0} disabled>Custom</MenuItem>
            <MenuItem value={7} className={classes.timeLabel}>Last Week</MenuItem>
            <MenuItem value={15} className={disable ? classes.disable : classes.timeLabel}>Last 15 Days</MenuItem>
            <MenuItem value={30} className={disable ? classes.disable : classes.timeLabel}>Last Month</MenuItem>
          </Select>
        </FormControl>
        <DateRangePicker
          // styles={{width:'500px', color:'red' }}
         className={classes.mystyles}
          startDateId="startDate"
          noBorder
          endDateId="endDate"
          displayFormat="DD/MM/YYYY"
          isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
          numberOfMonths={1}
          startDate={startDate}
          endDate={endDate}
          onDatesChange={({ startDate, endDate }) => { this.onDatesChanged(startDate, endDate); }}
          focusedInput={focusedInput}
          onFocusChange={(focusedInput) => { this.setState({ focusedInput }); }}
          showDefaultInputIcon
          inputIconPosition="after"
          
       
    //       showTimeSelect
    // timeFormat="HH:mm"
    // timeIntervals={15}
        />
       
        <p className={classes.timeLabel}><b>TIME PERIOD</b></p>
        {/* <Tooltip title={message}>
          <InfoIcon className={classes.info} />
        </Tooltip> */}
      </div>
    );
  }
}

UpdateCalendar.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any),
  // startDate: PropTypes.string,
  // endDate: PropTypes.string,
  handleRangeChange: PropTypes.func,
  handleQuickOptionsChange: PropTypes.func,
  selectedQuickOption: PropTypes.number,
};

UpdateCalendar.defaultProps = {
  classes: {},
  // startDate: '',
  // endDate: '',
  handleRangeChange: () => { },
  handleQuickOptionsChange: () => { },
  selectedQuickOption: 0,
};

export default withStyles(styles)(UpdateCalendar);
